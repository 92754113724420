import { Box, Divider, Typography } from '@mui/material';

import { LinksSection } from './components/LinksSection';
import { FilesSection } from './components/FilesSection';
import { Unit } from '@/shared/types/api/unit.type';
import { useTranslation } from 'react-i18next';
import { SetupSettings } from '../Settings/SetupSettings';
import { useCallback, useEffect, useState } from 'react';
import { getDefaultLinks } from '@/infrastructure/apis/links';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiFile, Link } from '@/shared/types/api';
import { getFiles } from '@/infrastructure/apis/files';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
type Props = {
  selectedUnit?: Unit;
};

export const DefaultContent = ({ selectedUnit }: Props) => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const selectedUnitId = selectedUnit?.id || null;
  const { getAccessTokenSilently } = useAuth0();

  const [setupClicked, setSetupClicked] = useState(false);

  const [defaultLinks, setDefaultLinks] = useState<Link[]>([]);
  const [defaultFiles, setDefaultFiles] = useState<ApiFile[]>([]);

  const [loading, setLoading] = useState(false);

  useDeferredLoader(loading, 'default-content-loading');

  const fetchLinks = useCallback(async () => {
    try {
      const defaultLinks = await getDefaultLinks(getAccessTokenSilently, {
        unitId: selectedUnitId,
      });

      setDefaultLinks(defaultLinks);
    } catch {
      toast.error(t('md.defaultContent.errorLoadingLinks', { id: 'default-content-loading' }));
    }
  }, [getAccessTokenSilently, selectedUnitId, t, toast]);

  const fetchFiles = useCallback(async () => {
    try {
      const {
        data: { data: _files },
      } = await getFiles(getAccessTokenSilently, {
        unitId: selectedUnitId,
      });
      setDefaultFiles(_files);
    } catch (error) {
      toast.error(t('md.defaultContent.errorLoadingFiles', { id: 'default-content-loading' }));
    }
  }, [getAccessTokenSilently, selectedUnitId, t, toast]);

  useEffect(() => {
    setSetupClicked(false);
  }, [selectedUnitId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchLinks(), fetchFiles()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchLinks, fetchFiles]);

  if (loading) return null;

  const showSetupSettings =
    selectedUnitId &&
    !setupClicked &&
    !loading &&
    defaultLinks.length === 0 &&
    defaultFiles.length === 0;

  return (
    <Box pt={2}>
      {showSetupSettings ? (
        <SetupSettings onClick={() => setSetupClicked(true)} />
      ) : (
        <Box>
          <Typography variant='body2' sx={{ display: 'block', mb: 4 }}>
            {t('md.defaultContent.description')}
          </Typography>
          <LinksSection
            selectedUnitId={selectedUnitId}
            defaultLinks={defaultLinks}
            mutateDefaultLinks={setDefaultLinks}
            refetchLinks={fetchLinks}
          />
          <Divider sx={{ my: 4 }} />
          <FilesSection
            selectedUnitId={selectedUnitId}
            defaultFiles={defaultFiles}
            mutateDefaultFiles={setDefaultFiles}
            refetchFiles={fetchFiles}
          />
        </Box>
      )}
    </Box>
  );
};
