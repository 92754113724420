import TooltipInfo from '@/components/TooltipInfo';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  text?: string;
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  lineCount: number;
}

export const LineClamp = (props: Props) => {
  const [overflow, setOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>();

  const childrenToRender = props.children ?? props.text;

  useEffect(() => {
    if (contentRef.current) {
      //+2 to consider the borders
      if (contentRef.current.scrollHeight > contentRef.current.clientHeight + 2) {
        setOverflow(true);
      }
    }
  }, []);

  const content = (
    <LineClampDiv ref={contentRef} style={{ ...(props.styles || {}) }} lineCount={props.lineCount}>
      {childrenToRender}
    </LineClampDiv>
  );

  if (overflow) {
    return (
      <TooltipInfo onHover text={props.text} placement='top' onHoverCursor='unset'>
        {content}
      </TooltipInfo>
    );
  } else {
    return content;
  }
};

const LineClampDiv = styled.div<{ lineCount: number }>`
  display: -webkit-box;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: ${props => props.lineCount};
  -webkit-line-clamp: ${props => props.lineCount};
  white-space: pre-wrap;
  word-break: break-word;
`;
