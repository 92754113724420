import { SelectedCountCounterGeneric } from '@/components/Table';
import { useTranslation } from 'react-i18next';

export const SelectedCountCounter = ({ total, selectedCount, onSelectAll, selectAllVisible }) => {
  const { t } = useTranslation();
  return (
    <SelectedCountCounterGeneric
      onSelectAll={onSelectAll}
      selectAllVisible={selectAllVisible}
      text={
        selectedCount === total
          ? t('leads.xAllSelected')
          : t('leads.xSelected', { count: selectedCount })
      }
      subText={t('selectAllTexts.lead', { count: total })}
    />
  );
};
