import { Filter } from '@/shared/util';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Fade, ListItemText, MenuItem, Popover, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  filters: Filter[];
  onAdd: (key: string) => void;
};

export const AddFilterButton = ({ filters, onAdd }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchWord, setSearchWord] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  const options = filters
    .map(item => ({
      label: t(item.labelKey),
      field: item.field,
    }))
    .filter(item => item.label.toLowerCase().includes(searchWord.toLowerCase()));

  const open = Boolean(anchorEl);
  return (
    <Box mt={3} ml='auto'>
      <Button variant='outlined' color='primary' startIcon={<AddOutlined />} onClick={handleClick}>
        {t('filter.addFilter')}
      </Button>
      <Popover
        PaperProps={{ sx: { width: '30rem' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: '1.6rem' }}>
          <TextField
            value={searchWord}
            onChange={({ target: { value } }) => setSearchWord(value)}
            label={t('filter.searchLabel')}
            size='small'
            color='secondary'
          />
        </Box>
        {options.map(item => (
          <MenuItem
            key={item.field}
            onClick={() => {
              handleClose();
              onAdd(item.field);
            }}
          >
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};
