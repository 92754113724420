import { Box, SxProps, Theme, styled, useMediaQuery } from '@mui/material';

export const useLeftSidesheetWidth = () => {
  const isBigDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'), { noSsr: true });
  return isBigDesktop ? '55rem' : '36rem';
};

export type LeftSideSheetProps = {
  bgImg?: string;
  bgColor?: string;
  contentImg?: string;
  logoImg?: string;
  invertLogo?: boolean;
  sx?: SxProps;
};
export const LeftSideSheet = ({
  bgImg,
  logoImg,
  bgColor,
  contentImg,
  invertLogo,
}: LeftSideSheetProps) => {
  const leftSidesheetWidth = useLeftSidesheetWidth();
  return (
    <Box
      width={leftSidesheetWidth}
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100%',
        backgroundColor: bgColor,
        display: 'flex',
      }}
    >
      {logoImg && <StyledLogo src={logoImg} alt='logo' invert={invertLogo} />}
      {contentImg && <ContentImage src={contentImg} alt='content' />}
      {!contentImg && <StyledBgImage src={bgImg} />}
    </Box>
  );
};

const StyledBgImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '200%',
  objectFit: 'cover',
});

const StyledLogo = styled('img')(props => ({
  position: 'absolute',
  bottom: '2.5rem',
  left: '2.5rem',
  objectFit: 'contain',
  maxHeight: '8rem',
  width: '70%',
  maxWidth: '18rem',
  zIndex: 1,
  filter: props.invert ? 'brightness(0) invert(1)' : undefined,
}));

const ContentImage = styled('img')({
  width: '90%',
  maxWidth: '40rem',
  margin: 'auto',
  objectFit: 'contain',
});
