import { useCallback, useEffect, useState } from 'react';
import { Employee } from '@/shared/types/api/employee.type';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { withSilentAccessToken } from '@/infrastructure/helper';
import axios from 'axios';
import config from '@/config/config';
import { PERMISSIONS } from '@/infrastructure/constants';
import TooltipInfo from '@/components/TooltipInfo';
import { Box, Button, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { ScanCardPopup } from '../../MyCards/components/ScanCardPopup';
import { EnterCodePopup } from '../../MyCards/components/EnterCodePopup';
import { SingleCard } from '../../MyCards/components/SingleNfcCard';
import { LtDialog } from '@/components';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

interface Props {
  employee: Employee;
  themeDomain: string;
  onBackClick?: () => void;
  onSaveSuccess?: Function;
  isAccountCards?: boolean;
}

const EmployeeCards = (props: Props) => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();

  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [cards, setCards] = useState([]);
  const [selectedCode, setSelectedCode] = useState('');
  const [codeIsChecking, setCodeIsChecking] = useState(false);
  const [scanCardPopupVisible, setScanCardPopupVisible] = useState(false);
  const [enterCodePopupVisible, setEnterCodePopupVisible] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const fetchCards = useCallback(
    () =>
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(
            config.API_BASE_URL +
              (props.isAccountCards
                ? `account/codes`
                : `business/employees/${props.employee.id}/codes`),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),

        [PERMISSIONS.READ.THEME_PROFILES],
      )
        .then(res => {
          setIsLoadingCards(false);
          setCards(res.data.data);
        })
        .catch(() => toast.error(t('error.general'))),
    [getAccessTokenSilently, props.employee.id, props.isAccountCards, t, toast],
  );
  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  const handleSubmit = async (_code: string) => {
    if (_code.length !== 10) {
      toast.error(t('cards.notValid'));
      return;
    }
    if (cards.find(card => card.code === _code)) {
      toast.error(t('cards.cardAlreadyConnected'));
      setCodeIsChecking(false);
      setScanCardPopupVisible(false);
      return;
    }

    const promise = withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.post(
          config.API_BASE_URL +
            (props.isAccountCards
              ? `account/addcode`
              : `business/employees/${props.employee.id}/codes/${_code}`),
          props.isAccountCards
            ? {
                code: _code,
              }
            : {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [PERMISSIONS.WRITE.THEME_PROFILES],
    );

    setCodeIsChecking(true);
    try {
      const res = await promise;
      setEnterCodePopupVisible(false);
      setCards([{ code: _code, date: res.data.data?.date || new Date() }, ...cards]);

      toast.success(t('cards.successUpload'));
    } catch (error) {
      toast.error(t('cards.errorUpload'));
    }
    setScanCardPopupVisible(false);
    setCodeIsChecking(false);
  };
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const handleDeleteCode = () => {
    setShowDeleteLoader(true);
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.delete(
          config.API_BASE_URL +
            (props.isAccountCards
              ? `account/codes/${selectedCode}`
              : `business/employees/${props.employee.id}/codes/${selectedCode}`),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [PERMISSIONS.WRITE.THEME_PROFILES],
    )
      .then(res => {
        if (res.data.isSuccess) {
          setSelectedCode('');
          setCards(cards.filter(card => card.code !== selectedCode));
          toast.success(t('cards.successDelete'), { id: 'delete-code-toast' });
        }
      })
      .catch(() => toast.error(t('cards.errorDelete'), { id: 'delete-code-toast' }))
      .finally(() => setShowDeleteLoader(false));
  };

  return (
    <>
      <Box px={4} py={3}>
        <Box display='flex' alignItems='center' mb={2}>
          <Typography variant='h3' sx={{ mr: 1 }}>
            {t('cards.cardsHeader')}
          </Typography>
          {!props.isAccountCards && (
            <TooltipInfo text={t('cards.headToolTip')} icon={{ color: 'primary' }} arrow />
          )}
          <Button
            onClick={() => setScanCardPopupVisible(true)}
            startIcon={<AddCircleOutline />}
            sx={{ ml: 'auto' }}
          >
            {t('connectCard')}
          </Button>
        </Box>

        <Box mb={2}>
          {isLoadingCards ? (
            <Typography>{t('cards.loadingCards')}</Typography>
          ) : cards.length === 0 ? (
            <Typography>{t('cards.noCards')}</Typography>
          ) : (
            cards.map(card => (
              <SingleCard
                card={{ date: card.date, code: card.code }}
                onDelete={() => setSelectedCode(card.code)}
              />
            ))
          )}
        </Box>

        <LtDialog
          size='sm'
          title={t('cards.codeDelete')}
          onClose={() => setSelectedCode('')}
          onCancel={() => setSelectedCode('')}
          onDelete={() => handleDeleteCode()}
          open={Boolean(selectedCode)}
          loading={showDeleteLoader}
        >
          {t('cards.areYouSureDelete')}
        </LtDialog>

        <ScanCardPopup
          onScanFinish={handleSubmit}
          onClose={() => setScanCardPopupVisible(false)}
          onEnterCodeClick={() => {
            setScanCardPopupVisible(false);
            setEnterCodePopupVisible(true);
          }}
          loading={codeIsChecking}
          open={scanCardPopupVisible}
        />
        <EnterCodePopup
          onSubmit={handleSubmit}
          onClose={() => setEnterCodePopupVisible(false)}
          open={enterCodePopupVisible}
          loading={codeIsChecking}
        />
      </Box>
    </>
  );
};
export default EmployeeCards;
