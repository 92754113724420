// There is no conclusive documentation covering all aspects of the configuration accessed and modified via the constants in this file
// Here are a few resources, the configuration objects here were mainly created by looking at the implementation of the packages used
// https://github.com/m-Ryan/easy-email
// https://github.com/m-Ryan/easy-email-demo
// https://github.com/m-Ryan/easy-email/blob/master/packages/easy-email-core/readme.md

import { EmailTemplateAttachments, EmailTemplatesEnum } from '@/shared/constants';
import { AdvancedType } from 'easy-email-core';
import { ExtensionProps } from 'easy-email-extensions';
import { TFunction } from 'react-i18next';
import { CustomType } from './blockUtils';
// documentation: https://github.com/m-Ryan/easy-email/blob/master/packages/easy-email-extensions/readme.md

const defaultAttributes = {};

const defaultBlocks: Array<{
  type: AdvancedType | CustomType;
  payload: any; // defined like this in node_modules/easy-email-extensions/lib/components/Providers/ExtensionProvider.d.ts
  allowedTemplates?: Array<EmailTemplatesEnum>;
  requireFullAccess: boolean;
}> = [
  {
    type: AdvancedType.TEXT,
    payload: { attributes: {} },
    requireFullAccess: false,
  },
  {
    type: AdvancedType.IMAGE,
    payload: { attributes: { padding: '0px 0px 0px 0px' } },
    requireFullAccess: true,
  },
  {
    type: AdvancedType.BUTTON,
    payload: {
      attributes: {
        align: 'center',
        'background-color': '#01d49c',
        color: '#ffffff',
        'font-weight': 'normal',
        'border-radius': '20px',
        padding: '10px 25px 10px 25px',
        'inner-padding': '12px 50px 12px 50px',
        'line-height': '120%',
        target: '_blank',
        'vertical-align': 'middle',
        border: '#6dc2ba',
        'text-align': 'center',
        href: '#',
        'letter-spacing': '0',
      },
    },
    requireFullAccess: true,
  },
  { type: AdvancedType.DIVIDER, payload: { attributes: {} }, requireFullAccess: true },
  { type: AdvancedType.SPACER, payload: { attributes: {} }, requireFullAccess: true },
  { type: AdvancedType.WRAPPER, payload: { attributes: {} }, requireFullAccess: true },
  ...[
    CustomType.GOOGLE_WALLET_ONLINE_DE,
    CustomType.GOOGLE_WALLET_ONLINE_EN,
    CustomType.GOOGLE_WALLET_OFFLINE_DE,
    CustomType.GOOGLE_WALLET_OFFLINE_EN,
  ].map(type => ({
    type,
    payload: { attributes: {} },
    allowedTemplates: [
      EmailTemplatesEnum.WALLET_PASS_MAIL_TEMPLATE,
      EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE,
      EmailTemplatesEnum.ONBOARDING_EMAIL_TEMPLATE,
    ],
    requireFullAccess: false,
  })),
];

const getBlocks = (template: EmailTemplatesEnum, hasFullAccess: boolean) =>
  defaultBlocks
    .filter(
      block =>
        (!block.allowedTemplates || block.allowedTemplates.includes(template)) &&
        (hasFullAccess || !block.requireFullAccess),
    )
    .map(block => ({
      ...block,
      payload: {
        ...block.payload,
        attributes: { ...block.payload.attributes, ...defaultAttributes },
      },
    }));

export const getCategories = (
  template: EmailTemplatesEnum,
  hasFullAccess: boolean,
  t: TFunction,
): ExtensionProps['categories'] => {
  return [
    {
      label: t('emailTemplates.content'),
      active: true,
      blocks: getBlocks(template, hasFullAccess),
    },
    {
      label: t('emailTemplates.layout'),
      active: true,
      displayType: 'column',
      blocks: [
        {
          title: `1 ${t('emailTemplates.column')}`,
          payload: [['100%']],
        },
        {
          title: `2 ${t('emailTemplates.columns')}`,
          payload: [
            ['50%', '50%'],
            ['33%', '67%'],
            ['67%', '33%'],
            ['25%', '75%'],
            ['75%', '25%'],
          ],
        },
        {
          title: `3 ${t('emailTemplates.columns')}`,
          payload: [
            ['33.33%', '33.33%', '33.33%'],
            ['25%', '25%', '50%'],
            ['50%', '25%', '25%'],
          ],
        },
        {
          title: `4 ${t('emailTemplates.columns')}`,
          payload: [['25%', '25%', '25%', '25%']],
        },
      ],
    },
  ];
};

export const locales = [
  { value: 'standard', tName: 'standardLocale' },
  { value: 'en', tName: 'enLocale' },
  { value: 'de', tName: 'deLocale' },
];

export const emailTemplateMergeTags = {
  [EmailTemplatesEnum.CONTACT_SHARE_EMAIL_TEMPLATE]: [
    'NAME',
    'VCARD_LINK',
    'PROFILE_PIC_URL',
    'ACCOUNT_PUBLIC_URL',
    'COMPANY',
    'POSITION',
    'EMAIL',
    'PHONE_MOBILE',
    'PHONE_WORK',
    'ADDRESSEE',
    'ADDRESS_LINE_1',
    'ADDRESS_LINE_2',
    'CITY',
    'POSTCODE',
    'COUNTRY',
    'CONTACT_FIRST_NAME',
    'CONTACT_LAST_NAME',
  ],
  [EmailTemplatesEnum.QR_BG_COMPLETE_EMAIL_TEMPLATE]: [
    //empty
    // templating contains loop -> cannot be customized in fe
    // -> template will be removed soon
  ],
  [EmailTemplatesEnum.GENERIC_STATUS_EMAIL]: ['TEXT'],
  [EmailTemplatesEnum.QR_COMPLETE_EMAIL_TEMPLATE]: [
    //empty
    // templating contains loop -> cannot be customized in fe
    // -> template will be removed soon
  ],
  [EmailTemplatesEnum.CONTACT_RECEIVED_EMAIL_TEMPLATE]: [
    'RECEIVER_NAME',
    'LEAD_NAME',
    'LEAD_EMAIL',
    'LEAD_URL',
  ],
  [EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE]: [
    'firstName',
    'lastName',
    'company',
    'SET_PASSWORD_URL',
    'MAIN_ACCOUNT_EMAIL',
    'LOGIN_URL',
  ],
  [EmailTemplatesEnum.CARD_ORDER_CONFIRMATION_EMAIL_TEMPLATE]: [
    'firstName',
    'lastName',
    'amountCards',
  ],
  [EmailTemplatesEnum.REFERRAL_EMAIL_TEMPLATE]: [
    'REFERRER_FIRSTNAME',
    'REFERRER_LASTNAME',
    'REFERRAL_URL',
  ],
  [EmailTemplatesEnum.REFERRAL_REFERRER_TEMPLATE]: [
    'REFERRER_FIRSTNAME',
    'RECIPIENT_EMAIL',
    'CREDIT',
    'REFERRAL_URL',
  ],
  [EmailTemplatesEnum.REFERRAL_RECEPIENT_TEMPLATE]: [
    'RECIPIENT_FIRSTNAME',
    'CREDIT',
    'REFERRAL_URL',
  ],
  [EmailTemplatesEnum.WALLET_PASS_MAIL_TEMPLATE]: [
    // '<%= passUrl.online %>',
    // '<%= passUrl.offline %>',
    // '<%= account.firstName %>',
    'FIRST_NAME',
    'LAST_NAME',
  ],
  [EmailTemplatesEnum.ONBOARDING_EMAIL_TEMPLATE]: ['FIRST_NAME', 'LAST_NAME'],
};

export const emailTemplateAttachments = {
  [EmailTemplatesEnum.WALLET_PASS_MAIL_TEMPLATE]: [
    EmailTemplateAttachments.OFFLINE_APPLE_WALLET,
    EmailTemplateAttachments.ONLINE_APPLE_WALLET,
  ],
  [EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE]: [
    EmailTemplateAttachments.OFFLINE_APPLE_WALLET,
    EmailTemplateAttachments.ONLINE_APPLE_WALLET,
  ],
  [EmailTemplatesEnum.ONBOARDING_EMAIL_TEMPLATE]: [
    EmailTemplateAttachments.OFFLINE_APPLE_WALLET,
    EmailTemplateAttachments.ONLINE_APPLE_WALLET,
  ],
};

export const defaultFonts = [
  {
    href: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=PT+Sans:ital@0;1&display=swap',
    name: "'Open Sans', sans-serif",
  },
  {
    href: 'https://fonts.googleapis.com/css?family=Poppins&display=swap',
    name: "'Poppins', sans-serif",
  },
  {
    href: '',
    name: 'sans-serif',
  },
  {
    href: '',
    name: 'serif',
  },
];

export const defaultContent = {
  data: {
    value: {
      fonts: defaultFonts,
      'font-size': '14px',
      breakpoint: '480px',
      headStyles: [],
      responsive: true,
      'text-color': '#000000',
      'font-family': "'Poppins', sans-serif",
      'font-weight': '400',
      'line-height': '1.7',
      headAttributes: '',
    },
  },
  type: 'page',
  children: [
    {
      data: {
        value: {},
      },
      type: 'advanced_wrapper',
      children: [
        {
          data: {
            value: {},
          },
          type: 'advanced_image',
          children: [],
          attributes: {
            src: 'http://cdn.mcauto-images-production.sendgrid.net/b355230ae5e80943/4935e0d0-205e-4f14-a309-9be837790f08/980x113.png',
            align: 'center',
            width: '240px',
            height: 'auto',
            padding: '0px 0px 0px 0px',
          },
        },
      ],
      attributes: {
        border: 'none',
        padding: '20px 0px 20px 0px',
        direction: 'ltr',
        'text-align': 'center',
        'background-color': 'white',
      },
    },
    {
      data: {
        value: {},
      },
      type: 'advanced_wrapper',
      children: [],
      attributes: {
        border: 'none',
        padding: '20px 0px 20px 0px',
        direction: 'ltr',
        'text-align': 'center',
        'background-color': 'white',
      },
    },
    {
      data: {
        value: {},
      },
      type: 'advanced_wrapper',
      children: [
        {
          data: {
            value: {},
          },
          type: 'advanced_image',
          children: [],
          attributes: {
            src: 'http://cdn.mcauto-images-production.sendgrid.net/b355230ae5e80943/fb18cb96-58d1-4ad1-9002-f45a2c9ac242/939x243.png',
            align: 'center',
            height: 'auto',
            padding: '0px 25px 0px 25px',
          },
        },
        {
          data: {
            value: {},
          },
          type: 'advanced_divider',
          children: [],
          attributes: {
            align: 'center',
            padding: '0px 0px 0px 0px',
            'border-style': 'solid',
            'border-width': '1px',
          },
        },
        {
          data: {
            value: {
              content: 'Lemontaps - Your digital &amp; sustainable business card',
            },
          },
          type: 'advanced_text',
          children: [],
          attributes: {
            align: 'center',
            color: '#d0d0d0',
            padding: '10px 25px 0px 25px',
            'font-size': '11px',
            'font-family': 'Chivo',
          },
        },
        {
          data: {
            value: {
              content: 'Copyright © 2023 Lemon Innovation &amp; Technology GmbH',
            },
          },
          type: 'advanced_text',
          children: [],
          attributes: {
            align: 'center',
            color: '#d0d0d0',
            padding: '0px 25px 0px 25px',
            'font-size': '11px',
            'font-family': 'Chivo',
          },
        },
        {
          data: {
            value: {
              content: 'Seyfferstraße 34, 70197, Stuttgart, Germany',
            },
          },
          type: 'advanced_text',
          children: [],
          attributes: {
            align: 'center',
            color: '#d0d0d0',
            padding: '0px 25px 0px 25px',
            'font-size': '11px',
            'font-family': 'Chivo',
          },
        },
        {
          data: {
            value: {
              content: 'Local Court of Stuttgart HRB 778893',
            },
          },
          type: 'advanced_text',
          children: [],
          attributes: {
            align: 'center',
            color: '#d0d0d0',
            padding: '0px 25px 0px 25px',
            'font-size': '11px',
            'font-family': 'Chivo',
          },
        },
        {
          data: {
            value: {
              content: 'Unsubscribe',
            },
          },
          type: 'advanced_text',
          children: [],
          attributes: {
            align: 'center',
            color: '#d0d0d0',
            padding: '10px 25px 0px 25px',
            'font-family': '',
          },
        },
      ],
      attributes: {
        border: 'none',
        padding: '0px 0px 20px 0px',
        direction: 'ltr',
        'text-align': 'center',
        'background-color': 'white',
      },
    },
  ],
  attributes: {
    width: '600px',
    'background-color': '#F1F1F1',
  },
};

export const toolbarStyles = {
  '.MuiFormControl-root': {
    height: '100%',
  },
  '.MuiButton-root': {
    height: '100%',
  },
};

// templating for test emails is done in fe currently
// -> cannot be done for certain email types
export const TEST_EMAIL_ALLOWED_TEMPLATES = [
  EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE,
  EmailTemplatesEnum.CONTACT_RECEIVED_EMAIL_TEMPLATE,
  EmailTemplatesEnum.CONTACT_SHARE_EMAIL_TEMPLATE,
];

export const EMAIL_EDITOR_INITIALIZED_EVENT = 'email-editor-initialized';
