import { ProfileEditProps } from '@/components/Profile/types';
import { Box, Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditLogo from './edit';

interface Props {
  logoImageUrl: string | null;
  editProps?: ProfileEditProps['logoImageEditProps'];
}

export default function Logo({ logoImageUrl, editProps }: Props) {
  const isEdit = !!editProps;

  const { t } = useTranslation();

  if (isEdit) {
    if (logoImageUrl) {
      return (
        <DashedWrapper component={Card}>
          <LogoImage component='img' src={logoImageUrl} alt={t('ariaLogoImage')} />
          <EditWrapper>
            <EditLogo {...editProps} />
          </EditWrapper>
        </DashedWrapper>
      );
    } else {
      return (
        <Wrapper>
          <EditLogo {...editProps} />
        </Wrapper>
      );
    }
  }

  if (!logoImageUrl) return null;

  return (
    <Wrapper>
      <LogoImage component='img' src={logoImageUrl} alt={t('ariaLogoImage')} />
    </Wrapper>
  );
}

const Wrapper: typeof Box = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'baseline',
  padding: '3px 5px',
  overflow: 'visible',
  [theme.breakpoints.down('md')]: {
    height: theme.spacing(4),
    padding: '1.5px 2.5px',
  },
}));

const DashedWrapper = styled(Wrapper)({
  borderStyle: 'dashed',
});

const LogoImage: typeof Box = styled(Box)(({ theme }) => ({
  height: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
}));

const EditWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-2),
  right: theme.spacing(-2),
}));
