import { useEffect, useRef, useState } from 'react';
import { APIError } from '../../../shared/types/api';
import CreditsCard from './RedeemCard';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { CREDITS_REWARDS_CLIENT } from './constants';
import { CREDITS_REWARDS, ERROR_KEYS } from '../../../shared/constants';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../../config/config';
import axios from 'axios';
import useDeferredLoader from '../../../infrastructure/hooks/useDeferredLoader';
import withNav from '../../../infrastructure/hoc/withNav';
import { routePaths } from '../../../infrastructure/constants';
import useTierInfo from '../../../infrastructure/hooks/useTierInfo';
import { useHistory } from 'react-router-dom';
import { PageContainer } from '@/components';
import { Box, Button, Grid, Link, TextField, Typography, styled } from '@mui/material';
import { CardRenderer } from '@/components/CardRenderer';
import TooltipInfo from '@/components/TooltipInfo';
import { Euro, FileCopyOutlined, LinkedIn } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const Referral = () => {
  const { t, activeLanguage } = useAppTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();

  const [balance, setBalance] = useState<number>(0);
  const [referralEmail, setReferralEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [copyTooltipVisible, setCopyTooltipVisible] = useState(false);

  useDeferredLoader(loading, 'referrals-loading-toast');

  const { isTrial, isStarter } = useTierInfo();

  const history = useHistory();

  const referralUrl = useRef(null);

  useEffect(() => {
    if (!isStarter && !isTrial) {
      history.push(routePaths.HOME);
    }
  }, [history, isStarter, isTrial]);

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `account/balance/credit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [],
      () => setLoading(true),
    )
      .then((res: { data: { isSuccess: boolean; data?: { balance: number } } }) => {
        if (res.data?.isSuccess) {
          let result = res.data;
          setBalance(result.data.balance);
        }
      })
      .catch(err => {
        toast.error(t('error.general'));
      })
      .finally(() => setLoading(false));
  }, [getAccessTokenSilently, t, toast]);

  const generateReferralLink = () => {
    if (!referralUrl.current) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `account/referral/key`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [],
        () => setLoading(true),
      )
        .then((res: { data: { isSuccess: boolean; data?: { referralUrl: string } } }) => {
          if (res.data?.isSuccess) {
            let result = res.data;
            referralUrl.current = result.data.referralUrl;
            // copy link to clipboard
            navigator.clipboard.writeText(result.data.referralUrl);
            handleSuccessCopyClick();
          }
        })
        .catch(err => {
          toast.error(t('error.general'));
        })
        .finally(() => setLoading(false));
    } else {
      navigator.clipboard.writeText(referralUrl.current);
      handleSuccessCopyClick();
    }
  };

  const handleSuccessCopyClick = () => {
    setCopyTooltipVisible(true);
    setTimeout(() => {
      setCopyTooltipVisible(false);
    }, 2000);
  };

  const handleSendEmail = (onSuccess?: () => void) => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.post(
          config.API_BASE_URL + `account/referral/key`,
          { email: referralEmail },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [],
      () => setLoading(true),
    )
      .then((res: { data: { isSuccess: boolean } }) => {
        if (res.data?.isSuccess) {
          onSuccess?.();
          toast.success(t('referrals.toast.emailSuccess'));
        }
      })
      .catch((err: APIError) => {
        const errData: { notValidEmail?: boolean } = err.response.data?.error?.data;
        if (errData.notValidEmail) {
          toast.error(t('referrals.toast.emailError'));
        } else if (err?.response?.data?.error?.code === ERROR_KEYS.DISPOSABLE_EMAIL) {
          // disposable email
          toast.error(t('error.disposableEmail'));
        } else {
          toast.error(t('error.general'));
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <PageContainer maxWidth='m'>
      <Box display='flex' flexDirection='column' gap={4}>
        <Box>
          <Typography variant='body1'>{t('referrals.subtext')}</Typography>
        </Box>
        <CardRenderer>
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' alignItems='center'>
              <Typography variant='body1'>{t('referrals.balance.title')}</Typography>
              <TooltipInfo text={t('referrals.balance.tooltip')} />
            </Box>
            <Box display='flex' alignItems='center' gap={0.5}>
              <Typography variant='h1'>{balance}</Typography>
              <Euro sx={{ fontSize: 15 }} />
            </Box>
          </Box>
        </CardRenderer>

        <Box display='flex' flexDirection='column' gap={1}>
          <Typography variant='h1'>{t('referrals.redeem.title')}</Typography>
          <Box display='flex' sx={{ overflowX: 'auto', pb: 1 }}>
            {CREDITS_REWARDS.map(card => (
              <CreditsCard
                imageSrc={CREDITS_REWARDS_CLIENT[card.key].image}
                price={card.credit}
                title={
                  CREDITS_REWARDS_CLIENT[card.key].text[
                    activeLanguage.startsWith('en') ? 'en' : 'de'
                  ].title
                }
                subTitle={
                  CREDITS_REWARDS_CLIENT[card.key].text[
                    activeLanguage.startsWith('en') ? 'en' : 'de'
                  ]?.subtitle
                }
                buttonText={t('referrals.redeem.button')}
                balance={balance}
              />
            ))}
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography variant='h1'>{t('referrals.collect.title')}</Typography>
          <StyledWrapper>
            <CardRenderer>
              <Box display='flex' flexDirection='column' gap={2} justifyContent='center'>
                <Box display='flex' alignItems='center'>
                  <Typography variant='h1'>{t('referrals.collect.email.title')}</Typography>
                  <TooltipInfo text={t('referrals.collect.email.tooltip')} arrow />
                </Box>
                <Typography variant='body1'>
                  <Trans
                    i18nKey='referrals.collect.email.subtext'
                    components={[<Box component='span' sx={{ fontWeight: '600' }} />]}
                  />
                </Typography>
                <Grid container gap={1}>
                  <TextField
                    name='email'
                    value={referralEmail}
                    onChange={e => setReferralEmail(e.target.value)}
                    placeholder={t('referrals.collect.email.placeholder')}
                    sx={{ minWidth: '60%' }}
                  />
                  <Button size='large' onClick={() => handleSendEmail(() => setReferralEmail(''))}>
                    {t('referrals.collect.email.button')}
                  </Button>
                </Grid>
                <Box>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={generateReferralLink}
                    startIcon={
                      <TooltipInfo text={t('copied')} placement='top' open={copyTooltipVisible}>
                        <FileCopyOutlined />
                      </TooltipInfo>
                    }
                    size='large'
                  >
                    {t('referrals.collect.email.copyButton')}
                  </Button>
                </Box>
              </Box>
            </CardRenderer>
            <CardRenderer>
              <Box
                display='flex'
                flexDirection='column'
                gap={2}
                height='100%'
                justifyContent='center'
              >
                <Box display='flex' alignItems='center' gap={1}>
                  <LinkedIn sx={{ fontSize: 30 }} />
                  <Typography variant='h1'>{t('referrals.collect.linkedin.title')}</Typography>
                </Box>
                <Typography variant='body1'>
                  <Trans
                    i18nKey='referrals.collect.linkedin.subtext'
                    components={[
                      <Box component='span' sx={{ fontWeight: '600' }} />,
                      <br />,
                      <Link
                        href='mailto:contact@lemontaps.com'
                        target='_blank'
                        rel='noreferrer'
                        sx={{
                          color: 'text.primary',
                          ':hover, :visited, :active': {
                            color: 'text.primary',
                          },
                          fontWeight: '600',
                        }}
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            </CardRenderer>
          </StyledWrapper>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withNav(
  Referral,
  {
    tTitle: 'referrals.heading',
    showBackButton: {
      linkTo: routePaths.HOME,
    },
  },
  {},
);

const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: '1fr',
  },
}));
