import { debounce } from '@/utils/debounce';
import { Box, Button, styled } from '@mui/material';
import { useRef } from 'react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  ariaLabel?: string;
};

const ColorDot = ({ bg }) => {
  return (
    <Box
      sx={{
        marginRight: '1rem',
        padding: '2px',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '1.6rem',
          height: '1.6rem',
          borderRadius: '50%',
          backgroundColor: bg,
          outline: '1px solid black',
          outlineOffset: '2px',
        }}
      />
    </Box>
  );
};

const ColorPicker = ({ value, onChange, ariaLabel }: Props) => {
  const inputRef = useRef(null);

  const handleChange = debounce((value: string) => {
    onChange(value);
  }, 100);
  return (
    <Box position='relative'>
      <Button
        sx={{ width: '15rem', display: 'flex', justifyContent: 'space-between' }}
        variant='outlined'
        color='primary'
        startIcon={<ColorDot bg={value} />}
        onClick={() => inputRef?.current?.click()}
        size='large'
        aria-label={ariaLabel}
      >
        {value}
      </Button>
      <Input
        type='color'
        onChange={e => handleChange(e.target.value)}
        value={value}
        ref={inputRef}
      />
    </Box>
  );
};

const Input = styled('input')({
  width: 0,
  height: 0,
  position: 'absolute',
  visibility: 'hidden',
  opacity: 0,
  pointerEvents: 'none',
  left: 0,
  bottom: 0,
});

export default ColorPicker;
