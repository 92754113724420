import { ProfileEditProps } from '@/components/Profile/types';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import UrlButtonItem from './UrlButtonItem';
import {
  DeleteOutline,
  EditOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import LtSorter from '@/components/LtSorter';
import LtVideo from '@/components/LtVideo';
import NoteIcon from '@mui/icons-material/Note';

type Props = Pick<
  ProfileEditProps['filesBoxEditProps'],
  | 'files'
  | 'onDelete'
  | 'onToggleDisplay'
  | 'onReorder'
  | 'standardFilesAfter'
  | 'standardFilesBefore'
  | 'genericFileBoxColor'
>;

export default function FilesEdit({
  files,
  onReorder,
  onToggleDisplay,
  onDelete,
  standardFilesBefore,
  standardFilesAfter,
  genericFileBoxColor,
}: Props) {
  const { t } = useTranslation();
  return (
    <Stack direction='column' spacing={1.6}>
      {standardFilesBefore && (
        <Stack direction='column' spacing={1.6}>
          {standardFilesBefore.map(item => (
            <Stack direction='row' alignItems='center' spacing={0.5}>
              {item.embed ? (
                <LtVideo key={item.id} embedUrl={item.link} label={item.fileName} />
              ) : (
                <UrlButtonItem
                  key={item.id}
                  renderAs='link'
                  backgroundCss={genericFileBoxColor}
                  iconUrl=''
                  icon={<NoteIcon />}
                  url={item.link}
                  text={item.fileName}
                  meta={{ itemId: item.id }}
                  disabled
                  ariaHidden
                />
              )}
              <Tooltip enterDelay={500} title={t('explainThemeFile')}>
                <span>
                  <IconButton
                    size='small'
                    aria-label={t('ariaThemeLinkFileEdit', { name: item.fileName })}
                    disabled
                  >
                    <EditOutlined />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
      )}
      {files && (
        <LtSorter
          items={files}
          getItemAriaLabel={item => `${t('ariaProfileFile')}: ${item.fileName}`}
          getItemKey={item => item.id}
          renderer={item => (
            <Stack direction='row' alignItems='center' spacing={0.5}>
              <Box flex={1}>
                {item.embed ? (
                  <LtVideo key={item.id} embedUrl={item.link} label={item.fileName} />
                ) : (
                  <UrlButtonItem
                    renderAs='link'
                    backgroundCss={genericFileBoxColor}
                    iconUrl=''
                    icon={<NoteIcon />}
                    url={item.link}
                    text={item.fileName}
                    meta={{ itemId: item.id }}
                    disabled
                    ariaHidden
                  />
                )}
              </Box>
              <Tooltip
                enterDelay={500}
                title={item.canDisplayOnProfile ? t('hideMessageFile') : t('showMessageFile')}
              >
                <IconButton
                  size='small'
                  onClick={() => onToggleDisplay(item.id, !item.canDisplayOnProfile)}
                  aria-label={
                    item.canDisplayOnProfile
                      ? t('ariaHideMessage', { name: item.fileName })
                      : t('ariaShowMessage', { name: item.fileName })
                  }
                >
                  {item.canDisplayOnProfile ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </Tooltip>
              <Tooltip enterDelay={500} title={t('deleteFile')}>
                <IconButton
                  size='small'
                  onClick={() => onDelete(item)}
                  aria-label={t('ariaDeleteMessage', { name: item.fileName })}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          onEnd={onReorder}
        />
      )}
      {standardFilesAfter && (
        <Stack direction='column' spacing={1.6}>
          {standardFilesAfter.map(item => (
            <Stack direction='row' alignItems='center' spacing={0.5}>
              {item.embed ? (
                <LtVideo key={item.id} embedUrl={item.link} label={item.fileName} />
              ) : (
                <UrlButtonItem
                  key={item.id}
                  renderAs='link'
                  backgroundCss={genericFileBoxColor}
                  iconUrl=''
                  icon={<NoteIcon />}
                  url={item.link}
                  text={item.fileName}
                  meta={{ itemId: item.id }}
                  disabled
                  ariaHidden
                />
              )}
              <Tooltip enterDelay={500} title={t('explainThemeFile')}>
                <span>
                  <IconButton
                    size='small'
                    aria-label={t('ariaThemeLinkFileEdit', { name: item.fileName })}
                    disabled
                  >
                    <EditOutlined />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
