import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { Account } from '../../shared/types/api';
import { getScimBlockedFields } from '../apis/account';
import { getScimBlockedFieldsByAccountId } from '../apis/md/idp';
import useTierInfo from './useTierInfo';
import { usePrivateFeatureFlag } from './useFeatureFlags';

export const useScimBlockedFields = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { isFeatureAllowed } = useTierInfo();
  const flag_IAM = usePrivateFeatureFlag('iam');
  const isScimAllowed = isFeatureAllowed('profileSync');

  const [blockedFields, setBlockedFields] = useState<Array<keyof Account> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const isFieldBlocked = (field: keyof Account) => {
    return blockedFields?.includes(field);
  };

  useEffect(() => {
    if (!flag_IAM || !isScimAllowed) return;
    const fetchScimBlockedFields = async () => {
      setLoading(true);
      try {
        const { data } = await getScimBlockedFields(getAccessTokenSilently);
        setBlockedFields(data);
      } catch (error) {
        //
      }
      setLoading(false);
    };
    fetchScimBlockedFields();
  }, [getAccessTokenSilently, flag_IAM, isScimAllowed]);

  return { blockedFields, loading, isFieldBlocked };
};

export const useScimBlockedFieldsById = (accountId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureAllowed } = useTierInfo();
  const flag_IAM = usePrivateFeatureFlag('iam');
  const isScimAllowed = isFeatureAllowed('profileSync');

  const [blockedFields, setBlockedFields] = useState<Array<keyof Account> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const isFieldBlocked = (field: keyof Account) => {
    return blockedFields?.includes(field);
  };

  useEffect(() => {
    if (!flag_IAM || !isScimAllowed) return;
    const fetchScimBlockedFields = async () => {
      setLoading(true);
      try {
        const { data } = await getScimBlockedFieldsByAccountId(getAccessTokenSilently, accountId);
        setBlockedFields(data);
      } catch (error) {
        //
      }
      setLoading(false);
    };
    fetchScimBlockedFields();
  }, [getAccessTokenSilently, accountId, flag_IAM, isScimAllowed]);

  return { blockedFields, loading, isFieldBlocked };
};
