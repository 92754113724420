import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { appendParamsToLink } from '@/shared/util';
import { ShareType, getShareLink } from './shareLink';
import { LinkedIn, MailOutline, Share, WhatsApp } from '@mui/icons-material';
import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';

const sharingItems: Array<{
  icon: React.ReactNode;
  shareType?: ShareType;
  ariaLabel: string;
}> = [
  {
    icon: (
      <WhatsApp
        sx={{
          color: 'white',
          backgroundColor: '#45D354',
          fontSize: 40,
          borderRadius: 0.5,
          p: 0.5,
        }}
      />
    ),
    shareType: 'whatsapp',
    ariaLabel: 'ariaShareWhatsApp',
  },
  {
    icon: (
      <LinkedIn
        sx={{
          color: '#0177B5',
          fontSize: 54,
          borderRadius: 0.5,
        }}
      />
    ),
    shareType: 'linkedin',
    ariaLabel: 'ariaShareLinkedIn',
  },
  {
    icon: (
      <MailOutline
        sx={{
          color: '#fff',
          backgroundColor: 'text.primary',
          borderRadius: 0.5,
          fontSize: 40,
          p: 0.5,
        }}
      />
    ),
    shareType: 'mail',
    ariaLabel: 'ariaShareEmail',
  },
  {
    icon: (
      <Share
        sx={{
          color: '#fff',
          backgroundColor: 'secondary.main',
          borderRadius: 0.5,
          fontSize: 40,
          p: 0.5,
          ml: 0.7,
        }}
      />
    ),
    shareType: 'webShare',
    ariaLabel: 'ariaWebShare',
  },
];

type Props = BoxProps & { profileUrl: string };

const ShareBar = (props: Props) => {
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();
  const { desktopView } = useDesktopView();

  const navigatorShare = async () => {
    try {
      await navigator.share({
        url: appendParamsToLink(props.profileUrl, { utm_medium: 'emailshare' }),
        title: t('hereIsMyContact'),
        text: t('myContact'),
      });
    } catch (error) {
      if (!error.name.startsWith('AbortError')) toast.error(t('errorSharingData'));
    }
  };

  return (
    <Box {...props}>
      <Typography variant={desktopView ? 'body2' : 'h3'}>
        {t(`shareProfile.profileLink.shareVia${desktopView ? '' : 'Media'}`)}
      </Typography>
      <Box display={'flex'} alignItems={'center'} mt={1} gap={1}>
        {sharingItems.map((item, index) => {
          if (item.shareType === 'webShare') {
            if (!navigator.share) {
              return null;
            }
            return (
              <Box
                component='button'
                sx={{ cursor: 'pointer' }}
                key={index}
                onClick={navigatorShare}
                aria-label={t(item.ariaLabel)}
              >
                {item.icon}
              </Box>
            );
          }

          return (
            <Box
              component='a'
              href={getShareLink(item.shareType, props.profileUrl)}
              key={index}
              aria-label={t(item.ariaLabel)}
            >
              {item.icon}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShareBar;
