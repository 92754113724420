import { MutableRefObject, useEffect } from 'react';

const useEmbedScript = (
  url: string,
  ref?: MutableRefObject<HTMLElement>,
  id?: string,
  crossOrigin?: string,
  callback?: () => void,
) => {
  useEffect(() => {
    if (ref?.current) {
      const script = document.createElement('script');
      script.src = url;
      if (id) script.id = id;
      if (crossOrigin) script.crossOrigin = crossOrigin;
      script.async = true;
      ref.current!.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }
  }, [callback, crossOrigin, id, ref, url]);
};

export default useEmbedScript;
