import { dispatchTypes } from './index';
import { resendVerificationEmail as resendVerificationEmailApi } from '../../infrastructure/apis/verification';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import i18n from '../../config/i18n';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const resendVerificationEmail =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) => async dispatch => {
    dispatch({ type: dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL });
    try {
      let response = await resendVerificationEmailApi(getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL_SUCCESS,
          apiResponse: result,
        });
      } else {
        dispatch({
          type: dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL_ERROR,
          apiResponse: result,
        });
        useNotificationsStore.getState().notify.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({
        type: dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL_ERROR,
        apiResponse: error,
      });
      useNotificationsStore.getState().notify.error(i18n.t('error.general'));
    }
  };
