import { useEffect, useState } from 'react';
import { resendVerificationEmail } from '../../../application/actions/verification';

import { useAuth0 } from '@auth0/auth0-react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { isEmptyObject, withSilentAccessToken } from '../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../config/config';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { Box, Button, ThemeProvider, Typography, styled } from '@mui/material';
import { EditOutlined, MailOutlined, ReplayOutlined, West } from '@mui/icons-material';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import lemon from '@/views/images/onboarding/image-3.png';
import lemonTapsLogo from '@/views/images/lemontaps-logo.png';
import { PageContainer } from '@/components';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import Loader from '@/components/Loader';

const VerifyEmail = (): JSX.Element => {
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently, user, logout } = useAuth0();
  const { theme: muiTheme, globalStyles } = useMuiTheme();
  const { t } = useTranslation();
  const { desktopView } = useDesktopView();
  const dispatch = useAppDispatch();

  const { apiResponse, isLoading: resendEmailLoading } = useAppSelector(
    state => state.verification,
  );

  const [unlinkCodesLoading, setUnlinkCodesLoading] = useState<boolean>();

  useEffect(() => {
    if (apiResponse?.isSuccess) toast.success(t('verification.sendSuccess'));
    else if (!isEmptyObject(apiResponse) && !apiResponse?.isSuccess)
      toast.error(t('verification.sendError'));
  }, [apiResponse, t, toast]);

  const handleSendEmail = () => {
    dispatch(resendVerificationEmail(getAccessTokenSilently));
  };

  const handleChangeEmail = async () => {
    setUnlinkCodesLoading(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + 'account/unlink-all-codes',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
      .then(res => {
        let resData = res.data;
        if (resData.isSuccess && resData.data?.codes.length > 0) {
          logout({
            returnTo: `${window.location.origin}/login?signup=true&code=${resData.data?.codes[0]}`,
          });
        } else {
          logout({
            returnTo: `${window.location.origin}/login?signup=true`,
          });
        }
      })
      .catch(err => {
        toast.error('error change email');
      })
      .finally(() => setUnlinkCodesLoading(false));
  };

  return (
    <ThemeProvider theme={muiTheme}>
      {globalStyles}
      {(resendEmailLoading || unlinkCodesLoading) && <Loader />}
      <PageContainer
        maxWidth='s'
        leftSideSheet={{
          bgImg: lemon,
          logoImg: lemonTapsLogo,
        }}
        topActionBar={{
          renderCard: false,
          position: 'relative',
          customActionsPre: [
            {
              variant: 'text',
              startIcon: <West />,
              text: t('verification.back'),
              onClick: () =>
                logout({
                  returnTo: `${window.location.origin}/login`,
                }),
              sx: { mr: 'auto' },
            },
          ],
        }}
      >
        <Box width='100%' height='100%' display='flex' flexDirection='column' gap='4rem'>
          <Box>
            <Box textAlign='center' mb='2rem'>
              <MailOutlined sx={{ fontSize: '6rem', color: 'primary.main' }} />
              <Typography variant='h1'>{t('verification.verifyEmail')}</Typography>
            </Box>
            <Box textAlign='center'>
              <Typography variant='body2'>
                <Trans
                  i18nKey='verification.subHeader'
                  values={{
                    email: user.email,
                  }}
                  components={[<span style={{ fontWeight: '600' }} />, <br />]}
                />
              </Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems={'center'}>
            <StyledActionRow desktopView={desktopView}>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 1 }}>
                {t('verification.notReceived')}
              </Typography>
              <Button variant='text' startIcon={<ReplayOutlined />} onClick={handleSendEmail}>
                {t('verification.resendEmail')}
              </Button>
            </StyledActionRow>
            <StyledActionRow desktopView={desktopView}>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 1 }}>
                {t('verification.wrongEmail')}
              </Typography>
              <Button variant='text' startIcon={<EditOutlined />} onClick={handleChangeEmail}>
                {t('verification.changeEmail')}
              </Button>
            </StyledActionRow>
          </Box>
        </Box>
      </PageContainer>
    </ThemeProvider>
  );
};

export default VerifyEmail;

const StyledActionRow = styled(Box)<{ desktopView: boolean }>(({ desktopView }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: desktopView ? 'space-between' : 'center',
  width: desktopView ? '75%' : '100%',
}));
