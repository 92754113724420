import { DeleteOutline } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type CardType = { date: string | null; code: string };

export const SingleCard = ({
  card,
  isLast,
  onDelete,
}: {
  card: CardType;
  isLast?: boolean;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        bgcolor='white'
        borderBottom={!isLast && '1px solid'}
        borderColor='divider'
        px='1.6rem'
        py='1.2rem'
        display='flex'
        alignItems='center'
      >
        <Box flex='1'>
          <Typography variant='body2'>{t('cards.card', { code: card.code })}</Typography>
          <Typography variant='body2' color={theme => theme.palette.text.secondary}>
            {card.date ? moment(card.date).format('LLL') : t('cards.nodate')}
          </Typography>
        </Box>
        <IconButton
          onClick={onDelete}
          aria-haspopup='dialog'
          aria-label={t('ariaDeleteNfcCard', { card: card.code })}
        >
          <DeleteOutline />
        </IconButton>
      </Box>
    </>
  );
};
