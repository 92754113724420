import { Box, BoxProps, Divider, Typography } from '@mui/material';
import { TooltipText } from '../customization/TooltipText';

export const SectionRenderer = ({
  label,
  tooltipText,
  children,
  noDivider,
  ...rest
}: {
  label: string;
  tooltipText?: string;
  children: React.ReactNode;
  noDivider?: boolean;
} & BoxProps) => {
  return (
    <Box {...rest}>
      <Box height='4rem'>
        {tooltipText ? (
          <TooltipText label={label} variant='h3' tooltipText={tooltipText} />
        ) : (
          <Typography variant='h3'>{label}</Typography>
        )}
      </Box>
      <Box mt={1.6}>{children}</Box>
      {!noDivider && <Divider sx={{ my: 3.2 }} />}
    </Box>
  );
};
