import { Button, Menu, MenuItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVIcon } from '@/icons/CSVIcon';
import { useTranslation } from 'react-i18next';
import { CrmConnectorType } from '@/shared/types/api';

const StyledImage = styled('img')({
  width: '2.2rem',
  height: '2.2rem',
});

type Props = {
  onCrmExportClick: (serviceId: string, serviceName: string) => void;
  onCsvExportClick?: () => void;
  onExcelExportClick?: () => void;
  crmList: CrmConnectorType[];
};

export const ExportButton = ({
  onCrmExportClick,
  onCsvExportClick,
  onExcelExportClick,
  crmList,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        color='primary'
        variant='outlined'
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        startIcon={<DownloadIcon />}
        sx={{ textTransform: 'none' }}
        aria-label={t('ariaExportSelected')}
      >
        Export
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {onCsvExportClick && (
          <MenuItem
            onClick={() => {
              onCsvExportClick();
              handleClose();
            }}
          >
            <ListItemIcon>
              <CSVIcon />
            </ListItemIcon>
            <ListItemText>{`${t('export.title')} .csv`}</ListItemText>
          </MenuItem>
        )}
        {onExcelExportClick && (
          <MenuItem
            onClick={() => {
              onExcelExportClick();
              handleClose();
            }}
          >
            <ListItemIcon>
              <CSVIcon />
            </ListItemIcon>
            <ListItemText>{`${t('export.title')} .xlsx`}</ListItemText>
          </MenuItem>
        )}
        {crmList?.map(connector => (
          <MenuItem
            onClick={() => {
              onCrmExportClick(connector.id, connector.name);
              handleClose();
            }}
          >
            <ListItemIcon>
              <StyledImage src={connector.iconUrl} alt={connector.name} />
            </ListItemIcon>
            <ListItemText>{connector.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
