import { QRBGMode } from '@/views/pages/qrcode-background/common/types';
import DefaultImage from './DefaultImage';
import AdditionalImages from './AdditionalImages';
import { useEffect } from 'react';
import { useConfig } from '../../useConfig';
import ContentWrapper from '../ContentWrapper';
import { HandlersType } from '../..';

interface Props {
  title: string;
  mode: QRBGMode;
  setHandlers: Setter<HandlersType>;
}

const QRBGContentRenderer: React.FC<Props> = ({ title, mode, setHandlers }) => {
  const {
    defaults,
    additionals,
    handleSave,
    handleReset,
    saveIsLoading,
    resetIsLoading,
    refetch,
    initialFetchIsLoading,
  } = useConfig();

  useEffect(
    () => setHandlers({ handleSave, handleReset, saveIsLoading, resetIsLoading }),
    [handleReset, handleSave, resetIsLoading, saveIsLoading, setHandlers],
  );

  useEffect(() => {
    refetch();
  }, [refetch, mode]);

  return (
    <ContentWrapper title={title}>
      <DefaultImage
        mode={mode}
        defaultImageUrl={defaults[mode]?.image}
        setDefaultImage={defaults[mode]?.setter}
      />
      {!initialFetchIsLoading && (
        <AdditionalImages
          mode={mode}
          additionalImages={additionals[mode]?.images}
          setAdditionalImages={additionals[mode]?.setter}
        />
      )}
    </ContentWrapper>
  );
};

export default QRBGContentRenderer;
