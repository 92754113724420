import {
  _bulkUpdateImage,
  _bulkUpdateInfo,
  _bulkUpdateLinks,
  _bulkUpdateRoles,
  _bulkUploadFiles,
  _bulkUploadImage,
} from '@/infrastructure/apis/md/profiles/bulk';
import { assignUnits } from '@/infrastructure/apis/md/units';
import { ACCOUNT_BIO_FIELDS } from '@/shared/constants';
import { Account, AccountImageState, ApiFile, Link } from '@/shared/types/api';
import { Employee } from '@/shared/types/api/employee.type';
import { Unit } from '@/shared/types/api/unit.type';
import { Auth0ContextInterface } from '@auth0/auth0-react';

export type Field =
  | keyof Account
  | 'files'
  | 'links'
  | 'unit'
  | 'accountRole'
  | 'profileImageUrl'
  | 'bannerImageUrl'
  | 'logoHeaderUrl';

export type UnitValue = {
  mainUnit?: Unit;
  otherUnits?: Unit[];
};

export type ImageValue = {
  url: string;
  file?: File;
  state: AccountImageState;
};

export type FieldValue = string | Link[] | ApiFile[] | UnitValue | ImageValue;

export const BULK_EDIT_ACTION_TYPES = {
  OVERRIDE: 'override',
  DELETE: 'delete',
};

export const TEXT_FIELDS = ACCOUNT_BIO_FIELDS.map(({ id }) => id);
const CUSTOM_FIELDS = [
  'files',
  'links',
  'unit',
  'accountRole',
  'profileImageUrl',
  'bannerImageUrl',
  'logoHeaderUrl',
] as const;
export const SELECTABLE_FIELDS: Field[] = [...TEXT_FIELDS, ...CUSTOM_FIELDS];

export const isTextField = (field: Field) => {
  return TEXT_FIELDS.some(x => x === field);
};

export const isImageField = (field: Field) => {
  return ['profileImageUrl', 'bannerImageUrl', 'logoHeaderUrl'].some(x => x === field);
};

export const isActionChooserAvailable = (field?: Field) => {
  if (!field) return false;
  return isTextField(field) || field === 'unit' || isImageField(field);
};

export const getFieldTranslationKey = (field: Field) => {
  if (isTextField(field)) {
    return ACCOUNT_BIO_FIELDS.find(({ id }) => id === field)?.tname;
  }

  if (field === 'logoHeaderUrl') return 'logo';
  if (field === 'profileImageUrl') return 'profilePicture';
  if (field === 'bannerImageUrl') return 'coverPicture';
  return field;
};

export const isSaveDisabled = (selectedField: Field, value: FieldValue) => {
  if (selectedField === 'accountRole') return false;
  if (!selectedField || !value) return true;
};

const getImageFieldUploadKey = (field: Field) => {
  if (field === 'profileImageUrl') return 'profile';
  if (field === 'bannerImageUrl') return 'banner';
  if (field === 'logoHeaderUrl') return 'logoHeader';
};

export const bulkUpdateSaveHandler = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  selectedEmployees: Employee[],
  selectedField: Field,
  value: FieldValue,
) => {
  const selectedIds = selectedEmployees.map(({ id }) => id);
  const usernames = selectedEmployees.map(({ username }) => username);

  if (isTextField(selectedField)) {
    return await _bulkUpdateInfo(selectedIds, { [selectedField]: value }, getAccessTokenSilently);
  }
  if (selectedField === 'links' && value) {
    return await _bulkUpdateLinks(selectedIds, value as Link[], getAccessTokenSilently);
  }

  if (selectedField === 'files' && value) {
    return await _bulkUploadFiles(
      selectedIds,
      usernames,
      value as ApiFile[],
      getAccessTokenSilently,
    );
  }

  if (selectedField === 'accountRole') {
    return await _bulkUpdateRoles(
      selectedIds,
      [value as string].filter(Boolean),
      getAccessTokenSilently,
    );
  }

  if (selectedField === 'unit') {
    const mainUnitId = (value as UnitValue)?.mainUnit?.id || null;
    const otherUnitIds = ((value as UnitValue)?.otherUnits || []).map(unit => unit.id);
    return await assignUnits(getAccessTokenSilently, selectedIds, mainUnitId, otherUnitIds);
  }

  if (isImageField(selectedField)) {
    if (!value) return;
    const imageValue = value as ImageValue;

    if (imageValue.file) {
      return await _bulkUploadImage(
        selectedIds,
        usernames,
        imageValue.file,
        getImageFieldUploadKey(selectedField),
        imageValue.state,
        getAccessTokenSilently,
      );
    }

    if (imageValue.url || imageValue.state) {
      return await _bulkUpdateImage(
        selectedIds.map(id => ({ id, url: imageValue.url, imageState: imageValue.state })),
        getImageFieldUploadKey(selectedField),
        getAccessTokenSilently,
      );
    }
  }
};
