import { AddEditLinkDialog, LinkBox, LtDialog, LtSorter } from '@/components';
import { saveDefaultLinks } from '@/infrastructure/apis/links';
import { Link, LinkTemplate, Theme } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@/application/hooks';
import { useTranslation } from 'react-i18next';
import { getLinkTemplates } from '@/infrastructure/apis/linkTemplates';
import { SectionRenderer } from '../../SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';

type Props = {
  selectedUnitId?: number | null;
  defaultLinks?: Link[];
  refetchLinks?: () => void;
  mutateDefaultLinks?: (links: Link[]) => void;
};

export const LinksSection = ({
  selectedUnitId,
  defaultLinks,
  mutateDefaultLinks,
  refetchLinks,
}: Props) => {
  const { notify: toast } = useLtNotifications();
  const [addLinkDialogOpened, setAddLinkDialogOpened] = useState(false);
  const [linkIdToDelete, setLinkIdToDelete] = useState<number | null>(null);
  const [linkToEdit, setLinkToEdit] = useState<Link | null>(null);

  const [linkTemplates, setLinkTemplates] = useState<LinkTemplate[]>([]);
  const theme: Theme = useAppSelector(state => state.account.theme);
  const linkTypes = theme.themeLinkTypes?.map(({ linkType }) => linkType) || [];

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const { profileDesign } = useProfileDesignForUnits(selectedUnitId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const linkTemplates = await getLinkTemplates(getAccessTokenSilently);
        setLinkTemplates(linkTemplates);
      } catch {
        toast.error(t('md.defaultContent.errorLoadingLinks', { id: 'default-content-loading' }));
      }
    };

    fetchData();
  }, [getAccessTokenSilently, t, toast]);

  const handleLinkSave = async (links: Link[], shouldRefetch?: boolean) => {
    await saveDefaultLinks(getAccessTokenSilently, links, selectedUnitId);
    if (shouldRefetch) {
      refetchLinks();
    } else {
      mutateDefaultLinks(links);
    }
  };

  const handleLinkAdd = async (link: Link) => {
    setSaving(true);
    const newLinks = [...defaultLinks, link];
    try {
      await handleLinkSave(newLinks, true);
      setAddLinkDialogOpened(false);
      toast.success(t('md.defaultContent.successSaveLink'));
    } catch {
      toast.error(t('md.defaultContent.errorSavingLink'));
    }
    setSaving(false);
  };

  const handleLinkEdit = async (link: Link) => {
    setSaving(true);
    const newLinks = defaultLinks.map(l => (l.id === link.id ? link : l));
    try {
      await handleLinkSave(newLinks);
      setLinkToEdit(null);
      toast.success(t('md.defaultContent.successSaveLink'));
    } catch {
      toast.error(t('md.defaultContent.errorSavingLink'));
    }
    setSaving(false);
  };

  const handleLinkDelete = async () => {
    setDeleting(true);
    const newLinks = defaultLinks.filter(l => l.id !== linkIdToDelete);
    try {
      await handleLinkSave(newLinks);
      setLinkIdToDelete(null);
      toast.success(t('md.defaultContent.successDeleteLink'));
    } catch {
      toast.error(t('md.defaultContent.errorDeleteLink'));
    }
    setDeleting(false);
  };

  const handleSortEnd = (newLinks: Link[]) => {
    mutateDefaultLinks(newLinks);
    handleLinkSave(newLinks);
  };

  return (
    <SectionRenderer
      title={t('md.defaultContent.defaultLinks')}
      btnAriaLabel={t('ariaAddLink')}
      onAdd={() => setAddLinkDialogOpened(true)}
    >
      <LtSorter
        items={defaultLinks}
        getItemAriaLabel={item => `${t('md.defaultContent.defaultLink')}: ${item.name}`}
        getItemKey={item => item.id}
        renderer={link => (
          <LinkBox
            link={link}
            onDelete={() => setLinkIdToDelete(link.id)}
            onEdit={() => setLinkToEdit(link)}
            chips={[t('default'), link.linkTemplateId && t('template')].filter(Boolean)}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
        )}
        onEnd={handleSortEnd}
      />
      {/* Add Link Dialog */}
      <AddEditLinkDialog
        mode='admin'
        linkTypes={linkTypes}
        templates={linkTemplates}
        open={addLinkDialogOpened}
        onClose={() => setAddLinkDialogOpened(false)}
        onSave={handleLinkAdd}
        loading={saving}
        genericWebsiteColor={profileDesign.genericWebsiteColor}
      />
      {/* Edit Link Dialog */}
      <AddEditLinkDialog
        mode='admin'
        linkTypes={linkTypes}
        templates={linkTemplates}
        open={Boolean(linkToEdit)}
        onClose={() => setLinkToEdit(null)}
        onSave={handleLinkEdit}
        initialValues={linkToEdit}
        loading={saving}
        onDelete={() => {
          setLinkToEdit(null);
          setLinkIdToDelete(linkToEdit?.id);
        }}
        genericWebsiteColor={profileDesign.genericWebsiteColor}
      />
      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(linkIdToDelete)}
        onClose={() => setLinkIdToDelete(null)}
        onCancel={() => setLinkIdToDelete(null)}
        onDelete={handleLinkDelete}
        loading={deleting}
      >
        <Typography>{t('deleteMessageLink')}</Typography>
      </LtDialog>
    </SectionRenderer>
  );
};
