import { useAuth0 } from '@auth0/auth0-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Unit } from '@/shared/types/api/unit.type';
import { Employee } from '@/shared/types/api/employee.type';
import { assignUnits } from '@/infrastructure/apis/md/units';
import { Account, ThemeInternal } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { Box, Button } from '@mui/material';
import { fetchUserData } from '@/application/actions/account';
import { useDispatch } from 'react-redux';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { UnitSelectorForProfiles } from '../../common/UnitSelectorForProfiles';

interface Props {
  selectedIds: readonly string[];
  units?: Unit[];
  setLocalEmployees?: Dispatch<SetStateAction<Employee[]>>;
  localEmployees?: Employee[];
  onSaveStart?: () => void;
  onSaveSuccess?: () => void;
}

const RenderUnits = (props: Props) => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently, logout } = useAuth0();
  const selectedEmployees = props.localEmployees?.filter(e => props.selectedIds.includes(e.id));

  const [mainUnit, setMainUnit] = useState<Unit>(null);
  const [otherUnits, setOtherUnits] = useState<Unit[]>([]);

  const { multiUnitsAllowed } = useAppSelector<ThemeInternal>(
    state => state.account.theme.themeInternal,
  );
  const account = useAppSelector<Account>(state => state.account);

  const dispatch = useDispatch();
  const onAssignClick = async () => {
    toast.loading(t('saving'), { id: 'assigningUnit' });
    props.onSaveStart?.();
    assignUnits(
      getAccessTokenSilently,
      props.selectedIds,
      mainUnit?.id,
      multiUnitsAllowed ? otherUnits.map(unit => unit.id) : [],
    )
      .then(res => {
        if (res.data.isSuccess) {
          toast.success(t('saved'), { id: 'assigningUnit' });
          const employeesCopy = [];
          if (props.selectedIds.includes(account.id))
            dispatch(fetchUserData(getAccessTokenSilently, logout));
          props.localEmployees.forEach(employee => {
            const emp = { ...employee };

            if (props.selectedIds.includes(emp.id)) {
              emp.units = res.data.data;
            }
            employeesCopy.push(emp);
          });

          props.setLocalEmployees && props.setLocalEmployees(employeesCopy);
          props.onSaveSuccess?.();
        }
      })
      .catch(e => {
        toast.error(t('mdLeads.AssignUnitError'), { id: 'assigningUnit' });
      });
  };

  return (
    <>
      <UnitSelectorForProfiles
        units={props.units}
        selectedEmployees={selectedEmployees}
        values={{
          selectedMainUnit: mainUnit,
          selectedOtherUnits: otherUnits,
        }}
        onChange={values => {
          setMainUnit(values.selectedMainUnit);
          setOtherUnits(values.selectedOtherUnits);
        }}
      />
      <Box display='flex' justifyContent='center'>
        <Button onClick={onAssignClick}>{t('apply')}</Button>
      </Box>
    </>
  );
};

export default RenderUnits;
