import { useCallback, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';
import config from '../../../../config/config';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import ScannerCamera from '@/components/QRScanner/scanner-camera';
import axios from 'axios';
import useQuery from '../../../../infrastructure/hooks/useQuery';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { Box, ThemeProvider, Typography, styled } from '@mui/material';
import { PageContainer, PublicHeader } from '@/components';
import { routePaths } from '@/infrastructure/constants';
import { CheckCircleOutline } from '@mui/icons-material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const MD2Phone = () => {
  const { t, i18n } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const [scanResult, setScanResult] = useState<QrScanner.ScanResult>(null);
  const [newScan, setNewScan] = useState<boolean>(false);
  const [prevScan, setPrevScan] = useState<string>(null);
  const [nextTry, setNextTry] = useState<boolean>(true);
  const [codeIsChecking, setCodeIsChecking] = useState<boolean>(false);
  const [codeConnected, setCodeConnected] = useState<boolean>(false);

  const scanSetters = { setScanResult, setNewScan };
  const query = useQuery();

  const handleCode = useCallback(
    async (_code: string) => {
      if (_code.length === 10 && query.get('token')) {
        setCodeIsChecking(true);
        await axios
          .get(config.API_BASE_URL + `business/md2phone?token=${query.get('token')}&code=${_code}`)
          .then(res => {
            if (res.data.isSuccess) setCodeConnected(true);
          })
          .catch(error => {
            const errResponse = error.response.data?.error;
            toast.error(errResponse?.message || t('error.general'));
          });
        setCodeIsChecking(false);
      } else {
        toast.error(t('md2Phone.noCodeToken'));
      }
    },
    [query, t, toast],
  );

  useEffect(() => {
    i18n.changeLanguage(query.get('lang')?.substring(0, 2) || 'en');
  }, [i18n, query]);

  useEffect(() => {
    if (scanResult?.data) {
      let qrString = scanResult.data;
      // domain matching
      let domain = config.REACT_APP_URL.split('://')[1];
      let codeToTest = '';
      if (qrString.includes(domain)) {
        let url = new URL(qrString);
        url.pathname.length > 1 && (codeToTest = url.pathname.split('/')[1]);
      }

      if (!codeIsChecking && newScan && (codeToTest !== prevScan || nextTry) && !codeConnected) {
        if (codeToTest.length > 0) {
          handleCode(codeToTest);
          setPrevScan(codeToTest);
        } else {
          toast.error(t('codeRead.missed'));
          setPrevScan('');
        }
        setTimeout(() => {
          setNextTry(true);
        }, 7000);
        setNewScan(false);
      }
      setNextTry(false);
    }
  }, [
    scanResult,
    t,
    prevScan,
    nextTry,
    newScan,
    codeIsChecking,
    handleCode,
    query,
    codeConnected,
    toast,
  ]);

  const { theme } = useMuiTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <PageContainer maxWidth='s'>
          <PublicHeader showLogo={{ linkTo: routePaths.ROOT }} />
          <ContentWrapper>
            {codeConnected ? (
              <>
                <CheckCircleOutline color='success' sx={{ fontSize: 60 }} />
                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                  {t('md2Phone.success')}
                </Typography>
              </>
            ) : (
              <>
                <Box textAlign='center'>
                  <Typography variant='h3' mb={1}>
                    {t('md2Phone.title')}
                  </Typography>
                  <Typography variant='caption'>{t('md2Phone.subTitle')}</Typography>
                </Box>
                <ScannerCamera scanSetters={scanSetters} videoWidth='100%' allowUpload />
              </>
            )}
          </ContentWrapper>
        </PageContainer>
      </ThemeProvider>
    </>
  );
};

export default MD2Phone;

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '3rem',
  height: '100vh',
});
