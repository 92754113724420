import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { SettingsContext } from '../Settings/SettingsWrapper/context';
import { Box, Typography } from '@mui/material';
import SwitchRow from '../edit-rights/SwitchRow';
import { EditRightsFields, FIELDS, SubFields } from '../edit-rights/constants';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ACCOUNT_BIO_FIELDS,
  FEATURE,
  THEME_CONFIG_KEYS,
  THEME_FILE_EDIT_MODES,
  THEME_LINK_EDIT_MODES,
} from '@/shared/constants';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import {
  deleteThemeConfig,
  fetchThemeConfig,
  writeThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import { updateEditRights } from '@/infrastructure/apis/md/customization';
import { fetchThemeLinkTypes } from '@/infrastructure/apis/edit-profile';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { HierarchyLevel } from '@/shared/types/api';
import { SubMenu } from './SubMenu';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

export const EditRightsPage = () => {
  const {
    selectedUnit,
    setSettingsHierarchyLevel,
    deleteButtonIsVisible,
    deleteClickHandler,
    setSettingsLoading,
    settingsHierarchyLevel,
    canEdit,
  } = useContext(SettingsContext);
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();

  const { isUnitAdmin, isThemeAdmin } = useTierInfo();

  const [editRightsFields, setEditRightsFields] = useState<EditRightsFields>();
  const [blockedFields, setBlockedFields] = useState<SubFields>({
    detailsEditable: [],
    linksEditable: [],
  });
  const [defaultFields, setDefaultFields] = useState<SubFields>({
    detailsEditable: ACCOUNT_BIO_FIELDS,
    linksEditable: [],
  });

  const { isFeatureAllowed } = useTierInfo();

  const isBlockedFieldsEditable = useMemo(
    () => ({
      linksEditable: isFeatureAllowed(FEATURE.LINK_TYPE_CONFIG),
      detailsEditable: isFeatureAllowed(FEATURE.BLOCKED_BIO_FIELDS),
    }),
    [isFeatureAllowed],
  );

  const selectedUnitId = selectedUnit?.id || null;

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();

  const fetchEditRightsConfig = useCallback(
    async (hideLoader?: boolean) => {
      if (!hideLoader) setSettingsLoading(true);
      try {
        const [
          { value: _blockedBioFields },
          { value: _blockedLinkTypes },
          {
            value: { linkEditMode, fileEditMode, ..._editRights },
            meta: { hierarchyLevel },
          },
          {
            data: { data: _defaultLinkTypes },
          },
        ] = await Promise.all([
          fetchThemeConfig(getAccessTokenSilently, {
            key: THEME_CONFIG_KEYS.BLOCKED_BIO_FIELDS,
            fetchThemeLevel: true,
            unitId: selectedUnitId,
          }),
          fetchThemeConfig(getAccessTokenSilently, {
            key: THEME_CONFIG_KEYS.BLOCKED_LINK_TYPES,
            fetchThemeLevel: true,
            unitId: selectedUnitId,
          }),
          fetchThemeConfig(getAccessTokenSilently, {
            key: THEME_CONFIG_KEYS.EDIT_RIGHTS,
            fetchThemeLevel: true,
            unitId: selectedUnitId,
          }),
          fetchThemeLinkTypes(getAccessTokenSilently, true),
        ]);
        setDefaultFields(prev => ({
          ...prev,
          linksEditable: _defaultLinkTypes.map(x => ({ id: x.uniqueId, name: x.name })),
        }));
        setBlockedFields(prev => ({ ...prev, detailsEditable: _blockedBioFields }));
        setEditRightsFields({
          ..._editRights,
          linksEditable: linkEditMode !== THEME_LINK_EDIT_MODES.DISALLOW,
          filesEditable: fileEditMode !== THEME_FILE_EDIT_MODES.DISALLOW,
        });
        setSettingsHierarchyLevel(hierarchyLevel);
        setBlockedFields(prev => ({ ...prev, linksEditable: _blockedLinkTypes }));
      } catch (error) {
        toast.error(t('error.general'));
      }
      setSettingsLoading(false);
    },
    [
      getAccessTokenSilently,
      t,
      selectedUnitId,
      setSettingsHierarchyLevel,
      setSettingsLoading,
      toast,
    ],
  );

  useEffect(() => {
    fetchEditRightsConfig();
  }, [fetchEditRightsConfig]);

  const handleChange = (field: keyof typeof FIELDS) => {
    setEditRightsFields(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const { refetch: refetchEditRights } = useEditRights();

  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    const promises = [
      isBlockedFieldsEditable.detailsEditable &&
        writeThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.BLOCKED_BIO_FIELDS,
          value: blockedFields.detailsEditable,
          unitId: selectedUnitId,
        }),
      isBlockedFieldsEditable.linksEditable &&
        writeThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.BLOCKED_LINK_TYPES,
          value: blockedFields.linksEditable,
          unitId: selectedUnitId,
        }),

      updateEditRights(
        { editRights: editRightsFields, unitId: selectedUnitId },
        getAccessTokenSilently,
      ),
    ].filter(Boolean);

    setSaving(true);
    try {
      await Promise.all(promises);
      toast.success(t('changesSaved'));
      fetchEditRightsConfig();
      refetchEditRights();
      setIsUnsaved(false);
    } catch (error) {
      toast.error(t('error.general'));
      return;
    }
    setSaving(false);
  };

  const handleDelete = async () => {
    try {
      const promises = [
        isBlockedFieldsEditable.detailsEditable &&
          deleteThemeConfig(getAccessTokenSilently, {
            key: THEME_CONFIG_KEYS.BLOCKED_BIO_FIELDS,
            unitId: selectedUnitId,
            withDeleteUnitConfig: true,
          }),

        isBlockedFieldsEditable.linksEditable &&
          deleteThemeConfig(getAccessTokenSilently, {
            key: THEME_CONFIG_KEYS.BLOCKED_LINK_TYPES,
            unitId: selectedUnitId,
            withDeleteUnitConfig: true,
          }),

        deleteThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.EDIT_RIGHTS,
          unitId: selectedUnitId,
          withDeleteUnitConfig: true,
        }),
      ].filter(Boolean);

      await Promise.all(promises);

      await fetchEditRightsConfig(true);
      refetchEditRights();
      toast.success(t('changesSaved'));
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  const hasSubMenu = useCallback(
    (field: keyof typeof FIELDS) => {
      if (field === 'linksEditable') {
        if (!isThemeAdmin && !isBlockedFieldsEditable.linksEditable) return false;
        return true;
      }
      if (field === 'detailsEditable') {
        if (!isThemeAdmin && !isBlockedFieldsEditable.detailsEditable) return false;
        return true;
      }

      return false;
    },
    [isBlockedFieldsEditable, isThemeAdmin],
  );

  // conditionally show some edit rights
  // this is currently a frontend filter only
  // may be moved to some centralized logic and be applied in the backend as well later
  const flag_uidParam = usePrivateFeatureFlag('profileShare_uidParam');
  const visibleEditRightFields = Object.keys(FIELDS).filter((name: keyof typeof FIELDS) => {
    switch (name) {
      case 'profileShare_uidParam':
        return (
          flag_uidParam &&
          isFeatureAllowed(FEATURE.UNITS) &&
          isFeatureAllowed(FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN)
        );

      case 'singleUserCrmPush':
        return isFeatureAllowed(FEATURE.CRM_INTEGRATION);

      default:
        return true;
    }
  });

  return (
    <>
      <Box mt={4}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='h2' color='text.primary'>
            {t('editRights.mainHeading')}
          </Typography>
        </Box>
        <Typography variant='body2' color='text.secondary' sx={{ mt: 2 }}>
          {t(
            `editRightsInfo.${
              isUnitAdmin ? 'unitAdmin' : selectedUnit ? 'themeAdmin.unit' : 'themeAdmin.global'
            }`,
          )}
        </Typography>
      </Box>
      {editRightsFields && (
        <Box sx={{ mt: 2 }}>
          {visibleEditRightFields.map((field: keyof typeof FIELDS, index) => (
            <SwitchRow
              title={index + 1 + '. ' + t(`editRights.fields.${field}`)}
              checked={editRightsFields[field]}
              onChange={withUnsavedSetter(() => handleChange(field))}
              tooltip={t(FIELDS[field]?.tooltip)}
              key={field}
              subMenu={
                hasSubMenu(field) ? (
                  <SubMenu
                    showTeaser={!isBlockedFieldsEditable[field]}
                    title={t(`editRights.fieldsSubHeadings.${field}`)}
                    defaultFields={defaultFields[field]}
                    blockedFields={blockedFields[field]}
                    isEditable={editRightsFields[field] && isBlockedFieldsEditable[field]}
                    onChange={withUnsavedSetter((fieldId: string) =>
                      setBlockedFields(prev => ({
                        ...prev,
                        [field]: blockedFields?.[field]?.includes(fieldId)
                          ? [...blockedFields?.[field]?.filter(id => id !== fieldId)]
                          : [...blockedFields?.[field], fieldId],
                      })),
                    )}
                  />
                ) : undefined
              }
            />
          ))}
        </Box>
      )}
      {canEdit && (
        <LtActionButtonBar
          saveAction={{
            loading: saving,
            onClick: handleSave,
          }}
          deleteAction={
            deleteButtonIsVisible && {
              onClick: () => deleteClickHandler(handleDelete),
              text: t(
                settingsHierarchyLevel === HierarchyLevel.Unit ? 'deleteSettings' : 'resetSettings',
              ),
            }
          }
        />
      )}
    </>
  );
};
