import { useAppSelector } from '@/application/hooks';
import { doS3LeadUpload } from '@/infrastructure/apis/aws';
import { addLead, editLead } from '@/infrastructure/apis/leadGen';
import { LeadDataProps } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';

export const useLeadGen = () => {
  const account = useAppSelector(state => state.account);
  const { getAccessTokenSilently } = useAuth0();

  const getImageUrl = async (file?: File) => {
    return await doS3LeadUpload(
      `leads/${account.id}/${file?.name}`,
      file,
      account.id,
      getAccessTokenSilently,
    );
  };

  const preparePayload = async (
    leadData: Partial<LeadDataProps>,
    businessCardImages?: { file: File; url: string }[],
    profileImageFile?: File,
  ) => {
    const profileImagePromise = profileImageFile && getImageUrl(profileImageFile);

    const businessCardFilePromises =
      businessCardImages?.map(({ file, url }) =>
        file ? getImageUrl(file) : new Promise(res => res(url)),
      ) || [];

    const [profileImageUrl, ...businessCardUrls] = await Promise.all([
      profileImagePromise,
      ...businessCardFilePromises,
    ]);

    const payload = {
      ...leadData,
      profileImageUrl: profileImageUrl || leadData.profileImageUrl,
      businessCardUrls: businessCardUrls || leadData.businessCardUrls,
    } as LeadDataProps;

    return payload;
  };

  const createLeadHandler = async (
    leadData: Partial<LeadDataProps>,
    businessCardImages?: { file: File; url: string }[],
    profileImageFile?: File,
  ) => {
    const payload = await preparePayload(leadData, businessCardImages, profileImageFile);

    let { data: res } = await addLead(payload, account.id);
    if (!res.isSuccess) throw new Error('Error adding lead');
    return res.data;
  };

  const editLeadHandler = async (
    leadData: Partial<LeadDataProps>,
    businessCardImages?: { file: File; url: string }[],
    profileImageFile?: File,
  ) => {
    const payload = await preparePayload(leadData, businessCardImages, profileImageFile);

    let { data: res } = await editLead(payload, account.id, getAccessTokenSilently);
    if (!res.isSuccess) throw new Error('Error editing lead');
    return res.data;
  };

  return { createLeadHandler, editLeadHandler };
};
