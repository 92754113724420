import { Box, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import leadgenTeaser from '../../../../images/leadgen-teaser.png';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';

const CollectLeadsSection = () => {
  const { t } = useTranslation();
  const { desktopView } = useDesktopView();

  return (
    <Box
      py={3}
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.info.contrastText,
      })}
    >
      <Typography variant='h1'>{t('teaser.collectLeadsWithLt')}</Typography>

      <Box my='4rem' mx='auto' maxWidth='100rem'>
        <Grid container columnSpacing='8rem' justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6}>
            <CardMedia
              sx={{
                maxWidth: '25rem',
                display: 'block',
                marginLeft: 'auto',
                ...(!desktopView && { maxwidth: '80%', margin: 'auto', marginBottom: '3rem' }),
              }}
              src={leadgenTeaser}
              alt='screenshot'
              component={'img'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={'2.5rem'}>
              <Typography variant='h3' sx={{ mb: 0.5 }}>
                {t('teaser.makeContacts')}
              </Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {t('teaser.contacts')}
              </Typography>
            </Box>
            <Box>
              <Typography variant='h3' sx={{ mb: 0.5 }}>
                {t('teaser.manageContacts')}
              </Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {t('teaser.contactsLand')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CollectLeadsSection;
