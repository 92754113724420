import { FEATURE, THEME_LINKS_FILES_POSITIONS } from '../../../../../../../shared/constants';
import { Account, Link, LinkType } from '../../../../../../../shared/types/api';
import { Employee } from '../../../../../../../shared/types/api/employee.type';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import AutoComplete, { AutoCompleteValue } from '../../autoComplete';
import { withSilentAccessToken } from '../../../../../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../../../../config/config';
import { PERMISSIONS, routePaths } from '../../../../../../../infrastructure/constants';
import { ThemeLinksFilesPosition } from '../../../../../../../shared/types/global';
import { StyledLink } from '../../styles';
import useTierInfo from '../../../../../../../infrastructure/hooks/useTierInfo';
import FeatureTeaser from '@/views/pages/md/teaser/featureTeaser';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import {
  AddCircleOutline,
  ArrowOutward,
  FlashOff,
  FlashOn,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { LinkBox, LtSorter } from '@/components';
import TooltipInfo from '@/components/TooltipInfo';

// TODO resolve any
interface Props {
  employee: Employee;
  links: Array<Link>;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
  account: Account;
  themeLinkTypes: Array<LinkType>;
  popupProps: any;
  t: TFunction;
  maxOrder: number;
  themeLinksPosition: ThemeLinksFilesPosition;
  showAddNewLinkPopup: () => void;
  onDeleteLinkClick?: (link: Link) => void;
  unitId?: number;
  genericWebsiteColor?: string;
}

const LinksUi = (props: Props) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { popupProps, unitId } = props;
  const { links, userLinksProps, t } = props;
  const { showCreateEditLinkPopup } = popupProps;
  const [linkTemplates, setLinkTemplates] = useState<Link[]>(props.links);
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [companyLinksList, setCompanyLinksList] = useState<Link[]>([]);
  const isDisabled = autoCompleteValues.length === 0;
  const noTemplates = isDisabled && linkTemplates.length === 0;

  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(`${config.API_BASE_URL}theme/link-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      ).then(res => {
        setLinkTemplates(res.data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureAllowed]);
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'theme/global-links', {
          params: { unitId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
    ).then(res => {
      setCompanyLinksList(res.data.data);
    });
  }, [getAccessTokenSilently, props.employee, unitId]);

  useEffect(() => {
    setAutoCompleteValues(
      linkTemplates
        ?.filter(link => !links?.find(x => x.linkTemplateId === link.id))
        ?.filter(link => !companyLinksList?.find(x => x.linkTemplateId === link.id))
        .map((theme, index) => {
          return {
            id: theme.id,
            description: theme.name,
            index,
            otherData: theme,
          };
        }),
    );
  }, [linkTemplates, links, companyLinksList]);

  const saveAutoValue = (item: AutoCompleteValue) => {
    if (item === null) return;
    if (item != null) {
      const addedTemplateLink = {
        id: Math.floor(Math.random() * 1000),
        name: item.otherData.name,
        link: item.otherData.link,
        isShallow: true,
        canDisplayOnProfile: true,
        order: props.maxOrder + 10,
        linkTemplateId: item.otherData.id,
        linkType: item.otherData.themeLinkType.linkType,
        linkTypeId: item.otherData.themeLinkType.linkType.id,
      };

      userLinksProps.setUserLinks([...userLinksProps.userLinks, addedTemplateLink]);
    }
  };

  const { setUserLinks } = userLinksProps || {};

  const themeLinks = useMemo(
    () =>
      Boolean(companyLinksList?.length) ? (
        <Box display='flex' flexDirection='column' gap={2}>
          {companyLinksList.map((link, index) => (
            <LinkBox
              key={index}
              link={link}
              chips={[t('default')]}
              genericWebsiteColor={props.genericWebsiteColor}
            />
          ))}
        </Box>
      ) : null,
    [companyLinksList, t, props.genericWebsiteColor],
  );

  return (
    <>
      <FeatureTeaser condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} text='' fullWidth>
        <Box display='flex'>
          <AutoComplete
            options={autoCompleteValues}
            onSave={saveAutoValue}
            placeHolder={`${t(
              noTemplates ? 'linkTab.noTemplates' : 'linkTab.searchStandardLinks',
            )}...`}
            disabled={isDisabled}
          />
        </Box>
        {isThemeAdmin && (
          <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.PROFILE_DESIGN}>
            <Typography
              variant='body2'
              component='div'
              sx={{ ':hover': { textDecoration: 'underline' } }}
            >
              {t('linkTab.createTemplate')}
            </Typography>
            <ArrowOutward color='primary' fontSize='small' />
          </StyledLink>
        )}
      </FeatureTeaser>

      <Box display='flex' flexDirection='column' alignItems='center' mb={2}>
        <Typography variant='body2' component='div' sx={{ mt: 1.5, mb: 0.5 }} color='secondary'>
          {t('linkTab.orAddIndividualLink')}
        </Typography>
        <Button startIcon={<AddCircleOutline />} onClick={props.showAddNewLinkPopup}>
          {t('addLink')}
        </Button>
      </Box>
      {props.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeLinks && (
        <>
          {themeLinks}
          <Divider sx={{ my: 2 }} />
        </>
      )}
      <LtSorter
        onEnd={result =>
          setUserLinks(result.map((link, index) => ({ ...link, order: index * 10 })))
        }
        items={links}
        getItemAriaLabel={item => `${item.name}: ${item.linkType?.name} (${t('ariaProfileLink')})`}
        getItemKey={item => item.id}
        renderer={link => (
          <LinkBox
            link={link}
            onEdit={!link.linkTemplateId && (() => showCreateEditLinkPopup(link))}
            onDelete={link.linkTemplateId && (() => props.onDeleteLinkClick(link))}
            deleteTooltip={t('explainTemplatedLink')}
            chips={[link.linkTemplateId && t('template')].filter(Boolean)}
            customActions={[
              <TooltipInfo text={t('hideMessageLink')} onHover>
                <IconButton
                  aria-label={t('ariaHideLinkFromProfile', { link: link.name })}
                  onClick={() =>
                    setUserLinks(prev =>
                      prev.map(x =>
                        x.id === link.id
                          ? { ...x, canDisplayOnProfile: !link.canDisplayOnProfile }
                          : x,
                      ),
                    )
                  }
                >
                  {link.canDisplayOnProfile ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </TooltipInfo>,
              <TooltipInfo text={t('redirectMessage')} onHover>
                <IconButton
                  aria-label={t('ariaRedirectBusinesssCardTo', { link: link.name })}
                  onClick={() =>
                    userLinksProps.setDefaultLinkId(
                      userLinksProps.defaultLinkId === link.id ? null : link.id,
                    )
                  }
                >
                  {userLinksProps.defaultLinkId === link.id ? <FlashOn /> : <FlashOff />}
                </IconButton>
              </TooltipInfo>,
            ]}
            genericWebsiteColor={props.genericWebsiteColor}
          />
        )}
      />

      {props.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeLinks && (
        <>
          <Divider sx={{ my: 2 }} />
          {themeLinks}
        </>
      )}
    </>
  );
};

export default LinksUi;
