import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import LtDefaultActionButtons, { hasConfirm, hasDelete, hasSave } from './LtDefaultActionButtons';
import { LtDefaultActionButtonsProps } from './LtDefaultActionButtons/types';

/**
 * USE THIS COMPONENT FOR EVERY DIALOG
 * - almost all dialogs suport either action of save, done, cancel, delete
 * - this component's purpose is to make them look the same throughout the app
 * - try to do as little custom styling as possible, just let this component figure out the button style and order
 * - when using the "on<Action>" prop, default styling is used. Loading and Disabled states can be set via separate props.
 * - only use the "<action>Action" prop when needing to pass custom props to the button component
 * - an action is rendered only if either of the props "on<Action>" or "<action>Action" is not undefined
 */
const sizes = {
  sm: '50rem',
  md: '70rem',
  lg: '90rem',
};
export type LtDialogProps = Omit<DialogProps, 'children'> &
  LtDefaultActionButtonsProps & {
    children: React.ReactNode;
    title?: string;
    titleIcon?: React.ReactNode;
    width?: string;
    size?: keyof typeof sizes;
    loading?: boolean;
    disabled?: boolean;
    withActionDivider?: boolean;
  };
const LtDialog = ({
  children,
  width,
  title,
  titleIcon,
  loading,
  disabled,
  size = 'md',
  withActionDivider,
  ...rest
}: LtDialogProps) => {
  const actionButtons = useMemo(
    () => <LtDefaultActionButtons loading={loading} disabled={disabled} {...rest} />,
    [disabled, loading, rest],
  );

  return (
    <Dialog {...rest} sx={{ '& .MuiDialog-paper': { width: sizes[size] } }} maxWidth={size}>
      {title ? (
        <>
          {titleIcon ? (
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
              {titleIcon}
              <DialogTitle variant='h4'>{title}</DialogTitle>
            </Box>
          ) : (
            <DialogTitle
              color={
                hasDelete(rest) && !hasSave(rest) && !hasConfirm(rest) ? 'error.dark' : 'inherit'
              }
              variant='h4'
            >
              {title}
            </DialogTitle>
          )}
          <Divider />
        </>
      ) : null}

      <DialogContent>
        {typeof children === 'string' ? (
          <Typography variant='body2'>{children}</Typography>
        ) : (
          children
        )}
      </DialogContent>
      {withActionDivider && <Divider />}
      {actionButtons && <DialogActions sx={{ py: 1.6, px: 2.4 }}>{actionButtons}</DialogActions>}
    </Dialog>
  );
};
export default LtDialog;
