import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../application/hooks';
import { Account, FeatureSet } from '../../shared/types/api';
import { generateStaticTierInfo } from '@/shared/util';
import { FeatureFlagContext } from './useFeatureFlags';

export default function useTierInfo() {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [idToken, setIdToken] = useState<IdToken>(null);
  useEffect(() => {
    if (isAuthenticated && !idToken) {
      getIdTokenClaims().then(setIdToken);
    }
  }, [idToken, getIdTokenClaims, isAuthenticated]);

  const account = useAppSelector<Account>(state => state.account);
  const theme = account?.theme;

  const isFeatureAllowed = useCallback(
    (feature: keyof FeatureSet) => !!theme?.featureSet?.[feature],
    [theme?.featureSet],
  );

  const isMinTier = useCallback(
    (minTier: string) => {
      if (minTier === 'starter') return true;
      else if (minTier === 'essentials')
        return ['essentials', 'pro', 'enterprise'].includes(theme?.themeInternal?.tier);
      else if (minTier === 'pro') ['pro', 'enterprise'].includes(theme?.themeInternal?.tier);
      else if (minTier === 'enterprise') return theme?.themeInternal?.tier === 'enterprise';
      else return false;
    },
    [theme?.themeInternal?.tier],
  );

  const staticTierInfo = useMemo(
    () => generateStaticTierInfo(account, theme, idToken),
    [account, idToken, theme],
  );

  const context = useContext(FeatureFlagContext);

  const tierInfo = useMemo(
    () => ({
      ...staticTierInfo,
      isFeatureAllowed,
      isMinTier,
      privateFlags: context.privateFlags,
      publicFlags: context.publicFlags,
    }),
    [context?.privateFlags, context?.publicFlags, isFeatureAllowed, isMinTier, staticTierInfo],
  );

  return tierInfo;
}

export type TierInfo = ReturnType<typeof useTierInfo>;
