import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { HierarchyLevel } from '@/shared/types/api';

import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import {
  deleteThemeConfig,
  fetchThemeConfig,
  writeThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import { SettingsContext } from '../Settings/SettingsWrapper/context';

import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { Section } from '../profile-design/Section';
import { Box, TextField, Typography } from '@mui/material';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { SingleContactField } from './components/SingleContactField';
import { SwitchRow } from './components/SwitchRow';
import TooltipInfo from '@/components/TooltipInfo';
import { SameLabelAlert } from './components/SameLabelAlert';
import {
  VCARD_CONTACT_FIELDS,
  VCARD_FIELD_LABELS,
  VCardFieldType,
} from '@/shared/business-logic/features/vcard/constants';
import { VCardField, VCardThemeConfig } from '@/shared/business-logic/features/vcard/types';

export const VCardConfigurator = () => {
  const {
    selectedUnit,
    deleteButtonIsVisible,
    setSettingsHierarchyLevel,
    deleteClickHandler,
    setSettingsLoading,
    settingsHierarchyLevel,
  } = useContext(SettingsContext);

  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();

  const [vcardConfig, setVcardConfig] = useState<Omit<VCardThemeConfig, 'fields'>>();
  const [vcardFieldsDict, setVcardFieldsDict] = useState<Record<string, VCardField>>({});
  const selectedUnitId = selectedUnit?.id || null;

  const { setIsUnsaved, withUnsavedSetter } = useUnsavedStatusSetter();

  const [isSaving, setIsSaving] = useState(false);

  const fetchConfig = useCallback(async () => {
    try {
      const {
        value,
        meta: { hierarchyLevel },
      } = await fetchThemeConfig<VCardThemeConfig>(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.VCARD_CONFIG,
        fetchThemeLevel: true,
        unitId: selectedUnitId,
      });

      const { fields, ...rest } = value;

      setVcardConfig(rest);
      setVcardFieldsDict(fields.reduce((acc, field) => ({ ...acc, [field.key]: field }), {}));
      setSettingsHierarchyLevel(hierarchyLevel);
    } catch (error) {
      toast.error(t('error.general'));
    }
  }, [getAccessTokenSilently, t, selectedUnitId, setSettingsHierarchyLevel, toast]);

  useEffect(() => {
    const fetchData = async () => {
      setSettingsLoading(true);
      await fetchConfig();
      setSettingsLoading(false);
    };
    fetchData();
  }, [fetchConfig, setSettingsLoading]);

  const handleSave = async () => {
    setIsSaving(true);

    try {
      const payload = {
        ...vcardConfig,
        fields: Object.entries(vcardFieldsDict).map(([key, field]) => ({ ...field, key })),
      };
      await writeThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.VCARD_CONFIG,
        value: payload,
        unitId: selectedUnitId,
      });

      setIsUnsaved(false);
      toast.success(t('changesSaved'));
    } catch {
      toast.error(t('unexpectedError'));
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      await deleteThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.VCARD_CONFIG,
        unitId: selectedUnitId,
        withDeleteUnitConfig: true,
      });
      await fetchConfig();
      toast.success(t('changesSaved'));
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  const handleFieldChange = (fieldKey: string, value: VCardField) => {
    const existingField = vcardFieldsDict[fieldKey] || {
      key: fieldKey,
      visible: true,
    };

    setVcardFieldsDict(prev => ({ ...prev, [fieldKey]: { ...existingField, ...value } }));
    setIsUnsaved(true);
  };

  const m = useMemo(() => {
    const map: Record<string, string[]> = {};

    Object.entries(vcardFieldsDict).forEach(([key, field]) => {
      if (!field.visible) return;
      const fieldInfo = VCARD_CONTACT_FIELDS.find(f => f.key === key);
      if (!fieldInfo || fieldInfo.fieldType === VCardFieldType.phone) return;
      const mapKey = `${fieldInfo.fieldType}-${field.type || 'custom'}`;
      map[mapKey] = [...(map[mapKey] || []), fieldInfo.tKey];
    });
    return map;
  }, [vcardFieldsDict]);

  if (!vcardConfig) return null;

  return (
    <Box mt={2.4}>
      <Typography variant='body2' color='secondary'>
        {t('md.vcardConfigurator.description')}
      </Typography>
      <Box mt={2.4} sx={{ listStyleType: 'decimal', typography: 'h3', ml: 1.6 }} component='ol'>
        <Section
          component='li'
          title={t('md.vcardConfigurator.contactInfoTitle')}
          subTitle={t('md.vcardConfigurator.contactInfoDescription')}
          showDivider
        >
          <SameLabelAlert map={m} />
          <Box display='flex' flexDirection='column' gap={2}>
            {VCARD_CONTACT_FIELDS.map(field => (
              <SingleContactField
                field={field}
                vcardFieldValue={vcardFieldsDict[field.key]}
                onChange={val => handleFieldChange(field.key, val)}
                defaultLabels={VCARD_FIELD_LABELS[field.fieldType]}
              />
            ))}
          </Box>
        </Section>
        <Section title={t('md.vcardConfigurator.notes')} showDivider component='li'>
          <TextField
            label={t('md.vcardConfigurator.notesGerman')}
            variant='outlined'
            value={vcardConfig?.notes?.de || ''}
            onChange={withUnsavedSetter(e =>
              setVcardConfig(prev => ({ ...prev, notes: { ...prev?.notes, de: e.target.value } })),
            )}
            fullWidth
            sx={{ mt: 2 }}
            InputProps={{
              endAdornment: <TooltipInfo onHover text={t('md.vcardConfigurator.notesTooltip')} />,
            }}
          />
          <TextField
            label={t('md.vcardConfigurator.notesEnglish')}
            variant='outlined'
            value={vcardConfig?.notes?.en || ''}
            onChange={withUnsavedSetter(e =>
              setVcardConfig(prev => ({ ...prev, notes: { ...prev?.notes, en: e.target.value } })),
            )}
            fullWidth
            sx={{ mt: 2 }}
            InputProps={{
              endAdornment: <TooltipInfo onHover text={t('md.vcardConfigurator.notesTooltip')} />,
            }}
          />
        </Section>
        <Section title={t('md.vcardConfigurator.additionalSettings')} showDivider component='li'>
          <SwitchRow
            value={Boolean(vcardConfig?.showLinks)}
            onChange={val => setVcardConfig(prev => ({ ...prev, showLinks: val }))}
            title={t('md.vcardConfigurator.showLinks')}
            tooltipText={t('md.vcardConfigurator.showLinksTooltip')}
          />
          {vcardConfig?.showLinks && (
            <SwitchRow
              value={Boolean(vcardConfig?.showLinksOffline)}
              onChange={val => setVcardConfig(prev => ({ ...prev, showLinksOffline: val }))}
              title={t('md.vcardConfigurator.showLinksOffline')}
              tooltipText={t('md.vcardConfigurator.showLinksOfflineTooltip')}
            />
          )}
          <SwitchRow
            value={Boolean(vcardConfig?.convertSpecialChars)}
            onChange={val => setVcardConfig(prev => ({ ...prev, convertSpecialChars: val }))}
            title={t('md.vcardConfigurator.convertSpecialChars')}
            tooltipText={t('md.vcardConfigurator.convertSpecialCharsTooltip')}
          />
        </Section>
        <LtActionButtonBar
          saveAction={{
            loading: isSaving,
            onClick: handleSave,
          }}
          deleteAction={
            deleteButtonIsVisible && {
              onClick: () => deleteClickHandler(handleDelete),
              text: t(
                settingsHierarchyLevel === HierarchyLevel.Unit ? 'deleteSettings' : 'resetSettings',
              ),
            }
          }
        />
      </Box>
    </Box>
  );
};
