import React from 'react';
import { ApiFile } from '@/shared/types/api';
import { TableProps } from '..';
import { isNotEmptyArray } from '@/infrastructure/helper';
import TooltipInfo from '@/components/TooltipInfo';
import { Box, IconButton } from '@mui/material';
import { FolderOpenOutlined } from '@mui/icons-material';

const VISIBLE_FILES_COUNT = 8;

interface Props {
  files: ApiFile[];
  selectedFiles: TableProps['SelectedObjects']['selectedFilesProps'];
}

const FilesCell = ({ files, selectedFiles }: Props) => {
  if (!Array.isArray(files)) return null;
  const addFilter = (File: ApiFile) => {
    if (File.id === selectedFiles.selectedFile?.id) {
      selectedFiles.setSelectedFile(null);
    } else {
      selectedFiles.setSelectedFile(File);
    }
  };

  const visibleFiles = files.slice(0, VISIBLE_FILES_COUNT);
  return (
    <Box display='flex' flexWrap='wrap' justifyContent='center'>
      {isNotEmptyArray(files) &&
        visibleFiles.map(file => (
          <TooltipInfo
            text={
              <a href={file.link} target='_blank' rel='noopener noreferrer'>
                {file.link}
              </a>
            }
            placement='bottom'
            onHover={true}
            key={file.id}
          >
            <IconButton
              sx={{ p: 0.3 }}
              disabled={selectedFiles?.selectedFile && selectedFiles?.selectedFile?.id !== file.id}
              onClick={() => addFilter(file)}
              aria-label={file.fileName}
            >
              <FolderOpenOutlined sx={{ fontSize: 23 }} />
            </IconButton>
          </TooltipInfo>
        ))}
    </Box>
  );
};

export default FilesCell;
