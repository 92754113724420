import LtVideo from '@/components/LtVideo';
import { UrlsBoxItemVideo } from '@/shared/types/api';

type Props = UrlsBoxItemVideo;

export default function VideoItem({ src, text }: Props) {
  return (
    <LtVideo
      label={text}
      embedUrl={src}
      sx={{
        marginBottom: 2,
        width: '100%',
      }}
    />
  );
}
