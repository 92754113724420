import { Filter } from '@/shared/util';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FiltersRenderer } from '@/components';
import { getLeadFilters } from '@/infrastructure/apis/leadGen';
import { FilterValue, transformToQueryParams } from './utils';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { updateActionPreferences } from '@/application/actions/account';
import { Account } from '@/shared/types/api';

const mapper = {
  mdLead: getLeadFilters,
};

export const useFiltersFetcher = (mapKey: keyof typeof mapper) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchFilters = async () => {
      setLoading(true);
      try {
        const fetcher = mapper[mapKey];
        const filters = await fetcher(getAccessTokenSilently);
        setFilters(filters);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchFilters();
  }, [mapKey, getAccessTokenSilently]);

  return { filters, loading };
};

export const useFilters = (
  mapKey: keyof typeof mapper,
  onChange?: (filterQueryStrings: string[]) => void,
) => {
  const { filters, loading } = useFiltersFetcher(mapKey);

  const actionPreferencesKey = `${mapKey}_filters`;
  const account: Account = useAppSelector(state => state.account);
  const [visibleFilters, setVisibleFilters] = useState<string[]>(
    account.actionPreferences[actionPreferencesKey] || [],
  );

  const [filterValues, setFilterValues] = useState<Record<string, FilterValue | null>>({});

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const stringifiedQueryValueRef = useRef<string>('');

  const handleFilterValuesChange = useCallback(
    (filterValues: Record<string, FilterValue>) => {
      setFilterValues(filterValues);
      const transformedQueryParams = transformToQueryParams(filterValues);
      const stringifiedQueryValue = JSON.stringify(transformedQueryParams);
      if (stringifiedQueryValueRef.current === stringifiedQueryValue) {
        return;
      }
      stringifiedQueryValueRef.current = stringifiedQueryValue;
      onChange?.(transformedQueryParams);
    },
    [onChange],
  );

  const handleFilterVisibilityChange = useCallback(
    (keys: string[]) => {
      const uniqueKeys = Array.from(new Set(keys));
      const filterVals = uniqueKeys.reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});
      setVisibleFilters(uniqueKeys);
      handleFilterValuesChange(filterVals);
      dispatch(
        updateActionPreferences(getAccessTokenSilently, {
          [actionPreferencesKey]: uniqueKeys,
        }),
      );
    },
    [
      filterValues,
      dispatch,
      getAccessTokenSilently,
      actionPreferencesKey,
      handleFilterValuesChange,
    ],
  );

  const [drawerOpened, setDrawerOpened] = useState(false);
  const closeDrawer = useCallback(() => setDrawerOpened(false), []);
  const openDrawer = useCallback(() => setDrawerOpened(true), []);

  const Component = useMemo(
    () => (
      <FiltersRenderer
        open={drawerOpened}
        onClose={closeDrawer}
        filters={filters}
        visibleFilters={visibleFilters}
        onFilterVisibilityChange={handleFilterVisibilityChange}
        filterValues={filterValues}
        onFilterValuesChange={handleFilterValuesChange}
      />
    ),
    [
      closeDrawer,
      handleFilterVisibilityChange,
      drawerOpened,
      filterValues,
      filters,
      visibleFilters,
      handleFilterValuesChange,
    ],
  );

  const queryValues = useMemo(() => transformToQueryParams(filterValues), [filterValues]);

  const toStringQueryValues = JSON.stringify(queryValues);
  const memoizedQueryValues = useMemo(() => JSON.parse(toStringQueryValues), [toStringQueryValues]);

  return {
    filters,
    loading,
    Component,
    openDrawer,
    closeDrawer,
    filterValues,
    queryValues: memoizedQueryValues,
  };
};
