import { Alert, SnackbarProps } from '@mui/material';
import BaseSnackbar from './base';

export type InfoNotificationProps = SnackbarProps & {
  text?: string;
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

export default function InfoNotification({ text, ...snackbarProps }: InfoNotificationProps) {
  return (
    <BaseSnackbar {...snackbarProps}>
      <Alert
        onClose={snackbarProps.onClose}
        severity='info'
        variant='filled'
        sx={{ width: '100%' }}
      >
        {text}
      </Alert>
    </BaseSnackbar>
  );
}
