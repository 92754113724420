import { Button, FormControl, InputLabel, MenuItem, Select, StackProps } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ISO6391 from 'iso-639-1';
import { Box, Stack } from '@mui/material';
import LtDialog from '@/components/LtDialog';

export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language?.substring(0, 2);

  const handleLanguageChange = useCallback(
    async (lang: string) => {
      i18n.changeLanguage(lang);
      window.UC_UI?.updateLanguage?.(lang.substring(0, 2));
    },
    [i18n],
  );

  const Translation = useCallback(
    (props: { languages?: string[] } & StackProps) => {
      const { languages, ...stackProps } = props;
      const langs = languages?.filter(ISO6391.validate) ?? ['en', 'de'];

      return (
        <Stack
          direction='row'
          divider={<Box aria-hidden>|</Box>}
          spacing={0.25}
          aria-label={t('header.langSelector.aria.label')}
          alignItems='center'
          {...stackProps}
        >
          {langs.map((lang, idx) => (
            <Button
              key={idx}
              onClick={() => handleLanguageChange(lang)}
              sx={theme => ({
                fontWeight: i18n.language === lang ? 'bold' : 'normal',
                padding: theme.spacing(0.5),
                minWidth: theme.spacing(3),
                textTransform: 'uppercase',
              })}
              variant='text'
              size='small'
              aria-selected={i18n.language === lang}
              aria-label={ISO6391.getNativeName(lang)}
            >
              {lang}
            </Button>
          ))}
        </Stack>
      );
    },
    [handleLanguageChange, i18n.language, t],
  );

  const TranslationSelectDialog = (props: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    languages?: string[];
  }) => {
    const langs = props.languages?.filter(ISO6391.validate) ?? ['en', 'de'];
    const [selectedLang, setSelectedLang] = useState(activeLanguage);

    return (
      <LtDialog
        open={props.show}
        onClose={() => props.setShow(false)}
        onCancel={() => props.setShow(false)}
        onConfirm={() => {
          handleLanguageChange(selectedLang);
          props.setShow(false);
        }}
        title={t('settings.changeLanguage')}
        size='sm'
        withActionDivider
      >
        <FormControl fullWidth>
          <InputLabel id='langLabelId'>{t('settings.selectLanguage')}</InputLabel>
          <Select
            value={selectedLang}
            labelId={'langLabelId'}
            label={t('settings.selectLanguage')}
            onChange={({ target: { value } }) => {
              setSelectedLang(value);
            }}
          >
            {langs.map((lang, idx) => (
              <MenuItem key={idx} value={lang}>
                {ISO6391.getNativeName(lang)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </LtDialog>
    );
  };

  return {
    Translation,
    TranslationSelectDialog,
    t,
    activeLanguage,
    i18n,
  };
};
