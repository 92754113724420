import { useAppSelector } from '@/application/hooks';
import { LtDialog, PageContainer } from '@/components';
import config from '@/config/config';
import { homepageLink } from '@/infrastructure/constants';
import { withSilentAccessToken } from '@/infrastructure/helper';
import withNav from '@/infrastructure/hoc/withNav';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { Account, AnalyticsSummary, DateRange } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { CalendarTodayOutlined, ShareOutlined } from '@mui/icons-material';

import { Card, CardContent, Divider, Typography, Button, Box } from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ShareDialog from './components/ShareDialog';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import ClicksCard from './components/ClicksCard';
import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';
import StatsCard from './components/StatsCard';
import { getDefaultEndDate, getDefaultStartDate } from '@/shared/constants';
import LTDateRangePicker from '@/components/LTDataRangePicker';
import { getDateRangeButtonText, getEndDayDate, getStartDayDate } from '../md/analytics/helpers';
import { LtAdmin } from '@/components/LtAdmin';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { visuallyHidden } from '@mui/utils';

const Analytics = () => {
  const initialDataFetch = useRef(null);
  const newDataFetched = useRef(false);

  const { notify: toast } = useLtNotifications();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AnalyticsSummary>(null);
  const [error, setError] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const { firstName } = useAppSelector<Account>(state => state.account);
  const { getAccessTokenSilently } = useAuth0();

  const { t, activeLanguage } = useAppTranslation();
  const { desktopView: isDesktop } = useDesktopView();

  useDeferredLoader(!data && loading, 'analytics-dataloader-id');

  const [dateRange, setDateRange] = useState<Required<DateRange>>({
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(),
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRangeChanged, setDateRangeChanged] = useState(false);
  const [dateRangeButtonTtext, setDateRangeButtonTtext] = useState(
    getDateRangeButtonText(dateRange),
  );

  useEffect(() => {
    // update button text when switching language
    if (!!newDataFetched.current) {
      setDateRangeButtonTtext(getDateRangeButtonText(dateRange));
      newDataFetched.current = false;
    }
  }, [dateRange, activeLanguage]);

  const handleChange = useCallback(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `data/summary`, {
          params: {
            startDate: getStartDayDate(dateRange?.startDate),
            endDate: getEndDayDate(dateRange?.endDate),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [],
      () => setLoading(true),
    )
      .then(res => {
        if (res.data?.success) {
          setError(false);
          setData(res.data.data);
          setDateRangeButtonTtext(getDateRangeButtonText(dateRange));
        }
      })
      .catch(_ => {
        setError(true);
        toast.error(t('error.general'));
      })
      .finally(() => setLoading(false));
  }, [dateRange, getAccessTokenSilently, t, toast]);

  useEffect(() => {
    if (!initialDataFetch.current) {
      initialDataFetch.current = true;
      handleChange();
    }
  }, [handleChange]);

  const headerActions = useMemo(
    () =>
      createHeaderAction(
        <Button
          variant='outlined'
          color='primary'
          startIcon={<ShareOutlined />}
          onClick={() => setShowSharePopup(true)}
        >
          {t('analytics.share.share')}
        </Button>,
        { mobileOnly: true },
      ),
    [t],
  );
  useHeaderActions(headerActions);

  const oldFileClicksAmount = useMemo(() => {
    if (data?.raw) {
      const totalClicks = data.raw.fileClicksTotal;
      const activeFilesClicks = data.raw.fileClicksIndividual.reduce(
        (prev, cur) => prev + cur.clicks,
        0,
      );
      return totalClicks - activeFilesClicks;
    } else {
      return 0;
    }
  }, [data]);

  const oldLinkClicksAmount = useMemo(() => {
    if (data?.raw) {
      const totalClicks = data.raw.linkClicksTotal;
      const activeLinksClicks = data.raw.linkClicksIndividual.reduce(
        (prev, cur) => prev + cur.clicks,
        0,
      );
      return totalClicks - activeLinksClicks;
    } else {
      return 0;
    }
  }, [data]);

  const shareData = useMemo(() => {
    return {
      title: t('analytics.share.title'),
      text: t('analytics.share.text', {
        cards: Math.ceil(data?.savings?.cards),
        flyers: Math.ceil(data?.savings?.flyers),
        wood: Math.ceil(data?.savings?.wood),
        water: Math.ceil(data?.savings?.water),
        energy: Math.ceil(data?.savings?.energy),
        co2: Math.ceil(data?.savings?.co2),
      }),
      url: homepageLink,
    };
  }, [data, t]);

  if (error) {
    return null;
  }

  return (
    <PageContainer maxWidth='l'>
      <Box sx={{ display: 'flex', marginBottom: '2rem', ml: 'auto', width: 'max-content' }}>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          startIcon={<CalendarTodayOutlined />}
          onClick={() => setShowDatePicker(true)}
          aria-haspopup='dialog'
        >
          <span style={visuallyHidden}>{t('ariaOpenDateRangeSelector')}</span>
          {t(dateRangeButtonTtext)}
        </Button>
        {isDesktop && (
          <Button
            sx={{ ml: 1 }}
            size='large'
            onClick={() => setShowSharePopup(true)}
            startIcon={<ShareOutlined />}
            aria-haspopup='dialog'
            aria-label={t('ariaShareAnalytics')}
          >
            {t('analytics.share.share')}
          </Button>
        )}
      </Box>
      <Card sx={{ marginBottom: '1.5rem' }} component='section'>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <LtAdmin />
          <Typography variant='h3' ml='1rem'>
            {t('analytics.networking.title', { firstName })}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}
        >
          {isDesktop ? (
            <>
              <StatsCard
                title={t('analytics.networking.shares')}
                body={data?.raw?.profileShares?.toString()}
                tooltip={t('analytics.tooltips.shares')}
              />
              <StatsCard
                title={t('analytics.networking.contacts')}
                body={data?.raw?.contactsCollected?.toString()}
                tooltip={t('analytics.tooltips.contacts')}
              />
              <StatsCard
                title={t('analytics.networking.vcards')}
                body={data?.raw?.contactsSaved?.toString()}
                tooltip={t('analytics.tooltips.vcards')}
              />
              <StatsCard
                title={t('analytics.networking.edits')}
                body={data?.raw?.profileEdits?.toString()}
                tooltip={t('analytics.tooltips.edits')}
              />
            </>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 1 }} mb={1}>
                <StatsCard
                  title={t('analytics.networking.shares')}
                  body={data?.raw?.profileShares?.toString()}
                  tooltip={t('analytics.tooltips.shares')}
                />
                <StatsCard
                  title={t('analytics.networking.contacts')}
                  body={data?.raw?.contactsCollected?.toString()}
                  tooltip={t('analytics.tooltips.contacts')}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <StatsCard
                  title={t('analytics.networking.vcards')}
                  body={data?.raw?.contactsSaved?.toString()}
                  tooltip={t('analytics.tooltips.vcards')}
                />
                <StatsCard
                  title={t('analytics.networking.edits')}
                  body={data?.raw?.profileEdits?.toString()}
                  tooltip={t('analytics.tooltips.edits')}
                />
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          gap: 1,
          alignItems: isDesktop ? 'flex-start' : 'normal',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ClicksCard
            mode='links'
            entities={data?.raw?.linkClicksIndividual}
            totalClicks={data?.raw?.linkClicksTotal}
            oldClicks={oldLinkClicksAmount}
            type={t('ariaProfileLink')}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ClicksCard
            mode='files'
            entities={data?.raw?.fileClicksIndividual}
            totalClicks={data?.raw?.fileClicksTotal}
            oldClicks={oldFileClicksAmount}
            type={t('ariaProfileFile')}
          />
        </Box>
      </Box>
      <Divider sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} />
      <Card>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h3'>{t('analytics.saves.title')}</Typography>
        </CardContent>
        <Divider />
        <CardContent
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', gap: 1 }}
        >
          <StatsCard
            title={t('analytics.saves.cards')}
            body={data?.savings?.cards?.toString()}
            tooltip={t('analytics.tooltips.cards')}
          />
          <StatsCard
            title={t('analytics.saves.flyers')}
            body={data?.savings?.flyers?.toString()}
            tooltip={t('analytics.tooltips.flyers')}
          />
        </CardContent>
        <CardContent>
          <Typography variant='h3'>{t('analytics.saves.subtitle')}</Typography>
        </CardContent>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          {isDesktop ? (
            <>
              <StatsCard
                title={t('analytics.saves.wood')}
                body={`${Math.ceil(data?.savings?.wood)}kg`}
              />
              <StatsCard
                title={t('analytics.saves.water')}
                body={`${Math.ceil(data?.savings?.water)}l`}
              />
              <StatsCard
                title={t('analytics.saves.energy')}
                body={`${Math.ceil(data?.savings?.energy)}kwh`}
              />
              <StatsCard
                title={t('analytics.saves.co2')}
                body={`${Math.ceil(data?.savings?.co2)}kg`}
              />
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <StatsCard
                  title={t('analytics.saves.wood')}
                  body={`${Math.ceil(data?.savings?.wood)}kg`}
                />
                <StatsCard
                  title={t('analytics.saves.water')}
                  body={`${Math.ceil(data?.savings?.water)}l`}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <StatsCard
                  title={t('analytics.saves.energy')}
                  body={`${Math.ceil(data?.savings?.energy)}kwh`}
                />
                <StatsCard
                  title={t('analytics.saves.co2')}
                  body={`${Math.ceil(data?.savings?.co2)}kg`}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
      <ShareDialog
        shareData={shareData}
        open={showSharePopup}
        onClose={() => setShowSharePopup(false)}
      />
      <LtDialog
        open={showDatePicker}
        onClose={() => setShowDatePicker(false)}
        confirmAction={{
          onClick: () => {
            handleChange();
            setShowDatePicker(false);
            newDataFetched.current = true;
          },
          text: t('apply'),
          disabled: !dateRangeChanged,
        }}
        onCancel={() => setShowDatePicker(false)}
        withActionDivider
        size='lg'
      >
        <LTDateRangePicker
          setValue={val =>
            setDateRange(curDate => {
              if (curDate.startDate !== val.startDate || curDate.endDate !== val.endDate)
                setDateRangeChanged(true);
              return {
                startDate: getStartDayDate(val.startDate || curDate.startDate),
                endDate: getEndDayDate(val.endDate || curDate.endDate),
              };
            })
          }
          open={showDatePicker}
          setOpen={setShowDatePicker}
          initialDateRange={dateRange}
        />
      </LtDialog>
    </PageContainer>
  );
};

export default withNav(
  Analytics,
  {
    tTitle: 'Analytics',
    showBackButton: {
      linkTo: null,
    },
  },
  {},
);
