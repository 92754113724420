import { Box, Divider, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useUnsavedStatus } from '@/utils/unsavedStatus';
import { useTranslation } from 'react-i18next';
import { FEATURE } from '@/shared/constants';
import { PageContainer } from '@/components';
import { AssignmentIndOutlined, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { SettingsWrapper } from '../Settings/SettingsWrapper';
import { EditRightsPage } from './EditRights';
import { UnitEditRightsPage } from './UnitEditRights';
import withNav from '@/infrastructure/hoc/withNav';
import { getUnitEditRightsConfig } from './constants';
import { UnitSelector, useUnitsForSettings } from '@/components/UnitSelector';
enum TabsEnum {
  EditRights = 'EditRights',
  UnitEditRigts = 'UnitEditRights',
}

const EditRights = () => {
  const { t } = useTranslation();
  const { isThemeAdmin, isFeatureAllowed } = useTierInfo();
  const [value, setValue] = useState(TabsEnum.EditRights);

  const { openDialogIfUnsaved } = useUnsavedStatus();

  const isEditRightsAllowed = isFeatureAllowed(FEATURE.EDIT_RIGHTS);

  const teaserText =
    value === TabsEnum.UnitEditRigts
      ? t('upgradeTeaser.feature.unitHierarchies.editRightsGeneral')
      : t('upgradeTeaser.feature.unitHierarchies.editRights');

  const teaserOnUnitAdminTab =
    isThemeAdmin &&
    value === TabsEnum.UnitEditRigts &&
    getUnitEditRightsConfig(isFeatureAllowed).length === 0;

  const { selectedUnit, units, setSelectedUnit } = useUnitsForSettings();

  return (
    <PageContainer maxWidth='m' whiteCardWrapper sx={{ mb: 8 }}>
      {isFeatureAllowed(FEATURE.UNITS) && (
        <>
          <Box display='flex' alignItems='center' justifyContent='space-between' pb='1.5rem'>
            {isThemeAdmin && (
              <Box>
                <Tabs
                  value={value}
                  onChange={(_e, val) => openDialogIfUnsaved(() => setValue(val))}
                >
                  <Tab
                    icon={<SupervisedUserCircleOutlined />}
                    iconPosition='start'
                    label={t('editRights.mainTabLabel')}
                    value={TabsEnum.EditRights}
                    disabled={!isEditRightsAllowed}
                  />
                  <Tab
                    icon={<AssignmentIndOutlined />}
                    iconPosition='start'
                    label={t('editRights.unitTabLabel')}
                    value={TabsEnum.UnitEditRigts}
                    disabled={!isEditRightsAllowed}
                  />
                </Tabs>
              </Box>
            )}
            {Boolean(units?.length) && (
              <Box display={'flex'} width='35%'>
                <UnitSelector
                  selectedUnit={selectedUnit}
                  onChange={value => openDialogIfUnsaved(() => setSelectedUnit(value))}
                  units={units}
                  requiredFeature={FEATURE.UNIT_HIERARCHIES_EDIT_RIGHTS}
                  disabled={!isEditRightsAllowed}
                />
              </Box>
            )}
          </Box>
          <Divider />
        </>
      )}
      <SettingsWrapper
        requiredFeature={FEATURE.UNIT_HIERARCHIES_EDIT_RIGHTS}
        teaserContent={{
          text: !isEditRightsAllowed
            ? t('upgradeTeaser.feature.unitHierarchies.editRightsTheme')
            : teaserText,
        }}
        forceTeaser={teaserOnUnitAdminTab || !isEditRightsAllowed}
        settingKey={value === TabsEnum.EditRights ? 'edit-rights' : 'override-settings'}
        selectedUnit={selectedUnit}
      >
        {value === TabsEnum.EditRights && <EditRightsPage />}
        {value === TabsEnum.UnitEditRigts && isThemeAdmin && <UnitEditRightsPage />}
      </SettingsWrapper>
    </PageContainer>
  );
};

export default withNav(
  EditRights,
  {
    tTitle: 'editRights.title',
  },
  {},
);
