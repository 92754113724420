import LtActionButtonBar from '@/components/LtActionButtonBar';
import { Close } from '@mui/icons-material';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onSave: () => void;
  loading: boolean;
  saveDisabled: boolean;
};

export const WrapperDrawer = ({
  open,
  onClose,
  children,
  loading,
  onSave,
  saveDisabled,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: '70rem', display: 'flex', flexDirection: 'column' } }}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center' px='2rem' py='1rem'>
        <Typography variant='h1'>{t('bulkEditProfiles')}</Typography>
        <IconButton onClick={onClose} aria-label={t('ariaCloseDrawer')}>
          <Close />
        </IconButton>
      </Box>
      <Divider />
      <Box px='2rem' py='2rem' flex='1'>
        {children}
      </Box>
      <LtActionButtonBar
        saveAction={{
          onClick: onSave,
          loading,
          disabled: saveDisabled,
        }}
        onCancel={onClose}
        position='sticky'
      />
    </Drawer>
  );
};
