import { LeadAPI } from '@/shared/types/api';
import { getLeadType } from '../../helper';
import { isValidHttpUrl } from '@/infrastructure/validations';
import userIcon from '@/views/images/user.svg';
import { getFullName } from '@/shared/business-logic/features/profile/getter';

export const getAvatar = (lead: LeadAPI) => {
  const leadType = getLeadType(lead);
  return leadType === 'lt2lt'
    ? lead.lt2lt.profileImageUrl
    : isValidHttpUrl(lead.profileImageUrl)
    ? lead.profileImageUrl
    : lead.businessCardUrls?.length > 0 && isValidHttpUrl(lead.businessCardUrls?.[0])
    ? lead.businessCardUrls[0]
    : userIcon;
};

export const getLeadDisplayName = (lead: LeadAPI) => {
  const leadType = getLeadType(lead);

  const constructedName = leadType === 'lt2lt' ? getFullName(lead.lt2lt) : getFullName(lead);

  if (!constructedName) {
    return lead.email;
  }

  return constructedName;
};

export const lineClampCount = 1;
