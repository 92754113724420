import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addCodeAction, resetAuthCallbackState } from '@/application/actions/landing';
import { useAppSelector } from '@/application/hooks';
import { routePaths } from '@/infrastructure/constants';
import AddCodePopup from './add-code-popup';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ThemeProvider } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

interface AddCodeProps {
  suppress?: boolean;
}

export default function AddCode(props: AddCodeProps): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const { theme: muiTheme } = useMuiTheme();
  const { getAccessTokenSilently } = useAuth0();

  const account = useAppSelector(state => state.account);

  // Add code after login or while logged in
  const addCode = useAppSelector(state => state.landing.authCallbackState?.ltLoginCode);

  // Added code through signup
  const addedCodeAfterSignup = useAppSelector(state =>
    state.landing.authCallbackState?.signup
      ? state.landing.authCallbackState.ltSignupCode
      : undefined,
  );
  const [showAddCodePopup, setShowAddCodePopup] = useState(addCode ? true : false);

  useEffect(() => {
    if (addCode && !showAddCodePopup) {
      setShowAddCodePopup(true);
    } else if (!addCode) {
      setShowAddCodePopup(false);
    }
  }, [addCode, showAddCodePopup]);

  useEffect(() => {
    if (account && Object.keys(account).length > 0 && addedCodeAfterSignup)
      dispatch(addCodeAction(addedCodeAfterSignup, getAccessTokenSilently, false));
  }, [account, addCode, addedCodeAfterSignup, dispatch, getAccessTokenSilently]);

  const addCodePopupOnClose = () => {
    setShowAddCodePopup(false);
    dispatch(resetAuthCallbackState());
  };

  if (
    location.pathname === routePaths.LANDING.LOGIN_REGISTER ||
    location.pathname.includes('md/linkcode')
  )
    return null;

  return (
    <ThemeProvider theme={muiTheme}>
      {!props.suppress && showAddCodePopup && (
        <AddCodePopup onCloseClick={addCodePopupOnClose} code={addCode} />
      )}
    </ThemeProvider>
  );
}
