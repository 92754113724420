import {
  isAlphaNumeric,
  isAlphaNumericLowerCase,
  isAlphabetsWithSpecificChars,
  isOnlyNumbers,
  isOnlyNumbersAndSpaces,
  isOnlyNumbersAndSpacesAndDashes,
} from '@/infrastructure/helper';
import { ObjVal } from '@/shared/types/util';
import TooltipInfo from '@/components/TooltipInfo';
import { Box, TextField as MuiTextField, TextFieldProps } from '@mui/material';

export const InputRestrictions = {
  ONLY_ALPHABETS: 'alphabets',
  ONLY_NUMBERS: 'numbers',
  ALPHABETS_WITH_SPECIFIC_CHARS: 'alphabets with specific chars',
  ALPHA_NUMERIC: 'alphabets and numbers',
  ALPHA_NUMERIC_LOWERCASE: 'lowercase alphabets and numbers',
  ONLY_NUMBERS_AND_SPACES: 'numbers and spaces',
  ONLY_NUMBERS_AND_SPACES_AND_DASHES: 'numbers and spaces and dashes',
};

type Props = TextFieldProps & {
  maxLength?: number;
  inputRestrictions?: ObjVal<typeof InputRestrictions>;
  startAdornment?: TextFieldProps['InputProps']['startAdornment'];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  tooltip?: string;
  isPhone?: boolean;
};

export const TextField = ({
  maxLength,
  inputRestrictions,
  startAdornment: startAdornmentProp,
  onChange,
  multiline,
  tooltip,
  isPhone,
  ...textFieldProps
}: Props) => {
  const handleOnChange = e => {
    let isValid = true;
    if (inputRestrictions) {
      if (inputRestrictions === InputRestrictions.ONLY_ALPHABETS)
        isValid = isAlphabetsWithSpecificChars(e.target.value);
      else if (inputRestrictions === InputRestrictions.ONLY_NUMBERS)
        isValid = isOnlyNumbers(e.target.value);
      else if (inputRestrictions === InputRestrictions.ONLY_NUMBERS_AND_SPACES)
        isValid = isOnlyNumbersAndSpaces(e.target.value);
      else if (inputRestrictions === InputRestrictions.ONLY_NUMBERS_AND_SPACES_AND_DASHES)
        isValid = isOnlyNumbersAndSpacesAndDashes(e.target.value);
      else if (inputRestrictions === InputRestrictions.ALPHA_NUMERIC)
        isValid = isAlphaNumeric(e.target.value);
      else if (inputRestrictions === InputRestrictions.ALPHA_NUMERIC_LOWERCASE)
        isValid = isAlphaNumericLowerCase(e.target.value);
    }

    if (isValid === true) {
      if (onChange) onChange(e);
    }
  };

  const startAdornment = (
    <Box display='flex' alignSelf={multiline && 'self-start'}>
      <Box height='max-content' display='flex' mr={0.5}>
        {startAdornmentProp}
      </Box>
      {isPhone ? '+' : ''}
    </Box>
  );

  const field = (
    <MuiTextField
      fullWidth
      {...textFieldProps}
      onChange={handleOnChange}
      inputProps={{ ...(textFieldProps.inputProps || {}), maxLength }}
      InputProps={{ ...(textFieldProps.InputProps || {}), startAdornment }}
      multiline={multiline}
      rows={multiline && 3}
    />
  );

  return tooltip ? (
    <TooltipInfo onHover text={tooltip} placement='left'>
      {field}
    </TooltipInfo>
  ) : (
    field
  );
};
