import { StaticProfileConfig } from '@/shared/types/api';
import { Link, Stack } from '@mui/material';
import CookieSettings from './CookieSettings';

interface Props {
  imprint?: StaticProfileConfig['footer']['imprint'];
  privacyPolicy?: StaticProfileConfig['footer']['privacyPolicy'];
  renderCookieSettings?: boolean;
}

export default function Policies({ imprint, privacyPolicy, renderCookieSettings }: Props) {
  return (
    <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
      {imprint && (
        <Link href={imprint.url} target='_blank' rel='noopener noreferrer'>
          {imprint.label}
        </Link>
      )}

      {privacyPolicy && (
        <Link href={privacyPolicy.url} target='_blank' rel='noopener noreferrer'>
          {privacyPolicy.label}
        </Link>
      )}
      {renderCookieSettings && <CookieSettings />}
    </Stack>
  );
}
