import { LtDialog, PageContainer } from '@/components';
import withNav from '@/infrastructure/hoc/withNav';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import {
  AccountBalanceWalletOutlined,
  PhoneAndroidOutlined,
  RotateLeftOutlined,
  VideocamOutlined,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import QRBGContentRenderer from './components/QRBackground/QRBgContentRenderer';
import { SCREEN } from '@/views/pages/qrcode-background/common/constants';
import { LtTabsProps } from '@/components/LtTabs';
import WalletContentRenderer from './components/wallet/WalletContentRenderer';

export type HandlersType = {
  handleSave: (type: 'mobile' | 'videocall' | 'wallet') => void;
  handleReset: (type: 'mobile' | 'videocall' | 'wallet') => void;
  saveIsLoading: boolean;
  resetIsLoading: boolean;
};

const SharingDesign = () => {
  const { t } = useAppTranslation();
  const [handlers, setHandlers] = useState<HandlersType>(null);
  const [showResetPopup, setShowResetPopup] = useState(false);

  const currentTab = useRef<'mobile' | 'videocall' | 'wallet'>(SCREEN.WALLET);

  const onResetClick = () => {
    setShowResetPopup(false);
    handlers?.handleReset(currentTab.current);
  };

  const tabs: LtTabsProps = useMemo(
    () => ({
      orientation: 'vertical',
      onChangeCallback: newScreen => (currentTab.current = newScreen),
      tabs: [
        {
          icon: <AccountBalanceWalletOutlined />,
          iconPosition: 'start' as const,
          label: t('sharingDesign.wallet.tab'),
          value: SCREEN.WALLET,
          component: (
            <Box px={2}>
              <WalletContentRenderer
                title={t('sharingDesign.wallet.title')}
                setHandlers={setHandlers}
              />
            </Box>
          ),
        },
        {
          icon: <VideocamOutlined />,
          iconPosition: 'start' as const,
          label: t('sharingDesign.qrbg.videocall.tab'),
          value: SCREEN.VIDEOCALL,
          component: (
            <Box px={2}>
              <QRBGContentRenderer
                key='videocall'
                title={t('sharingDesign.qrbg.videocall.title')}
                mode='videocall'
                setHandlers={setHandlers}
              />
            </Box>
          ),
        },
        {
          icon: <PhoneAndroidOutlined />,
          iconPosition: 'start' as const,
          label: t('sharingDesign.qrbg.mobile.tab'),
          value: SCREEN.MOBILE,
          component: (
            <Box px={2}>
              <QRBGContentRenderer
                key='mobile'
                title={t('sharingDesign.qrbg.mobile.title')}
                mode='mobile'
                setHandlers={setHandlers}
              />
            </Box>
          ),
        },
      ],
    }),
    [t],
  );

  return (
    <>
      <PageContainer
        maxWidth={'m'}
        whiteCardWrapper
        tabs={tabs}
        bottomActionBar={{
          saveAction: {
            onClick: () => handlers?.handleSave(currentTab.current),
            loading: handlers?.saveIsLoading,
            disabled: handlers?.resetIsLoading,
          },
          customActionsPre: [
            {
              text: t('resetSettings'),
              onClick: () => setShowResetPopup(true),
              startIcon: <RotateLeftOutlined />,
              color: 'secondary',
              variant: 'outlined',
              loading: handlers?.resetIsLoading,
              disabled: handlers?.saveIsLoading,
            },
          ],
        }}
      />
      <LtDialog
        open={showResetPopup}
        onClose={() => setShowResetPopup(false)}
        onCancel={() => setShowResetPopup(false)}
        title={t('requestDelete')}
        size='sm'
        confirmAction={{
          onClick: onResetClick,
          text: t('resetSettings'),
          startIcon: <RotateLeftOutlined />,
          color: 'error',
          loading: handlers?.resetIsLoading,
          variant: 'outlined',
        }}
        withActionDivider
      >
        <Typography>{t('sharingDesign.qrbg.dialogText')}</Typography>
      </LtDialog>
    </>
  );
};

export default withNav(
  SharingDesign,
  {
    tTitle: 'sharingDesign.title',
  },
  {},
);
