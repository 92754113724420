import { Alert, Box, Button, Card, FormControlLabel, IconButton, Switch } from '@mui/material';
import { FullScreenContainer } from './FullScreenContainer';
import { BottomActionContainer } from './BottomActionContainer';
import { useTranslation } from 'react-i18next';
import WestIcon from '@mui/icons-material/West';
import { getLeadOptionTypes } from '@/infrastructure/apis/leadGen';
import { FieldsRenderer } from '../../CreateEditLead/components/FieldsRenderer';
import { NotesField } from '../../CreateEditLead/components/NotesField';
import { LeadDataProps, LeadOptionTypeAPI } from '@/shared/types/api';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LeadOptionsRenderer } from '../../RenderLeadOptions';
import { useAppSelector } from '@/application/hooks';
import { ImagesSlider } from '../../components/ImagesSlider';

type Props = {
  businessCardUrls: string[];
  values: Partial<LeadDataProps>;
  onChange: (values: Partial<LeadDataProps>) => void;
  onFinish: () => void;
  onBackClick: () => void;
  onRetakeClick: () => void;
  allowedFields: Array<keyof LeadDataProps>;
  sendContactToEmail: boolean;
  setSendContactToEmail: (value: boolean) => void;
};

export const EditScreen = ({
  values,
  onChange,
  onFinish,
  onBackClick,
  onRetakeClick,
  allowedFields,
  businessCardUrls,
  sendContactToEmail,
  setSendContactToEmail,
}: Props) => {
  const [leadOptionTypes, setLeadOptionTypes] = useState<Array<LeadOptionTypeAPI>>([]);
  const account = useAppSelector(state => state.account);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (!account.id) return;

      try {
        const data = await getLeadOptionTypes(getAccessTokenSilently, account.id);
        setLeadOptionTypes(data);
      } catch {
        //
      }
    };
    fetchData();
  }, [account.id, getAccessTokenSilently]);

  const allValuesEmpty = !allowedFields.some(field => !!values[field]);

  const hasNotesField = allowedFields.includes('notes');
  const fieldsWithoutNotes = allowedFields.filter(field => field !== 'notes');

  return (
    <FullScreenContainer>
      <IconButton
        sx={{ p: 1.6, width: 'max-content' }}
        onClick={onBackClick}
        size='large'
        aria-label={t('ariaGoBackToPreviewsScreen')}
      >
        <WestIcon />
      </IconButton>
      <Box pb={2.1} px={1.6}>
        <Card>
          <ImagesSlider images={businessCardUrls} />
        </Card>
        {hasNotesField && (
          <NotesField
            sx={{ my: 2 }}
            value={values.notes || ''}
            onChange={value => onChange({ ...values, notes: value })}
          />
        )}
        <LeadOptionsRenderer
          leadOptionTypes={leadOptionTypes}
          leadOptions={values.leadOptions}
          onChange={value => onChange({ ...values, leadOptions: value })}
        />
        <Box display='flex' flexDirection='column' gap={2} mt={2}>
          <FieldsRenderer
            fields={fieldsWithoutNotes}
            values={values}
            onChange={values => onChange(values)}
          />
        </Box>
        {allValuesEmpty && (
          <Alert sx={{ mt: 4 }} severity='warning' variant='outlined'>
            {t('businessCardScanner.enterMinOne')}
          </Alert>
        )}
      </Box>
      <BottomActionContainer>
        {values['email'] && (
          <FormControlLabel
            sx={{ mb: 2, mx: 0.5 }}
            label={t('businessCardScanner.sendContactToEmail', { email: values['email'] })}
            control={<Switch checked={sendContactToEmail} />}
            onChange={() => setSendContactToEmail(!sendContactToEmail)}
          />
        )}
        <Box display='flex' gap='1.3rem'>
          <Button fullWidth size='large' color='primary' variant='outlined' onClick={onRetakeClick}>
            {t('businessCardScanner.retake')}
          </Button>
          <Button fullWidth size='large' onClick={onFinish} disabled={allValuesEmpty}>
            {t('businessCardScanner.next')}
          </Button>
        </Box>
      </BottomActionContainer>
    </FullScreenContainer>
  );
};
