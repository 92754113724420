import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { Account, Theme, WalletCardColors } from '@/shared/types/api';
import { updateQrBgs } from '@/application/actions/md/customization';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import {
  deleteThemeConfig,
  fetchThemeConfig,
  writeThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

export const useConfig = () => {
  const { notify: toast } = useLtNotifications();
  const account = useAppSelector<Account>(state => state.account);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();

  const [defaultMobileBg, setDefaultMobileBg] = useState<string>('');
  const [defaultVideoCallBg, setDefaultVideoCallBg] = useState<string>('');

  const [additionalMobileBgs, setAdditionalMobileBgs] = useState<Array<string>>([]);
  const [additionalVideoCallBgs, setAdditionalVideoCallBgs] = useState<Array<string>>([]);

  const [walletColors, setWalletColors] = useState<WalletCardColors>();

  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const initiallyFetched = useRef(false);

  const { setIsUnsaved, withUnsavedSetter } = useUnsavedStatusSetter();

  const defaults = useMemo(
    () => ({
      mobile: {
        image: defaultMobileBg,
        setter: withUnsavedSetter(setDefaultMobileBg),
      },
      videocall: {
        image: defaultVideoCallBg,
        setter: withUnsavedSetter(setDefaultVideoCallBg),
      },
    }),
    [defaultMobileBg, defaultVideoCallBg, withUnsavedSetter],
  );

  const additionals = {
    mobile: {
      images: additionalMobileBgs,
      setter: withUnsavedSetter(setAdditionalMobileBgs),
    },
    videocall: {
      images: additionalVideoCallBgs,
      setter: withUnsavedSetter(setAdditionalVideoCallBgs),
    },
  };

  const wallet = {
    colors: walletColors,
    setter: withUnsavedSetter(setWalletColors),
  };

  useDeferredLoader(fetchIsLoading, 'get-qr-config');

  const fetchData = useCallback(async () => {
    setFetchIsLoading(true);
    try {
      const [{ value: mobile }, { value: videocall }, { value: walletColors }] = await Promise.all([
        fetchThemeConfig(getAccessTokenSilently, { key: THEME_CONFIG_KEYS.QR_BG_IMAGES_MOBILE }),
        fetchThemeConfig(getAccessTokenSilently, { key: THEME_CONFIG_KEYS.QR_BG_IMAGES_VIDEOCALL }),
        fetchThemeConfig(getAccessTokenSilently, { key: THEME_CONFIG_KEYS.WALLET_CARD_COLORS }),
      ]);

      setDefaultMobileBg(mobile.default);
      setAdditionalMobileBgs([...mobile.additional]);
      setDefaultVideoCallBg(videocall.default);
      setAdditionalVideoCallBgs([...videocall.additional]);
      setWalletColors(walletColors);
    } catch (error) {
      toast.error(t('error.general'));
    }
    setFetchIsLoading(false);
    initiallyFetched.current = true;
  }, [getAccessTokenSilently, t, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReset = async (type: 'mobile' | 'videocall' | 'wallet') => {
    setResetIsLoading(true);
    try {
      await deleteThemeConfig(getAccessTokenSilently, {
        key:
          type === 'mobile'
            ? THEME_CONFIG_KEYS.QR_BG_IMAGES_MOBILE
            : type === 'videocall'
            ? THEME_CONFIG_KEYS.QR_BG_IMAGES_VIDEOCALL
            : THEME_CONFIG_KEYS.WALLET_CARD_COLORS,
      });
      initiallyFetched.current = false;
      await fetchData();
      setResetIsLoading(false);
      setIsUnsaved(false);
      toast.success(t('changesSaved'), { id: 'qr-config-reset' });
    } catch (error) {
      setResetIsLoading(false);
      toast.error(t('error.general', { id: 'qr-config-reset' }));
    }
  };

  const handleSave = async (type: 'mobile' | 'videocall' | 'wallet') => {
    setSaveIsLoading(true);
    if (type === 'mobile' || type === 'videocall') {
      let ids = { id: account.id, uniqueId: theme?.themeInternal.uniqueId };
      const toUpdateQrBgs = {
        defaultMobileBg,
        defaultVideoCallBg,
        additionalMobileBgs,
        additionalVideoCallBgs,
      };
      dispatch(
        updateQrBgs(
          type,
          ids,
          toUpdateQrBgs,
          getAccessTokenSilently,
          () => {
            toast.success(t('changesSaved'), { id: 'qr-config-save' });
            setIsUnsaved(false);
            fetchData();
            setSaveIsLoading(false);
          },
          () => {
            setSaveIsLoading(false);
            toast.error(t('error.general'), { id: 'qr-bg-config-save' });
          },
        ),
      );
    } else {
      // handle wallet type
      try {
        await writeThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.WALLET_CARD_COLORS,
          value: walletColors,
        });
        setSaveIsLoading(false);
        setIsUnsaved(false);
        fetchData();
        toast.success(t('changesSaved'), { id: 'wallet-config-save' });
      } catch (error) {
        setSaveIsLoading(false);
        toast.error(t('error.general'), { id: 'wallet-config-save' });
      }
    }
  };

  return {
    defaults,
    additionals,
    wallet,
    handleSave,
    handleReset,
    refetch: fetchData,
    saveIsLoading,
    resetIsLoading,
    fetchIsLoading,
    initialFetchIsLoading: fetchIsLoading && !initiallyFetched.current,
  };
};
