import React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { Box, Button, Typography, styled } from '@mui/material';
import { ReactComponent as LemontapsLogo } from '@/views/images/lemontaps-logo-text.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LinkIcon from '@mui/icons-material/Link';
import EastIcon from '@mui/icons-material/East';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setAuthCallbackState } from '@/application/actions/landing';
import { routePaths } from '@/infrastructure/constants';
import config from '@/config/config';
interface Props {
  location: { state: { code: string } };
}

const IconWrapper = styled(Box)({
  width: '18rem',
  svg: {
    width: '100%',
  },
});

const StyledLink = styled(Link)({
  width: '100%',
});

const LoginOrRegister = (props: Props) => {
  const { Translation, t } = useAppTranslation();
  const { isAuthenticated, isLoading: isAuth0Loading, loginWithRedirect } = useAuth0();

  const history = useHistory();
  const dispatch = useDispatch();

  const code = props.location?.state?.code;

  if (!code) {
    return <Redirect to='/' />;
  }

  const routeState = { codeToConnect: code };

  const isLTDomain = window.location.origin === config.REACT_APP_URL;

  const handleAdminLinkClick = () => {
    dispatch(setAuthCallbackState({}));
    if (isAuthenticated) {
      history.push(`/md/linkcode/${code}`);
    } else {
      loginWithRedirect({
        redirectUri: `${window.location.origin}/home?redirect=md/linkcode/${code}`,
        appState: { login: true },
      });
    }
  };

  return (
    <Box
      maxWidth={'80rem'}
      m='auto'
      pt={8}
      px={2}
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <IconWrapper>
        <LemontapsLogo />
      </IconWrapper>
      <Typography variant='h2' sx={{ mt: 5, textAlign: 'center' }}>
        {t('loginOrRegister.title')}
      </Typography>

      {isLTDomain && (
        <StyledLink to={{ pathname: routePaths.REGISTRATION.SIGNUP, state: routeState }}>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            fullWidth
            sx={{ height: '5rem', boxShadow: 'none', mt: '6rem', textTransform: 'none' }}
          >
            {t('loginOrRegister.connectNew')}
          </Button>
        </StyledLink>
      )}
      <StyledLink to={{ pathname: routePaths.LOGIN, state: routeState }}>
        <Button
          startIcon={<LinkIcon />}
          fullWidth
          color='primary'
          variant='outlined'
          sx={{ height: '5rem', boxShadow: 'none', mt: '3rem', textTransform: 'none' }}
        >
          {t('loginOrRegister.connectExisting')}
        </Button>
      </StyledLink>

      {!isAuth0Loading && (
        <Button
          endIcon={<EastIcon />}
          onClick={handleAdminLinkClick}
          fullWidth
          color='primary'
          variant='text'
          sx={{ boxShadow: 'none', mt: '3rem', textTransform: 'none' }}
        >
          {t('loginOrRegister.linkAsAdmin')}
        </Button>
      )}

      <Translation textAlign='center' justifyContent='center' py={4} />
    </Box>
  );
};
export default LoginOrRegister;
