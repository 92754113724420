import {
  Alert,
  Box,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';
import TooltipInfo from '@/components/TooltipInfo';
import {
  ALLOWED_FORMATS,
  FIXED_POSITIONS,
  POSITIONS_DEFAULT,
  UPLOADED_IMG_LIMIT_MO,
  UPLOADED_IMG_LIMIT_VC,
} from './common/constants';
import { ImagesSlider } from '../lead-gen/components/ImagesSlider';
import { Dropzone } from '@/components';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { FileDownloadOutlined } from '@mui/icons-material';
import { useQRBackgrounds } from './useQRBackgrounds';
import QRCode from 'qrcode.react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import LtLoadingButton from '@/components/LtLoadingButton';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { QRBGMode, QRCodeMode, QRPositions } from './common/types';
import ImageCropper from '@/components/ImageCropper';

interface Props {
  screen: QRBGMode;
}

function ScreenRendrer(props: Props) {
  const { t } = useAppTranslation();
  const {
    editRights: { allowOwnQrBgImage },
  } = useEditRights();

  const { desktopView } = useDesktopView();

  const {
    loading,
    genLoading,
    states: {
      qrCodeType,
      qrCodePosition,
      positionConfig,
      qrCodeImgSize,
      bgPreviews,
      qrBgImg,
      downloadReady,
      uncroppedImage,
    },
    setters: { setQRCodeType, setQRCodePosition, setUncroppedImage, setDownloadReady },
    handlers: { handleUpload, handleDownload, handleImageClick, handleCroppedImage },
    qrCodeRef,
    urls,
  } = useQRBackgrounds(props.screen);

  const isMobileScreen = props.screen === 'mobile';

  if (!loading && !bgPreviews.length && !allowOwnQrBgImage) {
    return (
      <Alert severity='info' title={t('qrCodeBackground.alerts.noActionAllowed.title')}>
        {t('qrCodeBackground.alerts.noActionAllowed.content')}
      </Alert>
    );
  }
  return (
    <>
      <div ref={qrCodeRef} style={{ display: 'none' }}>
        <QRCode
          value={qrCodeType === 'online' ? urls?.online : urls?.offline}
          size={qrCodeImgSize}
          includeMargin={true}
          renderAs='svg'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          xmlSpace='preserve'
          crossOrigin=''
        />
      </div>
      {uncroppedImage && (
        <ImageCropper
          image={uncroppedImage}
          onCropClick={url => handleCroppedImage(url)}
          onClose={() => {
            setUncroppedImage('');
            setDownloadReady(true);
          }}
          noAspectRatio={true}
          fixedCrop={true}
          cropOptions={{ width: 100, height: 100 }}
        />
      )}
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        <Typography variant='body1'>{t('qrCodeBackground.mobile.header')}</Typography>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography component='h2' variant='h3'>
              {'1. ' + t('qrCodeBackground.type')}
            </Typography>
            <TooltipInfo
              text={t('qrCodeBackground.typeTooltip')}
              placement={window.innerWidth >= 600 ? 'right-start' : 'bottom'}
              arrow
              icon={{ color: 'text.primary' }}
            />
          </Box>
          <FormControl>
            <RadioGroup
              value={qrCodeType}
              onChange={({ target: { value } }) => setQRCodeType(value as QRCodeMode)}
              row
            >
              <FormControlLabel
                value='online'
                control={<Radio />}
                label={t('qrCodeBackground.online')}
              />
              <FormControlLabel
                value='offline'
                control={<Radio />}
                label={t('qrCodeBackground.offline')}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography component='h2' variant='h3'>
              {'2. ' + t('qrCodeBackground.image')}
            </Typography>
            {allowOwnQrBgImage && (
              <TooltipInfo
                text={t('qrCodeBackground.imageTooltip', {
                  limit: isMobileScreen ? UPLOADED_IMG_LIMIT_MO : UPLOADED_IMG_LIMIT_VC,
                })}
                placement={window.innerWidth >= 600 ? 'right-start' : 'bottom'}
                arrow
                icon={{ color: 'text.primary' }}
              />
            )}
          </Box>
          {bgPreviews.length ? (
            <Box>
              <ImagesSliderStyled
                images={bgPreviews}
                slidesPerView={isMobileScreen ? (desktopView ? 6 : 3) : desktopView ? 4 : 2}
                freeMode={true}
                onClick={e => handleImageClick(e.clickedIndex)}
              />
            </Box>
          ) : null}
          {bgPreviews.length && allowOwnQrBgImage ? <Divider /> : null}
          {!!allowOwnQrBgImage && (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Dropzone
                maxSize={10}
                types={ALLOWED_FORMATS}
                onChange={files => handleUpload(files)}
                fullWidth
                extensionsText={t('qrCodeBackground.uploadSubtext', {
                  limit: isMobileScreen ? UPLOADED_IMG_LIMIT_MO : UPLOADED_IMG_LIMIT_VC,
                })}
              />
            </Box>
          )}
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography component='h2' variant='h3'>
            {'3. ' + t('qrCodeBackground.download')}
          </Typography>
          <Box display={'flex'} justifyContent={'center'}>
            <Paper
              elevation={0}
              variant='outlined'
              sx={{
                display: 'flex',
                maxWidth: desktopView ? 0.8 : 1,
                mt: 1,
                borderRadius: 1,
                ...(!desktopView && { flexDirection: 'column' }),
              }}
            >
              {loading && !genLoading ? null : !qrBgImg ? (
                <Alert severity='info' title={t('qrCodeBackground.alerts.noSelection.title')}>
                  {t('qrCodeBackground.alerts.noSelection.content')}
                </Alert>
              ) : (
                <>
                  <Box
                    display={'flex'}
                    flexBasis={'25%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={theme => ({
                      borderRight: desktopView ? '1px solid' : undefined,
                      borderBottom: !desktopView ? '1px solid' : undefined,
                      borderColor: theme.palette.divider,
                      p: 2,
                    })}
                  >
                    <FormControl>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                        QR Position:
                      </Typography>
                      <RadioGroup
                        value={
                          !positionConfig && qrCodePosition === 'default'
                            ? POSITIONS_DEFAULT[props.screen]
                            : qrCodePosition
                        }
                        onChange={({ target: { value } }) =>
                          setQRCodePosition(value as QRPositions)
                        }
                        row={!desktopView}
                      >
                        {positionConfig && (
                          <FormControlLabel
                            value={'default'}
                            control={<Radio />}
                            label={t('qrCodeBackground.positions.default')}
                          />
                        )}
                        {FIXED_POSITIONS[props.screen].map((pos, index) => (
                          <FormControlLabel
                            key={index}
                            value={pos}
                            control={<Radio />}
                            label={t(`qrCodeBackground.positions.${pos}`)}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexBasis={'75%'}
                  >
                    <Box
                      display={'flex'}
                      flexBasis={isMobileScreen ? '60%' : '100%'}
                      flexGrow={0}
                      p={2}
                    >
                      <CardMedia
                        component={'img'}
                        src={qrBgImg}
                        sx={{
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Paper>
          </Box>
          <Box textAlign={'center'}>
            <LtLoadingButton
              onClick={() => handleDownload(qrBgImg, 'lt_source=qr-mobile-bg')}
              disabled={!downloadReady}
              size='large'
              startIcon={<FileDownloadOutlined />}
            >
              {t('download')}
            </LtLoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ScreenRendrer;

const ImagesSliderStyled = styled(ImagesSlider)({
  '.swiper-slide': {
    cursor: 'pointer',
  },
});
