import { getBeprintSsoUrl } from '@/infrastructure/apis/account';
import { CSS_VARS } from '@/infrastructure/constants';
import withNav from '@/infrastructure/hoc/withNav';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useEffect, useState } from 'react';

interface Props {
  variant?: 'product' | 'approval';
}

export function PaperCardsPage(props: Props) {
  const { getAccessTokenSilently } = useAuth0();

  const [ssoUrl, setSsoUrl] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    getBeprintSsoUrl(props.variant || 'product', getAccessTokenSilently)
      .then(res => {
        setSsoUrl(res.data.data.ssoUrl);
        setError('');
      })
      .catch(() => setError('Error'));
  }, [getAccessTokenSilently, props.variant]);

  if (error) return <Typography variant='body1'>{error}</Typography>;
  else return <BePrintIFrame src={ssoUrl} />;
}

export default withNav(
  PaperCardsPage,
  {
    tTitle: 'paperCards',
  },
  {},
);

export const BePrintIFrame = styled('iframe')({
  width: '100%',
  minHeight: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})`,
});
