import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const RoleSelector = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const { isThemeAdmin } = useTierInfo();
  const { isFeatureAllowed } = useTierInfo();

  const isUnitsAllowed = isFeatureAllowed(FEATURE.UNITS);

  return (
    <RadioGroup value={value} onChange={({ target: { value } }) => onChange(value)} row>
      <FormControlLabel value={''} control={<Radio />} label={t('employeeType.employee')} />
      {isThemeAdmin && (
        <FormControlLabel
          value='theme_admin'
          control={<Radio />}
          label={t('employeeType.themeAdmin')}
        />
      )}
      {isUnitsAllowed && (
        <FormControlLabel
          value={'unit_admin'}
          control={<Radio />}
          label={t('employeeType.unitAdmin')}
        />
      )}
    </RadioGroup>
  );
};
