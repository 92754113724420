import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { generateSecretToken } from '@/infrastructure/apis/md/idp';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';
import { InformativeInput } from '../InformativeInput';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const hideBody = {
  opacity: 0,
  pointerEvents: 'none',
};

const SCIM_BASE_URL = process.env.REACT_APP_API_BASE_URL + 'scim/v2';

type Props = {
  isRegeneration?: boolean;
  alert?: React.ReactNode;
};

export const GenerateKeyStep = ({ isRegeneration, alert }: Props) => {
  const [apiKeyToken, setApiKeyToken] = useState('');
  const [apiKeyGenerated, setApiKeyGenerated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();

  useEffect(() => {
    const generateToken = async () => {
      try {
        const {
          data: { secretToken },
        } = await generateSecretToken(getAccessTokenSilently, id);
        setApiKeyGenerated(true);
        setApiKeyToken(secretToken);
      } catch (error) {
        toast.error('Error generating token');
      }
    };
    generateToken();
  }, [getAccessTokenSilently, id, toast]);

  return (
    <>
      <TextsRenderer
        mb={1.2}
        title={t('idp.scim.steps.setupScim.title')}
        texts={[t('idp.scim.steps.setupScim.description')]}
      />

      {/* {!isRegeneration && <AnchorText mb={3.2} href='#' text={t('idp.scim.learnMoreAboutScim')} />} */}

      {alert && <Box mb={3.2}>{alert}</Box>}
      <Box gap={1.8} display='flex' flexDirection='column' sx={!apiKeyGenerated && hideBody}>
        <InformativeInput value={SCIM_BASE_URL} label={t('idp.scim.steps.setupScim.scimBaseUrl')} />
        <InformativeInput
          value={apiKeyToken}
          label={t('idp.scim.steps.setupScim.apiKey')}
          helperText={t('idp.scim.steps.setupScim.apiKeyHelperText')}
        />
      </Box>
    </>
  );
};
