import { AddEditLinkDialog } from '@/components/Links/AddEditLinkDialog';
import { ProfileEditProps } from '@/components/Profile/types';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { Link } from '@/shared/types/api';
import { AddCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = Pick<
  ProfileEditProps['linksBoxEditProps'],
  'linkTypes' | 'linkTemplates' | 'editRightAllowed' | 'onAdd'
>;

export default function AddLinkButton({
  linkTemplates,
  linkTypes,
  editRightAllowed,
  onAdd,
}: Props) {
  const { t } = useTranslation();
  const { notifyInfo } = useLtNotifications();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const addHandler = useCallback(
    async (link: Partial<Link>) => {
      setLoading(true);
      await onAdd?.(link);
      setLoading(false);
      setDialogOpen(false);
    },
    [onAdd],
  );
  return (
    <>
      <Button
        onClick={() =>
          editRightAllowed ? setDialogOpen(true) : notifyInfo(t('adminDisabledEditRight'))
        }
        startIcon={<AddCircleOutline />}
        aria-label={t('ariaAddLink')}
      >
        {t('add')}
      </Button>

      <AddEditLinkDialog
        mode='standard'
        open={dialogOpen}
        linkTypes={linkTypes}
        templates={linkTemplates}
        onClose={() => setDialogOpen(false)}
        loading={loading}
        onSave={addHandler}
      />
    </>
  );
}
