import { LinkTemplate } from '@/shared/types/api';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type Props = {
  templates: LinkTemplate[];
  value: number;
  onChange: (value: number) => void;
  showCreateOwnTemplate?: boolean;
};
export const TemplateSelectorUser = ({
  templates,
  onChange,
  value,
  showCreateOwnTemplate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id='labelId'>{t('selectTemplate')}</InputLabel>
      <Select
        value={value || ''}
        labelId={'labelId'}
        label={t('selectTemplate')}
        onChange={({ target: { value } }) => onChange(value as number)}
      >
        <MenuItem sx={{ display: 'none' }} value=''>
          {t('selectTemplate')}
        </MenuItem>
        {templates.map(template => (
          <MenuItem key={template.id} value={template.id}>
            {template.name}
          </MenuItem>
        ))}
        {showCreateOwnTemplate && <Divider />}
        {showCreateOwnTemplate && (
          <MenuItem value={-1}>
            <Box display='flex' alignItems='center' gap={1}>
              <AddCircleOutlineIcon />
              <ListItemText>{t('createOwnLink')}</ListItemText>
            </Box>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
