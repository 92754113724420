import { ProfileEditProps } from '@/components/Profile/types';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { AddCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = Pick<ProfileEditProps['filesBoxEditProps'], 'editRightAllowed' | 'onAddClick'>;

// TODO: Extract logic from AddNewFilePopup and create UI-only component
export default function AddFilePopup({ editRightAllowed, onAddClick }: Props) {
  const { t } = useTranslation();
  const { notifyInfo } = useLtNotifications();
  return (
    <>
      <Button
        onClick={() => (editRightAllowed ? onAddClick() : notifyInfo(t('adminDisabledEditRight')))}
        startIcon={<AddCircleOutline />}
        aria-label={t('ariaAddFile')}
      >
        {t('add')}
      </Button>
    </>
  );
}
