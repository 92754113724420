import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { AlertBase, AlertPositioned } from '@/shared/types/global';
import { Alert, AlertTitle } from '@mui/material';
import { checkConditions } from '../util';

interface GsAlertProps {
  alert: undefined | AlertBase | AlertPositioned;
  currentPosition?: 'pre' | 'post';
}

export default function GsAlert({ alert, currentPosition }: GsAlertProps) {
  const tierInfo = useTierInfo();

  if (!alert) return null;
  if (!checkConditions(tierInfo, alert.conditions)) return null;
  if (currentPosition && 'position' in alert && alert.position !== currentPosition) return null;

  return (
    <Alert severity={alert.severity || 'info'} title={alert.title} sx={{ my: 2 }}>
      {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
      {alert.content}
    </Alert>
  );
}
