import { FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { differenceInCalendarYears, getMonth, getYear, setMonth, setYear } from '../utils/utils';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  coupledView?: boolean;
}

const generateYearSelection = () => {
  const diffToBaseYear = differenceInCalendarYears(new Date(), new Date('2020-01-01')) + 1;
  return Array(diffToBaseYear + 1)
    .fill(0)
    .map((_y, i) => 2020 + i);
};

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  coupledView,
}: HeaderProps) => {
  const MONTHS_EN = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const MONTHS_DE = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ];

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setDate(setMonth(date, parseInt(event.target.value as string, 10)));
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setDate(setYear(date, parseInt(event.target.value as string, 10)));
  };

  const { activeLanguage, t } = useAppTranslation();

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item sx={{ padding: '5px' }}>
        <IconButton
          sx={{
            padding: '10px',
            '&:hover': {
              background: 'none',
            },
            display: prevDisabled && coupledView ? 'none' : undefined,
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
          aria-label={t('ariaMoveMonthBack')}
        >
          <ChevronLeft
            sx={{
              fontSize: 24,
              color: prevDisabled ? 'disabled' : 'action',
            }}
          />
        </IconButton>
      </Grid>
      <Grid item>
        <FormControl variant='standard'>
          <Select
            value={getMonth(date)}
            onChange={handleMonthChange}
            MenuProps={{ disablePortal: true }}
            aria-label={t('ariaSelectMonth')}
          >
            {(activeLanguage.startsWith('de') ? MONTHS_DE : MONTHS_EN).map((month, idx) => (
              <MenuItem key={month} value={idx}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl variant='standard'>
          <Select
            value={getYear(date)}
            onChange={handleYearChange}
            MenuProps={{ disablePortal: true }}
            aria-label={t('ariaSelectYear')}
          >
            {generateYearSelection().map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{ padding: '5px' }}>
        <IconButton
          sx={{
            padding: '10px',
            '&:hover': {
              background: 'none',
            },
            display: nextDisabled && coupledView ? 'none' : undefined,
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
          aria-label={t('ariaMoveMonthForward')}
        >
          <ChevronRight
            sx={{
              fontSize: 24,
              color: nextDisabled ? 'disabled' : 'action',
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
