import ThemeOverride from '@/components/ThemeOverride';
import { getMidmostHexValue, invertHex, isDarkHex } from '@/infrastructure/helper';
import { UrlsBoxItemLink } from '@/shared/types/api';
import { Box, Button, styled } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { PropsWithChildren } from 'react';

type Props = UrlsBoxItemLink & {
  onClick?: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
  ariaHidden?: boolean;
};

export default function UrlButtonItem({
  backgroundCss,
  text,
  iconUrl,
  icon,
  url,
  onClick,
  disabled,
  ariaHidden,
}: Props) {
  const buttonId = useId();
  try {
    /**
     * a better approach would be:
     *  const contrastColor = theme.palette.getContrastText(
     *    backgroundCss.includes('gradient') ? getMidmostHexValue(backgroundCss) : backgroundCss,
     *  );
     * -> supports color definitions other than gradients and hex codes
     * -> returns the actual themed contrast text color
     * however: it returns a rgba encoded color -> we cannot style the icon image given this
     *
     * on the image box, using a mask would be cool ( backgroundColor: contrastColor, mask: `url(${iconUrl}) no-repeat center / contain` ) but that leads to a cors error
     */
    const contrastColor = invertHex(
      backgroundCss.includes('gradient') ? getMidmostHexValue(backgroundCss) : backgroundCss,
      true,
    );
    return (
      <ButtonWrapper
        backgroundCss={backgroundCss}
        contrastColor={contrastColor}
        buttonId={buttonId}
      >
        <StyledButton
          id={buttonId}
          href={!disabled ? url : undefined}
          target='_blank'
          onClick={!disabled ? onClick : undefined}
          aria-disabled={disabled}
          aria-hidden={ariaHidden}
          tabIndex={disabled ? -1 : 0}
          sx={{
            pointerEvents: disabled ? 'none' : undefined,
          }}
          size='large'
          className='lt-url-button' // this button can be targeted from theme
          startIcon={
            icon || (
              <Box
                component='img'
                src={iconUrl}
                aria-hidden='true'
                sx={theme => ({
                  width: theme.spacing(2.4),
                  height: theme.spacing(2.4),
                  filter: `brightness(0) ${isDarkHex(contrastColor) ? '' : 'invert(1)'}`,
                })}
              />
            )
          }
          fullWidth
        >
          {text}
        </StyledButton>
      </ButtonWrapper>
    );
  } catch {
    // there might be errors with color calculations
    // ToDo: Prevent
    return null;
  }
}

const StyledButton: typeof Button = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflowWrap: 'anywhere',
  paddingLeft: theme.spacing(6.4),
  paddingRight: theme.spacing(6.4),
  textAlign: 'center',

  '& .MuiButton-startIcon': {
    position: 'absolute',
    left: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
}));

const ButtonWrapper = ({
  children,
  backgroundCss,
  contrastColor,
  buttonId,
}: PropsWithChildren<{ backgroundCss: string; contrastColor: string; buttonId: string }>) => {
  if (!backgroundCss.includes('gradient')) {
    // probably a valid color code
    return (
      <ThemeOverride
        overrides={{
          palette: {
            primaryButton: {
              main: backgroundCss,
              dark: backgroundCss,
              light: backgroundCss,
              contrastText: contrastColor,
            },
          },
        }}
      >
        {children}
      </ThemeOverride>
    );
  } else {
    // probably a gradient
    return (
      <Box
        sx={{
          flex: 1,
          [`& #${buttonId}, & #${buttonId}:focus, & #${buttonId}:active, & #${buttonId}:hover`]: {
            background: '#000000',
            backgroundImage: backgroundCss,
          },
        }}
      >
        {children}
      </Box>
    );
  }
};
