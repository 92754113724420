import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import {} from '@/infrastructure/apis/edit-profile';
import FeatureTeaser from '../../teaser/featureTeaser';
import { FEATURE, THEME_CONFIG_KEYS } from '@/shared/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import teaserImage from '@/views/images/teaser/contact-form.jpg';
import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { writeThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import Sorter from './ContactFieldsSorter';
import withNav from '@/infrastructure/hoc/withNav';
import { PageContainer } from '@/components';
import { ContactFormField, ThemeBoxStyle } from '@/shared/types/global';
import { Account, LeadAPI, StaticProfileConfig } from '@/shared/types/api';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { ContactForm } from '@/components/ContactForm';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { fetchProfile } from '@/infrastructure/apis/profile';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '@/application/hooks';
import { TFunction } from 'i18next';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import TooltipInfo from '@/components/TooltipInfo';
import { defaultPalette } from '@/config/theme/palette';
import { LtTabsProps } from '@/components/LtTabs';
import { ContactPageOutlined } from '@mui/icons-material';

enum SCREEN {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

type StaticLeadGenConfig = Omit<StaticProfileConfig['leadGen'], 'fields' | 'flow'>;

const getDefaultLeadGenConfig = (t: TFunction): StaticLeadGenConfig => ({
  submitButtonColor: defaultPalette.primaryButton.main,
  labels: {
    title: t('leaveYourContact'),
    gdprConsent: t('gdprConsent.pt1'),
    submitButton: t('submit'),
    field: {},
  },
});

const ContactFormFields = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'md-settings-contact-form');

  if (isFeatureAllowed(FEATURE.LEAD_GEN_FORM_CONFIG)) {
    return <FeatureContent />;
  } else {
    return (
      <FeatureTeaser text={t('upgradeTeaser.feature.leadGenContactForm')} imageSrc={teaserImage} />
    );
  }
};

export default withNav(ContactFormFields, { tTitle: 'contactFormPageHeader' }, {});

const fetchLeadKeys = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: fieldKeysResponse } = await fetchThemeConfig<(keyof LeadAPI)[]>(
    getAccessTokenSilently,
    THEME_CONFIG_KEYS.LEAD_DETAILS_KEYS,
  );

  return fieldKeysResponse;
};

const fetchContactForm = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: contactForm } = await fetchThemeConfig<{
    fields: Array<ContactFormField>;
  }>(getAccessTokenSilently, THEME_CONFIG_KEYS.PROFILE_PAGE_CONTACT_FORM);

  return contactForm;
};

const CardRenderer = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const FeatureContent = () => {
  const { notify: toast } = useLtNotifications();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [contactFormFields, setContactFormFields] = useState<{
    shown: ContactFormField[];
    notShown: ContactFormField[];
  }>({
    shown: [],
    notShown: [],
  });
  const [isSaving, setIsSaving] = useState(false);
  const [staticLeadGenConfig, setStaticLeadGenConfig] = useState<
    StaticLeadGenConfig & { boxStyle: ThemeBoxStyle }
  >(null);

  useDeferredLoader(isDataLoading, 'data-templates-contact-form-loader-id');
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const { getAccessTokenSilently } = useAuth0();
  const { t, activeLanguage } = useAppTranslation();
  const account: Account = useAppSelector(state => state.account);

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const [fieldKeysResponse, contactFormResponse, staticProfileConfig] = await Promise.all([
          fetchLeadKeys(getAccessTokenSilently),
          fetchContactForm(getAccessTokenSilently),
          fetchProfile(account.username, activeLanguage || 'en'),
        ]);
        const { profile } = staticProfileConfig;
        setStaticLeadGenConfig({
          ...(profile.leadGen || getDefaultLeadGenConfig(t)),
          boxStyle: profile.config.metaTheme.boxStyle,
        });

        const { fields: shownFields } = contactFormResponse;

        const filterFields = ['gdprLegalBasis', 'preferredLang'];
        const leadKeys = fieldKeysResponse.filter(x => !filterFields.includes(x));
        const notShownFields = leadKeys.filter(x => !shownFields.some(({ name }) => name === x));

        setContactFormFields({
          shown: shownFields,
          notShown: notShownFields.map(name => ({ name })),
        });
      } catch (error) {
        toast.error(t('error.general'));
      }
      setIsDataLoading(false);
    };

    fetchData();
  }, [getAccessTokenSilently, t, activeLanguage, account.username, toast]);

  const handleSave = async () => {
    const shownFields = contactFormFields.shown;
    if (!shownFields?.length) {
      toast.error(t('contactFormEdit.atLeastOneRequired'));
      return;
    }
    setIsSaving(true);
    const payload = {
      fields: shownFields,
    };
    try {
      await writeThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.PROFILE_PAGE_CONTACT_FORM,
        value: payload,
      });
      toast.success(t('changesSaved'), { id: 'contact-form-saving' });
      setIsUnsaved(false);
    } catch (error) {
      toast.error(t('error.general'), { id: 'contact-form-saving' });
    }
    setIsSaving(false);
  };

  const saveDisabled = !contactFormFields.shown?.some(({ required }) => required);

  const handleFieldsChange = useCallback(
    (fields: { shown: ContactFormField[]; notShown: ContactFormField[] }) => {
      setContactFormFields(fields);
      setIsUnsaved(true);
    },
    [setIsUnsaved],
  );

  const externalFormContent = useMemo(
    () => (
      <Grid container spacing={1.6}>
        <Grid item xs={12} xl={8}>
          <CardRenderer>
            <Typography sx={{ mb: 1.6 }} variant='h2'>
              {t('contactFormEdit.title')}
            </Typography>
            {contactFormFields && (
              <Sorter fields={contactFormFields} onFieldsChange={handleFieldsChange} />
            )}
          </CardRenderer>
        </Grid>
        <Grid item xs={6} xl={4}>
          <Card>
            <CardHeader
              sx={theme => ({ bgcolor: theme.palette.action.hover })}
              title={t('livePreview')}
              titleTypographyProps={{
                component: 'h3',
                variant: 'h2',
                color: 'secondary',
              }}
            />
            <Divider />
            <CardContent>
              {staticLeadGenConfig && (
                <ContactForm {...staticLeadGenConfig} fields={contactFormFields.shown} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    ),
    [contactFormFields, handleFieldsChange, staticLeadGenConfig, t],
  );

  const internalFormContent = useMemo(
    () => (
      <Alert severity='info' variant='outlined'>
        <AlertTitle>{t('contactFormEdit.internal.teaser.title')}</AlertTitle>
        {t('contactFormEdit.internal.teaser.body')}
      </Alert>
    ),
    [t],
  );

  const tabs: LtTabsProps = useMemo(
    () => ({
      orientation: 'horizontal',
      tabs: [
        {
          icon: <ContactPageOutlined />,
          iconPosition: 'start' as const,
          value: SCREEN.EXTERNAL,
          label: t('contactFormEdit.external.title'),
          component: <Box py={2}>{externalFormContent}</Box>,
        },
        {
          icon: <ContactPageOutlined />,
          iconPosition: 'start' as const,
          value: SCREEN.INTERNAL,
          label: t('contactFormEdit.internal.title'),
          component: <Box py={2}>{internalFormContent}</Box>,
        },
      ],
    }),
    [externalFormContent, internalFormContent, t],
  );

  if (isDataLoading) return null;

  return (
    <PageContainer
      whiteCardWrapper
      bottomActionBar={{
        saveAction: { onClick: handleSave, loading: isSaving, disabled: saveDisabled },
        customElemsPre: [
          saveDisabled && (
            <TooltipInfo text={t('contactFormEdit.saveTooltip')} icon={{ color: 'text.primary' }} />
          ),
        ].filter(Boolean),
      }}
      maxWidth='l'
      tabs={tabs}
    />
  );
};
