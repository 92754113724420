import { customLightPalette, defaultPalette } from './palette';
import CloseIcon from '@mui/icons-material/Close';

const focusOutline = {
  outline: '3px solid',
  outlineOffset: '3px',
  outlineColor: '#000',
};

export const defaultComponents = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        avatarLetter: 'div',
        inputLabel: 'label',
        helperText: 'div',
        inputText: 'div',
        tooltip: 'span',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      color: 'primaryButton' as const,
      variant: 'contained' as const,
      focusRipple: false,
    },
    styleOverrides: {
      containedError: {
        backgroundColor: defaultPalette.error.dark,
      },
      text: {
        color: defaultPalette.text.primary,
      },
      outlined: {
        backgroundColor: defaultPalette.background.paper,
      },
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          ...focusOutline,
        },
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      focusRipple: false,
    },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          outlineOffset: '-3px',
        },
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {
      focusRipple: false,
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      color: 'primaryButton' as const,
      variant: 'contained' as const,
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      color: 'primaryButton' as const,
      variant: 'contained' as const,
    },
  },
  MuiCard: {
    defaultProps: {
      variant: 'outlined' as const,
    },
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h4' as const,
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: 12,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: customLightPalette.anchor,
        '&:hover': {
          color: customLightPalette.anchor,
        },
        '&:visited': {
          color: customLightPalette.anchor,
        },
      },
    },
  },
  MuiAlert: {
    components: {
      CloseIcon: CloseIcon,
    },
    styleOverrides: {
      outlinedInfo: {
        borderColor: defaultPalette.info.main,
        //todo :: override the default icon color to our design info.main color
      },
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: {
        backgroundColor: defaultPalette.primary.main,
        color: defaultPalette.primary.contrastText,
        fontSize: '1.4rem',
      },
      arrow: {
        color: defaultPalette.primary.main,
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
      color: 'inherit' as const,
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      shrink: {
        transform: 'translate(14px,-9px) scale(0.857)',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        fontSize: '1.86rem',
        borderColor: 'rgba(0, 0, 0, 0.45)', // to ensure color contrast on white background of >3:1
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: 'white',
        'input::placeholder': {
          opacity: 0.5, // to ensure color contrast 3:1
        },
        '&:has([name][readonly])': {
          backgroundColor: '#F5F5F5',
          '& .MuiInputBase-input': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary' as const,
      focusRipple: false,
    },
    styleOverrides: {
      root: {
        overflow: 'visible',
        '&.Mui-focusVisible': {
          outlineOffset: '-3px',
        },
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      focusRipple: false,
    },
    styleOverrides: {
      root: {
        overflow: 'visible',
        '&.Mui-focusVisible': {
          outlineOffset: '-3px',
        },
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      focusRipple: false,
    },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          outlineOffset: '-3px',
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {
      focusRipple: false,
    },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          border: '3px solid #000',
          outline: 0,
          borderBox: 'border-box',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          outline: 0,
        },
      },
    },
  },
};
