import { LtDialog } from '@/components';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { AddCircleOutline, EditOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import TooltipInfo from '@/components/TooltipInfo';
import { VCARD_CONTACT_FIELDS } from '@/shared/business-logic/features/vcard/constants';
import { VCardField } from '@/shared/business-logic/features/vcard/types';

type VCardLocalLabel = {
  isCustom: boolean;
  type?: string;
  en: string;
  de: string;
};

type Props = {
  field: typeof VCARD_CONTACT_FIELDS[number];
  vcardFieldValue: VCardField;
  onChange: (value: VCardField) => void;
  defaultLabels: VCardLocalLabel[];
};

export const SingleContactField = ({ field, vcardFieldValue, onChange, defaultLabels }: Props) => {
  const { t, activeLanguage } = useAppTranslation();

  const visible = vcardFieldValue ? vcardFieldValue.visible : true;

  const selectedLabel = vcardFieldValue?.label ? vcardFieldValue.label : null;

  const [dialogOpen, setDialogOpen] = useState(false);

  const defaultLabels_default = useMemo(
    () => defaultLabels.filter(l => !l.isCustom),
    [defaultLabels],
  );
  const defaultLabels_custom = useMemo(
    () => defaultLabels.filter(l => l.isCustom),
    [defaultLabels],
  );
  const [customLabel, setCustomLabel] = useState<{ de: string; en: string }>({ de: '', en: '' });
  const updateCustomLabel = useCallback(
    (de: string, en: string, fromPresets: boolean) => {
      const newCustomLabel = { de, en };
      if (fromPresets && !!de && !en)
        newCustomLabel.en = defaultLabels_custom.find(l => l.de === de)?.en || en;
      if (fromPresets && !de && !!en)
        newCustomLabel.de = defaultLabels_custom.find(l => l.en === en)?.de || de;
      setCustomLabel(newCustomLabel);
    },
    [defaultLabels_custom],
  );

  useEffect(() => {
    if (dialogOpen) {
      if (!vcardFieldValue?.isCustom) return;
      setCustomLabel({
        de: vcardFieldValue?.label?.de || '',
        en: vcardFieldValue?.label?.en || '',
      });
    }
  }, [
    vcardFieldValue?.label?.de,
    vcardFieldValue?.label?.en,
    vcardFieldValue?.isCustom,
    dialogOpen,
  ]);

  const isCustomLabelDefined = Boolean(vcardFieldValue?.isCustom);

  return (
    <Box>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={6}>
          <Typography>{t(field.tKey)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display='flex' gap={0.5} alignItems='center' width='100%'>
            <Box flex={1}>
              <Autocomplete
                disabled={!visible}
                multiple={false}
                options={[...defaultLabels_default, {}]}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label={t('md.vcardConfigurator.chooseLabel')}
                    onKeyDown={e => e.preventDefault()}
                    inputProps={{
                      ...params.inputProps,
                      style: { caretColor: 'transparent', cursor: 'pointer' },
                    }}
                  />
                )}
                disableClearable
                onChange={(event, newValue: VCardLocalLabel) => {
                  if (Object.keys(newValue).length === 0) {
                    setDialogOpen(true);
                    return;
                  }
                  onChange({
                    ...vcardFieldValue,
                    isCustom: newValue.isCustom,
                    type: newValue.type,
                    label: {
                      en: newValue.en,
                      de: newValue.de,
                    },
                  });
                  setCustomLabel({ de: '', en: '' });
                }}
                renderOption={(props, option) =>
                  Object.keys(option).length === 0 ? (
                    <ListItem
                      sx={theme => ({ borderTop: `1px solid ${theme.palette.divider}` })}
                      {...props}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                        {isCustomLabelDefined ? <EditOutlined /> : <AddCircleOutline />}
                      </ListItemIcon>
                      <ListItemText>
                        {t(
                          isCustomLabelDefined
                            ? 'md.vcardConfigurator.editLabel'
                            : 'md.vcardConfigurator.createLabel',
                        )}
                      </ListItemText>
                    </ListItem>
                  ) : (
                    <ListItem {...props} key={JSON.stringify(option)}>
                      {option[activeLanguage]}
                    </ListItem>
                  )
                }
                isOptionEqualToValue={(option, value) =>
                  option[activeLanguage] === value[activeLanguage]
                }
                value={selectedLabel}
                getOptionLabel={option => option[activeLanguage] || ''}
              />
            </Box>
            <TooltipInfo
              onHover
              text={t(visible ? 'md.vcardConfigurator.doNotShow' : 'md.vcardConfigurator.show', {
                field: t(field.tKey),
              })}
            >
              <IconButton
                onClick={() => onChange({ ...vcardFieldValue, visible: !visible })}
                aria-label={t('ariaToggleVCardFieldVisibility', { field: t(field.tKey) })}
              >
                {visible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </IconButton>
            </TooltipInfo>
          </Box>
        </Grid>
      </Grid>
      <LtDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        title={t('md.vcardConfigurator.createCustomLabel')}
        onSave={() => {
          onChange({
            ...vcardFieldValue,
            isCustom: true,
            type: undefined,
            label: {
              en: !customLabel.en && customLabel.de ? customLabel.de : customLabel.en,
              de: !customLabel.de && customLabel.en ? customLabel.en : customLabel.de,
            },
          });
          setDialogOpen(false);
        }}
      >
        <Typography variant='body2'>{t('md.vcardConfigurator.customLabelInfo')}</Typography>
        <Autocomplete
          freeSolo
          options={defaultLabels_custom.map(o => o.en)}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label={t('md.vcardConfigurator.customLabelEnglish')}
              sx={{ mt: 2 }}
            />
          )}
          multiple={false}
          inputValue={customLabel.en}
          onInputChange={(event, newValue, reason) =>
            updateCustomLabel(customLabel.de, newValue, reason === 'reset')
          }
        />
        <Autocomplete
          freeSolo
          options={defaultLabels_custom.map(o => o.de)}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label={t('md.vcardConfigurator.customLabelGerman')}
              sx={{ mt: 2 }}
            />
          )}
          multiple={false}
          inputValue={customLabel.de}
          onInputChange={(event, newValue, reason) =>
            updateCustomLabel(newValue, customLabel.en, reason === 'reset')
          }
        />
      </LtDialog>
    </Box>
  );
};
