import { useAppSelector } from '@/application/hooks';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { getProfileImageUrlWithGlobalDefault } from '@/shared/business-logic/features/profile/getter';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LtAdmin = () => {
  const { t } = useTranslation();
  const account = useAppSelector(state => state.account);
  const { profileDesign } = useProfileDesign();

  return (
    <Box width='4rem' height='4rem' sx={{ borderRadius: '50%', overflow: 'hidden' }}>
      <img
        width='100%'
        height='100%'
        src={getProfileImageUrlWithGlobalDefault(account, profileDesign)}
        alt={t('ariaHeaderAdminDropdown')}
      />
    </Box>
  );
};
