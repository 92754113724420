import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { FeatureSet } from '@/shared/types/api';
import { Unit } from '@/shared/types/api/unit.type';
import { useUnsavedStatus } from '@/utils/unsavedStatus';
import { UnitSelector } from '@/components/UnitSelector';
import { Box, Divider, Typography } from '@mui/material';

type Props = {
  units: Unit[];
  selectedUnit: Unit;
  setSelectedUnit: (unit: Unit) => void;
  title?: string;
  requiredFeature: keyof FeatureSet;
  unitSelectorDisabled?: boolean;
  children: React.ReactNode;
};

export const ProfileDesignPageWrapper = ({
  units,
  selectedUnit,
  setSelectedUnit,
  title,
  requiredFeature,
  children,
}: Props) => {
  const { openDialogIfUnsaved } = useUnsavedStatus();
  const { isFeatureAllowed } = useTierInfo();

  return (
    <Box px={2} pb={2}>
      <Box display='flex' alignItems='center' justifyContent='space-between' pb='1.5rem'>
        {title && <Typography variant='h2'>{title}</Typography>}
        {isFeatureAllowed(FEATURE.UNITS) && Boolean(units?.length) && (
          <Box display={'flex'} width='35%'>
            <UnitSelector
              selectedUnit={selectedUnit}
              onChange={value => openDialogIfUnsaved(() => setSelectedUnit(value))}
              units={units}
              requiredFeature={requiredFeature}
            />
          </Box>
        )}
      </Box>
      <Divider />
      {children}
    </Box>
  );
};
