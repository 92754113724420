import { MoreVert } from '@mui/icons-material';
import {
  IconButton,
  Fade,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButtonProps,
} from '@mui/material';
import { useState } from 'react';

export type MenuItemType = {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
};

type Props = {
  icon?: React.ReactNode;
  menuItems: MenuItemType[];
  anchorEl?: HTMLElement;
  onClose?: () => void;
  iconButtonProps?: IconButtonProps;
  renderAnchor?: boolean;
};

const SettingsMenu = ({
  icon,
  menuItems,
  anchorEl: passedAnchorEl,
  onClose,
  iconButtonProps,
  renderAnchor = true,
}: Props) => {
  const [_anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };
  const anchorElement = passedAnchorEl || _anchorEl;
  const open = Boolean(anchorElement);

  return (
    <>
      {renderAnchor && (
        <IconButton {...iconButtonProps} onClick={handleClick}>
          {icon ?? <MoreVert />}
        </IconButton>
      )}
      <Menu
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            sx={{ width: '30rem' }}
            key={index}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            <ListItemIcon sx={{ color: 'secondary.main' }}>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SettingsMenu;
