import { dispatchTypes } from '../actions';

/**
 * a temporary state to hold the temporary frequently changing properties
 * these values might be useful for live sharing and previewing changes before saving them
 * @param state
 * @param action
 * @returns state.temp
 */

export type Temporary = {
  primaryButtonColor?: string;
  sidebarColor?: string;
  sidebarColorText?: string;
  sidebarColorActive?: string;
  sidebarColorActiveText?: string;
};

let initials = {
  primaryButtonColor: undefined,
  sidebarColor: undefined,
  sidebarColorText: undefined,
  sidebarColorActive: undefined,
  sidebarColorActiveText: undefined,
};

const tempReducer = (state = initials, action) => {
  switch (action.type) {
    case dispatchTypes.TEMP.THEME.SETCOLOR:
      return {
        ...state,
        ...action.payload,
      };

    case dispatchTypes.TEMP.THEME.RESETCOLORS:
      return {
        ...state,
        primaryButtonColor: undefined,
        sidebarColor: undefined,
        sidebarColorText: undefined,
        sidebarColorActive: undefined,
        sidebarColorActiveText: undefined,
      };
    default:
      return state;
  }
};

export default tempReducer;
