import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { LeadAPI, LeadDataProps, MDLead } from '../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';
import { withSilentAccessToken } from '../helper';
import { PERMISSIONS } from '../constants';
import { Filter } from '@/shared/util';

export const getAccountLeads = async (
  accountId: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  options = {},
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const { data } = await axios.get(config.API_BASE_URL + `lead-gen/accounts/${accountId}/leads`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: options,
  });

  if (!data.isSuccess) throw new Error('Failed to fetch leads');
  return data.data;
};

export const getSingleLead = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  accountId: string,
  leadId: string,
) => {
  const { data: response } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + `lead-gen/accounts/${accountId}/leads/${leadId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
  if (!response.isSuccess) throw new Error('Failed to fetch lead');
  return response.data;
};

export const getLeadLTAccount = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  username: string,
) => {
  const { data: res } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + `account/${username}?crossDomain=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );

  if (!res.isSuccess) throw new Error('Failed to fetch lead account');
  return res.data;
};

export const getLeadOptionTypes = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  accountId: string,
) => {
  const { data: response } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + `lead-gen/accounts/${accountId}/lead-option-types`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
  if (!response.isSuccess) throw new Error('Failed to fetch lead options types');
  return response.data;
};

export const addLead = async (
  leadData: LeadDataProps | Partial<Record<keyof LeadAPI, string>>,
  id: string,
  external = false,
) => {
  const response = await axios.post(config.API_BASE_URL + `lead-gen/accounts/${id}/leads`, {
    ...leadData,
    external,
  });
  return response;
};

export const editLead = async (
  leadData: LeadDataProps,
  id: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });
  const response = await axios.patch(
    config.API_BASE_URL + `lead-gen/accounts/${id}/leads/${leadData.id}`,
    {
      ...leadData,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const deleteAccountLead = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  accountId: string,
  leadId: string,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });
  const { data: response } = await axios.delete(
    config.API_BASE_URL + `lead-gen/accounts/${accountId}/leads/${leadId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (!response.isSuccess) throw new Error('Failed to fetch lead options types');
};

export const fetchDownloadLeads = async (
  firstName,
  fileType,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  options = {},
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);

  axios
    .get(config.API_BASE_URL + `lead-gen/accounts/leads/${fileType}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: options,
      responseType: 'blob',
    })
    .then(res => {
      let blob = res.data;
      let objectUrl = window.URL.createObjectURL(blob);

      anchor.href = objectUrl;
      anchor.download = `${firstName}-contacts.${fileType}`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export const scanBusinessCard = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: FormData,
  accountId: string,
) =>
  await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(config.API_BASE_URL + `lead-gen/accounts/${accountId}/scan`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );

export const exportLeadsToCrm = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: {
    leadIds: number[];
    serviceId: string;
    campaignId?: string;
    configurationValues?: Record<string, string>;
  },
) =>
  await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(config.API_BASE_URL + `lead-gen/accounts/leads/export`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );

export const exportLeadsToCSV = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  queryParams?: { leadIds: number[] },
) =>
  await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/leads/csv`, {
        params: queryParams || {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );

export const getMdLeads = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  queryParams = {},
): Promise<{
  leads?: Array<MDLead>;
  leadIds: { id: number }[];
  total?: number;
  page?: number;
}> => {
  const { data: result } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/contacts`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );

  if (!result.isSuccess || !result?.data?.leads) {
    throw new Error('Failed to fetch leads');
  }
  return {
    leads: result.data.leads || [],
    leadIds: result.data.leadIds || [],
    total: result.data.total || 0,
    page: result.data.page || 0,
  };
};

export const deleteMdLeads = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ids: number[],
) => {
  // send delete request
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + `business/bulkDeleteContacts/`,
        { ids },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );

  if (!data.isSuccess) {
    throw new Error('Failed to delete md lead');
  }

  return data;
};

export const editMdLead = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: Partial<LeadAPI>,
  accountId: string,
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.patch(config.API_BASE_URL + `theme/contacts/${accountId}/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
  if (!data.isSuccess) {
    throw new Error('Failed to edit md lead');
  }
  return data;
};

export const getLeadFilters = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
): Promise<Filter[]> => {
  const { data: result } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/contacts/filters`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );

  return result;
};
