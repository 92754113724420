import React, { useEffect, useMemo } from 'react';
import { routePaths } from '../../../infrastructure/constants';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { landingHasAccount, setAuthCallbackState } from '../../../application/actions/landing';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '@/components/Loader';

interface MatchParams {
  usernameorcode: string;
}

const Landing = (props: RouteComponentProps<MatchParams>) => {
  const usernameOrCode = useMemo(
    () => props.match.params.usernameorcode,
    [props.match.params.usernameorcode],
  );
  const dispatch = useAppDispatch();
  const { hasAccount, isCode, isUsername } = useAppSelector(state => state.landing);
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    const checkHasAccount = async () => {
      dispatch(landingHasAccount(usernameOrCode));
    };
    checkHasAccount();
  }, [dispatch, usernameOrCode]);

  useEffect(() => {
    if (isAuthenticated && isCode) {
      dispatch(setAuthCallbackState({ ltLoginCode: usernameOrCode }));
    }
  }, [usernameOrCode, isAuthenticated, isCode, dispatch]);

  const linkCode = !hasAccount && isCode ? true : false;

  if (!isLoading && (isCode || isUsername)) {
    return (
      <Redirect
        exact
        to={{
          pathname: routePaths.LANDING.LOGIN_REGISTER,
          state: { code: linkCode ? usernameOrCode : false },
        }}
      />
    );
  } else {
    return <Loader />;
  }
};

export default Landing;
