import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadJsScript } from '../../util';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import config from '../../config/config';
import { PERMISSIONS } from '../constants';
import { useDispatch } from 'react-redux';
import { getEmployees } from '../../application/actions/md/profiles';
import { stringToBoolean } from '../../shared/util';
import { USERS_IMPORT_LIMIT } from '@/shared/constants';
import { APIError } from '@/shared/types/api';
import { getImportError } from '@/views/pages/md/profiles/import-error-handler';
import { useNotificationsStore } from '@/zustand/notificationsStore';

let importer: undefined | CSVBoxImporter = undefined;
let updater: undefined | CSVBoxImporter = undefined;
let deleter: undefined | CSVBoxImporter = undefined;
export type licenseAlertType =
  | 'noOrderCardWithExceedLicense'
  | 'orderCardWithExceedLicense'
  | 'orderCardWithNoneExceedLicense';
export default function useCsvbox(licences: {
  importerLicence: string;
  updaterLicence: string;
  deleterLicence: string;
}) {
  const { t, i18n } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [csvboxScriptLoading, setCsvboxScriptLoading] = useState({
    loading: false,
    ready: 'uninitialized' as 'uninitialized' | 'loading' | 'initialized',
  });
  const [importError, setImportError] = useState<string>();
  // to counteract csvbox calling the onComplete callback on every CSVBoxImporter
  const mode = useRef<'import' | 'update' | 'delete' | null>(null);

  // to counteract csvbox calling the callback fct twice
  const runningId = useRef(0);

  useEffect(() => {
    if (csvboxScriptLoading.loading || csvboxScriptLoading.ready === 'loading') {
      useNotificationsStore.getState().notify.loading(t('Loading'), { id: 'csvboxScriptLoading' });
    } else {
      useNotificationsStore.getState().notify.dismiss('csvboxScriptLoading');
    }
  }, [csvboxScriptLoading, t]);

  useEffect(() => {
    return () => {
      importer = undefined;
      updater = undefined;
      deleter = undefined;
    };
  }, []);

  const insertIntoDatabase = useCallback(
    data => {
      useNotificationsStore.getState().notify.loading(t('Loading'), { id: 'csvboxRequestLoading' });
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.post(
            config.API_BASE_URL + 'business/addEmployees',
            {
              rows: data.rows,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),
        [PERMISSIONS.CREATE.THEME_PROFILE],
      )
        .then(() => {
          if (data.rows.length > USERS_IMPORT_LIMIT) {
            // background import
            useNotificationsStore.getState().notify.success(t('toast.backgroundImport'), {
              id: 'csvboxRequestLoading',
            });
          } else {
            dispatch(
              getEmployees(getAccessTokenSilently, '', '', t, {
                onSuccess: () =>
                  useNotificationsStore.getState().notify.success(t('toast.added'), {
                    id: 'csvboxRequestLoading',
                  }),
                onFail: () =>
                  useNotificationsStore.getState().notify.error(t('toast.addedThenError'), {
                    id: 'csvboxRequestLoading',
                  }),
              }),
            );
          }
        })
        .catch((error: APIError) => {
          if (error?.response?.data?.error) {
            setImportError(getImportError(error.response.data.error));
            useNotificationsStore.getState().notify.dismiss('csvboxRequestLoading');
          } else {
            useNotificationsStore.getState().notify.error(t('error.general'));
          }
        })
        .finally(() => {
          mode.current = null;
          runningId.current = 0;
        });
    },
    [dispatch, getAccessTokenSilently, t],
  );

  const openImportModal = useCallback(
    (
      numberOfLicenses: number,
      numberOfCurrentEmployees: number,
      ShowDialog?: (data, type: licenseAlertType, numberOfLicenses?: number) => void,
    ) => {
      mode.current = 'import';
      setCsvboxScriptLoading(prev => ({ ...prev, loading: true }));
      loadJsScript('https://js.csvbox.io/script.js', 'lt-external-script-csvbox', {
        cb: () => {
          if (!importer) {
            setCsvboxScriptLoading(prev => ({ ...prev, ready: 'loading' }));
            importer = new CSVBoxImporter(
              licences.importerLicence,
              {},
              (result, data) => {
                // workaround to prevent this running twice
                const timeouttime = Math.round(Math.random() * 50) + 1;
                setTimeout(() => {
                  if (runningId.current) return;
                  if (mode.current !== 'import') return;
                  else runningId.current = timeouttime;
                  setTimeout(() => {
                    const orderCard = data.rows.filter(row =>
                      stringToBoolean(row.orderCard),
                    ).length;
                    const orderCardAvailable = orderCard > 0;
                    const numberOfLicensesExceeded =
                      numberOfLicenses < numberOfCurrentEmployees + data.row_count;
                    if (orderCardAvailable) {
                      if (numberOfLicensesExceeded) {
                        ShowDialog(data, 'orderCardWithExceedLicense', orderCard);
                      } else {
                        ShowDialog(data, 'orderCardWithNoneExceedLicense', orderCard);
                      }
                    } else {
                      if (numberOfLicensesExceeded) {
                        ShowDialog(data, 'noOrderCardWithExceedLicense', orderCard);
                      } else {
                        insertIntoDatabase(data);
                      }
                    }
                  }, 100);
                }, timeouttime);
                runningId.current = 0;
              },
              { data_location: 'eu-central-1' },
            );
            importer.listen('onReady', () =>
              setCsvboxScriptLoading(prev => ({ ...prev, ready: 'initialized' })),
            );
          }

          importer.setUser({ user_id: user.sub });
          importer.setOptions({ language: i18n.language.substring(0, 2) });
          setCsvboxScriptLoading(prev => ({ ...prev, loading: false }));
          importer.openModal();
        },
      });
    },
    [i18n.language, insertIntoDatabase, licences.importerLicence, user.sub],
  );

  const openUpdateModal = useCallback(() => {
    mode.current = 'update';
    setCsvboxScriptLoading(prev => ({ ...prev, loading: true }));
    loadJsScript('https://js.csvbox.io/script.js', 'lt-external-script-csvbox', {
      cb: () => {
        if (!updater) {
          setCsvboxScriptLoading(prev => ({ ...prev, ready: 'loading' }));
          updater = new CSVBoxImporter(
            licences.updaterLicence,
            {},
            (result, data) => {
              // workaround to prevent this running twice
              const timeouttime = Math.round(Math.random() * 50) + 1;
              setTimeout(() => {
                if (runningId.current) return;
                if (mode.current !== 'update') return;
                else runningId.current = timeouttime;
                setTimeout(() => {
                  // check if either username, code or email is present
                  if (data.rows.some(r => !r.code && !r.username && !r.email)) {
                    useNotificationsStore
                      .getState()
                      .notify.error('One of email, username, code is required', {
                        id: 'csvboxRequestLoading',
                      });
                    return;
                  }
                  useNotificationsStore
                    .getState()
                    .notify.loading(t('Loading'), { id: 'csvboxRequestLoading' });
                  withSilentAccessToken(
                    getAccessTokenSilently,
                    token =>
                      axios.post(
                        config.API_BASE_URL + 'business/updateEmployees',
                        {
                          rows: data.rows,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      ),
                    [PERMISSIONS.WRITE.THEME_PROFILES],
                  )
                    .then(() => {
                      dispatch(
                        getEmployees(getAccessTokenSilently, '', '', t, {
                          onSuccess: () =>
                            useNotificationsStore
                              .getState()
                              .notify.success(t('csvImport.toast.updated'), {
                                id: 'csvboxRequestLoading',
                              }),
                          onFail: () =>
                            useNotificationsStore
                              .getState()
                              .notify.error(t('toast.updatedThenError'), {
                                id: 'csvboxRequestLoading',
                              }),
                        }),
                      );
                    })
                    .catch(error =>
                      useNotificationsStore
                        .getState()
                        .notify.error(error?.response?.data?.error?.message || 'Error'),
                    )
                    .finally(() => {
                      mode.current = null;
                      runningId.current = 0;
                    });
                }, 100);
              }, timeouttime);
            },
            { data_location: 'eu-central-1' },
          );
          updater.listen('onReady', () =>
            setCsvboxScriptLoading(prev => ({ ...prev, ready: 'initialized' })),
          );
        }

        updater.setUser({ user_id: user.sub });
        updater.setOptions({ language: i18n.language.substring(0, 2) });
        setCsvboxScriptLoading(prev => ({ ...prev, loading: false }));
        updater.openModal();
      },
    });
  }, [dispatch, getAccessTokenSilently, i18n.language, licences.updaterLicence, t, user.sub]);

  const openDeleteModal = useCallback(() => {
    mode.current = 'delete';
    setCsvboxScriptLoading(prev => ({ ...prev, loading: true }));
    loadJsScript('https://js.csvbox.io/script.js', 'lt-external-script-csvbox', {
      cb: () => {
        if (!deleter) {
          setCsvboxScriptLoading(prev => ({ ...prev, ready: 'loading' }));
          deleter = new CSVBoxImporter(
            licences.deleterLicence,
            {},
            (result, data) => {
              // workaround to prevent this running twice
              const timeouttime = Math.round(Math.random() * 50) + 1;
              setTimeout(() => {
                if (runningId.current) return;
                if (mode.current !== 'delete') return;
                else runningId.current = timeouttime;
                setTimeout(() => {
                  // check if either username, code or email is present OR full name
                  if (
                    !data.rows.every(
                      r => r.email || r.username || r.code || (r.firstName && r.lastName),
                    )
                  ) {
                    useNotificationsStore
                      .getState()
                      .notify.error(
                        'One of email, username, code or full name is required, but missing in row ' +
                          (1 +
                            data.rows.findIndex(
                              r =>
                                !(r.email || r.username || r.code || (r.firstName && r.lastName)),
                            )),
                        {
                          id: 'csvboxRequestLoading',
                        },
                      );
                    return;
                  }
                  useNotificationsStore
                    .getState()
                    .notify.loading(t('Loading'), { id: 'csvboxRequestLoading' });
                  withSilentAccessToken(
                    getAccessTokenSilently,
                    token =>
                      axios.post(
                        config.API_BASE_URL + 'business/deleteEmployees',
                        {
                          rows: data.rows,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      ),
                    [PERMISSIONS.DELETE.THEME_PROFILES],
                  )
                    .then(() => {
                      dispatch(
                        getEmployees(getAccessTokenSilently, '', '', t, {
                          onSuccess: () =>
                            useNotificationsStore.getState().notify.success(t('toast.deleted'), {
                              id: 'csvboxRequestLoading',
                            }),
                          onFail: () =>
                            useNotificationsStore
                              .getState()
                              .notify.error(t('toast.deletedThenError'), {
                                id: 'csvboxRequestLoading',
                              }),
                        }),
                      );
                    })
                    .catch(error =>
                      useNotificationsStore
                        .getState()
                        .notify.error(error?.response?.data?.error?.message || 'Error'),
                    )
                    .finally(() => {
                      mode.current = null;
                      runningId.current = 0;
                    });
                }, 100);
              }, timeouttime);
            },
            { data_location: 'eu-central-1' },
          );
          deleter.listen('onReady', () =>
            setCsvboxScriptLoading(prev => ({ ...prev, ready: 'initialized' })),
          );
        }

        deleter.setUser({ user_id: user.sub });
        deleter.setOptions({ language: i18n.language.substring(0, 2) });
        setCsvboxScriptLoading(prev => ({ ...prev, loading: false }));
        deleter.openModal();
      },
    });
  }, [dispatch, getAccessTokenSilently, i18n.language, licences.deleterLicence, t, user.sub]);

  return {
    openImportModal,
    openUpdateModal,
    openDeleteModal,
    insertIntoDatabase,
    importError,
    setImportError,
  };
}
