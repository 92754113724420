import { LtDialog } from '@/components';
import { Stepper, Step, StepLabel, Box, Divider } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateKeyStep, RegenerateStep } from './ScimSteps';

enum StepEnum {
  REGENERATE,
  API_KEYS,
}

const INITIAL_STEP = StepEnum.REGENERATE;
const steps = [StepEnum.REGENERATE, StepEnum.API_KEYS];
const stepsLabels = {
  [StepEnum.REGENERATE]: 'idp.scim.steps.regenerate.stepperTitle',
  [StepEnum.API_KEYS]: 'idp.scim.steps.apiKeys.stepperTitle',
};

const getNextStep = (step: StepEnum) => steps.indexOf(step) + 1;

type Props = {
  onClose: () => void;
  open: boolean;
};
export const RegenerateScimTokenPopup = ({ onClose, open }: Props) => {
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const { t } = useTranslation();

  const isLastStep = activeStep === steps[steps.length - 1];

  const nextClickHandler = () => {
    if (isLastStep) {
      onClose();
      return;
    }
    setActiveStep(getNextStep(activeStep));
  };

  return (
    <LtDialog
      TransitionProps={{
        onExited: () => {
          setActiveStep(INITIAL_STEP);
        },
      }}
      size='lg'
      open={open}
      onClose={onClose}
      title={t('idp.scim.regenerateKey')}
      confirmAction={{
        onClick: nextClickHandler,
        text: t(isLastStep ? 'done' : 'idp.scim.regenerateKey'),
      }}
    >
      <Box mb={3}>
        <Stepper activeStep={activeStep}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{t(stepsLabels[step])}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider />
      <Box mt={3}>
        {activeStep === StepEnum.REGENERATE && <RegenerateStep />}
        {activeStep === StepEnum.API_KEYS && <GenerateKeyStep isRegeneration />}
      </Box>
    </LtDialog>
  );
};
