import { CrmConnectorType, LeadAPI, LeadSortOption } from '@/shared/types/api';
import {
  Checkbox,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  DeleteOutline,
  DownloadOutlined,
  EditOutlined,
  NoteAddOutlined,
} from '@mui/icons-material';
import { useState } from 'react';
import config from '@/config/config';
import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { openLink } from '@/util';
import { LtDialog, SROnly } from '@/components';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';
import moment from 'moment';
import { getAvatar, getLeadDisplayName, lineClampCount } from '../utils/helpers';
import { getFullName } from '@/shared/business-logic/features/profile/getter';
import { isPhoneFaxField } from '@/views/pages/edit-bio/utils';
import { getPhone } from '@/views/pages/md/profiles/table/utils/helpers';
import { ExportEventRenderer } from './ExportEventRenderer';
import { CustomTableCell, CustomTableCellHeader } from '@/components/Table';
import SortableCustomTableCellHeader from '@/components/Table/SortableTableCellHeader';
import { isDateField } from '@/components/Table/common';

const EnhancedTableRow = ({
  lead,
  onSelect,
  isSelected,
  onDelete,
  onLeadEditClick,
  columns,
  crmConnectors,
  showCrmExport,
}: {
  lead: LeadAPI;
  onSelect: () => void;
  isSelected: boolean;
  onDelete?: (leadId: number) => Promise<void>;
  onLeadEditClick: (leadId: number, focusNotes?: boolean) => void;
  columns: ColumnDefinition[];
  crmConnectors?: CrmConnectorType[];
  showCrmExport?: boolean;
}) => {
  const { id: accountId } = useAppSelector(state => state.account);
  const { activeLanguage, t } = useAppTranslation();

  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDownload = () => {
    openLink(
      `${config.API_BASE_URL}lead-gen/accounts/${lead.account?.id || accountId}/leads/${
        lead.id
      }/vcard?lang=${activeLanguage}`,
    );
  };

  const handleDelete = async () => {
    setDeleting(true);
    await onDelete(lead.id);
    setDeletePopupOpened(false);
    setDeleting(false);
  };

  const rowActions = [
    { icon: <EditOutlined />, label: t('edit'), onClick: () => onLeadEditClick(lead.id) },
    {
      icon: <NoteAddOutlined />,
      label: t('leads.addEditNote'),
      onClick: () => onLeadEditClick(lead.id, true),
    },
    { icon: <DeleteOutline />, label: t('delete'), onClick: () => setDeletePopupOpened(true) },
    { icon: <DownloadOutlined />, label: t('download'), onClick: handleDownload },
  ];

  const avatar = getAvatar(lead);
  const name = getLeadDisplayName(lead);

  return (
    <>
      <TableRow hover aria-selected={isSelected} key={lead.id} selected={isSelected}>
        <TableCell
          padding='checkbox'
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Checkbox
            color='primary'
            onClick={() => onSelect()}
            checked={isSelected}
            inputProps={{ 'aria-label': t('ariaSelect', { name }) }}
          />
        </TableCell>

        <CustomTableCell
          lineClampCount={lineClampCount}
          tooltipText={name}
          noPadding
          dim='l'
          renderAvatar
          avatarSrc={avatar}
          actions={rowActions}
          onClick={() => onLeadEditClick(lead.id)}
          actionsBtnAriaLabel={t('ariaSettings', { name })}
          avatarAriaLabel={t('ariaEditContact', { name })}
        >
          {name}
        </CustomTableCell>
        {columns.map(({ key }) => {
          if (key === 'metBy') {
            const metByAcc = lead?.account;
            const fullName = metByAcc ? getFullName(metByAcc) : t('mdLeads.accountDeleted');
            return (
              <CustomTableCell
                lineClampCount={lineClampCount}
                tooltipText={fullName}
                noPadding
                dim='l'
                renderAvatar
                avatarSrc={metByAcc?.profileImageUrl}
              >
                {fullName}
              </CustomTableCell>
            );
          }

          const isDate = isDateField(key);
          const isPhone = isPhoneFaxField(key);
          const text = isDate
            ? moment(lead[key]).format('DD-MM-YYYY')
            : isPhone
            ? getPhone(lead.lt2lt?.[key] || lead[key])
            : lead.lt2lt?.[key] || lead[key];

          return (
            <CustomTableCell lineClampCount={lineClampCount} key={key} tooltipText={text}>
              {text}
            </CustomTableCell>
          );
        })}
        {showCrmExport && Boolean(crmConnectors?.length) && lead.crmExportEvents && (
          <CustomTableCell lineClampCount={lineClampCount}>
            <Box display='flex' gap={1} alignItems='center' justifyContent='center'>
              {lead.crmExportEvents?.map(event => (
                <ExportEventRenderer crmConnectors={crmConnectors} event={event} />
              ))}
            </Box>
          </CustomTableCell>
        )}
      </TableRow>
      <LtDialog
        title={t('requestDelete')}
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={handleDelete}
        loading={deleting}
      >
        {t('deleteLeadMsg')}
      </LtDialog>
    </>
  );
};

type Props = {
  leads: LeadAPI[];
  onCheckboxClick: (lead: LeadAPI) => void;
  onHeaderCheckboxClick: () => void;
  selectedItems: { id: number }[];
  isAllSelected: boolean;
  isAnySelectedOnCurrentPage: boolean;
  onDelete?: (leadId: number) => Promise<void>;
  onLeadEditClick: (leadId: number) => void;
  columns: ColumnDefinition[];
  sortOptions: LeadSortOption;
  onSortOptionsChange: (sortOptions: LeadSortOption) => void;
  crmConnectors?: CrmConnectorType[];
  showCrmExport?: boolean;
};

export const Table = ({
  leads,
  onCheckboxClick,
  onHeaderCheckboxClick,
  isAllSelected,
  isAnySelectedOnCurrentPage,
  selectedItems,
  onDelete,
  onLeadEditClick,
  columns,
  sortOptions,
  onSortOptionsChange,
  crmConnectors,
  showCrmExport,
}: Props) => {
  const shouldRenderExportColumn = showCrmExport && Boolean(crmConnectors?.length);
  const { t } = useAppTranslation();
  return (
    <TableContainer
      sx={(theme: Theme) => ({
        flex: '1',
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      <MuiTable stickyHeader sx={{ minWidth: 750 }}>
        <SROnly component='caption'>{t('ariaLeadsTableCaption')}</SROnly>
        <TableHead>
          <TableRow sx={{ bgcolor: 'white' }}>
            <TableCell
              padding='checkbox'
              sx={theme => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Checkbox
                color='primary'
                indeterminate={isAnySelectedOnCurrentPage}
                checked={isAllSelected}
                onChange={onHeaderCheckboxClick}
                inputProps={{ 'aria-label': t('selectAll') }}
              />
            </TableCell>
            <SortableCustomTableCellHeader
              column={{ key: 'lastName', tkey: 'fullName' }}
              sortOptions={sortOptions}
              onSortOptionsChange={onSortOptionsChange}
              lineClampCount={lineClampCount}
            />
            {columns.map(column => (
              <SortableCustomTableCellHeader
                column={column}
                sortOptions={sortOptions}
                onSortOptionsChange={onSortOptionsChange}
                lineClampCount={lineClampCount}
              />
            ))}
            {shouldRenderExportColumn && (
              <CustomTableCellHeader lineClampCount={lineClampCount} tooltipText='Exports'>
                Exports
              </CustomTableCellHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((lead, index) => (
            <EnhancedTableRow
              key={lead.id}
              lead={lead}
              onSelect={() => onCheckboxClick(lead)}
              isSelected={selectedItems.some(({ id }) => id === lead.id)}
              onDelete={onDelete}
              onLeadEditClick={onLeadEditClick}
              columns={columns}
              crmConnectors={crmConnectors}
              showCrmExport={showCrmExport}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
