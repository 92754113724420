import { AddOutlined, East } from '@mui/icons-material';
import { Avatar, Box, Button, CardHeader, CircularProgress, Typography } from '@mui/material';

import { LeadAPI } from '@/shared/types/api';
import moment from 'moment';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { NoLeads } from '../../lead-gen/MyLeads/components/NoLeads';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { ReactComponent as ScannerIcon } from '@/views/images/nav/scanner.svg';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { getAvatar, getLeadDisplayName } from '../../lead-gen/MyLeads/utils/helpers';
import { CardRenderer } from '@/components/CardRenderer';

const SingleLead = ({ lead }: { lead: LeadAPI }) => {
  return (
    <Box
      component={Link}
      to={`/contacts/${lead.id}/edit`}
      sx={{ color: 'black', display: 'block' }}
    >
      <CardHeader
        avatar={<Avatar src={getAvatar(lead)} />}
        title={getLeadDisplayName(lead)}
        subheader={lead?.lt2lt?.company || lead.company}
        action={<Typography>{moment(lead.createdOn).format('L')}</Typography>}
        sx={theme => ({
          cursor: 'pointer',
          borderBottom: `1px solid ${theme.palette.divider}`,
          '.MuiCardHeader-action': { alignSelf: 'center' },
        })}
      />
    </Box>
  );
};

type Props = {
  leads?: LeadAPI[];
  isLoading: boolean;
};

export const LeadsCard = ({ isLoading, leads }: Props) => {
  const { isStarter } = useTierInfo();
  const flag_businessCardScanner = usePrivateFeatureFlag('businessCardScanner');

  const { t } = useTranslation();

  const scanCardBtn = flag_businessCardScanner ? (
    <Button
      sx={{ svg: { width: '2rem', height: '2rem' } }}
      variant='outlined'
      color='primary'
      startIcon={<ScannerIcon />}
      component={Link}
      to={isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.CONTACTS.SCANNER}
    >
      {t('scanCard')}
    </Button>
  ) : null;

  const addContactBtn = (
    <Button
      component={Link}
      to={isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.CONTACTS.ADD}
      startIcon={<AddOutlined />}
    >
      {t('addContact')}
    </Button>
  );

  return (
    <CardRenderer
      title={t('homePage.leads.recentContacts')}
      actions={[
        <Button
          component={Link}
          to={isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.CONTACTS.ROOT}
          endIcon={<East />}
          variant='text'
          aria-label={t('ariaviewAllLeads')}
        >
          {t('homePage.viewAll')}
        </Button>,
      ]}
    >
      {(isStarter || (!isLoading && leads.length === 0)) && (
        <NoLeads size='sm' actions={[scanCardBtn, addContactBtn]} />
      )}

      {isLoading && (
        <Box display='flex' width='100%' height='100%'>
          <CircularProgress sx={{ m: 'auto' }} />
        </Box>
      )}

      <Box>
        {leads?.map(lead => (
          <SingleLead lead={lead} />
        ))}
      </Box>
    </CardRenderer>
  );
};
