import { LtDialog } from '@/components';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnchorText } from './AnchorText';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const IdpDisconnectPopup = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <LtDialog open={open} onClose={onClose} title={t('idp.disconnect.title')}>
      <Box typography={'body1'} lineHeight={2} component={Typography} color='text.secondary'>
        <Box>{t('idp.disconnect.description')}</Box>
        <Box mt={0.8} sx={{ whiteSpace: 'pre-wrap' }}>
          {t('idp.disconnect.bulletPoints')}
        </Box>
      </Box>
      <AnchorText mt={2} href='#' text={t('idp.disconnect.anchorText')} />
    </LtDialog>
  );
};
