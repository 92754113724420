import { getLogoHeaderUrl } from '@/shared/business-logic/features/profile/getter';
import { ImageSettings } from '@/components/Profile/components/ImageSettings';
import { useTranslation } from 'react-i18next';
import { ProfileEditProps } from '@/components/Profile/types';

type Props = ProfileEditProps['logoImageEditProps'];

export default function EditLogo({
  account,
  profileDesign,
  editRightAllowed,
  onUpdateByFile,
  onUpdateByUrl,
  onResetToDefault,
}: Props) {
  const { t } = useTranslation();
  const logoHeaderUrlToDisplay = getLogoHeaderUrl(account, profileDesign);

  return (
    <ImageSettings
      disallowed={!editRightAllowed}
      currentUrl={getLogoHeaderUrl(account, profileDesign)}
      imageState={account.logoHeaderState}
      defaultImageState={profileDesign.logoHeaderState}
      isButton={!Boolean(logoHeaderUrlToDisplay)}
      buttonText={t('addLogo')}
      options={profileDesign.logoImgOptions}
      onUploadByFile={onUpdateByFile}
      onUploadByUrl={onUpdateByUrl}
      onResetToDefault={onResetToDefault}
      onDelete={onResetToDefault}
      ariaLabel={!Boolean(logoHeaderUrlToDisplay) ? t('addLogo') : t('editLogo')}
    />
  );
}
