import { Box, Button, Card, CardActionArea, Chip, IconButton, Typography } from '@mui/material';
import { useFrameDimensions } from '../helpers';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { useTranslation } from 'react-i18next';
import { FullScreenContainer } from './FullScreenContainer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FixedBox, XButton } from './common';
import { AddCircleOutline, Visibility, Image } from '@mui/icons-material';
import { BottomActionContainer } from './BottomActionContainer';
import useId from '@mui/material/utils/useId';

const hidden = { opacity: 0, pointerEvents: 'none' };

const Frame = () => {
  const { width, height } = useFrameDimensions();
  return (
    <>
      <FixedBox
        sx={{
          border: '4px solid white',
          borderRadius: '2rem',
          left: '2.4rem',
          top: '50%',
          transform: 'translateY(-50%)',
          height: height,
          width: width,
        }}
      ></FixedBox>
    </>
  );
};

const CaptureButton = ({ onClick }) => {
  return (
    <Box
      sx={{
        borderRadius: '50%',
        width: '7.2rem',
        height: '7.2rem',
        border: '4px solid white',
        overflow: 'hidden',
        ':focus-within': {
          outline: '3px solid white',
          outlineOffset: '3px',
        },
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'white',
          opacity: '30%',
          '&:active': { opacity: '50%' },
        }}
        component='button'
      ></Box>
    </Box>
  );
};

const FileSelectButton = ({ onChange, hidden }) => {
  const { t } = useTranslation();
  const id = useId();
  return (
    <Box
      htmlFor={id}
      component='label'
      sx={{
        ':focus-within': {
          outline: '3px solid white',
        },
      }}
    >
      <Box width='4rem' height='4rem' m='auto'>
        <Image
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            color: 'white',
          }}
        />
      </Box>
      <Typography mt={0.8} variant='body2' color='white'>
        {t('businessCardScanner.upload')}
      </Typography>
      {!hidden && (
        <input
          id={id}
          multiple
          type='file'
          accept='image/*'
          onChange={onChange}
          style={{ height: 0, width: 0, opacity: 0, overflow: 'hidden', position: 'absolute' }}
        />
      )}
    </Box>
  );
};

const CameraFlipButton = ({ onClick, sx = {}, ...rest }) => {
  return (
    <IconButton
      size='large'
      onClick={onClick}
      sx={sx}
      focusVisibleClassName='focus-styles'
      {...rest}
    >
      <CameraswitchIcon sx={{ color: 'white', width: '3rem', height: '3rem' }} />
    </IconButton>
  );
};

const TorchButton = ({ onClick, isOn }) => {
  const Icon = isOn ? FlashOnIcon : FlashOffIcon;
  return (
    <FixedBox sx={{ top: '8rem', right: '2rem' }}>
      <IconButton size='large' onClick={onClick} focusVisibleClassName='focus-styles'>
        <Icon sx={{ color: 'white', width: '2.8rem', height: '2.8rem' }} />
      </IconButton>
    </FixedBox>
  );
};

const HelpButton = ({ onClick }) => {
  return (
    <FixedBox sx={{ top: '3rem', left: '3rem' }}>
      <Chip
        label='BETA'
        color='primaryButton'
        variant='filled'
        onClick={onClick}
        icon={<HelpOutlineIcon />}
      />
    </FixedBox>
  );
};

const CancelAcceptButton = ({ onAccept, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 'max-content',
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'white',
      }}
    >
      <BottomActionContainer display='flex' gap='1.3rem' width='100%'>
        <Button fullWidth size='large' color='primary' variant='outlined' onClick={onCancel}>
          {t('businessCardScanner.retake')}
        </Button>
        <Button fullWidth size='large' onClick={onAccept}>
          {t('save')}
        </Button>
      </BottomActionContainer>
    </Box>
  );
};

const FrameTooltipText = ({ text }) => {
  return (
    <Box
      sx={{
        boxShadow:
          '0px 1px 18px 0px rgba(17, 19, 86, 0.12), 0px 6px 10px 0px rgba(17, 19, 86, 0.14), 0px 3px 5px -1px rgba(17, 19, 86, 0.20)',
        bgcolor: 'white',
        px: '1.6rem',
        py: '1rem',
        borderRadius: '0.4rem',
        textAlign: 'center',
        width: 'max-content',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Typography variant='body1'>{text}</Typography>
    </Box>
  );
};

const BottomBarContainer = ({ children }) => {
  const { height } = useFrameDimensions();
  const bottomBarHeight = (window.innerHeight - height) / 2;

  return (
    <FixedBox
      sx={{
        mx: 3,
        bottom: '0',
        left: '0',
        right: '0',
        height: `${bottomBarHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        position='absolute'
        bottom='0'
        width='100%'
        pb={4}
        pt={2}
        minHeight='100%'
        display='flex'
        flexDirection='column'
        alignItems='stretch'
        justifyContent='space-between'
      >
        {children}
      </Box>
    </FixedBox>
  );
};

const ImageFaceFlipButton = ({
  onClick,
  isFrontFaceImage,
  isFrontFaceCaptured,
  isBackFaceCaptured,
}) => {
  const { t } = useTranslation();
  const backFace = isBackFaceCaptured
    ? {
        text: t('businessCardScanner.showBack'),
        icon: <Visibility />,
      }
    : {
        text: t('businessCardScanner.captureBack'),
        icon: <AddCircleOutline />,
      };

  const frontFace = isFrontFaceCaptured
    ? {
        text: t('businessCardScanner.showFront'),
        icon: <Visibility />,
      }
    : {
        text: t('businessCardScanner.captureFront'),
        icon: <AddCircleOutline />,
      };

  const { text, icon } = isFrontFaceImage ? backFace : frontFace;

  return (
    <Card sx={{ width: 'max-content', m: 'auto' }}>
      <CardActionArea
        onClick={onClick}
        sx={{ px: 1.5, py: 0.5 }}
        focusVisibleClassName='focus-styles'
      >
        <Box display='flex' alignItems='center' gap='1rem'>
          {icon} {text}
        </Box>
      </CardActionArea>
    </Card>
  );
};

export const VideoToolsOverlay = ({
  onFileSelect,
  onCaptureClick,
  onCloseClick,
  isCurrentFaceCaptured,
  onCaptureCancel,
  onCaptureAccept,
  onCameraFlipClick,
  onHelpClick,
  cameraFlipVisible,
  onTorchClick,
  isTorchAvailable,
  isTorchOn,
  infoText,
  isFrontFaceImage,
  onImageFaceFlipClick,
  isFrontFaceCaptured,
  isBackFaceCaptured,
}) => {
  const { t } = useTranslation();
  const isAnyFaceCaptured = isFrontFaceCaptured || isBackFaceCaptured;
  return (
    <FullScreenContainer nobg>
      <Box width='100%' height='100%' position='relative'>
        <XButton
          onClick={onCloseClick}
          aria-label={t('ariaCloseScanner')}
          focusVisibleClassName='focus-styles'
        />
        <Frame />
        {onHelpClick && <HelpButton onClick={onHelpClick} />}
        {isTorchAvailable && !isCurrentFaceCaptured && (
          <TorchButton isOn={isTorchOn} onClick={onTorchClick} />
        )}
        <BottomBarContainer>
          <Box flex={1}>
            {isCurrentFaceCaptured ? (
              <ImageFaceFlipButton
                onClick={onImageFaceFlipClick}
                isFrontFaceImage={isFrontFaceImage}
                isFrontFaceCaptured={isFrontFaceCaptured}
                isBackFaceCaptured={isBackFaceCaptured}
              />
            ) : (
              <FrameTooltipText text={infoText} />
            )}
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mt={1}
            minHeight='8rem'
          >
            <Box
              sx={isAnyFaceCaptured && hidden}
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              width='20%'
            >
              <FileSelectButton onChange={onFileSelect} hidden={isAnyFaceCaptured && hidden} />
            </Box>

            {isCurrentFaceCaptured ? (
              <CancelAcceptButton onAccept={onCaptureAccept} onCancel={onCaptureCancel} />
            ) : (
              <CaptureButton onClick={onCaptureClick} />
            )}

            <Box
              display='flex'
              sx={(!cameraFlipVisible || isCurrentFaceCaptured) && hidden}
              justifyContent='flex-end'
              alignItems='center'
              width='20%'
            >
              <CameraFlipButton
                onClick={onCameraFlipClick}
                aria-hidden={(!cameraFlipVisible || isCurrentFaceCaptured) && hidden}
                tabindex={(!cameraFlipVisible || isCurrentFaceCaptured) && hidden && -1}
              />
            </Box>
          </Box>
        </BottomBarContainer>
      </Box>
    </FullScreenContainer>
  );
};
