import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import mime from 'mime-types';
import { Box, Paper, Typography } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { flatten } from '@/shared/util/array';
import { useNotificationsStore } from '@/zustand/notificationsStore';

const DEFAULT_MAX_SIZE = 1;

const BYTE_SIZE = 1000000;

type Props = {
  onChange: (acceptedFiles: File[]) => void;
  types: string[];
  maxSize?: number | ((mimetype: string) => number); //MB
  fullWidth?: boolean;
  extensionsText?: string;
  description?: string;
};

const UploadFileIcon = () => (
  <Box
    sx={theme => ({
      backgroundColor: theme.palette.action.disabledBackground,
      width: '4rem',
      height: '4rem',
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    })}
  >
    <UploadFile fontSize='medium' />
  </Box>
);

const Dropzone = ({
  maxSize = DEFAULT_MAX_SIZE,
  onChange,
  types,
  fullWidth,
  extensionsText,
  description,
}: Props) => {
  const { t } = useTranslation();

  const handleSelect = (acceptedFiles: File[], rejections: FileRejection[]) => {
    if (rejections.length > 0) {
      const errors = Array.from(
        flatten(
          rejections.map(rejection =>
            rejection.errors.map((error: FileError & { meta: any }) => ({
              code: error.code,
              meta: error.meta,
            })),
          ),
        ),
      );

      const message = errors
        .map(({ code, meta }) =>
          t(`dropzone.errors.${code}`, {
            maxSize: meta?.maxSize,
            ext: meta?.ext,
            formats: types.map(x => `.${x}`).join(', '),
          }),
        )
        .join('\n');

      useNotificationsStore.getState().notify.error(message);
      return;
    }
    onChange(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleSelect,
    accept: types.reduce((acc, type) => {
      acc[mime.types[type]] = [type];
      return acc;
    }, {}),
    maxFiles: 1,
    // maxSize: maxSize * BYTE_SIZE,
    validator: (file): FileError & { meta: any } => {
      if (!types.some(t => mime.extension(file.type) === t)) return null; // don't show size error if file type rejected
      if (typeof maxSize === 'number' && file.size > maxSize * BYTE_SIZE) {
        return {
          code: 'file-too-large',
          message: 'File is too large',
          meta: { maxSize, ext: mime.extension(file.type) },
        };
      } else if (typeof maxSize === 'function' && file.size > maxSize(file.type) * BYTE_SIZE) {
        return {
          code: 'file-too-large',
          message: 'File is too large',
          meta: { maxSize: maxSize(file.type), ext: mime.extension(file.type) },
        };
      }
      return null;
    },
  });

  return (
    <Box>
      <Paper
        {...getRootProps()}
        sx={theme => ({
          py: 2,
          px: 3,
          borderStyle: 'dashed',
          m: 'auto',
          mb: 1,
          backgroundColor: isDragActive ? theme.palette.grey[200] : undefined,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          width: fullWidth ? '100%' : 'max-content',
          '&:focus-visible': {
            outline: '3px solid',
          },
        })}
        elevation={0}
        variant='outlined'
      >
        <input {...getInputProps()} type='file' />
        <UploadFileIcon />
        <Typography sx={{ mt: 1, mb: 0.8, textAlign: 'center' }} variant='body1'>
          {t('dropzone.title')}
        </Typography>
        <Typography variant='body2' color='text.secondary' sx={{ textAlign: 'center' }}>
          {extensionsText ?? typeof maxSize === 'number'
            ? types
                .map(x => `.${x}`)
                .join(' / ')
                .toLowerCase() + ` - max. ${maxSize}MB`
            : types.map(x => `.${x} (<${maxSize(mime.lookup(x) || '')}MB)`).join(' / ')}
        </Typography>
      </Paper>
      {description && (
        <Typography color='secondary.main' variant='body2' sx={{ mb: 0.8 }} textAlign='center'>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default Dropzone;
