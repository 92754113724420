import { useHistory } from 'react-router-dom';
import ScreenRendrer from '../ScreenRenderer';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { useEffect } from 'react';
import { routePaths } from '@/infrastructure/constants';
import withNav from '@/infrastructure/hoc/withNav';
import { PageContainer } from '@/components';

const QRCodeBGMobileView = () => {
  const history = useHistory();
  const isPhoneRoute = history?.location?.pathname === routePaths.QR_CODE_BACKGROUND.PHONE;
  const { desktopView } = useDesktopView();
  useEffect(() => {
    if (desktopView) history.push(routePaths.QR_CODE_BACKGROUND.ROOT);
  }, [desktopView, history]);
  return (
    <PageContainer maxWidth={'s'}>
      <ScreenRendrer screen={isPhoneRoute ? 'mobile' : 'videocall'} />
    </PageContainer>
  );
};

export default withNav(
  QRCodeBGMobileView,
  {
    tTitle: () =>
      window?.location?.pathname === routePaths.QR_CODE_BACKGROUND.PHONE
        ? 'qrCodeBackground.mobile.title'
        : 'qrCodeBackground.videocall.title',
    showBackButton: {
      linkTo: routePaths.SHARE_PROFILE,
    },
  },
  {},
);
