import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';

type Props = BoxProps & {
  title: string;
};

export const Section = ({ title, children, ...rest }: Props) => {
  return (
    <Box {...rest}>
      <Typography sx={{ mb: 2 }} variant='h3'>
        {title}
      </Typography>
      {children}
    </Box>
  );
};
