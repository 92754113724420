import { Account } from '@/shared/types/api';

import { useAppSelector } from '@/application/hooks';
import { UnitValue } from '../utils';
import { Employee } from '@/shared/types/api/employee.type';
import { useAuth0 } from '@auth0/auth0-react';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { getUnits } from '@/infrastructure/apis/md/units';
import { useEffect, useState } from 'react';
import { Unit } from '@/shared/types/api/unit.type';
import { UnitSelectorForProfiles } from '../../common/UnitSelectorForProfiles';

type Props = {
  value: UnitValue;
  onChange: (value: UnitValue) => void;
  selectedEmployees?: Employee[];
};

export const Units = ({ onChange, value, selectedEmployees }: Props) => {
  const account: Account = useAppSelector(state => state.account);
  const [units, setUnits] = useState<Unit[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { isThemeAdmin } = useTierInfo();

  useEffect(() => {
    if (!isThemeAdmin) return;
    const fetchData = async () => {
      try {
        const { data } = await getUnits(getAccessTokenSilently);
        setUnits(data.data.units);
      } catch (error) {}
    };
    fetchData();
  }, [getAccessTokenSilently, isThemeAdmin, account]);

  const availableUnits = isThemeAdmin ? units : account.units;

  return (
    <UnitSelectorForProfiles
      selectedEmployees={selectedEmployees}
      units={availableUnits || []}
      values={{
        selectedMainUnit: value?.mainUnit || null,
        selectedOtherUnits: value?.otherUnits || [],
      }}
      onChange={values =>
        onChange({
          mainUnit: values.selectedMainUnit,
          otherUnits: values.selectedOtherUnits,
        })
      }
    />
  );
};
