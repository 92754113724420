import { SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { useAppSelector } from '../../../../../application/hooks';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import config from '../../../../../config/config';
import { withSilentAccessToken } from '../../../../../infrastructure/helper';
import { Account, LeadOptionTypeAPI, MDLead, ThemeAccount } from '../../../../../shared/types/api';
import { PERMISSIONS } from '../../../../../infrastructure/constants';
import { useExternalProfileDesignByUsername } from '@/infrastructure/hooks/useProfileDesign';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

export const useLt2LtAccount = (lead: MDLead) => {
  const { getAccessTokenSilently } = useAuth0();
  const [lt2ltAccount, setLt2ltAccount] = useState<Account>();
  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  const { profileDesign } = useExternalProfileDesignByUsername(lead?.lt2lt?.username);

  useEffect(() => {
    if (lead && lead.id && lead?.lt2lt?.username && getAccessTokenSilently) {
      setLt2ltAccount(null);
      setLoading(true);
      withSilentAccessToken(getAccessTokenSilently, token =>
        axios.get(config.API_BASE_URL + `account/${lead.lt2lt.username}?crossDomain=true`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      )
        .then(res => {
          const result = res.data;
          if (result?.isSuccess) {
            setLt2ltAccount(res.data.data);
          } else {
            setError(false);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [getAccessTokenSilently, lead]);

  return { lt2ltAccount, isLoading: isLoading || !profileDesign, error, profileDesign };
};

export const useThemeLeadOptionTypes = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();
  const account: Account = useAppSelector(state => state.account);
  const [error, setError] = useState<boolean>();
  const [themeLeadOptionTypes, setThemeLeadOptionTypes] = useState<LeadOptionTypeAPI[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (account) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `lead-gen/accounts/${account.id}/lead-option-types`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [],
        () => setIsLoading(true),
      )
        .then(res => {
          const data = res.data;
          if (data?.isSuccess) {
            setThemeLeadOptionTypes(data.data);
          } else {
            setError(true);
            toast.error(t('error.general'));
            setThemeLeadOptionTypes([]);
          }
        })
        .catch(() => {
          setError(true);
          toast.error(t('error.general'));
          setThemeLeadOptionTypes([]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [account, getAccessTokenSilently, t, toast]);

  return { themeLeadOptionTypes, isLoading, error };
};

export const useThemeAllowedKeys = () => {
  const account: Account = useAppSelector(state => state.account);
  const { getAccessTokenSilently } = useAuth0();

  const [themeAllowedKeys, setThemeAllowedKeys] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  useEffect(() => {
    if (account) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `lead-gen/accounts/${account.id}/allowed-keys`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [],
        () => setIsLoading(true),
      )
        .then(res => {
          let data = res.data;
          if (data?.isSuccess && data?.data) {
            setThemeAllowedKeys(res.data.data);
          }
        })
        .catch(() => setError(true))
        .finally(() => setIsLoading(false));
    }
  }, [account, getAccessTokenSilently]);

  return { themeAllowedKeys, isLoading, error };
};

export const useEditLead = () => {
  const [editLead, setEditLead] = useState(false);
  const [lead, setLead] = useState<MDLead>();

  const handleEdit = (lead: MDLead) => {
    setEditLead(true);
    setLead(lead);
  };

  const onEditBackClick = () => {
    setEditLead(false);
  };

  return { editLead, lead, handleEdit, onEditBackClick };
};

export const useAddLead = () => {
  const [addLead, setAddLead] = useState(false);

  const handleAdd = () => {
    setAddLead(true);
  };

  const onAddBackClick = () => {
    setAddLead(false);
  };

  return { addLead, handleAdd, onAddBackClick };
};

export const useDeleteLead = () => {
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>();

  const onDeleteLeadClick = () => {
    setShowDeletePopup(true);
    return;
  };

  const onDeleteLeadClose = (): SetStateAction<boolean> => {
    setShowDeletePopup(false);
    return;
  };

  return { showDeletePopup, onDeleteLeadClick, onDeleteLeadClose };
};

export const useThemeAccounts = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const [themeAccounts, setThemeAccounts] = useState<Array<ThemeAccount>>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'theme/profiles-shallow', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
      () => setIsLoading(true),
    )
      .then(res => {
        let result: {
          isSuccess: boolean;
          data: Array<ThemeAccount>;
        } = res.data;
        if (result.isSuccess) {
          setThemeAccounts(result.data);
        } else {
          setError(true);
          toast.error(t('error.general'));
        }
      })
      .catch(err => {
        setError(true);
        toast.error(t('error.general'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getAccessTokenSilently, t, toast]);

  return { themeAccounts, isLoading, error };
};
