import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Box, Typography, Switch } from '@mui/material';
import { ComponentProps } from 'react';
import { SubField } from './constants';

type FieldsSetProps = {
  defaultFields: SubField[];
  blockedFields: string[];
  onChange: (fieldId: string) => void;
  isEditable: boolean;
  sx?: ComponentProps<typeof Box>['sx'];
};

export const FieldsSet: React.FC<FieldsSetProps> = ({
  defaultFields,
  blockedFields,
  onChange,
  isEditable,
  sx,
}) => {
  const { t } = useAppTranslation();
  return (
    <Box sx={sx}>
      {defaultFields.map((field, index) => (
        <Box
          py={0.5}
          pr={1.5}
          key={index}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={theme => ({ borderTop: `1px solid ${theme.palette.divider}` })}
        >
          <Typography variant='body1'>{field.name || t(field.tname)}</Typography>
          <Switch
            disabled={!isEditable}
            onChange={() => onChange(field.id)}
            checked={!blockedFields?.includes(field.id)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      ))}
    </Box>
  );
};
