import { Toolbar, styled } from '@mui/material';

const CustomTableToolbar = styled(Toolbar)<{ secondary?: boolean }>(({ theme }) => ({
  '&.MuiToolbar-root': {
    gap: '1.6rem',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.light,
    },
  },
}));

export default CustomTableToolbar;
