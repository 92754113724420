export enum VCardFieldType {
  email = 'email',
  phone = 'phone',
  address = 'address',
  link = 'link',
}

export const VCARD_CONTACT_FIELDS = [
  {
    key: 'email',
    tKey: 'email',
    fieldType: VCardFieldType.email,
  },
  {
    key: 'workEmail',
    tKey: 'workEmail',
    fieldType: VCardFieldType.email,
  },
  {
    key: 'workEmail2',
    tKey: 'workEmail2',
    fieldType: VCardFieldType.email,
  },
  {
    key: 'assistantEmail',
    tKey: 'assistantEmail.text',
    fieldType: VCardFieldType.email,
  },
  {
    key: 'workPhone',
    tKey: 'workPhone.text',
    fieldType: VCardFieldType.phone,
  },
  {
    key: 'mobilePhone',
    tKey: 'mobilePhone.text',
    fieldType: VCardFieldType.phone,
  },
  {
    key: 'homePhone',
    tKey: 'homePhone.text',
    fieldType: VCardFieldType.phone,
  },
  {
    key: 'hotlinePhone',
    tKey: 'hotlinePhone.text',
    fieldType: VCardFieldType.phone,
  },
  {
    key: 'workFax',
    tKey: 'workFax.text',
    fieldType: VCardFieldType.phone,
  },
  {
    key: 'assistantPhone',
    tKey: 'assistantPhone.text',
    fieldType: VCardFieldType.phone,
  },
  {
    key: 'address1',
    tKey: 'address1',
    fieldType: VCardFieldType.address,
  },
  {
    key: 'address2',
    tKey: 'address2',
    fieldType: VCardFieldType.address,
  },
  {
    key: 'businessCardLink',
    tKey: 'businessCardLink',
    fieldType: VCardFieldType.link,
  },
  {
    key: 'workUrl',
    tKey: 'workUrl',
    fieldType: VCardFieldType.link,
  },
];

const VCARD_PHONE_DEFAULT_LABELS = [
  {
    isCustom: false,
    type: 'WORK,VOICE',
    en: 'Work',
    de: 'Arbeit',
  },
  {
    isCustom: false,
    type: 'HOME,VOICE',
    en: 'Private',
    de: 'Privat',
  },
  {
    isCustom: false,
    type: 'CELL,VOICE',
    en: 'Mobile',
    de: 'Mobil',
  },
  {
    isCustom: true,
    type: null,
    en: 'Hotline',
    de: 'Hotline',
  },
  {
    isCustom: true,
    type: null,
    en: 'Assistance',
    de: 'Assistenz',
  },
  {
    isCustom: false,
    type: 'WORK,FAX',
    en: 'Work Fax',
    de: 'Fax (Arbeit)',
  },
  {
    isCustom: false,
    type: 'HOME,FAX',
    en: 'Home Fax',
    de: 'Fax (privat)',
  },
  {
    isCustom: true,
    type: null,
    en: 'Other',
    de: 'Sonstige',
  },
];

const VCARD_EMAIL_DEFAULT_LABELS = [
  {
    isCustom: false,
    type: 'WORK',
    en: 'Work',
    de: 'Arbeit',
  },
  {
    isCustom: false,
    type: 'HOME',
    en: 'Private',
    de: 'Privat',
  },
  {
    isCustom: true,
    type: null,
    en: 'Assistance',
    de: 'Assistenz',
  },
  {
    isCustom: true,
    type: 'OTHER',
    en: 'Other',
    de: 'Sonstige',
  },
];

const VCARD_ADDRESS_DEFAULT_LABELS = [
  {
    isCustom: false,
    type: 'WORK',
    en: 'Work',
    de: 'Arbeit',
  },
  {
    isCustom: false,
    type: 'HOME',
    en: 'Private',
    de: 'Privat',
  },
  {
    isCustom: true,
    type: 'OTHER',
    en: 'Other',
    de: 'Sonstige',
  },
];

const VCARD_LINK_DEFAULT_LABELS = [
  {
    isCustom: true,
    type: null,
    en: 'Homepage',
    de: 'Homepage',
  },
  {
    isCustom: true,
    type: null,
    en: 'Digital Business Card',
    de: 'Digitale Visitenkarte',
  },
  {
    isCustom: false,
    type: 'WORK',
    en: 'Work',
    de: 'Arbeit',
  },
  {
    isCustom: false,
    type: 'HOME',
    en: 'Private',
    de: 'Privat',
  },
];

export const VCARD_FIELD_LABELS = {
  [VCardFieldType.email]: VCARD_EMAIL_DEFAULT_LABELS,
  [VCardFieldType.phone]: VCARD_PHONE_DEFAULT_LABELS,
  [VCardFieldType.address]: VCARD_ADDRESS_DEFAULT_LABELS,
  [VCardFieldType.link]: VCARD_LINK_DEFAULT_LABELS,
};
