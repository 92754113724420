import { useState } from 'react';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import LtDialog from '@/components/LtDialog';
import { FormControl, RadioGroup, FormLabel, FormControlLabel, Radio, Box } from '@mui/material';
import useId from '@mui/material/utils/useId';

interface Props {
  open: boolean;
  onClose: () => void;
  options: string[];
  initialSelection: string;
  onSelect: (option: string) => void;
}

export default function ImageOptionsSelector(props: Props): JSX.Element {
  const { open, onClose, options, initialSelection, onSelect } = props;
  const { t } = useAppTranslation();
  const [value, setValue] = useState<string>(
    options.includes(initialSelection) ? initialSelection : null,
  );

  const baseId = useId();

  return (
    <LtDialog open={open} onClose={onClose} onSave={() => onSelect(value)} disabled={!value}>
      <FormControl sx={{ width: '100%', maxWidth: '48rem', margin: 'auto', display: 'flex' }}>
        <FormLabel id={baseId}>{t('ariaBannerImageOptionsLabel')}</FormLabel>
        <RadioGroup
          aria-labelledby={baseId}
          defaultValue={initialSelection}
          name={baseId}
          value={value}
          onChange={e => setValue(e.target.value)}
          sx={{ alignItems: 'center' }}
        >
          {options.map((option, idx) => {
            return (
              <FormControlLabel
                value={option}
                control={
                  <Radio
                    inputProps={{
                      'aria-label': t('ariaBannerImageOption', { n: idx }),
                    }}
                    sx={{
                      mr: 2,
                    }}
                  />
                }
                label={
                  <Box
                    component='img'
                    src={option}
                    aria-hidden='true'
                    sx={{
                      maxWidth: '100%',
                      objectFit: 'contain',
                    }}
                  />
                }
                sx={{
                  mt: 2,
                }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </LtDialog>
  );
}
