import { Filter } from '@/shared/util';
import { Box, Divider, Drawer, DrawerProps } from '@mui/material';
import { ListFilter } from './components/ListFilter';
import { FilterHeader } from './components/FilterHeader';
import { AddFilterButton } from './components/AddFilterButton';
import { FilterValue } from './utils';

type Props = DrawerProps & {
  filters: Filter[];
  onFilterValuesChange: (filterValues: Record<string, FilterValue>) => void;
  filterValues: Record<string, FilterValue>;
  onClose: () => void;
  visibleFilters: string[];
  onFilterVisibilityChange: (key: string[]) => void;
};
const FiltersRenderer = ({
  filters,
  filterValues,
  onFilterValuesChange,
  onClose,
  visibleFilters,
  onFilterVisibilityChange,
  ...rest
}: Props) => {
  if (!filters?.length) return null;

  const handleChange = (field: string, newVal: FilterValue) => {
    onFilterValuesChange({ ...filterValues, [field]: newVal });
  };

  const handleFilterDelete = (field: string) => () => {
    onFilterVisibilityChange(visibleFilters.filter(f => f !== field));
  };

  const filtersToRender = filters.filter(f => visibleFilters.includes(f.field));

  return (
    <Drawer
      anchor='right'
      onClose={onClose}
      {...rest}
      PaperProps={{ sx: { width: '50rem', p: 2 } }}
    >
      <FilterHeader onClose={onClose} />
      <AddFilterButton
        filters={filters}
        onAdd={key => onFilterVisibilityChange([...visibleFilters, key])}
      />

      <Box>
        {filtersToRender.map(filter => {
          const type = filter.type;
          const value = filterValues[filter.field];
          switch (type) {
            case 'list':
              return (
                <Box key={filter.field} mt={2.5}>
                  <Divider sx={{ mb: 1 }} />
                  <ListFilter
                    filterValue={value}
                    filter={filter}
                    onFilterValueChange={x => handleChange(filter.field, x)}
                    onFilterDelete={handleFilterDelete(filter.field)}
                  />
                </Box>
              );

            default:
              return null;
          }
        })}
      </Box>
    </Drawer>
  );
};

export default FiltersRenderer;
