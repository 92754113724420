import { AnchorText } from '../AnchorText';
import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';
import { Box, Typography, Divider, TextField } from '@mui/material';
import { StrategyName, StrategyOptions } from '@/shared/types/api';
import { useStrategyFields } from '../helpers/hooks/useStrategyFields';
import { useState } from 'react';

type Props = {
  values: StrategyOptions;
  onChange: (values: StrategyOptions) => void;
  selectedStrategy: StrategyName;
};

export const AddSamlStep = ({ values, onChange, selectedStrategy }: Props) => {
  const { t } = useTranslation();

  const [manualFieldsVisible, setManualFieldsVisible] = useState(false);

  const isSamlp = selectedStrategy === 'samlp';
  const fields = useStrategyFields(selectedStrategy, values, onChange);

  return (
    <>
      <TextsRenderer
        title={t('idp.sso.steps.addSaml.title')}
        texts={[t('idp.sso.steps.addSaml.description')]}
      />
      {/* <AnchorText mb={3} href='#' text={t('idp.sso.learnMoreAboutSaml')} /> */}
      {isSamlp && (
        <>
          <Typography mb={1.3} variant='h4'>
            {t('idp.sso.steps.addSaml.setupUsingMetadata')}
          </Typography>
          <TextField
            value={values.metaDataUrl}
            onChange={e => onChange({ ...values, metaDataUrl: e.target.value })}
            fullWidth
            label={t(`idp.sso.metaDataUrl`)}
            // helperText={t(`idp.sso.metaDataUrlHelper`)}
          />
          <Divider sx={{ my: 2 }}>
            <Typography mb={1.3} variant='h4'>
              {t('idp.sso.steps.addSaml.or')}
            </Typography>
          </Divider>
        </>
      )}
      {isSamlp && (
        <AnchorText
          mb={3}
          href='#'
          onClick={() => setManualFieldsVisible(!manualFieldsVisible)}
          text={t('idp.sso.steps.addSaml.setupManually')}
        />
      )}
      {(manualFieldsVisible || !isSamlp) && (
        <Box display='flex' flexDirection='column' gap={2}>
          {fields}
        </Box>
      )}
    </>
  );
};
