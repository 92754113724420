import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import {
  BULK_EDIT_ACTION_TYPES,
  bulkUpdateSaveHandler,
  Field,
  FieldValue,
  isActionChooserAvailable,
  isImageField,
  isSaveDisabled,
  isTextField,
} from './utils';
import { FieldSelector } from './components/FieldSelector';
import { WrapperDrawer } from './components/WrapperDrawer';
import { ActionSelector } from './components/ActionSelector';
import { Section } from './components/Section';
import { FieldRenderer } from './components/FieldRenderer';
import { useAuth0 } from '@auth0/auth0-react';
import { Employee } from '@/shared/types/api/employee.type';
import { AccountImageState } from '@/shared/types/api';

interface Props {
  onBackClick: () => void;
  onSave: () => void;
  open: boolean;
  selectedEmployees: Employee[];
}

export const BulkEdit = ({ onBackClick, open, onSave, selectedEmployees }: Props) => {
  const { notify } = useLtNotifications();
  const { t } = useTranslation();
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const [selectedActionType, setSelectedActionType] = useState(BULK_EDIT_ACTION_TYPES.OVERRIDE);
  const [selectedField, setSelectedField] = useState<Field>(null);
  const [fieldValue, setFieldValue] = useState<FieldValue>(null);

  const { getAccessTokenSilently } = useAuth0();

  const { withUnsavedSetter } = useUnsavedStatusSetter();

  useEffect(() => {
    if (!open) {
      setSelectedActionType(BULK_EDIT_ACTION_TYPES.OVERRIDE);
      setSelectedField(null);
      setFieldValue(null);
    }
  }, [open]);

  useEffect(() => {
    if (selectedField && !isTextField(selectedField)) {
      setSelectedActionType(BULK_EDIT_ACTION_TYPES.OVERRIDE);
    }
  }, [selectedField]);

  const [bulkUpdateLoading, setBulkUpdateLoading] = useState(false);

  const handleSave = async () => {
    if (!selectedField || (selectedField !== 'accountRole' && selectedActionType === BULK_EDIT_ACTION_TYPES.OVERRIDE && !fieldValue)) {
      notify.error(t('error.pleaseFillOutRequired'));
      return;
    }

    const value =
      selectedActionType === BULK_EDIT_ACTION_TYPES.OVERRIDE
        ? fieldValue
        : isImageField(selectedField)
        ? { url: '', state: AccountImageState.DEFAULT }
        : '';

    setBulkUpdateLoading(true);
    try {
      await bulkUpdateSaveHandler(getAccessTokenSilently, selectedEmployees, selectedField, value);
      await onSave();
      onBackClick();
      setIsUnsaved(false);
      notify.success(t('bulkUpdated'));
    } catch (error) {
      notify.error(t('bulkFailed'));
    }
    setBulkUpdateLoading(false);
  };

  const { openDialogIfUnsaved } = useUnsavedStatus();
  const handleClose = () => openDialogIfUnsaved(onBackClick);

  let sectionIdx = 1;
  const getTitle = (tText: string) => {
    return sectionIdx++ + '. ' + t(tText);
  };

  return (
    <WrapperDrawer
      open={open}
      onClose={handleClose}
      onSave={handleSave}
      loading={bulkUpdateLoading}
      saveDisabled={
        selectedActionType === BULK_EDIT_ACTION_TYPES.OVERRIDE &&
        isSaveDisabled(selectedField, fieldValue)
      }
    >
      <Section title={getTitle('bulkActions.chooseField')} mb={4}>
        <FieldSelector
          onSelect={withUnsavedSetter(val => {
            setSelectedField(val);
            setFieldValue(null);
          })}
          selectedField={selectedField}
        />
      </Section>
      {isActionChooserAvailable(selectedField) && (
        <Section title={getTitle('bulkActions.chooseActionType')} mb={4}>
          <ActionSelector
            onChange={withUnsavedSetter(setSelectedActionType)}
            selectedActionType={selectedActionType}
            selectedCount={selectedEmployees.length}
          />
        </Section>
      )}

      {selectedField && selectedActionType === BULK_EDIT_ACTION_TYPES.OVERRIDE && (
        <Section title={getTitle('bulkActions.value')}>
          <FieldRenderer
            fieldValue={fieldValue}
            onChange={withUnsavedSetter(setFieldValue)}
            selectedField={selectedField}
            selectedEmployees={selectedEmployees}
          />
        </Section>
      )}
    </WrapperDrawer>
  );
};
