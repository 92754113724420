import { useEffect, useState } from 'react';
import useGenericSelection from './useGenericSelection';

export const useTableSelection = <T extends { id: number | string }>(
  items: T[],
  curPageItems?: T[],
) => {
  const [isSelectAllVisible, setSelectAllVisibility] = useState(false);
  const selectionProps = useGenericSelection(items, curPageItems);

  const {
    selectItem,
    selectAllItems,
    unselectAllOnPage,
    selectAllOnPage,
    selectedCount,
    isAnySelectedOnCurrentPage,
  } = selectionProps;

  const handleCheckboxClick = (item: T) => {
    selectItem(item);
    setSelectAllVisibility(false);
  };

  const handleHeaderCheckboxClick = () => {
    if (isAnySelectedOnCurrentPage) {
      unselectAllOnPage();
      setSelectAllVisibility(false);
      return;
    }
    selectAllOnPage();
    if (selectedCount < items.length) {
      setSelectAllVisibility(true);
    }
  };

  useEffect(() => {
    if (selectedCount === items.length) {
      setSelectAllVisibility(false);
    }
  }, [selectedCount, items.length]);

  const handleSelectAll = () => {
    selectAllItems();
    setSelectAllVisibility(false);
  };

  return {
    isSelectAllVisible,
    handleCheckboxClick,
    handleHeaderCheckboxClick,
    handleSelectAll,
    ...selectionProps,
  };
};
