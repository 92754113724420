import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import EditEmployee from '../edit-employee';
import useEdit from './hooks/useEdit';
import useSelection from './hooks/useSelection';
import TableUi, { TableUiHandle } from './components/table';
import { Employee } from '../../../../../shared/types/api/employee.type';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ApiFile, Link, ThemeInternal } from '../../../../../shared/types/api';
import { _bulkDelete } from '../../../../../infrastructure/apis/md/profiles/bulk';
import { useAppSelector } from '../../../../../application/hooks';
import { Unit } from '../../../../../shared/types/api/unit.type';
import { getUnits } from '../../../../../infrastructure/apis/md/units';
import { FEATURE } from '../../../../../shared/constants';
import useTierInfo from '../../../../../infrastructure/hooks/useTierInfo';
import usePagination from './hooks/usePagination';
import { SortOption } from '@/shared/types/global';
import { LtDialog } from '@/components';
import { Drawer, Typography } from '@mui/material';
import { BulkEdit } from '../BulkEdit';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

export interface TableProps {
  employees: Employee[];
  tableRef: MutableRefObject<TableUiHandle>;
  SelectedObjects: {
    selectedLinksProps: {
      selectedLink: Link;
      setSelectedLink: Dispatch<SetStateAction<Link>>;
    };
    selectedFilesProps: {
      selectedFile: ApiFile;
      setSelectedFile: Dispatch<SetStateAction<ApiFile>>;
    };
  };
  refreshEmployees: () => void;
  orderBy?: string | null;
  sort?: 'asc' | 'desc' | null;
  onSortChange?: (sortOption: SortOption) => void;
}

const Table = (props: TableProps) => {
  const { notify: toast } = useLtNotifications();
  const { isFeatureAllowed } = useTierInfo();
  const paginationProps = usePagination();
  const { page, rowsPerPage } = paginationProps;
  const selectionProps = useSelection(props.employees, page, rowsPerPage);

  const { unselectAllEmployees, selectedEmployees, selectedIds } = selectionProps;
  const editProps = useEdit();
  const { editEmployee, employee, onBackClick } = editProps;
  const { user } = useAuth0();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const bulkDelete = () => setShowDeletePopup(true);
  const [units, setUnits] = useState<Unit[]>([]);
  const themeAdmin = user['https://lemontaps.com/claim/roles'].includes('theme_admin');

  const { orderBy, sort, onSortChange } = props;

  useEffect(() => {
    if (themeAdmin && isFeatureAllowed(FEATURE.UNITS)) {
      getUnits(getAccessTokenSilently)
        .then(res => {
          setUnits(res.data.data.units);
        })
        .catch(err => {
          toast.error(t('mdLeads.errorReadUnits'));
          setUnits([]);
        });
    }
  }, [getAccessTokenSilently, isFeatureAllowed, t, themeAdmin, toast]);

  const themeInternal = useAppSelector<ThemeInternal>(state => state.account?.theme?.themeInternal);

  const performDelete = async () => {
    // prevent deleting main account
    if (themeInternal?.mainAccountId && selectedIds.includes(themeInternal?.mainAccountId)) {
      return toast.error(t('md.profiles.error.deleteMainAccount'));
    }

    setIsDeleting(true);
    try {
      await _bulkDelete(selectedIds, getAccessTokenSilently);
      props.refreshEmployees();
      toast.success(t('successfullyUsersDeleted', { count: selectedIds.length }));
      setShowDeletePopup(false);
      unselectAllEmployees();
    } catch (error) {
      toast.error(error.message || t('errorDeletingUsers', { count: selectedIds.length }));
    }
    setIsDeleting(false);
  };

  const combinedProps = {
    ...editProps,
    ...selectionProps,
    bulkDelete,
  };

  const [bulkEditDrawerOpened, setBulkEditDrawerOpened] = useState(false);

  return (
    <>
      <BulkEdit
        open={bulkEditDrawerOpened}
        onBackClick={() => setBulkEditDrawerOpened(false)}
        onSave={props.refreshEmployees}
        selectedEmployees={selectedEmployees}
      />

      <Drawer
        PaperProps={{ sx: { width: '75rem' } }}
        open={Boolean(editEmployee && employee)}
        anchor='right'
        onClose={onBackClick}
      >
        {editEmployee && employee && <EditEmployee employee={employee} onBackClick={onBackClick} />}
      </Drawer>
      <TableUi
        ref={props.tableRef}
        employees={props.employees}
        {...combinedProps}
        SelectedObjects={props.SelectedObjects}
        units={units}
        refreshEmployees={props.refreshEmployees}
        orderBy={orderBy}
        sort={sort}
        paginationProps={paginationProps}
        onSortChange={onSortChange}
        onBulkEditClick={() => setBulkEditDrawerOpened(true)}
      />
      <LtDialog
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        onCancel={() => setShowDeletePopup(false)}
        title={t('deleteAccounts', { count: selectedIds.length })}
        size='sm'
        onDelete={performDelete}
        withActionDivider
        loading={isDeleting}
      >
        <Typography variant='body1'>
          {t('deleteBulkMessageConfirmation', { count: selectedIds.length })}
        </Typography>
      </LtDialog>
    </>
  );
};

export default Table;
