import { LtDialog } from '@/components';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  idpName: string;
  onSave: (value: string) => void;
  loading?: boolean;
};

export const IdpEditPopup = ({ open, onClose, idpName, onSave, loading }: Props) => {
  const [newValue, setNewValue] = useState(idpName);
  const { t } = useTranslation();

  return (
    <LtDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onSave={() => onSave(newValue)}
      title={t('idp.renameDirectory')}
    >
      <TextField
        label={t('idp.dirName')}
        fullWidth
        value={newValue}
        onChange={({ target: { value } }) => setNewValue(value)}
      />
    </LtDialog>
  );
};
