import { Dispatch, SetStateAction } from 'react';
import { useAppDispatch } from '@/application/hooks';
import { deleteLink } from '@/application/actions/edit-profile';
import { deleteFile } from '@/application/actions/edit-profile';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { trackPrivateEvent } from '@/infrastructure/apis/analytics';
import { Link, ApiFile } from '@/shared/types/api';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '@/shared/constants/global';
import { LtDialog } from '@/components';
import { ThemeProvider, Typography } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

export type DeleteModeType = 'link' | 'file' | '';

interface Props {
  mode: DeleteModeType;
  onCloseClick: () => void;
  item: { id?: number };
  employeeId?: string;
  isCompanydataSettings?: boolean;
  handleCompanySettingsFileDelete?: (itemId: number) => void;
  userLinks?: Array<Link>;
  setUserLinks?: Dispatch<SetStateAction<Array<Link>>>;
  userFiles?: Array<ApiFile>;
  setUserFiles?: Dispatch<SetStateAction<Array<ApiFile>>>;
}

const DeletePopup = (props: Props): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { item, employeeId } = props;

  const onDeleteClick = () => {
    if (props.mode === 'link') {
      if (employeeId) {
        props.setUserLinks(props.userLinks.filter(link => link.id !== item.id));
      } else {
        dispatch(deleteLink(item.id, getAccessTokenSilently));
        trackPrivateEvent(
          getAccessTokenSilently,
          PRIVATE_EVENTS.PROFILE_EDIT,
          {
            type: PRIVATE_META_TYPES.LINK_DELETE,
          },
          null,
          item.id,
        );
      }
      props.onCloseClick();
    } else if (props.mode === 'file') {
      if (props.handleCompanySettingsFileDelete) {
        props.handleCompanySettingsFileDelete(item.id);
      } else if (employeeId) {
        props.setUserFiles(props.userFiles.filter(file => file.id !== item.id));
      } else {
        trackPrivateEvent(
          getAccessTokenSilently,
          PRIVATE_EVENTS.PROFILE_EDIT,
          {
            type: PRIVATE_META_TYPES.FILE_DELETE,
          },
          item.id,
        );
        dispatch(deleteFile(item.id, getAccessTokenSilently));
      }
      props.onCloseClick();
    }
  };

  const { theme } = useMuiTheme();
  return (
    <ThemeProvider theme={theme}>
      <LtDialog
        open
        onClose={props.onCloseClick}
        onCancel={props.onCloseClick}
        title={t('requestDelete')}
        size='sm'
        onDelete={onDeleteClick}
        withActionDivider
      >
        <Typography>
          {props.mode === 'link' ? t('deleteMessageLink') : t('deleteMessageFile')}
        </Typography>
      </LtDialog>
    </ThemeProvider>
  );
};

export default DeletePopup;
