import config from '@/config/config';
import { PERMISSIONS } from '@/infrastructure/constants';
import { withSilentAccessToken } from '@/infrastructure/helper';
import { Idp } from '@/shared/constants/Idp';
import { StrategyData, UpdateStrategyData } from '@/shared/types/api';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import axios from 'axios';

export type ThemeIdpUpdatePayload = {
  isActive?: boolean;
  niceName?: string;
};

//** IDP */

export const createThemeIdp = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: { name: Idp; niceName: string },
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(`${config.API_BASE_URL}idps`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const getThemeIdps = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  options: { skip?: number; take?: number; searchWord?: string } = {},
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}idps`, {
        params: options,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.IAM],
  );
};

export const getThemeIdp = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}idps/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.IAM],
  );
};

export const updateThemeIdp = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
  payload: ThemeIdpUpdatePayload,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.put(`${config.API_BASE_URL}idps/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const deleteThemeIdp = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(`${config.API_BASE_URL}idps/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

//** SCIM  */

export const generateSecretToken = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(`${config.API_BASE_URL}idps/${id}/scim/generateSecretToken`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const deleteScimConfiguration = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(`${config.API_BASE_URL}idps/${id}/scim/deleteConfiguration`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const toggleScimInvitation = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(`${config.API_BASE_URL}idps/${id}/scim/toggleInvitation`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

//** SSO */

export const getSsoConnection = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}idps/${id}/sso`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.IAM],
  );
};

export const createSsoConnection = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
  payload: StrategyData,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        `${config.API_BASE_URL}idps/${id}/sso`,
        { data: payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const updateSsoConnection = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
  payload: UpdateStrategyData,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.patch(
        `${config.API_BASE_URL}idps/${id}/sso`,
        { data: payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const deleteSsoConnection = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(`${config.API_BASE_URL}idps/${id}/sso`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const toggleSsoConnection = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string | number,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(`${config.API_BASE_URL}idps/${id}/sso/toggle`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.IAM],
  );
};

export const getScimBlockedFieldsByAccountId = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: string,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}idps/scim/blockedFields/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.IAM],
  );
};
