import { updateAccount } from '../../infrastructure/apis/edit-bio';
import { dispatchTypes } from './index';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Account } from '../../shared/types/api';

export const updateAccountDetails =
  (
    account: Account,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    callbacks?: { onSuccess?: () => void; onError?: () => void },
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITBIO.SAVE });
    try {
      const response = await updateAccount(account, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITBIO.SAVESUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEBIODETAILS,
          account,
        });
        callbacks?.onSuccess?.();
      } else {
        callbacks?.onError?.();
        dispatch({
          type: dispatchTypes.EDITBIO.SAVEERROR,
          apiResponse: result,
        });
      }
    } catch (error) {
      callbacks?.onError?.();
      dispatch({ type: dispatchTypes.EDITBIO.SAVEERROR, apiResponse: error });
    }
  };

export const clearApiResponse = () => async dispatch => {
  dispatch({ type: dispatchTypes.EDITBIO.CLEARAPIRESPONSE });
};

export const updateDefaultTemplate =
  (defaultAddressTemplate: number, errorCb: Function) => async dispatch => {
    try {
      dispatch({
        type: dispatchTypes.COMMON.SETTHEMEDEFAULTADDRESSTEMPLATE,
        data: { defaultAddressTemplate },
      });
    } catch (error) {
      errorCb();
    }
  };
