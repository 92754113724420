import ThemeOverride from '@/components/ThemeOverride';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { StaticProfileConfig } from '@/shared/types/api';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';

type Props = ButtonProps & {
  config: StaticProfileConfig['header']['contactSaveButton'];
  contactSaveTrackHandler?: () => void;
};

export default function SaveContactButton({
  config,
  contactSaveTrackHandler,
  ...buttonProps
}: Props) {
  const { theme: baseTheme } = useMuiTheme();
  if (!config) return null;
  return (
    <ThemeOverride
      overrides={{
        palette: {
          primaryButton: baseTheme.palette.augmentColor({
            color: { main: config.backgroundCss },
          }),
        },
      }}
    >
      <Button
        startIcon={<FileDownloadOutlined />}
        {...buttonProps}
        sx={{ width: '100%', ...(buttonProps.sx || {}) }}
        href={config.href}
        onClick={contactSaveTrackHandler}
      >
        {config.text}
      </Button>
    </ThemeOverride>
  );
}
