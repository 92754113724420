import LtLoadingButton from '@/components/LtLoadingButton';
import { CrmServiceConfguration } from '@/shared/types/api';
import { ExpandMore, Refresh, WarningAmber } from '@mui/icons-material';
import {
  TextField,
  Box,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from '@mui/material';
import useId from '@mui/material/utils/useId';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Option = {
  value: string;
  label: string;
};

type CommonProps = {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  required?: boolean;
  description?: string;
};

type TextFieldProps = CommonProps & {
  type?: string;
};

const RenderTextField = ({
  onChange,
  value,
  type,
  label,
  required,
  description,
}: TextFieldProps) => {
  return (
    <TextField
      label={label}
      fullWidth
      value={value || ''}
      onChange={e => onChange(e.target.value)}
      type={type}
      required={required}
      helperText={description}
    />
  );
};

const RenderDateField = ({
  onChange,
  value,
  type,
  label,
  required,
  description,
}: TextFieldProps) => {
  const isDatetime = type === 'datetime';

  const handleChange = val => {
    onChange(new Date(val).toISOString());
  };

  return (
    <TextField
      label={label}
      fullWidth
      value={moment(value).format().substring(0, 16)}
      onChange={e => handleChange(e.target.value)}
      type={isDatetime ? 'datetime-local' : type}
      required={required}
      helperText={description}
    />
  );
};

type DropdownProps = CommonProps & {
  options: Array<Option>;
};

const RenderSelect = ({
  onChange,
  value,
  options,
  label,
  required,
  description,
}: DropdownProps) => {
  const autocompleteValue = options.find(option => option.value === value) || null;
  return (
    <Autocomplete
      multiple={false}
      options={options}
      getOptionLabel={(option: Option) => option?.label}
      value={autocompleteValue}
      onChange={(_, value) => onChange((value as Option)?.value)}
      renderInput={params => (
        <TextField {...params} label={label} required={required} helperText={description} />
      )}
    />
  );
};

type MultiSelectProps = CommonProps & {
  options: Array<Option>;
  value: string | Array<string>;
};

const RenderMultiSelect = ({
  onChange,
  value,
  options,
  label,
  required,
  description,
}: MultiSelectProps) => {
  const autocompleteValue = Array.isArray(value)
    ? value.map(val => options.find(opt => opt.value === val)).filter(Boolean)
    : value
        ?.split(';')
        .map(value => {
          const option = options.find(option => option.value === value);
          if (!option) return null;
          return option;
        })
        .filter(Boolean) || [];

  const handleChange = (value: Option[]) => {
    const newValue = value?.map(option => option.value).join(';') || '';
    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option: Option) => option?.label}
      value={autocompleteValue}
      onChange={(_, value) => handleChange(value as Option[])}
      renderInput={params => (
        <TextField {...params} label={label} required={required} helperText={description} />
      )}
    />
  );
};

type CheckboxProps = Omit<CommonProps, 'onChange' | 'value'> & {
  onChange: (value: boolean) => void;
  value: boolean;
};

const RenderCheckbox = ({ onChange, value, description, label, required }: CheckboxProps) => {
  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={() => onChange(!value)} required={required} />}
        label={label}
      />
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};

interface Props {
  configurations: Array<CrmServiceConfguration>;
  onChange: (values: Record<string, string | boolean>) => void;
  values: Record<string, string | boolean>;
  onRefresh: () => Promise<void>;
}

export const RenderCrmConfigurations = ({ configurations, values, onChange, onRefresh }: Props) => {
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await onRefresh();
    setRefreshing(false);
  }, [onRefresh]);

  const accId = useId();

  const handleChange = (fieldName: string, value: string | boolean) => {
    onChange({ ...values, [fieldName]: value });
  };

  const requiredFields = configurations?.filter(configuration => configuration.required);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${accId}-content`}
        id={`${accId}-header`}
      >
        <Box display='flex' alignItems='center' gap={1}>
          <Typography variant='body2'>
            {t('mdLeads.crmExport.confirmDialog.configurationsAccordionTitle')}
          </Typography>
          {Boolean(requiredFields.length) && <WarningAmber />}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' flexDirection='column' gap={2}>
          {requiredFields.length === 0 && (
            <Typography variant='body2'>
              {t('mdLeads.crmExport.confirmDialog.noConfigurations')}
            </Typography>
          )}

          <Alert severity='warning' sx={{ whiteSpace: 'pre-wrap' }}>
            {t('mdLeads.crmExport.confirmDialog.configurationsInfo')}
          </Alert>

          <Box display='flex'>
            <LtLoadingButton
              variant='text'
              loading={refreshing}
              onClick={handleRefresh}
              endIcon={<Refresh />}
              loadingPosition='end'
              aria-label={t('ariaRefreshCrmConfigurations')}
            >
              {t('mdLeads.crmExport.confirmDialog.refreshConfigurations')}
            </LtLoadingButton>
          </Box>

          {requiredFields.map(configuration => {
            const commonFields = {
              onChange: value => handleChange(configuration.id, value),
              key: configuration.id,
              label: configuration.label,
              required: configuration.required,
              description: configuration.description,
            };

            const stringFields = {
              ...commonFields,
              value: (values[configuration.id] || '') as string,
            };
            switch (configuration.type) {
              case 'select':
                return <RenderSelect {...stringFields} options={configuration.options} />;
              case 'multi-select':
                return <RenderMultiSelect {...stringFields} options={configuration.options} />;
              case 'checkbox':
                return (
                  <RenderCheckbox {...commonFields} value={Boolean(values[configuration.id])} />
                );
              case 'text':
              case 'number':
                return <RenderTextField {...stringFields} type={configuration.type} />;
              case 'date':
              case 'datetime':
                return <RenderDateField {...stringFields} type={configuration.type} />;
              default:
                return null;
            }
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
