export const UPLOADED_IMG_LIMIT_MO = 500;
export const UPLOADED_IMG_LIMIT_MO_HEIGHT = 1500;
export const UPLOADED_IMG_LIMIT_VC = 800;
export const UPLOADED_IMG_LIMIT_VC_HEIGHT = 2000;
export const DEFAULT_IMG_WIDTH_MO = 1333;
export const DEFAULT_IMG_WIDTH_VC = 1920;

export const POS_CONST_MO = {
  X: 1 / 2,
  Y: {
    TOP: 1 / 6,
    MIDDLE: 1 / 2,
    BOTTOM: 1 / 1.2,
  },
} as const;

export const POS_CONST_VC = {
  Y: 1 / 8,
  X: {
    LEFT: 1 / 12,
    MIDDLE: 1 / 2,
    RIGHT: 1 / 1.1,
  },
} as const;

export const FIXED_POSITIONS = {
  mobile: ['top', 'middle', 'bottom'],
  videocall: ['left', 'middle', 'right'],
};

export const POSITIONS_DEFAULT = {
  mobile: 'bottom',
  videocall: 'left',
};

export const ALLOWED_FORMATS = ['png', 'jpg'];

export enum SCREEN {
  WALLET = 'wallet',
  MOBILE = 'mobile',
  VIDEOCALL = 'videocall',
}

export const UPLOAD_LIMIT = {
  mobile: UPLOADED_IMG_LIMIT_MO,
  videocall: UPLOADED_IMG_LIMIT_VC,
};
