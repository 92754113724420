import { CardRenderer } from '@/components/CardRenderer';
import { Box, CardActionArea, Typography } from '@mui/material';
import React from 'react';
import qrBgImage from '../../images/qr-wallpaper-bg.png';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';

const QRBackgroundsCard = () => {
  const { t } = useAppTranslation();
  return (
    <CardActionArea component={Link} to={routePaths.QR_CODE_BACKGROUND.ROOT}>
      <CardRenderer sx={{ flexBasis: '100%' }}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box width={'60%'} display={'flex'} justifyContent={'center'}>
              <img
                src={qrBgImage}
                alt='qr-background'
                role='presentation'
                style={{ width: '80%', maxWidth: '18rem', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box>
              <Typography variant='h3' mb={1}>
                {t('shareProfile.qrBackgrounds.title')}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {t('shareProfile.qrBackgrounds.description')}
              </Typography>
            </Box>
          </Box>
          <ArrowForwardOutlined role='presentation' sx={{ color: 'text.primary' }} />
        </Box>
      </CardRenderer>
    </CardActionArea>
  );
};

export default QRBackgroundsCard;
