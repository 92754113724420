import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { addCodeAction } from '@/application/actions/landing';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { LtDialog } from '@/components';
import { Typography } from '@mui/material';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';

interface Props {
  code: string;
  onCloseClick: () => void;
}

const AddCodePopup = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { addCodeLoading, addCodeResponse } = useAppSelector(state => state.landing);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useDeferredLoader(addCodeLoading, 'add-code-loading');

  const onAddCodeClick = () => {
    dispatch(addCodeAction(props.code, getAccessTokenSilently));
  };

  const showConnectPopup = !addCodeLoading && !addCodeResponse.isSuccess;

  return (
    <>
      <LtDialog
        open={showConnectPopup}
        title={t('connectCard')}
        onClose={props.onCloseClick}
        onCancel={props.onCloseClick}
        onConfirm={onAddCodeClick}
        size='sm'
        withActionDivider
      >
        <Typography variant='body2'>{t('newCardDetected')}</Typography>
      </LtDialog>
    </>
  );
};

export default AddCodePopup;
