import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import NewerIphoneGif from '../../../images/onboarding/newerIphones.gif';
import OlderIphoneGif from '../../../images/onboarding/olderIphones.gif';
import AndroidsGif from '../../../images/onboarding/androids.gif';
import ScanQrGif from '../../../images/onboarding/scan.gif';
import { ScreenGif } from './ScreenGif';
import { ScreenLastAdvice } from './ScreenLastAdvice';
import { ContinueButton } from '../components/ContinueButton';
import { Box } from '@mui/material';
import { OnboardingScreenWrapper } from '../components';

interface Props {
  next: () => void;
  buttonTitle: string;
}

export const Screen3Card = (props: Props) => {
  const { t } = useAppTranslation();

  return (
    <OnboardingScreenWrapper title={t('onboarding.howShareProfile')} titleCenter>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '3rem',
        }}
      >
        <ScreenGif
          heading={null}
          subheading={null}
          gif={{ url: ScanQrGif, text: null, underText: t('onboarding.viaQR') }}
          secondGif={{
            text: null,
            url: NewerIphoneGif,
            underText: t('onboarding.viaNFC'),
          }}
        />
        <ScreenGif
          heading={t('TapNewIphoneHeading')}
          subheading={t('TapNewIphoneDetailSubHeading')}
          gif={{ url: NewerIphoneGif, text: t('onboarding.slideVeryTop'), underText: null }}
        />
        <ScreenGif
          heading={t('TapOlderIphoneHeading')}
          subheading={t('TapNewIphoneDetailSubHeading')}
          gif={{ url: OlderIphoneGif, text: t('onboarding.mustPressNFCbutton'), underText: null }}
        />
        <ScreenGif
          heading={t('TapAndroidHeading')}
          subheading={null}
          gif={{
            url: AndroidsGif,
            text: [t('onboarding.androidGifText1'), t('onboarding.androidGifText2')],
            underText: null,
          }}
        />
        <ScreenLastAdvice heading={t('onboarding.lastAdvice')} />
      </Box>
      <ContinueButton onClick={props.next}>{t('onboarding.continue')}</ContinueButton>
    </OnboardingScreenWrapper>
  );
};
