import { useEffect, useMemo, useState } from 'react';

import withNav from '@/infrastructure/hoc/withNav';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from '@/infrastructure/hooks/useQuery';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { PageContainer } from '@/components';
import { BusinessCardCard } from './components/BusinessCardCard';
import { ShareProfileCard } from './components/ShareProfileCard';
import { Box, Typography, styled } from '@mui/material';
import { SimpleCard } from './components/SimpleCard';
import { AddCircleOutline, AutoGraph, CreditCardOutlined } from '@mui/icons-material';
import { AnalyticsCard } from './components/AnalyticsCard';
import { getAnalyticsDataTeaser } from '@/infrastructure/apis/analytics';
import { getAccountLeads } from '@/infrastructure/apis/leadGen';
import { LeadsCard } from './components/LeadsCard';
import UpgradedOnMobilePopup from './components/UpgradedOnMobile';
import AddAppToHomePopup from './components/AddAppToHomePopup';
import { useAppSelector } from '@/application/hooks';
import { Account, AnalyticsTeaser, LeadAPI } from '@/shared/types/api';
import { routePaths } from '@/infrastructure/constants';
import { MobileWidget } from './components/MobileWidget';
import { ShareProfileMobileCard } from './components/ShareProfileMobileCard';
import { useTranslation } from 'react-i18next';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import AcademyCard from './components/AcademyCard';
import { HelpDropdown } from '@/components/Header/components/HelpDropdown';
import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';

const ADD_TO_HOMESCREEN_HASH = '#add-to-homescreen';

const Home = () => {
  const account = useAppSelector<Account>(state => state.account);
  const hideNfcCardUI = account?.theme?.themeInternal?.hideNfcCardUI;

  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();

  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const justUpgraded = query.get('lt-upgraded-now') === 'true';
  const isDesktop = useIsDesktopMui();

  // reload once after signup2theme
  useEffect(() => {
    if (sessionStorage.getItem('lt-signup2theme-reload') === 'true') {
      sessionStorage.setItem('lt-signup2theme-reload', 'reloaded');
      window.location.reload();
    }
  }, []);

  const [analyticsTeaser, setAnalyticsTeaser] = useState<AnalyticsTeaser | null>(null);
  const [analyticsLoading, setAnalyticsLoading] = useState(true);
  const [leads, setLeads] = useState<LeadAPI[]>([]);
  const [leadsLoading, setLeadsLoading] = useState(true);

  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const addToHomeScreenVisible = location.hash === ADD_TO_HOMESCREEN_HASH;

  const { profileDesign } = useProfileDesign();

  const headerActions = useMemo(
    () => createHeaderAction(<HelpDropdown />, { mobileOnly: true }),
    [],
  );
  useHeaderActions(headerActions);

  useEffect(() => {
    if (!isDesktop) return;
    const fetchAnalytics = async () => {
      setAnalyticsLoading(true);
      try {
        const res = await getAnalyticsDataTeaser(getAccessTokenSilently);
        setAnalyticsTeaser(res);
      } catch {}
      setAnalyticsLoading(false);
    };
    fetchAnalytics();
  }, [getAccessTokenSilently, isDesktop]);

  useEffect(() => {
    if (!account.id || !isDesktop) return;
    const fetchData = async () => {
      setLeadsLoading(true);
      try {
        let { leads = [] } = await getAccountLeads(account.id, getAccessTokenSilently, {
          pageSize: 3,
          page: 0,
        });
        setLeads(leads);
      } catch {}
      setLeadsLoading(false);
    };
    fetchData();
  }, [getAccessTokenSilently, account.id, isDesktop]);

  return (
    <>
      <PageContainer maxWidth='m'>
        <StyledGridContainer lgMdColumnsCount={1}>
          <BusinessCardCard account={account} profileDesign={profileDesign} />
          {isDesktop && (
            <>
              <ShareProfileCard account={account} />
              <StyledGridContainer oneColumn={hideNfcCardUI}>
                <SimpleCard
                  icon={<AddCircleOutline />}
                  text={t('homePage.addAppToPhone.title')}
                  to={{ hash: ADD_TO_HOMESCREEN_HASH, pathname: routePaths.HOME }}
                />
                {!hideNfcCardUI && (
                  <SimpleCard
                    icon={<CreditCardOutlined />}
                    to={routePaths.NFC_CARDS}
                    text={t('homePage.nfcCard.addAndManage')}
                  />
                )}
              </StyledGridContainer>
              <AnalyticsCard data={analyticsTeaser} isLoading={analyticsLoading} />
            </>
          )}
          {!isDesktop && (
            <>
              <ShareProfileMobileCard />
              <Box>
                <Typography mb={1} variant='h3'>
                  {t('homePage.quickActions.title')}
                </Typography>
                <StyledMobileWidgetsContainer columnCount={hideNfcCardUI ? 2 : 3}>
                  <MobileWidget
                    icon={<AddCircleOutline />}
                    onClick={() =>
                      history.push({ hash: ADD_TO_HOMESCREEN_HASH, pathname: routePaths.HOME })
                    }
                    text={t('homePage.quickActions.addApp')}
                  />
                  {!hideNfcCardUI && (
                    <MobileWidget
                      icon={<CreditCardOutlined />}
                      onClick={() => history.push(routePaths.NFC_CARDS)}
                      text={t('homePage.quickActions.nfcCards')}
                    />
                  )}

                  <MobileWidget
                    icon={<AutoGraph />}
                    onClick={() => history.push(routePaths.ANALYTICS)}
                    text={t('homePage.quickActions.analytics')}
                  />
                  {/* {flag_businessCardScanner && (
                    <MobileWidget
                      icon={<ScannerIcon />}
                      onClick={() =>
                        history.push(
                          isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.LEAD_GEN.SCANNER,
                        )
                      }
                      text={t('homePage.quickActions.scanCard')}
                    />
                  )} */}
                </StyledMobileWidgetsContainer>
              </Box>
            </>
          )}
          <AcademyCard onUpgradeClick={upgradeAction} />
          {isDesktop && <LeadsCard leads={leads} isLoading={leadsLoading} />}
        </StyledGridContainer>
      </PageContainer>
      <UpgradedOnMobilePopup
        open={justUpgraded}
        onClose={() => {
          const query = new URLSearchParams(location.search);
          query.delete('lt-upgraded-now');
          history.replace({
            search: query.toString(),
          });
        }}
      />
      <AddAppToHomePopup
        domain={account?.theme?.domain}
        open={addToHomeScreenVisible}
        onClose={() => history.push({})}
      />
      {upgradeRequestPopup}
    </>
  );
};

export default withNav(
  Home,
  {
    tTitle: 'home',
    showLogoOnMobile: {
      linkTo: routePaths.HOME,
    },
    hideTitleOnMobile: true,
  },
  {
    activeScreen: routePaths.HOME,
  },
);

const StyledGridContainer = styled(Box)(({ theme, lgMdColumnsCount, oneColumn }) => ({
  display: 'grid',
  gridTemplateColumns: `${oneColumn ? 'minmax(0, 1fr)' : 'minmax(0, 1fr) minmax(0, 1fr)'} `,
  gap: '2rem',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'minmax(0, 1fr)',
  },
  [theme.breakpoints.down(970)]: {
    gridTemplateColumns: lgMdColumnsCount ? `repeat(${lgMdColumnsCount}, 1fr)` : null,
  },
}));

const StyledMobileWidgetsContainer = styled(Box)(({ columnCount }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
  gap: '0.8rem',
}));
