import { Box, Button, CardMedia, Typography } from '@mui/material';
import { VIDEOS } from '../../../../../infrastructure/constants';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { TRIAL_LENGTH_DAYS } from '@/shared/constants';
import { Check } from '@mui/icons-material';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';

const VideoSection = ({
  onShowUpgradePopup,
  isLeadgen = false,
}: {
  onShowUpgradePopup: () => void;
  isLeadgen: boolean;
}) => {
  const { t, activeLanguage } = useAppTranslation();
  const { desktopView } = useDesktopView();

  const { src, title } = activeLanguage.startsWith('de') ? VIDEOS.MD.DE : VIDEOS.MD.EN;
  return (
    <Box py={3}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        sx={!desktopView && { flexDirection: 'column' }}
      >
        <Box>
          <CardMedia
            component={'iframe'}
            src={src}
            title={title}
            allowFullScreen
            sx={{ minWidth: '40rem', height: '23rem', maxWidth: '100%', borderRadius: 1 }}
          ></CardMedia>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          px={4}
          sx={!desktopView && { mt: 2 }}
        >
          <Typography variant='h1'>{t('teaser.teamsEssential')}</Typography>
          <Typography variant='body2' color={'text.secondary'}>
            {t(isLeadgen ? 'teaser.leadgenEfficientSolution' : 'teaser.efficientSolution', {
              days: TRIAL_LENGTH_DAYS,
            })}
          </Typography>

          <Box>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Check sx={{ color: 'success.main' }} />
              <Typography variant='body2' sx={{ color: 'success.main' }}>
                {t('teaser.noCredit')}
              </Typography>
            </Box>

            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Check sx={{ color: 'success.main' }} />
              <Typography variant='body2' sx={{ color: 'success.main' }}>
                {t('teaser.noInstallation')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button size='large' onClick={onShowUpgradePopup}>
              {t('teaser.testItXDaysForFree', { days: TRIAL_LENGTH_DAYS })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSection;
