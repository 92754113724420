import { AddEditLinkDialog, LinkBox, LtDialog } from '@/components';
import { Link, LinkTemplate, Theme } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '@/application/hooks';
import { useTranslation } from 'react-i18next';
import {
  LinkTemplatePayload,
  createLinkTemplate,
  deleteLinkTemplate,
  getLinkTemplates,
  updateLinkTemplate,
} from '@/infrastructure/apis/linkTemplates';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { SectionRenderer } from '../../SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';

const mapLinkTemplateToLink = (linkTemplate: LinkTemplate): Link => ({
  id: linkTemplate.id,
  name: linkTemplate.name,
  link: linkTemplate.link,
  /** themeLinkTypeId */
  linkTypeId: linkTemplate.themeLinkType.id,
  linkType: linkTemplate.themeLinkType.linkType,
});

const mapLinkToLinkTemplatePayload = (link: Link): LinkTemplatePayload => ({
  id: link.id,
  name: link.name,
  link: link.link,
  linkTypeId: link.linkTypeId,
});

type Props = {
  selectedUnitId?: number | null;
};

/**
 * For simplicity we map LinkTemplates to Link objects for easy manipulation
 * after fetch - map LinkTemplates to Link objects
 * before save - map Link objects to LinkTemplatePayload objects
 */

export const LinksSection = ({ selectedUnitId }: Props) => {
  const { notify: toast } = useLtNotifications();
  const { profileDesign } = useProfileDesignForUnits(selectedUnitId);

  const [addLinkTemplateDialogOpened, setAddLinkTemplateDialogOpened] = useState(false);
  const [linkTemplateIdToDelete, setLinkTemplateIdToDelete] = useState<number | null>(null);
  const [linkTemplateToEdit, setLinkTemplateToEdit] = useState<Link | null>(null);

  const [linkTemplates, setLinkTemplates] = useState<Link[]>([]);
  const theme: Theme = useAppSelector(state => state.account.theme);

  /** Map themeLinkTypes to linkType but keep themeLinkTypeId as an id, this is how BE works*/
  const linkTypes = theme.themeLinkTypes?.map(({ linkType, id }) => ({ ...linkType, id })) || [];

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  useDeferredLoader(loading, 'content-templates-loading');

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const fetchLinkTemplates = useCallback(async () => {
    try {
      const linkTemplates = await getLinkTemplates(getAccessTokenSilently);
      setLinkTemplates(linkTemplates.map(mapLinkTemplateToLink));
    } catch {
      toast.error(t('md.contentTemplate.errorLoadingLinks', { id: 'default-content-loading' }));
    }
  }, [getAccessTokenSilently, t, toast]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchLinkTemplates();
      setLoading(false);
    };
    fetchData();
  }, [fetchLinkTemplates]);

  const handleLinkAdd = async (link: Link) => {
    setSaving(true);
    try {
      await createLinkTemplate(getAccessTokenSilently, mapLinkToLinkTemplatePayload(link));
      await fetchLinkTemplates();
      setAddLinkTemplateDialogOpened(false);
      toast.success(t('md.contentTemplate.successSaveLink'));
    } catch (error) {
      toast.error(t('md.contentTemplate.errorSavingLink'));
    }
    setSaving(false);
  };

  const handleLinkEdit = async (link: Link) => {
    setSaving(true);
    try {
      await updateLinkTemplate(getAccessTokenSilently, mapLinkToLinkTemplatePayload(link));
      await fetchLinkTemplates();
      setLinkTemplateToEdit(null);
      toast.success(t('md.contentTemplate.successSaveLink'));
    } catch (error) {
      console.log(error);
      toast.error(t('md.contentTemplate.errorSavingLink'));
    }
    setSaving(false);
  };

  const handleLinkDelete = async () => {
    setDeleting(true);
    try {
      await deleteLinkTemplate(getAccessTokenSilently, linkTemplateIdToDelete);
      await fetchLinkTemplates();
      setLinkTemplateIdToDelete(null);
      toast.success(t('md.contentTemplate.successDeleteLink'));
    } catch {
      toast.error(t('md.contentTemplate.errorDeleteLink'));
    }
    setDeleting(false);
  };

  return (
    <SectionRenderer
      title={t('md.contentTemplate.linkTemplates')}
      btnAriaLabel={t('addLinkTemplate')}
      onAdd={() => setAddLinkTemplateDialogOpened(true)}
    >
      <Box display='flex' flexDirection='column' gap={2}>
        {linkTemplates.map((link, index) => (
          <LinkBox
            key={index}
            link={link}
            onDelete={() => setLinkTemplateIdToDelete(link.id)}
            onEdit={() => setLinkTemplateToEdit(link)}
            chips={[t('template')].filter(Boolean)}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
        ))}
      </Box>
      {/* Add Dialog */}
      <AddEditLinkDialog
        mode='admin'
        linkTypes={linkTypes}
        open={addLinkTemplateDialogOpened}
        onClose={() => setAddLinkTemplateDialogOpened(false)}
        onSave={handleLinkAdd}
        loading={saving}
        hideTemplateSelector
        genericWebsiteColor={profileDesign.genericWebsiteColor}
      />
      {/* Edit Dialog */}
      <AddEditLinkDialog
        mode='admin'
        linkTypes={linkTypes}
        open={Boolean(linkTemplateToEdit)}
        onClose={() => setLinkTemplateToEdit(null)}
        onSave={handleLinkEdit}
        initialValues={linkTemplateToEdit}
        loading={saving}
        hideTemplateSelector
        genericWebsiteColor={profileDesign.genericWebsiteColor}
      />
      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(linkTemplateIdToDelete)}
        onClose={() => setLinkTemplateIdToDelete(null)}
        onCancel={() => setLinkTemplateIdToDelete(null)}
        onDelete={handleLinkDelete}
        loading={deleting}
      >
        <Typography>{t('deleteMessageLink')}</Typography>
      </LtDialog>
    </SectionRenderer>
  );
};
