import { dispatchTypes } from '../../index';
import {
  _bulkSendEmail,
  _bulkUpdateInfo,
  _bulkUpdateLinks,
  _bulkExtendFiles,
} from '../../../../infrastructure/apis/md/profiles/bulk';
import { saveFile } from '../../../../infrastructure/apis/md/profiles/files';
import { updateImageUrl } from '../../../../infrastructure/apis/md/profiles';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import {
  ACCOUNT_STATUSES,
  INVITE_EMAILS_MAX_NUM,
  PRIVATE_EVENTS,
  PRIVATE_META_TYPES,
} from '../../../../shared/constants';
import { TFunction } from 'i18next';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { InputFormSaveProps } from '../../../../views/pages/md/profiles/common/inputs-form';
import { Link, BulkFile, AccountImageState } from '../../../../shared/types/api';
import i18n from '../../../../config/i18n';
import { trackPrivateEvent } from '../../../../infrastructure/apis/analytics';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const bulkSendEmail =
  (
    idsToUpdate: readonly string[],
    t: TFunction,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    if (idsToUpdate.length > 100) {
      useNotificationsStore.getState().notify.loading(t('LoadingLong'), { id: 'toastid' });
    } else {
      useNotificationsStore.getState().notify.loading(t('Loading'), { id: 'toastid' });
    }
    try {
      let response = await _bulkSendEmail(idsToUpdate, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        if (idsToUpdate.length <= INVITE_EMAILS_MAX_NUM) {
          for (const id of idsToUpdate) {
            dispatch({
              type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
              id,
              field: 'status',
              value: ACCOUNT_STATUSES.INVITED,
            });
          }
          dispatch({ type: dispatchTypes.BUSINESS.BULK.INVITE_SUCCESS });
          useNotificationsStore.getState().notify.success(t('bulkInvitedSync'), { id: 'toastid' });
        } else {
          useNotificationsStore.getState().notify.success(t('bulkInvitedAsync'), { id: 'toastid' });
        }
      } else {
        dispatch({ type: dispatchTypes.BUSINESS.BULK.INVITE_ERROR });
        useNotificationsStore.getState().notify.error(t('error.general'), { id: 'toastid' });
      }
    } catch (error) {
      console.error(error);
      useNotificationsStore.getState().notify.error(t('error.general'), { id: 'toastid' });
    }
  };

export const bulkUploadImage =
  (
    ids: readonly string[],
    usernames: readonly string[],
    file: File,
    type: 'banner' | 'logoHeader',
    imageState: AccountImageState,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      const username = usernames[index];
      try {
        const url = await doS3UploadForBusiness(
          `${type}/${username}`,
          file,
          id,
          getAccessTokenSilently,
          type,
        );

        if (url) {
          await updateImageUrl(id, url, type, imageState, getAccessTokenSilently)
            .then(res => {
              dispatch({
                type: dispatchTypes.EDITEMPLOYEE.UPDATEIMAGE,
                payload: { id, type, url, imageState },
              });
            })
            .catch(error =>
              dispatch({
                type: dispatchTypes.EDITEMPLOYEE.UPLOADFILEERROR,
                apiResponse: error,
              }),
            );
        }
      } catch (error) {
        dispatch({
          type: dispatchTypes.EDITEMPLOYEE.UPLOADFILEERROR,
          apiResponse: error,
        });
        useNotificationsStore.getState().notify.error(i18n.t('error.uploadingImage'));
      }
    }

    // track bulk profiles banner update
    type === 'banner' &&
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.BANNER,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
  };

export const bulkUpdateInfo =
  (
    ids: readonly string[],
    toUpdateInfo: InputFormSaveProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    let response = await _bulkUpdateInfo(ids, toUpdateInfo, getAccessTokenSilently);
    const { isSuccess } = response.data;
    if (isSuccess) {
      for (const id of ids) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELDS,
          id,
          fields: Object.keys(toUpdateInfo),
          values: Object.values(toUpdateInfo),
        });
      }

      // track bulk profiles bio update
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.BIO,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
    }
    return isSuccess;
  };

export const bulkUpdateLinks =
  (
    ids: readonly string[],
    toUpdateLinks: Array<Link>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    let response = await _bulkUpdateLinks(ids, toUpdateLinks, getAccessTokenSilently);
    const { isSuccess, data } = response.data;
    if (isSuccess) {
      for (const { id, links } of data) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
          id,
          field: 'links',
          value: links,
        });
      }
      // track bulk profiles link update/add
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.LINK_ADD,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
    }
    return isSuccess;
  };

export const bulkUpdateFiles =
  (
    ids: readonly string[],
    usernames: readonly string[],
    toUpdateFiles: Array<BulkFile>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    for (const file of toUpdateFiles) {
      if (file.uploadedFile instanceof File) {
        try {
          for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            const username = usernames[index];
            const { uploadedFile, fileName, pageCount, link } = file;
            const url = link
              ? link
              : await doS3UploadForBusiness(
                  `file/${username}`,
                  uploadedFile,
                  id,
                  getAccessTokenSilently,
                );

            if (url) {
              const uploadResponse = await saveFile(
                id,
                fileName,
                url,
                pageCount,
                getAccessTokenSilently,
              );

              if (uploadResponse.data.isSuccess) {
                const { data: files } = uploadResponse.data;
                dispatch({
                  type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
                  id,
                  field: 'files',
                  value: files,
                });
              }
            }
          }

          // track bulk profiles file add
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
            {
              type: PRIVATE_META_TYPES.FILE_ADD,
              editedAccountIds: [...ids],
            },
            null,
            null,
            true,
          );
        } catch (err) {
          useNotificationsStore.getState().notify.error(i18n.t('error.uploadFile'));
        }
      } else if (file.extendToAll) {
        const response = await _bulkExtendFiles(ids, file, getAccessTokenSilently);
        const { isSuccess, data } = response.data;
        if (isSuccess) {
          for (const { id, files } of data) {
            dispatch({
              type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
              id,
              field: 'files',
              value: files,
            });
          }

          // track bulk profiles file add
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
            {
              type: PRIVATE_META_TYPES.FILE_ADD,
              editedAccountIds: [...ids],
            },
            null,
            null,
            true,
          );
        }
      }
    }
  };
