import { Autocomplete, TextField } from '@mui/material';
import { Field, getFieldTranslationKey, SELECTABLE_FIELDS } from '../utils';
import { useTranslation } from 'react-i18next';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';

type Props = {
  onSelect: (field: Field) => void;
  selectedField: Field;
};

export const FieldSelector = ({ onSelect, selectedField }: Props) => {
  const { t } = useTranslation();

  const { isFeatureAllowed } = useTierInfo();

  const isUnitsAllowed = isFeatureAllowed(FEATURE.UNITS);

  const selectableFields = isUnitsAllowed
    ? SELECTABLE_FIELDS
    : SELECTABLE_FIELDS.filter(field => field !== 'unit');

  return (
    <Autocomplete
      multiple={false}
      options={selectableFields}
      disableClearable
      onChange={(_event, newValue: Field) => {
        onSelect(newValue);
      }}
      value={selectedField}
      renderInput={params => <TextField {...params} label={t('selectField')} />}
      getOptionLabel={option => t(getFieldTranslationKey(option as Field))}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option}>
            {t(getFieldTranslationKey(option))}
          </li>
        );
      }}
    />
  );
};
