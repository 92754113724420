import { HelpSidebarKey } from '@/shared/constants';
import { Box, BoxProps, Card } from '@mui/material';
import { HelpButton } from '../HelpSidebar';
import LtActionButtonBar, { LtActionButtonBarProps } from '../LtActionButtonBar';
import { useMemo } from 'react';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { RequireOnlyOne } from '@/shared/types/util';
import { LtTabsProps } from '../LtTabs';
import LtTabs from '../LtTabs';
import {
  LeftSideSheet,
  LeftSideSheetProps,
  useLeftSidesheetWidth,
} from './components/LeftSideSheet';

type Props = RequireOnlyOne<
  {
    children: React.ReactNode;
    tabs?: LtTabsProps;
    maxWidth?: 's' | 'm' | 'l' | 'xl';
    helpSidebarConfigKey?: HelpSidebarKey | null;
    helpButtonBoxProps?: BoxProps;
    bottomActionBar?: LtActionButtonBarProps;
    topActionBar?: LtActionButtonBarProps;
    whiteCardWrapper?: boolean;
    leftSideSheet?: LeftSideSheetProps;
  },
  'children' | 'tabs'
> &
  BoxProps;

const maxWidthMap = {
  s: '60rem',
  m: '120rem',
  l: '180rem',
  xl: '240rem',
};

const PageContainer: React.FC<Props> = ({
  children,
  maxWidth,
  helpSidebarConfigKey,
  helpButtonBoxProps,
  bottomActionBar,
  topActionBar,
  tabs,
  whiteCardWrapper,
  sx,
  leftSideSheet,
  ...rest
}) => {
  const leftSidesheetWidth = useLeftSidesheetWidth();
  const { desktopView } = useDesktopView();
  const _sx = {
    ...(maxWidth ? { maxWidth: maxWidthMap[maxWidth], margin: '0 auto' } || {} : {}),
    ...sx,
  };

  const sidesheetVisible = Boolean(desktopView && leftSideSheet);
  const SidesheetWrapper = useMemo(
    () =>
      sidesheetVisible
        ? ({ children }: React.PropsWithChildren<{}>) => (
            <>
              <LeftSideSheet {...leftSideSheet} />
              <Box ml={leftSidesheetWidth}>{children}</Box>
            </>
          )
        : ({ children }: React.PropsWithChildren<{}>) => <>{children}</>,
    [leftSideSheet, leftSidesheetWidth, sidesheetVisible],
  );

  const ContentWrapper = useMemo(
    () =>
      whiteCardWrapper && desktopView
        ? ({ children }: React.PropsWithChildren<{}>) => (
            <Card
              sx={{
                p: 2,
                borderRadius: '1rem',
              }}
            >
              {children}
            </Card>
          )
        : ({ children }: React.PropsWithChildren<{}>) => <Box>{children}</Box>,
    [desktopView, whiteCardWrapper],
  );

  return (
    <>
      {topActionBar && (
        <LtActionButtonBar
          location='top'
          renderRelativeBox
          disableBigButtonsOnMobile
          explicitOffsets={{ left: sidesheetVisible ? leftSidesheetWidth : undefined }}
          {...topActionBar}
        />
      )}
      <SidesheetWrapper>
        <Box sx={_sx} px={{ xs: 2, md: 4 }} pt={{ xs: 2, md: 4.5 }} pb={4.5} {...rest}>
          {helpSidebarConfigKey && (
            <Box display='flex' justifyContent='flex-end' mt={-1.5} {...helpButtonBoxProps}>
              <HelpButton configKey={helpSidebarConfigKey} />
            </Box>
          )}
          <ContentWrapper>{tabs ? <LtTabs {...tabs} /> : children}</ContentWrapper>
        </Box>
      </SidesheetWrapper>
      {bottomActionBar && (
        <LtActionButtonBar
          location='bottom'
          noCancelOnDesktop
          renderRelativeBox
          explicitOffsets={{ left: sidesheetVisible ? leftSidesheetWidth : undefined }}
          {...bottomActionBar}
        />
      )}
    </>
  );
};

export default PageContainer;

export type PageContainerProps = Props;
