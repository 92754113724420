import { useRef, useState } from 'react';
import { Menu, MenuItem, ListItemIcon, Typography, Avatar, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownAction, DropdownType } from '@/infrastructure/hoc/types';

const isActionClickable = (action: DropdownAction) => action.link || action.onClick;

export type LtDropdownProps = {
  ariaLabel: string;
  open?: boolean;
  onClose?: () => void;
} & DropdownType;

export function Dropdown({ ariaLabel, open: externalOpen, onClose, ...props }: LtDropdownProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const anchorRef = useRef<HTMLButtonElement>();

  const [intrinsicOpen, setIntrinsicOpen] = useState(false);

  const handleClose = () => {
    setIntrinsicOpen(false);
    onClose?.();
  };

  const handleClick = (action: DropdownAction) => {
    if (!isActionClickable(action)) return;
    action.link ? history.push(action.link) : action.onClick?.();
    handleClose();
  };

  const open = intrinsicOpen || externalOpen;

  return (
    <>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setIntrinsicOpen(true)}
        aria-label={ariaLabel}
        ref={anchorRef}
        sx={{ p: 0 }}
      >
        <Avatar
          sx={{ bgcolor: 'transparent', width: '4rem', height: '4rem', color: 'primary.main' }}
        >
          {props.icon}
        </Avatar>
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.actions
          .filter(action => !action.hide)
          .map((action, idx, allActions) => (
            <MenuItem
              sx={!isActionClickable(action) && { pointerEvents: 'none' }}
              onClick={() => handleClick(action)}
              divider={action.withSeparator && idx !== allActions.length - 1}
            >
              {action.icon && <ListItemIcon>{<action.icon />}</ListItemIcon>}
              {action.label && <Typography variant='body2'>{t(action.label)}</Typography>}
              {action.customActionUI && <>{action.customActionUI}</>}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
