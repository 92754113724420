import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnitOverrideSettings } from '@/infrastructure/hooks/useUnitOverrideSettings';
import { Box, Typography } from '@mui/material';
import { FeatureSet, HierarchyLevel, UnitOverrideRightsKey } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { SetupSettings } from '../SetupSettings';
import { SettingsContext } from './context';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import FeatureTeaser from '../../../teaser/featureTeaser';
import LtDialog from '@/components/LtDialog';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { Unit } from '@/shared/types/api/unit.type';

type Props = {
  selectedUnit?: Unit;
  settingKey: UnitOverrideRightsKey;
  children: React.ReactNode;
  requiredFeature: keyof FeatureSet;
  teaserContent: {
    text: string;
  };
  forceTeaser?: boolean;
  overrideTexts?: {
    editNotAllowed?: string;
    setupSettings?: {
      title?: string;
      description?: string;
      buttonText?: string;
    };
    deleteDialog?: {
      unit?: {
        settingDeleteConfirmationTitle?: string;
        settingDeleteConfirmation?: string;
      };
      theme?: {
        settingResetConfirmationTitle?: string;
        settingResetConfirmation?: string;
      };
    };
  };
};

export const SettingsWrapper = ({
  settingKey,
  children,
  overrideTexts,
  requiredFeature,
  teaserContent,
  forceTeaser,
  selectedUnit,
}: Props) => {
  const { isThemeAdmin, loading: tierInfoLoading, isUnitAdmin, isFeatureAllowed } = useTierInfo();

  const { t } = useTranslation();

  const selectedUnitId = selectedUnit?.id || null;
  const [settingsHierarchyLevel, setSettingsHierarchyLevel] = useState<HierarchyLevel | null>(null);
  const [setupClicked, setSetupClicked] = useState(false);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { isUnitOverrideAllowed, fetched: overrideSettingsFetched } = useUnitOverrideSettings({
    unitId: selectedUnitId,
  });

  const showLoader =
    tierInfoLoading || (!isThemeAdmin && (!selectedUnitId || !overrideSettingsFetched));

  useDeferredLoader(settingsLoading, 'settings-loader-id');
  useDeferredLoader(showLoader, 'settings-loader-id');

  const showTeaser =
    forceTeaser || (isThemeAdmin && Boolean(selectedUnit) && !isFeatureAllowed(requiredFeature));

  const canEdit =
    isThemeAdmin ||
    (isFeatureAllowed(requiredFeature) && !isThemeAdmin && isUnitOverrideAllowed(settingKey));

  const showSetupUnitsSettings =
    canEdit &&
    settingsHierarchyLevel !== null &&
    settingsHierarchyLevel !== HierarchyLevel.Unit &&
    selectedUnitId &&
    !setupClicked;

  const showPageContent =
    canEdit && !showLoader && !showSetupUnitsSettings && settingsHierarchyLevel !== null;

  const flag_showGlobalReset = usePrivateFeatureFlag('themeConfigGlobalReset');

  const deleteButtonIsVisible =
    (isUnitAdmin && settingsHierarchyLevel === HierarchyLevel.Unit) ||
    (isThemeAdmin && settingsHierarchyLevel === HierarchyLevel.Unit) ||
    (isThemeAdmin &&
      settingsHierarchyLevel === HierarchyLevel.Theme &&
      !selectedUnit &&
      flag_showGlobalReset);

  useEffect(() => {
    setSetupClicked(false);
    setSettingsHierarchyLevel(null);
  }, [selectedUnitId, settingKey]);

  const deleteCallbackFnRef = useRef(null);

  const deleteClickHandler = confirmCallback => {
    setDeletePopupShow(true);
    deleteCallbackFnRef.current = confirmCallback;
  };

  const deleteDialogConfirmHandler = async () => {
    setDeleteLoading(true);
    deleteCallbackFnRef.current && (await deleteCallbackFnRef.current());
    deleteCallbackFnRef.current = null;
    setDeletePopupShow(false);
    setupClicked && setSetupClicked(false);
    setDeleteLoading(false);
  };

  const deleteDialogCloseHandler = async () => {
    deleteCallbackFnRef.current = null;
    setDeletePopupShow(false);
  };

  const content = useMemo(
    () => (
      <>
        {showSetupUnitsSettings && (
          <SetupSettings
            onClick={() => setSetupClicked(true)}
            overrideTexts={overrideTexts?.setupSettings}
            pt={2}
          />
        )}
        <Box sx={{ display: showPageContent ? 'block' : 'none' }}>
          <SettingsContext.Provider
            value={{
              selectedUnit,
              deleteButtonIsVisible,
              settingsHierarchyLevel,
              setSettingsHierarchyLevel,
              deleteClickHandler,
              setSettingsLoading,
              canEdit,
            }}
          >
            {children}
          </SettingsContext.Provider>
        </Box>
      </>
    ),
    [
      canEdit,
      children,
      deleteButtonIsVisible,
      overrideTexts?.setupSettings,
      selectedUnit,
      settingsHierarchyLevel,
      showPageContent,
      showSetupUnitsSettings,
    ],
  );

  return (
    <>
      <Box width='100%'>
        <Box>
          {showLoader ? null : showTeaser && isThemeAdmin ? (
            <>
              <FeatureTeaser text={teaserContent.text} fullWidth />
              {!selectedUnit && (
                <Box sx={{ opacity: 0.35, pointerEvents: 'none !important' }}>{content}</Box>
              )}
            </>
          ) : !canEdit ? (
            <Typography variant='body2' pt={4} pb={4}>
              {overrideTexts?.editNotAllowed ?? t('editNotAllowed')}
            </Typography>
          ) : (
            <>{content}</>
          )}
        </Box>
      </Box>

      <LtDialog
        title={
          settingsHierarchyLevel === HierarchyLevel.Unit
            ? overrideTexts?.deleteDialog?.unit?.settingDeleteConfirmationTitle ??
              t('settingDeleteConfirmationTitle')
            : overrideTexts?.deleteDialog?.theme?.settingResetConfirmationTitle ??
              t('settingResetConfirmationTitle')
        }
        open={deletePopupShow}
        loading={deleteLoading}
        onClose={deleteDialogCloseHandler}
        onCancel={deleteDialogCloseHandler}
        onDelete={() => deleteDialogConfirmHandler()}
      >
        {settingsHierarchyLevel === HierarchyLevel.Unit
          ? overrideTexts?.deleteDialog?.unit?.settingDeleteConfirmation ??
            t('settingDeleteConfirmation')
          : overrideTexts?.deleteDialog?.theme?.settingResetConfirmation ??
            t('settingResetConfirmation')}
      </LtDialog>
    </>
  );
};
