import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

import { isFileValid } from '@/infrastructure/helper';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Box, Button, CardMedia, Typography, styled, Divider, Chip } from '@mui/material';
import { FlipCameraIosOutlined, UploadFileOutlined } from '@mui/icons-material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

type Props = {
  scanSetters: {
    setScanResult: Dispatch<SetStateAction<QrScanner.ScanResult>>;
    setNewScan: Dispatch<SetStateAction<boolean>>;
  };
  videoWidth?: string;
  allowUpload?: boolean;
};

const ScannerCamera = (props: Props) => {
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();
  let videoRef = useRef(null);
  const scanner = useRef<QrScanner | null>(null);
  const imageInput = useRef(null);

  const { scanSetters, allowUpload } = props;
  const { setScanResult, setNewScan } = scanSetters;
  const [cameras, setCameras] = useState<Array<{ id: string; label: string }>>([]);
  const [cameraIdx, setCameraIdx] = useState(0);

  useEffect(() => {
    if (!videoRef.current || cameras.length === 0 || scanner.current) return;

    scanner.current = new QrScanner(
      videoRef.current,
      result => {
        setScanResult(result);
        setNewScan(true);
      },
      {
        returnDetailedScanResult: true,
        maxScansPerSecond: 2,
        preferredCamera: 'user',
        highlightCodeOutline: true,
      },
    );

    scanner.current.setCamera(cameras[cameraIdx].id);
    scanner.current.start();
  }, [cameraIdx, cameras, setNewScan, setScanResult]);

  useEffect(() => {
    return () => {
      if (scanner.current) scanner.current.destroy();
    };
  }, []);

  useEffect(() => {
    QrScanner.listCameras(true).then(setCameras);
  }, []);

  const switchCamera = () => {
    const nextIdx = (cameraIdx + 1) % cameras.length;
    setCameraIdx(nextIdx);
    scanner.current?.setCamera(cameras[nextIdx].id);
  };

  const handleScanImage = () => {
    const uploadedFile = imageInput.current?.files[0];
    const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
    if (errMsg) {
      toast.error(errMsg);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      const scanResult = reader.result as string;
      QrScanner.scanImage(scanResult)
        .then(result => {
          setScanResult({ data: result } as QrScanner.ScanResult);
          setNewScan(true);
        })
        .catch(error => console.error(error || 'No QR code found.'));
    };
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
      <ScannerVideo
        component='video'
        ref={videoRef}
        playsInline
        muted
        autoplay
        sx={props?.videoWidth && { width: props.videoWidth }}
      />
      {cameras.length > 1 && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={1}
        >
          <Typography variant='body2'>{cameras[cameraIdx].label}</Typography>
          <Button variant='text' startIcon={<FlipCameraIosOutlined />} onClick={switchCamera}>
            {t('codeRead.switchCamera')}
          </Button>
        </Box>
      )}
      {allowUpload && (
        <>
          <Box width='100%'>
            <Divider orientation='horizontal'>
              <Chip label={t('or')} />
            </Divider>
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Button
              onClick={() => imageInput.current.click()}
              startIcon={<UploadFileOutlined />}
              size='large'
            >
              {t('codeRead.uploadImage')}
            </Button>
            <input
              type='file'
              onChange={handleScanImage}
              style={{ display: 'none' }}
              ref={imageInput}
              accept='image/*'
              onClick={e => {
                setNewScan(false);
                (e.target as HTMLInputElement).value = null;
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ScannerCamera;

const ScannerVideo = styled(CardMedia)(({ theme }) => ({
  width: '90%',
  [theme.breakpoints.down('sm')]: {
    height: '50vh',
    objectFit: 'cover',
  },
}));
