import useDeviceInfo from '@/infrastructure/hooks/useDeviceInfo';
import LoadingButton from '@mui/lab/LoadingButton';
import { SaveOutlined } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type LtLoadingButtonProps = {
  minimumAnimationTime?: number;
  presets?: 'save';
} & React.ComponentProps<typeof LoadingButton>;

// sets default values
// and enforces a minimum time the loading animation is shown for
const LtLoadingButton = ({
  minimumAnimationTime,
  loading,
  presets,
  disabled,
  ...rest
}: LtLoadingButtonProps) => {
  const [deferredLoading, setDeferredLoading] = useState(false);
  const deferrerPromise = useRef<Promise<void>>(null);

  const { isMobile } = useDeviceInfo();

  const { t } = useTranslation();
  const presetsProps = useMemo<React.ComponentProps<typeof LoadingButton>>(
    () =>
      presets === 'save'
        ? {
            startIcon: <SaveOutlined />,
            children: <>{t('save')}</>,
          }
        : {},
    [t, presets],
  );

  useEffect(() => {
    if (loading) {
      setDeferredLoading(true);
      deferrerPromise.current = new Promise(res => setTimeout(res, minimumAnimationTime || 500));
    } else {
      if (deferrerPromise.current) {
        deferrerPromise.current.then(() => {
          setDeferredLoading(false);
          deferrerPromise.current = null;
        });
      }
    }
  }, [loading, minimumAnimationTime]);

  return (
    <LoadingButton
      loading={deferredLoading}
      loadingPosition={rest.startIcon ? 'start' : undefined}
      size={isMobile ? 'large' : undefined}
      fullWidth={isMobile ? true : undefined}
      disabled={disabled}
      aria-disabled={disabled}
      aria-busy={loading}
      {...presetsProps}
      {...rest}
    />
  );
};

export default LtLoadingButton;
