import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { SuccessNotificationProps } from '@/infrastructure/notifications/variants/success';
import { ErrorNotificationProps } from '@/infrastructure/notifications/variants/error';
import { InfoNotificationProps } from '@/infrastructure/notifications/variants/info';

interface NotificationsState {
  loading: string[];
  loadingTexts: Record<string, string | undefined>;
  notifyLoading: (toAddId: string, text?: string) => void;
  dismiss: (toDismissId?: string) => void;
  dismissAll: () => void;

  info: Omit<InfoNotificationProps, 'onClose'>;
  notifyInfo: (text: string, toDismissId?: string) => void;
  closeInfo: () => void;

  success: Omit<SuccessNotificationProps, 'onClose'>;
  notifySuccess: (text: string, toDismissId?: string) => void;
  closeSuccess: () => void;

  error: Omit<ErrorNotificationProps, 'onClose'>;
  notifyError: (text: string, toDismissId?: string) => void;
  closeError: () => void;

  // helper for migration from old toast
  notify: {
    success: (text: string, options?: { id: string }) => void;
    error: (text: string, options?: { id: string }) => void;
    loading: (text: string, options?: { id: string }) => void;
    dismiss: (id: string) => void;
  };
}

export const useNotificationsStore = create<NotificationsState>()(
  devtools((set, get) => ({
    loading: [],
    loadingTexts: {},
    notifyLoading: (toAddId: string, text?: string) =>
      set(state => ({
        loading: Array.from(new Set([...state.loading, toAddId])),
        loadingTexts: { ...state.loadingTexts, [toAddId]: text },
      })),
    dismiss: (toDismissId?: string) =>
      set(state => ({
        loading: state.loading.filter(id => id !== toDismissId),
      })),
    dismissAll: () => set({ loading: [] }),

    info: { open: false },
    notifyInfo: (text: string, toDismissId?: string) => {
      get().dismiss(toDismissId);
      set(state => ({
        info: { open: true, text },
      }));
    },
    closeInfo: () =>
      set(state => ({
        info: { ...state.info, open: false },
      })),

    success: { open: false },
    notifySuccess: (text: string, toDismissId?: string) => {
      get().dismiss(toDismissId);
      set(state => ({
        success: { open: true, text },
      }));
    },
    closeSuccess: () =>
      set(state => ({
        success: { ...state.success, open: false },
      })),

    error: { open: false },
    notifyError: (text: string, toDismissId?: string) => {
      get().dismiss(toDismissId);
      set(state => ({
        error: { open: true, text },
      }));
    },
    closeError: () =>
      set(state => ({
        error: { ...state.error, open: false },
      })),

    notify: {
      success: (text: string, options?: { id: string }) => get().notifySuccess(text, options?.id),
      error: (text: string, options?: { id: string }) => get().notifyError(text, options?.id),
      loading: (text: string, options?: { id: string }) => get().notifyLoading(options?.id || text),
      dismiss: (id: string) => get().dismiss(id),
    },
  })),
);
