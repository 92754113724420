import { ACCOUNT_FIELDS_MAX_LENGTH, AccountBioFields } from '@/shared/constants';
import { TFunction } from 'i18next';
import { FieldValues, RegisterOptions } from 'react-hook-form';

export type Rules = Omit<
  RegisterOptions<FieldValues, string>,
  'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
>;

const requiredCreator = (t: TFunction) => () => ({
  required: { value: true, message: t('error.required') },
});
const maxLengthCreator = (t: TFunction) => (value: number) => ({
  maxLength: { value, message: t('error.maxLength', { value }) },
});

const validateMainEmailCreator = (t: TFunction) => (value: string) => {
  const email_user = value?.split('@').shift();
  const email_domain = value?.split('@').pop();
  if (
    !!email_user &&
    !!email_domain &&
    (email_user.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAILUSER ||
      email_domain.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAILDOMAIN)
  )
    return t('error.invalidFieldGeneral');
  return undefined;
};

/**Reference: shared/util/helpers.ts/checkAccountFieldsLength */

export const validationRules = (t: TFunction): { [key in keyof AccountBioFields]: Rules } => {
  const required = requiredCreator(t);
  const maxLength = maxLengthCreator(t);
  const mainEmailValidator = validateMainEmailCreator(t);
  return {
    email: {
      validate: mainEmailValidator,
    },

    firstName: { ...required(), ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.FIRSTNAME) },
    lastName: { ...required(), ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.LASTNAME) },
    workEmail: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.EMAIL) },
    workEmail2: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.EMAIL) },
    position: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    role: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    division: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    company: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    mobilePhone: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    workPhone: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    homePhone: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    hotlinePhone: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    assistantPhone: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    workFax: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    workUrl: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.URL) },
    address1_addressee: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address1_addressLine1: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address1_addressLine2: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address1_postCode: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    address1_city: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address1_country: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address1_label: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address2_addressee: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address2_addressLine1: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address2_addressLine2: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address2_postCode: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) },
    address2_city: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address2_country: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
    address2_label: { ...maxLength(ACCOUNT_FIELDS_MAX_LENGTH.TEXT) },
  };
};
