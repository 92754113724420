import { dispatchTypes } from '../../index';
import { updateFileDisplayStatus } from '../../../../infrastructure/apis/md/profiles/files';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import i18n from '../../../../config/i18n';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const updateFileDisplayMd =
  (
    employeeId: string,
    fileId: number,
    canDisplayOnProfile: boolean,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.BUSINESS.FILES.SHOWLOADER });
    try {
      let response = await updateFileDisplayStatus(
        employeeId,
        fileId,
        canDisplayOnProfile,
        getAccessTokenSilently,
      );

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.FILES.UPDATEFILESUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFILE,
          payload: { employeeId, res: result },
        });
      } else {
        dispatch({
          type: dispatchTypes.BUSINESS.FILES.UPDATEFILEERROR,
          apiResponse: result,
        });
        useNotificationsStore.getState().notify.error(i18n.t('error.updateFile'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.BUSINESS.FILES.UPDATEFILEERROR, apiResponse: error });
      useNotificationsStore.getState().notify.error(i18n.t('error.updateFile'));
    }

    dispatch({ type: dispatchTypes.BUSINESS.FILES.HIDELOADER });
  };

export const getUploadFileThemeUrl = async (
  themeId: string,
  file: File,
  accountId: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  try {
    const url = await doS3UploadForBusiness(
      `themes/${themeId}/theme-files`,
      file,
      accountId,
      getAccessTokenSilently,
    );
    return url;
  } catch (error) {
    return null;
  }
};
