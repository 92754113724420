import { ApiFile } from '@/shared/types/api';
import { Box, styled } from '@mui/material';
import EntityBoxRenderer, { EntityBoxRendererGenericProps } from '../EntityBoxRenderer';
import NoteIcon from '@mui/icons-material/Note';
import { useTranslation } from 'react-i18next';
import { invertHex } from '@/infrastructure/helper';

type Props = {
  file: ApiFile;
  iconBgColor?: string;
} & EntityBoxRendererGenericProps;

export const FileBox = ({ file, iconBgColor, chips, ...rest }: Props) => {
  const isEmbed = file.embed;

  const { t } = useTranslation();

  return (
    <EntityBoxRenderer
      title={file.fileName}
      subtitle={isEmbed && file.link}
      icon={
        <IconWrapper bgcolor={iconBgColor}>
          <NoteIcon sx={{ color: invertHex(iconBgColor, true) }} />
        </IconWrapper>
      }
      {...rest}
      isLink={isEmbed}
      chips={[...(chips || []), file.embed && t('embed')].filter(Boolean)}
    />
  );
};

const IconWrapper = styled(Box)(({ bgcolor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4.4rem',
  width: '4.4rem',
  backgroundColor: bgcolor,
  borderRadius: '50%',
  img: {
    height: '2rem',
    display: 'block',
  },
}));
