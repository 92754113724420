import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropzone } from '@/components';
import { Box, CardMedia, IconButton, Paper, Switch } from '@mui/material';
import { Close } from '@mui/icons-material';
import TooltipInfo from '@/components/TooltipInfo';
import useId from '@mui/material/utils/useId';
import ImageCropper from '@/components/ImageCropper';

interface Props {
  logoWide: string;
  setLogoWide: Dispatch<SetStateAction<string>>;
  logoWideBright: string;
  setLogoWideBright: Dispatch<SetStateAction<string>>;
  logoWideInvert: boolean;
  setLogoWideInvert: Dispatch<SetStateAction<boolean>>;
}

const ALLOWED_TYPES = ['png'];
const MAX_SIZE = 10;

const Logos = (props: Props) => {
  const { t } = useTranslation();

  type ImageType = 'logoWide' | 'logoWideBright';
  const [imageType, setImageType] = useState<ImageType>();

  const { logoWide, logoWideBright, logoWideInvert } = props;
  const { setLogoWide, setLogoWideBright, setLogoWideInvert } = props;

  const [logoWideUrl, setLogoWideUrl] = useState(logoWide);
  const [logoWideBrightUrl, setLogoWideBrightUrl] = useState(logoWideBright);

  const [imageToCrop, setImageToCrop] = useState('');

  const selectHandler = async (image: ImageType, files: File[]) => {
    setImageType(image);
    const uploadedFile = files[0];
    if (uploadedFile) {
      if (uploadedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);

        reader.onload = () => {
          setImageToCrop(reader.result as string);
        };
      }
    }
  };

  const uploadHandler = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    setImageToCrop('');

    let file;
    switch (imageType) {
      case 'logoWide':
        file = new File([blob], 'logoWide.png');
        setLogoWide(file);
        setLogoWideUrl(croppedImageUrl);
        break;
      case 'logoWideBright':
        file = new File([blob], 'logoWideBright.png');
        setLogoWideBright(file);
        setLogoWideBrightUrl(croppedImageUrl);
        break;
      default:
        console.warn('default case, cropper');
    }
  };

  const removeHandler = (type: ImageType) => {
    switch (type) {
      case 'logoWide':
        setLogoWide(null);
        setLogoWideUrl(null);
        break;
      case 'logoWideBright':
        setLogoWideBright(null);
        setLogoWideBrightUrl(null);
        break;
    }
  };

  const darkBgImageId = useId();
  return (
    <>
      {imageToCrop && (
        <ImageCropper
          image={imageToCrop}
          onCropClick={url => uploadHandler(url)}
          onClose={() => setImageToCrop('')}
          noAspectRatio={true}
          cropOptions={{ width: 100, height: 100 }}
        />
      )}
      <Box display={'flex'} flexDirection={'column'} gap={2.4}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Box sx={{ typography: 'h4' }}>{t('organisation.lightBackground')}</Box>
            <TooltipInfo
              text={t('organisation.lightBackgroundTooltip')}
              icon={{ color: 'text.primary', size: 17 }}
              placement='right'
            />
          </Box>
          {logoWideUrl ? (
            <ImagePreview image={logoWideUrl} onClose={() => removeHandler('logoWide')} />
          ) : (
            <Dropzone
              maxSize={MAX_SIZE}
              types={ALLOWED_TYPES}
              onChange={files => selectHandler('logoWide', files)}
              fullWidth
              extensionsText={t('organisation.logoLimit', { x: MAX_SIZE })}
            />
          )}
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box id={darkBgImageId} sx={{ typography: 'h4' }}>
              {t('organisation.otherLightBackground')}
            </Box>
            <Switch
              onChange={() => {
                if (logoWideBrightUrl) removeHandler('logoWideBright');
                setLogoWideInvert(!logoWideInvert);
              }}
              checked={!logoWideInvert}
              inputProps={{
                'aria-labelledby': darkBgImageId,
              }}
            />
          </Box>
          {logoWideInvert && logoWideUrl ? (
            <ImagePreview image={logoWideUrl} invertedImage />
          ) : logoWideBrightUrl ? (
            <ImagePreview
              image={logoWideBrightUrl}
              onClose={() => removeHandler('logoWideBright')}
            />
          ) : (
            <>
              <Dropzone
                maxSize={MAX_SIZE}
                types={ALLOWED_TYPES}
                onChange={files => selectHandler('logoWideBright', files)}
                fullWidth
                extensionsText={t('organisation.logoLimit', { x: MAX_SIZE })}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Logos;

const ImagePreview = ({
  image,
  onClose,
  invertedImage,
}: {
  image: string;
  onClose?: () => void;
  invertedImage?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Paper
      sx={theme => ({
        borderStyle: 'dashed',
        position: 'relative',
        backgroundColor: theme.palette.grey[300],
        p: 1,
        display: 'flex',
        maxHeight: '10vw',
      })}
      variant='outlined'
    >
      <CardMedia
        component={'img'}
        src={image}
        sx={{
          objectFit: 'contain',
          ...(invertedImage && {
            filter: 'brightness(0) invert(1)',
          }),
        }}
      />
      {onClose && (
        <IconButton
          onClick={onClose}
          aria-label={t('ariaClear')}
          sx={theme => ({
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: theme.palette.action.disabledBackground,
            },
          })}
        >
          <Close />
        </IconButton>
      )}
    </Paper>
  );
};
