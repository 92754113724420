import { Tooltip, IconButton, IconButtonProps, Fade } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: () => void;
} & IconButtonProps;

const DURATION = 2000;

const CopyIconButton = ({ onClick, ...rest }: Props) => {
  const [open, setOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleClick = () => {
    clearTimeout(timeoutRef.current);
    setOpen(true);
    onClick?.();
    timeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, DURATION);
  };

  const { t } = useTranslation();

  return (
    <>
      <Tooltip placement='top' open={open} title={t('copied')} TransitionComponent={Fade}>
        <IconButton {...rest} onClick={handleClick}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CopyIconButton;
