import { getContactFormTranslationKeyByField } from '@/shared/util';
import { useTranslation } from 'react-i18next';
import { TextField, Card, CardContent, CardHeader, CardProps } from '@mui/material';
import { useEffect, useRef } from 'react';
import useId from '@mui/material/utils/useId';

type Props = CardProps & {
  value: string;
  onChange: (value: string) => void;
  focused?: boolean;
};

export const NotesField = ({ value, onChange, focused, ...rest }: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focused && inputRef.current) {
      const input = inputRef.current;
      input.focus();
      const length = input.value?.length || 0;
      input.setSelectionRange(length, length);
    }
  }, [focused]);

  const titleId = useId();

  return (
    <Card {...rest}>
      <CardHeader
        titleTypographyProps={{ variant: 'h4', id: titleId }}
        title={t(getContactFormTranslationKeyByField('notes'))}
      />
      <CardContent>
        <TextField
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          multiline
          rows={4}
          fullWidth
          inputRef={inputRef}
          inputProps={{
            'aria-labelledby': titleId,
          }}
        />
      </CardContent>
    </Card>
  );
};
