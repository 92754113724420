import { Autocomplete, TextField } from '@mui/material';
// import { AnchorText } from '../AnchorText';
import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';
import { ThemeDomain } from '@/shared/types/api';

type Props = {
  setSelectedDomains: (domains: ThemeDomain[]) => void;
  selectedDomains: ThemeDomain[];
  domains: ThemeDomain[];
};

export const LinkDomainStep = ({ domains, selectedDomains, setSelectedDomains }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <TextsRenderer
        mb={1.2}
        title={t('idp.sso.steps.linkDomain.title')}
        texts={[t('idp.sso.steps.linkDomain.description')]}
      />

      {/* <AnchorText mb={3.2} href='#' text={t('idp.sso.learnMoreAboutDomains')} /> */}

      <Autocomplete
        multiple
        options={domains}
        getOptionLabel={(option: ThemeDomain) => option.domain}
        onChange={(event, newValue: ThemeDomain[]) => setSelectedDomains(newValue)}
        value={selectedDomains}
        renderInput={params => (
          <TextField {...params} label={t('idp.sso.selectDomains')} placeholder='example.com' />
        )}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </>
  );
};
