import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
  CardHeader,
} from '@mui/material';
import { Box } from '@mui/system';
import { FILE_GEN_STATUS, QR_META_TYPES } from '@/shared/constants';
import DownloadIcon from '@mui/icons-material/Download';
import ReplayIcon from '@mui/icons-material/Replay';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import {
  downloadGeneratedFile,
  retryDownloadGeneratedFile,
} from '@/infrastructure/apis/md/customization/fileGen';
import { openLink } from '@/util';
import FileRow from './FileRow';
import LtLoadingButton from '@/components/LtLoadingButton';
import { FileGeneration } from '@/shared/types/api/file-generation.type';
import { Trans } from 'react-i18next';
import { useNotificationsStore } from '@/zustand/notificationsStore';

type Props = {
  file: FileGeneration;
  refetch: () => Promise<FileGeneration[]>;
};

const FileCard = ({ file, refetch }: Props) => {
  const { t } = useAppTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [downloadLoading, setDownloadLoading] = useState<Record<number, boolean>>({});
  const [retryLoading, setRetryLoading] = useState(false);

  const handleDownloadFile = async (id: number, fileUuid: string) => {
    setDownloadLoading(prev => ({ ...prev, [fileUuid]: true }));
    try {
      const res = await downloadGeneratedFile(getAccessTokenSilently, id, fileUuid);
      const url = res.url;
      if (url) {
        openLink(url);
      } else {
        useNotificationsStore.getState().notify.error(t('unexpectedError'));
      }
    } catch (error) {
      useNotificationsStore.getState().notify.error(t('unexpectedError'));
    }
    setDownloadLoading(prev => ({ ...prev, [fileUuid]: false }));
  };

  const handleRetryDownloadFile = async () => {
    setRetryLoading(true);
    try {
      await retryDownloadGeneratedFile(getAccessTokenSilently, file);
      await refetch();
    } catch (error) {
      console.log(error);
      useNotificationsStore.getState().notify.error(t('unexpectedError'));
    }
    setRetryLoading(false);
  };

  return (
    <Card sx={{ mb: 1.5 }}>
      <CardHeader
        title={t(
          file.meta.type === QR_META_TYPES.PLAIN
            ? 'export.fileCard.header.qrPlain'
            : 'export.fileCard.header.qrBg',
          { count: file.meta.amount, type: file.meta?.variant },
        )}
        titleTypographyProps={{ variant: 'h4' }}
      ></CardHeader>
      <Divider />
      <CardContent>
        <Box width={'100%'} display='flex' flexDirection='column' gap={1.5} mt={2}>
          {file.status === FILE_GEN_STATUS.PENDING && (
            <FileRow
              title={t('export.fileCard.runningTitle')}
              status={file.status}
              createdOn={file.createdOn}
              action={<CircularProgress color='primary' />}
            />
          )}
          {file.status === FILE_GEN_STATUS.ERROR && (
            <FileRow
              title={t('export.fileCard.errorTitle')}
              status={file.status}
              createdOn={file.createdOn}
              action={
                file.isRetryAttempt ? (
                  <Typography variant='caption'>
                    <Trans
                      i18nKey='export.fileCard.action.contact'
                      components={[<span style={{ fontWeight: '600' }} />]}
                    />
                  </Typography>
                ) : (
                  <LtLoadingButton
                    loading={retryLoading}
                    loadingPosition='start'
                    size='small'
                    color='error'
                    startIcon={<ReplayIcon />}
                    onClick={handleRetryDownloadFile}
                  >
                    {t('export.fileCard.action.retry')}
                  </LtLoadingButton>
                )
              }
            />
          )}
          {file.status === 'success' &&
            file.files?.map(x => (
              <FileRow
                key={x.path}
                title={x.fileName}
                status={file.status}
                createdOn={file.createdOn}
                action={
                  <LtLoadingButton
                    loading={downloadLoading?.[x.uuid]}
                    loadingPosition='start'
                    size='small'
                    color='primaryButton'
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownloadFile(file.id, x.uuid)}
                  >
                    {t('export.fileCard.action.download')}
                  </LtLoadingButton>
                }
              />
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default FileCard;
