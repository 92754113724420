import { Auth0ContextInterface } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import { PERMISSIONS } from '../constants';
import config from '@/config/config';

export type FileTemplatePayload = {
  id?: number;
  fileName?: string;
  link: string;
  embed: boolean;
  pageCount: number;
};

export const getFileTemplates = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/file-templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error fetching file templates');
  }
  return res.data;
};

export const deleteFileTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: number,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(`${config.API_BASE_URL}theme/file-templates/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error deleting file template');
  }
  return res.data;
};

export const createFileTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: FileTemplatePayload,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        `${config.API_BASE_URL}theme/file-templates`, ///here must chagne
        {
          fileTemplateEntity: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error creating file template');
  }
  return res.data;
};

export const updateFileTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: FileTemplatePayload,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.patch(
        `${config.API_BASE_URL}theme/file-templates/${payload.id}`,
        {
          fileTemplateEntity: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error deleting file template');
  }
  return res.data;
};
