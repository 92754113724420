import { LeadAPI } from '../../../shared/types/api';

// determines whether lead is lt2lt type
export type LeadType = 'manual' | 'businessCardOnly' | 'lt2lt';
export const getLeadType = (
  lead: Pick<LeadAPI, 'firstName' | 'lastName' | 'email' | 'lt2lt' | 'businessCardUrls'>,
): LeadType => {
  if (!lead) return 'manual';
  else if (lead.firstName || lead.lastName || lead.email) return 'manual';
  else if (lead.businessCardUrls?.length > 0) return 'businessCardOnly';
  else if (lead.lt2lt) return 'lt2lt';
  else return 'manual';
};
