const dateFieldKeys = ['createdOn', 'birthday', 'deletedAt', 'editedAt'];

export const isDateField = (field: string) => {
  return dateFieldKeys.includes(field);
};

const numberFieldKeys = ['numAccounts', 'numUnitAdmins'];

export const isNumberField = (field: string) => {
  return numberFieldKeys.includes(field);
};
