import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AddNewFilePopup from '@/components/Profile/components/popups/add-new-file-popup';
import DeletePopup from '@/components/Profile/components/popups/delete-item-popup';
import { Employee } from '../../../../../../shared/types/api/employee.type';
import { ApiFile } from '../../../../../../shared/types/api';
import FileSelector from './FileSelector';
import { useAuth0 } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../../../../../../infrastructure/helper';
import axios from 'axios';
import { PERMISSIONS } from '../../../../../../infrastructure/constants';
import config from '../../../../../../config/config';
import { ThemeLinksFilesPosition } from '../../../../../../shared/types/global';
import { THEME_LINKS_FILES_POSITIONS } from '../../../../../../shared/constants';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useTranslation } from 'react-i18next';
import { LtSorter } from '@/components';
import { FileBox } from '@/components/Files/FileBox';
import { Box, Divider } from '@mui/material';
import { SectionRenderer } from '../../SectionRenderer';

export interface UploadfileProps {
  id?: number;
  uploadedFile?: File;
  fileName: string;
  pageCount: number;
  embed: boolean;
  link?: string;
  fileTemplateId?: number;
}

export interface UploadfileTemplateProps {
  id: number;
  url: string;
  fileName: string;
  pageCount: number;
}

interface Props {
  employee?: Employee;
  isCompanydataSettings?: boolean;
  setThemeFiles?(themeFiles: (ApiFile & { fileToUpload?: File })[]): void;
  themeFilesPosition: ThemeLinksFilesPosition;
  setThemeFilesPosition?(position: ThemeLinksFilesPosition): void;
  userFilesProps?: {
    userFiles: Array<ApiFile>;
    setUserFiles: Dispatch<SetStateAction<Array<ApiFile>>>;
  };
  loadingCompleted?: Function;
  unitId?: number;
  fileBoxColor?: string;
}

const Files = (props: Props) => {
  const { t } = useTranslation();
  const {
    employee,
    isCompanydataSettings,
    setThemeFiles,
    userFilesProps,
    loadingCompleted,
    unitId,
    fileBoxColor,
  } = props;

  const [loading, setLoading] = useState(true);

  const [savedTemplateFiles, setSavedTemplateFiles] = useState<
    (ApiFile & { fileToUpload?: File })[]
  >([]);
  const [companyFilesList, setCompanyFilesList] = useState<ApiFile[]>([]);
  const files = useMemo(
    () => (isCompanydataSettings ? [] : userFilesProps?.userFiles || []),
    [userFilesProps?.userFiles, isCompanydataSettings],
  );

  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<ApiFile>();

  const { setIsUnsaved } = useUnsavedStatusSetter();

  const EndFilesRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();

  const totalFiles = useMemo((): ApiFile[] => {
    let _totalFiles = [];
    if (Array.isArray(files)) _totalFiles = [...files];
    return [..._totalFiles, ...savedTemplateFiles];
  }, [files, savedTemplateFiles]);

  const scrollToBottom = () => {
    EndFilesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'theme/global-files', {
          params: { unitId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
    )
      .then(res => {
        if (isCompanydataSettings) {
          setSavedTemplateFiles(res.data.data);
        } else {
          if (employee) setCompanyFilesList(res.data.data);
        }
        setThemeFiles?.(res.data.data);
        loadingCompleted?.();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    employee,
    getAccessTokenSilently,
    isCompanydataSettings,
    loadingCompleted,
    setThemeFiles,
    unitId,
  ]);

  const onDeleteFileClick = useCallback(file => {
    setSelectedFile(file);
    setShowDeleteFile(true);
  }, []);

  const [showIndividualFile, setShowIndividualFile] = useState(false);

  const handleCompanySettingsUpdate = async (config: {
    fileType: 'newFile' | 'templateFile';
    templateFileDetails?: UploadfileTemplateProps;
    newFileDetails?: UploadfileProps;
  }) => {
    const templateFiles: (ApiFile & { fileToUpload?: File })[] = [
      ...savedTemplateFiles,
      {
        id: config.fileType === 'templateFile' ? Math.random() : -Math.random(),
        link: config.fileType === 'newFile' ? '' : config.templateFileDetails.url,
        fileName:
          config.fileType === 'newFile'
            ? config.newFileDetails.fileName
            : config.templateFileDetails.fileName,
        embed: config.fileType === 'newFile' ? config.newFileDetails.embed : false,
        canDisplayOnProfile: true,
        order: savedTemplateFiles.length,
        pageCount:
          config.fileType === 'newFile'
            ? config.newFileDetails.pageCount
            : config.templateFileDetails.pageCount,
        fileTemplateId: config.fileType === 'newFile' ? null : config.templateFileDetails.id,
        fileToUpload: config.fileType === 'newFile' && config.newFileDetails?.uploadedFile,
      },
    ];
    setSavedTemplateFiles(templateFiles);
    props.setThemeFiles?.(templateFiles);
    setIsUnsaved(true);
    setShowIndividualFile(false);
  };

  const handleCompanySettingsFileDelete = (fileId: number) => {
    const templateFiles = savedTemplateFiles.filter(file => file.id !== fileId);
    setSavedTemplateFiles(templateFiles);
    props.setThemeFiles?.(templateFiles);
    setIsUnsaved(true);
  };

  const themeFiles = useMemo(
    () =>
      Boolean(companyFilesList.length) ? (
        <Box display='flex' flexDirection='column' gap={2}>
          {companyFilesList.map((item, index) => (
            <FileBox key={item.id} file={item} iconBgColor={fileBoxColor} chips={[t('default')]} />
          ))}
        </Box>
      ) : null,

    [companyFilesList, fileBoxColor, t],
  );

  return (
    <>
      {showIndividualFile && (
        <AddNewFilePopup
          isCompanydataSettings={isCompanydataSettings}
          handleCompanySettingsUpdate={isCompanydataSettings && handleCompanySettingsUpdate}
          employee={employee}
          onCloseClick={() => setShowIndividualFile(false)}
          {...userFilesProps}
        />
      )}
      {showDeleteFile && selectedFile && (
        <DeletePopup
          isCompanydataSettings={isCompanydataSettings}
          handleCompanySettingsFileDelete={isCompanydataSettings && handleCompanySettingsFileDelete}
          employeeId={employee?.id}
          onCloseClick={() => setShowDeleteFile(false)}
          item={selectedFile}
          mode='file'
          {...userFilesProps}
        />
      )}

      <SectionRenderer
        label={t('files')}
        tooltipText={t('md.profileDesign.filesTooltip')}
        noDivider
      >
        <FileSelector
          setSavedTemplateFiles={setSavedTemplateFiles}
          savedTemplateFiles={savedTemplateFiles}
          setShowIndividualFile={setShowIndividualFile}
          employeeId={employee?.id}
          scrollToBottom={scrollToBottom}
          totalFiles={totalFiles}
          isCompanydataSettings={isCompanydataSettings}
          handleCompanySettingsUpdate={isCompanydataSettings && handleCompanySettingsUpdate}
          companyFilesList={companyFilesList}
          {...userFilesProps}
        />
        {loading && <p>{t('Loading')}..</p>}
        {props.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeFiles && (
          <>
            {themeFiles}
            <Divider sx={{ my: 2 }} />
          </>
        )}
        <LtSorter
          onEnd={newItems => userFilesProps?.setUserFiles?.(newItems)}
          items={totalFiles}
          getItemAriaLabel={item => `${t('ariaProfileFile')}: ${item.fileName}`}
          getItemKey={item => item.id}
          renderer={file => (
            <FileBox
              file={file}
              onDelete={() => onDeleteFileClick(file)}
              iconBgColor={fileBoxColor}
              chips={[file.fileTemplateId && t('template')].filter(Boolean)}
            />
          )}
        />

        {props.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeFiles && (
          <>
            <Divider sx={{ my: 2 }} />
            {themeFiles}
          </>
        )}
      </SectionRenderer>
    </>
  );
};

export default Files;
