import { BadgeBase } from '@/shared/types/global';
import { checkConditions } from '../util';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { Chip } from '@mui/material';

interface GsBadgeProps {
  badge: undefined | BadgeBase;
}

export default function GsBadge({ badge }: GsBadgeProps) {
  const tierInfo = useTierInfo();
  if (!badge || !checkConditions(tierInfo, badge.conditions)) return null;
  return <Chip color='default' size='small' label={badge.text} />;
}
