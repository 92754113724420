import { useAppSelector } from '@/application/hooks';
import { Account, AccountImageState } from '@/shared/types/api';
import { Box, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { useDispatch } from 'react-redux';
import { uploadImage } from '@/application/actions/edit-profile';
import ProfilePicture from '../../md/profiles/common/images/profile-picture';
import { checkAccountFieldsLength } from '@/shared/util';
import { getAccountLengthError } from '../../md/profiles/import-error-handler';
import { isEmptyObject } from '@/infrastructure/helper';
import { operations } from '@/application/actions/onboarding';
import { useAuth0 } from '@auth0/auth0-react';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { ContinueButton, OnboardingScreenWrapper } from '../components';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { useBlockedFields } from '@/infrastructure/hooks/useBlockedFields';

type Props = {
  next: () => void;
};

export const Screen1 = ({ next }: Props) => {
  const { isMinEssentials } = useTierInfo();
  const { getAccessTokenSilently } = useAuth0();
  const { isFieldBlocked } = useBlockedFields();

  const [loading, setLoading] = useState(false);
  const account: Account = useAppSelector(state => state.account);
  const [form, setForm] = useState({
    firstName: account.firstName,
    lastName: account.lastName,
    position: account.position,
    company: account.company,
    email: account.email,
    mobilePhone: account.mobilePhone,
    workUrl: account.workUrl,
  });

  const [profileImageFile, setProfileImageFile] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();

  const {
    profileDesign: { defaultProfileImageUrl },
  } = useProfileDesign();

  const currentProfileImage = account.profileImageUrl || defaultProfileImageUrl;

  const [profileImageUrl, setProfileImageUrl] = useState(currentProfileImage);

  const [profileImageState, setProfileImageState] = useState<AccountImageState>(
    AccountImageState.DEFAULT,
  );
  const handleProfileImageChange = (file?: File, url?: string, state?: AccountImageState) => {
    setProfileImageFile(file);
    setProfileImageUrl(url);
    setProfileImageState(state);
  };

  const isContinueAllowed = form.firstName && form.lastName && (!isMinEssentials || form.company);

  const handleSubmit = async () => {
    if (!isContinueAllowed) {
      return;
    }

    // check for account fields length rule
    const lengthError = checkAccountFieldsLength(form);
    if (!isEmptyObject(lengthError)) {
      const errorText = getAccountLengthError(lengthError);
      toast.error(errorText);
      return;
    }

    setLoading(true);

    await Promise.all(
      [
        dispatch(operations(form, getAccessTokenSilently)),
        profileImageFile &&
          dispatch(
            uploadImage(
              account.username,
              profileImageFile,
              'profile',
              AccountImageState.DEFINED,
              getAccessTokenSilently,
            ),
          ),
      ].filter(Boolean),
    );

    setLoading(false);

    next();
  };

  const generateTextFieldProps = (field: string, required: boolean = false) => ({
    label: `${t(field)}${required ? '*' : ''}`,
    value: form[field] || '',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [field]: e.target.value });
    },
    fullWidth: true,
    disabled: isFieldBlocked(field as keyof Account),
  });

  return (
    <OnboardingScreenWrapper title={t('onboarding.welcome')} titleCenter>
      <Box display='flex' flexDirection='column' gap={1.6}>
        <Box margin='auto'>
          <ProfilePicture
            defaultProfileImageUrl={defaultProfileImageUrl}
            profileImageUrl={profileImageUrl}
            profileImageState={profileImageState}
            onChange={handleProfileImageChange}
            hideDeleteOption
          />
        </Box>
        <Grid container spacing={1.6}>
          <Grid item xs={12} sm={6}>
            <TextField {...generateTextFieldProps('firstName', true)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField {...generateTextFieldProps('lastName', true)} />
          </Grid>
        </Grid>
        <TextField {...generateTextFieldProps('position')} />
        <TextField {...generateTextFieldProps('company', isMinEssentials)} />
        <TextField {...generateTextFieldProps('email')} />
        <TextField {...generateTextFieldProps('mobilePhone')} label={t('mobilePhone.text')} />
        <TextField {...generateTextFieldProps('workUrl')} label={t('website')} />
        <ContinueButton
          onClick={handleSubmit}
          disabled={!isContinueAllowed}
          loading={loading}
          loadingPosition='end'
        >
          {t('onboarding.continue')}
        </ContinueButton>
      </Box>
    </OnboardingScreenWrapper>
  );
};
