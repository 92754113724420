import LtLoadingButton from '@/components/LtLoadingButton';
import { CrmCampaign } from '@/shared/types/api';
import TooltipInfo from '@/components/TooltipInfo';
import { ExpandMore, Refresh } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onSelect: (value: string) => void;
  campaigns: CrmCampaign[];
  onRefresh: () => Promise<void>;
};

export const CampaignSelector = ({ value, onSelect, campaigns, onRefresh }: Props) => {
  const { t } = useTranslation();

  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await onRefresh();
    setRefreshing(false);
  };

  const labelId = useId();
  const accId = useId();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${accId}-content`}
        id={`${accId}-header`}
      >
        <Typography variant='body2'>
          {t('mdLeads.crmExport.confirmDialog.campaignAccordionTitle')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='body2' color='secondary'>
          {t('mdLeads.crmExport.confirmDialog.campaignTitle')}
        </Typography>
        <Box mt={2} display='flex' alignItems='start' gap={0.5}>
          <FormControl fullWidth>
            <InputLabel id={labelId}>
              {t('mdLeads.crmExport.confirmDialog.selectCampaign')}
            </InputLabel>
            <Select
              value={value || ''}
              labelId={labelId}
              label={t('mdLeads.crmExport.confirmDialog.selectCampaign')}
              onChange={({ target: { value } }) => onSelect(value)}
            >
              {campaigns.map(campaign => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t('mdLeads.crmExport.confirmDialog.campaignHelper')}</FormHelperText>
          </FormControl>
          <TooltipInfo text={t('ariaRefreshCampaigns')} onHover>
            <LtLoadingButton
              variant='text'
              sx={{ mt: 1 }}
              loading={refreshing}
              onClick={handleRefresh}
              aria-label={t('ariaRefreshCampaigns')}
            >
              <Refresh />
            </LtLoadingButton>
          </TooltipInfo>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
