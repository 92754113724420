import { LtDialog } from '@/components';
import { Stepper, Step, StepLabel, Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddSamlStep,
  BeforeYouBeginStep,
  CopyUrlsStep,
  LinkDomainStep,
  SaveConfigStep,
} from './SSOSteps';
import {
  EntraIdStrategyData,
  OktaStrategyData,
  SAMLStrategyData,
  StrategyName,
  StrategyOptions,
  ThemeDomain,
  ThemeIdentityProviderDetailed,
} from '@/shared/types/api';
import { createSsoConnection } from '@/infrastructure/apis/md/idp';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useConnectionSettings } from './helpers/hooks';
import { isValidOptions } from './helpers/utils';
import { getSSOStrategies } from '@/shared/util';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

enum StepEnum {
  BEFORE_YOU_BEGIN,
  COPY_URLS,
  ADD_SAML,
  LINK_DOMAIN,
  SAVE,
}

const INITIAL_STEP = StepEnum.BEFORE_YOU_BEGIN;
const steps = [
  StepEnum.BEFORE_YOU_BEGIN,
  StepEnum.COPY_URLS,
  StepEnum.ADD_SAML,
  StepEnum.LINK_DOMAIN,
  StepEnum.SAVE,
];
const stepsLabels = {
  [StepEnum.BEFORE_YOU_BEGIN]: 'idp.sso.steps.beforeYouBegin.stepperTitle',
  [StepEnum.COPY_URLS]: 'idp.sso.steps.copyUrls.stepperTitle',
  [StepEnum.ADD_SAML]: 'idp.sso.steps.addSaml.stepperTitle',
  [StepEnum.LINK_DOMAIN]: 'idp.sso.steps.linkDomain.stepperTitle',
  [StepEnum.SAVE]: 'idp.sso.steps.save.stepperTitle',
};

const getNextStep = (step: StepEnum) => steps.indexOf(step) + 1;

const getPrevStep = (step: StepEnum) => steps.indexOf(step) - 1;

type Props = {
  onClose: () => void;
  open: boolean;
  themeIdp: ThemeIdentityProviderDetailed;
  domains: ThemeDomain[];
  isScimActive: boolean;
  openScimSetupPopup: () => void;
};

const getInitialOptions = (strategy: StrategyName) => {
  switch (strategy) {
    case 'samlp':
      return {
        signInUrl: '',
        certificate: '',
        signOutUrl: '',
        metaDataUrl: '',
      } as SAMLStrategyData;
    case 'okta':
      return {
        oktaDomain: '',
        clientID: '',
        clientSecret: '',
      } as OktaStrategyData;
    case 'waad':
      return {
        entraIdDomain: '',
        clientID: '',
        clientSecret: '',
        identityAPI: 'microsoft-identity-platform-v2.0',
      } as EntraIdStrategyData;
    default:
      return {};
  }
};

export const SSOSetupPopup = ({
  onClose,
  open,
  themeIdp,
  domains,
  isScimActive,
  openScimSetupPopup,
}: Props) => {
  const { notify: toast } = useLtNotifications();

  const [selectedStrategy, setSelectedStrategy] = useState<StrategyName>('samlp' as StrategyName);
  const [strategyOptionsValues, setStrategyOptionsValues] = useState<StrategyOptions>({
    ...getInitialOptions('samlp'),
  });

  const [selectedDomains, setSelectedDomains] = useState<ThemeDomain[]>([]);
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const [createConnectionLoading, setCreateConnectionLoading] = useState(false);
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();

  const availableStrategies = getSSOStrategies(themeIdp);
  const [primaryStrategy] = availableStrategies;

  const { connectionName, copyFields } = useConnectionSettings(themeIdp, selectedStrategy);

  useEffect(() => {
    setSelectedStrategy(primaryStrategy);
  }, [primaryStrategy]);

  useEffect(() => {
    setStrategyOptionsValues(getInitialOptions(selectedStrategy));
  }, [selectedStrategy]);

  const isLastStep = activeStep === steps[steps.length - 1];
  const isFirstStep = activeStep === steps[0];
  const disableNextBtn =
    (activeStep === StepEnum.ADD_SAML &&
      !isValidOptions(strategyOptionsValues, selectedStrategy)) ||
    (activeStep === StepEnum.LINK_DOMAIN && selectedDomains.length === 0);

  const createConnection = async () => {
    setCreateConnectionLoading(true);
    let success = true;
    try {
      await createSsoConnection(getAccessTokenSilently, id, {
        connectionName,
        strategy: selectedStrategy,
        options: strategyOptionsValues,
        themeDomainIds: selectedDomains.map(({ id }) => id),
      });
      success = true;
    } catch (error) {
      const errMessage = error?.response?.data?.error?.message || 'Something went wrong';
      toast.error(errMessage);
      success = false;
    }
    setCreateConnectionLoading(false);
    return success;
  };

  const handleNextClick = async () => {
    if (!disableNextBtn) {
      let success = true;
      if (activeStep === StepEnum.LINK_DOMAIN) {
        success = await createConnection();
      }
      if (isLastStep) {
        onClose();
        return;
      }
      if (success) setActiveStep(getNextStep(activeStep));
    }
  };

  const handlePrevClick = () => {
    if (isFirstStep) {
      onClose();
      return;
    }
    setActiveStep(getPrevStep(activeStep));
  };

  const handleScimSetupClick = () => {
    onClose();
    openScimSetupPopup();
  };

  return (
    <LtDialog
      TransitionProps={{
        onExited: () => {
          setActiveStep(INITIAL_STEP);
        },
      }}
      size='lg'
      open={open}
      onClose={onClose}
      title={t('idp.sso.setupSaml')}
      cancelAction={
        !isLastStep && {
          onClick: handlePrevClick,
          text: t(isFirstStep ? 'cancel' : 'back'),
        }
      }
      saveAction={{
        onClick: handleNextClick,
        text: t(isLastStep ? 'finish' : 'next'),
        disabled: disableNextBtn,
        loading: createConnectionLoading,
        startIcon: null,
        loadingPosition: 'center',
      }}
      confirmAction={
        isLastStep &&
        !isScimActive && {
          onClick: handleScimSetupClick,
          text: t('idp.setupScim'),
        }
      }
    >
      <Box mb={3}>
        <Stepper activeStep={activeStep}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{t(stepsLabels[step])}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider />
      <Box mt={3}>
        {activeStep === StepEnum.BEFORE_YOU_BEGIN && (
          <BeforeYouBeginStep
            selectedStrategy={selectedStrategy}
            strategies={availableStrategies}
            setSelectedStrategy={setSelectedStrategy}
          />
        )}
        {activeStep === StepEnum.COPY_URLS && <CopyUrlsStep fields={copyFields} />}
        {activeStep === StepEnum.ADD_SAML && (
          <AddSamlStep
            values={strategyOptionsValues}
            onChange={setStrategyOptionsValues}
            selectedStrategy={selectedStrategy}
          />
        )}
        {activeStep === StepEnum.LINK_DOMAIN && (
          <LinkDomainStep
            domains={domains}
            selectedDomains={selectedDomains}
            setSelectedDomains={setSelectedDomains}
          />
        )}
        {activeStep === StepEnum.SAVE && <SaveConfigStep />}
      </Box>
    </LtDialog>
  );
};
