import { Box, styled } from '@mui/material';

const ResponsiveProfileWidthContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: 'auto',
  width: '56rem',
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

// LEGACY

const bannerWidths = {
  base: '33% + 4rem',
  lg: '50% + 3rem',
  md: '100%',
};
export const ResponsiveProfileBannerWidthContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: 'auto',
  width: `calc(${bannerWidths.base})`,
  [theme.breakpoints.down('lg')]: {
    width: `calc(${bannerWidths.lg})`,
  },
  [theme.breakpoints.down('md')]: {
    width: `calc(${bannerWidths.md})`,
  },
}));
export const ResponsiveProfileBannerWidthHeightContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: 'auto',
  width: `calc(${bannerWidths.base})`,
  height: `calc((${bannerWidths.base}) / (16/9))`,
  [theme.breakpoints.down('lg')]: {
    width: `calc(${bannerWidths.lg})`,
    height: `calc((${bannerWidths.lg}) / (16/9))`,
  },
  [theme.breakpoints.down('md')]: {
    width: `calc(${bannerWidths.md})`,
    height: `calc((${bannerWidths.md}) / (16/9))`,
  },
}));

export default ResponsiveProfileWidthContainer;
