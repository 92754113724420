import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { Alert, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  map: Record<string, string[]>;
};

export const SameLabelAlert = ({ map }: Props) => {
  const { t } = useTranslation();

  const { config: helpUrls } = usePrivateThemeConfig<Record<string, string>>(
    THEME_CONFIG_KEYS.HELP_RESOURCE_URLS,
  );
  const helpArticleUrl_compatibility = helpUrls?.['vcard-configurator-compatibility'];

  const sameLabelFields = Object.entries(map).filter(([_, fieldTKeys]) => fieldTKeys.length > 1);
  if (sameLabelFields.length === 0) return null;

  return (
    <Alert severity='info' sx={{ mb: 3 }}>
      {t('md.vcardConfigurator.samelLabelOutlookWarning')}
      {helpArticleUrl_compatibility && (
        <>
          {' '}
          <Typography
            underline='none'
            component={Link}
            href={helpArticleUrl_compatibility}
            target='_blank'
            fontSize={'1em'}
          >
            {t('learnMore')}.
          </Typography>
        </>
      )}
      <br />
      {sameLabelFields.map(([label, fieldTKeys]) => {
        const field = label.split('-')[0];
        if (!field) return null;

        const lastField = fieldTKeys[fieldTKeys.length - 1];
        const fieldTKeysWithoutLast = fieldTKeys.slice(0, -1);

        const fieldsString =
          fieldTKeysWithoutLast.map(tKey => `"${t(tKey)}"`).join(', ') +
          t('and') +
          `"${t(lastField)}"`;
        return (
          <>
            {(() => {
              switch (field) {
                case 'email':
                  return t('md.vcardConfigurator.sameLabelEmailWarning', { fields: fieldsString });
                case 'address':
                  return t('md.vcardConfigurator.sameLabelAddressWarning', {
                    fields: fieldsString,
                  });
                case 'link':
                  return t('md.vcardConfigurator.sameLabelLinkWarning', { fields: fieldsString });
                default:
                  return null;
              }
            })()}
            <br />
          </>
        );
      })}
    </Alert>
  );
};
