import TooltipInfo from '@/components/TooltipInfo';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Box, Chip, IconButton, Typography, styled } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useTranslation } from 'react-i18next';

export type EntityBoxRendererGenericProps = {
  onDelete?: () => void;
  onEdit?: () => void;
  chips?: string[];
  deleteTooltip?: string;
  editTooltip?: string;
  customActions?: React.ReactNode;
};

type Props = {
  icon?: React.ReactNode;
  title: string;
  subtitle?: string;
  isLink?: boolean;
} & EntityBoxRendererGenericProps;

/**
 * Generic component - EntityBoxRenderer
 * is used to render LinkBox, FileBox, AddressBox, etc.
 */
const EntityBoxRenderer = ({
  subtitle,
  title,
  icon,
  isLink,
  onDelete,
  onEdit,
  chips,
  customActions,
  deleteTooltip,
  editTooltip,
}: Props) => {
  const { t } = useTranslation();
  const editAction = (
    <IconButton onClick={onEdit} aria-label={t('ariaEdit', { name: title })} aria-haspopup='dialog'>
      <EditOutlined />
    </IconButton>
  );

  const deleteAction = (
    <IconButton
      onClick={onDelete}
      aria-label={t('ariaDelete', { name: title })}
      aria-haspopup='dialog'
    >
      <DeleteOutline />
    </IconButton>
  );

  const titleId = useId();
  return (
    <Wrapper>
      <Box mr={1.2}>{icon}</Box>
      <Box flex='1'>
        <Typography id={titleId} variant='body1'>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant='body2'
            sx={{ a: { color: 'inherit', textDecoration: 'underline' }, wordBreak: 'break-word' }}
            color='secondary'
          >
            {isLink ? (
              <a
                href={subtitle}
                target='_blank'
                rel='noreferrer noopener'
                aria-labelledby={titleId}
              >
                {subtitle}
              </a>
            ) : (
              subtitle
            )}
          </Typography>
        )}
      </Box>
      <Box display='flex' alignItems='center' gap='1rem'>
        {chips?.map((chip, index) => (
          <Chip key={index} label={chip} size='small' />
        ))}
        {/* Actions */}
        {customActions}
        {onEdit &&
          (editTooltip ? (
            <TooltipInfo text={editTooltip} onHover>
              {editAction}
            </TooltipInfo>
          ) : (
            editAction
          ))}
        {onDelete &&
          (deleteTooltip ? (
            <TooltipInfo text={deleteTooltip} onHover>
              {deleteAction}
            </TooltipInfo>
          ) : (
            deleteAction
          ))}
      </Box>
    </Wrapper>
  );
};

export default EntityBoxRenderer;

const Wrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper,
  borderRadius: '0.4rem',
  padding: '2rem 1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
