import {
  Box,
  BoxProps,
  Collapse,
  List,
  Popper,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NavItem } from '@/infrastructure/hoc/types';

const removeChildrenProperty = (item: NavItem) => {
  const { children: _, ...rest } = item;
  return rest;
};

const ChildrenWrapper = ({ sidebarCollapsed, children, anchorEl }) => {
  if (!sidebarCollapsed) {
    return <>{children}</>;
  }

  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement={'right-start'}
      popperOptions={{}}
      onResize={() => {}}
      onResizeCapture={() => {}}
    >
      <Box
        sx={{
          ml: '1.2rem',
          p: '0.8rem',
          width: 'max-content',
          borderRadius: '0.4rem',
        }}
        bgcolor='sidebar.main'
      >
        {children}
      </Box>
    </Popper>
  );
};

export const SingleNavItem = ({
  item,
  sidebarCollapsed,
  elemId,
  returnToId,
}: {
  item: NavItem;
  sidebarCollapsed: boolean;
  elemId?: string;
  returnToId?: string;
}) => {
  const location = useLocation();

  const { t } = useTranslation();

  const getIsSelected = (item: NavItem) =>
    location.pathname === item.to ||
    item.activeWhen?.some(path => matchPath(location.pathname, path));
  const isSelected = getIsSelected(item);
  const hasChildren = Boolean(item.children?.length);
  const isAnyChildSelected = item.children?.some(getIsSelected);
  const isChildrenVisible = isSelected || isAnyChildSelected;
  const [childrenListOpened, setChildrenListOpened] = useState(isChildrenVisible);

  const elemRef = useRef();
  const anchorEl = childrenListOpened && elemRef.current;

  useEffect(() => {
    sidebarCollapsed && setChildrenListOpened(false);
  }, [sidebarCollapsed]);

  const clickHandler = () => {
    item.onClick?.();
    if (hasChildren && !sidebarCollapsed) setChildrenListOpened(!childrenListOpened);
  };

  const tooltipTitle = sidebarCollapsed && !hasChildren && t(item.text);

  const isActive = sidebarCollapsed ? isChildrenVisible : isSelected;

  const childrenItems = hasChildren
    ? sidebarCollapsed
      ? [removeChildrenProperty(item), ...item.children]
      : item.children
    : null;

  const ownId = useRef(`lt-return-to-${Math.round(100000 * Math.random())}`);
  const firstChildId = useRef(`lt-first-child-${Math.round(100000 * Math.random())}`);

  return (
    <>
      <CustomTooltip title={tooltipTitle} placement='right' enterDelay={500}>
        <li
          onMouseEnter={() => sidebarCollapsed && setChildrenListOpened(true)}
          onMouseLeave={() => sidebarCollapsed && setChildrenListOpened(false)}
        >
          <Box
            component={Link}
            ref={elemRef}
            to={item.to}
            onClick={clickHandler}
            aria-flowto={hasChildren ? firstChildId.current : returnToId}
            id={elemId || ownId.current}
            sx={{
              ...baseStyles,
              ...(isActive ? activeStateStyles : inactiveStateStyles),
              ...(sidebarCollapsed ? { p: 0, alignItems: 'center', justifyContent: 'center' } : {}),
            }}
            aria-current={isActive ? 'page' : undefined}
            aria-label={t(item.ariaLabel || item.text)}
            {...(hasChildren && { 'aria-expanded': childrenListOpened })}
          >
            {item.icon && <item.icon />}
            {!sidebarCollapsed && (
              <>
                <Typography
                  sx={{ color: 'inherit', ml: item.icon && '1.6rem', flex: 1 }}
                  variant='body2'
                >
                  {t(item.text)}
                </Typography>
                {item.children && <>{childrenListOpened ? <ExpandLess /> : <ExpandMore />}</>}
              </>
            )}
          </Box>
          {hasChildren && (
            <ChildrenWrapper sidebarCollapsed={sidebarCollapsed} anchorEl={anchorEl}>
              <CollapsableList
                ml={!sidebarCollapsed && '1.6rem'}
                items={childrenItems}
                opened={childrenListOpened}
                sidebarCollapsed={false}
                firstElemId={firstChildId.current}
                lastElemReturnToId={ownId.current}
              />
            </ChildrenWrapper>
          )}
        </li>
      </CustomTooltip>
    </>
  );
};

type Props = BoxProps & {
  items: NavItem[];
  opened: boolean;
  sidebarCollapsed: boolean;
  firstElemId?: string;
  lastElemReturnToId?: string;
};

export const CollapsableList = ({
  items,
  opened,
  sidebarCollapsed,
  firstElemId,
  lastElemReturnToId,
  ...rest
}: Props) => {
  return (
    <Box {...rest}>
      <Collapse timeout='auto' in={opened} unmountOnExit>
        <List component='ul' sx={{ width: '100%' }} disablePadding>
          {items
            .filter(x => !x?.hide)
            .map((item, idx) => {
              return (
                <SingleNavItem
                  key={item.to}
                  item={item}
                  sidebarCollapsed={sidebarCollapsed}
                  elemId={idx === 0 ? firstElemId : undefined}
                  returnToId={lastElemReturnToId}
                />
              );
            })}
        </List>
      </Collapse>
    </Box>
  );
};

const activeStateStyles = {
  color: 'sidebarActive.contrastText',
  backgroundColor: 'sidebarActive.main',
};

const inactiveStateStyles = {
  color: 'sidebar.contrastText',
  backgroundColor: 'sidebar.main',
};

const baseStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '4rem',
  px: '1.6rem',
  borderRadius: '0.4rem',
  mb: '0.4rem',
  '&:hover': {
    ...activeStateStyles,
  },
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }: { theme: Theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.sidebar.main,
    color: theme.palette.sidebar.contrastText,
    borderRadius: '0.8rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.sidebar.main,
    marginLeft: '1rem',
  },
}));
