import { LtDialog } from '@/components';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import { ShareData } from '../utils';
import ShareList from './ShareList';
import { IconButton } from '@mui/material';

const ShareDialog = ({
  open,
  onClose,
  shareData,
}: {
  open: boolean;
  onClose: () => void;
  shareData: ShareData;
}) => {
  const { t } = useAppTranslation();
  const [subScreen, setSubScreen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState<string>(null);
  const closeHandler = () => {
    onClose?.();
    setTimeout(() => {
      setSubScreen(false);
      setDialogTitle(null);
    }, 200);
  };
  return (
    <LtDialog
      title={subScreen ? dialogTitle : t('analytics.share.dialogTitle')}
      titleIcon={
        subScreen ? (
          <IconButton aria-label={t('ariaGoBack')} onClick={() => setSubScreen(false)}>
            <ArrowBack />
          </IconButton>
        ) : null
      }
      open={!!open}
      onClose={closeHandler}
      cancelAction={{
        ttext: 'close',
        onClick: closeHandler,
        color: 'primary',
      }}
      style={{ borderRadius: 1 }}
    >
      <ShareList
        shareData={shareData}
        close={onClose}
        setSubScreen={setSubScreen}
        subScreen={subScreen}
        setDialogTitle={setDialogTitle}
      />
    </LtDialog>
  );
};

export default ShareDialog;
