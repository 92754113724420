import { routePaths } from '@/infrastructure/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import {
  BrushOutlined,
  ContactPageOutlined,
  DriveFileRenameOutlineOutlined,
  LocationCityOutlined,
  MarkEmailReadOutlined,
  PowerOutlined,
  QrCode,
  SupervisedUserCircleOutlined,
  TranslateOutlined,
} from '@mui/icons-material';
import { FEATURE } from '@/shared/constants';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import DownloadIcon from '@mui/icons-material/Download';
import { NavItem } from '@/infrastructure/hoc/types';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import SecurityIcon from '@mui/icons-material/Security';
import { useAppSelector } from '@/application/hooks';
import { Theme } from '@/shared/types/api';

export const useTeamSettingsItems = (): NavItem[] => {
  const theme = useAppSelector<Theme>(state => state.account?.theme);
  const { isUnitAdmin, isThemeAdmin, isEnterprise, isFeatureAllowed } = useTierInfo();

  const flag_emailTemplates = usePrivateFeatureFlag('emailTemplates');
  const flag_userDefinedTranslations = usePrivateFeatureFlag('userDefinedTranslations');
  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');
  const flag_IAM = usePrivateFeatureFlag('iam');

  return [
    {
      to: routePaths.MD.CUSTOMISESETTINGS.COMPANY_DATA,
      icon: LocationCityOutlined,
      text: 'organisationPageHeader',
      hide: isUnitAdmin,
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.EDIT_RIGHTS,
      icon: DriveFileRenameOutlineOutlined,
      text: 'editRights.title',
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.PROFILE_DESIGN,
      icon: BrushOutlined,
      text: 'profileDesign',
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.SHARING_DESIGN,
      icon: QrCode,
      text: 'sharingDesign.shortTitle',
      hide: isUnitAdmin,
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.EMAIL_TEMPLATES,
      icon: MarkEmailReadOutlined,
      text: 'emailTemplatesPageHeader',
      hide: isUnitAdmin || !flag_emailTemplates,
      badge: !isFeatureAllowed(FEATURE.EMAIL_TEMPLATES) ? 'Enterprise' : undefined,
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.UNITS,
      icon: SupervisedUserCircleOutlined,
      text: 'unitSettings.title',
      hide:
        !isFeatureAllowed(FEATURE.UNITS) ||
        isUnitAdmin ||
        ['allianz-lebensversicherungs-ag'].includes(theme?.name || ''),
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.TRANSLATIONS,
      icon: TranslateOutlined,
      text: 'translations.title',
      hide:
        !flag_userDefinedTranslations ||
        ['allianz-lebensversicherungs-ag'].includes(theme?.name || ''),
      badge: !isFeatureAllowed(FEATURE.USER_DEFINED_TRANSLATIONS) ? 'Enterprise' : undefined,
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.CONTACT_FORM,
      icon: ContactPageOutlined,
      text: 'leadForm',
      hide: isUnitAdmin || ['allianz-lebensversicherungs-ag'].includes(theme?.name || ''),
    },

    {
      to: routePaths.MD.INTEGRATIONS,
      icon: PowerOutlined,
      text: 'integrations.title',
      hide: isUnitAdmin || (!flag_IAM && !flag_crmIntegration),
      badge: !isEnterprise ? 'Addon' : undefined,
      children: [
        {
          to: routePaths.IDP.THEME_IDPS,
          activeWhen: [
            routePaths.IDP.LIST,
            routePaths.IDP.THEME_IDPS,
            routePaths.IDP.THEME_SSO_SETTINGS,
            routePaths.IDP.THEME_IDP_SETTINGS,
          ],
          icon: SecurityIcon,
          text: 'idp.identityProvider',
          hide: !isThemeAdmin || !flag_IAM,
        },
        {
          to: routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION,
          icon: ElectricalServicesIcon,
          text: 'crmIntegration.title',
          hide: !isThemeAdmin || !flag_crmIntegration,
        },
      ],
    },
    {
      to: routePaths.MD.CUSTOMISESETTINGS.EXPORT,
      icon: DownloadIcon,
      text: 'export.title',
      hide: !isUnitAdmin && !isThemeAdmin,
    },

    // {
    //   to: routePaths.CUSTOMISESETTINGS.CONTACT_FORM,
    //   icon: <MdOutlineGppGood />,
    //   text: 'Identity Provider',
    // },
  ];
};
