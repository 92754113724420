import { Box, Divider, Typography } from '@mui/material';
import { getTranslatedField, isAddress1Field } from '../utils';
import { Account } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import BioTextField from './BioTextField';
import { AccountBioFields } from '@/shared/constants';

const AddressWrapper = ({ children }) => {
  return (
    <Box display={'flex'} width={'100%'} flexWrap={'wrap'} gap={2} justifyContent={'center'}>
      {children}
    </Box>
  );
};
const AddressRenderer = ({
  addressFields,
  isFieldBlocked,
}: {
  addressFields: Array<keyof AccountBioFields>;
  isFieldBlocked: (field: keyof Account) => boolean;
}) => {
  const { t } = useAppTranslation();

  const account = useAppSelector<Account>(state => state.account);

  const isDisabled = (field: keyof Account) =>
    Boolean(
      field.startsWith('address1')
        ? !!account?.addressTemplate1Id
        : field.startsWith('address2')
        ? !!account?.addressTemplate2Id
        : null,
    ) || isFieldBlocked(field);

  return (
    <>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant='h3' component='h2' color='text.secondary' mb={2}>
        {isAddress1Field(addressFields[0]) ? t('address1') : t('address2')}
      </Typography>
      <AddressWrapper>
        {addressFields.map(fieldName => (
          <BioTextField
            fieldName={fieldName}
            ariaLabel={`${
              isAddress1Field(addressFields[0]) ? t('address1') : t('address2')
            } - ${getTranslatedField(fieldName)}`}
            readonly={isDisabled(fieldName)}
          />
        ))}
      </AddressWrapper>
    </>
  );
};

export default AddressRenderer;
