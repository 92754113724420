import { DeepPartial } from '@/shared/types/util';
import { ThemeProvider, useTheme, Theme } from '@mui/system';
import deepmerge from 'deepmerge';
import { PropsWithChildren, useMemo } from 'react';

interface Props {
  overrides: DeepPartial<Theme>;
}

export default function ThemeOverride({ children, overrides }: PropsWithChildren<Props>) {
  const baseTheme = useTheme();

  const newTheme = useMemo(() => deepmerge(baseTheme, { ...overrides }), [baseTheme, overrides]);

  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
}
