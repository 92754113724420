import { useTranslation } from 'react-i18next';
import LtDialog from '../LtDialog';
import { Address } from '@/shared/types/api';
import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

export type AddEditAddressDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (link: Partial<Address>) => void;
  initialValues?: Partial<Address>;
  loading?: boolean;
  showDefaultCheckbox?: boolean;
};

/**
 * On save always produces Address type object, but the dialog can be used for Address and AddressTemplates as well
 * ToDo: remove 'isDefault' logic and UI when introducing default addresses
 */
export const AddEditAddressDialog = ({
  onClose,
  open,
  initialValues,
  onSave,
  loading,
  showDefaultCheckbox,
}: AddEditAddressDialogProps) => {
  const { notify } = useLtNotifications();
  const [values, setValues] = useState<Partial<Address>>(initialValues || {});
  const isEdit = Boolean(values.id);
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialValues) return;
    setValues(initialValues);
  }, [initialValues]);

  /** need timeout to keep smooth dialog close/open animation */
  useEffect(() => {
    let timeoutId;
    if (!open) {
      timeoutId = setTimeout(() => setValues({}), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  const generateTextFieldProps = field => ({
    label: t(`address.${field}`),
    value: values[field] || '',
    onChange: e => setValues(prev => ({ ...prev, [field]: e.target.value })),
    fullWidth: true,
  });

  const handleSave = () => {
    if (
      !values.addressee &&
      !values.addressLine1 &&
      !values.addressLine2 &&
      !values.postCode &&
      !values.city &&
      !values.country &&
      !values.label
    ) {
      notify.error(t('addressesTab.emptyAddressFieldsError'));
      return;
    }

    onSave(values);
  };

  return (
    <LtDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      saveAction={{
        text: t('save'),
        onClick: handleSave,
        loading,
      }}
      title={isEdit ? t('editAddress') : t('addAddress')}
      size='md'
      withActionDivider
    >
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField {...generateTextFieldProps('label')} />
        <TextField {...generateTextFieldProps('addressee')} />
        <TextField {...generateTextFieldProps('addressLine1')} />
        <TextField {...generateTextFieldProps('addressLine2')} />

        <Box display='flex' gap={2}>
          <TextField {...generateTextFieldProps('postCode')} />
          <TextField {...generateTextFieldProps('city')} />
        </Box>
        <TextField {...generateTextFieldProps('country')} />
        {showDefaultCheckbox && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(values?.isDefault)}
                  onChange={() => setValues(prev => ({ ...prev, isDefault: !prev.isDefault }))}
                />
              }
              label={t('addressesTab.makeAddressDefault')}
            />
          </Box>
        )}
      </Box>
    </LtDialog>
  );
};
