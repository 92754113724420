import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { AccountImageState, HierarchyLevel, ProfileDesignConfig, Theme } from '@/shared/types/api';

import { Account } from '@/shared/types/api';
import ButtonColors from './button-colors';
import Images from './images';
import ImageUpload from './ImageUpload';
import {
  FEATURE,
  THEME_CONFIG_KEYS,
  THEME_CONTACT_EXCHANGE_FLOWS,
  THEME_LINKS_FILES_POSITIONS,
} from '@/shared/constants';
import withNav from '@/infrastructure/hoc/withNav';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import FeatureTeaser from '../../teaser/featureTeaser';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import {
  deleteThemeConfig,
  fetchThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import { useDispatch } from 'react-redux';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { fetchUserData } from '@/application/actions/account';
import { SettingsContext } from '../Settings/SettingsWrapper/context';
import { SettingsWrapper } from '../Settings/SettingsWrapper';
import AdditionalLogoUpload from './AdditionalLogoUpload';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { updateProfileDesign } from '@/infrastructure/apis/md/customization';
import {
  getBannerImageFromProfileDesign,
  getLogoHeaderUrlFromProfileDesign,
  getProfileImageFromProfileDesign,
} from '@/shared/business-logic/features/profile/getter';
import { FeatureSetGuard, PageContainer } from '@/components';
import { useAppSelector } from '@/application/hooks';
import { Section } from './Section';
import { ContactsForm } from './contactsForm';
import { PolicyForm } from './policyForm';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { BoxForm } from './box-form';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import TooltipInfo from '@/components/TooltipInfo';
import { LtTabsProps } from '@/components/LtTabs';
import { DefaultContent } from '../DefaultContent';
import { useUnitsForSettings } from '@/components/UnitSelector';
import { ProfileDesignPageWrapper } from './ProfileDesignPageWrapper';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { ContentTemplates } from '../ContentTemplates';
import { ContentCopy, EditOutlined, Public, ContactPhoneOutlined } from '@mui/icons-material';
import { VCardConfigurator } from '../VCardConfigurator';

const RadioGroupRenderer = ({ type, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <Box mb={2.4}>
      <FormControl>
        <RadioGroup value={value} onChange={({ target: { value } }) => onChange(value)} row>
          {Object.values(THEME_LINKS_FILES_POSITIONS).map(pos => (
            <FormControlLabel
              value={pos}
              control={<Radio />}
              label={t(`md.profileDesign.${type}.` + pos)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export interface ProfileDesignConfigLocal
  extends Omit<
    ProfileDesignConfig,
    | 'defaultBannerImageUrl'
    | 'defaultProfileImageUrl'
    | 'logoHeaderUrl'
    | 'bannerImgOptions'
    | 'logoImgOptions'
  > {
  defaultBannerImageUrl: string | File;
  defaultProfileImageUrl: string | File;
  logoHeaderUrl: string | File;
  defaultBannerImageState: AccountImageState;
  defaultProfileImageState: AccountImageState;
  logoHeaderState: AccountImageState;
  bannerImgOptions: (string | File)[];
  logoImgOptions: (string | File)[];
}

type ProfileDesignState = Omit<ProfileDesignConfigLocal, 'bannerImgOptions' | 'logoImgOptions'>;

export type Image = {
  file: File | null;
  url: string;
  fileName?: string;
};

const mapImageUrls = (urls: string[]) => {
  return urls.map(x => ({
    file: null,
    url: x,
    fileName: x,
  }));
};

const ProfileDesign = () => {
  const {
    selectedUnit,
    deleteButtonIsVisible,
    setSettingsHierarchyLevel,
    deleteClickHandler,
    setSettingsLoading,
    settingsHierarchyLevel,
  } = useContext(SettingsContext);
  const { isFeatureAllowed } = useTierInfo();

  const isGlobalDataFeautureAllowed = isFeatureAllowed(FEATURE.GLOBAL_DATA);
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently, logout } = useAuth0();

  const account: Account = useAppSelector(state => state.account);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const [profileDesign, setProfileDesign] = useState<ProfileDesignState>();
  const [bannerImgOptions, setBannerImgOptions] = useState<Image[]>([]);
  const [logoImgOptions, setLogoImgOptions] = useState<Image[]>([]);
  const selectedUnitId = selectedUnit?.id || null;

  const { refetch: refetchGlobalProfileDesign } = useProfileDesign();
  const { mutateProfileDesignForUnit, deleteProfileDesignForUnit } =
    useProfileDesignForUnits(selectedUnitId);

  const { setIsUnsaved, withUnsavedSetter } = useUnsavedStatusSetter();

  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  const fetchProfileDesign = useCallback(async () => {
    try {
      const {
        value,
        meta: { hierarchyLevel },
      } = await fetchThemeConfig<ProfileDesignConfig>(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.PROFILE_DESIGN,
        fetchThemeLevel: true,
        unitId: selectedUnitId,
      });
      const {
        bannerImgOptions: _bannerImgOptions,
        logoImgOptions: _logoImgOptions,
        ..._profileDesign
      } = value;
      setProfileDesign(_profileDesign);
      setBannerImgOptions(mapImageUrls(_bannerImgOptions));
      setLogoImgOptions(mapImageUrls(_logoImgOptions));
      setSettingsHierarchyLevel(hierarchyLevel);
    } catch (error) {
      toast.error(t('error.general'));
    }
  }, [getAccessTokenSilently, t, selectedUnitId, setSettingsHierarchyLevel, toast]);

  useEffect(() => {
    const fetchData = async () => {
      setSettingsLoading(true);
      await fetchProfileDesign();
      setSettingsLoading(false);
    };
    fetchData();
  }, [fetchProfileDesign, setSettingsLoading]);

  const handleImageChange = withUnsavedSetter(
    (
      field: 'banner' | 'profile' | 'logo',
      value: { val: string | File; state: AccountImageState },
    ) => {
      const newValues =
        field === 'banner'
          ? {
              defaultBannerImageUrl: value.val,
              defaultBannerImageState: value.state,
            }
          : field === 'profile'
          ? {
              defaultProfileImageUrl: value.val,
              defaultProfileImageState: value.state,
            }
          : {
              logoHeaderUrl: value.val,
              logoHeaderState: value.state,
            };

      setProfileDesign(prevState => ({
        ...prevState,
        ...newValues,
      }));
    },
  );

  const handleChange = withUnsavedSetter(
    (
      field: keyof ProfileDesignConfig,
      value: string | boolean | File | Array<string | boolean | File>,
    ) => {
      setProfileDesign(prevState => ({
        ...prevState,
        [field]: value,
      }));
    },
  );

  const handleSave = async () => {
    setIsSaving(true);

    let ids = { id: account.id, uniqueId: theme?.themeInternal.uniqueId };

    try {
      const payload = {
        ...profileDesign,
        showCopyright: !profileDesign.legalName ? false : profileDesign.showCopyright,
        bannerImgOptions: bannerImgOptions.map(x => (x.file ? x.file : x.url)),
        logoImgOptions:
          profileDesign.logoHeaderState === AccountImageState.DELETED
            ? logoImgOptions.map(x => (x.file ? x.file : x.url))
            : [],
      };
      const profileDesignResponse = await updateProfileDesign(
        ids,
        payload,
        getAccessTokenSilently,
        selectedUnitId,
      );

      setIsUnsaved(false);
      fetchProfileDesign();
      refetchGlobalProfileDesign();
      mutateProfileDesignForUnit(profileDesignResponse);
      dispatch(fetchUserData(getAccessTokenSilently, () => logout(), true));
      toast.success(t('changesSaved'));
    } catch {
      toast.error(t('unexpectedError'));
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      await deleteThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.PROFILE_DESIGN,
        unitId: selectedUnitId,
        withDeleteUnitConfig: true,
      });
      await fetchProfileDesign();
      deleteProfileDesignForUnit();
      toast.success(t('changesSaved'));
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  let sectionIdx = 1;

  const getTitle = (tText: string) => {
    return sectionIdx++ + '. ' + t(tText);
  };

  if (!profileDesign) return null;

  return (
    <>
      <Section title={getTitle('boxForm')} showDivider mt={3} id='box-form-section'>
        <BoxForm
          aria-label={t('boxForm')}
          value={profileDesign.boxStyle}
          onChange={value => handleChange('boxStyle', value)}
        />
      </Section>
      <Section title={getTitle('buttonColors')} showDivider>
        <ButtonColors
          saveColor={profileDesign.leadGenColor}
          filesColor={profileDesign.fileBoxColor}
          genericWebsiteColor={profileDesign.genericWebsiteColor}
          setSaveColor={value => handleChange('leadGenColor', value)}
          setFilesColor={value => handleChange('fileBoxColor', value)}
          setGenericWebsiteColor={value => handleChange('genericWebsiteColor', value)}
        />
      </Section>
      <Section title={getTitle('images')} showDivider>
        <Images
          key={`images-${selectedUnitId || ''}`}
          bannerImage={getBannerImageFromProfileDesign(profileDesign as ProfileDesignConfig)}
          profileImage={getProfileImageFromProfileDesign(profileDesign as ProfileDesignConfig)}
          logoHeaderImage={getLogoHeaderUrlFromProfileDesign(profileDesign as ProfileDesignConfig)}
          bannerImageState={profileDesign.defaultBannerImageState}
          profileImageState={profileDesign.defaultProfileImageState}
          logoHeaderImageState={profileDesign.logoHeaderState}
          onBannerChange={value => handleImageChange('banner', value)}
          onProfileImageChange={value => handleImageChange('profile', value)}
          onLogoChange={value => handleImageChange('logo', value)}
        />
      </Section>
      <Section
        title={getTitle('additionalCover')}
        subTitle={t('md.profileDesign.additionalCoverImagesSize')}
        subHeader={t('*optional')}
        tooltipText={t('md.profileDesign.uploadImageTooltip')}
        showDivider
      >
        <ImageUpload
          key={`cover-images-${selectedUnitId || ''}`}
          images={bannerImgOptions}
          onChange={setBannerImgOptions}
        />
      </Section>
      <Section
        title={getTitle('md.profileDesign.additionalLogosTitle')}
        subTitle={t('md.profileDesign.additionalLogoImagesSize')}
        tooltipText={t('md.profileDesign.additionalLogosTooltip')}
        subHeader={t('*optional')}
        showDivider
      >
        <AdditionalLogoUpload
          images={logoImgOptions}
          onChange={setLogoImgOptions}
          isHeaderLogoDefined={Boolean(profileDesign.logoHeaderUrl)}
        />
      </Section>

      <Section title={getTitle('contactsForm')} showDivider>
        <ContactsForm
          leadGenFormShown={profileDesign.leadGenFormShown}
          setLeadGenFormShown={value => handleChange('leadGenFormShown', value)}
          leadGenFormAsPopup={
            profileDesign.contactExchangeFlow === THEME_CONTACT_EXCHANGE_FLOWS.POPUP
          }
          setLeadGenFormAsPopup={value =>
            handleChange(
              'contactExchangeFlow',
              value
                ? THEME_CONTACT_EXCHANGE_FLOWS.POPUP
                : THEME_CONTACT_EXCHANGE_FLOWS.BOTTOM_CARD_STATIC,
            )
          }
        />
      </Section>

      <Section
        title={getTitle('md.profileDesign.themeFiles.title')}
        tooltipText={t('md.profileDesign.themeFiles.tooltip')}
        showDivider
      >
        {isGlobalDataFeautureAllowed ? (
          profileDesign.themeFilesPosition && (
            <RadioGroupRenderer
              type='themeFilesPosition'
              value={profileDesign.themeFilesPosition}
              onChange={value => handleChange('themeFilesPosition', value)}
            />
          )
        ) : (
          <FeatureTeaser text={t('upgradeTeaser.feature.themeLinks')} fullWidth />
        )}
      </Section>

      <Section
        title={getTitle('footerPageHeader')}
        tooltipText={t('md.customizationFooter.mainTooltip')}
      >
        <PolicyForm
          privacyUrl={profileDesign.privacyUrl || ''}
          imprintUrl={profileDesign.imprintUrl || ''}
          onChange={(field, value) => handleChange(field, value)}
        />
      </Section>
      <LtActionButtonBar
        saveAction={{
          loading: isSaving,
          onClick: handleSave,
        }}
        deleteAction={
          deleteButtonIsVisible && {
            onClick: () => deleteClickHandler(handleDelete),
            text: t(
              settingsHierarchyLevel === HierarchyLevel.Unit ? 'deleteSettings' : 'resetSettings',
            ),
          }
        }
        customActionsPost={[
          {
            text: t('preview'),
            onClick: () => window.open(`/${account.username}?forceProfile=true`, '_blank'),
            variant: 'outlined',
            color: 'primary',
          },
        ]}
        customElemsPost={[
          <Box sx={{ marginLeft: '-1rem' }}>
            <TooltipInfo
              key='tooltip'
              text={t('md.profileDesign.previewTooltip')}
              icon={{ color: 'text.primary' }}
            />
          </Box>,
        ]}
      />
    </>
  );
};

const Page = () => {
  const { t } = useTranslation();
  const { selectedUnit, units, setSelectedUnit } = useUnitsForSettings();

  const { isUnitAdmin } = useTierInfo();

  const tabs: LtTabsProps = {
    orientation: 'vertical',
    tabs: [
      {
        value: 'generalDesign',
        label: t('generalDesign'),
        component: (
          <ProfileDesignPageWrapper
            units={units}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            title={t('generalDesign')}
            requiredFeature={FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN}
          >
            <SettingsWrapper
              requiredFeature={FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN}
              teaserContent={{
                text: t('upgradeTeaser.feature.unitHierarchies.profileDesign'),
              }}
              settingKey={'profile-design'}
              selectedUnit={selectedUnit}
            >
              <ProfileDesign />
            </SettingsWrapper>
          </ProfileDesignPageWrapper>
        ),
        icon: <EditOutlined />,
        iconPosition: 'start',
      },
      {
        value: 'defaultContent',
        label: t('defaultContent'),
        component: (
          <FeatureSetGuard
            feature={FEATURE.GLOBAL_DATA}
            text={t('upgradeTeaser.feature.globalData')}
          >
            <ProfileDesignPageWrapper
              units={units}
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              title={t('defaultContent')}
              requiredFeature={FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN}
            >
              <DefaultContent selectedUnit={selectedUnit} />
            </ProfileDesignPageWrapper>
          </FeatureSetGuard>
        ),
        icon: <Public />,
        iconPosition: 'start',
      },
      {
        value: 'contentTemplates',
        label: t('contentTemplate'),
        hidden: isUnitAdmin,
        component: (
          <FeatureSetGuard
            feature={FEATURE.DATA_TEMPLATES}
            text={t('upgradeTeaser.feature.dataTemplates')}
          >
            <Box px={2} pb={2}>
              <ContentTemplates />
            </Box>
          </FeatureSetGuard>
        ),
        icon: <ContentCopy />,
        iconPosition: 'start',
      },
      {
        value: 'vcardConfigurator',
        label: t('md.vcardConfigurator.title'),
        component: (
          <FeatureSetGuard
            feature={FEATURE.VCARD_CONFIG}
            text={t('upgradeTeaser.feature.vcardConfig')}
          >
            <ProfileDesignPageWrapper
              units={units}
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              title={t('md.vcardConfigurator.title')}
              requiredFeature={FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN}
            >
              <SettingsWrapper
                requiredFeature={FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN}
                teaserContent={{
                  text: t('upgradeTeaser.feature.unitHierarchies.profileDesign'),
                }}
                settingKey={'vcard-config'}
                selectedUnit={selectedUnit}
              >
                <VCardConfigurator />
              </SettingsWrapper>
            </ProfileDesignPageWrapper>
          </FeatureSetGuard>
        ),
        icon: <ContactPhoneOutlined />,
        iconPosition: 'start',
      },
    ],
  };

  return <PageContainer maxWidth='m' whiteCardWrapper sx={{ mb: 12.5, p: 0 }} tabs={tabs} />;
};

export default withNav(
  Page,
  {
    tTitle: 'profileDesign',
  },
  {},
);
