import withNav from '../../../../infrastructure/hoc/withNav';
import customDomainImage from '../../../images/integrations/custom-domain.png';
import ssoImage from '../../../images/integrations/sso.png';
import activeDirectoryImage from '../../../images/integrations/active-directory.png';
import crmImage from '../../../images/integrations/crm.png';
import { useHistory } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { LtDialog, PageContainer } from '@/components';
import { Box, styled } from '@mui/material';
import LtActionCard from '@/components/LtActionCard';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { East } from '@mui/icons-material';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { useState } from 'react';

const Integrations = () => {
  const history = useHistory();
  const { t } = useAppTranslation();
  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');
  const flag_iam = usePrivateFeatureFlag('iam');

  const { isFeatureAllowed } = useTierInfo();

  const customDomainAllowed = isFeatureAllowed(FEATURE.CUSTOM_DOMAIN);
  const ssoAllowed = isFeatureAllowed(FEATURE.SSO);
  const crmAllowed = isFeatureAllowed(FEATURE.CRM_INTEGRATION);

  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const [customDomainPopupVisible, setCustomDomainPopupVisible] = useState(false);

  return (
    <>
      {upgradeRequestPopup}
      <LtDialog
        open={customDomainPopupVisible}
        title={t('integrations.customDomain.title')}
        onClose={() => setCustomDomainPopupVisible(false)}
      >
        {t('integrations.customDomain.popupDescription', { email: 'support@lemontaps.com' })}
      </LtDialog>

      <PageContainer maxWidth={'m'}>
        <StyledWrapper>
          <LtActionCard
            image={{ src: customDomainImage, alt: 'custom-domain-image' }}
            title={t('integrations.customDomain.title')}
            titleTypographyProps={{ component: 'h2' }}
            body={t('integrations.customDomain.description')}
            singleAction={{
              text: t('integrations.connect'),
              onClick: customDomainAllowed
                ? () => setCustomDomainPopupVisible(true)
                : upgradeAction,
              endIcon: <East />,
            }}
          />
          <LtActionCard
            image={{ src: ssoImage, alt: 'sso-image' }}
            title={t('integrations.sso.title')}
            titleTypographyProps={{ component: 'h2' }}
            body={t('integrations.sso.description')}
            singleAction={{
              text: t('integrations.connect'),
              onClick:
                flag_iam && ssoAllowed
                  ? () => history.push(routePaths.IDP.THEME_IDPS)
                  : upgradeAction,
              endIcon: <East />,
            }}
          />
          <LtActionCard
            image={{ src: activeDirectoryImage, alt: 'active-directory-image' }}
            title={t('integrations.activeDirectory.title')}
            titleTypographyProps={{ component: 'h2' }}
            body={t('integrations.activeDirectory.description')}
            singleAction={{
              text: t('integrations.connect'),
              onClick: upgradeAction,
              endIcon: <East />,
            }}
          />
          <LtActionCard
            image={{ src: crmImage, alt: 'crm-image' }}
            title={t('integrations.crm.title')}
            titleTypographyProps={{ component: 'h2' }}
            body={t('integrations.crm.description')}
            singleAction={{
              text: t('integrations.configure'),
              onClick:
                flag_crmIntegration && crmAllowed
                  ? () => history.push(routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION)
                  : upgradeAction,
              endIcon: <East />,
            }}
          />
        </StyledWrapper>
      </PageContainer>
    </>
  );
};

export default withNav(
  Integrations,
  {
    tTitle: 'integrations.title',
  },
  {},
);

const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '2rem',
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('1000')]: {
    gridTemplateColumns: '1fr',
  },
}));
