import { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { saveLink, clearApiResponse } from '@/application/actions/edit-profile';
import { clearApiResponse as clearEmployeeApiResponse } from '@/application/actions/md/profiles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { removePrefix } from '@/util';
import { trackPrivateEvent } from '@/infrastructure/apis/analytics';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '@/shared/constants/global';
import { LINK_TYPE_MODES } from '@/shared/constants';
import { Link } from '@/shared/types/api';
import { LtDialog } from '@/components';
import { Alert, Box, Button, IconButton, TextField, ThemeProvider, styled } from '@mui/material';
import { ArrowBack, DeleteOutline } from '@mui/icons-material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';

interface Props {
  item: Link;
  mode: string;
  employeeId?: string;
  onCloseClick: () => void;
  onBackClick: () => void;
  onDeleteClick: () => void;
  isCompanydataSettings?: boolean;
  onSaveCompanydataSettings?: (linkItem: Link) => void;
  userLinks?: Array<Link>;
  setUserLinks?: Dispatch<SetStateAction<Array<Link>>>;
  genericWebsiteColor: string;
}

const CreateEditLinkPopup = (props: Props): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const apiResponse = useAppSelector(state =>
    props.employeeId ? state.md.apiResponse : state.editProfile.apiResponse,
  );
  const [isResponseUpdated, setResponseUpdate] = useState(false);
  const [linkName, setLinkName] = useState(props.item.name);
  const [linkText, setLinkText] = useState<string>(
    props.mode === LINK_TYPE_MODES.LINK
      ? removePrefix(props.item.link, 'https://')
      : props.item.link,
  );
  const [errorMsg, setErrorMsg] = useState('');

  const isUsername = props.mode === LINK_TYPE_MODES.USERNAME;
  const isPhone = props.mode === LINK_TYPE_MODES.PHONE;
  const isCreate = props.item.id === 0;
  const backgroundCss =
    props.item?.linkType.backgroundCss || props.genericWebsiteColor || '#152570';
  const iconUrl = props.item?.linkType.iconUrl;

  const onTextBlur = (fieldName: string) => {
    if (fieldName === 'linkName') {
      if (errorMsg === t('nameLinkerror') && linkName.length > 1) setErrorMsg('');
    } else if (fieldName === 'linkText') {
      if (
        (errorMsg === t('enterLinkerror') || errorMsg === t('enterUsernameerror')) &&
        linkText.length > 1
      )
        setErrorMsg('');
    }
  };

  const onSaveClick = () => {
    if (linkName.length <= 1) {
      setErrorMsg(t('nameLinkerror'));
    } else if (linkText.length <= 1) {
      setErrorMsg(t('enterMessage', { subject: props.mode }));
    } else {
      setErrorMsg('');

      const linkItem = {
        ...props.item,
        name: linkName,
        link: linkText,
        order: props.item.order,
        linkTypeId: props.item.linkType.id,
      };

      if (props.isCompanydataSettings) {
        // @ts-ignore:next-line
        props.onSaveCompanydataSettings({ ...props.item, name: linkName, link: linkText });
      } else if (props.employeeId) {
        if (isCreate) {
          linkItem.id = Math.floor(Math.random() * 1000);
          props.setUserLinks([...props.userLinks, linkItem]);
        } else {
          let index = props.userLinks?.findIndex((link: Link) => link.id === linkItem.id);
          if (index !== -1) props.userLinks[index] = linkItem;
        }
      } else {
        dispatch(
          saveLink(linkItem, getAccessTokenSilently, linkId => {
            trackPrivateEvent(
              getAccessTokenSilently,
              PRIVATE_EVENTS.PROFILE_EDIT,
              {
                type: PRIVATE_META_TYPES.LINK_ADD,
              },
              null,
              linkId,
            );
          }),
        );
      }
      props.onCloseClick();
    }
  };

  const checkForResponse = () => {
    if (apiResponse && apiResponse.isSuccess === false && isResponseUpdated === false) {
      setErrorMsg(apiResponse.error.message);
      setResponseUpdate(true);
      if (props.employeeId) dispatch(clearEmployeeApiResponse());
      else dispatch(clearApiResponse());
    }
  };

  const getHeaderText = (): string => {
    return isCreate ? t('createLink') : t('editLink');
  };

  const { theme: muiTheme } = useMuiTheme();

  const isDesktop = useIsDesktopMui();

  return (
    <>
      {checkForResponse()}
      <ThemeProvider theme={muiTheme}>
        <LtDialog
          open
          onClose={props.onCloseClick}
          onCancel={props.onCloseClick}
          title={getHeaderText()}
          onSave={() => !props.item.linkTemplateId && onSaveClick()}
          deleteAction={
            !isCreate &&
            isDesktop && {
              onClick: props.onDeleteClick,
              text: t('deleteLink'),
            }
          }
          titleIcon={
            isCreate && (
              <IconButton onClick={() => props.onBackClick()}>
                <ArrowBack />
              </IconButton>
            )
          }
        >
          <Box width={{ md: '70%', xs: '100%' }} m='auto'>
            <LinkWrapper style={{ background: backgroundCss }}>
              <img src={iconUrl} alt='link icon' height='6.2rem' />
            </LinkWrapper>
            <Box mt={5}>
              {errorMsg && (
                <Alert variant='filled' severity='error'>
                  {errorMsg}
                </Alert>
              )}

              <TextField
                name='linkName'
                placeholder={t('nameLink')}
                value={linkName}
                onChange={e => setLinkName(e.target.value)}
                onBlur={() => onTextBlur('linkName')}
                inputProps={{ maxLength: 30 }}
                fullWidth
                disabled={!!props.item.linkTemplateId}
              />
              <TextField
                name='linkText'
                value={linkText}
                placeholder={
                  isUsername ? t('enterUsername') : isPhone ? t('enterPhone') : t('enterLink')
                }
                onChange={e => setLinkText(removePrefix(e.target.value, 'https://'))}
                onBlur={() => onTextBlur('linkText')}
                inputProps={(isUsername || isPhone) && { maxLength: 30 }}
                InputProps={{
                  startAdornment: isUsername ? (
                    <Box mr={0.5}>@</Box>
                  ) : isPhone ? (
                    <Box mr={0.5}>+</Box>
                  ) : null,
                }}
                fullWidth
                disabled={!!props.item.linkTemplateId}
                sx={{ mt: 2 }}
              />
            </Box>
          </Box>
          {!isDesktop && (
            <Box m='auto' width='max-content' mb='-2rem' mt='3rem'>
              <Button variant='outlined' color='error' startIcon={<DeleteOutline />}>
                {t('deleteLink')}
              </Button>
            </Box>
          )}
        </LtDialog>
      </ThemeProvider>
    </>
  );
};

export default CreateEditLinkPopup;

const LinkWrapper = styled('div')({
  display: 'grid',
  placeItems: 'center',
  borderRadius: '10px',
  color: 'white',
  fontSize: '1.5rem',
  padding: '1.5rem',
  cursor: 'pointer',
  maxWidth: '6rem',
  margin: '0 auto 1.5rem',
  img: {
    height: '25px',
  },
});
