import { dispatchTypes } from './index';
import {
  checkDomain,
  getOwnAccount,
  postActionPreference,
} from '../../infrastructure/apis/account';
import { Auth0ContextInterface, LogoutOptions } from '@auth0/auth0-react';
import { Account, ActionPreferences } from '../../shared/types/api';

export const checkDomainAction =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async (dispatch: any) => {
    try {
      await checkDomain(getAccessTokenSilently);
      dispatch({
        type: dispatchTypes.LOGIN.DOMAIN_VERIFIED,
      });
    } catch (error) {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.error?.code === 'domainMismatch'
      ) {
        dispatch({
          type: dispatchTypes.LOGIN.DOMAIN_MISMATCH,
          domain: error.response.data.error.data.domain,
        });
      }
    }
  };

export const fetchUserData =
  (
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    logoutAuth0: (options?: LogoutOptions) => void,
    silent: boolean = false,
  ) =>
  async (dispatch: any) => {
    if (!silent) dispatch({ type: dispatchTypes.LOGIN.CHECKLOGIN });
    try {
      let response = await getOwnAccount(getAccessTokenSilently);

      const result = response.data;

      if (result.isSuccess) {
        let account: Account = result.data;

        dispatch({
          type: dispatchTypes.LOGIN.DOMAIN_VERIFIED,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEACCOUNT,
          account: account,
        });

        dispatch({
          type: dispatchTypes.LOGIN.LOGINSUCESS,
          apiResponse: result,
        });
      } else {
        dispatch({
          type: dispatchTypes.LOGIN.LOGINERROR,
          apiResponse: result,
        });
        logoutAuth0();
      }
    } catch (error) {
      // only initiate a logout if error wasn't caused by domain mismatch
      // in the latter case, a redirect will be initiated
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.error?.code === 'domainMismatch'
      ) {
        dispatch({
          type: dispatchTypes.LOGIN.DOMAIN_MISMATCH,
          domain: error.response.data.error.data.domain,
        });
      } else {
        dispatch({ type: dispatchTypes.LOGIN.LOGINERROR, apiResponse: error });
        logoutAuth0();
      }
    }
  };

export const reFetchAccountInfo =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async (dispatch: any) => {
    let { data: { data: account, isSuccess } = {} } = await getOwnAccount(getAccessTokenSilently);

    if (isSuccess) {
      dispatch({
        type: dispatchTypes.COMMON.UPDATEACCOUNT,
        account: account,
      });
    }
  };

export const logout = () => async (dispatch: any) => {
  dispatch({
    type: dispatchTypes.COMMON.USERLOGOUT,
  });
};

export const updateActionPreferences =
  (
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    partialPrefs: Partial<ActionPreferences>,
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: dispatchTypes.COMMON.UPDATEFIELD,
        field: 'actionPreferences',
        value: { ...getState()?.account?.actionPreferences, ...partialPrefs },
      });
      let response = await postActionPreference(getAccessTokenSilently, partialPrefs);

      const result = response.data;
      if (result.isSuccess) {
        const updatedPrefs = result.data;
        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'actionPreferences',
          value: updatedPrefs,
        });
      }
    } catch (error) {
      console.error('ue');
    }
  };
