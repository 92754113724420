import styled from 'styled-components';
import { CSS_VARS } from '@/infrastructure/constants';
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';

interface CodeProps {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  codeExists: boolean;
  setCodeExists: Dispatch<SetStateAction<boolean>>;
}

// button components
export const ButtonWrapper = styled.div`
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${(props: CodeProps) =>
    props.codeExists &&
    `& svg {
      color: #535353;
      font-size: 2rem;
      margin-right: 0.7em;
      cursor: pointer;
    }`}
`;

export const Button = styled.div`
  display: flex;
  background: ${(props: CodeProps) =>
    props.codeExists ? '#fff' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  color: ${(props: CodeProps) =>
    props.codeExists ? '#535353' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  height: 50px;
  box-sizing: border-box;
  border: ${(props: CodeProps) => (props.codeExists ? 'solid 1px #DFE0E7' : 'none')};
  border-radius: 10px;
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  cursor: ${(props: CodeProps) => (props.codeExists ? 'auto' : 'pointer')};
  & svg {
    color: ${(props: CodeProps) =>
      props.codeExists ? CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR : '#fff'};
    font-size: 2rem;
    margin-right: 0.7em;
    cursor: auto;
  }
`;

export const StyledLink = styled(Link)`
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: normal;
  margin-top: 1rem;
  &:hover,
  &:active {
    color: #666666;
  }
`;
