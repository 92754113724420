import { Link } from '@/shared/types/api';
import { Box, styled } from '@mui/material';
import EntityBoxRenderer, { EntityBoxRendererGenericProps } from '../EntityBoxRenderer';
import { getFullLink } from '@/infrastructure/helper';

type Props = {
  link: Link;
  genericWebsiteColor?: string;
} & EntityBoxRendererGenericProps;

export const LinkBox = ({ link, genericWebsiteColor, ...rest }: Props) => {
  return (
    <EntityBoxRenderer
      title={link.name}
      subtitle={getFullLink(link.link, link.linkType)}
      icon={
        <IconWrapper bgcolor={link.linkType?.backgroundCss || genericWebsiteColor || '#152570'}>
          <img src={link.linkType?.iconUrl} alt={link.linkType?.name} />
        </IconWrapper>
      }
      isLink
      {...rest}
    />
  );
};

const IconWrapper = styled(Box)(({ bgcolor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4.4rem',
  width: '4.4rem',
  background: bgcolor,
  borderRadius: '50%',
  img: {
    height: '2rem',
    display: 'block',
  },
}));
