import { HelpOutline } from '@mui/icons-material';
import { Dropdown, LtDropdownProps } from './Dropdown';
import { useHelpActions } from '@/infrastructure/hooks/Header/useHelpActions';
import { useTranslation } from 'react-i18next';

export const HelpDropdown = (props: Pick<LtDropdownProps, 'open' | 'onClose'>) => {
  const { t } = useTranslation();
  const helpItems = useHelpActions();
  return (
    <Dropdown
      ariaLabel={t('ariaHeaderHelpDropdown')}
      icon={<HelpOutline />}
      actions={helpItems}
      {...props}
    />
  );
};
