import { UDT_CATEGORIES } from '@/shared/business-logic/features/user-defined-translations/constants';
import { FEATURE } from '@/shared/constants';
import { EditRightsConfig } from '@/shared/types/api';

// the type here replaces the keys linkEditMode/fileEditMode with linksEditable/filesEditable
// ToDo: convert the entire link and files edit modes to be controlled by boolean edit rights as well
// i.e. get rid of linkEditMode=options
export const FIELDS: Record<
  | Exclude<keyof EditRightsConfig, 'linkEditMode' | 'fileEditMode'>
  | 'linksEditable'
  | 'filesEditable',
  { tooltip: string }
> = {
  detailsEditable: { tooltip: '' },
  profileImageEditable: { tooltip: '' },
  bannerImageEditable: { tooltip: '' },
  logoImageEditable: { tooltip: '' },
  linksEditable: { tooltip: '' },
  filesEditable: { tooltip: '' },
  allowOwnQrBgImage: {
    tooltip: 'editRights.tooltips.allowOwnQrBgImage',
  },
  rebuyCards: { tooltip: 'editRights.tooltips.rebuyCards' },
  singleUserCrmPush: { tooltip: '' },
  profileShare_uidParam: { tooltip: 'editRights.tooltips.profileShare_uidParam' },
} as const;

export const getUnitEditRightsConfig = (isFeatureAllowed: (key: string) => boolean) => {
  const allowedConfig = [
    {
      themeConfigKey: 'profile-design',
      tLabel: 'editRights.unitOverrideLabels.profileDesign.label',
      requiredFeature: FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN,
      tooltipTLabel: null,
      conditionalThemeConfigGroup: null,
    },
    {
      themeConfigKey: 'edit-rights',
      tLabel: 'editRights.unitOverrideLabels.editRights.label',
      requiredFeature: FEATURE.UNIT_HIERARCHIES_EDIT_RIGHTS,
      tooltipTLabel: null,
      conditionalThemeConfigGroup: null,
    },
    {
      themeConfigKey: 'user-defined-translations',
      tLabel: 'editRights.unitOverrideLabels.userDefinedTranslations.label',
      requiredFeature: FEATURE.USER_DEFINED_TRANSLATIONS,
      tooltipTLabel: null,
      conditionalThemeConfigGroup: {
        tHeading: 'editRights.unitOverrideLabels.userDefinedTranslations.subsettings.heading',
        tSubHeading: 'editRights.unitOverrideLabels.userDefinedTranslations.subsettings.subheading',
        tPrefix: 'editRights.unitOverrideLabels.userDefinedTranslations.subsettings.labels.',
        prefix: 'user-defined-translation',
        suffixes: UDT_CATEGORIES,
      },
    },
  ] as const;

  return allowedConfig.filter(conf => isFeatureAllowed(conf.requiredFeature));
};

export type SubField = { id: string; name?: string; tname?: string };
export type SubFields = {
  [k in 'detailsEditable' | 'linksEditable']: SubField[];
};

export type EditRightsFields =
  | {
      [k in keyof typeof FIELDS]: boolean;
    }
  | null;
