import React, { useCallback, useState } from 'react';
import Layout from '../show-profile/components/white-layout';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import styled, { css } from 'styled-components';
import websiteIcon from '../../images/textbox-icons/website.svg';
import phoneIcon from '../../images/textbox-icons/phone.svg';
import mailIcon from '../../images/textbox-icons/mail.svg';
import locationIcon from '../../images/textbox-icons/location.svg';
import downIcon from '../../images/down.svg';
import userIcon from '@/views/images/textbox-icons/user.svg';
import { DEFAULT_BOX_STYLE } from '../../../shared/constants';
import { Account } from '../../../shared/types/api';
import { getAddressFromAccount, removeLeading } from '../../../shared/util/transforms';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { ReactComponent as DownloadIcon } from '../../images/download.svg';
import { RequireAtMostOne } from '../../../shared/types/util';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { ThemeBoxStyle } from '@/shared/types/global';
import nl2br from 'react-nl2br';

type Props = RequireAtMostOne<
  {
    account: Account;
    boxStyle: ThemeBoxStyle;
    genericHeader?: boolean;
    onSaveContact?: () => void;
    editConfig?: {
      addNewText: string;
      onAddNewClick(): void;
    };
  },
  'onSaveContact' | 'editConfig' // can either show edit or save button
>;

export default function ContactDetails(props: Props) {
  const { account, editConfig, onSaveContact, boxStyle } = props;
  const {
    editRights: { detailsEditable },
  } = useEditRights();

  const { t } = useAppTranslation();
  const [expandView, setExpandView] = useState(false);

  const getAssistantAsMarkup = useCallback(
    (account: Account) => {
      const line1 = `${account.assistantName || ''}`.trim();
      const line2 = `${account.assistantPhone || ''}`.trim();
      const line3 = `${account.assistantEmail || ''}`.trim();
      const line4 = `${account.assistantGeneric || ''}`.trim();
      if (!line1 && !line2 && !line3 && !line4) {
        return null;
      }

      return (
        <>
          {line1 ?? null}
          {line1 && (line2 || line3 || line4) && <br />}
          {line2 ? (
            editConfig ? (
              `+ ${line2}`
            ) : (
              <ContactLink
                href={generateLink('assistantPhone', account.assistantPhone)}
                target='_blank'
                rel='noopener noreferrer'
              >
                + {line2}
              </ContactLink>
            )
          ) : null}
          {line2 && (line3 || line4) && <br />}
          {line3 ? (
            editConfig ? (
              `${line3}`
            ) : (
              <ContactLink
                href={generateLink('assistantEmail', account.assistantEmail)}
                target='_blank'
                rel='noopener noreferrer'
              >
                {line3}
              </ContactLink>
            )
          ) : null}
          {line3 && line4 && <br />}
          {line4 ?? null}
        </>
      );
    },
    [editConfig],
  );

  const getAddressAsMarkup = useCallback((account: Account, addrNr: 1 | 2) => {
    const addressText = getAddressFromAccount(account, addrNr);
    if (!addressText) return null;
    return <>{nl2br(addressText)}</>;
  }, []);

  const ThirdPartiesApp = useCallback(
    ({ children, item, value }) => {
      if (item === 'assistant') return children;
      const link = generateLink(item, value, account);
      return (
        <ContactLink href={link} target='_blank' rel='noopener noreferrer'>
          {children}
        </ContactLink>
      );
    },
    [account],
  );

  const keys = [
    'email',
    'workEmail',
    'workEmail2',
    'mobilePhone',
    'workPhone',
    'homePhone',
    'hotlinePhone',
    'assistant',
    'workFax',
    'workUrl',
    'location',
    'location2',
  ];
  const iconMap = {
    workUrl: websiteIcon,
    mobilePhone: phoneIcon,
    workPhone: phoneIcon,
    homePhone: phoneIcon,
    hotlinePhone: phoneIcon,
    assistant: userIcon,
    workFax: phoneIcon,
    email: mailIcon,
    workEmail: mailIcon,
    workEmail2: mailIcon,
    location: locationIcon,
    location2: locationIcon,
  };
  const items = {
    workUrl: account.workUrl,
    mobilePhone: account.mobilePhone,
    workPhone: account.workPhone,
    homePhone: account.homePhone,
    hotlinePhone: account.hotlinePhone,
    assistant: getAssistantAsMarkup(account),
    workFax: account.workFax,
    email: account.email,
    workEmail: account.workEmail,
    workEmail2: account.workEmail2,
    location: getAddressAsMarkup(account, 1),
    location2: getAddressAsMarkup(account, 2),
  };

  const labels = {
    mobilePhone: t('mobilePhone.label'),
    workPhone: t('workPhone.label'),
    homePhone: t('homePhone.label'),
    hotlinePhone: t('hotlinePhone.label'),
    assistant: t('assistantPhone.label'),
    workFax: t('workFax.label'),
    location: account.address1_label || undefined,
    location2: account.address2_label || undefined,
  };
  const availableKeys = keys.filter(key => {
    return items[key];
  });

  if (availableKeys.length === 0 && !editConfig) return null;

  const onExpandClick = () => {
    setExpandView(!expandView);
  };

  const RowWrapper = editConfig ? CustomeDiv : ThirdPartiesApp;

  return (
    <Layout
      headerText={props.genericHeader ? t('contactDetails') : t('myContactDetails')}
      themeBoxStyle={boxStyle || DEFAULT_BOX_STYLE}
      actionButton={
        editConfig && detailsEditable
          ? {
              text: editConfig.addNewText,
              icon: <EditIcon />,
              customBtnClasses: 'lt-usetiful--bio-edit',
              onClick: editConfig.onAddNewClick,
            }
          : !editConfig && onSaveContact
          ? {
              text: 'vcard',
              icon: <DownloadIcon />,
              onClick: onSaveContact,
            }
          : undefined
      }
    >
      <ContactWrapper withToggle={availableKeys.length > 2}>
        {availableKeys.map(
          (key, idx) =>
            (expandView || (!expandView && idx <= 1)) && (
              <RowWrapper key={key} item={key} value={items[key]}>
                <ContactRow>
                  <img
                    style={{
                      transform: key === 'workUrl' ? 'rotate(90deg) scale(0.95)' : undefined,
                    }}
                    src={iconMap[key]}
                    alt={`${key} icon`}
                  />
                  <ContactContent>
                    <ContactValue>
                      {iconMap[key] === phoneIcon && '+ '}
                      {key === 'workUrl' && !items[key]?.startsWith('https://') ? 'https://' : ''}
                      {iconMap[key] === phoneIcon ? removeLeading(items[key], '+') : items[key]}
                    </ContactValue>
                    {labels[key] && <ContactLabel> {labels[key]}</ContactLabel>}
                  </ContactContent>
                </ContactRow>
              </RowWrapper>
            ),
        )}
      </ContactWrapper>
      {availableKeys.length > 2 && (
        <ArrowIconWrapper>
          <ArrowIcon src={downIcon} alt='toggle' expandView={expandView} onClick={onExpandClick} />
        </ArrowIconWrapper>
      )}
    </Layout>
  );
}

const CustomeDiv = ({ children, item, value }) => {
  return <div>{children}</div>;
};

const generateLink = (item: string, value, account?: Account) => {
  if (item === 'workUrl') {
    return `${!value?.startsWith('https://') ? 'https://' : ''}${value}`;
  } else if (
    item === 'mobilePhone' ||
    item === 'workPhone' ||
    item === 'homePhone' ||
    item === 'hotlinePhone' ||
    item === 'assistantPhone' ||
    item === 'workFax'
  ) {
    return `tel:${'+' + removeLeading(value, '+')}`;
  } else if (
    item === 'email' ||
    item === 'workEmail' ||
    item === 'workEmail2' ||
    item === 'assistantEmail'
  ) {
    return `mailto:<${value}>`;
  } else if (item === 'location') {
    const location = account ? getAddressFromAccount(account, 1, ', ') : '';
    const isApple = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
    return isApple
      ? `https://maps.apple.com/maps?q=${location}`
      : `https://maps.google.com/maps?q=${location}`;
  } else if (item === 'location2') {
    const location = account ? getAddressFromAccount(account, 2, ', ') : '';
    const isApple = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
    return isApple
      ? `https://maps.apple.com/maps?q=${location}`
      : `https://maps.google.com/maps?q=${location}`;
  } else {
    return '#';
  }
};

const ContactWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  ${(props: { withToggle: boolean }) =>
    props.withToggle &&
    css`
      padding-right: 5rem;
    `}
`;

export const ArrowIconWrapper = styled.div`
  position: relative;
  height: 18px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: -8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ArrowIcon = styled.img`
  cursor: pointer;
  position: absolute;
  width: 18px;
  height: 18px;
  ${(props: { expandView }) =>
    props.expandView &&
    css`
      transform: rotate(180deg);
    `}
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ::first-child {
    margin-top: 0;
  }
  & > img {
    margin: 0 1.5rem 0 0.5rem;
    width: 18px;
  }
`;

const ContactContent = styled.div`
  line-height: 21px;
  padding: 8px 0;
  flex: 1;
`;

const ContactValue = styled.div`
  color: #55595e;
  font-size: 14px;
  font-weight: 500;
`;

const ContactLabel = styled.div`
  color: #ababab;
  font-size: 1.4rem;
  margin-top: -3px;
`;

const ContactLink = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
  & > * {
    &:active {
      border-radius: 100px;
      background-image: linear-gradient(to bottom, #eee, #ddd);
    }
  }
`;
