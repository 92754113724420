import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import {
  chunks,
  getDaysInMonth,
  isStartOfRange,
  isEndOfRange,
  inDateRange,
  isRangeSameDay,
  getDay,
  isSameMonth,
  isWithinInterval,
  isToday,
} from '../utils/utils';
import Header from './Header';
import Day from './Day';

import { NavigationAction } from '../utils/types';
import { DateRange } from '@/shared/types/api';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Marker } from './Markers';
import moment from 'moment';

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (marker: Marker, day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: Marker, action: NavigationAction) => void;
  };
  coupledView?: boolean;
}

const Month: React.FunctionComponent<MonthProps> = (props: MonthProps) => {
  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    setValue: setDate,
    minDate,
    maxDate,
    coupledView,
  } = props;

  const WEEK_DAYS_EN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const WEEK_DAYS_DE = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  const [back, forward] = props.navState;

  const { activeLanguage, t } = useAppTranslation();

  return (
    <Paper square elevation={0}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
          onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
          coupledView={coupledView}
        />

        <Grid item container direction='row' justifyContent='center' gap='1.3rem' my={0.5}>
          {(activeLanguage.startsWith('de') ? WEEK_DAYS_DE : WEEK_DAYS_EN).map((day, index) => (
            <Typography color='textSecondary' key={index} variant='caption'>
              {day}
            </Typography>
          ))}
        </Grid>

        <Grid item container direction='column' justifyContent='space-between' px={1} my={1}>
          {chunks(getDaysInMonth(date), 7).map((week, idx) => (
            <Grid key={idx} container direction='row' justifyContent='center'>
              {week.map(day => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);
                const wouldBeStart = Boolean(dateRange.startDate) && Boolean(dateRange.endDate);

                return (
                  <Day
                    key={day.toString()}
                    filled={isStart || isEnd}
                    outlined={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={
                      !isSameMonth(date, day) ||
                      !isWithinInterval(day, { start: minDate, end: maxDate })
                    }
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    onClick={() => handlers.onDayClick(marker, day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={getDay(day)}
                    ariaLabel={
                      t(wouldBeStart ? 'ariaSelectStartDay' : 'ariaSelectEndDay', {
                        date: moment(day).format('LL'),
                      }) as unknown as string
                    }
                  />
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Month;
