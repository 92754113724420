import { PropsWithChildren, useMemo } from 'react';
import { LtNotificationsContext } from './LtNotificationsContext';
import SuccessNotification from './variants/success';
import ErrorNotification from './variants/error';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ThemeProvider } from '@mui/material';
import LoadingNotification from './variants/loading';
import { useNotificationsStore } from '@/zustand/notificationsStore';
import InfoNotification from './variants/info';

export default function LtNotificationsProvider(props: PropsWithChildren<{}>) {
  const {
    info,
    notifyInfo,
    closeInfo,
    success,
    closeSuccess,
    notifySuccess,
    error,
    closeError,
    notifyError,
    loading,
    loadingTexts,
    dismiss,
    dismissAll,
    notifyLoading,
  } = useNotificationsStore();

  const { theme: muiTheme } = useMuiTheme();

  // helper for migrations from toast syntax
  const notify = useMemo(
    () => ({
      success: (text: string, options?: { id: string }) => notifySuccess(text, options?.id),
      error: (text: string, options?: { id: string }) => notifyError(text, options?.id),
      loading: (text: string, options?: { id: string }) => notifyLoading(options?.id, text),
      dismiss: (id: string) => dismiss(id),
    }),
    [dismiss, notifyError, notifyLoading, notifySuccess],
  );

  return (
    <>
      <LtNotificationsContext.Provider
        value={{
          notifyInfo,
          notifySuccess,
          notifyError,
          notifyLoading,
          dismiss,
          dismissAll,
          notify,
        }}
      >
        {props.children}
      </LtNotificationsContext.Provider>
      <ThemeProvider theme={muiTheme}>
        <InfoNotification {...info} onClose={closeInfo} />
        <SuccessNotification {...success} onClose={closeSuccess} />
        <ErrorNotification {...error} onClose={closeError} />
        <LoadingNotification
          open={Boolean(loading.length)}
          text={loadingTexts[loading[0]]}
          onClose={dismissAll}
        />
      </ThemeProvider>
    </>
  );
}
