import { Alert, CircularProgress, SnackbarProps, Typography } from '@mui/material';
import BaseSnackbar from './base';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';

export type LoadingNotificationProps = SnackbarProps & {
  text?: string;
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

export default function LoadingNotification({ text, ...snackbarProps }: LoadingNotificationProps) {
  const { t } = useTranslation();
  return (
    <BaseSnackbar {...snackbarProps} onClose={undefined}>
      <Alert severity='info' variant='standard' sx={{ width: '100%' }} icon={false}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <CircularProgress size='2.4rem' />
          <Typography>{text || t('Loading')}</Typography>
        </Stack>
      </Alert>
    </BaseSnackbar>
  );
}
