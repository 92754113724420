import { StaticProfileConfig } from '@/shared/types/api';
import { Box, styled } from '@mui/material';
import ProfilePicture, { WIDTH as PROFILE_PICTURE_WIDTH } from './components/ProfilePicture';
import Logo from './components/Logo';
import Bio from './components/Bio';
import SaveContactButton from './SaveContactButton';
import { ProfileEditProps, TrackHandlers } from '../../types';
import ContentBoxUi, { PADDING } from '../../components/ContentBoxUI';

interface Props {
  config: StaticProfileConfig['header'];
  profileImageEditProps?: ProfileEditProps['profileImageEditProps'];
  logoImageEditProps?: ProfileEditProps['logoImageEditProps'];
  trackHandlers?: Pick<TrackHandlers, 'contactSaveTrackHandler' | 'profileImageClickTrackHandler'>;
}

export default function HeaderBox({
  config,
  profileImageEditProps,
  logoImageEditProps,
  trackHandlers = {},
}: Props) {
  const isProfileImageEdit = !!profileImageEditProps;
  const isLogoImageEdit = !!logoImageEditProps;
  const isEdit = isProfileImageEdit || isLogoImageEdit;
  return (
    <ContentBoxUi
      sx={{
        position: 'relative',
        overflow: 'visible',
        paddingTop:
          !config.logoImageUrl && !config.profileImageUrl && !isEdit
            ? 1
            : !config.profileImageUrl && !isEdit
            ? 6
            : 0,
        paddingBottom: 1,
      }}
      isEdit={isEdit}
    >
      {(isProfileImageEdit || config.profileImageUrl) && (
        <ProfilePictureWrapper>
          <ProfilePicture
            profileImageUrl={config.profileImageUrl}
            editProps={profileImageEditProps}
            profileImageClickTrackHandler={trackHandlers?.profileImageClickTrackHandler}
          />
        </ProfilePictureWrapper>
      )}
      {(isLogoImageEdit || config.logoImageUrl) && (
        <LogoImageWrapper>
          <Logo logoImageUrl={config.logoImageUrl} editProps={logoImageEditProps} />
        </LogoImageWrapper>
      )}
      <BioWrapper>
        <Bio config={config.bioContent} isEdit={isEdit} />
      </BioWrapper>
      {!isEdit && config.contactSaveButton && (
        <SaveContactButtonWrapper>
          <SaveContactButton
            config={config.contactSaveButton}
            contactSaveTrackHandler={trackHandlers?.contactSaveTrackHandler}
          />
        </SaveContactButtonWrapper>
      )}
    </ContentBoxUi>
  );
}

const ProfilePictureWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(-PADDING - PROFILE_PICTURE_WIDTH / 2),
  marginBottom: theme.spacing(-PROFILE_PICTURE_WIDTH / 2),
  width: 'fit-content',
}));

const LogoImageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.75),
  right: theme.spacing(2),
  width: '33%',
  height: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    height: theme.spacing(4),
    width: '50%',
  },
}));

const BioWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const SaveContactButtonWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}));
