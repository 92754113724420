import { AddEditThemeLinkDialog } from './AddEditThemeLinkDialog';
import { AddEditUserLinkDialog } from './AddEditUserLinkDialog';
import { AddEditLinkDialogProps } from './types';

type Props = {
  mode: 'admin' | 'standard';
} & AddEditLinkDialogProps;

export const AddEditLinkDialog = ({ mode, ...rest }: Props) => {
  return mode === 'admin' ? (
    <AddEditThemeLinkDialog {...rest} />
  ) : (
    <AddEditUserLinkDialog {...rest} />
  );
};
