import { Alert, AlertTitle, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BULK_EDIT_ACTION_TYPES } from '../utils';

const TYPE_ARIA_LABELS = {
  [BULK_EDIT_ACTION_TYPES.OVERRIDE]: 'ariaBulkOverride',
  [BULK_EDIT_ACTION_TYPES.DELETE]: 'ariaBulkDelete',
};

type Props = {
  onChange: (value: string) => void;
  selectedActionType: string;
  selectedCount: number;
};

export const ActionSelector = ({ selectedActionType, onChange, selectedCount }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <FormControl>
        <RadioGroup
          value={selectedActionType}
          onChange={({ target: { value } }) => onChange(value)}
          row
        >
          {Object.values(BULK_EDIT_ACTION_TYPES).map(type => (
            <FormControlLabel
              value={type}
              control={<Radio />}
              label={t(type)}
              aria-label={TYPE_ARIA_LABELS[type]}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Alert severity='info' sx={{ mt: 2 }} variant='outlined'>
        <AlertTitle sx={{ color: 'inherit' }}>
          {t('xProfilesSelectedLong', { count: selectedCount })}
        </AlertTitle>
        {selectedActionType === BULK_EDIT_ACTION_TYPES.OVERRIDE
          ? t('contactInfoBulkHint')
          : t('contactInfoBulkHintDeleted')}
      </Alert>
    </>
  );
};
