import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../application/hooks';

import { onboardingNotify } from '../../../../util';

import { ContinueButton, OnboardingScreenWrapper } from '../components';
import WalletGenerator from '../../wallet/WalletGenerator';
import { generateProfileLink } from '@/shared/business-logic/features/profile/getter';
import Loader from '@/components/Loader';

interface Props {
  next: () => void;
}

export const Screen2 = (props: Props) => {
  const { isLoading } = useAppSelector(state => state.onboarding);
  const account = useAppSelector(state => state.account);
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const handleSubmit = async () => {
    onboardingNotify('add-to-wallet-completed', getAccessTokenSilently);
    props.next();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <OnboardingScreenWrapper title={t('onboarding.addToWallet.title')}>
      <WalletGenerator
        profileUrl={generateProfileLink(account)}
        oneQrType='online'
        showCardActions={false}
        cardLayout={false}
        showQrCode={false}
        downloadCallback={() => {
          onboardingNotify('add-to-wallet-clicked', getAccessTokenSilently);
        }}
        description={t('onboarding.addToWallet.description')}
      />
      <ContinueButton onClick={handleSubmit}>{t('onboarding.continue')}</ContinueButton>
    </OnboardingScreenWrapper>
  );
};
