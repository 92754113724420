import i18n from '@/config/i18n';
import { appendParamsToLink } from '@/shared/util';

export type ShareType = 'whatsapp' | 'linkedin' | 'mail' | 'webShare';

export const getShareLink = (type: ShareType, profileLink: string) => {
  const linkedInUrl = appendParamsToLink(profileLink, { utm_medium: 'linkedinshare' });
  const whatsAppUrl = appendParamsToLink(profileLink, { utm_medium: 'whatsappshare' });
  const emailShare = appendParamsToLink(profileLink, { utm_medium: 'emailshare' });

  switch (type) {
    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${linkedInUrl}`;
    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${i18n.t('hereIsMyContact')}: ${whatsAppUrl}`;
    case 'mail':
      return `mailto:""?subject=${i18n.t('hereIsMyContact')}&body=${i18n.t(
        'myContact',
      )}: ${emailShare}`;
    default:
      return '';
  }
};
