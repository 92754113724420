import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LtDialog } from '@/components';

interface Props {
  onCancel: () => void;
  options?: string[];
  current?: string;
  onSelected: (string) => void;
}

export default function BannerChooser(props: Props): JSX.Element {
  const { onCancel, options, current, onSelected } = props;
  const { t } = useAppTranslation();
  const [selectedUrl, setSelectedUrl] = useState(current);

  return (
    <LtDialog
      open={true}
      onClose={onCancel}
      onCancel={onCancel}
      saveAction={{
        text: t('save'),
        onClick: () => onSelected(selectedUrl),
      }}
      title={t('chooseCoverPicture')}
      size='md'
      withActionDivider
    >
      <BannerOption>
        {options.map((url: string) => (
          <Image
            key={url}
            src={url}
            alt='Banner option'
            onClick={() => setSelectedUrl(url)}
            selected={url === selectedUrl}
          />
        ))}
      </BannerOption>
    </LtDialog>
  );
}

const BannerOption = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  row-gap: 1.5rem;
  column-gap: 1rem;
  margin: 1rem 0;
  box-sizing: border-box;
`;

const Image = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  max-width: 100%;
  border: 6px solid transparent;
  transition: border 0.15s ease-in;

  ${props =>
    props.selected &&
    css`
      border: 6px solid #01c987;
    `}
`;
