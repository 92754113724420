import { SelectedCountCounterGeneric } from '@/components/Table';
import { useTranslation } from 'react-i18next';

interface Props {
  total: number;
  selectedCount: number;
  onSelectAll: () => void;
  selectAllVisible: boolean;
}

export const SelectedCountCounter = ({
  total,
  selectedCount,
  onSelectAll,
  selectAllVisible,
}: Props) => {
  const { t } = useTranslation();
  return (
    <SelectedCountCounterGeneric
      onSelectAll={onSelectAll}
      selectAllVisible={selectAllVisible}
      text={
        selectedCount === total
          ? t('xAllProfilesSelected')
          : t('xProfilesSelected', { count: selectedCount })
      }
      subText={t('selectAllTexts.profile', { count: total })}
    />
  );
};
