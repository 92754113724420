import { Option, OptionValue } from '@/shared/util';
import {
  Autocomplete,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FilterItemHeader } from './FilterItemHeader';
import { FilterComponentProps } from '../utils';

export const ListFilter = ({
  filter,
  filterValue,
  onFilterValueChange,
  onFilterDelete,
}: FilterComponentProps) => {
  const { t } = useTranslation();
  const options = filter.options;
  const value = (filterValue?.value as OptionValue[]) || [];
  const operator = filterValue?.operator || filter.operators[0];

  const selectedOptions = options.filter(o => value.includes(o.id));

  const handleChange = (options: Option[]) => {
    onFilterValueChange({ value: options.map(o => o.id), operator });
  };

  return (
    <>
      <FilterItemHeader
        label={t(filter.labelKey)}
        operators={filter.operators}
        value={operator}
        onChange={operator => onFilterValueChange({ value, operator })}
        onDelete={onFilterDelete}
      />
      <Autocomplete
        multiple
        options={filter.options}
        getOptionLabel={(option: Option) => option.title}
        value={selectedOptions}
        onChange={(_, value) => handleChange(value as Option[])}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            label={t(filter.labelKey)}
            placeholder={t(filter.placeholderKey)}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props}>
            {option.avatar && (
              <ListItemAvatar>
                <Avatar src={option.avatar} alt={option.title} />
              </ListItemAvatar>
            )}
            <ListItemText primary={option.title} />
          </ListItem>
        )}
      />
    </>
  );
};
