import { useAppSelector } from '@/application/hooks';
import { routePaths } from '@/infrastructure/constants';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useStateCallback } from '@/infrastructure/hooks/useStateCallback';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useUnitSelectorForUidParam } from '@/infrastructure/hooks/useUnitSelectorForUidParam';
import { generateProfileLink } from '@/shared/business-logic/features/profile/getter';
import { Account } from '@/shared/types/api';
import { QRBGConfig } from '@/shared/types/global';
import {
  DEFAULT_IMG_WIDTH_MO,
  DEFAULT_IMG_WIDTH_VC,
  FIXED_POSITIONS,
  POSITIONS_DEFAULT,
  POS_CONST_MO,
  POS_CONST_VC,
} from './common/constants';
import {
  generateImageObj,
  imageSizeValidator,
  imgWithTimestamp,
  loadQrConfig,
} from './common/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { QRBGMode, QRCodeMode, QRPositions } from './common/types';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { getVcard } from '@/infrastructure/apis/account';

export const useQRBackgrounds = (screen: QRBGMode) => {
  const { getAccessTokenSilently } = useAuth0();
  const { t, activeLanguage } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const account = useAppSelector<Account>(state => state.account);

  const domain = useMemo(() => account?.theme?.domain, [account?.theme?.domain]);

  let qrCodeRef = useRef(null);
  const [mode, setMode] = useState<'samples' | 'uploaded'>('samples');
  const [qrCodeImg, setQRCodeImg] = useStateCallback('');
  const [qrCodeImgSize, setQrCodeImgSize] = useStateCallback(0);
  const [qrConfig, setQRConfig] = useState<QRBGConfig>();
  const [qrCodeType, setQRCodeType] = useState<QRCodeMode>('online');

  const [bgPreviews, setBgPreviews] = useState<string[]>([]);
  const [qrBgImg, setQRBgImg] = useState('');

  const [downloadReady, setDownloadReady] = useState(false);

  const [configsLoading, setConfigsLoading] = useState<boolean>(undefined);
  const [imagesLoading, setImagesLoading] = useState(false);

  const [qrCodePosition, setQRCodePosition] = useState<QRPositions>(undefined);
  const [positionConfig, setPositionConfig] = useState<boolean>();
  const [uncroppedImage, setUncroppedImage] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');
  const [imgIndex, setImgIndex] = useState(0);
  const [origImgCounter, setOrigImgCounter] = useState(1);
  const [error, setError] = useState(null);

  const { isStarter } = useTierInfo();

  const defaultPosition = useRef(false);

  const isMobileScreen = screen === 'mobile';

  const genLoading = !downloadReady && !uncroppedImage;
  const loading =
    (configsLoading && imagesLoading) || (!!bgPreviews.length && !downloadReady && !uncroppedImage);
  useDeferredLoader(loading, 'qr-background-loading-toast');

  const { uidForProfileLink } = useUnitSelectorForUidParam();

  const profileLink = useMemo(
    () =>
      generateProfileLink(account, {
        lt: { lt_medium: 'mobilebgqr' },
        unit: uidForProfileLink,
      }),
    [account, uidForProfileLink],
  );

  const [vcardString, setVcardString] = useState('');
  useEffect(() => {
    getVcard(account.id, activeLanguage, 'qr-code')
      .then(res => {
        setVcardString(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, [account?.id, activeLanguage]);

  useEffect(() => {
    // load qr params theme-config
    setConfigsLoading(true);
    const fetchConfig = async () => await loadQrConfig(getAccessTokenSilently);
    fetchConfig()
      .then(config => setQRConfig(config))
      .catch(err => {
        setError(err);
      })
      .finally(() => setConfigsLoading(false));
  }, [getAccessTokenSilently]);

  // handle qr-code default position
  useEffect(() => {
    if (!defaultPosition.current && typeof configsLoading !== 'undefined' && !configsLoading) {
      if (!qrConfig || isStarter) {
        setQRCodePosition(POSITIONS_DEFAULT[screen] as QRPositions);
      } else {
        setQRCodePosition('default');
      }

      defaultPosition.current = true;
    }
  }, [configsLoading, isStarter, qrConfig, screen]);

  const { config: imgsPreviewMo } = usePrivateThemeConfig(THEME_CONFIG_KEYS.QR_BG_IMAGES_MOBILE);
  const { config: imgsPreviewVC } = usePrivateThemeConfig(THEME_CONFIG_KEYS.QR_BG_IMAGES_VIDEOCALL);

  useEffect(() => {
    if (imgsPreviewMo && imgsPreviewVC && qrConfig) {
      isMobileScreen
        ? setBgPreviews(
            imgsPreviewMo.default
              ? [imgsPreviewMo.default, ...imgsPreviewMo.additional]
              : imgsPreviewMo.additional,
          )
        : setBgPreviews(
            imgsPreviewVC.default
              ? [imgsPreviewVC.default, ...imgsPreviewVC.additional]
              : imgsPreviewVC.additional,
          );
    }
  }, [imgsPreviewMo, imgsPreviewVC, isMobileScreen, qrConfig]);

  useEffect(() => {
    // set initial displayed image
    if (bgPreviews.length > 0) {
      setQRBgImg(imgWithTimestamp(bgPreviews[0]));
    }
    setImagesLoading(false);
  }, [bgPreviews]);

  useEffect(() => {
    if (error) {
      toast.error(t('generalQRImgError'));
      console.error(error);
    }
  }, [error, t, toast]);

  const getQRPositions = useCallback(
    (
      position: QRPositions,
      imgWidth: number,
      imgHeight: number,
      qrCodeWidth: number,
      qrConfig: QRBGConfig,
    ): { x: number; y: number } => {
      let x: number;
      let y: number;
      const fixedPosMo = {
        x: POS_CONST_MO.X * (imgWidth - qrCodeWidth),
        y_top: POS_CONST_MO.Y.TOP * (imgHeight - qrCodeWidth),
        y_middle: POS_CONST_MO.Y.MIDDLE * (imgHeight - qrCodeWidth),
        y_bottom: POS_CONST_MO.Y.BOTTOM * (imgHeight - qrCodeWidth),
      };
      const fixedPosVC = {
        y: POS_CONST_VC.Y * (imgHeight - qrCodeWidth),
        x_left: POS_CONST_VC.X.LEFT * (imgWidth - qrCodeWidth),
        x_middle: POS_CONST_VC.X.MIDDLE * (imgWidth - qrCodeWidth),
        x_right: POS_CONST_VC.X.RIGHT * (imgWidth - qrCodeWidth),
      };

      if (screen === 'mobile') {
        const configX = qrConfig[screen].x * (imgWidth - qrCodeWidth);
        const configY = qrConfig[screen].y * (imgHeight - qrCodeWidth);
        x =
          !FIXED_POSITIONS[screen].includes(position) && !qrConfig.fallback
            ? configX
            : fixedPosMo.x;

        // set positions
        if (position === 'bottom') {
          y = fixedPosMo.y_bottom;
        } else if (position === 'top') {
          y = fixedPosMo.y_top;
        } else if (position === 'middle') {
          y = fixedPosMo.y_middle;
        } else {
          y = configY;
        }

        if (isStarter) {
          // disable config position on starter plan
          setPositionConfig(false);
        } else {
          // set to fixed positions if config positions equal to fixed ones
          if (
            qrConfig.fallback ||
            (POS_CONST_MO.X === qrConfig[screen].x &&
              Object.values(POS_CONST_MO.Y).includes(qrConfig[screen].y))
          ) {
            // fixed positions
            setPositionConfig(false);
            if (x === fixedPosMo.x && y === fixedPosMo.y_top) {
              qrCodePosition !== 'top' && setQRCodePosition('top');
            } else if (x === fixedPosMo.x && y === fixedPosMo.y_middle) {
              qrCodePosition !== 'middle' && setQRCodePosition('middle');
            } else if (x === fixedPosMo.x && y === fixedPosMo.y_bottom) {
              qrCodePosition !== 'bottom' && setQRCodePosition('bottom');
            }
          } else {
            // config position
            setPositionConfig(true);
          }
        }
      } else if (screen === 'videocall') {
        const configX = qrConfig[screen].x * (imgWidth - qrCodeWidth);
        const configY = qrConfig[screen].y * (imgHeight - qrCodeWidth);
        y =
          !FIXED_POSITIONS[screen].includes(position) && !qrConfig.fallback
            ? configY
            : fixedPosVC.y;
        if (position === 'right') {
          x = fixedPosVC.x_right;
        } else if (position === 'left') {
          x = fixedPosVC.x_left;
        } else if (position === 'middle') {
          x = fixedPosVC.x_middle;
        } else {
          x = configX;
        }

        if (isStarter) {
          setPositionConfig(false);
        } else {
          if (
            qrConfig.fallback ||
            (POS_CONST_VC.Y === qrConfig[screen].y &&
              Object.values(POS_CONST_VC.X).includes(qrConfig[screen].x))
          ) {
            // fixed positions
            setPositionConfig(false);
            if (y === fixedPosVC.y && x === fixedPosVC.x_left) {
              qrCodePosition !== 'left' && setQRCodePosition('left');
            } else if (y === fixedPosVC.y && x === fixedPosVC.x_middle) {
              qrCodePosition !== 'middle' && setQRCodePosition('middle');
            } else if (y === fixedPosVC.y && x === fixedPosVC.x_right) {
              qrCodePosition !== 'right' && setQRCodePosition('right');
            }
          } else {
            // config position
            setPositionConfig(true);
          }
        }
      }

      return { x, y };
    },
    [isStarter, qrCodePosition, screen],
  );

  const generateQrBgImage = useCallback(
    (bgImg: string, qrImg: string) => {
      if (!Boolean(bgImg) || !Boolean(qrImg)) return;

      setDownloadReady(false);

      let img = generateImageObj(bgImg);
      let imgWidth = 0;
      let imgHeight = 0;
      img.onload = () => {
        imgWidth = img.width;
        imgHeight = img.height;

        let qrCode = generateImageObj(qrImg);
        let qrCodeWidth = 0;

        let canvas = document.createElement('canvas');
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        let ctx = canvas.getContext('2d');

        // draw first image on created canvas element
        ctx.drawImage(img, 0, 0);

        qrCode.onload = () => {
          qrCodeWidth = qrCode.width;
          // draw second image on created canvas element

          const { x, y } = getQRPositions(
            qrCodePosition,
            imgWidth,
            imgHeight,
            qrCodeWidth,
            qrConfig,
          );

          ctx.drawImage(qrCode, x, y);

          let img = canvas.toDataURL('image/*');
          setQRBgImg(img);
          setDownloadReady(true);
        };
      };
    },
    [qrConfig, getQRPositions, qrCodePosition],
  );

  const getQrCodeImg = useCallback(
    async (img?: string) => {
      if (!qrCodeRef?.current || !Boolean(img)) return;
      let serialized = new XMLSerializer().serializeToString(await qrCodeRef.current.children[0]);
      const parser = new DOMParser();
      const newSvg = parser.parseFromString(serialized, 'image/svg+xml');
      const newSvgEl = newSvg.firstChild as SVGSVGElement;
      let qrCodeImgSrc =
        'data:image/svg+xml;base64,' + encodeURIComponent(window.btoa(newSvgEl.outerHTML));

      if (qrCodeImgSrc?.length > 0) {
        setQRCodeImg(qrCodeImgSrc, (qrCodeImg: string) => {
          generateQrBgImage(img, qrCodeImg);
        });
      }
    },
    [generateQrBgImage, setQRCodeImg],
  );

  const handleImageClick = async (imgIndex: number) => {
    if (typeof imgIndex !== 'number') return;
    let src: string;
    setImgIndex(imgIndex);
    src = imgWithTimestamp(bgPreviews[imgIndex]);
    if (mode === 'uploaded') {
      // regenerate code size dynamically for sample images after coming from custom uploading process
      let bgImg = generateImageObj(src);
      let factor = 0;
      let percentage = qrConfig[screen].size;

      bgImg.onload = () => {
        factor = isMobileScreen ? bgImg.height : bgImg.width;
        setQrCodeImgSize(factor * percentage, async (_: number) => {
          setMode('samples');
          await getQrCodeImg(src);
        });
      };
    } else {
      generateQrBgImage(src, qrCodeImg);
    }
  };

  const handleDownload = (qrBgImg: string, analyticQuery?: string) => {
    if (!Boolean(qrBgImg)) return;
    fetch(qrBgImg, {
      method: 'GET',
      headers: {},
    })
      .then(response => {
        response.arrayBuffer().then(buffer => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${account?.username}-QR-Background.png`);
          document.body.appendChild(link);
          link.click();
          if (analyticQuery) {
            fetch(`${domain}${routePaths.QR_CODE_BACKGROUND.ROOT}?${analyticQuery}`);
          }
          toast.success(t('downloadImgSuccess'));
        });
      })
      .catch(err => {
        setError(err);
      });
  };

  const handleUpload = async (files: File[]) => {
    mode === 'samples' && setMode('uploaded');
    uploadedImage.length > 0 && setUploadedImage('');
    if (files && files.length > 0) {
      const acceptedFileTypes = ['image/jpg', 'image/png', 'image/jpeg'];
      const inputImg = files[0];
      if (!acceptedFileTypes.includes(inputImg.type)) {
        return toast.error(t('uploadImgError'));
      } else {
        const imgUrl = URL.createObjectURL(inputImg);
        setUncroppedImage(imgUrl);
      }
      return;
    }
  };

  const handleCroppedImage = async (croppedImage: string) => {
    // validate uploaded image size
    const error = await imageSizeValidator(croppedImage, screen, () => setDownloadReady(true));
    if (error && error.length) {
      toast.error(error);
      return;
    }

    setUncroppedImage('');
    let percentage = qrConfig[screen].size;
    let img = generateImageObj(croppedImage);
    img.onload = async () => {
      let uploadedImgWidth = img.width;
      let defaultImgWidth = screen === 'mobile' ? DEFAULT_IMG_WIDTH_MO : DEFAULT_IMG_WIDTH_VC;
      if (uploadedImgWidth === defaultImgWidth)
        setOrigImgCounter(
          qrCodeImg > defaultImgWidth * percentage ? origImgCounter - 1 : origImgCounter + 1,
        );
      setQrCodeImgSize(
        uploadedImgWidth === defaultImgWidth
          ? defaultImgWidth * percentage + origImgCounter
          : uploadedImgWidth * percentage,
        async (_size: number) => {
          await getQrCodeImg(croppedImage);
          setUploadedImage(croppedImage);
          return toast.success(t('uploadImgSuccess'));
        },
      );
    };
  };

  // generate the qrcode image after render
  useEffect(() => {
    if (qrCodeRef.current) {
      (async () => await getQrCodeImg(qrBgImg))();
    }
  }, [getQrCodeImg, qrBgImg, qrCodeType, uidForProfileLink]);

  // generate initial bg images
  useEffect(() => {
    if (mode === 'samples' && account.theme.qrBg?.images && bgPreviews?.length) {
      (async () => await getQrCodeImg(bgPreviews[0]))();
    }
  }, [account.theme.qrBg?.images, bgPreviews, getQrCodeImg, mode]);

  // reset to default values on screens toggle
  useEffect(() => {
    setImgIndex(0);
    setMode('samples');
  }, [screen]);

  // handle the change of qr position
  useEffect(() => {
    if (mode === 'samples') {
      if (bgPreviews?.length) generateQrBgImage(imgWithTimestamp(bgPreviews[imgIndex]), qrCodeImg);
    } else {
      if (uploadedImage) generateQrBgImage(uploadedImage, qrCodeImg);
    }
  }, [qrCodeImg, bgPreviews, generateQrBgImage, imgIndex, mode, uploadedImage]);

  useEffect(() => {
    let defaultBGImg = generateImageObj(qrBgImg);
    let factor = 0;

    defaultBGImg.onload = () => {
      factor = defaultBGImg.width;
      setQrCodeImgSize(factor * qrConfig?.[screen]?.size, async (_: number) => {
        await getQrCodeImg(qrBgImg);
      });
    };
  }, [qrConfig, getQrCodeImg, qrBgImg, screen, setQrCodeImgSize]);

  const states = {
    qrBgImg,
    bgPreviews,
    downloadReady,
    qrCodeType,
    qrCodePosition,
    positionConfig,
    qrCodeImgSize,
    uncroppedImage,
  };

  const setters = {
    setQRCodeType,
    setQRCodePosition,
    setUncroppedImage,
    setDownloadReady,
  };

  const handlers = {
    handleUpload,
    handleDownload,
    handleCroppedImage,
    handleImageClick,
  };

  return {
    loading,
    genLoading,
    states,
    qrCodeRef,
    handlers,
    setters,
    urls: {
      online: profileLink,
      offline: vcardString,
    },
  };
};
