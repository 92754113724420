import { HelpSidebarConfig } from '@/shared/types/api';
import { Box, Button, Drawer, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HelpSidebarKey } from '@/shared/constants';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import CloseIcon from '@mui/icons-material/Close';
import MarkdownRenderer from './MarkdownRenderer';
import LtVideo from './LtVideo';

/********************
 *  Main Component  *
 ********************/

export const HelpButton = ({ configKey }: { configKey: HelpSidebarKey }) => {
  const [toggled, setToggled] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (open?: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (open === undefined) setToggled(prev => !prev);
    else setToggled(open);
  };

  const { config: helpSidebarConfig, loading: helpSidebarConfigLoading } =
    usePrivateThemeConfig<HelpSidebarConfig>(configKey);

  if (
    !helpSidebarConfigLoading &&
    (!helpSidebarConfig || (!helpSidebarConfig.content && !helpSidebarConfig.videos?.length))
  )
    return null;

  return (
    <>
      <Button variant='text' startIcon={<HelpOutlineIcon />} onClick={toggleDrawer()}>
        {t('help')}
      </Button>

      <Drawer
        anchor='right'
        variant='temporary'
        open={toggled}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { bgcolor: '#F6F8FE', minWidth: '36rem' } }}
      >
        <SidebarContent config={helpSidebarConfig} onClose={toggleDrawer(false)} />
      </Drawer>
    </>
  );
};

/********************
 *  Sidebar Content *
 ********************/

const SidebarContent = ({
  config,
  onClose,
}: {
  config: HelpSidebarConfig | null;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={headerStyles}>
        <Button variant='text' size='small' startIcon={<CloseIcon />} onClick={onClose}>
          {t('close')}
        </Button>
      </Box>
      <Box sx={boxSyles}>
        {config.content && <MarkdownRenderer content={config.content} />}
        {config.videos && (
          <Box mt={6}>
            {config?.videos?.map((x, idx) => (
              <SingleVideo title={x.title} url={x.url} key={x.url.concat(x.title || '')} />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

const boxSyles: SxProps = (theme: Theme) => ({
  maxWidth: '45rem',
  p: 3,
});

const headerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  p: 3,
  pb: 2,
  position: 'sticky',
  top: 0,
  bgcolor: 'inherit',
};

/********************
 *  Video Container *
 ********************/

export const SingleVideo = ({ title, url }: { title: string; url: string }) => {
  return (
    <Box mb={2}>
      <LtVideo embedUrl={url} label={title} />
    </Box>
  );
};
