import { FullScreenContainer } from './FullScreenContainer';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Box,
  Typography,
  IconButton,
  Alert,
} from '@mui/material';
import { BottomActionContainer } from './BottomActionContainer';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import WestIcon from '@mui/icons-material/West';
import useId from '@mui/material/utils/useId';

type Props = {
  onFinish: () => void;
  onBackClick: () => void;
  onRetakeClick: () => void;
  isSaving: boolean;
  options: string[];
  selectedGdpr: string;
  onChange: (str: string) => void;
  sendContactToEmail: boolean;
};

const SingleSelectBox = ({ selected, title, onClick }) => {
  const labelId = useId();
  return (
    <Card sx={theme => selected && { borderColor: theme.palette.primary.main }} onClick={onClick}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h4', id: labelId }}
        action={
          <Checkbox
            checked={selected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        }
      />
    </Card>
  );
};

export const GDPRScreen = ({
  onFinish,
  onBackClick,
  onRetakeClick,
  isSaving,
  options,
  selectedGdpr,
  onChange,
  sendContactToEmail,
}: Props) => {
  const { t } = useTranslation();
  const canClickSave = selectedGdpr !== '';
  return (
    <FullScreenContainer>
      <IconButton
        sx={{ p: 1.6, width: 'max-content' }}
        onClick={onBackClick}
        size='large'
        aria-label={t('ariaGoBackToPreviewsScreen')}
      >
        <WestIcon />
      </IconButton>
      <Box py={2.1} px={1.6}>
        <Typography variant='h2'>{t('businessCardScanner.gdprTitle')}</Typography>
        <Typography variant='h6' mt={2.4}>
          {t('businessCardScanner.gdprSubtitle')}
        </Typography>
        <Box gap={1.4} display='flex' flexDirection='column' mt={2.5}>
          {options.map(x => (
            <SingleSelectBox
              key={x}
              title={x}
              selected={selectedGdpr === x}
              onClick={() => onChange(selectedGdpr === x ? '' : x)}
            />
          ))}
        </Box>
        {sendContactToEmail && (
          <Alert sx={{ mt: 4 }} severity='info' variant='outlined'>
            {t('businessCardScanner.infoEmailWillBeSent')}
          </Alert>
        )}
      </Box>
      <BottomActionContainer display='flex' gap='1.3rem'>
        <Button fullWidth size='large' color='primary' variant='outlined' onClick={onRetakeClick}>
          {t('businessCardScanner.retake')}
        </Button>
        <LoadingButton
          fullWidth
          size='large'
          onClick={onFinish}
          loading={isSaving}
          disabled={!canClickSave}
          aria-busy={isSaving}
          aria-disabled={!canClickSave}
        >
          {t('save')}
        </LoadingButton>
      </BottomActionContainer>
    </FullScreenContainer>
  );
};
