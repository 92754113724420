import TooltipInfo from '@/components/TooltipInfo';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

type Props = {
  tooltipText: string;
  label: string;
  variant?: TypographyProps['variant'];
} & BoxProps;

export const TooltipText = ({ tooltipText, label, variant = 'body1' }: Props) => {
  return (
    <Box display='flex' alignItems='center' width='max-content'>
      <Typography sx={{ mr: 1 }} variant={variant}>
        {label}
      </Typography>
      <TooltipInfo text={tooltipText} icon={{ color: 'text.primary' }} />
    </Box>
  );
};
