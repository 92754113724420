import { useEffect } from 'react';
import { useAppDispatch } from '@/application/hooks';
import { dispatchTypes } from '@/application/actions';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useTranslation } from 'react-i18next';
import { defaultPalette } from '@/config/theme/palette';
import { Box, Button, Card, CardContent, Divider } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import { ColorPicker } from '@/components';

const COLORS = [
  'primaryButtonColor',
  'sidebarColor',
  'sidebarColorText',
  'sidebarColorActive',
  'sidebarColorActiveText',
];

const DEFAULT_COLORS = {
  primaryButtonColor: defaultPalette.primaryButton.main,
  sidebarColor: defaultPalette.sidebar.main,
  sidebarColorText: defaultPalette.sidebar.contrastText,
  sidebarColorActive: defaultPalette.sidebarActive.main,
  sidebarColorActiveText: defaultPalette.sidebarActive.contrastText,
};

type Props = {
  [k in typeof COLORS[number]]: string;
};

const Colors = (props: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();

  useEffect(() => {
    return function cleanup() {
      setIsUnsaved(false);
      dispatch({
        type: dispatchTypes.TEMP.THEME.RESETCOLORS,
      });
    };
  }, [dispatch, setIsUnsaved]);

  const setTempColor = payload => {
    dispatch({
      type: dispatchTypes.TEMP.THEME.SETCOLOR,
      payload,
    });
  };

  const resetColors = () => {
    dispatch({
      type: dispatchTypes.TEMP.THEME.SETCOLOR,
      payload: DEFAULT_COLORS,
    });
  };

  return (
    <Card>
      {COLORS.map((color, index) => {
        return (
          <>
            <ColorRow
              value={props[color] || DEFAULT_COLORS[color]}
              title={t(`organisation.${color}`)}
              onChange={withUnsavedSetter(val => setTempColor({ [color]: val }))}
              key={index}
            />
            {index !== COLORS.length - 1 && <Divider />}
          </>
        );
      })}
      <Box display={'flex'} justifyContent={'end'} pt={2} pb={4} pr={1.5}>
        <Button
          startIcon={<RestoreIcon />}
          variant='outlined'
          color='primary'
          size='small'
          onClick={withUnsavedSetter(() => resetColors())}
        >
          {t('organisation.resetColors')}
        </Button>
      </Box>
    </Card>
  );
};

export default Colors;

const ColorRow = ({
  value,
  title,
  onChange,
}: {
  value: string;
  title: string;
  onChange: (value: string) => void;
}) => {
  return (
    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ mr: 1, typography: 'h3' }}>{title}</Box>
      <ColorPicker value={value} onChange={onChange} ariaLabel={`${title} - ${value}`} />
    </CardContent>
  );
};
