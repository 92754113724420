export * from './global';
export * from './entities';
export * from './qrcodes';

export const THEME_LEADS_DEFAULT_PAGE_SIZE = 20;

export const UNITS_DEFAULT_PAGE_SIZE = 20;

export const THEME_LEADS_PAGE_SIZE = 20;

export const THEME_DEFAULT_PAGE_SIZE = 20;
