import { StrategyName, StrategyOptions } from '@/shared/types/api';

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_GLOBAL_TEST_CLIENT_ID; // legacy global client id by auth0

export const getTrySSOSetupLink = (connectionName: string) => {
  const queryParams = new URLSearchParams({
    client_id: clientId,
    response_type: 'code',
    connection: connectionName,
    prompt: 'login',
    scope: 'openid profile',
    redirect_uri: `https://manage.auth0.com/tester/callback?connection=${connectionName}`,
  });

  return `https://${auth0Domain}/authorize?${queryParams.toString()}`;
};

export const isValidOptions = (options?: StrategyOptions, strategy?: StrategyName) => {
  if (strategy === 'samlp') {
    if (options?.metaDataUrl) return true;
    if (options?.certificate && options?.signInUrl) return true;
  } else if (strategy === 'okta') {
    if (options?.oktaDomain && options?.clientID && options?.clientSecret) return true;
  } else if (strategy === 'waad') {
    if (
      options?.entraIdDomain &&
      options?.clientID &&
      options?.clientSecret &&
      options?.identityAPI
    )
      return true;
  }

  return false;
};
