import React, { useEffect, useMemo, useState, useImperativeHandle } from 'react';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { Employee } from '@/shared/types/api/employee.type';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import usePagination from '../hooks/usePagination';
import { TableProps } from '..';
import { COLUMNS_DEFAULT, initColumnsDef, saveColumnsDef } from '../utils/constants';
import { Account } from '@/shared/types/api';
import { Unit } from '@/shared/types/api/unit.type';
import BulkActions from './bulkActions';
import { DownloadableActions, EmailActions } from '../utils/actions';
import useActions from '../hooks/useActions';
import UnitAssign from './unitAssign';
import { FEATURE } from '@/shared/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { Box, Button, TablePagination, alpha } from '@mui/material';
import { bulkSendEmail } from '@/application/actions/md/profiles/bulk-update';
import { ColSortMenu, LtDialog } from '@/components';
import { CustomTableToolbar } from '@/components/Table';
import { ProfilesTable } from './ProfilesTable';
import { sendEmail } from '@/application/actions/md/profiles';
import { SortOption } from '@/shared/types/global';
import { SelectedCountCounter } from '../../SelectedCountCounter';
import {
  Close,
  DeleteOutline,
  DownloadOutlined,
  EditOutlined,
  SendOutlined,
} from '@mui/icons-material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import Loader from '@/components/Loader';

interface Props {
  numSelected: number;
  numEmployees: number;
  selectedIds: readonly string[];
  selectedUsernames: readonly string[];
  isSelected: (emp: Employee) => boolean;
  selectEmployee: (employee: Employee) => void;
  selectAllEmployees: () => void;
  unselectAllEmployees: () => void;
  employees: Array<Employee>;
  handleEdit: (employee: Employee) => void;
  bulkDelete: () => void;
  SelectedObjects: TableProps['SelectedObjects'];
  units?: Array<Unit>;
  refreshEmployees: () => void;
  orderBy?: string | null;
  sort?: 'asc' | 'desc' | null;
  selectAllOnPage: () => void;
  unselectAllOnPage: () => void;
  isAnySelectedOnCurrentPage: boolean;
  isAllSelected: boolean;
  selectedEmployees: Employee[];
  paginationProps: ReturnType<typeof usePagination>;
  onSortChange?: (sortOption: SortOption) => void;
  onBulkEditClick?: () => void;
}

export interface TableUiHandle {
  setPage: (newPage: number) => void;
}
const TableUi = React.forwardRef<TableUiHandle, Props>((props, forwardedRef) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();
  const isLoading = useAppSelector(state => state.md.isLoading);
  const { page, onPageChange, rowsPerPage } = props.paginationProps;
  const [selectAllVisible, setSelectAllVisibility] = useState(false);

  const theme = useAppSelector(state => state.account?.theme);

  const [columns, setColumns] = useState(
    initColumnsDef(isFeatureAllowed(FEATURE.UNITS), theme?.themeInternal?.multiUnitsAllowed),
  );
  const visibleColumns = useMemo(() => columns.filter(col => !col.hidden), [columns]);
  const [localEmployees, setLocalEmployees] = useState<Employee[]>([]);
  const [bulkInviteConfirmOpened, setBulkInviteConfirmOpened] = useState(false);

  // useSelection props
  const {
    numSelected,
    numEmployees,
    selectedIds,
    selectedUsernames,
    selectEmployee,
    selectAllEmployees,
    unselectAllEmployees,
    isAllSelected,
    isAnySelectedOnCurrentPage,
    selectAllOnPage,
    unselectAllOnPage,
    selectedEmployees,
    onSortChange,
  } = props;
  const account = useAppSelector<Account>(state => state.account);
  // employees, useEdit props, useBulk props
  const { employees, handleEdit, bulkDelete, orderBy, sort } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectAllVisibility(false);
  }, [employees, page]);

  const notActiveSelectedIds = useMemo(
    () =>
      employees?.reduce<string[]>((ids, employee) => {
        if (selectedIds?.includes(employee.id) && employee.status !== 'active')
          ids.push(employee.id);
        return ids;
      }, []),
    [employees, selectedIds],
  );

  useEffect(() => setLocalEmployees(employees), [employees]);

  const handleCheckboxClick = (employee: Employee) => {
    selectEmployee(employee);
  };

  const { actionLoading, ...exportDownloadHandlers } = useActions(selectedUsernames);

  const showBlockingLoader = isLoading || actionLoading;

  const downloadableActions = DownloadableActions(
    numSelected,
    selectedIds,
    exportDownloadHandlers,
    t,
  );
  const emailActions = EmailActions(numSelected, selectedIds, exportDownloadHandlers, t);

  const visibleEmployees =
    localEmployees?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || null;

  // to allow to reset the page when a search happens
  useImperativeHandle(forwardedRef, () => ({
    setPage: (newPage: number) => onPageChange(null, newPage),
  }));

  const handleBulkInvite = () => {
    dispatch(bulkSendEmail(notActiveSelectedIds, t, getAccessTokenSilently));
    setBulkInviteConfirmOpened(false);
  };

  const handleHeaderCheckboxClick = () => {
    if (isAnySelectedOnCurrentPage) {
      unselectAllOnPage();
      setSelectAllVisibility(false);
      return;
    }
    selectAllOnPage();
    if (numSelected < numEmployees) {
      setSelectAllVisibility(true);
    }
  };

  const handleDeselectAll = () => {
    unselectAllEmployees();
    setSelectAllVisibility(false);
  };

  const handleSelectAll = () => {
    selectAllEmployees();
    setSelectAllVisibility(false);
  };

  const sendInvitationEmail = (employee: Employee) => {
    dispatch(
      sendEmail(employee.id, employee.email, getAccessTokenSilently, t, () =>
        toast.success(t('invited')),
      ),
    );
  };

  return (
    <>
      {showBlockingLoader && <Loader />}

      <CustomTableToolbar
        sx={theme => ({
          bgcolor: alpha(theme.palette.primary.main, 0.04),
          py: 0.5,
        })}
      >
        {numSelected > 0 && (
          <Box display='flex' alignItems='center' flexWrap='wrap' gap={1.6}>
            <SelectedCountCounter
              onSelectAll={handleSelectAll}
              selectAllVisible={selectAllVisible}
              selectedCount={numSelected}
              total={numEmployees}
            />
            <Button
              variant='text'
              color='primary'
              startIcon={<Close />}
              onClick={handleDeselectAll}
            >
              {t('deselectAll')}
            </Button>
          </Box>
        )}
        {numSelected > 0 && (
          <Box display='flex' flexWrap='wrap' gap={1.6}>
            {notActiveSelectedIds.length > 0 && (
              <Button
                variant='outlined'
                color='primary'
                startIcon={<SendOutlined />}
                onClick={() => setBulkInviteConfirmOpened(true)}
                aria-label={t('ariaSendInviteToSelected')}
              >
                {t('sendInvite')}
              </Button>
            )}

            <Button
              variant='outlined'
              color='primary'
              startIcon={<EditOutlined />}
              onClick={
                numSelected > 1
                  ? props.onBulkEditClick
                  : () => handleEdit(localEmployees?.find(e => selectedIds.some(id => id === e.id)))
              }
              aria-label={t('ariaBulkEditSelected')}
            >
              {t('bulkEditProfiles')}
            </Button>

            <BulkActions
              label={t('export.title')}
              aria-label={t('ariaExportSelected')}
              actionGroups={[
                {
                  title: t('download'),
                  actions: downloadableActions,
                },
                {
                  title: t('sendViaEmail'),
                  actions: emailActions,
                },
              ]}
              icon={<DownloadOutlined />}
            />

            {isFeatureAllowed(FEATURE.UNITS) && props.units && props.units.length > 0 && (
              <UnitAssign
                units={!isThemeAdmin ? account.units : props.units}
                selectedIds={selectedIds}
                localEmployees={localEmployees}
                setLocalEmployees={setLocalEmployees}
                onSaveSuccess={() => {
                  props.refreshEmployees();
                }}
              />
            )}
            <Button
              color='error'
              variant='outlined'
              startIcon={<DeleteOutline />}
              onClick={bulkDelete}
            >
              {t('delete')}
            </Button>
          </Box>
        )}
        {numSelected === 0 && (
          <Box ml='auto'>
            <ColSortMenu
              columnDefs={columns}
              setColumnDefs={newDef => {
                setColumns(newDef);
                saveColumnsDef(
                  newDef,
                  isFeatureAllowed(FEATURE.UNITS),
                  theme.themeInternal.multiUnitsAllowed,
                );
              }}
              reset={() => {
                const newDef = [
                  ...COLUMNS_DEFAULT(
                    isFeatureAllowed(FEATURE.UNITS),
                    theme.themeInternal.multiUnitsAllowed,
                  ),
                ];
                setColumns(newDef);
                saveColumnsDef(
                  newDef,
                  isFeatureAllowed(FEATURE.UNITS),
                  theme.themeInternal.multiUnitsAllowed,
                );
              }}
            />
          </Box>
        )}
      </CustomTableToolbar>

      <ProfilesTable
        employees={visibleEmployees}
        onCheckboxClick={handleCheckboxClick}
        onHeaderCheckboxClick={handleHeaderCheckboxClick}
        isAllSelected={isAllSelected}
        isAnySelectedOnCurrentPage={isAnySelectedOnCurrentPage}
        selectedItems={selectedEmployees}
        columns={visibleColumns}
        sortOptions={{ orderBy, sort }}
        onSortOptionsChange={onSortChange}
        linkProps={props.SelectedObjects.selectedLinksProps}
        fileProps={props.SelectedObjects.selectedFilesProps}
        onEditClick={empId => handleEdit(visibleEmployees.find(e => e.id === empId))}
        sendInvitationEmail={sendInvitationEmail}
      />

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={t('rowsPerPage')}
        component='div'
        count={numEmployees}
        {...props.paginationProps}
        nextIconButtonProps={{ 'aria-label': t('showNextPage') }}
        backIconButtonProps={{ 'aria-label': t('showPreviousPage') }}
      />
      <LtDialog
        onConfirm={handleBulkInvite}
        onCancel={() => setBulkInviteConfirmOpened(false)}
        onClose={() => setBulkInviteConfirmOpened(false)}
        open={bulkInviteConfirmOpened}
        title={t('bulkActions.invite.title')}
      >
        {t('bulkActions.invite.confirm')}
      </LtDialog>
    </>
  );
});

export default TableUi;
