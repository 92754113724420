import { useMemo } from 'react';
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  isChrome,
  isChromium,
  isFirefox,
  isSafari,
  isEdge,
  isEdgeChromium,
  isOpera,
} from 'react-device-detect';

export default function useDeviceInfo() {
  const isStandalone = useMemo(
    () =>
      ('standalone' in window.navigator && window.navigator['standalone'] === true) || // iOS
      window.matchMedia('(display-mode: standalone)').matches, // Android
    [],
  );

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIOS,
    isStandalone,
    isChrome: isChrome || isChromium,
    isFirefox,
    isSafari,
    isEdge: isEdge || isEdgeChromium,
    isOpera,
  };
}
