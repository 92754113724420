import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/system';

export default function SkeletonStack() {
  return (
    <Stack spacing={1}>
      <Skeleton variant='text' width='100%' />
      <Skeleton variant='text' width='100%' />
      <Skeleton variant='text' width='100%' />
    </Stack>
  );
}
