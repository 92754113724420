import ScreenRenderer from './ScreenRenderer';
import { PageContainer } from '@/components';
import { Box } from '@mui/material';
import { PhoneAndroidOutlined, VideocamOutlined } from '@mui/icons-material';
import withNav from '@/infrastructure/hoc/withNav';
import { useEffect, useMemo } from 'react';
import { routePaths } from '@/infrastructure/constants';
import { SCREEN } from './common/constants';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useHistory } from 'react-router-dom';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { LtTabsProps } from '@/components/LtTabs';

const QRCodeBG = () => {
  const { t } = useAppTranslation();
  const history = useHistory();
  const { desktopView } = useDesktopView();
  useEffect(() => {
    if (!desktopView) history.push(routePaths.QR_CODE_BACKGROUND.PHONE);
  }, [desktopView, history]);

  const tabs: LtTabsProps = useMemo(
    () => ({
      orientation: 'horizontal',
      tabs: [
        {
          icon: <PhoneAndroidOutlined />,
          iconPosition: 'start' as const,
          value: SCREEN.MOBILE,
          label: t('qrCodeBackground.mobile.label'),
          component: (
            <Box py={4} px={2}>
              <ScreenRenderer key={SCREEN.MOBILE} screen={SCREEN.MOBILE} />
            </Box>
          ),
        },
        {
          icon: <VideocamOutlined />,
          iconPosition: 'start' as const,
          value: SCREEN.VIDEOCALL,
          label: t('qrCodeBackground.videocall.label'),
          component: (
            <Box py={4} px={2}>
              <ScreenRenderer key={SCREEN.VIDEOCALL} screen={SCREEN.VIDEOCALL} />
            </Box>
          ),
        },
      ],
    }),
    [t],
  );

  return <PageContainer maxWidth='m' whiteCardWrapper tabs={tabs} />;
};

export default withNav(
  QRCodeBG,
  {
    tTitle: 'qrWallpaper',
    showBackButton: {
      linkTo: routePaths.SHARE_PROFILE,
    },
  },
  {},
);
