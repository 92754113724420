import { getProfileImageUrl } from '@/shared/business-logic/features/profile/getter';
import { ImageSettings } from '@/components/Profile/components/ImageSettings';
import { ProfileEditProps } from '@/components/Profile/types';
import { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutline } from '@mui/icons-material';

type Props = ProfileEditProps['profileImageEditProps'] & {
  CustomAnchor?: ComponentProps<typeof ImageSettings>['CustomAnchor'];
};

export default function EditProfileImage({
  account,
  profileDesign,
  editRightAllowed,
  onUpdateByFile,
  onResetToDefault,
  CustomAnchor,
}: Props) {
  const { t } = useTranslation();
  const profileImageUrlToDisplay = getProfileImageUrl(account, profileDesign);

  const labelOverrides = useMemo(() => ({ reset: t('delete') }), [t]);
  const iconOverrides = useMemo(() => ({ reset: <DeleteOutline /> }), []);

  return (
    <ImageSettings
      disallowed={!editRightAllowed}
      currentUrl={profileImageUrlToDisplay}
      imageState={account.profileImageState}
      defaultImageState={profileDesign.defaultProfileImageState}
      crop={{ circularCrop: true }}
      onUploadByFile={onUpdateByFile}
      onResetToDefault={onResetToDefault}
      onDelete={onResetToDefault}
      CustomAnchor={CustomAnchor}
      ariaLabel={t('ariaEditProfilePicture')}
      actionLabelOverrides={labelOverrides}
      actionIconOverrides={iconOverrides}
    />
  );
}
