import { Box, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { ComponentProps } from 'react';

type Props = {
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  headingActions?: React.ReactNode;
  px?: number;
  py?: number;
  sx?: ComponentProps<typeof Card>['sx'];
} & ComponentProps<typeof Card>;

export const CardRenderer = ({
  title,
  children,
  actions,
  headingActions,
  px,
  py,
  sx,
  ...cardProps
}: Props) => {
  return (
    <Card
      variant='elevation'
      sx={{ display: 'flex', flexDirection: 'column', ...sx }}
      {...cardProps}
    >
      {(title || headingActions) && (
        <>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...(headingActions && { pt: 0.5, pb: 0.5 }),
            }}
          >
            {title && (
              <Typography component='h2' variant='h4'>
                {title}
              </Typography>
            )}
            {headingActions && (
              <Box display={'flex'} alignItems={'center'}>
                {headingActions}
              </Box>
            )}
          </CardContent>

          <Divider />
        </>
      )}
      <CardContent sx={{ flex: 1, px, py }}>{children}</CardContent>
      {actions && (
        <CardActions sx={{ pt: 0 }}>
          <Box flexGrow={1}></Box>
          {actions}
        </CardActions>
      )}
    </Card>
  );
};
