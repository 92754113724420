import { Box, Typography } from '@mui/material';

type Props = {
  children: React.ReactNode;
  title: string;
  titleCenter?: boolean;
  description?: string;
};

export const OnboardingScreenWrapper = ({ children, title, titleCenter, description }: Props) => {
  return (
    <Box>
      <Typography textAlign={titleCenter ? 'center' : 'start'} variant='h1'>
        {title}
      </Typography>
      {description && (
        <Typography variant='body2' color='secondary' mt={3}>
          {description}
        </Typography>
      )}
      <Box mt={2.4}>{children}</Box>
    </Box>
  );
};
