import { useTranslation } from 'react-i18next';
import LtDialog from '../LtDialog';
import { Link } from '@/shared/types/api';
import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { AddEditLinkDialogProps } from './types';
import { DeleteOutline } from '@mui/icons-material';
import { AddEditUserLinkForm } from './AddEditUserLinkForm';

export const AddEditUserLinkDialog = ({
  linkTypes,
  onClose,
  open,
  initialValues,
  onSave,
  templates,
  loading,
  onDelete,
}: AddEditLinkDialogProps) => {
  const [values, setValues] = useState<Partial<Link>>(initialValues || {});
  const isEdit = Boolean(values.id);
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialValues) return;
    setValues(initialValues);
  }, [initialValues]);

  /** need timeout to keep smooth dialog close/open animation */
  useEffect(() => {
    let timeoutId;
    if (!open) {
      timeoutId = setTimeout(() => setValues({}), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  const handleSubmit = () => {
    const payload = { ...values };

    /**
     * If the selected templateId is -1, remove the linkTemplateId from the payload
     */
    if (payload.linkTemplateId === -1) payload.linkTemplateId = null;
    onSave(payload);
  };

  return (
    <LtDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      saveAction={{
        text: t('save'),
        onClick: handleSubmit,
        disabled: !values.linkTypeId || !values.link || !values.name,
        loading,
      }}
      title={initialValues ? t('editLink') : t('createLink')}
      size='sm'
      withActionDivider
    >
      <AddEditUserLinkForm
        linkTypes={linkTypes}
        values={values}
        onChange={setValues}
        templates={templates}
      />

      <Box mt={2} display='flex'>
        {onDelete && isEdit && (
          <Button
            sx={{ width: 'max-content', ml: 'auto' }}
            variant='text'
            startIcon={<DeleteOutline />}
            onClick={() => onDelete(values.id)}
          >
            {t('deleteLink')}
          </Button>
        )}
      </Box>
    </LtDialog>
  );
};
