import { Box, CardContent, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ title: string }>;
const ContentWrapper = ({ title, children }: Props) => {
  return (
    <Box>
      <CardContent sx={{ width: '100%', borderBottom: '1px solid', borderColor: 'divider' }}>
        <Typography variant='h2'>{title}</Typography>
      </CardContent>
      {children}
    </Box>
  );
};

export default ContentWrapper;
