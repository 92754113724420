import { LinkType } from '@/shared/types/api';
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  linkTypes: LinkType[];
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  genericWebsiteColor?: string;
};
export const LinkTypeSelector = ({
  linkTypes,
  onChange,
  value,
  disabled,
  genericWebsiteColor,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id='labelId'>{t('chooseLink')}</InputLabel>
      <Select
        value={value || ''}
        labelId={'labelId'}
        label={t('chooseLink')}
        onChange={({ target: { value } }) => onChange(value as number)}
      >
        <MenuItem value='' sx={{ display: 'none' }}>
          <ListItemText>{t('chooseLink')}</ListItemText>
        </MenuItem>
        {linkTypes.map(linkType => (
          <MenuItem key={linkType.id} value={linkType.id}>
            <Box display='flex' alignItems='center'>
              <IconWrapper
                sx={{
                  background: linkType.backgroundCss || genericWebsiteColor || '#152570',
                }}
              >
                <img src={linkType.iconUrl} alt={linkType.name} />
              </IconWrapper>
              <ListItemText>{linkType.name}</ListItemText>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const IconWrapper = styled(Box)(({ bgcolor }) => ({
  padding: '0.6rem',
  marginRight: '0.8rem',
  borderRadius: '50%',
  backgroundColor: bgcolor,
  height: 'max-content',
  img: {
    height: '1.5rem',
    display: 'block',
  },
}));
