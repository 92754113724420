import React, { useEffect, useMemo, useState } from 'react';
import { Screen1, Screen2, Screen3, Screen3Card } from './screens';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../../application/hooks';
import { useHistory } from 'react-router-dom';
import { onboardingNotify } from '../../../util';
import { Account, Theme } from '../../../shared/types/api';
import { getRedirectedRoute } from './screens/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import lemon from '@/views/images/onboarding/image-3.png';
import lemonTapsLogo from '@/views/images/lemontaps-logo.png';
import { West } from '@mui/icons-material';
import wallet from '@/views/images/onboarding-wallet.png';
import { PageContainer } from '@/components';
import { green } from '@mui/material/colors';

const OnBoarding = () => {
  const { t } = useAppTranslation();

  // when this is rendered, the email has been verified
  const { getAccessTokenSilently, user } = useAuth0();
  const account = useAppSelector<Account>(state => state.account);
  const { isStarter, isTrial } = useTierInfo();
  const isCardOnboarding = account.origin === 'card';
  useEffect(() => {
    if (user.email_verified) {
      onboardingNotify('email-verified', getAccessTokenSilently);
    }
  }, [getAccessTokenSilently, user?.email_verified, t]);

  const SCREENS = useMemo(
    () =>
      Array.from(
        { length: (isCardOnboarding ? 4 : 3) - (!isStarter && !isTrial ? 1 : 0) },
        (_, i) => i + 1,
      ),
    [isCardOnboarding, isStarter, isTrial],
  );
  const [screenNumber, setScreenNumber] = useState(1);

  const buttonTitle = 'next';

  // to prevent the image width to "jump"
  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [screenNumber]);

  const history = useHistory();
  const redirectHandler = () => {
    const redirectedRoute = getRedirectedRoute(account?.origin);
    history.push(redirectedRoute);
  };

  const nextScreen = () => {
    if (!isStarter && !isTrial && screenNumber === SCREENS.length) {
      redirectHandler();
    } else {
      setScreenNumber(screenNumber + 1);
    }
  };
  const handleBack = () => {
    setScreenNumber(screenNumber - 1);
  };

  const handleSkip = () => {
    if (screenNumber === SCREENS.length) {
      redirectHandler();
      onboardingNotify('meta-page-skipped', getAccessTokenSilently);
    } else {
      nextScreen();
      if (screenNumber === 2) onboardingNotify('add-to-wallet-skipped', getAccessTokenSilently);
    }
  };

  const skipVisible = screenNumber === 2 || (isCardOnboarding && screenNumber === 3);

  const theme: Theme = useAppSelector(state => state.account.theme);

  const logo =
    theme?.themeInternal?.logoWideBrightUrl || theme?.themeInternal?.logoWideUrl || lemonTapsLogo;

  const leftSideSheet = useMemo(
    () => ({
      bgImg: lemon,
      logoImg: screenNumber !== 2 ? logo : undefined,
      invertLogo: !theme?.themeInternal.logoWideBrightUrl && theme?.themeInternal.logoWideInvert,
      bgColor: green[50],
      contentImg: screenNumber === 2 && wallet,
    }),
    [logo, screenNumber, theme],
  );

  return (
    <PageContainer
      maxWidth='s'
      leftSideSheet={leftSideSheet}
      topActionBar={{
        renderCard: false,
        position: 'relative',
        customActionsPre:
          screenNumber !== 1
            ? [
                {
                  variant: 'text',
                  startIcon: <West />,
                  text: t('back'),
                  onClick: handleBack,
                  sx: { mr: 'auto' },
                },
              ]
            : undefined,
        customActionsPost: skipVisible
          ? [{ variant: 'text', text: t('skip'), onClick: handleSkip }]
          : undefined,
      }}
    >
      <>
        {screenNumber === 1 && <Screen1 next={nextScreen} />}
        {screenNumber === 2 && <Screen2 next={nextScreen} />}
        {isCardOnboarding ? (
          screenNumber === 3 ? (
            <>
              <Screen3Card
                next={() => {
                  nextScreen();
                  onboardingNotify('how-to-tap-completed', getAccessTokenSilently);
                }}
                buttonTitle={t(buttonTitle)}
              />
            </>
          ) : (
            screenNumber === 4 && <Screen3 />
          )
        ) : (
          screenNumber === 3 && <Screen3 />
        )}
      </>
    </PageContainer>
  );
};

export default OnBoarding;
