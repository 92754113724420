import { Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  count?: number;
};

export const ScimSyncedUsersCount = ({ count }: Props) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography lineHeight={2} color='text.secondary' variant='body2'>
          {t('idp.scim.syncedUsers')}
        </Typography>
        <Typography lineHeight={1.5} variant='h4' component='div'>
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};
