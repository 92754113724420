import { FontWeight, MuiTypography } from '@/shared/types/api';
import { defaultPalette } from './palette';

export const typographyVariants = {
  h1: {
    fontWeight: FontWeight.Medium,
    fontSize: '2rem',
    letterSpacing: '0',
  },
  h2: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.8rem',
    letterSpacing: '0',
  },
  h3: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.6rem',
    letterSpacing: '0',
  },
  h4: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.4rem',
    letterSpacing: '0',
  },
  h5: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.4rem',
    letterSpacing: '0',
  },
  h6: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.0rem',
    letterSpacing: '0',
  },
  subtitle1: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.6rem',
    letterSpacing: '0',
  },
  subtitle2: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.4rem',
    letterSpacing: '0',
  },
  body1: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.6rem',
    letterSpacing: '0',
  },
  body2: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.4rem',
    letterSpacing: '0',
    color: defaultPalette.text.secondary,
  },
  button: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.4rem',
    letterSpacing: '0',
    textTransform: 'none',
  },
  caption: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.2rem',
    letterSpacing: '0',
  },
  overline: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.2rem',
    letterSpacing: '0',
  },
  //customs
  pageTitle: {
    fontWeight: FontWeight.Bold,
    fontSize: '2.2rem',
    letterSpacing: '0',
  },
  avatarLetter: {
    fontWeight: FontWeight.Regular,
    fontSize: '2.0rem',
    letterSpacing: '0',
  },
  inputLabel: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.2rem',
    letterSpacing: '0',
  },
  helperText: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.2rem',
    letterSpacing: '0',
  },
  inputText: {
    fontWeight: FontWeight.Regular,
    fontSize: '1.6rem',
    letterSpacing: '0',
  },
  tooltip: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.0rem',
    letterSpacing: '0',
  },
};
export const defaultTypography: MuiTypography = {
  fontFamily: 'Poppins, sans-serif',
  ...typographyVariants,
};
