import { Box, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { SettingsMenu } from '@/components';
import { MenuItemType } from '@/components/SettingsMenu';

type Props = {
  actions: MenuItemType[];
  el: JSX.Element;
};

export const ImageSettingsMenu = ({ actions, el }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <ThemeProvider theme={muiTheme}>
      <Box onClick={handleClick}>{el}</Box>
      <SettingsMenu
        renderAnchor={false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        menuItems={actions}
      />
    </ThemeProvider>
  );
};
