import { CustomBioTextBox } from '@/shared/types/api/profile-page.type';
import ContentBoxUi from '../../components/ContentBoxUI';
import { Button, Typography } from '@mui/material';
import { ProfileEditProps } from '../../types';
import { useMemo } from 'react';
import { EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

interface Props {
  config: CustomBioTextBox;
  editProps?: ProfileEditProps['customBioTextEditProps'];
}

export default function BioTextBox({ config, editProps }: Props) {
  const { t } = useTranslation();
  const { notifyInfo } = useLtNotifications();

  const isEdit = !!editProps?.isEdit;
  const editRightAllowed = !!editProps?.editRightAllowed;
  const editButton = useMemo(
    () =>
      isEdit ? (
        <Button
          startIcon={<EditOutlined />}
          {...(editRightAllowed
            ? { component: Link, to: { pathname: routePaths.EDITBIO, state: { focus: 'bioText' } } }
            : { onClick: () => notifyInfo(t('adminDisabledEditRight')) })}
        >
          {t('editBio')}
        </Button>
      ) : undefined,
    [editRightAllowed, isEdit, notifyInfo, t],
  );
  return (
    <ContentBoxUi title={config.label} actionButton={editButton} isEdit={isEdit}>
      <Typography variant='body1' color='secondary' whiteSpace='pre-line'>
        {config.text}
      </Typography>
    </ContentBoxUi>
  );
}
