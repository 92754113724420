import TooltipInfo from '@/components/TooltipInfo';
import { emailRegexp } from '@/shared/util';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { Box, Button, FormControlLabel, IconButton, Switch, TextField } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const AddEmployeeAccountDetailsFields = ({ control }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: 'accounts',
    control,
  });

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: fields.length > 1 ? '2fr 2fr 3fr max-content' : '2fr 2fr 3fr',
            gap: '2rem',
            mb: 2,
          }}
        >
          <Controller
            control={control}
            name={`accounts[${index}].firstName`}
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field }) => (
              <TextField
                inputProps={field}
                label={t('firstName')}
                error={Boolean(errors.accounts?.[index]?.firstName)}
                aria-invalid={Boolean(errors.accounts?.[index]?.firstName)}
                helperText={errors.accounts?.[index]?.firstName?.message}
                fullWidth
                required
              />
            )}
          />

          <Controller
            control={control}
            name={`accounts[${index}].lastName`}
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field }) => (
              <TextField
                inputProps={field}
                label={t('lastName')}
                error={Boolean(errors.accounts?.[index]?.lastName)}
                aria-invalid={Boolean(errors.accounts?.[index]?.lastName)}
                helperText={errors.accounts?.[index]?.lastName?.message}
                fullWidth
                required
              />
            )}
          />

          <Controller
            control={control}
            name={`accounts[${index}].email`}
            rules={{
              required: { message: t('error.required'), value: true },
              pattern: { value: emailRegexp, message: t('error.invalidEmail') },
            }}
            render={({ field }) => (
              <TextField
                inputProps={field}
                label={t('email')}
                error={Boolean(errors.accounts?.[index]?.email)}
                aria-invalid={Boolean(errors.accounts?.[index]?.email)}
                helperText={errors.accounts?.[index]?.email?.message}
                fullWidth
                required
              />
            )}
          />
          {fields.length > 1 && (
            <Box display='flex' m='auto'>
              <IconButton>
                <DeleteOutline onClick={() => remove(index)} />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}

      <Box display='flex' alignItems='center' mt={2}>
        <Controller
          control={control}
          name='sendInvite'
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={<Switch />}
              label={t('sendInvite')}
              aria-label={t('ariaSendInviteAfterUserCreate')}
            />
          )}
        />

        <TooltipInfo text={t('sendInviteTooltip')} onHover icon={{ color: 'primary' }} />
        <Button
          startIcon={<AddCircleOutline />}
          variant='outlined'
          color='primary'
          sx={{ ml: 'auto' }}
          onClick={() =>
            append({
              firstName: '',
              lastName: '',
              email: '',
            })
          }
        >
          {t('addAnotherUser')}
        </Button>
      </Box>
    </Box>
  );
};
