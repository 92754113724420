import { MarkdownRenderer } from '@/components';
import { ChecklistItemAction, SubActionType } from '@/shared/types/global';
import { Box, Button, Card, CardMedia, Typography } from '@mui/material';
import { checkConditions } from '../util';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useTranslation } from 'react-i18next';

const SubActionItem = ({
  subaction,
  handlePopupConfirm,
  handleActionButtonClick,
  checked,
  actionTitle,
}: {
  subaction: SubActionType;
  handlePopupConfirm: () => void;
  handleActionButtonClick: (action: ChecklistItemAction) => void;
  checked: boolean;
  actionTitle?: string;
}) => {
  const tierInfo = useTierInfo();
  const { t } = useTranslation();

  return (
    <>
      <Card sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1, flexBasis: '48%' }}>
        {subaction?.imgUrl && (
          <Box sx={{ maxWidth: '4rem', minHeight: '4rem', display: 'flex', alignItems: 'center' }}>
            <CardMedia component='img' image={subaction.imgUrl} />
          </Box>
        )}
        {subaction?.title && <Typography variant='h4'>{subaction.title}</Typography>}
        {subaction?.description && (
          <Typography variant='body2' color='secondary'>
            {subaction.description}
          </Typography>
        )}
        {(subaction?.action || Boolean(subaction?.learnMoreUrl)) && (
          <Box display={'flex'} alignItems={'baseline'} mt='auto' gap={1}>
            {Boolean(subaction?.action?.label) &&
              checkConditions(tierInfo, subaction.action?.conditions) && (
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => handleActionButtonClick(subaction.action)}
                  sx={checked ? { pointerEvents: 'none' } : null}
                  aria-label={subaction.action.label + ' - ' + (subaction.title || actionTitle)}
                >
                  {subaction.action.label}
                </Button>
              )}
            {Boolean(subaction?.learnMoreUrl) && (
              <MarkdownRenderer
                content={`:external-link[${t('learnMore')}]{url="${subaction.learnMoreUrl}"}`}
              />
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

export default SubActionItem;
