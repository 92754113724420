import { LOCAL_STORAGE } from '@/infrastructure/constants';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';

export const UNITS_TABLE_COLUMNS: ColumnDefinition[] = [
  { key: 'niceName', tkey: 'unit' },
  { key: 'numAccounts', tkey: 'mdUnits.members' },
  { key: 'numUnitAdmins', tkey: 'mdUnits.admins' },
  { key: 'viewMembers', tkey: 'mdUnits.viewMembers' },
];

export const initColumnsDef = () => {
  const res = localStorage.getItem(LOCAL_STORAGE.ltMdUnitsTableColumns);

  const columns: ColumnDefinition[] = res ? JSON.parse(res) : UNITS_TABLE_COLUMNS;

  return columns;
};

export const saveUnitsTableColumns = cols => {
  localStorage.setItem(LOCAL_STORAGE.ltMdUnitsTableColumns, JSON.stringify(cols));
};

export const resetColumnsDef = () => {
  localStorage.removeItem(LOCAL_STORAGE.ltMdUnitsTableColumns);
  return initColumnsDef();
};
