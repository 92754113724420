import { Auth0ContextInterface } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import { PERMISSIONS } from '../constants';
import config from '@/config/config';

export type LinkTemplatePayload = {
  id?: number;
  name?: string;
  link: string;
  linkTypeId: number;
};

export const getLinkTemplates = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}theme/link-templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error fetching link templates');
  }
  return res.data;
};

export const deleteLinkTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: number,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(`${config.API_BASE_URL}theme/link-templates/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error deleting link template');
  }
  return res.data;
};

export const createLinkTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: LinkTemplatePayload,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + `theme/link-templates`,
        {
          linkTemplateEntity: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error creating link template');
  }
  return res.data;
};

export const updateLinkTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: LinkTemplatePayload,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.patch(
        config.API_BASE_URL + `theme/link-templates/${payload.id}`,
        {
          linkTemplateEntity: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error deleting link template');
  }
  return res.data;
};
