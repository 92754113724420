import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, CardMedia, IconButton, styled } from '@mui/material';
import { Swiper, SwiperSlide, SwiperRef, SwiperProps } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Pagination } from 'swiper';

type Props = {
  images?: string[];
} & SwiperProps;

const arrowStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  bgcolor: 'white',
  zIndex: 2,
  backgroundOpacity: 0.5,
  '&.Mui-disabled': {
    bgcolor: 'white',
    opacity: 0.5,
  },
  '&:hover': {
    backgroundColor: 'white',
    opacity: 0.8,
  },
};

export const ImagesSlider = forwardRef(({ images, ...sliderProps }: Props, ref) => {
  const [swiper, setSwiper] = useState<SwiperRef['swiper']>();
  const [arrowVisible, setArrowVisible] = useState({
    left: false,
    right: false,
  });

  useEffect(() => {
    if (!swiper) return;

    const updateFct = (swiper: SwiperRef['swiper']) =>
      setArrowVisible({ left: !swiper.isBeginning, right: !swiper.isEnd });
    updateFct(swiper);
    swiper.on('slideChange', updateFct);
  }, [swiper]);

  useImperativeHandle(ref, () => ({
    getActiveIndex: () => swiper?.activeIndex,
  }));

  return (
    <Box sx={{ position: 'relative', width: '100%' }} position={'relative'}>
      {images.length > 1 && (
        <IconButton
          size='small'
          color='primary'
          sx={{ ...arrowStyles, left: '1rem' }}
          onClick={() => swiper?.slidePrev()}
          disabled={!arrowVisible.left}
        >
          <ChevronLeft />
        </IconButton>
      )}

      <StyledSwiperWrapper>
        {images.length > 0 && (
          <Swiper
            modules={[Pagination]}
            style={{ width: '100%' }}
            pagination={{ clickable: true }}
            spaceBetween={20}
            slidesPerView={1}
            onSwiper={setSwiper}
            {...sliderProps}
          >
            {images?.map((img, index) => (
              <SwiperSlide key={index}>
                <Box pb={2}>
                  <CardMedia
                    component={'img'}
                    width='100%'
                    height='100%'
                    src={img}
                    alt={'slide-' + index}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </StyledSwiperWrapper>
      {images.length > 1 && (
        <IconButton
          size='small'
          sx={{ ...arrowStyles, right: '1rem' }}
          onClick={() => swiper?.slideNext()}
          disabled={!arrowVisible.right}
        >
          <ChevronRight />
        </IconButton>
      )}
    </Box>
  );
});

const StyledSwiperWrapper = styled(Box)({
  width: '100%',
  '.swiper-pagination': {
    bottom: 0,

    '.swiper-pagination-bullet': {
      backgroundColor: 'black',
      borderRadius: '0',
      width: '1.6rem',
      height: '0.4rem',
    },
  },
});
