import EastIcon from '@mui/icons-material/East';
import LtLoadingButton, { LtLoadingButtonProps } from '@/components/LtLoadingButton';

export const ContinueButton = ({ children, ...rest }: LtLoadingButtonProps) => {
  return (
    <LtLoadingButton size='large' endIcon={<EastIcon />} {...rest} fullWidth>
      {children}
    </LtLoadingButton>
  );
};
