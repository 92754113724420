import { LtDialog, MarkdownRenderer } from '@/components';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { ChecklistItem, ChecklistItemAction } from '@/shared/types/global';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import SubActionItem from './SubActionItem';
import LtVideo from '@/components/LtVideo';
import GsAlert from './GsAlert';
import GsBadge from './GsBadge';
import { checkConditions } from '../util';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { useHistory } from 'react-router-dom';

const CheckListItem = ({
  item,
  keys,
  setKeys,
}: {
  item: ChecklistItem;
  keys: string[];
  setKeys: Setter<string[]>;
}) => {
  const { t } = useAppTranslation();
  const tierInfo = useTierInfo();
  const [showMarkdownPopup, setShowMarkdownPopup] = useState<ChecklistItemAction>(null);
  const [showVideoPopup, setShowVideoPopup] = useState<ChecklistItemAction>(null);

  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();
  const history = useHistory();

  const handleActionButtonClick = (buttonAction: ChecklistItemAction) => {
    if (
      (buttonAction.conditionsShowUpgrade
        ? checkConditions(tierInfo, buttonAction.conditionsShowUpgrade)
        : false) ||
      (item.featureGate && !tierInfo.isFeatureAllowed(item.featureGate))
    ) {
      upgradeAction();
      return;
    }

    switch (buttonAction.action.type) {
      case 'link':
        if (buttonAction.action.external) window.open(buttonAction.action.link, '_blank');
        else history.push(buttonAction.action.link);
        break;
      case 'popup':
        setShowMarkdownPopup(buttonAction);
        break;
      case 'video':
        setShowVideoPopup(buttonAction);
        break;
    }
  };

  const checked = keys.includes(item?.key);

  const handleChecked = () => {
    checked ? setKeys(keys.filter(key => key !== item.key)) : setKeys([...keys, item.key]);
  };

  const handleAlwaysChecked = () => !keys.includes(item?.key) && setKeys([...keys, item.key]);

  const closePopup = useCallback(() => {
    setShowMarkdownPopup(null);
    setShowVideoPopup(null);
  }, []);

  return (
    <Box key={item?.key} display={'flex'} flexDirection={'column'} alignItems={'start'} gap={1}>
      <Box
        display={'flex'}
        alignItems={'start'}
        justifyContent={'start'}
        sx={checked && { opacity: 0.5 }}
      >
        <Checkbox checked={checked} onChange={handleChecked} sx={{ p: 0, mr: 1 }} />
        <Box>
          <Box display={'flex'} gap={1} mb={0.5}>
            <Typography variant='body1'>{item.title}</Typography>
            <GsBadge
              badge={
                item.badge ||
                (item.featureGate && !tierInfo.isFeatureAllowed(item.featureGate)
                  ? { text: 'Addon' }
                  : undefined)
              }
            />
          </Box>
          <GsAlert currentPosition='pre' alert={item.alert} />
          {item.video && item.video.position === 'pre' && (
            <LtVideo embedUrl={item.video.embedUrl} />
          )}
          {Boolean(item?.description) && (
            <Typography variant='body2'>
              <MarkdownRenderer
                content={
                  item.description +
                  (item.learnMoreUrl
                    ? ` :external-link[${t('learnMore')}]{url="${item.learnMoreUrl}"}`
                    : '')
                }
                containerSx={{
                  color: 'text.secondary',
                  pointerEvents: checked ? 'none' : undefined,
                }}
              />
            </Typography>
          )}
          {item.video && item.video.position === 'post' && (
            <LtVideo embedUrl={item.video.embedUrl} sx={{ maxWidth: '48rem' }} />
          )}
          <GsAlert currentPosition='post' alert={item.alert} />
          {(item?.mainAction || !item?.unskippable) && (
            <Box mt={1}>
              {Boolean(item?.mainAction?.label) &&
                checkConditions(tierInfo, item.mainAction?.conditions) && (
                  <Button
                    size='medium'
                    variant='outlined'
                    color='primary'
                    onClick={() => handleActionButtonClick(item.mainAction)}
                    sx={{ mr: 1, ...(checked && { pointerEvents: 'none' }) }}
                  >
                    {item.mainAction.label}
                  </Button>
                )}
              {!item?.unskippable && !checked && (
                <Button
                  size='medium'
                  variant='text'
                  sx={theme => ({ color: theme.palette.secondary.main })}
                  onClick={handleAlwaysChecked}
                  aria-label={t('skip') + ' - ' + item.title}
                >
                  {t('skip')}
                </Button>
              )}
            </Box>
          )}
          {item?.subActions?.length && (
            <Box display={'flex'} gap={1} flexWrap={'wrap'}>
              {item.subActions.map((subAction, index) => (
                <SubActionItem
                  subaction={subAction}
                  key={index}
                  handlePopupConfirm={handleAlwaysChecked}
                  handleActionButtonClick={handleActionButtonClick}
                  checked={checked}
                  actionTitle={item.title}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <CheckListItemPopup
        action={showMarkdownPopup || showVideoPopup}
        onClose={closePopup}
        onDone={() => {
          handleAlwaysChecked();
          closePopup();
        }}
      />
      {upgradeRequestPopup}
    </Box>
  );
};

export default CheckListItem;

export function CheckListItemPopup(props: {
  action: ChecklistItemAction | null;
  onClose: () => void;
  onDone: () => void;
}) {
  const { action, onClose, onDone } = props;
  return (
    <LtDialog
      open={!!action}
      title={
        (action?.action && 'title' in action.action ? action.action.title : undefined) ||
        action?.label
      }
      onClose={onClose}
      onCancel={onClose}
      onDone={onDone}
    >
      {action?.action?.type === 'popup' && (
        <MarkdownRenderer content={action.action?.content || null} />
      )}
      {action?.action?.type === 'video' && (
        <>
          {action.action.preContent && (
            <MarkdownRenderer content={action.action.preContent} containerSx={{ mb: 1 }} />
          )}
          <LtVideo embedUrl={action.action.embedUrl} />
          {action.action.postContent && (
            <MarkdownRenderer content={action.action.postContent} containerSx={{ mb: 1 }} />
          )}
        </>
      )}
    </LtDialog>
  );
}
