import { InputAdornment, TextField } from '@mui/material';
import { getTranslatedField, isPhoneFaxField, isRequiredField } from '../utils';
import TooltipInfo from '@/components/TooltipInfo';
import { ACCOUNT_MULTILINE_FIELDS, AccountBioFields } from '@/shared/constants/entities';
import { useMemo } from 'react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CSS_VARS } from '@/infrastructure/constants';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { Controller, useFormContext } from 'react-hook-form';
import { validationRules } from '../validationRules';
import { toOnlyNumbersAndSpacesAndDashes } from '@/infrastructure/helper';

const BioTextField = ({
  fieldName,
  ariaLabel,
  readonly = false,
}: {
  fieldName: keyof AccountBioFields;
  readonly: boolean;
  ariaLabel?: string;
}) => {
  const { t } = useAppTranslation();

  const { desktopView } = useDesktopView();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rules = useMemo(() => validationRules(t), [t]);

  return (
    <Controller
      key={fieldName}
      control={control}
      name={fieldName}
      rules={rules[fieldName]}
      render={({ field }) => {
        const { onChange, ...restProps } = field;
        return (
          <TextField
            label={getTranslatedField(fieldName)}
            required={isRequiredField(fieldName)}
            onChange={({ target: { value } }) => {
              onChange(isPhoneFaxField(fieldName) ? toOnlyNumbersAndSpacesAndDashes(value) : value);
            }}
            inputProps={{
              ...restProps,
              readOnly: readonly,
              'aria-label': ariaLabel,
            }}
            InputProps={{
              startAdornment: isPhoneFaxField(fieldName) && (
                <InputAdornment position='start'>+</InputAdornment>
              ),
              endAdornment: readonly && (
                <TooltipInfo
                  text={t('editDisabledTooltip')}
                  icon={{ help: true, color: 'text.secondary' }}
                  iconAriaLabel={t('ariaDisabledFieldHelpButton', {
                    fieldName: getTranslatedField(fieldName),
                  })}
                />
              ),
            }}
            InputLabelProps={{ color: 'secondary' }}
            key={fieldName}
            multiline={ACCOUNT_MULTILINE_FIELDS.includes(fieldName)}
            fullWidth
            sx={{
              scrollMarginTop: `calc(${
                desktopView
                  ? CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR
                  : CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR
              } + 2rem)`,
            }}
            error={Boolean(errors[fieldName])}
            aria-invalid={Boolean(errors[fieldName])}
            helperText={errors[fieldName]?.message}
          />
        );
      }}
    />
  );
};

export default BioTextField;
