import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { routePaths } from '@/infrastructure/constants';
import { Box, Button, styled } from '@mui/material';
import { DESKTOP_BREAK_POINT } from '@/shared/constants';
const isMdPage = path =>
  path
    .split('/')
    .filter(x => x !== '')
    .includes('md');

const MobilePopup: React.FC = () => {
  const [isMobilePopupVisible, setIsMobilePopupVisible] = React.useState(false);
  const location = useLocation();

  const isUserOnMdPage = isMdPage(location.pathname);
  const { t } = useTranslation();

  const history = useHistory();

  React.useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < DESKTOP_BREAK_POINT) {
        if (isUserOnMdPage) {
          setIsMobilePopupVisible(true);
        }
      } else {
        setIsMobilePopupVisible(false);
      }
    };
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [isUserOnMdPage]);

  return (
    isMobilePopupVisible &&
    ReactDOM.createPortal(
      <>
        <StyledOverlay />
        <StyledWrapper>
          {t('mobilePopupText', { pixel: DESKTOP_BREAK_POINT.toString() })}
        </StyledWrapper>
        <StyledButtonWrapper>
          <Button size='large' onClick={() => history.push(routePaths.HOME)}>
            {t('toProfile')}
          </Button>
        </StyledButtonWrapper>
      </>,
      document.body,
    )
  );
};
export default MobilePopup;

const StyledOverlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(20px)',
  zIndex: 1112,
});

const StyledWrapper = styled(Box)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  background: '#fff',
  zIndex: 1113,
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.04)',
});

const StyledButtonWrapper = styled(Box)({
  position: 'fixed',
  bottom: '5rem',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1113,
});
