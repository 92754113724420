import { CircularProgress, Box, Typography, Button } from '@mui/material';
import { FullScreenContainer } from './FullScreenContainer';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

const SHOW_BUTTON_AFTER = 3000;

export const LoadingScreen = ({ onCancel }) => {
  const [cancelVisible, setCancelVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCancelVisible(true);
    }, SHOW_BUTTON_AFTER);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <FullScreenContainer>
      <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        aria-busy='true'
      >
        <CircularProgress size='5.8rem' />
        <Typography mt={3} variant='h3'>
          {t('businessCardScanner.loadingTitle')}
        </Typography>
        <Typography color='secondary' mt={1} mb={3} variant='body2'>
          {t('businessCardScanner.loadingSubtitle')}
        </Typography>
        <Button
          color='warning'
          variant='outlined'
          onClick={onCancel}
          sx={!cancelVisible && { opacity: 0, pointerEvents: 'none' }}
        >
          {t('cancel')}
        </Button>
      </Box>
    </FullScreenContainer>
  );
};
