import { LINK_TYPE_MODES } from '@/shared/constants';
import { LinkType } from '@/shared/types/api';
import { removePrefix } from '@/util';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onChange: (value: string) => void;
  selectedLinkType: LinkType;
};

export const LinkField = ({ value, onChange, selectedLinkType }: Props) => {
  const { t } = useTranslation();

  const isUsername = selectedLinkType?.mode === LINK_TYPE_MODES.USERNAME;
  const isPhone = selectedLinkType?.mode === LINK_TYPE_MODES.PHONE;
  return (
    <TextField
      value={value}
      label={isUsername ? t('enterUsername') : isPhone ? t('enterPhone') : t('enterLink')}
      onChange={e => onChange(removePrefix(e.target.value, 'https://'))}
      inputProps={(isUsername || isPhone) && { maxLength: 30 }}
      InputProps={{
        startAdornment: isUsername ? <Box mr={0.5}>@</Box> : isPhone ? <Box mr={0.5}>+</Box> : null,
      }}
      fullWidth
    />
  );
};
