import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import LtLoadingButton from '../LtLoadingButton';
import { LtDefaultActionButtonsProps } from './types';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { DeleteOutline, SaveOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

export default function LtDefaultActionButtons(props: LtDefaultActionButtonsProps) {
  const {
    cancelAction,
    onCancel,
    saveAction,
    onSave,
    confirmAction,
    onConfirm,
    doneAction,
    onDone,
    deleteAction,
    onDelete,
    customActionsPre,
    customActionsPost,
    customElemsPre,
    customElemsPost,
    loading,
    disabled,
    noCancelOnDesktop,
    disableBigButtonsOnMobile,
  } = props;

  const { t } = useAppTranslation();

  const { desktopView: isDesktop } = useDesktopView();
  const responsiveProps =
    isDesktop || disableBigButtonsOnMobile
      ? { size: 'medium' as const, fullWidth: false }
      : { size: 'large' as const, fullWidth: true };

  const asAnchorTabProps = useCallback(
    (href?: string | null) =>
      !href
        ? {}
        : {
            component: Link,
            to: href,
          },
    [],
  );

  if (!hasAnyAction(props) && !customElemsPre?.length && !customElemsPost?.length) return null;

  return (
    <>
      {customElemsPre}
      {hasCustomActionPre(props) &&
        customActionsPre.map(action => (
          <LtLoadingButton
            variant='contained'
            color='primaryButton'
            {...asAnchorTabProps(action?.asAnchor)}
            {...responsiveProps}
            {...action}
          >
            {action.text || t(action.ttext)}
          </LtLoadingButton>
        ))}
      {hasCancel(props) && !(isDesktop && noCancelOnDesktop) && (
        <LtLoadingButton
          variant='outlined'
          color='primary'
          onClick={onCancel || cancelAction.onClick}
          {...asAnchorTabProps(cancelAction?.asAnchor)}
          {...responsiveProps}
          {...(cancelAction || {})}
        >
          {cancelAction?.text || t(cancelAction?.ttext ?? 'cancel')}
        </LtLoadingButton>
      )}
      {hasDelete(props) && (
        <LtLoadingButton
          onClick={onDelete || deleteAction.onClick}
          startIcon={<DeleteOutline />}
          loading={loading}
          disabled={disabled}
          color='error'
          variant='outlined'
          {...asAnchorTabProps(deleteAction?.asAnchor)}
          {...responsiveProps}
          {...(deleteAction || {})}
        >
          {deleteAction?.text || t(deleteAction?.ttext ?? 'delete')}
        </LtLoadingButton>
      )}
      {hasSave(props) && (
        <LtLoadingButton
          onClick={onSave || saveAction.onClick}
          startIcon={<SaveOutlined />}
          loading={loading}
          loadingPosition='start'
          disabled={disabled}
          {...asAnchorTabProps(saveAction?.asAnchor)}
          {...responsiveProps}
          {...(saveAction || {})}
        >
          {saveAction?.text || t(saveAction?.ttext ?? 'save')}
        </LtLoadingButton>
      )}
      {hasConfirm(props) && (
        <LtLoadingButton
          onClick={onConfirm || confirmAction.onClick}
          loading={loading}
          disabled={disabled}
          {...asAnchorTabProps(confirmAction?.asAnchor)}
          {...responsiveProps}
          {...(confirmAction || {})}
        >
          {confirmAction?.text || t(confirmAction?.ttext ?? 'confirm')}
        </LtLoadingButton>
      )}
      {hasDone(props) && (
        <LtLoadingButton
          onClick={onDone || doneAction.onClick}
          loading={loading}
          disabled={disabled}
          {...asAnchorTabProps(doneAction?.asAnchor)}
          {...responsiveProps}
          {...(doneAction || {})}
        >
          {doneAction?.text || t(doneAction?.ttext ?? 'done')}
        </LtLoadingButton>
      )}
      {hasCustomActionPost(props) &&
        customActionsPost.map(action => (
          <LtLoadingButton
            variant='contained'
            color='primaryButton'
            {...asAnchorTabProps(action?.asAnchor)}
            {...responsiveProps}
            {...action}
          >
            {action.text || t(action.ttext)}
          </LtLoadingButton>
        ))}
      {customElemsPost}
    </>
  );
}

export const hasCancel = (props: LtDefaultActionButtonsProps) =>
  !!(props.cancelAction || props.onCancel);
export const hasSave = (props: LtDefaultActionButtonsProps) => !!(props.saveAction || props.onSave);
export const hasConfirm = (props: LtDefaultActionButtonsProps) =>
  !!(props.confirmAction || props.onConfirm);
export const hasDone = (props: LtDefaultActionButtonsProps) => !!(props.doneAction || props.onDone);
export const hasDelete = (props: LtDefaultActionButtonsProps) =>
  !!(props.deleteAction || props.onDelete);
export const hasCustomActionPre = (props: LtDefaultActionButtonsProps) =>
  props.customActionsPre && props.customActionsPre.length > 0;
export const hasCustomActionPost = (props: LtDefaultActionButtonsProps) =>
  props.customActionsPost && props.customActionsPost.length > 0;
export const hasAnyAction = (props: LtDefaultActionButtonsProps) =>
  hasCancel(props) ||
  hasSave(props) ||
  hasConfirm(props) ||
  hasDone(props) ||
  hasDelete(props) ||
  hasCustomActionPre(props) ||
  hasCustomActionPost(props);
