import React from 'react';
import { Box, Typography, styled } from '@mui/material';

interface Props {
  heading: string;
  subheading: string;
  gif: { url: string; text: string[] | string; underText?: string };
  secondGif?: { url: string; text: string; underText?: string };
}

export const ScreenGif = (props: Props) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={3}>
      {props.heading && (
        <Typography textAlign='center' variant='h1'>
          {props.heading}
        </Typography>
      )}
      {props.subheading && (
        <Typography textAlign='center' variant='body1' color='secondary'>
          {props.subheading}
        </Typography>
      )}
      <GifsWrapper doubleGifs={props?.secondGif?.url !== undefined}>
        <GifWrapper>
          <GifText>
            {Array.isArray(props.gif.text) ? (
              <ol>
                {props.gif.text.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ol>
            ) : (
              props.gif.text
            )}
          </GifText>
          <Gif src={props.gif.url} />
          <GifText>{props.gif?.underText && props.gif.underText}</GifText>
        </GifWrapper>
        {props.secondGif?.url && (
          <GifWrapper>
            <GifText>{props.secondGif.text}</GifText>
            <Gif src={props.secondGif.url} />
            <GifText>{props.secondGif?.underText && props.secondGif.underText}</GifText>
          </GifWrapper>
        )}
      </GifsWrapper>
    </Box>
  );
};

const GifWrapper = styled('div')({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '39rem',
  width: '56rem',
  border: '6px solid #eff3fd',
  borderRadius: '21px',
  '@media (max-width: 767px)': {
    border: 'none',
    width: '30rem',
  },
});

const Gif = styled('img')({
  width: 'auto',
  maxHeight: '29rem',
  borderRadius: '21px',
  '@media (max-width: 767px)': {
    border: 'none',
    width: '24rem',
  },
});

const GifText = styled(Typography)({
  display: 'block',
  padding: '1.5rem 4rem',
  ol: {
    listStyle: 'auto',
  },
});

const GifsWrapper = styled('div')(props => ({
  width: '75%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
  flexWrap: 'wrap',
  '@media (max-width: 1200px)': props?.doubleGifs && {
    width: '30rem',
  },
  '@media (max-width: 767px)': {
    width: '30rem',
  },
}));
