import { ProfileEditProps } from '@/components/Profile/types';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import UrlButtonItem from './UrlButtonItem';
import {
  DeleteOutline,
  EditOutlined,
  FlashOffOutlined,
  FlashOnOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import LtSorter from '@/components/LtSorter';

type Props = Pick<
  ProfileEditProps['linksBoxEditProps'],
  | 'links'
  | 'standardLinksAfter'
  | 'standardLinksBefore'
  | 'genericWebsiteColor'
  | 'defaultLinkId'
  | 'onReorder'
  | 'onToggleDisplay'
  | 'onToggleDefault'
  | 'onDelete'
  | 'onEdit'
>;

export default function LinksEdit({
  links,
  standardLinksBefore,
  standardLinksAfter,
  genericWebsiteColor,
  defaultLinkId,
  onReorder,
  onToggleDisplay,
  onToggleDefault,
  onDelete,
  onEdit,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction='column' spacing={1.6}>
        {standardLinksBefore && (
          <Stack direction='column' spacing={1.6}>
            {standardLinksBefore.map(item => (
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <UrlButtonItem
                  backgroundCss={item.linkType.backgroundCss || genericWebsiteColor || '#152570'}
                  iconUrl={item.linkType.iconUrl}
                  url={item.link}
                  text={item.name}
                  renderAs='link'
                  meta={{ itemId: item.id }}
                  disabled
                  ariaHidden
                />
                <Tooltip enterDelay={500} title={t('explainThemeLink')}>
                  <span>
                    <IconButton
                      size='small'
                      aria-label={t('ariaThemeLinkFileEdit', { name: item.name })}
                      disabled
                    >
                      <EditOutlined />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        )}
        {links && (
          <LtSorter
            items={links}
            getItemAriaLabel={item => item.name}
            getItemKey={item => item.id}
            renderer={item => (
              <Stack direction='row' alignItems='center' spacing={{ xs: 0.25, md: 0.5 }}>
                <Box flex={1}>
                  <UrlButtonItem
                    backgroundCss={item.linkType.backgroundCss || genericWebsiteColor || '#152570'}
                    iconUrl={item.linkType.iconUrl}
                    url={item.link}
                    text={item.name}
                    renderAs='link'
                    meta={{ itemId: item.id }}
                    disabled
                    ariaHidden
                  />
                </Box>
                <Tooltip
                  enterDelay={500}
                  title={item.canDisplayOnProfile ? t('hideMessageLink') : t('showMessageLink')}
                >
                  <IconButton
                    size='small'
                    onClick={() => onToggleDisplay(item.id, !item.canDisplayOnProfile)}
                    aria-label={
                      item.canDisplayOnProfile
                        ? t('ariaHideMessage', { name: item.name })
                        : t('ariaShowMessage', { name: item.name })
                    }
                  >
                    {item.canDisplayOnProfile ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  enterDelay={500}
                  title={item.id === defaultLinkId ? t('redirectOffMessage') : t('redirectMessage')}
                >
                  <IconButton
                    size='small'
                    onClick={() => onToggleDefault(item.id, item.id !== defaultLinkId)}
                    aria-label={
                      item.id === defaultLinkId
                        ? t('ariaRedirectOffMessage', { name: item.name })
                        : t('ariaRedirectMessage', { name: item.name })
                    }
                  >
                    {item.id === defaultLinkId ? <FlashOnOutlined /> : <FlashOffOutlined />}
                  </IconButton>
                </Tooltip>
                {!item.linkTemplateId && (
                  <Tooltip enterDelay={500} title={t('editLink')}>
                    <IconButton
                      size='small'
                      onClick={() => onEdit(item)}
                      aria-label={t('ariaEditMessage', {
                        name: item.name,
                      })}
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                )}
                {item.linkTemplateId && (
                  <Tooltip enterDelay={500} title={t('explainTemplatedLink')}>
                    <IconButton
                      size='small'
                      onClick={() => onDelete(item)}
                      aria-label={t('ariaDeleteMessage', {
                        name: item.name,
                      })}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}
            onEnd={onReorder}
          />
        )}
        {standardLinksAfter && (
          <Stack direction='column' spacing={1.6}>
            {standardLinksAfter.map(item => (
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <UrlButtonItem
                  backgroundCss={item.linkType.backgroundCss || genericWebsiteColor || '#152570'}
                  iconUrl={item.linkType.iconUrl}
                  url={item.link}
                  text={item.name}
                  renderAs='link'
                  meta={{ itemId: item.id }}
                  disabled
                  ariaHidden
                />
                <Tooltip enterDelay={500} title={t('explainThemeLink')}>
                  <span>
                    <IconButton
                      size='small'
                      aria-label={t('ariaThemeLinkFileEdit', { name: item.name })}
                      disabled
                    >
                      <EditOutlined />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
}
