import {
  Button,
  Checkbox,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

import { useState } from 'react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LtDialog, SROnly } from '@/components';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';
import { CustomTableCell } from '@/components/Table';
import SortableCustomTableCellHeader from '@/components/Table/SortableTableCellHeader';
import { Unit } from '@/shared/types/api/unit.type';
import { SortOption } from '@/shared/types/global';
import { AddOutlined, DeleteOutline, EditOutlined, ManageAccounts } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const lineClampCount = 1;

const EnhancedTableRow = ({
  unit,
  onSelect,
  isSelected,
  onDelete,
  columns,
  onEditClick,
  onAddMembersClick,
  onManageMembersClick,
}: {
  unit: Unit;
  onSelect: () => void;
  isSelected: boolean;
  onDelete?: (unitId: number) => Promise<void>;
  columns: ColumnDefinition[];
  onEditClick: (unitId: number) => void;
  onManageMembersClick?: (unitId: number) => void;
  onAddMembersClick?: (unitId: number) => void;
}) => {
  const { t } = useAppTranslation();

  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    await onDelete(unit.id);
    setDeletePopupOpened(false);
    setDeleting(false);
  };

  const rowActions = [
    {
      icon: <ManageAccounts />,
      label: t('mdUnits.manageMembers'),
      onClick: () => onManageMembersClick(unit.id),
    },
    {
      icon: <AddOutlined />,
      label: t('mdUnits.addMembers'),
      onClick: () => onAddMembersClick(unit.id),
    },
    { icon: <EditOutlined />, label: t('rename'), onClick: () => onEditClick(unit.id) },
    { icon: <DeleteOutline />, label: t('delete'), onClick: () => setDeletePopupOpened(true) },
  ];

  return (
    <>
      <TableRow hover aria-selected={isSelected} key={unit.id} selected={isSelected}>
        <TableCell
          padding='checkbox'
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Checkbox
            color='primary'
            onClick={() => onSelect()}
            checked={isSelected}
            inputProps={{
              'aria-label': t('ariaSelect', { name: unit.niceName }),
            }}
          />
        </TableCell>
        {columns.map(({ key }) => {
          if (key === 'niceName') {
            return (
              <CustomTableCell
                lineClampCount={lineClampCount}
                key={key}
                tooltipText={unit[key]}
                actions={rowActions}
                onClick={() => onManageMembersClick(unit.id)}
                actionsBtnAriaLabel={t('ariaSettings', { name: unit.niceName })}
              >
                {unit[key]}
              </CustomTableCell>
            );
          }
          if (key === 'viewMembers') {
            return (
              <CustomTableCell key={key}>
                <Button
                  variant='outlined'
                  color='info'
                  size='small'
                  onClick={() => onManageMembersClick(unit.id)}
                  aria-label={t('ariaUnitViewMembers', { name: unit.niceName })}
                >
                  {t('mdUnits.viewMembers')}
                </Button>
              </CustomTableCell>
            );
          }
          return (
            <CustomTableCell lineClampCount={lineClampCount} key={key} tooltipText={unit[key]}>
              {unit[key]}
            </CustomTableCell>
          );
        })}
      </TableRow>
      <LtDialog
        title={t('requestDelete')}
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={handleDelete}
        loading={deleting}
      >
        {t('mdUnits.deleteUnitsMsg', { count: 1 })}
      </LtDialog>
    </>
  );
};

type Props = {
  units: Unit[];
  onCheckboxClick: (unit: Unit) => void;
  onHeaderCheckboxClick: () => void;
  selectedItems: { id: number }[];
  isAllSelected: boolean;
  isAnySelectedOnCurrentPage: boolean;
  onDelete?: (unitId: number) => Promise<void>;
  columns: ColumnDefinition[];
  sortOptions: SortOption;
  onSortOptionsChange: (sortOptions: SortOption) => void;
  onEditClick: (unitId: number) => void;
  onManageMembersClick?: (unitId: number) => void;
  onAddMembersClick?: (unitId: number) => void;
};

export const Table = ({
  units,
  onCheckboxClick,
  onHeaderCheckboxClick,
  isAllSelected,
  isAnySelectedOnCurrentPage,
  selectedItems,
  onDelete,
  columns,
  sortOptions,
  onSortOptionsChange,
  onEditClick,
  onAddMembersClick,
  onManageMembersClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      sx={(theme: Theme) => ({
        flex: '1',
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      <MuiTable stickyHeader sx={{ minWidth: 750 }}>
        <SROnly component='caption'>{t('ariaUnitsTableCaption')}</SROnly>
        <TableHead>
          <TableRow sx={{ bgcolor: 'white' }}>
            <TableCell
              padding='checkbox'
              sx={theme => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Checkbox
                color='primary'
                indeterminate={isAnySelectedOnCurrentPage}
                checked={isAllSelected}
                onChange={onHeaderCheckboxClick}
                inputProps={{
                  'aria-label': t('selectAll'),
                }}
              />
            </TableCell>
            {columns.map(column =>
              column.key === 'viewMembers' ? (
                <CustomTableCell lineClampCount={lineClampCount}>
                  {t('mdUnits.viewMembers')}
                </CustomTableCell>
              ) : (
                <SortableCustomTableCellHeader
                  column={column}
                  sortOptions={sortOptions}
                  onSortOptionsChange={onSortOptionsChange}
                  lineClampCount={lineClampCount}
                />
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((unit, index) => (
            <EnhancedTableRow
              key={unit.id}
              unit={unit}
              onSelect={() => onCheckboxClick(unit)}
              isSelected={selectedItems.some(({ id }) => id === unit.id)}
              onDelete={onDelete}
              columns={columns}
              onEditClick={onEditClick}
              onAddMembersClick={onAddMembersClick}
              onManageMembersClick={onManageMembersClick}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
