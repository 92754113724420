export enum Idp {
  ACTIVE_DIRECTORY_FEDERATION = 'active-directory-federation',
  AUTH0 = 'auth0',
  CYBERARK = 'cyberark',
  GOOGLE_CLOUD_IDENTITY = 'google-cloud-identity',
  GOOGLE_WORKSPACE = 'google-workspace',
  JUMP_CLOUD = 'jump-cloud',
  MICROSOFT_AZURE_ENTRA_ID = 'microsoft-azure-entra-id',
  OKTA = 'okta',
  ONE_LOGIN = 'one-login',
  PING_IDENTITY = 'ping-identity',
  OTHER = 'other',
}

//Idp strategies [primary, secondary] if other then "samlp" exists goes as primary
export const idpStrategies = {
  [Idp.ACTIVE_DIRECTORY_FEDERATION]: ['samlp'],
  [Idp.AUTH0]: ['samlp'],
  [Idp.CYBERARK]: ['samlp'],
  [Idp.GOOGLE_CLOUD_IDENTITY]: ['samlp'],
  [Idp.GOOGLE_WORKSPACE]: ['samlp'],
  [Idp.JUMP_CLOUD]: ['samlp'],
  [Idp.MICROSOFT_AZURE_ENTRA_ID]: ['waad', 'samlp'],
  [Idp.OKTA]: ['okta', 'samlp'],
  [Idp.ONE_LOGIN]: ['samlp'],
  [Idp.PING_IDENTITY]: ['samlp'],
  [Idp.OTHER]: ['samlp'],
} as const;

export const idpOptions = {
  [Idp.MICROSOFT_AZURE_ENTRA_ID]: {
    title: 'Microsoft Entra ID (formerly Active Directory)',
    imgPath: '/idps/azure-entra-id.png',
  },
  [Idp.OKTA]: { title: 'Okta', imgPath: '/idps/okta.png' },
  [Idp.ACTIVE_DIRECTORY_FEDERATION]: {
    title: 'Active Directory Federation Service (ADFS)',
    imgPath: '/idps/microsoft.png',
  },
  [Idp.GOOGLE_WORKSPACE]: { title: 'Google Workspace', imgPath: '/idps/google-workspace.png' },
  [Idp.ONE_LOGIN]: { title: 'OneLogin', imgPath: '/idps/onelogin.png' },
  [Idp.PING_IDENTITY]: { title: 'Ping Identity', imgPath: '/idps/ping-identity.png' },
  [Idp.GOOGLE_CLOUD_IDENTITY]: {
    title: 'Google Cloud Identity',
    imgPath: '/idps/google-cloud-identity.png',
  },
  [Idp.AUTH0]: { title: 'Auth0', imgPath: '/idps/auth0.svg' },
  [Idp.CYBERARK]: { title: 'CyberArk Idaptive', imgPath: '/idps/cyberark-idaptive.png' },
  [Idp.JUMP_CLOUD]: { title: 'JumpCloud', imgPath: '/idps/jumpcloud.png' },
  [Idp.OTHER]: { title: 'Other', imgPath: '/idps/other.jpg' },
} as const;

export type IdpOption = {
  value: Idp;
  label: string;
  imgPath: string;
};

export const idpOptionsArray: IdpOption[] = Object.keys(idpOptions).map(key => ({
  value: key as Idp,
  label: idpOptions[key as keyof typeof idpOptions].title,
  imgPath: idpOptions[key as keyof typeof idpOptions].imgPath,
}));
