import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import FeatureTeaser from '../teaser/featureTeaser';
import {
  Box,
  Button,
  Paper,
  TablePagination,
  TextField,
  Theme,
  Typography,
  alpha,
} from '@mui/material';
import { CustomTableToolbar } from '@/components/Table';
import { ColSortMenu, LtDialog, PageContainer, SearchField } from '@/components';
import { Table } from './components/Table';
import { useTableSelection } from '@/infrastructure/hooks/useTableSelection';
import { initColumnsDef, resetColumnsDef, saveUnitsTableColumns } from './utils/columns';
import { SortOption } from '@/shared/types/global';
import { ColumnDefinition } from '../profiles/table/utils/constants';
import { AddOutlined, Close, DeleteOutline } from '@mui/icons-material';
import { NoUnits } from './components/NoUnits';
import { SelectedCountCounter } from './components/SelectedCountCounter';
import { AddUserToUnitPopup } from './components/AddUserToUnitPopup';
import { useHistory } from 'react-router-dom';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import withNav from '@/infrastructure/hoc/withNav';
import { Unit } from '@/shared/types/api/unit.type';
import { createUnit, deleteUnits, editUnit, getUnits } from '@/infrastructure/apis/md/units';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { FEATURE, UNITS_DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { CSS_VARS, routePaths } from '@/infrastructure/constants';
import { HelpButton } from '@/components/HelpSidebar';
import { Account } from '@/shared/types/api';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { updateActionPreferences } from '@/application/actions/account';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import Loader from '@/components/Loader';

const ROWS_PER_PAGE = [5, 20, 100];

const MdUnits = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  if (loading) {
    return <Loader />;
  }

  if (isFeatureAllowed(FEATURE.UNITS)) {
    return <UnitsPage />;
  } else {
    return (
      <PageContainer maxWidth='l'>
        <FeatureTeaser text={t('upgradeTeaser.feature.units')} />
      </PageContainer>
    );
  }
};

export default withNav(
  MdUnits,
  {
    tTitle: 'units',
  },
  {},
);

const UnitsPage = () => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [unitsIds, setUnitsIds] = useState<{ id: number }[]>([]);
  const total = unitsIds.length;
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(UNITS_DEFAULT_PAGE_SIZE);
  const [searchWord, setSearchWord] = useState('');
  const [unitsLoading, setUnitsLoading] = useState(true);

  const account: Account = useAppSelector(state => state.account);
  const optionsPreferences = account?.actionPreferences?.mdUnits_options;

  const [sortOptions, setSortOptions] = useState<SortOption>({
    orderBy: 'createdOn',
    sort: 'DESC',
    ...(optionsPreferences || {}),
  });

  const [bulkDeletePopupOpened, setBulkDeletePopupOpened] = useState(false);
  const [isBulkDeleteLoading, setIsBulkDeleteLoading] = useState(false);
  const [createEditPopupOpened, setCreateEditPopupOpened] = useState(false);
  const [createEditUnit, setCreateEditUnit] = useState<Unit>();
  const [addUserToUnitExplainerOpened, setAddUserToUnitExplainerOpened] = useState(false);

  const [columns, setColumns] = useState(initColumnsDef());
  const visibleColumns = columns.filter(x => !x.hidden);
  const history = useHistory();

  useDeferredLoader(unitsLoading, 'units-loading-toast');

  const {
    handleCheckboxClick,
    handleHeaderCheckboxClick,
    selectedItems,
    isAllSelected,
    isAnySelectedOnCurrentPage,
    selectedCount,
    unselectAllItems,
    isSelectAllVisible,
    handleSelectAll,
  } = useTableSelection<{ id: number }>(unitsIds, units);

  const mountedRef = useRef(false);
  const dispatch = useAppDispatch();

  const fetchUnits = useCallback(async () => {
    try {
      const { data: res } = await getUnits(getAccessTokenSilently, {
        ...sortOptions,
        page,
        pageSize: rowsPerPage,
        searchWord,
      });
      setUnits(res.data.units);
      setUnitsIds(res.data.unitIds);
    } catch (error) {
      toast.error(t('mdLeads.errorReadUnits'), { id: 'units-loading-toast' });
    }
  }, [getAccessTokenSilently, page, t, rowsPerPage, searchWord, sortOptions, toast]);

  useEffect(() => {
    const fetch = async () => {
      setUnitsLoading(true);
      await fetchUnits();
      mountedRef.current = true;
      setUnitsLoading(false);
    };
    fetch();
  }, [fetchUnits]);

  const handleColumnChange = (newCols: ColumnDefinition[]) => {
    setColumns(newCols);
    saveUnitsTableColumns(newCols);
  };

  const handleSortChange = (newOptions: SortOption) => {
    if (newOptions.orderBy === sortOptions.orderBy && newOptions.sort === sortOptions.sort) return;
    setSortOptions(newOptions);
    setPage(0);
    dispatch(
      updateActionPreferences(getAccessTokenSilently, {
        mdUnits_options: { ...sortOptions, ...newOptions },
      }),
    );
  };

  const handleSearch = (value: string) => {
    setPage(0);
    setSearchWord(value);
  };

  const handleDelete = async (ids: number[]) => {
    try {
      await deleteUnits(getAccessTokenSilently, ids);
      await fetchUnits();
      toast.success(t('mdUnits.deleteSuccess'));
    } catch (err) {
      if (err?.response?.data?.error?.code === 'not-empty') {
        toast.error(t('mdLeads.unitsNotEmpty'), { id: 'deletingUnits' });
      } else {
        toast.error(t('mdLeads.errorDeleteUnits', { id: 'deletingUnits' }));
      }
    }
  };

  const handleBulkDelete = async () => {
    setIsBulkDeleteLoading(true);
    await handleDelete(selectedItems.map(x => x.id));
    setIsBulkDeleteLoading(false);
    setBulkDeletePopupOpened(false);
  };

  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    setSaving(true);
    try {
      if (createEditUnit.id) {
        await editUnit(getAccessTokenSilently, createEditUnit);
      } else {
        await createUnit(getAccessTokenSilently, createEditUnit);
      }
      await fetchUnits();
      toast.success(t('saved'), { id: 'savingUnits' });
      setCreateEditPopupOpened(false);
      setCreateEditUnit(undefined);
    } catch (error) {
      toast.error(
        error.response.status === 409 ? t('mdLeads.unitNameExists') : t('mdLeads.errorReadUnits'),
        { id: 'savingUnits' },
      );
    }
    setSaving(false);
  };

  const handleEditClick = (unitId: number) => {
    const unit = units.find(x => x.id === unitId);
    setCreateEditUnit(unit);
    setCreateEditPopupOpened(true);
  };

  const handlePopupClose = () => {
    setCreateEditPopupOpened(false);
    setCreateEditUnit(undefined);
  };

  const handleManageMembersClick = (unitId: number) => {
    const unit = units.find(x => x.id === unitId);
    history.push(
      `${routePaths.MD.PROFILES}?col=unit&val=${encodeURIComponent(unit.niceName)}&fromUnits=true`,
    );
  };

  const addUnitBtn = (
    <Button startIcon={<AddOutlined />} onClick={() => setCreateEditPopupOpened(true)}>
      {t('addUnit')}
    </Button>
  );

  const filtersApplied = !!searchWord;

  if (unitsLoading && !filtersApplied && !mountedRef.current) return null;

  const noUnitsAreAdded = !unitsLoading && total === 0 && !filtersApplied;

  return (
    <>
      {noUnitsAreAdded ? (
        <NoUnits actions={addUnitBtn} />
      ) : (
        <Box p={2} sx={{ height: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})` }}>
          <Paper
            variant='outlined'
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <CustomTableToolbar sx={{ py: '1.6rem' }}>
              <SearchField
                sx={{ flexGrow: 1 }}
                onSearch={handleSearch}
                placeholder={t('mdUnits.searchPlaceholder')}
              />
              <Box sx={{ flexGrow: 1 }} />
              {addUnitBtn}
              <HelpButton configKey='help-sidebar-units' />
            </CustomTableToolbar>
            <CustomTableToolbar
              sx={(theme: Theme) => ({
                bgcolor: alpha(theme.palette.primary.main, 0.04),
              })}
            >
              {selectedCount > 0 && (
                <>
                  <SelectedCountCounter
                    total={total}
                    selectAllVisible={isSelectAllVisible}
                    onSelectAll={handleSelectAll}
                    selectedCount={selectedCount}
                  />
                  <Button
                    variant='text'
                    color='primary'
                    startIcon={<Close />}
                    onClick={unselectAllItems}
                  >
                    {t('deselectAll')}
                  </Button>
                  <Button
                    color='error'
                    variant='outlined'
                    startIcon={<DeleteOutline />}
                    onClick={() => setBulkDeletePopupOpened(true)}
                    aria-label={t('deleteSelected')}
                  >
                    {t('delete')}
                  </Button>
                </>
              )}
              <Box flexGrow={1} />
              <ColSortMenu
                columnDefs={columns}
                setColumnDefs={handleColumnChange}
                reset={() => handleColumnChange(resetColumnsDef())}
              />
            </CustomTableToolbar>
            {Boolean(units.length) ? (
              <>
                <Table
                  units={units}
                  onCheckboxClick={handleCheckboxClick}
                  onHeaderCheckboxClick={handleHeaderCheckboxClick}
                  selectedItems={selectedItems}
                  isAllSelected={isAllSelected}
                  isAnySelectedOnCurrentPage={isAnySelectedOnCurrentPage}
                  onDelete={id => handleDelete([id])}
                  columns={visibleColumns}
                  sortOptions={sortOptions}
                  onSortOptionsChange={handleSortChange}
                  onEditClick={handleEditClick}
                  onAddMembersClick={() => setAddUserToUnitExplainerOpened(true)}
                  onManageMembersClick={handleManageMembersClick}
                />
                <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE}
                  component='div'
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={t('rowsPerPage')}
                  onPageChange={(_, page) => setPage(page)}
                  onRowsPerPageChange={({ target: { value } }) => {
                    setRowsPerPage(+value);
                    setPage(0);
                  }}
                  nextIconButtonProps={{ 'aria-label': t('showNextPage') }}
                  backIconButtonProps={{ 'aria-label': t('showPreviousPage') }}
                />
              </>
            ) : (
              <Typography variant='h2' textAlign='center' py={5}>
                {t('mdUnits.noUnitsFound')}
              </Typography>
            )}
          </Paper>
        </Box>
      )}
      <LtDialog
        title={t('requestDelete')}
        open={bulkDeletePopupOpened}
        onClose={() => setBulkDeletePopupOpened(false)}
        onCancel={() => setBulkDeletePopupOpened(false)}
        onDelete={handleBulkDelete}
        loading={isBulkDeleteLoading}
      >
        {t('mdUnits.deleteUnitsMsg', { count: selectedCount })}
      </LtDialog>
      <LtDialog
        title={createEditUnit?.id ? t('editUnit') : t('addUnit')}
        open={createEditPopupOpened}
        onClose={handlePopupClose}
        onCancel={handlePopupClose}
        onSave={handleSave}
        loading={saving}
        disabled={!createEditUnit?.niceName}
      >
        <TextField
          fullWidth
          label={t('Name*')}
          value={createEditUnit?.niceName || ''}
          onChange={e => setCreateEditUnit({ ...createEditUnit, niceName: e.target.value })}
        />
      </LtDialog>
      <AddUserToUnitPopup
        open={addUserToUnitExplainerOpened}
        onClose={() => setAddUserToUnitExplainerOpened(false)}
      />
    </>
  );
};
