import React, { useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonProps,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import TooltipInfo from '@/components/TooltipInfo';

export interface ActionType {
  icon: JSX.Element;
  actionText: string;
  tooltipText: string;
  onClick: () => void;
  dividerAbove?: boolean;
}

type Props = {
  label: string;
  actionGroups: { title: string; actions: ActionType[] }[];
  icon?: React.ReactNode;
} & ButtonProps;

const BulkActions = ({ actionGroups, label, icon, ...rest }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        startIcon={icon}
        endIcon={<ArrowDropDown />}
        onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
        aria-haspopup='listbox'
        {...rest}
      >
        {label}
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          minWidth: '25rem',
          p: 0,
          pt: '0.4rem',
        }}
      >
        {actionGroups.map((group, i) => {
          const id = 'action-label-'.concat(i.toString());
          return (
            <>
              <Divider />
              <Typography id={id} variant='subtitle1' sx={{ py: '0.5rem', px: '1rem' }}>
                {group.title}
              </Typography>
              <Divider />
              <MenuList aria-labelledby={id} autoFocus={i === 0}>
                {group.actions.map(action => (
                  <>
                    {action.dividerAbove && <Divider />}
                    <MenuItem
                      onClick={() => {
                        action.onClick();
                        setAnchorEl(null);
                      }}
                    >
                      <ListItemIcon>{action.icon}</ListItemIcon>
                      <ListItemText>{action.actionText}</ListItemText>

                      <TooltipInfo onHover={true} text={action.tooltipText} placement='right' />
                    </MenuItem>
                  </>
                ))}
              </MenuList>
            </>
          );
        })}
      </Popover>
    </>
  );
};

export default BulkActions;
