import { FontType, StaticProfileConfig } from '@/shared/types/api';
import ContentBoxUi from '../components/ContentBoxUI';
import { ContactForm } from '@/components/ContactForm';
import { TrackHandlers } from '../types';
import { useEffect, useMemo, useState } from 'react';
import LtDialog from '@/components/LtDialog';
import FontWrapper from '../components/FontWrapper';

interface Props {
  config: StaticProfileConfig['leadGen'];
  font?: FontType | null;
  onSubmit?: (values: Record<string, string>) => Promise<boolean>;
  suppressPopupFlow?: boolean; // defaults to false
  trackHandlers?: Pick<TrackHandlers, 'contactSubmitTrackHandler'>;
  isEdit?: boolean;
}

export default function ContactFormBox({
  config,
  onSubmit,
  suppressPopupFlow,
  trackHandlers,
  font,
  isEdit,
}: Props) {
  const { flow, ...contactFormProps } = config;

  const submitHandler = useMemo(
    () =>
      onSubmit
        ? async (values: Record<string, string>) => {
            trackHandlers?.contactSubmitTrackHandler?.();
            const res = await onSubmit?.(values);
            if (res) setPopupOpen(false);
            return res;
          }
        : undefined,
    [onSubmit, trackHandlers],
  );

  // popup logic -> open after 3s when flow is set to 'popup'
  const [popupOpen, setPopupOpen] = useState(false);
  const popupFlow = flow === 'popup' && !suppressPopupFlow;
  useEffect(() => {
    if (popupFlow) {
      setTimeout(() => {
        setPopupOpen(true);
      }, 3000);
    }
  }, [popupFlow]);

  return (
    <>
      <ContentBoxUi isEdit={isEdit}>
        <ContactForm onSubmit={submitHandler} {...contactFormProps} />
      </ContentBoxUi>
      {popupFlow && (
        <LtDialog open={popupOpen} onClose={() => setPopupOpen(false)}>
          <FontWrapper font={font}>
            <ContactForm onSubmit={submitHandler} {...contactFormProps} />
          </FontWrapper>
        </LtDialog>
      )}
    </>
  );
}
