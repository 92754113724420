import { dispatchTypes } from '../../index';
import { fetchThemeLinkTypes } from '../../../../infrastructure/apis/edit-profile';
import {
  UpdateEmployeeDataProps,
  toUpdateImagesType,
  _getEmployees,
  _sendEmail,
} from '../../../../infrastructure/apis/md/profiles';
import { _updateEmployee } from '../../../../infrastructure/apis/md/profiles';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../../shared/constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import { reorderLinksAPI } from '../../../../infrastructure/apis/md/profiles/links';
import { reorderFilesAPI } from '../../../../infrastructure/apis/md/profiles/files';
import { Link, ApiFile, AccountImageState } from '../../../../shared/types/api';
import { trackPrivateEvent } from '../../../../infrastructure/apis/analytics';
import { TFunction } from 'i18next';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const getEmployees =
  (
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    searchTerm = '',
    selectedColumn = 'firstName',
    t: TFunction,
    params?: {
      onSuccess?: Function;
      onFail?: Function;
      withToast?: boolean;
      withErrorToast?: boolean;
    },
    unitId?,
    orderByParams?: { orderBy: string; sort: string },
  ) =>
  async dispatch => {
    const toastId = 'getEmployees-toast';
    const { onSuccess, onFail, withToast, withErrorToast } = params || {};
    let toastTimeoutId = 0;
    try {
      if (withToast) {
        // show toast only if loading takes longer than 500ms
        toastTimeoutId = setTimeout(
          () => useNotificationsStore.getState().notify.loading(t('Loading'), { id: toastId }),
          500,
          'delay-loading-toast',
        );
      }
      // TODO: implement pagination for list of employees and update page and pageSize based on pagination
      const page = 0;
      const pageSize = 99999;
      let response = await _getEmployees(
        getAccessTokenSilently,
        searchTerm,
        selectedColumn,
        unitId,
        page,
        pageSize,
        orderByParams,
      );

      const result = response.data;
      if (result.isSuccess) {
        const employees = result.data.accounts;
        dispatch({ type: dispatchTypes.BUSINESS.FETCHEMPLOYEES, employees });
        if (withToast) useNotificationsStore.getState().notify.dismiss(toastId);
        onSuccess?.();
      } else {
        // Will handle it later when I add onFail cb
        if (withToast || withErrorToast)
          useNotificationsStore
            .getState()
            .notify.error(t('errorFetchingProfiles'), { id: toastId });
        onFail?.();
      }
    } catch (error) {
      // Will handle it later when I add onFail cb
      if (withToast || withErrorToast)
        useNotificationsStore.getState().notify.error(t('errorFetchingProfiles'), { id: toastId });
      onFail?.();
    }
    clearTimeout(toastTimeoutId);
  };

export const updateEmployee =
  (
    isAdmin: boolean,
    updatedAccount: UpdateEmployeeDataProps,
    toUpdateImages: toUpdateImagesType,
    toUpdateLinks: Link[],
    toUpdateFiles: ApiFile[],
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess: () => void,
    onFailed: () => void,
  ) =>
  async dispatch => {
    const { id, username, profileImageState, bannerImageState, logoHeaderState } = updatedAccount;

    const { profileImageFile, bannerImageFile, logoHeaderFile } = toUpdateImages;
    let profileImageUrl: string, bannerImageUrl: string, logoHeaderUrl: string;

    try {
      if (profileImageState === AccountImageState.DEFINED) {
        profileImageUrl = profileImageFile
          ? await doS3UploadForBusiness(
              `profile/${username}`,
              profileImageFile,
              id,
              getAccessTokenSilently,
              'profile',
            )
          : toUpdateImages.profileImageUrl;
      } else {
        profileImageUrl = null;
      }

      if (bannerImageState === AccountImageState.DEFINED) {
        bannerImageUrl = bannerImageFile
          ? (bannerImageUrl = await doS3UploadForBusiness(
              `banner/${username}`,
              bannerImageFile,
              id,
              getAccessTokenSilently,
              'banner',
            ))
          : toUpdateImages.bannerImageUrl;
      } else {
        bannerImageUrl = null;
      }

      if (logoHeaderState === AccountImageState.DEFINED) {
        logoHeaderUrl = logoHeaderFile
          ? await doS3UploadForBusiness(
              `logoHeader/${username}`,
              logoHeaderFile,
              id,
              getAccessTokenSilently,
              'logoHeader',
            )
          : toUpdateImages.logoHeaderUrl;
      } else {
        logoHeaderUrl = null;
      }

      const { defaultLinkId } = updatedAccount;
      let defaultLink = defaultLinkId && toUpdateLinks.find(link => link.id === defaultLinkId);

      toUpdateLinks.forEach(l => (!l.isShallow ? l : delete l.id));
      const linksResponse = await reorderLinksAPI(id, toUpdateLinks, getAccessTokenSilently);
      const links = linksResponse.data.data;

      if (defaultLink) {
        defaultLink = links.find(
          l =>
            l.name === defaultLink.name &&
            l.link === defaultLink.link &&
            l.linkTypeId === defaultLink.linkTypeId,
        );
        updatedAccount.defaultLinkId = defaultLink.id;
      }

      if (toUpdateFiles.length) {
        // const progressToastFct = (starttime: number, filename: string) => (progress: number) =>
        //   Date.now() - starttime > 2000
        //     ? progress < 1
        //       ? toast(`"${filename}": ${Math.round(progress * 100)}%`, {
        //           id: 'fileupload',
        //         })
        //       : setTimeout(() => useNotificationsStore.getState().notify.dismiss('fileupload'), 500)
        //     : undefined;
        for (const file of toUpdateFiles) {
          if (file.uploadedFile instanceof File) {
            // const starttime = Date.now();
            const url = file.link
              ? file.link
              : await doS3UploadForBusiness(
                  `file/${username}`,
                  file.uploadedFile,
                  id,
                  getAccessTokenSilently,
                  undefined,
                  // progressToastFct(starttime, file.uploadedFile.name),
                );

            delete file.id;
            file.link = url;
          } else if (file.fileTemplateId) delete file.id;
        }
      }

      const filesResponse = await reorderFilesAPI(id, toUpdateFiles, getAccessTokenSilently);
      const files = filesResponse.data.data;

      updatedAccount = {
        ...updatedAccount,
        profileImageUrl,
        bannerImageUrl,
        logoHeaderUrl,
        links,
        files,
      };
      const response = await _updateEmployee(updatedAccount, getAccessTokenSilently);
      const result = response.data;

      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELDS,
          id,
          fields: Object.keys(updatedAccount),
          values: Object.values(updatedAccount),
        });

        if (isAdmin) {
          dispatch({
            type: dispatchTypes.COMMON.UPDATEACCOUNT,
            account: updatedAccount,
          });
        }

        // tracking events
        profileImageFile &&
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_SINGLE,
            {
              type: PRIVATE_META_TYPES.PROFILE_PICTURE,
              editedAccountId: id,
            },
            null,
            null,
            true,
          );

        bannerImageFile &&
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_SINGLE,
            {
              type: PRIVATE_META_TYPES.BANNER,
              editedAccountId: id,
            },
            null,
            null,
            true,
          );

        trackPrivateEvent(
          getAccessTokenSilently,
          PRIVATE_EVENTS.PROFILE_EDIT_MD_SINGLE,
          {
            type: PRIVATE_META_TYPES.BIO,
          },
          null,
          null,
          true,
        );

        onSuccess();
      } else {
        onFailed();
      }
    } catch (error) {
      onFailed();
    }
  };

export const sendEmail =
  (id: string, email: string, getAccessTokenSilently, t: TFunction, onSuccess: () => void) =>
  async dispatch => {
    try {
      dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });

      let response = await _sendEmail(id, email, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.SENDEMAILSUCCESS,
          payload: { id, apiResponse: result },
        });

        dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });

        onSuccess();
      } else {
        dispatch({ type: dispatchTypes.BUSINESS.COMMON.SENDEMAILERROR, apiResponse: result });
        useNotificationsStore.getState().notify.error(t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.BUSINESS.COMMON.SENDEMAILERROR, apiResponse: error });
      useNotificationsStore.getState().notify.error(t('error.general'));
    }
  };

export const getThemeLinkTypes =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) => async dispatch => {
    try {
      let response = await fetchThemeLinkTypes(getAccessTokenSilently, true);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.LINKS.SETLINKSSUCCESS,
          themeLinkTypes: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.BUSINESS.LINKS.SETLINKSERROR,
          apiResponse: result,
        });
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.BUSINESS.LINKS.SETLINKSERROR, apiResponse: error });
    }
  };

export const clearApiResponse = () => async dispatch => {
  dispatch({ type: dispatchTypes.BUSINESS.CLEARAPIRESPONSE });
};
