import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LEAD_OPTION_TYPES } from '@/shared/constants';
import { LeadOptionAPI, LeadOptionTypeAPI } from '@/shared/types/api';
import { LeadOptionValue } from '@/shared/types/global';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import useId from '@mui/material/utils/useId';

type TextProps = {
  onChange: (value: string) => void;
  value: string;
  meta: string;
  titleId: string;
};

const RenderText = ({ onChange, value, meta, titleId }: TextProps) => {
  return (
    <TextField
      fullWidth
      placeholder={meta}
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
      multiline
      rows={4}
      inputProps={{
        'aria-labelledby': titleId,
      }}
    />
  );
};

type DropdownProps = {
  onChange: (value: string | number) => void;
  value: number;
  meta: { options: Array<{ id: number; title: string }> };
  titleId: string;
};

const RenderDropDown = ({ onChange, value, meta, titleId }: DropdownProps) => {
  const { options } = meta;

  return (
    <FormControl fullWidth>
      <Select labelId={titleId} value={value} onChange={({ target: { value } }) => onChange(value)}>
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type CheckboxProps = {
  onChange: (value: number[]) => void;
  value: number[];
  meta: { options: Array<{ id: number; title: string }> };
  titleId: string;
};

const RenderCheckbox = ({ onChange, value, meta, titleId }: CheckboxProps) => {
  const { options } = meta;

  const handleChange = option => {
    const currentValues = value || [];
    if (currentValues.includes(option.id)) {
      onChange(currentValues.filter(val => val !== option.id));
    } else {
      onChange([...currentValues, option.id]);
    }
  };

  return (
    <FormControl variant='outlined' fullWidth aria-describedby={titleId}>
      <FormGroup aria-label='position'>
        {options.map(option => (
          <FormControlLabel
            label={option.title}
            control={
              <Checkbox
                checked={(value || []).includes(option.id)}
                onChange={() => handleChange(option)}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const SingleOptionRenderer = ({ optionType, onChange, value }) => {
  const { activeLanguage } = useAppTranslation();
  const isDE = activeLanguage.startsWith('de');

  const label =
    isDE && optionType.translatedName?.de ? optionType.translatedName.de : optionType.name;
  const meta =
    isDE && optionType.translatedMeta?.de ? optionType.translatedMeta.de : optionType.meta;

  let Component = null;
  switch (optionType.type) {
    case LEAD_OPTION_TYPES.TEXT:
      Component = RenderText;
      break;
    case LEAD_OPTION_TYPES.SELECT:
      Component = RenderDropDown;
      break;
    case LEAD_OPTION_TYPES.CHECKBOX:
      Component = RenderCheckbox;
      break;
  }

  const titleId = useId();

  return Component ? (
    <Card>
      <CardHeader titleTypographyProps={{ variant: 'h4', id: titleId }} title={label} />
      <CardContent>
        <Component
          meta={meta}
          value={value}
          onChange={value => onChange(value, optionType.id)}
          titleId={titleId}
        />
      </CardContent>
    </Card>
  ) : null;
};

interface LeadOptionsProps {
  leadOptionTypes: Array<LeadOptionTypeAPI>;
  leadOptions: Array<LeadOptionAPI>;
  onChange: (value: Array<LeadOptionAPI>) => void;
}

export const LeadOptionsRenderer = ({
  leadOptionTypes,
  onChange,
  leadOptions,
}: LeadOptionsProps) => {
  const handleChange = (value: LeadOptionValue, optionTypeId: number) => {
    const currentLeadOptions = leadOptions || [];
    const currentLeadOption = currentLeadOptions.find(item => item.typeId === optionTypeId);

    if (currentLeadOption) {
      onChange(
        currentLeadOptions.map(item =>
          item.typeId === optionTypeId ? { typeId: optionTypeId, value: value, id: item.id } : item,
        ),
      );
      return;
    } else {
      onChange([...currentLeadOptions, { typeId: optionTypeId, value: value, id: null }]);
      return;
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={2} mb={2}>
      {leadOptionTypes?.map(optionType => (
        <SingleOptionRenderer
          key={optionType.id}
          value={
            leadOptions?.find(option => {
              return option.typeId === optionType.id;
            })?.value || null
          }
          onChange={handleChange}
          optionType={optionType}
        />
      ))}
    </Box>
  );
};
