import { RequireAtMostOne } from '@/shared/types/util';
import { Card, CardMedia, Typography, TypographyProps, styled } from '@mui/material';
import { Box, alpha } from '@mui/system';
import { ComponentProps, useCallback, useMemo } from 'react';
import LtMultiActionButton from './LtMultiActionButton';
import { LtDefaultActionButtonsProps } from './LtDefaultActionButtons/types';
import LtDefaultActionButtons from './LtDefaultActionButtons';

type Props = RequireAtMostOne<
  {
    image?: {
      src: string;
      alt: string;
      fill?: boolean;
    };
    title: string;
    body: string;
    loading?: boolean;
    disabled?: boolean;
    singleAction: LtDefaultActionButtonsProps['confirmAction'];
    multipleActions: {
      text: string;
      actions: ComponentProps<typeof LtMultiActionButton>['options'];
    };
    titleTypographyProps?: TypographyProps & { component?: React.ReactNode };
    sx?: ComponentProps<typeof Card>['sx'];
  },
  'singleAction' | 'multipleActions'
>;

export default function LtActionCard({
  image,
  title,
  body,
  loading,
  disabled,
  singleAction,
  multipleActions,
  titleTypographyProps,
  sx = {},
}: Props) {
  const ActionButton = useCallback(
    ({ className }: { className?: string }) => {
      if (singleAction) {
        return (
          <Box display='flex' justifyContent='end'>
            <LtDefaultActionButtons
              loading={loading}
              disabled={disabled}
              confirmAction={singleAction}
            />
          </Box>
        );
      } else if (multipleActions) {
        return (
          <LtMultiActionButton
            className={className}
            label={multipleActions.text}
            options={multipleActions.actions}
          />
        );
      } else {
        return null;
      }
    },
    [disabled, loading, multipleActions, singleAction],
  );
  const StyledActionButton = useMemo(
    () => styled(ActionButton)({ alignSelf: 'flex-end' }),
    [ActionButton],
  );

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', ...sx }}>
      {image && (
        <Box
          display='flex'
          justifyContent='center'
          sx={theme => ({ backgroundColor: alpha(theme.palette.primary.main, 0.04) })}
        >
          <CardMedia
            component='img'
            src={image.src}
            alt={image.alt}
            sx={
              image.fill
                ? {
                    height: '24rem',
                    objectFit: 'cover',
                  }
                : {
                    height: '24rem',
                    objectFit: 'contain',
                    width: '40%',
                    padding: 2,
                  }
            }
          />
        </Box>
      )}
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        gap={2}
        p={2}
        height='100%'
      >
        <Box>
          <Typography component='div' variant='h3' {...(titleTypographyProps || {})}>
            {title}
          </Typography>
          <Typography variant='body1' mt={1} mb={2}>
            {body}
          </Typography>
        </Box>
        <StyledActionButton />
      </Box>
    </Card>
  );
}
