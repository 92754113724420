import { GroupAddOutlined } from '@mui/icons-material';
import { Theme, Typography } from '@mui/material';
import { Box, alpha } from '@mui/system';
import { useTranslation } from 'react-i18next';

type Props = {
  actions: React.ReactNode;
};

export const NoUnits = ({ actions }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display='flex' alignItems='center' flexDirection='column' mt='15rem' px={2}>
      <Box
        sx={(theme: Theme) => ({
          border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
          bgcolor: alpha(theme.palette.primary.main, 0.04),
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '15rem',
          height: '15rem',
        })}
      >
        <GroupAddOutlined sx={{ fontSize: '5rem', color: 'primary.main' }} />
      </Box>
      <Typography sx={{ mt: '5rem' }} variant='h1' textAlign='center'>
        {t('mdUnits.noUnitsAreAdded')}
      </Typography>
      <Box gap={2} display='flex' mt='5rem'>
        {actions}
      </Box>
    </Box>
  );
};
