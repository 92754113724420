import config from '../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { getAuth0TokenScopeString, getFileName } from '../../util';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const doS3Upload = async (
  path: string,
  file: File | Blob | null,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  type: 'profile' | 'banner' | 'logoHeader' = undefined,
  onUploadProgressCb?: (progress: number) => void,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const fileName = getFileName(type, file);

  const { data } = await axios.get(config.API_BASE_URL + 'account/upload/get-signed-url', {
    params: {
      name: `${path}/${uuidv4()}/${fileName}`,
      contentType: file.type || 'application/octet-stream; charset=binary',
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const onUploadProgress = function (progressEvent) {
    const progress = progressEvent.loaded / progressEvent.total;
    onUploadProgressCb?.(progress);
  };

  const response = await axios({
    url: data.data.url,
    method: 'put',
    data: file,
    headers: { 'Content-Type': file.type },
    maxContentLength: 100 * 1024 * 1024 * 1024,
    timeout: 30 * 60 * 1000, //30mins
    onUploadProgress,
  });
  const urlafter = response.config.url;
  return urlafter.split('?')[0];
};

export const doS3UploadTemplateFile = async (
  path: string,
  file: File | Blob | null,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const fileName = getFileName('', file);

  const { data } = await axios.get(
    config.API_BASE_URL + 'theme/file-templates/upload/get-signed-url',
    {
      params: {
        name: `${path}/${fileName}`,
        contentType: file.type || 'application/octet-stream; charset=binary',
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const response = await axios({
    url: data.data.url,
    method: 'put',
    data: file,
    headers: { 'Content-Type': file.type },
    maxContentLength: 100 * 1024 * 1024 * 1024,
    timeout: 30 * 60 * 1000, //30mins
  });
  const urlafter = response.config.url;
  return urlafter.split('?')[0];
};

export const doS3LeadUpload = async (
  path: string,
  file: File,
  accountId: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });
  const { data } = await axios.get(
    config.API_BASE_URL + `lead-gen/accounts/${accountId}/get-signed-url`,
    {
      params: {
        name: `${path}/${uuidv4()}/${file.name}`,
        contentType: file.type || 'application/octet-stream; charset=binary',
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const response = await axios({
    url: data.data.url,
    method: 'put',
    data: file,
    headers: { 'Content-Type': file.type },
    maxContentLength: 100 * 1024 * 1024 * 1024,
    timeout: 30 * 60 * 1000, //30mins
  });
  const urlafter = response.config.url;
  return urlafter.split('?')[0];
};

export const doS3UploadForBusiness = async (
  path: string,
  file: File,
  accountId: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  type = undefined,
  onUploadProgressCb?: (progress: number) => void,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const fileName = getFileName(type, file);

  const { data } = await axios.get(config.API_BASE_URL + 'business/upload/get-signed-url', {
    params: {
      accountId,
      name: `${path}/${uuidv4()}/${fileName}`,
      contentType: file.type || 'application/octet-stream; charset=binary',
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const progressToastFct = (starttime: number, filename: string) => (progress: number) =>
    Date.now() - starttime > 2000
      ? progress < 1
        ? useNotificationsStore
            .getState()
            .notify.loading(`"${filename}": ${Math.round(progress * 100)}%`, {
              id: 'fileupload',
            })
        : setTimeout(() => useNotificationsStore.getState().notify.dismiss('fileupload'), 500)
      : undefined;

  const starttime = Date.now();
  const response = await axios({
    url: data.data.url,
    method: 'put',
    data: file,
    headers: { 'Content-Type': file.type },
    maxContentLength: 100 * 1024 * 1024 * 1024,
    timeout: 30 * 60 * 1000, //30mins
    onUploadProgress: progressEvent => {
      const progress = progressEvent.loaded / progressEvent.total;
      onUploadProgressCb?.(progress);
      progressToastFct(starttime, file.name)(progress);
    },
  });
  const urlafter = response.config.url;
  return urlafter.split('?')[0];
};
