import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useHashState(defaultValue: string | null = null) {
  const history = useHistory();
  const location = useLocation();

  const getHashValue = useCallback(() => {
    return location.hash ? decodeURIComponent(location.hash.substring(1)) : defaultValue;
  }, [defaultValue, location.hash]);

  const [state, setState] = useState<string | null>(getHashValue);

  useEffect(() => {
    const handleHashChange = () => {
      setState(getHashValue());
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [getHashValue]);

  const setHashState = useCallback(
    (newState?: string | null) => {
      const hash = newState ? `#${encodeURIComponent(newState)}` : '';
      history.push(`${location.pathname}${location.search}${hash}`);
      setState(newState);
    },
    [history, location.pathname, location.search],
  );

  return [state, setHashState] as const;
}

export default useHashState;
