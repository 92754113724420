import { LtDialog } from '@/components';
import { DeleteOutline } from '@mui/icons-material';
import { Box, IconButton, SxProps, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  src: string;
  onDeleteClick: () => void;
  sx?: SxProps;
}

export const SingleListImage = ({ src, onDeleteClick, sx = {} }: Props) => {
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'relative',
        width: '17rem',
        marginRight: '1.4rem',
        ...sx,
      }}
    >
      <StyledImg src={src} alt='banner' />
      <IconButton
        size='medium'
        sx={theme => ({
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          boxShadow: 1,
          background: theme.palette.background.paper,
          ':hover': {
            background: theme.palette.action.hover,
          },
        })}
        onClick={() => setDeletePopupOpened(true)}
      >
        <DeleteOutline fontSize='small' />
      </IconButton>

      <LtDialog
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={() => {
          onDeleteClick();
          setDeletePopupOpened(false);
        }}
        size='sm'
      >
        {t('requestDelete')}
      </LtDialog>
    </Box>
  );
};

const StyledImg = styled('img')({
  width: '100%',
  borderRadius: '5px',
  display: 'block',
});
