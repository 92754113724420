import { useAppSelector } from '@/application/hooks';
import { CardRenderer } from '@/components/CardRenderer';
import { routePaths } from '@/infrastructure/constants';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { ThemeInternal } from '@/shared/types/api';
import { CreditCardOutlined, PhoneAndroidOutlined, VideocamOutlined } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ShareMenu = () => {
  const { t } = useAppTranslation();
  const { hideNfcCardUI } = useAppSelector<ThemeInternal>(
    state => state.account.theme?.themeInternal,
  );
  return (
    <CardRenderer variant='outlined' sx={{ mt: 1.5, mb: 1.5 }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Link to={{ pathname: routePaths.QR_CODE_BACKGROUND.PHONE, state: { screen: 'mobile' } }}>
          <Box display={'flex'} pt={1} pb={1} alignItems={'center'}>
            <PhoneAndroidOutlined sx={{ color: 'text.primary', mr: 1 }} />
            <Typography variant='body2'>{t('shareProfile.shareMenu.qrMobile')}</Typography>
          </Box>
        </Link>
        <Divider />
        <Link
          to={{ pathname: routePaths.QR_CODE_BACKGROUND.VIDEOCALL, state: { screen: 'videocall' } }}
        >
          <Box display={'flex'} pt={1} pb={1} alignItems={'center'}>
            <VideocamOutlined sx={{ color: 'text.primary', mr: 1 }} />
            <Typography variant='body2'>{t('shareProfile.shareMenu.qrDesktop')}</Typography>
          </Box>
        </Link>
        {!hideNfcCardUI && (
          <>
            <Divider />
            <Link to={routePaths.NFC_CARDS}>
              <Box display={'flex'} pt={1} pb={1} alignItems={'center'}>
                <CreditCardOutlined sx={{ color: 'text.primary', mr: 1 }} />
                <Typography variant='body2'>{t('shareProfile.shareMenu.nfcCard')}</Typography>
              </Box>
            </Link>
          </>
        )}
      </Box>
    </CardRenderer>
  );
};

export default ShareMenu;
