import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DefinedRange } from '../utils/types';
import { isSameDay } from '../utils/utils';
import { DateRange } from '@/shared/types/api';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

type DefinedRangesProps = {
  setRange: (range: DateRange) => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
};

const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

const DefinedRanges: React.FunctionComponent<DefinedRangesProps> = ({
  ranges,
  setRange,
  selectedRange,
}: DefinedRangesProps) => {
  const { t } = useTranslation();
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems='center' width='17rem'>
      {ranges.map((range, idx) => (
        <Button
          variant='text'
          key={idx}
          onClick={() => setRange({ startDate: range.startDate, endDate: range.endDate })}
          sx={{ justifyContent: 'flex-start', py: 1, px: 1 }}
        >
          <Typography
            variant='body2'
            sx={{
              fontWeight: isSameRange(range, selectedRange) ? 'bold' : 'normal',
            }}
          >
            <span style={visuallyHidden}>{t('ariaSelectPredefinedRange')}</span>
            {range.label}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default DefinedRanges;
