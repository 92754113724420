import { ProfileEditProps, TrackHandlers } from '@/components/Profile/types';
import { Box, Card, IconButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditProfileImage from './edit';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import LtDialog from '@/components/LtDialog';

interface Props {
  profileImageUrl: string | null;
  editProps?: ProfileEditProps['profileImageEditProps'];
  profileImageClickTrackHandler?: TrackHandlers['profileImageClickTrackHandler'];
}

export const WIDTH = 10.5;

export default function ProfilePicture({
  profileImageUrl,
  editProps,
  profileImageClickTrackHandler,
}: Props) {
  const isEdit = !!editProps;

  const { t } = useTranslation();
  const [imageModalShown, setImageModalShown] = useState(false);

  if (isEdit) {
    if (profileImageUrl) {
      return (
        <Wrapper>
          <ProfileImage component='img' src={profileImageUrl} alt={t('ariaProfilePicture')} />
          <Box sx={{ position: 'absolute', bottom: 0, right: 1 }}>
            <EditProfileImage {...editProps} />
          </Box>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper
          component={Card}
          sx={{
            borderRadius: '50%',
            background: 'white',
          }}
        >
          <EditProfileImage
            CustomAnchor={props => (
              <IconButton
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                onClick={props.onClick}
                aria-label={t('ariaAddProfilePicture')}
              >
                <Add />
              </IconButton>
            )}
            {...editProps}
          />
        </Wrapper>
      );
    }
  }

  return (
    <>
      <Wrapper>
        <ProfileImage
          component='img'
          src={profileImageUrl}
          alt={t('ariaProfilePicture')}
          onClick={() => {
            setImageModalShown(true);
            profileImageClickTrackHandler?.();
          }}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              setImageModalShown(true);
              profileImageClickTrackHandler?.();
            }
          }}
          role='button'
          tabIndex={0}
          aria-label={t('ariaOpenToEnlarge')}
          sx={{
            cursor: 'pointer',
            '&:focus': {
              outline: '3px solid black',
            },
          }}
        />
      </Wrapper>
      <LtDialog
        open={imageModalShown}
        onClose={() => setImageModalShown(false)}
        onDone={() => setImageModalShown(false)}
      >
        <Box
          component='img'
          src={profileImageUrl}
          alignContent={t('ariaProfilePicture')}
          sx={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </LtDialog>
    </>
  );
}

const Wrapper: typeof Box = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: theme.spacing(WIDTH),
  height: theme.spacing(WIDTH),
}));

const ProfileImage: typeof Box = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '50%',
  border: '5px solid white',
}));
