import { LtDialog, LtSorter } from '@/components';
import { Account, ApiFile, FileTemplate } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '@/application/hooks';
import { useTranslation } from 'react-i18next';
import { updateFiles } from '@/infrastructure/apis/files';
import { FileBox } from '@/components/Files/FileBox';
import AddNewFilePopup from '@/components/Profile/components/popups/add-new-file-popup';
import { getUploadFileThemeUrl } from '@/application/actions/md/profiles/files';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { UploadfileProps } from '../../../profiles/common/files';
import { getFileTemplates } from '@/infrastructure/apis/fileTemplates';
import { SectionRenderer } from '../../SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

type LocalApiFile = ApiFile & { fileToUpload?: File };

type Props = {
  selectedUnitId?: number | null;
  defaultFiles: ApiFile[];
  refetchFiles: () => void;
  mutateDefaultFiles: (files: ApiFile[]) => void;
};

export const FilesSection = ({
  selectedUnitId,
  defaultFiles,
  mutateDefaultFiles,
  refetchFiles,
}: Props) => {
  const { notify: toast } = useLtNotifications();
  const [addFileDialogOpened, setAddFileDialogOpened] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<number | null>(null);

  const [fileTemplates, setFileTemplates] = useState<FileTemplate[]>([]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const account: Account = useAppSelector(state => state.account);
  const theme = account.theme;

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { profileDesign } = useProfileDesignForUnits(selectedUnitId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fileTemplates = await getFileTemplates(getAccessTokenSilently);
        setFileTemplates(fileTemplates);
      } catch (error) {
        toast.error(t('md.defaultContent.errorLoadingFiles', { id: 'default-content-loading' }));
      }
    };
    fetchData();
  }, [getAccessTokenSilently, t, toast]);

  const handleFileSave = async (files: ApiFile[], shouldRefetch?: boolean) => {
    await updateFiles(
      getAccessTokenSilently,
      files.map((x, i) => ({ ...x, order: i * 10 })),
      selectedUnitId,
    );

    if (shouldRefetch) {
      refetchFiles();
    } else {
      mutateDefaultFiles(files);
    }
  };

  const handleFileDelete = async () => {
    setDeleting(true);
    const newFiles = defaultFiles.filter(l => l.id !== fileIdToDelete);
    try {
      await handleFileSave(newFiles);
      setFileIdToDelete(null);
      toast.success(t('md.defaultContent.successDeleteFile'));
    } catch (error) {
      console.log(error);
      toast.error(t('md.defaultContent.errorDeleteFile'));
    }
    setDeleting(false);
  };

  const handleSortEnd = (newFiles: ApiFile[]) => {
    mutateDefaultFiles(newFiles);
    handleFileSave(newFiles);
  };

  const getLinksFromFiles = useCallback(
    async (files: LocalApiFile[]) => {
      return await Promise.all(
        files.map(async file => {
          if (file.link) {
            return file.link;
          } else if (file.id < 0 && file.fileToUpload) {
            return file.link
              ? file.link
              : await getUploadFileThemeUrl(
                  theme?.themeInternal.uniqueId,
                  file.fileToUpload,
                  account.id,
                  getAccessTokenSilently,
                );
          } else {
            return '';
          }
        }),
      );
    },
    [theme?.themeInternal.uniqueId, account.id, getAccessTokenSilently],
  );

  const handleFileAdd = async (config: {
    fileType: 'newFile' | 'templateFile';
    newFileDetails?: UploadfileProps;
  }) => {
    setSaving(true);
    const newFiles: (ApiFile & { fileToUpload?: File })[] = [
      ...defaultFiles,
      {
        id: config.fileType === 'templateFile' ? Math.random() : -Math.random(),
        link: config.newFileDetails.link,
        fileName: config.newFileDetails.fileName,
        embed: config.newFileDetails.embed,
        canDisplayOnProfile: true,
        order: defaultFiles.length,
        pageCount: config.newFileDetails.pageCount,
        fileTemplateId: config.fileType === 'newFile' ? null : config.newFileDetails.fileTemplateId,
        fileToUpload: config.fileType === 'newFile' && config.newFileDetails?.uploadedFile,
      },
    ];

    const links: string[] = await getLinksFromFiles(newFiles);

    const filesForUpdate: ApiFile[] = newFiles.map(({ id, fileToUpload, ...rest }, index) => ({
      ...rest,
      ...(id < 1 ? {} : { id }), // files ids is between 0 and 1, uploaded file ids is below 0. Keep ids only for already existing theme files
      link: links[index],
    }));

    try {
      await handleFileSave(filesForUpdate, true);
      setAddFileDialogOpened(false);
      toast.success(t('md.defaultContent.successSaveFile'));
    } catch (error) {
      console.log(error);
      toast.error(t('md.defaultContent.errorSavingFile'));
    }
    setSaving(false);
  };

  return (
    <SectionRenderer
      title={t('md.defaultContent.defaultFiles')}
      btnAriaLabel={t('ariaAddFile')}
      onAdd={() => setAddFileDialogOpened(true)}
    >
      <LtSorter
        items={defaultFiles}
        getItemAriaLabel={item => `${t('md.defaultContent.defaultFile')}: ${item.fileName}`}
        getItemKey={item => item.id}
        renderer={file => (
          <FileBox
            file={file}
            onDelete={() => setFileIdToDelete(file.id)}
            iconBgColor={profileDesign.fileBoxColor}
            chips={[t('default'), file.fileTemplateId && t('template')].filter(Boolean)}
          />
        )}
        onEnd={handleSortEnd}
      />
      {addFileDialogOpened && (
        <AddNewFilePopup
          isCompanydataSettings
          handleCompanySettingsUpdate={handleFileAdd}
          onCloseClick={() => setAddFileDialogOpened(false)}
          templates={fileTemplates}
          showTemplatesSelector
          loading={saving}
        />
      )}

      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(fileIdToDelete)}
        onClose={() => setFileIdToDelete(null)}
        onCancel={() => setFileIdToDelete(null)}
        onDelete={handleFileDelete}
        loading={deleting}
      >
        <Typography>{t('deleteMessageFile')}</Typography>
      </LtDialog>
    </SectionRenderer>
  );
};
