import { getConsumer } from '@/infrastructure/apis/md/crmIntegration';
import { ApideckConnectionState, ApideckConsumer } from '@/shared/types/api';
import { useConnectorsFetch } from '@/views/pages/CrmIntegration/hooks/useConnectorsFetch';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const useCrmExport = shouldFetchConsumer => {
  const { getAccessTokenSilently } = useAuth0();
  const [apideckConsumer, setApideckConsumer] = useState<ApideckConsumer | null>(null);

  const { connectors: crmConnectors } = useConnectorsFetch();

  const callableConnections = crmConnectors.filter(connector =>
    apideckConsumer?.apideckConnections?.some(
      c => c.seviceId === connector.id && c.enabled && c.state === ApideckConnectionState.CALLABLE,
    ),
  );
  const hasCallableApideckConnection = !!callableConnections.length;
  const connectionsToList = hasCallableApideckConnection ? callableConnections : crmConnectors;

  useEffect(() => {
    if (!shouldFetchConsumer) return;
    const fetchConsumer = async () => {
      try {
        const { data } = await getConsumer(getAccessTokenSilently);
        setApideckConsumer(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchConsumer();
  }, [shouldFetchConsumer, getAccessTokenSilently]);

  return {
    apideckConsumer,
    connectionsToList,
    hasCallableApideckConnection,
    crmConnectors,
  };
};
