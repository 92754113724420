import { ArrowBack } from '@mui/icons-material';
import { IconButton, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

function a11yProps(index: number) {
  return {
    id: `edite-employee-tab-${index}`,
    'aria-controls': `edite-employee-tab-panel-${index}`,
  };
}

type Props = {
  value: string;
  tabs: string[];
  onChange: (val: string) => void;
  onClose: () => void;
};

export const EditEmployeesTabs = ({ onChange, onClose, tabs, value }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider',
        px: 2,
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.paper',
        zIndex: 11,
      }}
    >
      <Box display='flex' alignItems='center' justifyContent='center'>
        <IconButton onClick={onClose} aria-label={t('ariaCloseDrawer')}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Box>
        <Tabs value={value} onChange={(_, newVal) => onChange(newVal)}>
          {tabs.map((tab, index) => (
            <Tab key={tab} label={t(tab)} {...a11yProps(index)} value={tab} />
          ))}
        </Tabs>
      </Box>
      <Box />
    </Box>
  );
};
