import { dispatchTypes } from './index';
import { hasAccount, saveCode } from '../../infrastructure/apis/landing';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import i18n from '../../config/i18n';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const landingHasAccount = (usernameOrCode: string) => async dispatch => {
  dispatch({ type: dispatchTypes.LANDING.HAS_ACCOUNT_INIT });
  try {
    let response = await hasAccount(usernameOrCode);
    const result = response.data;

    if (result.hasAccount) {
      dispatch({
        type: dispatchTypes.LANDING.HAS_ACCOUNT_SUCCESS,
        hasAccount: result.hasAccount,
        isUsername: result.isUsername,
        isCode: result.isCode,
        code: result.isCode ? usernameOrCode : false,
      });
    } else {
      dispatch({
        type: dispatchTypes.LANDING.HAS_ACCOUNT_ERROR,
        isCode: result.isCode,
        code: result.isCode ? usernameOrCode : false,
        errorMsg: result.message,
      });
    }
  } catch (error) {
    dispatch({
      type: dispatchTypes.LANDING.HAS_ACCOUNT_ERROR,
      errorMsg: error,
    });
    useNotificationsStore.getState().notify.error(i18n.t('error.general'));
  }
};

export const addCodeAction =
  (
    code: string,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    showSuccessToaster: boolean = true,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.LANDING.ADD_CODE_INIT });

    try {
      let response = await saveCode(code, getAccessTokenSilently);

      const result = response.data;

      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.LANDING.ADD_CODE_SUCCESS,
          apiResponse: result,
        });
        dispatch({ type: dispatchTypes.LANDING.RESET_AUTH_CALLBACK_STATE });
        if (showSuccessToaster)
          useNotificationsStore.getState().notify.success(i18n.t('addCodeSuccess'));
      } else {
        dispatch({
          type: dispatchTypes.LANDING.ADD_CODE_ERROR,
          error: result,
        });
        useNotificationsStore.getState().notify.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({
        type: dispatchTypes.LANDING.ADD_CODE_ERROR,
        error,
      });
      useNotificationsStore.getState().notify.error(i18n.t('error.general'));
    }
  };

export const setAuthCallbackState = (state: Object) => dispatch => {
  dispatch({ type: dispatchTypes.LANDING.SET_AUTH_CALLBACK_STATE, state });
};

export const resetAuthCallbackState = () => dispatch => {
  dispatch({ type: dispatchTypes.LANDING.RESET_AUTH_CALLBACK_STATE });
};
