import { useRef, useState } from 'react';
import { isFileValid } from '../../../../../../infrastructure/helper';
import { useTranslation } from 'react-i18next';
import { AccountImageState } from '@/shared/types/api';
import { DeleteOutlined, ImageOutlined, Upload } from '@mui/icons-material';
import { ImageSettings } from '@/components/ImageSettings';
import { Box, Typography, styled } from '@mui/material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import ImageCropper from '@/components/ImageCropper';
import BannerChooser from './banner-chooser';

interface Props {
  bannerImgOptions: string[];
  bannerImageUrl?: string;
  defaultBannerImageUrl?: string;
  onChange: (file?: File, url?: string, state?: AccountImageState) => void;
  bannerImageState?: AccountImageState;
  defaultBannerImageState?: AccountImageState;
}
const Banner = ({
  bannerImgOptions,
  onChange,
  bannerImageUrl,
  defaultBannerImageUrl,
  bannerImageState,
  defaultBannerImageState,
}: Props) => {
  let fileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const [bannerImage, setBannerImage] = useState('');
  const [showBannerChooser, setShowBannerChooser] = useState(false);

  const onImageClick = () => {
    if (bannerImgOptions && bannerImgOptions.length > 0) setShowBannerChooser(true);
    else fileInputRef.current.click();
  };

  const onImageSelection = async () => {
    const uploadedFile = fileInputRef.current.files[0];
    const extension = uploadedFile.name.split('.').pop();

    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        toast.error(errMsg);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);

      if (extension === 'gif') {
        reader.onload = () => {
          onChange(uploadedFile, reader.result as string, AccountImageState.DEFINED);
        };
      } else {
        reader.onload = () => setBannerImage(reader.result as string);
      }
    }
  };

  const onBannerUrlSelected = async (newUrl: string) => {
    onChange(undefined, newUrl, AccountImageState.DEFINED);
    setShowBannerChooser(false);
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    var file = new File([blob], fileInputRef.current.files[0].name);
    setBannerImage('');
    onChange(file, croppedImageUrl, AccountImageState.DEFINED);
  };

  const items = [
    {
      icon: <Upload />,
      label: t('uploadNew'),
      onClick: () => onImageClick(),
    },
    defaultBannerImageUrl &&
      bannerImageState !== AccountImageState.DEFAULT && {
        icon:
          defaultBannerImageState === AccountImageState.DELETED ? (
            <DeleteOutlined />
          ) : (
            <ImageOutlined />
          ),
        label:
          defaultBannerImageState === AccountImageState.DELETED ? t('delete') : t('resetToDefault'),
        onClick: () => onChange(null, defaultBannerImageUrl, AccountImageState.DEFAULT),
      },
  ].filter(Boolean);

  return (
    <>
      {bannerImage && (
        <ImageCropper
          image={bannerImage}
          onCropClick={url => onUploadClick(url)}
          onClose={() => setBannerImage('')}
          cropOptions={{ width: 100, x: 0, y: 0, aspect: 16 / 9 }}
        />
      )}

      {showBannerChooser && (
        <BannerChooser
          onCancel={() => setShowBannerChooser(false)}
          options={bannerImgOptions}
          onSelected={onBannerUrlSelected}
          current={bannerImageUrl}
        />
      )}

      <Box
        position='relative'
        display='flex'
        flexDirection='column'
        m='0 auto'
        mx={8}
        alignItems='center'
      >
        {bannerImageUrl && <BannerImage src={bannerImageUrl} alt='Banner' />}
        {!bannerImageUrl && <ImageSettings isButton buttonText={t('addCover')} actions={items} />}
        <Typography variant='caption'>{t('imageSize')}</Typography>
        {bannerImageUrl && (
          <EditBannerWrapper>
            <ImageSettings actions={items} ariaLabel={t('editCoverPicture')} />
          </EditBannerWrapper>
        )}
      </Box>

      <input
        type='file'
        ref={fileInputRef}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={() => onImageSelection()}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default Banner;

const BannerImage = styled('img')({
  width: '100%',
  borderRadius: '10px',
});

const EditBannerWrapper = styled('div')({
  position: 'absolute',
  background: '#e7e7e7',
  border: '1px solid #ffffff',
  boxSizing: 'border-box',
  borderRadius: '50%',
  bottom: 0,
  right: 0,
  transform: 'translate(50%, 0)',
  width: 'max-content',
  height: 'max-content',
});
