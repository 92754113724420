import { Avatar, Box, IconButton, TableCell, TableCellProps, useTheme } from '@mui/material';
import CustomMenuList, { MenuItemType } from '../CustomMenuList';
import { MoreVert } from '@mui/icons-material';
import { LineClamp } from '../LineClamp';

export type CustomTableCellProps = TableCellProps & {
  children: React.ReactNode;
  dim?: 's' | 'm' | 'l';
  tooltipText?: string;
  noPadding?: boolean;
  lineClampCount?: number;
  avatarSrc?: string;
  renderAvatar?: boolean;
  actions?: MenuItemType[];
  actionsIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onAvatarClick?: () => void;
  actionsBtnAriaLabel?: string;
  avatarAriaLabel?: string;
};

const CustomTableCell = ({
  children,
  dim,
  sx,
  tooltipText,
  noPadding,
  lineClampCount,
  renderAvatar,
  avatarSrc,
  actions,
  actionsIcon,
  onClick,
  onAvatarClick,
  actionsBtnAriaLabel,
  avatarAriaLabel,
  ...rest
}: CustomTableCellProps) => {
  const theme = useTheme();
  const width = !dim || dim === 'm' ? 20 : dim === 'l' ? 30 : 15;

  const avatarClickHandler = onAvatarClick || onClick;

  return (
    <TableCell
      {...rest}
      sx={{
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        minWidth: theme.spacing(width - 2),
        width: theme.spacing(width),
        maxWidth: theme.spacing(width + 2),
        p: 0,
        '&:first-child': {
          borderRight: 'none',
        },
        height: '1px',
        wordWrap: 'break-word',
        cursor: 'default',
        ...(onClick && {
          cursor: 'pointer',
        }),
      }}
      onClick={e => onClick?.(e)}
    >
      <Box
        display='flex'
        alignItems='center'
        width='100%'
        height='100%'
        py={!noPadding && '0.6rem'}
        px={!noPadding && '1.6rem'}
        sx={{ ...sx }}
      >
        {renderAvatar && (
          <Box
            component={avatarClickHandler ? IconButton : 'div'}
            sx={{
              p: 0,
              mx: 1,
              '&.Mui-focusVisible': {
                outlineOffset: '0px',
              },
            }}
            onClick={e => {
              if (avatarClickHandler) {
                e.stopPropagation();
                avatarClickHandler(e);
              }
            }}
            aria-label={avatarAriaLabel}
            aria-hidden={!avatarClickHandler}
          >
            <Avatar
              sx={theme => ({
                cursor: avatarClickHandler ? 'pointer' : 'default',
                width: theme.spacing(3),
                height: theme.spacing(3),
              })}
              src={avatarSrc}
            />
          </Box>
        )}
        <Box
          flex={1}
          height='100%'
          display='flex'
          alignItems='center'
          sx={{
            ...(onClick
              ? {
                  ':hover': { textDecoration: 'underline' },
                  ':focus-visible': { textDecoration: 'underline' },
                  cursor: 'pointer',
                }
              : {}),
            wordBreak: 'break-word',
          }}
        >
          {lineClampCount ? (
            <LineClamp lineCount={lineClampCount} text={tooltipText}>
              {children}
            </LineClamp>
          ) : (
            children
          )}
          {/* for jaws '23 compatibility, the table cell cannot be empty */}
          {!children && '\u00A0'}
        </Box>
        {actions && actions.length > 0 && (
          <CustomMenuList
            items={actions}
            toggleElement={
              <IconButton
                aria-label={actionsBtnAriaLabel}
                aria-haspopup={Boolean(actionsBtnAriaLabel)}
              >
                {actionsIcon || <MoreVert />}
              </IconButton>
            }
          />
        )}
      </Box>
    </TableCell>
  );
};

export default CustomTableCell;
