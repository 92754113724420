import { LtDialog } from '@/components';
import { Account, ApiFile, FileTemplate } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '@/application/hooks';
import { useTranslation } from 'react-i18next';
import { FileBox } from '@/components/Files/FileBox';
import AddNewFilePopup from '@/components/Profile/components/popups/add-new-file-popup';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { UploadfileProps } from '../../../profiles/common/files';
import {
  FileTemplatePayload,
  createFileTemplate,
  deleteFileTemplate,
  getFileTemplates,
  updateFileTemplate,
} from '@/infrastructure/apis/fileTemplates';
import { doS3Upload } from '@/infrastructure/apis/aws';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { SectionRenderer } from '../../SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const mapFileTemplateToApiFile = (fileTemplate: FileTemplate): ApiFile => ({
  id: fileTemplate.id,
  link: fileTemplate.link,
  fileName: fileTemplate.fileName,
  pageCount: fileTemplate.pageCount,
  embed: fileTemplate.embed,
  /** props to make TS work, not relevant */
  canDisplayOnProfile: false,
  order: 0,
});

type Props = {
  selectedUnitId?: number | null;
};

/**
 * For simplicity we map FileTemplate to ApiFile objects for easy manipulation
 * after fetch - map FileTemplate to ApiFile objects
 * before save - map Link objects to FileTemplatePayload objects
 */

export const FilesSection = ({ selectedUnitId }: Props) => {
  const { notifySuccess, notifyError } = useLtNotifications();

  const [addFileTemplateDialogOpened, setAddFileTemplateDialogOpened] = useState(false);
  const [fileTemplateIdToDelete, setFileTemplateIdToDelete] = useState<number | null>(null);
  const [fileTemplateToEdit, setFileTemplateToEdit] = useState<ApiFile | null>(null);

  const [fileTemplates, setFileTemplates] = useState<ApiFile[]>([]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  useDeferredLoader(loading, 'content-templates-loading');

  const account: Account = useAppSelector(state => state.account);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { profileDesign } = useProfileDesignForUnits(selectedUnitId);

  const fetchFileTemplates = useCallback(async () => {
    try {
      const fileTemplates = await getFileTemplates(getAccessTokenSilently);
      setFileTemplates(fileTemplates.map(mapFileTemplateToApiFile));
    } catch (error) {
      notifyError(t('md.contentTemplate.errorLoadingFiles'));
    }
  }, [getAccessTokenSilently, notifyError, t]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchFileTemplates();
      setLoading(false);
    };
    fetchData();
  }, [fetchFileTemplates]);

  const handleFileDelete = async () => {
    setDeleting(true);
    try {
      await deleteFileTemplate(getAccessTokenSilently, fileTemplateIdToDelete);
      setFileTemplates(fileTemplates.filter(file => file.id !== fileTemplateIdToDelete));
      setFileTemplateIdToDelete(null);
      notifySuccess(t('md.contentTemplate.successDeleteFile'));
    } catch (error) {
      notifyError(t('md.contentTemplate.errorDeleteFile'));
    }
    setDeleting(false);
  };

  const handleFileAdd = async ({ newFileDetails }: { newFileDetails?: UploadfileProps }) => {
    if (!newFileDetails) return;

    setSaving(true);
    try {
      const fileLink = newFileDetails.uploadedFile
        ? await doS3Upload(
            `file/${account.username}`,
            newFileDetails.uploadedFile,
            getAccessTokenSilently,
          )
        : newFileDetails.link;

      const payload: FileTemplatePayload = {
        embed: newFileDetails.embed,
        fileName: newFileDetails.fileName,
        pageCount: newFileDetails.pageCount,
        link: fileLink,
      };

      const newFileTemplates = await createFileTemplate(getAccessTokenSilently, payload);
      setFileTemplates(newFileTemplates.map(mapFileTemplateToApiFile));
      setAddFileTemplateDialogOpened(false);
      notifySuccess(t('md.contentTemplate.successSaveFile'));
    } catch (error) {
      notifyError(t('md.contentTemplate.errorSavingFile'));
    }
    setSaving(false);
  };

  const handleEditFile = async (
    fileName: string,
    oldFile: ApiFile,
    embed: boolean,
    newUploadedFile: { file: File; pageCount: number },
  ) => {
    setSaving(true);

    try {
      const fileLink = newUploadedFile.file
        ? await doS3Upload(`file/${account.username}`, newUploadedFile.file, getAccessTokenSilently)
        : oldFile.link;

      const pageCount = newUploadedFile.file ? newUploadedFile.pageCount : oldFile.pageCount;

      const payload: FileTemplatePayload = {
        id: oldFile.id,
        embed: embed,
        fileName: fileName,
        link: fileLink,
        pageCount: pageCount,
      };

      const newFileTemplates = await updateFileTemplate(getAccessTokenSilently, payload);
      setFileTemplates(newFileTemplates.map(mapFileTemplateToApiFile));
      setFileTemplateToEdit(null);
      notifySuccess(t('md.contentTemplate.successSaveFile'));
    } catch {
      notifyError(t('md.contentTemplate.errorSavingFile'));
    }
    setSaving(false);
  };

  return (
    <SectionRenderer
      title={t('md.contentTemplate.fileTemplates')}
      btnAriaLabel={t('addFileTemplate')}
      onAdd={() => setAddFileTemplateDialogOpened(true)}
    >
      <Box display='flex' flexDirection='column' gap={2}>
        {fileTemplates.map((fileTemplate, index) => (
          <FileBox
            key={index}
            file={fileTemplate}
            onDelete={() => setFileTemplateIdToDelete(fileTemplate.id)}
            onEdit={() => setFileTemplateToEdit(fileTemplate)}
            iconBgColor={profileDesign.fileBoxColor}
            chips={[t('template')].filter(Boolean)}
          />
        ))}
      </Box>
      {addFileTemplateDialogOpened && (
        <AddNewFilePopup
          isCompanydataSettings
          handleCompanySettingsUpdate={handleFileAdd}
          onCloseClick={() => setAddFileTemplateDialogOpened(false)}
          loading={saving}
        />
      )}
      {fileTemplateToEdit && (
        <AddNewFilePopup
          onCloseClick={() => setFileTemplateToEdit(null)}
          isEdit
          fileToEdit={fileTemplateToEdit}
          handleEdit={handleEditFile}
          loading={saving}
        />
      )}
      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(fileTemplateIdToDelete)}
        onClose={() => setFileTemplateIdToDelete(null)}
        onCancel={() => setFileTemplateIdToDelete(null)}
        onDelete={handleFileDelete}
        loading={deleting}
      >
        <Typography>{t('deleteMessageFile')}</Typography>
      </LtDialog>
    </SectionRenderer>
  );
};
