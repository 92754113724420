import { Link, Typography, Box, BoxProps } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { FontWeight } from '@/shared/types/api';

type Props = {
  text: string;
  href: string;
} & BoxProps;

export const AnchorText = ({ text, href, sx, ...rest }: Props) => {
  const _sx = {
    ...sx,
    width: 'max-content',
  };
  return (
    <Box {...rest} sx={_sx}>
      <Typography
        underline='none'
        component={Link}
        href={href}
        variant='body1'
        display={'flex'}
        gap={1}
        fontWeight={FontWeight.Medium}
      >
        {text} <EastIcon fontSize='small' />
      </Typography>
    </Box>
  );
};
