import React from 'react';

import { Box } from '@mui/material';
import DateRangePicker from './DateRangePicker';

import { DefinedRange } from '../utils/types';
import { DateRange } from '@/shared/types/api';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

export interface DateRangePickerWrapperProps {
  open: boolean;
  toggle: () => void;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date;
  maxDate?: Date;
  onChange: (dateRange: DateRange) => void;
  closeOnClickOutside?: boolean;
  wrapperClassName?: string;
  coupledView?: boolean;
}

const DateRangePickerWrapper: React.FunctionComponent<DateRangePickerWrapperProps> = (
  props: DateRangePickerWrapperProps,
) => {
  const { t } = useTranslation();
  const { wrapperClassName } = props;

  return (
    <Box sx={{ position: 'relative' }}>
      <h2 style={visuallyHidden}>{t('ariaHeaderDateRangePicker')}</h2>
      <Box sx={{ position: 'relative', zIndex: 1 }} className={wrapperClassName}>
        <DateRangePicker {...props} />
      </Box>
    </Box>
  );
};

export default DateRangePickerWrapper;
