import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFileValid, isNotEmptyArray } from '@/infrastructure/helper';
import { Box, Button, Typography, styled } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { SingleListImage } from '../SingleListImage';
import { Image } from './index';
import ImageCropper from '@/components/ImageCropper';

interface Props {
  images: Image[];
  onChange: (arr: Image[]) => void;
  isHeaderLogoDefined?: boolean;
}

const AdditionalLogoUpload = ({ images, onChange, isHeaderLogoDefined }: Props) => {
  let fileInput = useRef(null);
  const { t } = useTranslation();

  const [imgForCropper, setImgForCropper] = useState('');
  const cropperProps = { noAspectRatio: true, crop: { width: 100, height: 100 } };

  const [imageCount, setImageCount] = useState(0);

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => {
          setImgForCropper(reader.result as string);
        };
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = fileInput.current.files[0].name.split('.').pop();
    setImgForCropper('');
    const fileName = `logoOption${imageCount}.${ext}`;
    const file = new File([blob], fileName);
    setImageCount(imageCount + 1);
    onChange([...images, { fileName, url: croppedImageUrl, file }]);
  };

  if (isHeaderLogoDefined) {
    return (
      <Typography variant='body1'>
        {t('md.profileDesign.additionalLogosDefaultLogoIsSet')}
      </Typography>
    );
  }

  return (
    <>
      {imgForCropper && (
        <ImageCropper
          image={imgForCropper}
          onCropClick={url => onUploadClick(url)}
          onClose={() => setImgForCropper('')}
          cropOptions={cropperProps?.crop}
          {...cropperProps}
        />
      )}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='body1'>{t('md.profileDesign.additionalLogosDescription')}</Typography>
        <Button
          variant='outlined'
          startIcon={<AddCircleOutline />}
          color='primary'
          onClick={() => fileInput.current.click()}
        >
          {t('addLogo')}
        </Button>
      </Box>

      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
      <ImagesContainer>
        {!images?.length && (
          <Typography variant='body2'>
            {t('md.profileDesign.additionalLogosCurrentSettings')}
          </Typography>
        )}
        {images?.map((file, index) => (
          <SingleListImage
            key={index}
            src={file.url}
            onDeleteClick={() => onChange(images.filter(image => image.fileName !== file.fileName))}
          />
        ))}
      </ImagesContainer>
    </>
  );
};

export default AdditionalLogoUpload;

const ImagesContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '20px',
});
