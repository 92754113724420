import { Box, Typography, styled } from '@mui/material';

const SelectedCountCounterGeneric = ({ onSelectAll, selectAllVisible, text, subText }) => {
  return (
    <Typography variant='h4'>
      <Box>{text}</Box>
      {selectAllVisible && <StyledSelectAll onClick={onSelectAll}>{subText}</StyledSelectAll>}
    </Typography>
  );
};

const StyledSelectAll = styled(Box)({
  fontStyle: 'italic',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export default SelectedCountCounterGeneric;
