import React from 'react';
import advice from '@/views/images/onboarding/advice.png';
import { Box, Typography, styled } from '@mui/material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

interface Props {
  heading: string;
}

export const ScreenLastAdvice = (props: Props) => {
  const { t } = useAppTranslation();

  const text = [
    'onboarding.lastAdviceAboutNFC.pointOne',
    'onboarding.lastAdviceAboutNFC.pointTwo',
    'onboarding.lastAdviceAboutNFC.pointThree',
  ];

  return (
    <Box display='flex' flexDirection='column' m={3}>
      {props.heading && <Typography variant='h1'>{props.heading}</Typography>}
      <Wrapper>
        <Typography variant='h2'>{t('onboarding.importForNFC')}</Typography>
        <Typography variant='body1' component='ul' sx={{ mt: 1 }}>
          {text.map((item, idx) => (
            <li key={idx}>{t(item)}</li>
          ))}
        </Typography>
      </Wrapper>
      <ItalicText>
        <img src={advice} alt='' />
        {t('onboarding.advice')}
      </ItalicText>
    </Box>
  );
};

const Wrapper = styled('div')({
  backgroundColor: '#eff3fd',
  borderRadius: '10px',
  padding: '3rem',
  marginTop: '3rem',
  textAlign: 'start',

  ul: {
    listStyle: 'disc',
    listStylePosition: 'inside',
    marginLeft: '1.5rem',
  },
});

const ItalicText = styled('div')({
  fontSize: '2.2rem',
  fontStyle: 'italic',
  fontWeight: 500,
  lineHeight: '3.3rem',
  letterSpacing: '0em',
  textAlign: 'left',
  maxWidth: '47rem',
  margin: '10rem 0',

  '@media (max-width: 767px)': {
    height: '8.7rem',
    fontSize: '1.5rem',
  },
});
