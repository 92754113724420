import { DateRange } from '@/shared/types/api';
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  getDay,
  isSameDate,
} from '@/components/LTDataRangePicker/utils/utils';
import { DATE_RANGE_OPTIONS } from '@/shared/constants';
import moment from 'moment';

export const getDateRangeButtonText = (dateRange: Required<DateRange>) => {
  const allTimeStartDate = DATE_RANGE_OPTIONS.filter(dr => dr.tTitle === 'allTime')[0].value;
  const { startDate, endDate } = dateRange;
  const today = new Date();
  const startDay = getDay(startDate);
  const isToday = isSameDate(startDate, endDate) && getDay(today) === startDay;
  const isPastWeek =
    isSameDate(today, endDate) && Math.abs(differenceInCalendarDays(startDate, endDate)) === 7;
  const isPastMonth =
    isSameDate(today, endDate) && Math.abs(differenceInCalendarMonths(startDate, endDate)) === 1;
  const isPast90Days =
    isSameDate(today, endDate) && Math.abs(differenceInCalendarDays(startDate, endDate)) === 90;
  const isPastYear =
    isSameDate(today, endDate) && Math.abs(differenceInCalendarYears(startDate, endDate)) === 1;
  const isAllTime = isSameDate(today, endDate) && isSameDate(startDate, new Date(allTimeStartDate));

  if (isToday) {
    return 'datePicker.options.today';
  } else if (isPastWeek) {
    return 'datePicker.options.pastWeek';
  } else if (isPastMonth) {
    return 'datePicker.options.pastMonth';
  } else if (isPast90Days) {
    return 'datePicker.options.past90Days';
  } else if (isPastYear) {
    return 'datePicker.options.pastYear';
  } else if (isAllTime) {
    return 'datePicker.options.allTime';
  } else {
    return moment(startDate).format('L') + ' - ' + moment(endDate).format('L');
  }
};

export const getStartDayDate = (date: Date) => new Date(date.setHours(0, 0, 0, 0));
export const getEndDayDate = (date: Date) => new Date(date.setHours(23, 59, 59, 999));
