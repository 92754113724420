import { UserPlaceholderIcon } from '@/icons/UserPlaceholderIcon';
import { fileToBase64 } from '@/util';
import { Box, Avatar, Theme } from '@mui/material';
import { useState, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ImageCropper from '@/components/ImageCropper';

const imgWrapperStyles = (theme: Theme) => ({
  width: '6.6rem',
  height: '6.6rem',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '15rem',
    height: '15rem',
  },
  svg: { width: '100%', height: '100%' },
});

const plusStyles = (theme: Theme) => ({
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  position: 'absolute',
  bottom: 0,
  right: 0,
  bgcolor: 'primary.main',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '5rem',
    height: '5rem',
  },
});

export const ProfileImage = ({ onChange, value }) => {
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [toCropImageUrl, setToCropImageUrl] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const imgPath = croppedImageUrl || value;

  const clickOnInput = () => {
    inputRef.current?.click();
  };

  const handleChange = async e => {
    const [file] = e.target.files;
    if (!file) return;

    const res = await fileToBase64(file);
    setToCropImageUrl(res);
  };

  const handleCropperClose = () => {
    setToCropImageUrl('');
    inputRef.current.value = '';
  };

  const handleCrop = async (url: string) => {
    const originalFile = inputRef.current.files[0];
    if (!originalFile) return;

    const blob = await fetch(url).then(r => r.blob());
    const file = new File([blob], originalFile.name);

    setCroppedImageUrl(url);
    onChange(file);
    setToCropImageUrl('');
    inputRef.current.value = '';
  };

  return (
    <Box display='flex' justifyContent='center' mb={2}>
      <Box onClick={clickOnInput} position='relative'>
        <Box sx={imgWrapperStyles}>
          {imgPath ? (
            <Avatar color='primary' sx={{ width: '100%', height: '100%' }} src={imgPath} />
          ) : (
            <UserPlaceholderIcon />
          )}
        </Box>
        <Box sx={plusStyles}>
          {imgPath ? (
            <EditIcon sx={{ width: '65%', height: '65%' }} />
          ) : (
            <AddIcon sx={{ width: '65%', height: '65%' }} />
          )}
        </Box>
      </Box>
      {toCropImageUrl && (
        <ImageCropper
          image={toCropImageUrl}
          onCropClick={handleCrop}
          onClose={handleCropperClose}
          circularCrop
        />
      )}
      <input ref={inputRef} onChange={handleChange} style={{ display: 'none' }} type='file' />
    </Box>
  );
};
