import React, { useEffect } from 'react';
import ContentWrapper from '../ContentWrapper';
import { Box, CardContent } from '@mui/material';
import { ColorPicker } from '@/components';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useConfig } from '../../useConfig';
import { HandlersType } from '../..';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import TooltipInfo from '@/components/TooltipInfo';

const WALLET_COLORS = [
  { color: 'backgroundColor', tooltip: false },
  { color: 'foregroundColor', tooltip: true },
  { color: 'labelColor', tooltip: true },
] as const;

const WalletContentRenderer = ({
  title,
  setHandlers,
}: {
  title: string;
  setHandlers: Setter<HandlersType>;
}) => {
  const { wallet, handleSave, handleReset, saveIsLoading, resetIsLoading } = useConfig();

  const { withUnsavedSetter } = useUnsavedStatusSetter();
  const { t } = useAppTranslation();

  useEffect(
    () => setHandlers({ handleSave, handleReset, saveIsLoading, resetIsLoading }),
    [handleReset, handleSave, resetIsLoading, saveIsLoading, setHandlers],
  );

  return (
    <ContentWrapper title={title}>
      {WALLET_COLORS.map(({ color, tooltip }, index) => (
        <ColorRow
          title={t(`sharingDesign.wallet.colors.${color}.title`)}
          tooltip={tooltip && t(`sharingDesign.wallet.colors.${color}.tooltip`)}
          value={wallet.colors?.[color]}
          onChange={withUnsavedSetter(val => wallet.setter(prev => ({ ...prev, [color]: val })))}
          key={index}
        />
      ))}
    </ContentWrapper>
  );
};

export default WalletContentRenderer;

const ColorRow = ({
  value,
  title,
  onChange,
  tooltip,
}: {
  value: string;
  title: string;
  onChange: (value: string) => void;
  tooltip?: string;
}) => {
  return (
    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box display='flex' alignItems='center'>
        <Box sx={{ mr: 1, typography: 'h3' }}>{title}</Box>
        {tooltip && <TooltipInfo text={tooltip} />}
      </Box>
      <ColorPicker value={value} onChange={onChange} ariaLabel={`${title} - ${value}`} />
    </CardContent>
  );
};
