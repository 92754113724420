import { DateRange } from '@/shared/types/api';
import moment from 'moment';

export const identity = <T>(x: T) => x;

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) =>
    array.slice(i * size, i * size + size),
  );

export const getDaysInMonth = (date: Date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days: Date[] = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }: DateRange, day: Date) =>
  (startDate && isSameDay(day, startDate)) as boolean;

export const isEndOfRange = ({ endDate }: DateRange, day: Date) =>
  (endDate && isSameDay(day, endDate)) as boolean;

export const inDateRange = ({ startDate, endDate }: DateRange, day: Date) =>
  (startDate &&
    endDate &&
    (isWithinInterval(day, { start: startDate, end: endDate }) ||
      isSameDay(day, startDate) ||
      isSameDay(day, endDate))) as boolean;

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

export const addDays = (date: Date, daysToAdd: number) =>
  moment(date).add(daysToAdd, 'days').toDate();
export const addWeeks = (date: Date, weeksToAdd: number) =>
  moment(date).add(weeksToAdd, 'weeks').toDate();
export const addMonths = (date: Date, monthsToAdd: number) =>
  moment(date).add(monthsToAdd, 'M').toDate();
export const addYears = (date: Date, yearsToAdd: number) =>
  moment(date).add(yearsToAdd, 'y').toDate();

export const startOfWeek = (date: Date) => moment(date).startOf('week').toDate();
export const startOfMonth = (date: Date) => moment(date).startOf('month').toDate();
export const startOfYear = (date: Date) => moment(date).startOf('year').toDate();
export const endOfWeek = (date: Date) => moment(date).endOf('week').toDate();
export const endOfMonth = (date: Date) => moment(date).endOf('month').toDate();
export const endOfYear = (date: Date) => moment(date).endOf('year').toDate();

export const isAfter = (date1: Date, date2: Date) => moment(date1).isAfter(date2);

export const isBefore = (date1: Date, date2: Date) => moment(date1).isBefore(date2);

export const isSameDay = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'day');

export const isSameWeek = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'week');

export const isSameMonth = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'month');

export const isSameYear = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'year');

export const isSameDate = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'date');

export const isToday = (date: Date) => moment(date).isSame(new Date(), 'day');

export const isWithinInterval = (date: Date, dateInterval: { start: Date; end: Date }) =>
  moment(date).isBetween(dateInterval.start, dateInterval.end, 'days', '[]');

/**
 * @summary — Return the latest of the given dates.
 * @description — Return the latest of the given dates.
 * @typeParam — The Date type, the function operates on.
 * @param dates — The dates to compare
 * @returns — The latest of the dates
 */
export const max = (dates: Date[]) => {
  let latest = dates[0];
  dates.forEach(date => {
    if (moment(date).isBefore(latest)) latest = date;
  });
  return latest;
};

/**
 * @summary — Return the latest of the given dates.
 * @description — Return the latest of the given dates.
 * @typeParam — The Date type, the function operates on.
 * @param dates — The dates to compare
 * @returns — The latest of the dates
 */
export const min = (dates: Date[]) => {
  let latest = dates[0];
  dates.forEach(date => {
    if (moment(date).isAfter(latest)) latest = date;
  });
  return latest;
};

/**
 * @description — Get the day of the given date in month index (1-31).
 * @param date — The given date
 * @returns number - respective day in month index
 */
export const getDay = (date: Date) => moment(date).date();

/**
 * @description — Get the month of the given date.
 * @param date — The given date
 * @returns number - Givin date resp to month 0-index (0-11)
 */
export const getMonth = (date: Date) => moment(date).month();

/**
 * @description — Get the year of the given date.
 * @param date — The given date
 * @returns number - Givin date resp year
 */
export const getYear = (date: Date) => moment(date).year();

/**
 * @description — Set the month to the given date.
 * @param date — The date to be changed
 * @param month — The month of the new date
 * @returns — The new date with the month set
 */
export const setMonth = (date: Date, month: number) => moment(date).set('month', month).toDate();

/**
 * @description — Set the year to the given date.
 * @param date — The date to be changed
 * @param month — The year of the new date
 * @returns — The new date with the year set
 */
export const setYear = (date: Date, year: number) => moment(date).set('year', year).toDate();

/**
 * @description — Get the difference of days between two dates
 * @param date1 — First date
 * @param date2 — Second date
 * @returns — Number of days between two dates
 */
export const differenceInCalendarDays = (date1: Date, date2: Date) =>
  moment(date1).diff(date2, 'days');

/**
 * @description — Get the difference of months between two dates
 * @param date1 — First date
 * @param date2 — Second date
 * @returns — Number of months between two dates
 */
export const differenceInCalendarMonths = (date1: Date, date2: Date) =>
  moment(date1).diff(date2, 'months');

/**
 * @description — Get the difference of years between two dates
 * @param date1 — First date
 * @param date2 — Second date
 * @returns — Number of years between two dates
 */
export const differenceInCalendarYears = (date1: Date, date2: Date) =>
  moment(date1).diff(date2, 'years');
