import { ApiFile, FileTemplate } from '@/shared/types/api';

import { LtDialog } from '@/components';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFileTemplates } from '@/infrastructure/apis/fileTemplates';
import { Box, Button, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { FileBox } from '@/components/Files/FileBox';
import AddNewFilePopup from '@/components/Profile/components/popups/add-new-file-popup';
import { UploadfileProps } from '../../common/files';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';

type Props = {
  files: ApiFile[];
  onChange: (files: ApiFile[]) => void;
};

export const Files = ({ files, onChange }: Props) => {
  const [addFileDialogOpened, setAddFileDialogOpened] = useState(false);
  const [fileIdxToDelete, setFileIdxToDelete] = useState<number | null>(null);

  const { profileDesign } = useProfileDesign();

  const [fileTemplates, setFileTemplates] = useState<FileTemplate[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fileTemplates = await getFileTemplates(getAccessTokenSilently);
        setFileTemplates(fileTemplates);
      } catch {}
    };
    fetchData();
  }, [getAccessTokenSilently]);

  const handleAdd = (config: {
    fileType: 'newFile' | 'templateFile';
    newFileDetails?: UploadfileProps;
  }) => {
    setAddFileDialogOpened(false);

    const newFiles: (ApiFile & { fileToUpload?: File })[] = [
      ...files,
      {
        link: config.newFileDetails.link,
        fileName: config.newFileDetails.fileName,
        embed: config.newFileDetails.embed,
        canDisplayOnProfile: true,
        order: null,
        pageCount: config.newFileDetails.pageCount,
        fileTemplateId: config.fileType === 'newFile' ? null : config.newFileDetails.fileTemplateId,
        fileToUpload: config.fileType === 'newFile' && config.newFileDetails?.uploadedFile,
      },
    ];

    onChange(newFiles);
  };

  const handleFileDelete = () => {
    if (typeof fileIdxToDelete !== 'number') return;

    const newFiles = [...files];
    newFiles.splice(fileIdxToDelete, 1);
    onChange(newFiles);

    setFileIdxToDelete(null);
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2}>
        <Button
          onClick={() => setAddFileDialogOpened(true)}
          startIcon={<AddCircleOutline />}
          aria-label={t('ariaAddFile')}
          sx={{ ml: ' auto' }}
        >
          {t('add')}
        </Button>
        {files.map((file, index) => (
          <FileBox
            key={`file-${index}`}
            file={file}
            onDelete={() => setFileIdxToDelete(index)}
            iconBgColor={profileDesign.fileBoxColor}
          />
        ))}
      </Box>
      {addFileDialogOpened && (
        <AddNewFilePopup
          isCompanydataSettings
          handleCompanySettingsUpdate={handleAdd}
          onCloseClick={() => setAddFileDialogOpened(false)}
          templates={fileTemplates}
          showTemplatesSelector
        />
      )}
      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(typeof fileIdxToDelete === 'number')}
        onClose={() => setFileIdxToDelete(null)}
        onCancel={() => setFileIdxToDelete(null)}
        onDelete={handleFileDelete}
      >
        <Typography>{t('deleteMessageFile')}</Typography>
      </LtDialog>
    </>
  );
};
