import React, { Dispatch, SetStateAction, useState } from 'react';
import RenderUnits from './renderUnits';
import { Unit } from '@/shared/types/api/unit.type';
import { Employee } from '@/shared/types/api/employee.type';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { assignUnits } from '@/infrastructure/apis/md/units';
import { fetchUserData } from '@/application/actions/account';
import { useDispatch } from 'react-redux';
import { Button, Popover } from '@mui/material';
import { ArrowDropDown, SupervisedUserCircleOutlined } from '@mui/icons-material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { LtDialog } from '@/components';

interface Props {
  units: Array<Unit>;
  selectedIds: readonly string[];
  localEmployees: Array<Employee>;
  setLocalEmployees: Dispatch<SetStateAction<Employee[]>>;
  onSaveSuccess?: () => void;
}

const UnitAssign = (props: Props) => {
  const { user, logout } = useAuth0();
  const themeAdmin = user['https://lemontaps.com/claim/roles'].includes('theme_admin');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const [showPopup, setShowPopup] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const unassignSelected = async () => {
    toast.loading(t('mdLeads.removingFromUnit'), { id: 'removingunits' });
    const unitsResponse = await assignUnits(getAccessTokenSilently, props.selectedIds, null, []);
    if (props.selectedIds.includes(user.sub))
      dispatch(fetchUserData(getAccessTokenSilently, logout));
    if (unitsResponse.data.isSuccess === false) {
      toast.error(t('mdLeads.errorReadUnits', { id: 'removingunits' }));
      return;
    }
    toast.success(t('mdLeads.successRemovingFromUnit'), { id: 'removingunits' });
    props.setLocalEmployees(props.localEmployees.filter(x => !props.selectedIds.includes(x.id)));
    setShowPopup(false);
  };

  if (!themeAdmin && props.units.length === 1) {
    return (
      <>
        <Button variant='outlined' color='error' onClick={() => setShowPopup(true)}>
          {t('unitAssign')}
        </Button>

        {showPopup && (
          <LtDialog
            open
            onClose={() => setShowPopup(false)}
            onCancel={() => setShowPopup(false)}
            onDelete={unassignSelected}
          >
            {t('mdLeads.confirmRemoveUnit', { count: props.selectedIds.length })}
          </LtDialog>
        )}
      </>
    );
  }

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(e.currentTarget);
        }}
        endIcon={<ArrowDropDown />}
        startIcon={<SupervisedUserCircleOutlined />}
        aria-haspopup='dialog'
        aria-label={t('ariaUnitAssignSelected')}
      >
        {t('unitAssign')}
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { width: '45rem', p: '2rem' },
        }}
      >
        <RenderUnits
          selectedIds={props.selectedIds}
          units={props.units}
          setLocalEmployees={props.setLocalEmployees}
          localEmployees={props.localEmployees}
          onSaveStart={() => setAnchorEl(null)}
          onSaveSuccess={props.onSaveSuccess}
        />
      </Popover>
    </>
  );
};

export default UnitAssign;
