import ThemeOverride from '@/components/ThemeOverride';
import { StaticProfileConfig } from '@/shared/types/api';
import { Box, Typography, styled } from '@mui/material';

interface Props {
  config: StaticProfileConfig['header']['bioContent'];
  isEdit?: boolean;
}

export default function Bio({ config, isEdit }: Props) {
  return (
    <Wrapper>
      <ThemeOverride
        overrides={{
          typography: {
            h1: {
              marginBottom: '.75rem',
            },
            body1: {
              lineHeight: 1.25,
            },
          },
        }}
      >
        {config.map(line => (
          <Typography
            component={line.variant === 'h1' ? (isEdit ? 'h2' : 'h1') : 'p'}
            variant={line.variant}
            color={line.paletteColor}
            mb={0.25}
            whiteSpace='pre-line'
          >
            {line.text}
          </Typography>
        ))}
      </ThemeOverride>
    </Wrapper>
  );
}

const Wrapper = styled(Box)(({ theme }) => ({
  padding: '.25rem 1rem',
  borderLeft: '2px dashed',
  borderLeftColor: theme.palette.text.secondary,
}));
