// use when at least one of a subset of object properties is required
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

// use when exactly one of a subset of object properties is required
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

// use when not more than one from a subset of object properties is required
export type RequireAtMostOne<T, Keys extends keyof T = keyof T> =
  | RequireOnlyOne<T, Keys>
  | (Required<Pick<T, Exclude<keyof T, Keys>>> &
      Partial<Record<Exclude<Keys, Exclude<keyof T, Keys>>, undefined>>);

// use when either every or none of a subset of object properties are required
export type RequireEveryOrNone<T, Keys extends keyof T = keyof T> =
  | (Pick<T, Exclude<keyof T, Keys>> & Partial<Record<Keys, undefined>>)
  | (Pick<T, Exclude<keyof T, Keys>> & Required<Pick<T, Keys>>);

// use when you need the types of the attributes of an object
export type ObjVal<T, K extends keyof T = keyof T> = T[K];

// src: https://stackoverflow.com/questions/72204112/remove-null-attributes-from-an-object-in-typescript
type ExpandRecursively<T> = T extends object
  ? T extends infer O
    ? { [K in keyof O]: ExpandRecursively<O[K]> }
    : never
  : T;

// remove all U values from object type T
export type RemoveNull<T> = ExpandRecursively<{
  [K in keyof T]: Exclude<RemoveNull<T[K]>, null>;
}>;

// for usage in array filtering function to filter out undefined and null values
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

// Mark certain keys as non null and non undefined
export type EnsureNonNullKeys<T, U extends keyof T> = {
  [K in keyof T]-?: K extends U ? NonNullable<T[K]> : T[K];
};
// for usage in array filtering function to filter out objects where certain keys are undefined or null
export const nonNullableObject = <T extends object, U extends keyof T = keyof T>(
  val: Partial<T>,
  nonNullableKeys?: U[],
): val is EnsureNonNullKeys<T, U> => {
  const keysToCheck = nonNullableKeys || (Object.keys(val) as (keyof T)[]);
  return Object.entries(val)
    .filter(([key]) => keysToCheck.includes(key as keyof T))
    .every(([_, v]) => v !== null && v !== undefined);
};

// all paths of an object
export type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`;
    }[keyof T]
  : never;

// leaf paths of an object
export type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ''
        : `.${Leaves<T[K]>}`}`;
    }[keyof T]
  : never;

// paths with depth max_depth-1
export type LeaveParents<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${LeaveParents<T[K][keyof T[K]]> extends never
        ? ''
        : `.${LeaveParents<T[K]>}`}`;
    }[keyof T]
  : never;

export const stringBooleanFilter = (val: string | null | undefined): val is string => !!val;

export type DeepPartial<T, D extends number = 5> = [D] extends [never]
  ? T
  : T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P], Prev[D]>;
    }
  : T;
// Helper type to decrement depth
type Prev = [never, 0, 1, 2, 3, 4, 5];
