import { useState } from 'react';
import RedeemDialog from './RedeemDialog';
import { Box, Button, CardMedia, Typography, styled } from '@mui/material';
import { Euro } from '@mui/icons-material';

type Props = {
  imageSrc: string;
  price: number;
  title: string;
  subTitle?: string;
  buttonText: string;
  balance: number;
};

const RedeemCard = (props: Props) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>();
  const [showFailedDialog, setShowFailedDialog] = useState<boolean>();

  const redeemClickHandler = () => {
    if (props.balance < props.price) {
      setShowFailedDialog(true);
    } else {
      setShowSuccessDialog(true);
    }
  };

  return (
    <>
      <Wrapper>
        <CardMedia
          component='img'
          alt='credit-image'
          src={props.imageSrc}
          sx={{ maxWidth: '70%' }}
        />
        <Box display='flex' sx={{ scale: '1.3', py: 4 }}>
          <Typography variant='h1'>{props.price}</Typography>
          <Euro />
        </Box>
        <Box>
          <Typography variant='body2'>{props.title}</Typography>
          {props?.subTitle && <Typography variant='caption'>{props.subTitle}</Typography>}
        </Box>
        <Button size='large' onClick={redeemClickHandler} fullWidth>
          {props.buttonText}
        </Button>
      </Wrapper>
      {showSuccessDialog && <RedeemDialog success onClose={() => setShowSuccessDialog(false)} />}
      {showFailedDialog && <RedeemDialog onClose={() => setShowFailedDialog(false)} />}
    </>
  );
};

export default RedeemCard;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '50% 16% 10% 1fr',
  justifyItems: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  padding: '2rem',
  width: 'fit-content',
  maxWidth: '32rem',
  height: 'auto',
  backgroundColor: theme.palette.background.box,
  borderRadius: '1rem',
  marginRight: '2rem',
  flex: 'none',
}));
