// import { AnchorText } from '../AnchorText';
import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const STRATEGY_MAP = {
  waad: 'OAuth (recommended)',
  okta: 'OIDC',
  samlp: 'SAML',
};

export const BeforeYouBeginStep = ({ strategies, setSelectedStrategy, selectedStrategy }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextsRenderer
        title={t('idp.sso.steps.beforeYouBegin.title')}
        texts={[
          t('idp.sso.steps.beforeYouBegin.description'),
          t('idp.sso.steps.beforeYouBegin.description2'),
          t('idp.sso.steps.beforeYouBegin.description3'),
        ]}
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>{t('idp.sso.steps.beforeYouBegin.selectStrategy')}</InputLabel>
        <Select
          value={selectedStrategy}
          label={t('idp.sso.steps.beforeYouBegin.selectStrategy')}
          onChange={e => setSelectedStrategy(e.target.value)}
        >
          {strategies.map(x => (
            <MenuItem key={x} value={x}>
              {STRATEGY_MAP[x] || x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <AnchorText href='#' text={t('idp.sso.learnMoreAboutSaml')} mt={2} /> */}
    </>
  );
};
