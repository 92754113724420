import { dispatchTypes } from './index';
import { onboardingOperations } from '../../infrastructure/apis/onboarding';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import i18n from '../../config/i18n';
import { OnboardingDataProps1, OnboardingDataProps2 } from '@/shared/types/api';
import { useNotificationsStore } from '@/zustand/notificationsStore';

export const operations =
  (
    onboardingData: OnboardingDataProps1 | OnboardingDataProps2,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.ONBOARDING.SHOWLOADER });

    try {
      let response = await onboardingOperations(onboardingData, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.COMMON.UPDATEACCOUNT,
          account: onboardingData,
        });

        dispatch({
          type: dispatchTypes.ONBOARDING.ONBOARDINGOPERATIONS.SUCCESS,
          apiResponse: result,
        });
      } else {
        dispatch({
          type: dispatchTypes.ONBOARDING.ONBOARDINGOPERATIONS.ERROR,
          apiResponse: result,
        });
        useNotificationsStore.getState().notify.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({
        type: dispatchTypes.ONBOARDING.ERROR,
        apiResponse: error,
      });
      useNotificationsStore.getState().notify.error(i18n.t('error.general'));
    }
  };
