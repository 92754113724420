import { routePaths } from '../../../infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageContainer } from '@/components';
import lemonBackground from '@/views/images/background.png';
import lemonTapIcon from '@/views/images/lemontap-icon.png';
import { Box, Button, CardMedia, ThemeProvider, Typography } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

const PageNotFound = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { theme: muiTheme } = useMuiTheme();
  return (
    <ThemeProvider theme={muiTheme}>
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: `url(${lemonBackground}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <PageContainer maxWidth={'s'}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            height='60vh'
            gap='2rem'
          >
            <CardMedia component={'img'} src={lemonTapIcon} sx={{ width: '4rem' }} />
            <Typography variant='h1' color={'primary.contrastText'}>
              {t('pageNotFound')}
            </Typography>
            <Typography variant='body1' color={'primary.contrastText'}>
              {t('weareSorry')}
            </Typography>
            <Button
              variant='contained'
              color='primaryButton'
              size='large'
              onClick={() => history.push(routePaths.HOME)}
              sx={{ mt: 2 }}
            >
              {t('backHome')}
            </Button>
          </Box>
        </PageContainer>
      </Box>
    </ThemeProvider>
  );
};

export default PageNotFound;
