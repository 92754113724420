import { REWARDS_KEYS } from '../../../shared/constants';
import voucher10Img from '../../images/referral/voucher-10.png';
import voucher30Img from '../../images/referral/voucher-30.png';
import voucher40Img from '../../images/referral/voucher-40.png';
import voucher50Img from '../../images/referral/voucher-50.png';
import licenceEssentialsImg from '../../images/referral/licence-essentials.png';

export const CREDITS_REWARDS_CLIENT = {
  [REWARDS_KEYS.VOUCHER10]: {
    image: voucher10Img,
    text: {
      en: { title: 'Lemontaps Basics', subtitle: null },
      de: { title: 'Lemontaps Standardprodukte', subtitle: null },
    },
  },
  [REWARDS_KEYS.LICENCE_ESSENTIALS]: {
    image: licenceEssentialsImg,
    text: {
      en: { title: '1 year abonement of Lemontaps Teams', subtitle: '(1 Licence)' },
      de: { title: '1 Jahr Lemontaps Teams Abo', subtitle: '(1 Lizenz)' },
    },
  },
  [REWARDS_KEYS.VOUCHER30]: {
    image: voucher30Img,
    text: {
      en: { title: 'Customized wood or plastic NFC-card', subtitle: '(Front)' },
      de: { title: 'Individuelle Holz- oder Kunststoffkarte', subtitle: '(Vorderseite)' },
    },
  },
  [REWARDS_KEYS.VOUCHER40]: {
    image: voucher40Img,
    text: {
      en: { title: 'Customized wood or plastic NFC-card', subtitle: '(Front and back)' },
      de: { title: 'Individuelle Holz- oder Kunststoffkarte', subtitle: '(Vorder-und Rückseite)' },
    },
  },
  [REWARDS_KEYS.VOUCHER50]: {
    image: voucher50Img,
    text: {
      en: { title: 'Customized metal NFC-card', subtitle: null },
      de: { title: 'Individuelle Metallkarte', subtitle: null },
    },
  },
} as const;
