import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useState } from 'react';
import { Box, IconButton, ThemeProvider, Typography, alpha } from '@mui/material';
import { InfoOutlined as InfoIcon, HelpOutlineOutlined as HelpIcon } from '@mui/icons-material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { useTranslation } from 'react-i18next';

type Props = {
  text: TooltipProps['title'];
  children?: JSX.Element;
  icon?: { size?: number; color?: string; help?: boolean };
  onHover?: boolean;
  onHoverCursor?: string;
  iconAriaLabel?: string;
} & Partial<TooltipProps>;

const TooltipInfo = (props: Props) => {
  const { t } = useTranslation();
  const { theme: muiTheme } = useMuiTheme();
  const { text, children, onHover, onHoverCursor, icon, sx, ...tooltipProps } = props;
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={muiTheme}>
      {onHover ? (
        <>
          <Tooltip
            title={
              <Typography variant='body2' color='white'>
                {text}
              </Typography>
            }
            enterDelay={100}
            PopperProps={{
              sx,
            }}
            {...tooltipProps}
          >
            <Box sx={{ cursor: onHoverCursor }}>
              {children ? (
                children
              ) : icon?.help ? (
                <IconButton aria-label={props.iconAriaLabel || t('ariaHelpTooltip')}>
                  <HelpIcon
                    sx={theme => ({
                      color: icon?.color ?? theme.palette.secondary.main,
                      fontSize: icon?.size ?? 17,
                    })}
                  />
                </IconButton>
              ) : (
                <IconButton>
                  <InfoIcon
                    sx={theme => ({
                      fontSize: icon?.size ?? 17,
                      color: icon?.color ?? theme.palette.secondary.main,
                    })}
                    aria-label={props.iconAriaLabel || t('ariaInfoTooltip')}
                  />
                </IconButton>
              )}
            </Box>
          </Tooltip>
        </>
      ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              sx,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Typography variant='body2' color='white'>
                {text}
              </Typography>
            }
            {...tooltipProps}
          >
            {children ? (
              children
            ) : (
              <IconButton
                onClick={handleTooltipOpen}
                onBlur={handleTooltipClose}
                aria-label={
                  props.iconAriaLabel || (icon?.help ? t('ariaHelpTooltip') : t('ariaInfoTooltip'))
                }
              >
                {icon?.help ? (
                  <HelpIcon
                    sx={theme => ({
                      color: icon?.color ?? alpha(theme.palette.secondary.main, 0.5),
                      fontSize: icon?.size ?? 17,
                    })}
                  />
                ) : (
                  <InfoIcon
                    sx={theme => ({
                      fontSize: icon?.size ?? 17,
                      color: icon?.color ?? alpha(theme.palette.secondary.main, 0.5),
                    })}
                  />
                )}
              </IconButton>
            )}
          </Tooltip>
        </ClickAwayListener>
      )}
    </ThemeProvider>
  );
};

export default TooltipInfo;
