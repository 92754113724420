import { useAppSelector } from '@/application/hooks';
import { Account, ThemeIdentityProvider } from '@/shared/types/api';
import { normalizeThemeName } from '@/shared/util';
import { InformativeInput } from '../../InformativeInput';
import { useTranslation } from 'react-i18next';

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0DomainId = process.env.REACT_APP_AUTH0_TENANT_NAME;
const baseSingleSignOnUrl = `https://${auth0Domain}/login/callback`;

export const useConnectionSettings = (themeIdp?: ThemeIdentityProvider, strategy?: string) => {
  const { theme } = useAppSelector<Account>(state => state.account) || {};
  const { t } = useTranslation();
  const connectionName = `${normalizeThemeName(theme.name || '')}-${themeIdp?.name}-${strategy}`;
  const entityId = `urn:auth0:${auth0DomainId}:${connectionName}`;
  const samlSingleSignOnUrl = `${baseSingleSignOnUrl}?connection=${connectionName}`;

  const samlFields = [
    <InformativeInput value={entityId} label={t('idp.sso.entityId')} />,
    <InformativeInput value={samlSingleSignOnUrl} label={t('idp.sso.singleSignOnUrlForCopy')} />,
  ];

  const oktaFields = [
    <InformativeInput value={baseSingleSignOnUrl} label={t('idp.sso.singleSignOnUrlForCopy')} />,
  ];

  const waadFields = [
    <InformativeInput value={baseSingleSignOnUrl} label={t('idp.sso.singleSignOnUrlForCopy')} />,
  ];

  const dict = {
    samlp: samlFields,
    okta: oktaFields,
    waad: waadFields,
  };

  return {
    connectionName,
    copyFields: dict[strategy],
  };
};
