import { MarkdownRenderer } from '@/components';
import LtVideo from '@/components/LtVideo';
import { FAQItem as FAQItemType } from '@/shared/types/global';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

const FAQItem = ({ item }: { item: FAQItemType }) => {
  return (
    <Box sx={{ p: 1 }}>
      <Accordion sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          <Typography variant='body1'>{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {item.video && item.video.position === 'pre' && (
            <LtVideo embedUrl={item.video.embedUrl} />
          )}
          <Typography variant='body2'>
            <MarkdownRenderer content={item.answer} containerSx={{ color: 'text.secondary' }} />
          </Typography>
          {item.video && item.video.position === 'post' && (
            <LtVideo embedUrl={item.video.embedUrl} />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQItem;
