import { UrlsBox as UrlsBoxConfig, UrlsBoxType } from '@/shared/types/api';
import ContentBoxUi from '../../components/ContentBoxUI';
import { Stack } from '@mui/material';
import UrlButtonItem from './components/UrlButtonItem';
import VideoItem from './components/VideoItem';
import NoteIcon from '@mui/icons-material/Note';
import { ProfileEditProps, TrackHandlers } from '../../types';
import AddLinkButton from './components/AddLinkButton';
import AddFilePopup from './components/AddFilePopup';
import LinksEdit from './components/LinksEdit';
import FilesEdit from './components/FilesEdit';

// TODO: Consolidate types
interface Props<T extends UrlsBoxType> {
  config: UrlsBoxConfig<T>;
  editProps?: T extends 'links'
    ? ProfileEditProps['linksBoxEditProps']
    : T extends 'files'
    ? ProfileEditProps['filesBoxEditProps']
    : never;
  trackHandlers?: Pick<TrackHandlers, 'linkClickTrackHandler' | 'fileClickTrackHandler'>;
}

export default function UrlsBox<T extends UrlsBoxType>({
  config,
  editProps,
  trackHandlers,
}: Props<T>) {
  const isEdit = !!editProps;
  return (
    <ContentBoxUi
      title={config.label}
      actionButton={
        editProps ? (
          config.type === 'links' ? (
            // TODO: get rid of typecast
            <AddLinkButton {...(editProps as ProfileEditProps['linksBoxEditProps'])} />
          ) : config.type === 'files' ? (
            <AddFilePopup {...(editProps as ProfileEditProps['filesBoxEditProps'])} />
          ) : undefined
        ) : undefined
      }
      isEdit={isEdit}
    >
      {editProps && editProps.editRightAllowed ? (
        config.type === 'links' ? (
          <LinksEdit
            links={(editProps as ProfileEditProps['linksBoxEditProps']).links}
            standardLinksAfter={
              (editProps as ProfileEditProps['linksBoxEditProps']).standardLinksAfter
            }
            standardLinksBefore={
              (editProps as ProfileEditProps['linksBoxEditProps']).standardLinksBefore
            }
            genericWebsiteColor={
              (editProps as ProfileEditProps['linksBoxEditProps']).genericWebsiteColor
            }
            defaultLinkId={(editProps as ProfileEditProps['linksBoxEditProps']).defaultLinkId}
            onReorder={(editProps as ProfileEditProps['linksBoxEditProps']).onReorder}
            onToggleDisplay={(editProps as ProfileEditProps['linksBoxEditProps']).onToggleDisplay}
            onToggleDefault={(editProps as ProfileEditProps['linksBoxEditProps']).onToggleDefault}
            onEdit={(editProps as ProfileEditProps['linksBoxEditProps']).onEdit}
            onDelete={(editProps as ProfileEditProps['linksBoxEditProps']).onDelete}
          />
        ) : (
          <FilesEdit
            files={(editProps as ProfileEditProps['filesBoxEditProps']).files}
            onReorder={(editProps as ProfileEditProps['filesBoxEditProps']).onReorder}
            onDelete={(editProps as ProfileEditProps['filesBoxEditProps']).onDelete}
            onToggleDisplay={(editProps as ProfileEditProps['filesBoxEditProps']).onToggleDisplay}
            standardFilesBefore={
              (editProps as ProfileEditProps['filesBoxEditProps']).standardFilesBefore
            }
            standardFilesAfter={
              (editProps as ProfileEditProps['filesBoxEditProps']).standardFilesAfter
            }
            genericFileBoxColor={
              (editProps as ProfileEditProps['filesBoxEditProps']).genericFileBoxColor
            }
          />
        )
      ) : (
        <Stack direction='column' spacing={1.6}>
          {config.items.map(item =>
            item.renderAs === 'link' ? (
              <UrlButtonItem
                {...item}
                onClick={
                  config.type === 'links'
                    ? () => trackHandlers?.linkClickTrackHandler(item.meta)
                    : config.type === 'files'
                    ? () => trackHandlers?.fileClickTrackHandler(item.meta)
                    : undefined
                }
                icon={config.type === 'files' ? <NoteIcon /> : undefined}
              />
            ) : item.renderAs === 'video' ? (
              <VideoItem {...item} />
            ) : null,
          )}
        </Stack>
      )}
    </ContentBoxUi>
  );
}
