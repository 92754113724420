import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routePaths } from '@/infrastructure/constants';
import { ReactComponent as LemontapsLogo } from '@/views/images/lemontaps-logo.svg';
import useWindowSize from '@/infrastructure/hooks/useWindowSize';
import { styled } from '@mui/system';
import { Card } from '@mui/material';

// TODO: profile page optimizations

interface Props {
  onHeightChange?(newHeight: number): void;
  username: string;
}

const TryForFreeBanner = (props: Props) => {
  const { t } = useTranslation();
  const signupWithParams = `${routePaths.REGISTRATION.SIGNUP}/?lt_ref=${props.username}&lt_medium=profilerefer`;

  const [tffBannerHeight, setTffBannerHeight] = useState(0); // tff = try for free
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setTffBannerHeight?.(containerRef.current.clientHeight);
    }
  }, [containerRef, setTffBannerHeight]);
  useWindowSize(
    useCallback(
      () => setTffBannerHeight?.(containerRef?.current?.clientHeight),
      [setTffBannerHeight],
    ),
  );

  return (
    <>
      <Container>
        <LemontapsLogo />
        <Link target='_blank' to={signupWithParams} tabIndex={0}>
          {t('tryForFree')} &rarr;
        </Link>
      </Container>
      {/* to have the banner image shown _below_ the tff banner: */}
      <div style={{ height: tffBannerHeight }}></div>{' '}
    </>
  );
};

export default TryForFreeBanner;

const Container = styled(Card)({
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 30,
  backgroundColor: '#ffffff',
  padding: '1rem 2rem',

  '& a': {
    color: '#55595e',
    background: '#ffffff',
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '1rem 2rem',
    border: '1px solid black',
    borderRadius: '1rem',

    '&:focus-visible': {
      outline: '2px solid black',
      outlineOffset: '2px',
    },
  },
});
