import { Box, Button, CardMedia, Link, Typography } from '@mui/material';
import { VIDEOS } from '../../../../../infrastructure/constants';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { ArrowForward } from '@mui/icons-material';

const VideoSectionExpired = () => {
  const { t, activeLanguage } = useAppTranslation();
  const { src, title } = activeLanguage.startsWith('de') ? VIDEOS.MD.DE : VIDEOS.MD.EN;
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  return (
    <Box py={3}>
      <Box display={'flex'} justifyContent='space-evenly' alignItems={'center'}>
        <Box>
          <CardMedia
            component={'iframe'}
            src={src}
            title={title}
            allowFullScreen
            sx={{ width: '40rem', height: '23rem', maxWidth: '100%', borderRadius: 1 }}
          ></CardMedia>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography variant='h1'>{t('teaser.expired')}</Typography>

          <Box>
            <Button onClick={upgradeAction} size='large'>
              {flag_hidePrices ? t('contactSales') : t('upgradeNow')}
            </Button>
          </Box>
          {upgradeRequestPopup}

          <Link
            target='_blank'
            href={
              activeLanguage.startsWith('de')
                ? 'https://lemontaps.de/pages/preise'
                : 'https://lemontaps.de/en/pages/prices'
            }
            color={'text.primary'}
            sx={{
              display: 'flex',
              ':hover, :visited, :active': {
                color: 'text.primary',
              },
            }}
          >
            {t('teaser.moreInfo')}
            <ArrowForward sx={{ fontSize: 18 }} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSectionExpired;
