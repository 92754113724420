import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

export const CollapseIcon = ({ onClick, sidebarCollapsed }) => {
  const { t } = useAppTranslation();
  return (
    <IconButton
      onClick={onClick}
      aria-hidden
      aria-label={t('navSidebar.collapseButton.aria.label')}
      aria-expanded={!sidebarCollapsed}
      sx={theme => ({
        position: 'absolute',
        right: 0,
        bottom: '2.4rem',
        transform: 'translateX(50%)',
        bgcolor: 'primaryButton.main',
        ':hover': { bgcolor: 'primaryButton.main' },
        width: '3rem',
        height: '3rem',
        color: 'primaryButton.contrastText',
        '&.Mui-focusVisible:focus': {
          outlineColor: `${theme.palette.primaryButton.main} !important`,
        },
      })}
    >
      {sidebarCollapsed ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
};
