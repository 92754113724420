import { Box, Card, CardProps, Divider, Typography, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  title?: string;
  divider?: boolean; // defaults to false
  actionButton?: JSX.Element;
  isEdit: boolean;
} & CardProps;

export default function ContentBoxUi({
  children,
  title,
  divider = false,
  actionButton,
  isEdit,
  ...cardProps
}: PropsWithChildren<Props>) {
  return (
    <Card {...cardProps}>
      {title && (
        <>
          <PaddingContainer
            sx={{ pb: actionButton ? 0.5 : 0.1 }}
            display='flex'
            alignItems='baseline'
            justifyContent='space-between'
          >
            <Typography component={isEdit ? 'h3' : 'h2'} variant='h4'>
              {title}
            </Typography>
            {actionButton}
          </PaddingContainer>
          {divider && <Divider />}
        </>
      )}
      <PaddingContainer>{children}</PaddingContainer>
    </Card>
  );
}

export const PADDING = 1.6;
const PaddingContainer: typeof Box = styled(Box)(({ theme }) => ({
  padding: theme.spacing(PADDING),
}));
