import { ERROR_KEYS } from '@/shared/constants';

export const de = {
  translation: {
    paperCards: 'Papiervisitenkarten',
    profilePicture: 'Profilbild',
    colSortMenu: {
      dialogTitle: 'Tabelle anpassen',
      description: 'Du kannst die Reihenfolge der Spalten ändern und Spalten ein- oder ausblenden.',
      showColumnHeader: 'Spalte anzeigen',
    },
    ariaProfileFile: 'Profildatei',
    ariaProfileLink: 'Profillink',
    ariaSelectStartDay: 'Wähle {{date}} als Start des Zeitraums aus',
    ariaSelectEndDay: 'Wähle {{date}} als Ende des Zeitraums aus',
    ariaSelectMonth: 'Monat auswählen',
    ariaSelectYear: 'Jahr auswählen',
    ariaMoveMonthBack: 'Um einen Monat zurück',
    ariaMoveMonthForward: 'Um einen Monat vor',
    ariaFrom: 'Von',
    ariaTo: 'Nach',
    ariaHeaderDateRangePicker: 'Zeitraum auswählen',
    ariaSelectPredefinedRange: 'Vordefinierten Zeitraum wählen: ',
    ariaHelpTooltip: 'Hilfe',
    ariaInfoTooltip: 'Info',
    ariaGdprConsent: 'Zustimmung zur Datenschutzerklärung',
    ariaAddProfilePicture: 'Profilbild hinzufügen',
    ariaEditProfilePicture: 'Profilbild bearbeiten',
    ariaEditContactDetails: 'Kontaktdaten bearbeiten',
    ariaGoBack: 'Zurück',
    ariaShowAllSharingOptions: 'Alle Optionen zum Teilen des Profils anzeigen',
    ariaAcademySwiperPreviousVideo: 'Zeige vorheriges Academy-Video',
    ariaAcademySwiperNextVideo: 'Zeige nächstes Academy-Video',
    ariaNavHeaderProfile: 'Navigationselemente des Einzelnutzers ein-/ausblenden',
    ariaNavHeaderAdmin: 'Navigationselemente des Admins ein-/ausblenden',
    ariaNavHeaderTeamSettings: 'Navigationselemente der Team-Einstellungen ein-/ausblenden',
    ariaHeaderAdminDropdown: 'Profil-Einstellungsmenü öffnen',
    ariaHeaderHelpDropdown: 'Hilfe-Menü öffnen',
    ariaAddLink: 'Link hinzufügen',
    ariaAddFile: 'Datei hinzufügen',
    onlyPreview: 'Dies ist nur eine Vorschau',
    ariaCollapseTo_one: 'Liste auf ein Element reduzieren',
    ariaCollapseTo_other: 'Liste auf {{count}} Elemente reduzieren',
    ariaExpandFor_one: 'Kontaktdaten erweitern, um ein weiteres Element anzuzeigen',
    ariaExpandFor_other: 'Kontaktdaten erweitern, um {{count}} weitere Elemente anzuzeigen',
    ariaOpenCookieSettings: 'Öffne die Einstellungen für Cookies',
    adminDisabledEditRight: 'Dein Admin erlaubt Dir nicht, hier Bearbeitungen vorzunehmen.',
    ariaDigitalBusinesscardOf: 'Digitale Visitenkarte von',
    ariaLogoImage: 'Unternehmens-Logo',
    ariaProfilePicture: 'Profilbild',
    ariaOpenToEnlarge: 'Du kannst das Bild in einem Dialog öffnen',
    ariaBannerImageOption: 'Bildoption {{n}} für das Profil-Banner',
    ariaBannerImageOptionsLabel:
      'Wähle ein Bild aus den Optionen aus, um es als Profil-Banner anzuzeigen.',
    addAnotherUser: 'Nutzer:in hinzufügen',
    livePreview: 'Live Vorschau',
    sendViaEmail: 'Per E-Mail senden',
    ariaLogoOf: 'Logo von {{name}}',
    teamSettingsSubmenu: 'Teameinstellungen - öffnet das Untermenü „Team-Einstellungen“',
    default: 'Standard',
    template: 'Vorlage',
    embed: 'Einbetten',
    bulkEdit: 'Bearbeiten',
    bulkEditProfiles: 'Bearbeiten',
    copied: 'Kopiert!',
    back: 'Zurück',
    disconnect: 'Trennen',
    done: 'Fertig',
    internalContactPerson: 'Interne:r Ansprechpartner:in',
    generalDesign: 'Allgemeines Design',
    defaultContent: 'Standardinhalt',
    contentTemplate: 'Inhaltsvorlagen',
    editBio: 'Bio bearbeiten',
    fileupload: 'Dateiupload',
    value: 'Wert',
    selectField: 'Feld auswählen',
    override: 'Überschreiben',
    card: 'NFC-Karte',
    addFileDialog: {
      title: 'Titel',
      videoUrl: 'Video-Link',
      modeFile: 'Datei hochladen',
      modeVideoLink: 'Video einbetten',
      embedVideo: {
        label: 'Video einbetten',
        tooltip:
          'Durch das Einbetten wird das Video im Profil angezeigt und kann dort direkt abgespielt werden.',
        placeholder: 'Füge die Video-URL ein (Youtube, Vimeo, etc.)',
      },
      embedVideoAlert: {
        title: 'Video einbetten',
        content: 'Du kannst Videos aus unterschiedlichen Quellen einbetten (YouTube, Vimeo, etc.).',
        helpReference: 'Schau <0>hier</0> für weitere Informationen.',
      },
      error: {
        invalidEmbedUrl: 'Muss Video-Link sein',
      },
    },
    upgradeNow: 'Jetzt upgraden',
    contactSales: 'Mit Vertrieb sprechen',
    testBusinessSolution: 'Firmenlösung testen',
    select: 'Auswählen',
    standard: 'Standard',
    fillRequired: 'Fülle die erforderlichen Felder aus',
    navSidebar: {
      ariaGoHome: 'Öffne Homepage',
      teamSettings: {
        ariaGoBack: 'Zurück zu den Hauptnavigationselementen',
      },
      collapseButton: {
        aria: {
          label: 'Navigationsseitenleiste ein-/ausklappen',
        },
      },
    },
    addCover: 'Hintergrundbild hinzufügen',
    addLogo: 'Logo hinzufügen',
    addImage: 'Bild hinzufügen',
    addAvatar: 'Profilbild hinzufügen',
    add: 'Hinzufügen',
    note: 'Notiz',
    at: 'bei',
    preferredLang: 'Sprache',
    admin: 'Admin',
    scanCard: 'Karte scannen',
    scanCardShort: 'Scannen',
    search: 'Suche',
    noSearchOptions: 'Keine Suchoptionen',
    customizeTable: 'Tabelle anpassen',
    upgradeToContinue: 'Für diese Aktion musst Du upgraden',
    deselectAll: 'Alle abwählen',
    selectAll: 'Alle auswählen',
    namePrefix: 'Namenspräfix',
    namePrefixHelper: 'z.B. Dr., Prof.',
    academicDegree: 'Akademischer Grad',
    academicDegreeHelper: 'z.B. Ing., B.Sc.',
    academicDegree2: 'Akademischer Grad 2',
    academicDegree2Helper: 'Zeile 2',
    toPaperCardOrderForm: 'Zum Papiervisitenkarten-Bestellformular',
    close: 'Schließen',
    help: 'Hilfe',
    gdpr: 'DSGVO',
    gdprLegalBasis: 'DSGVO',
    addressee: 'Adressat',
    addressLine1: 'Adresszeile 1',
    addressLine2: 'Adresszeile 2',
    postCode: 'PLZ',
    city: 'Stadt',
    country: 'Land',
    address: {
      addressee: 'Adressat',
      addressLine1: 'Adresszeile 1',
      addressLine2: 'Adresszeile 2',
      postCode: 'PLZ',
      city: 'Stadt',
      country: 'Land',
      label: 'Adressbeschriftung',
      2: {
        addressee: '2. Adressat',
        addressLine1: '2. Adresszeile 1',
        addressLine2: '2. Adresszeile 2',
        postCode: '2. PLZ',
        city: '2. Stadt',
        country: '2. Land',
        label: '2. Adressbeschriftung',
      },
    },
    address1: 'Adresse 1',
    address2: 'Adresse 2',
    editDisabledTooltip:
      'Dein Admin hat diese Funktion eingeschränkt. Die Kontaktinformationen Deines Admins findest Du im Hilfe-Button oben rechts.',
    editDisabledTooltipWithEmail:
      'Nur ein Admin kann dies bearbeiten. Bitte kontaktiere: {{email}}.',
    scanner: 'Scanner',
    stillMultiAssigned: 'Manche Accounts sind noch mehr als einer Unit zugewiesen',
    editNotAllowed:
      'Dein Administrator erlaubt es nicht, diese Einstellungen auf Unit-Ebene zu überschreiben.',
    onlyExt: 'Nur ',
    unsavedChangesText: 'Du hast möglicherweise ungespeicherte Änderungen. Trotzdem verlassen?',
    featureNotAllowed: 'Upgrade um dieses Feature freizuschalten',
    upgrade: 'Upgrade',
    openLinkInNewTab: 'Link in neuem Tab öffnen',
    needHelp: 'Fragen?',
    confirm: 'Bestätigen',
    finish: 'Fertig',
    apply: 'Anwenden',
    duplicate: 'Duplizieren',
    deleteSettings: 'Unit-Einstellungen löschen',
    settingDeleteConfirmation:
      'Möchtest Du die Unit-Einstellungen wirklich löschen? Die Einstellungen werden anschließend wieder synchron mit den globalen Organisationseinstellungen sein.',
    settingDeleteConfirmationTitle: 'Unit-Einstellungen Löschen',
    resetSettings: 'Zurücksetzen',
    settingResetConfirmation:
      'Möchtest Du die Einstellungen wirklich komplett auf Anfang zurücksetzen? Auch alle Unit-Einstellungen werden gelöscht.',
    settingResetConfirmationTitle: 'Auf Anfangseinstellungen Zurücksetzen',
    setupSettings: {
      title:
        'Aktuell sind die Einstellungen dieser Unit mit den Einstellungen der Gesamtorganisation synchronisiert.',
      description:
        'Du kannst spezielle Einstellungen für diese Unit festlegen, dann werden sie nicht mehr mit den Einstellungen der Gesamtorganisation synchron sein.',
      buttonText: 'Unit-Einstellungen einrichten +',
    },
    upgradedOnMobile: {
      title: 'Willkommen zu Lemontaps Teams 🎉',
      text: 'Um Dir eine gute und sichere Nutzererfahrung zu garantieren, ist es nur auf dem Desktop möglich, die Admin-Management Ansicht und Funktionalitäten zu nutzen. Logge Dich deshalb gleich auf dem Desktop ein, um auf die neuen Features zugreifen zu können.',
    },
    wallet: {
      description:
        'Füge Lemontaps zu Deiner {{company}} Wallet hinzu und teile Deinen Kontakt über den QR-Code in der Wallet.',
      step1: 'Wähle den QR-Code Typen aus',
      step2: 'Lade deine Visitenkarte in die Wallet',
      hint: {
        title: 'Direkt in die Wallet in meinem {{device}} Gerät?',
        p1: 'Scanne dazu erst den folgenden QR-Code, um Dich auf Deinem {{device}} Gerät in Lemontaps einzuloggen',
        p2: 'Gehe dann auf "Profil teilen" -> "Wallet" und klicke dann auf den "Hinzufügen zu {{company}} Wallet" Button auf Deinem {{device}} Gerät.',
        browsers:
          '⚠️ Die Wallet-Funktion funktioniert in Deinem Browser möglicherweise nicht korrekt. Nutze stattdessen Safari oder Chrome zum Generieren der Visitenkarte für das Wallet.',
      },
    },
    toProfile: 'Zu meinem Profil',
    profileOf: 'Profil von {{name}}',
    contactUs: 'Kontaktiere uns',
    openOnDesktop:
      'Der Zugriff auf die Admin Management Plattform ist nur über Desktop-Geräte möglich, aufgrund von Sicherheitsmaßnahmen.',
    upgradeRequestPopup: {
      title: 'Kontaktiere uns mit Deinem Anliegen',
    },
    upgradePopup: {
      title: 'Willkommen zu Lemontaps Teams 🎉',
      q1: 'Wie heißt Deine Organisation?',
      q2: 'Um wie viele potentielle Nutzer handelt es sich?',
      submit: 'Jetzt loslegen!',
    },
    myCards: 'NFC-Karten',
    profileOverview: 'Profilübersicht',
    profileOverviewFromUnits: 'Unit Mitglieder:Innen',
    header: {
      help: 'Hilfe',
      helpCenter: 'Hilfe-Center',
      chatWithUs: 'Chatte mit uns',
      bookCall: '10-Minuten-Gespräch buchen',
      support: 'Support-Anfragen',
      settings: 'Account-Einstellungen',
      teamManagement: 'Team Management',
      langSelector: {
        aria: {
          label: 'Sprache ändern',
          en: 'Englisch auswählen',
          de: 'Deutsch auswählen',
        },
      },
    },
    saved: 'Gespeichert',
    permissionLevel: 'Berechtigungslevel',
    unexpectedError: 'Unerwarteter Fehler',
    invalidCharEntered: 'Ungültiges Symbol',
    explainTemplatedLink:
      'Dies ist ein Standardlink. Er kann nur entfernt, nicht aber bearbeitet werden',
    explainThemeLink: 'Dieser Link ist von einem Admin festgelegt und kann nicht bearbeitet werden',
    explainThemeLinkToAdmin:
      'Dieser Link wird auf jedem Profil angezeigt. Er kann auf der Profildesign-Seite bearbeitet werden.',
    explainThemeFile: 'Diese Datei ist von einem Admin festgelegt und kann nicht bearbeitet werden',
    explainThemeFileToAdmin:
      'Diese Datei wird auf jedem Profil angezeigt. Sie kann auf der Profildesign-Seite bearbeitet werden.',
    username: 'Benutzername',
    uploading: 'Hochladen',
    mobileBg: 'Handyhintergrund',
    videoBg: 'Videohintergrund',
    minute_one: '{{count}} Minute',
    minute_other: '{{count}} Minuten',
    addData: 'Daten hinzufügen',
    notEnoughLicences: 'Du hast nicht genügend Lizenzen',
    startNow: 'Jetzt starten',
    resetToDefault: 'Auf Standard zurücksetzen',
    editColumns: 'Spalten bearbeiten',
    role: 'Rolle',
    accountDetails: 'Account-Details',
    title: 'Titel',
    Employee: 'Mitarbeiter:in',
    you: 'Du',
    Loading: 'Laden',
    LoadingLong: 'Das kann einige Minuten dauern',
    deleting: 'Löschen',
    saving: 'Speichern',
    Dismiss: 'Schließen',
    tryForFree: 'Kostenlos testen',
    edit: 'Details bearbeiten',
    myLinks: 'Meine Links',
    addLink: 'Link hinzufügen',
    editLinks: 'Links bearbeiten',
    assign: 'zuordnen',
    unitAssign: 'Unit zuweisen',
    unassign: 'aus aktuellen Units entfernen',
    chooseLink: 'Wähle einen Linktyp',
    useTemplate: 'Vorlage verwenden',
    createNewTemplate: 'Neue Vorlage erstellen',
    selectTemplate: 'Vorlage auswählen',
    createOwnLink: 'Eigenen Link erstellen',
    shareVia: 'Per {{app}} teilen',
    copyText: 'Text kopieren',
    pasteToWebsite: 'Füge nun den Text in Deinen Post ein',
    openLink: 'Seite öffnen',
    chooseLinks: 'Wähle Links aus',
    addFile: 'Datei hinzufügen',
    editFile: 'Datei bearbeiten',
    files: 'Dateien',
    links: 'Links',
    contactsForm: 'Kontaktformular',
    additionalCover: 'Zusätzliche Titelbilder',
    showContactsForm: 'Formular anzeigen',
    showContactsFormAsPopup: 'Als Popup anzeigen',
    website: 'Webseite',
    addProfile: 'Nutzer Hinzufügen',
    myFiles: 'Meine Dateien',
    showProfile: 'Profilvorschau ansehen',
    myProfile: 'Mein Profil',
    coverPicture: 'Titelbild',
    editCoverPicture: 'Titelbild bearbeiten',
    editLogo: 'Logo bearbeiten',
    editHeaderLogo: 'Logo',
    editLogoTooltip:
      'Hier kannst Du jedem:er einzelnen Mitarbeiter:in oder einer Gruppe ein spezifisches Firmenlogo hochladen z.B. falls es unterschiedliche Marken gibt. Dieses Logo wird jedoch für alle einheitlich überschrieben, falls ein Logo in den globalen Einstellung gesetzt ist.',
    errorUploadingImage: 'Fehler beim Hochladen',
    uploadingImage: 'Bild hochladen',
    updatingImage: 'Bild wird aktualisiert',
    chooseCoverPicture: 'Hintergrundbild auswählen',
    chooseLogoPicture: 'Logo auswählen',
    nameLink: 'Benenne Deinen Link',
    enterUsername: 'Gib Deinen Benutzernamen ein',
    enterPhone: 'Ländervorwahl & Deine Nummer',
    save: 'Speichern',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    enterLink: 'Gib Deinen Link ein',
    createLink: 'Link erstellen',
    editLink: 'Bearbeite den Link',
    redirectMessage:
      'Leite Deine Visitenkarte direkt auf diesen Link weiter. Zurzeit ist Deine digitale Visitenkarte erreichbar.',
    redirectOffMessage:
      'Zeige wieder Deine digitale Visitenkarte an, wenn Du Dein Profil teilst. Zurzeit wird automatisch auf diesen Link weitergeleitet.',
    hideMessageLink: 'Blende diesen Link auf Deinem Profil aus. Er wird zurzeit angezeigt.',
    showMessageLink:
      'Blende diesen Link auf Deinem Profil wieder ein. Er wird zurzeit nicht angezeigt.',
    hideMessageFile: 'Blende diese Datei auf Deinem Profil aus. Sie wird zurzeit angezeigt.',
    showMessageFile:
      'Blende diese Datei auf Deinem Profil wieder ein. Sie wird zurzeit nicht angezeigt.',
    nameFile: 'Benenne Deine Datei',
    upload: 'Hochladen',
    uploadNew: 'Neu hochladen',
    browseFiles: 'Dateien durchsuchen',
    deleteLink: 'Link löschen',
    imageSize: 'Bildgröße: 750 x 410',
    saveContact: 'Kontakt speichern',
    editProfile: 'Profil bearbeiten',
    editProfiles: 'Profile bearbeiten',
    myContactDetails: 'Meine Kontaktdaten',
    contactDetails: 'Kontaktdaten',
    sendContactProfile: 'Übermittle mir Deinen Kontakt und\nerhalte meinen per Mail:',
    submitYourContact: 'Sende Deine Kontaktdaten und\nerhalte meine per E-Mail:',
    account: 'Konto',
    connectAccount: 'Konto mit Tap oder Karte verbinden',
    howToTap: 'Wie halte ich meinen Lemontaps NFC-Produkt gegen ein Handy?',
    tapIphone: 'So tappst Du auf ein iPhone',
    tapAndroid: 'So tappst Du auf ein Android',
    myContacts: 'Meine Kontakte',
    addContact: 'Kontakt hinzufügen',
    addContactShort: 'Kontakt',
    howtotap: 'Wie benutze ich mein Lemontaps NFC-Produkt?',
    showNow: 'Jetzt Lemontaps Produkte einkaufen',
    changePassword: 'Passwort ändern',
    logout: 'Abmelden',
    nfcInfo1: '1. NFC muss aktiviert sein',
    nfcInfo2: 'iPhone XR und neuer: Es ist bereits automatisch aktiviert',
    nfcInfo3:
      'iPhone 7 - Iphone X: iOS14 muss installiert sein. Gehe zu "Einstellungen" - "Kontrollzentrum" und füge "NFC" hinzu.',
    nfcInfo4:
      'Wische nun über das Kontrollzentrum und drücke das NFC-Zeichen, um das Profil auf der Karte aufzurufen:',
    nfcInfo5: 'Telefon hat kein NFC?',
    nfcInfo6: '2. Tap',
    nfcInfo7: 'Halte Deine Karte gegen den oberen Teil des iPhones der Empfänger:in',
    nfcInfo8: 'Aktiviere "NFC" einmal im oberen Menü',
    nfcInfo9:
      'Wenn NFC noch nicht im Menü zu sehen ist, musst Du es zuerst in den Einstellungen aktivieren:',
    nfcInfo10: 'Einstellungen - Verbindungen - NFC und Zahlungen',
    nfcInfo11: 'Einstellungen - Geräteverbindungen - NFC',
    nfcInfo12: 'Einstellungen - Verbundene Geräte - Verbindungseinstellungen - NFC',
    nfcInfo13: 'Einstellungen - Mehr - Mobile Daten - NFC',
    nfcInfo14: 'Verknüpfe Dein Lemontaps NFC-Produkt mit Deinem Profil',
    nfcInfo15: 'Den Code findest Du auf der Verpackung von Deinem Produkt.',
    nfcInfo16:
      'Du kannst später jederzeit ein Lemontaps NFC-Produkt kaufen und mit Deinem Profil im Menü verbinden.',
    nfcInfo17: 'Halte Deine Karte gegen den oberen Teil des Android der Empfänger:in',
    gotIt: 'Verstanden!',
    accountRole: 'Berechtigung',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    workEmail: 'Email 2',
    workEmail2: 'Email 3',
    businessCardLink: 'Visitenkartenlink',
    birthday: 'Geburtstag',
    position: 'Position',
    company: 'Unternehmen',
    costCenter: 'Kostenstelle',
    division: 'Abteilung',
    businessCardUrl: 'Link zur Visitenkarte',
    mobilePhone: { text: 'Telefon Mobil', label: 'Mobil' },
    workPhone: { text: 'Telefon Arbeit', label: 'Arbeit' },
    homePhone: { text: 'Telefon Privat', label: 'Privat' },
    hotlinePhone: { text: 'Telefon Hotline', label: 'Hotline' },
    assistantPhone: { text: 'Telefon Assistenz', label: 'Assistenz' },
    phone: 'Phone',
    assistantEmail: { text: 'Email Assistenz', label: 'Assistenz' },
    assistantName: { text: 'Name Assistenz', label: 'Assistenz' },
    assistantGeneric: { text: 'Sonstiges Assistenz', label: 'Assistenz' },
    workFax: { text: 'Fax', label: 'Fax' },
    authEmail: 'Account-Email',
    createdOn: 'Erstellt am',
    editedAt: 'Bearbeitet am',
    email1: 'Email 1',
    email2: 'Email 2',
    email3: 'Email 3',
    workUrl: 'Homepage URL',
    bioText: 'Bio (Freitext)',
    update: 'Aktualisieren',
    allProfilesMD: 'Alle Nutzerprofile',
    rowsPerPage: 'Zeilen pro Seite:',
    searchMD: 'Suche nach...',
    emptySearchMD: {
      tooltip: 'Gib "#leer#" ein, um nach leeren Werten zu filtern, oder klicke einfach hier',
      tag: '#leer#',
    },
    editMD: 'Bearbeiten',
    editSelected: 'Profile bearbeiten',
    editSelected_one: 'Profil bearbeiten',
    editSelected_other: '{{count}} Profile bearbeiten',
    deleteSelected_one: 'Profil löschen',
    deleteSelected: 'Ausgewählte löschen',
    deleteSelected_other: '{{count}} Profile löschen',
    deleteBulkMessageConfirmation_one:
      'Möchtest Du dieses Profil wirklich löschen? Keine Daten gehen verloren, außer der Profilinformationen (Gesammelte Kontakte und Statistiken bleiben erhalten).',
    deleteBulkMessageConfirmation_other:
      'Möchtest Du diese {{count}} Profile wirklich löschen? Keine Daten gehen verloren, außer der Profilinformationen (Gesammelte Kontakte und Statistiken bleiben erhalten).',
    yes: 'Ja',
    no: 'Nein',
    asc: 'Aufsteigend',
    desc: 'Absteigend',
    sortOrder: 'Sortierreihenfolge',
    sortBy: 'Sortieren nach',
    shareProfile: {
      text: 'Teilen',
      title: 'Profil teilen via...',
      wallet: {
        online: 'Online',
        offline: 'Offline',
        title: 'QR-Code',
        titleTooltip:
          'Online: Öffnet direkt Dein digitales Lemontaps-Profil im Browser.\nOffline: Der Offline QR-Code funktioniert ohne Internet. Durch Scannen wird der Kontakt direkt geöffnet, nicht Dein online Lemontaps-Profil. Im gespeicherten Kontakt ist trotzdem der Link zu Deinem Lemontaps-Profil enthalten, damit Dein Gegenüber es jederzeit öffnen kann.',
        buttonsTooltip:
          'Füge Lemontaps zu Deiner {{company}} Wallet hinzu und teile Deinen Kontakt über den QR-Code in der Wallet.',
        dialog: {
          title: 'Was ist ein Online- und Offline-QR-Code?',
          description:
            'Online: Öffnet direkt Dein digitales Lemontaps-Profil im Browser.\nOffline: Der Offline QR-Code funktioniert ohne Internet. Durch Scannen wird der Kontakt direkt geöffnet, nicht Dein online Lemontaps-Profil. Im gespeicherten Kontakt ist trotzdem der Link zu Deinem Lemontaps-Profil enthalten, damit Dein Gegenüber es jederzeit öffnen kann.',
        },
      },
      profileLink: {
        title: 'Profil-Link',
        label: 'Link kopieren',
        copied: 'Kopiert',
        shareVia: 'Teilen via',
        shareViaMedia: 'Über soziale Medien teilen',
        tip: '💡 Tipp: Füge den Link in Deine E-Mail-Signatur ein',
      },
      nfcCards: {
        title: 'NFC-Karten',
        description:
          'Du kannst physische Karten nutzen, um Dein Profil per Tap oder QR-Code zu teilen.',
      },
      qrBackgrounds: {
        title: 'QR-Hintergründe',
        description: 'Gestalte Videocall-Hintergründe und Smartphone-Sperrbildschirme.',
      },
      shareMenu: {
        qrMobile: 'QR-Sperrbildschirm erstellen',
        qrDesktop: 'QR-Hintergrund für Videocall erstellen',
        nfcCard: 'NFC-Karte',
      },
    },
    dateDesc: 'Absteigend',
    dateAsc: 'Aufsteigend',
    textDesc: 'Sortiere Z bis A',
    textAsc: 'Sortiere A bis Z',
    reset: 'Zurücksetzen',
    numberDesc: 'Absteigend',
    numberAsc: 'Aufsteigend',
    leads: {
      xSelected: '{{count}} Profil ausgewählt',
      xAllSelected: 'Alle Profile ausgewählt',
      searchPlaceholder: 'Suche nach Name, Mail, etc.',
      addEditNote: 'Notiz hinzufügen/bearbeiten',
      leadNoteAddSuccess: 'Notiz hinzugefügt',
      leadNoteAddError: 'Notiz nicht hinzugefügt',
      noLeadsCollected:
        'Du hast noch keine Kontakte erfasst. Füge jetzt Deinen ersten Kontakt hinzu!',
      createDate: 'Erstellungsdatum',
      noLeadsSearchResult: 'Keine Ergebnisse gefunden',
    },
    mdUnits: {
      noUnitsFound: 'Keine Units gefunden',
      searchPlaceholder: 'Suche nach Unitnamen...',
      noUnitsAreAdded: 'Es wurden noch keine Units hinzugefügt',
      deleteUnitsMsg_one: 'Möchtest Du die ausgewählte Unit wirklich löschen?',
      deleteUnitsMsg_other: 'Möchtest Du die ausgewählten {{count}} Units wirklich löschen?',
      xAllSelected: 'Alle ausgewählt',
      xSelected: '{{count}} ausgewählt',
      members: 'Mitglieder:innen',
      admins: 'Admins',
      viewMembers: 'Mitglieder:innen anzeigen',
      manageMembers: 'Mitglieder:innen verwalten',
      addMembers: 'Mitglieder:innen hinzufügen',
      addMembersExplainer:
        'Um Mitglieder:innen zu Deiner Einheit hinzuzufügen, musst Du die Benutzerprofilseite besuchen, dort die Mitglieder:innen auswählen und sie der ausgewählten Einheit zuweisen.',
      goToUsers: 'Zu den Nutzer:innen',
      addExistingUser: 'Bestehenden Nutzer:innen hinzufügen',
      createNewUser: 'Neuen Nutzer:innen erstellen',
      deleteSuccess: 'Unit gelöscht',
    },
    mdLeads: {
      unitsNotEmpty: 'Nur leere Units können gelöscht werden.',
      xUnitsSelected: '{{count}} ausgewählt',
      xAllUnitsSelected: 'Alle ausgewählt',
      successCreate: 'Unit erstellt',
      noUnit: 'Keine Unit',
      edit: 'Bearbeiten',
      unit: 'Unit',
      status: 'Status',
      members: 'Mitglieder:innen',
      membersView: 'Mitglieder:innen anzeigen',
      view: 'Ansicht',
      unitAdmins: 'Unit-Admins',
      noSelectedUnits: 'Es sind noch keine Nutzer:innen in dieser Unit',
      firstCreateUnit: 'Du musst zuerst eine Unit erstellen',
      addEmployeeToUnit: 'Hinzufügen bestehender Mitarbeiter:innen zu einer Unit',
      editUnitHeader:
        'Gehe zur Profilseite, wähle die Benutzer:innen aus, die Du einer Unit zuweisen möchtest, und wähle dann die Option "Unit zuweisen“.',
      errorReadUnits: 'Fehler beim Laden von Units',
      errorDeleteUnits: 'Fehler beim Löschen von Units',
      noUnitSelected: 'Mindestens eine Unit muss ausgewählt sein',
      noRoleSelected: 'Mindestens eine Rolle muss ausgewählt sein',
      unassignFromUnit: 'Aus Unit entfernen',
      confirmRemoveUnit_one: 'Möchtest Du das ausgewählte Profil wirklich aus der Unit entfernen?',
      confirmRemoveUnit_other:
        'Möchtest Du die ausgewählten Profile wirklich aus der Unit entfernen?',
      removeFromUnit: 'Aus Unit entfernen',
      removingFromUnit: 'Aus Unit entfernen',
      successRemovingFromUnit: 'Aus Unit entfernt',
      toProfiles: 'zur Profilseite',
      addUsersToUnit: 'Füge der Unit vorhandene Benutzer hinzu',
      loadingUnits: 'Lade Units',
      AssignUnitError: 'Fehler beim Zuweisen von Units',
      assignUnitWarning:
        'Die ausgewählten Accounts werden den Units zugeordnet, die hier ausgewählt werden, und aus den Units entfernt, die hier nicht ausgewählt werden.',
      deleteUnits_one: 'Möchtest Du die Unit wirklich löschen?',
      deleteUnits_other: 'Möchtest Du die Units wirklich löschen?',
      loadingUnitsError: 'Fehler beim Laden der Units',
      unitNameExists: 'Eine Unit mit diesem Namen existiert bereits',
      contact: 'Kontakt',
      notes: 'Notiz',
      multiNotes: 'Evtl. untersch. Notizen',
      connectedWith: 'Kennengelernt durch',
      date: 'Datum',
      vcf: '.vcf',
      deletedAccount: 'Account existiert nicht mehr',
      accountDeleted: 'Gelöschter Account',
      connectedAccounts: 'Kennengelernt durch',
      connectTo: 'Kennengelernt durch',
      downloadCSV: 'csv Herunterladen',
      editFormTitle: 'Kontakt bearbeiten',
      editSuccess: 'Kontakt wurde aktualisiert',
      editError: 'Kontakt nicht aktualisiert',
      addFormTitle: 'Kontakt hinzufügen',
      addSuccecss: 'Kontakt erfolgreich hinzugefügt',
      addError: 'Kontakt konnte nicht hinzugefügt werden',
      uploadPhoto: 'Foto hochladen',
      download: 'Herunterladen',
      metAt: 'Kennengelernt am',
      fetchError: 'Bitte aktualisiere die Seite und probiere es noch einmal',
      deleteContact: 'Kontakt löschen',
      deleteSuccess: 'Kontakt wurde gelöscht',
      deleteError: 'Kontakt nicht gelöscht',
      tooltipMultiAccountsConnected:
        'Dieser Kontakt wurde von mehreren Mitarbeiter:innen aufgenommen. Im Dropdown-Menü unten kannst Du zwischen den Kontaktinformationen wechseln, die die verschiedenen Mitarbeiter:innen eingetragen haben.',
      export: 'Export',
      errorSaving: 'Fehler beim Speichern, mindestens ein Feld ist erforderlich',
      crmExport: {
        confirmDialog: {
          title: 'Exportieren',
          text_one: 'Möchtest Du einen Kontakt zu {{name}} exportieren?',
          text_other: 'Möchtest Du {{count}} Kontakte zu {{name}} exportieren?',
          textAll: 'Möchtest Du alle Kontakte zu {{name}} exportieren?',
          campaignAccordionTitle: 'Einer Kampagne zuordnen',
          campaignTitle: 'Möchtest Du die ausgewählten Kontakte einer Kampagne zuordnen?',
          selectCampaign: 'Wähle eine Kampagne aus',
          campaignHelper:
            'Hinweis: Du musst keine Kampagne auswählen und kannst einfach mit dem Export fortfahren',
          configurationsAccordionTitle: 'Pflichtfelder aus CRM befüllen',
          configurationsInfo:
            'Wenn Du in Deinem CRM benutzerdefinierte Pflichtfelder konfiguriert hast, musst Du hier einen Wert angeben, andernfalls schlägt der Export fehl. Wenn du ein Feld hier nicht siehst, klicke auf den Button unten, um die Felder zu aktualisieren.\n\nFalls Du Administrator:in bist, kannst Du auch globale Standardwerte auf der CRM-Einstellungsseite konfigurieren.',
          noConfigurations: 'Keine Pflichtfelder verfügbar.',
          refreshConfigurations: 'Benutzerdefinierte Felder aktualisieren',
        },
        startSuccess: 'Export gestartet',
        startFail: 'Fehler beim Starten des Kontaktexports',
        emptySelection: 'Wähle die zu exportierenden Kontakte aus',
      },
      metOn: 'Kennengelernt am',
      metBy: 'Kennengelernt durch',
      showDeleted: 'Gelöschte anzeigen',
      deleteLeadsMsg:
        'Bist Du sicher, dass Du die ausgewählten {{count}} Kontakte löschen möchtest?',
      crmExportStatus: 'Exportstatus',
    },
    deletedAt: 'Gelöscht am',
    translations: {
      title: 'Übersetzung',
      description:
        'Übersetze die digitalen Visitenkartenprofile Deines Teams. Du kannst den Standardtext, Kontaktinformationen, Linknamen, Links und Dateinamen übersetzen. Du kannst auch festlegen, dass Informationen in bestimmten Sprachen nicht angezeigt werden sollen.',
      language: {
        updateError: 'Fehler beim Aktualisieren der Sprache!',
        title: 'Unterstützte Sprachen',
        description:
          'Die Sprache, in der ein Profil angezeigt wird, hängt von den Geräteeinstellungen des:der Empfänger:in ab. Wenn die Sprache des Geräts nicht unterstützt wird, wird das Profil in der Fallback-Sprache angezeigt. Stelle die Eingabesprache auf die Sprache ein, die Du zum Ausfüllen der Profilattribute im Admin-Dashboard nutzst.',
        types: {
          input: 'Eingabe-Sprache',
          fallback: 'Fallback-Sprache',
        },
        button: 'Sprache hinzufügen',
        addModal: {
          title: 'Neue Sprache hinzufügen',
          languageLabel: 'Sprache',
          typeLabel: 'Typ (optional)',
        },
        notEditable:
          'Du kannst die Eingabe- und Fallback-Sprachen hier nicht entfernen. Wähle stattdessen die neue Sprache aus, die du als Eingabe- oder Fallback-Sprache markieren möchtest.',
        editModal: {
          title: 'Sprache bearbeiten',
          languageLabel: 'Sprache auswählen',
          typeLabel: 'Typ (optional)',
        },
        deleteModal: {
          errorMainLangDelete:
            'Du kannst die Eingabe- und Fallback-Sprachen nicht löschen. Markiere zuerst eine andere Sprache als Eingabe- oder Fallback-Sprache.',
          title: 'Du möchtest diese Sprache nicht mehr unterstützen?',
          description:
            'Diese Sprache wird in den Profilen Ihrer Mitarbeiter:innen nicht mehr unterstützt. Profilbesucher, deren Geräte auf diese Sprache konfiguriert sind, sehen das Profil stattdessen in der Fallback-Sprache.',
        },
      },
      packs: {
        title: 'Sprachpakete',
        description:
          'Lade die Sprachpakete herunter und füge Übersetzungen hinzu oder ändere sie. Wenn Du ein Feld in der Übersetzung leer lässt, wird der Wert der Eingabesprache für dieses Feld angezeigt. Wenn Du ein Feld löschen möchtest, schreibe "{{token}}" als Übersetzung. Lade im Anschluss eine Zip-Datei hoch, die nur csv-Dateien mit denselben Namen wie im Download enthält.',
        uploadLabel: 'Zum Hochladen klicken oder per Drag & Drop',
        link: 'Aktuelle Sprachpaket-Konfiguration herunterladen',
        notAllowedTitle: 'Bitte deinen Admin um Erlaubnis',
        notAllowedBody:
          'Dein Admin erlaubt die Bearbeitung von Übersetzungen für Unit-Admins nicht.',
        uploadSuccess: 'Sprachpakete aktualisiert.',
        filename: 'Sprachpaket',
        errors: {
          generic: 'Error',
          fileNotFound: 'Datei nicht gefunden',
          uploadError: 'Upload fehlgeschlagen. Überprüfe die Dateiformate und Inhalte.',
          invalidFilename:
            'Mindestens ein ungültiger Dateiname. Benenne die Dateien genau so, wie im heruntergeladenen Sprachpaket.',
          api: {
            LIMIT_FILE_SIZE: 'Die Datei ist zu groß.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_NO_DATA]: 'Datei konnte nicht gelesen werden.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_INVALID_FILE_TYPE]: 'Ungültiger Dateityp.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_READ_ERROR]:
              'Fehler beim Lesen der Datei. Die hochgeladene zip-Datei könnte beschädigt sein.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_INVALID_FILENAMES_OR_NO_PERMISSION]:
              'Es sind nur Dateinamen erlaubt, die auch im heruntergeladenen Sprachpaket enthalten sind. Entweder wurden falsch benannte Dateien hochgeladen, oder Du bist nicht berechtigt, einige der Kategorien zu bearbeiten.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_CSV_PARSE_ERROR]:
              '"{{filename}}": Fehler beim Lesen der Daten. Achte darauf, dass die Spalten und Zeilen korrekt formatiert sind.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_INVALID_KEYS]:
              '"{{filename}}": Einige oder alle Werte in der Spalte "key" sind ungültig.',
          },
        },
      },
    },
    errorDeletingUsers_one: 'Fehler beim Versuch, Profil zu löschen',
    errorDeletingUsers_other: 'Fehler beim Versuch, Profile zu löschen',
    errorFetchingProfiles: 'Fehler beim Abrufen von Profilen',
    successfullyUsersDeleted_one: 'Profil erfolgreich gelöscht',
    successfullyUsersDeleted_other: 'Profile erfolgreich gelöscht',
    deleteAccounts_one: 'Profil löschen',
    deleteAccounts_other: 'Profile löschen',
    onlineQrCodes: 'Online QR-Codes',
    offlineQrCodes: 'Offline QR-Codes',
    employeesCsv: 'CSV',
    employeesXlsx: 'Excel',
    profileLinks: 'Profil-Links',
    actionTooltips: {
      onlineQrCodes:
        'Du exportierst einen Lemontaps QR-Code (enthält den Link zum Lemontaps-Profil) als Bild für jedes der ausgewählten Profile.',
      offlineQrCodes:
        'Du exportierst einen Lemontaps QR-Code (enthält die Kontaktinformationen als vcard) als Bild für jedes der ausgewählten Profile.',
      csv: 'Du exportierst alle Profilinformationen in der Tabelle im CSV-Format.',
      xlsx: 'Du exportierst alle Profilinformationen in der Tabelle im Excel-Format.',
      links: 'Du exportierst eine CSV-Datei mit den Profillinks.',
      mobileBg: 'Du exportierst einen QR-Code Hintergrund für den Handy Sperrbildschirm.',
      videoBg: 'Du exportierst einen QR-Code-Hintergrund für das Videokonferenz-Tool.',
    },
    gettingCommonLinks: 'Überprüfung von gemeinsamen Links...',
    commonLinksFound: 'Gemeinsame Links gefunden!',
    commonLinksNotFound: 'Keine gemeinsamen Links gefunden!',
    xProfilesSelected: '{{count}} ausgewählt',
    xProfilesSelectedLong: '{{count}} Profile ausgewählt',
    xAllProfilesSelected: 'Alle ausgewählt',
    bulkActions: {
      chooseActionType: 'Wähle eine Aktion aus',
      chooseField: 'Wähle ein Feld aus',
      value: 'Wert',
      distribute: {
        title: 'Verteilen per E-Mail',
        wallet: {
          tooltip:
            'Verteile Google und Apple Wallet Visitenkarten per E-Mail an die ausgewählten Profile.',
          successMsg:
            'Die Wallet E-Mails wurden erfolgreich an die ausgewählten Profile versendet.',
          errorMsg:
            'Beim Senden ist ein Fehler aufgetreten. Bitte lade die Seite neu und versuche es erneut.',
        },
      },
      invite: {
        title: 'Einladungen versenden',
        confirm: 'Möchst Du an die ausgewählten Profile Einladungen per E-Mail versenden?',
      },
    },
    inviteSelected: 'Einladungen versenden',
    inviteSelected_one: 'Einladung versenden',
    inviteSelected_other: '{{count}} Einladungen versenden',
    bulkUpdated: 'Profile wurden aktualisiert',
    bulkFailed: 'Fehler beim Aktualisieren der Profile',
    bulkInvitedSync: 'Die Zugangsmails wurden versendet',
    bulkInvitedAsync:
      'Du erhälst eine E-Mail, sobald die Profile eingeladen wurden. Das kann eine Weile dauern.',
    invited: 'Die Zugangsmail wurde versendet',
    passwordMessage: 'Dein Passwort sollte mindestens Folgendes enthalten',
    characters: '8 Zeichen',
    oneNumber: 'Eine Zahl',
    oneSpecialCharacter: 'Ein Sonderzeichen',
    next: 'Weiter',
    welcomeMD: 'Willkommen zur Management Plattform!',
    problemsMD: 'Falls Du Probleme oder sonstige Fragen hast.',
    clickHere: 'Klicke hier!',
    welcomeBackMD: 'Willkommen zurück!',
    forgotMD: 'Zugangsdaten vergessen?',
    resetPasswordMD: 'Passwort zurücksetzen',
    emailAddress: 'Email Adresse',
    password: 'Passwort',
    welcomeMessage: 'Willkommen zu Lemontaps!',
    logIn: 'Einloggen',
    passwordForgot: 'Passwort vergessen?',
    choosePersonalLink: 'Wähle Deinen eigenen Benutzernamen für Deinen Link aus',
    yourUsername: 'Dein Nutzername',
    signUpMessage: 'Registriere Dich für Dein Lemontaps Konto innerhalb Sekunden.',
    alreadyRegistered: 'Du hast schon ein Konto?',
    signUp: 'Registrieren',
    confirmPW: 'Passwort wiederholen',
    consentMessage:
      'Mit der Nutzung unseres Dienstes erklärst Du Dich mit den Nutzungsbedingungen und der Datenschutzerklärung einverstanden.',
    firstNameONB: 'Hallo, willkommen an Board! 🎉 Wie ist Dein Vorname?',
    lastNameONB: 'Hallo {{firstName}} 🎉 Wie ist Dein Nachname?',
    addProfileImageONB: 'Füge Dein Profilbild hinzu',
    changeMessage: 'Du kannst dieses jederzeit später ändern.',
    skip: 'Überspringen',
    addHeadlineONB: 'Füge eine Beschreibung hinzu',
    addHeadlineMessage: 'Hier kannst Du Deine Position oder einen Slogan über Dich einfügen',
    headline: 'Beschreibung',
    addPhoneONB: 'Füge Deine Geschäftsnummer hinzu',
    addPhoneMessage:
      'Diese Telefonnummer kann mit Deinen neu gewonnen Kontakten geteilt werden. Du kannst diese jederzeit später ändern oder entfernen.',
    linkLemontap: 'Verbinde Dein Lemontap mit Deinem Konto',
    activationCodeMessage: 'Du kannst den Aktivierungscode auf der Verpackung finden.',
    enterCode: 'Aktivierungscode eingeben',
    connect: 'Vernetzen',
    continueWithoutLemontap: 'Ohne das Verbinden eines Lemontaps NFC-Produkts fortführen',
    learnHowONB:
      'Finde heraus, wie man sein Lemontaps NFC-Produkt gegen unterschiedliche Smartphones hält',
    connectSuccessfully:
      'Du hast erfolgreich Dein Lemontaps NFC-Produkt mit Deinem Konto verknüpft! Du kannst nun mit der Einrichtung Deines Profils beginnen.',
    validateAccount: 'Bestätige Dein Konto',
    sentEmail:
      'Wir haben Dir eine E-Mail mit einem Link zur Bestätigung Deines Kontos gesendet. Bitte überprüfe Deinen Posteingang oder Deinen Spam-Ordner.',
    failreceiveEmail: 'Ich habe die E-Mail nicht erhalten',
    findInstructions: 'Klicke hier, um immer die Anweisungen für das Tippen zu finden.',
    contactInfo: 'Kontaktinformationen',
    contactInfoBulkHint:
      'Die hierdurch gesetzten Informationen überschreiben das entsprechende Feld der ausgwählten Profile.',
    contactInfoBulkHintDeleted:
      'Das hier selektierte Feld wird bei allen ausgewählten Profilen geleert.',
    accountInfo: 'Accountinformationen',
    german: 'Deutsch',
    english: 'Englisch',
    language: 'Sprache',
    createProfile: 'Neues Profil erstellen',
    shareNFC: 'Auf Smartphones ohne NFC teilen',
    scanQRC: 'Lass sie Deinen QR-Code scannen, den sie in Deinem Profil finden',
    loginEmail: 'Mit E-Mail anmelden',
    pleaseLogin: 'Bitte logge Dich mit der E-Mail-Adresse und dem Passwort aus der Verpackung ein.',
    pageNotFound: 'Hoppla! Seite nicht gefunden 🙈',
    weareSorry:
      'Wir entschuldigen uns für die Unannehmlichkeiten . Offenbar versuchst Du, auf eine Seite zuzugreifen, die entweder gelöscht wurde oder nie existiert hat.',
    backHome: 'Zurück zur Startseite',
    enterLinkerror: 'Bitte gib einen Link ein',
    nameLinkerror: 'Bitte benenne Deinen Link-Button',
    enterMessage: 'Bitte gib {{subject}} ein',
    phoneSubject: 'eine Telefonnummer',
    usernameSubject: 'einen Nutzernamen',
    linkSubject: 'einen Link',
    one: ' ein',
    nameFileerror: 'Bitte benenne Deine Datei',
    correctLink: 'Bitte gib einen korrekten Link an',
    serverError: 'Server Problem.',
    usernameCharcters: 'Der Benutzername muss mindestens 3 Zeichen enthalten',
    matchingPasswords: 'Die Passwörter sind noch nicht identisch.',
    connectMD: 'Bitte gib den Aktivierungscode ein, um dem Profil eine Karte zu zuweisen.',
    errorMDMail: 'Bitte gib eine Email-Adresse ein',
    errorMDFullName: 'Die Informationen zu Vor- und Nachname fehlen',
    changesSaved: 'Änderungen gespeichert',
    codeRead: {
      missed: 'QR-Code ist inkorrekt!',
      title: 'Halte den QR-Code der Karte, die Du verbinden möchtest, in die Kamera',
      loadingMD: 'Code validieren...',
      successMD: 'Der Code wurde validiert!',
      errorMD:
        'Der QR-Code ist entweder belegt oder nicht korrekt. Bitte kontaktiere den Kundenservice, falls die Karte nicht belegt ist, aber nicht verknüpft wird.',
      btnText: 'Neue Karte verknüpfen',
      btnSuccessText: '1 Karte wird verknüpft',
      connectHeadlineMD: 'Optional: Eine neue Karte mit dem Profil verknüpfen',
      connectMessageMD:
        'Einfach den QR-Code auf der Karte in die Kamera halten und der Code wird automatisch mit dem Profil verknüpft.',
      enterCodeText: 'oder manuell eingeben',
      switchCamera: 'Kamera wechseln',
      problems: 'Probleme? Scanne den QR-Code mit Deinem Smartphone.',
      startNow: 'Jetzt starten',
      uploadImage: 'Bild von QR-Code hochladen',
    },
    imageUpdatedMD: 'Das Profilbild wurde aktualisiert!',
    createdSuccessMD: 'Das Profil wurde erfolgreich erstellt!',
    status: 'Status',
    statusActive: 'Aktiv',
    statusInvited: 'Einladung versendet',
    statusUninvited: 'Nicht eingeladen',
    sendInvite: 'Einladung senden',
    sendInviteTooltip:
      'Möchtest Du einen Zugangslink automatisch nach der Erstellung des Profils an die registrierte Email mit senden? Du kannst dies auch später manuell machen.',
    resendInvite: 'Einladung erneut senden',
    invitationMD: `Möchtest Du einen Zugangslink automatisch nach der Erstellung des Profils an die registrierte Email mit senden?
                       Du kannst dies auch später manuell machen.`,
    cropImage: 'Ziehe ein Rechteck um die gewünschte Stelle',
    requestDelete: 'Bist Du Dir sicher?',
    deleteMessageLink: 'Dein Link wird gelöscht.',
    deleteMessageFile: 'Deine Datei wird gelöscht.',
    deleteMessageAddress: 'Diese Adresse wird gelöscht.',
    genericDelete: 'Diese Aktion ist irreversibel. Möchtest Du fortfahren?',
    deleteFile: 'Datei löschen',
    deleteIt: 'Ja, löschen.',
    DBScanQR:
      'Halte die Karte in der Hand und scanne den QR-Code auf der Karte mit Deinem Handy ein',
    DBOpenlink: 'Öffne den gescannten Link und gehe zur URL-Leiste',
    DBUseCharac: 'Verwende die Zeichen nach dem Schrägstrich "/" der URL lemontaps.com/',
    DBUseCharac2: 'als Aktivierungscode und gib  diesen Code hier unten ein',
    YourPlan: 'Dein Plan',
    FirstYear: 'im ersten Jahr',
    billingHeadingSecond: 'und danach 29,99€/Jahr',
    active: 'Aktiv',
    activeUppercase: 'AKTIV',
    cancelPlan: 'Abo kündigen',
    mailTemplateFirst: 'Bitte sende uns eine E-Mail an ',
    mailTemplateSecond: ' um Dein Abonnement zu kündigen.',
    mailTemplateActive: 'um Dein Abonnement zu aktivieren.',
    billing: 'Abrechnung',
    onBoard: 'Hallo, willkommen an Bord!',
    yourName: 'Wie heißt Du?',
    cardInfo: 'Füge Deiner digitalen Visitenkarte weitere Informationen hinzu.',
    learnHowToTap: 'Erfahre, wie Du Dein Lemontaps NFC-Produkt per NFC',
    lemonTapsPhones: 'gegen ein Handy halten und verwenden kannst',
    subscription: 'Mein Abonnement',
    newCardDetected:
      'Eine neue NFC-Karte wurde erkannt. Möchtest Du sie mit Deinem Profil verknüpfen?',
    connectCard: 'NFC-Karte verknüpfen?',
    addCodeSuccess:
      'Glückwunsch, Du hast Dein Lemontaps NFC-Produkt erfolgreich mit Deinem Profil verknüpft!',
    verification: {
      verifyEmail: 'Verifiziere Deine E-Mail',
      subHeader:
        'Wir haben einen Verifizierungslink an <0>{{email}}</0> geschickt.<1></1> Bitte überprüfe deinen Posteingang oder deinen Spam-Ordner.',
      notReceived: 'Keine E-Mail erhalten?',
      resendEmail: 'Erneut senden',
      sendSuccess: 'Gesendet',
      sendError: 'Fehler',
      changeEmail: 'Andere E-Mail benutzen',
      wrongEmail: 'Falsche E-Mail?',
      logout: 'Logout',
      back: 'Zurück zur Anmeldung',
    },
    emailTooltipCreate:
      'Dies ist die Email-Adresse, welche für die Registrierung und den Login verwendet wird',
    emailTooltipEdit: `Dieses Feld bestimmt nur die Email-Adresse, die auf der Profilseite und im heruntergeladenen Kontakt dargestellt wird.
                        Die Email-Adresse, die für den Login verwendet wird, kannst Du über den Reiter "Account" bearbeiten.`,
    metaTagTitle: 'Deine digitale und nachhaltige Visitenkarte',
    metaTagDescription:
      'Verwalte und teile deine Kontaktinformationen, Links und Dokumente effizient und ohne Papierverschwendung! Die Nr.1 in Deutschland und das Original für digitale Visitenkarten!',
    metaTagProfileDescription: 'Vernetzen wir uns und schaue Dir mein Profil an!',
    register: 'Registrieren',
    newToLt: 'Falls Du neu bei Lemontaps bist',
    login: 'Einloggen',
    fileUploaded: 'Datei erfolgreich hochgeladen',
    // success messages from backend
    'Login credentials validated successfully': 'Login Daten erfolgreich validiert',
    'Activation credentials validated successfully': 'Aktivierungsdaten erfolgreich validiert',
    'Password reset mail sent successfully': 'Passwort Reset Email erfolgreich versendet',
    'Password Updated Successfully': 'Passwort erfolgreich aktualisiert',
    'Links saved successfully': 'Links erfolgreich gespeichert',
    'Link deleted successfully': 'Link erfolgreich gelöscht',
    'User details updated successfully': 'Account Details erfolgreich aktualisiert',
    'File saved successfully!': 'Datei erfolgreich gespeichert',
    'File deleted successfully!': 'Datei erfolgreich gelöscht',
    'Username is available': 'Username ist verfügbar',
    'Account saved successfully': 'Account erfolgreich gespeichert',
    'Validation mail resent successfully': 'Validiertungsemail erfolgreich versendet',
    'Account details saved successfully': 'Account Details erfolgreich gespeichert',
    'The code is linked successfully.': 'Code erfolgreich verlinkt',
    'The given value is code': 'Valide Nummer',
    'Account validated successfully.': 'Account erfolgreich validiert',
    'Account Validation Mail sent successfully': 'Accountvalidierungsemail erfolgreich versendet',
    'Contact mail sent successfully': 'Kontaktemail erfolgreich versendet',
    // error messages from backend
    'User not found': 'Account nicht gefunden',
    'Email address is not registered.': 'Diese Email ist nicht registriert',
    'Failed to save the links': 'Links nicht gespeichert',
    'Failed to delete the links': 'Links nicht gelöscht',
    'Failed to save the user details': 'Accountdetails nicht gespeichert',
    'Failed to save the file': 'Datei nicht gespeichert',
    'Failed to delete the user links': 'Accountlinks nicht gelöscht',
    'Email address is already registered.': 'Emailaddresse bereits registriert',
    'Failed to update the password.': 'Passwort nicht aktualisiert',
    'Insufficient Permission': 'Berechtigungen nicht ausreichend',
    'Username is already taken!': 'Username nicht verfügbar',
    'Account not saved': 'Account nicht gespeichert',
    'Account does not exist!': 'Account existiert nicht',
    'The code is incorrect. Try again or contact us.':
      'Der Code ist falsch. Erneut versuchen oder Lemontaps kontaktieren',
    'This code is already used.': 'Code wird bereits genutzt',
    'The given code does not exist.': 'Code existiert nicht',
    'The given username or code does not exist.': 'Code oder Username existiert nicht',
    'The given code is not connected to any account.': 'Code ist mit keinem Account verknüpft',
    'Invalid token': 'Token nicht valide',
    addContactLead: 'Neuen Kontakt hinzufügen',
    leadType: 'Lead Typ',
    exportToCRM: 'Export & Import ins CRM',
    importToCRM: 'Datei ins CRM importieren',
    step1: 'Schritt 1',
    step2: 'Schritt 2',
    downloadFile: 'Datei herunterladen',
    chooseFileFormat: 'Wähle bitte das Format für den Export aus.',
    downloadCSV: 'CSV herunterladen',
    downloadXLSX: 'XLS herunterladen',
    importInstructions: 'Es ist einfach - wir haben Dir Anleitungen für CRMs vorbereitet',
    learnMore: 'Mehr erfahren',
    howToImport: 'Wie importiert man die Datei ins CRM',
    qrCodeBG: 'QR-Code Hintergrund',
    qrWallpaper: 'QR-Hintergrund',
    videocallBG: 'Videocall Hintergrund',
    videocallTxt:
      'Gib auch bei Online Events oder Meetings Deinen Gesprächspartner:innen die Möglichkeit Dein digitales Profil einzuscannen. Die empfohlene Mindestgrösse ist 1500x1000.',
    bgImageTitles: {
      choosePosition: 'Wähle die QR-Code Position aus',
    },
    qrCodeBackground: {
      alerts: {
        noSelection: {
          title: 'Kein Bild ausgewählt',
          content: 'Du musst zuerst ein Bild hochladen hochladen.',
        },
        noActionAllowed: {
          title: 'Nicht erlaubt',
          content:
            'Du kannst hier keinen QR-Code-Hintergrund herunterladen. Bitte Deinen Admin, entweder eine Auswahl an Bildern hochzuladen oder das Hochladen eigener Bilder zu erlauben.',
        },
      },
      type: 'QR-Code Typ auswählen',
      typeTooltip:
        'Online: Öffnet direkt Dein digitales Lemontaps-Profil im Browser.\n Offline: Der Offline QR-Code funktioniert ohne Internet. Durch Scannen wird der Kontakt direkt geöffnet, nicht Dein online Lemontaps-Profil. Im gespeicherten Kontakt ist trotzdem der Link zu Deinem Lemontaps-Profil enthalten, damit der Gegenüber jederzeit es öffnen kann.',
      image: 'Bild auswählen',
      download: 'QR-Code gestalten & herunterladen',
      online: 'Online',
      offline: 'Offline',
      uploadSubtext: 'SVG, PNG, JPG oder GIF (beliebiges Format, Mindestbreite: {{limit}}px)',
      howToUseVC: 'Wie benutze ich meinen Videocall Hintergrund in Zoom, Teams oder Hangouts?',
      imageTooltip:
        'Beliebiges Format, Mindestbreite {{limit}}px empfohlen.\nTipp: Verwende das Format Deines Handys!',
      positions: {
        top: 'Oben',
        middle: 'Mitte',
        bottom: 'Unten',
        left: 'Links',
        right: 'Rechts',
        default: 'Standard',
      },
      mobile: {
        title: 'QR Smartphone Hintergrund',
        header:
          'Halte Dein Lemontaps-Profil über einen QR-Code auf dem Lockscreen Deines Smartphones immer griffbereit.',
        label: 'Smartphone-Hintergrund',
      },
      videocall: {
        title: 'QR Videocall Hintergrund',
        header:
          'Teile Dein Profil auch bei Online-Events oder Meetings mit Deinem Lemontaps QR-Code im Videocall-Hintergrund.',
        label: 'Videocall-Hintergrund',
      },
    },
    download: 'Herunterladen',
    imageUpload: 'Bild hochladen',
    generalQRImgError: 'Bitte aktualisiere die Seite und probieren es nochmal',
    uploadImgError: 'Bitte lade ein Bildformat hoch (.jpg, .jpeg, .png)',
    uploadImgSuccess: ' Das Bild wurde erfolgreich hochgeladen',
    downloadImgSuccess: 'Das Bild wird heruntergeladen',
    mobileBG: 'Handy Hintergrund',
    mobileTxt:
      'Hast Du Deine Lemontaps Karte vergessen? Keine Sorge, habe Dein Lemontaps Profil immer griffbereit über einen QR-Code auf Deinem Handy Sperrbildschirm. Die empfohlene Mindestgrösse ist 1000x2000',
    uploadedImgSizeErr: `Dein hochgeladenes Bild muss mindestens {{x}}px Breite haben`,
    uploadedMobileImgErr: 'Das Bild muss vertikal sein und eine Mindestbreite von {{x}}px haben.',
    notes: 'Notizen',
    writeNoteHere: 'Füge hier Notizen hinzu',
    contactInformation: 'Kontaktdaten eingeben',
    scanBusinessCard: 'Visitenkartenbild hochladen',
    dataWillBeEntered: 'Daten müssen eingetragen werden',
    editContact: 'Kontakt bearbeiten',
    leadSegment: 'Lead Segment',
    or: 'oder',
    createInProgress: 'Die "Erstellen-Funktion" ist in der Umsetzung',
    editInProgress: 'Die "Bearbeiten-Funktion" ist in der Umsetzung',
    missingInputPrompt: '{{param}} ist nicht ausgefüllt',
    fullName: 'Vollständiger Name',
    firstAndLastName: 'Vor- & Nachname (in einer Zeile)',
    name: 'Name',
    correctEmail: 'Bitte gib eine korrekte Email-Adresse ein',
    succAdded: 'Erfolgreich hinzugefügt!',
    consentToData: 'Bitte bestätige die Verarbeitung Deiner Kontaktdaten.',
    shareBusinessCard: 'Teile Deine Visitenkarte mit {{name}}',
    shareContactDetails: 'Teile Deine Kontaktdaten mit {{name}}',
    retakePic: 'Erneut aufnehmen',
    gdprConsent: {
      pt1: 'Durch Absenden bestätige ich, dass ich die',
      pt2: 'zur Kenntnis genommen habe und mit der Verarbeitung meiner Daten im Kontakt-Management des Lemontaps Nutzers und gegebenfalls seiner Firma',
      pt3: 'zu den genannten Zwecken einverstanden bin. Du kannst jederzeit Deine Einwilligung widerrufen.',
      gdpr: 'Datenschutzerklärung',
    },
    privacyPolicy: ' DSGVO',
    termsAndConditions: 'AGBs ',
    and: ' und ',
    Send: 'Senden',
    saveContactOf: `{{name}} Kontakt speichern`,
    modalEmailPlaceholder: 'Kontakt an Deine Email senden',
    dataDisclaimer: 'Wir werden Deine Daten weder verkaufen, noch mit Dritten teilen.',
    lgFollowUp_contSave: 'Möchtest Du auch {{name}} Kontaktinformationen speichern?',
    lgFollowUp_contConn: 'Möchtest Du auch Deine Kontaktinformationen mit {{name}} teilen?',
    lgFollowUp_doneSave: 'Wir haben Dir eine Email mit {{name}} Kontaktdaten gesendet!',
    lgFollowUp_doneSaveDown: '{{name}} Kontakt heruntergeladen!',
    lgFollowUp_doneConn: 'Deine Kontaktinformationen wurden erfolgreich an {{name}} versendet!',
    leadSuccess: 'Dein Kontakt wurde digital übermittelt!',
    leadSaveSuccess: 'Erfolgreich gespeichert',
    leadError: 'Fehler beim Speichern.',
    deleteLead: 'Kontakt löschen',
    deleteLeadSuccess: 'Kontakt gelöscht.',
    deleteLeadError: 'Fehler beim Löschen.',
    deleteLeadMsg: 'Dein Kontakt wird gelöscht.',
    fileTypeNotSupported: 'Das Format wird nicht unterstützt.',
    uploadRestrictedTo: 'Bitte nur jpg, jpeg, png, gif Dateien hochladen.',
    maxUpload: 'Max. Uploadgröße: {{mb}} MB.',
    EditBioSuccess: 'Erfolgreich gespeichert.',
    editDetails: 'Details bearbeiten',
    profiles: 'Nutzerprofile',
    units: 'Units',
    unit: 'Unit',
    mainUnit: 'Haupt-Unit',
    subUnit: 'Unter-Unit',
    mainUnitTooltip:
      'Die Haupt-Unit ist entscheidend für Unit-spezifische Einstellungen. Werden bspw. für alle Units eigene Profildesigns festgelegt, so entscheidet die Haupt-Unit eines Accounts über dessen Profildesign.',
    otherUnits: 'Neben-Units',
    otherUnitsTooltip:
      'Accounts können mehreren Units zugeordnet sein. Neben der Haupt-Unit können also weitere Units festgelegt werden. Ein Unit-Admin kann immer sämtliche Profile in allen Units bearbeiten, in denen er:sie Mitglied ist.',
    unitsText:
      'Gruppiere Nutzer:innen in Units. Diese können durch Sub-Admins auch selbst verwaltet werden.',
    addUnit: 'Unit hinzufügen',
    editUnit: 'Unit bearbeiten',
    searchUnits: 'Suche Units',
    createUnit: 'Unit erstellen',
    noUnitData: 'Es sind aktuell noch keine Units vorhanden',
    guides: 'Erste Schritte',
    guideHeader: 'Los geht’s mit Lemontaps',
    guideSubHeader:
      'Hier findest Du eine Übersicht zu Guides und Hilfen, wie Du Lemontaps einrichten und benutzen kannst.',
    connectionsSubHeader:
      'Finde alle erfassten Kontakte/Leads aller Mitarbeiter:innen an einem Ort, welche mithilfe des Kontaktformulars unten im öffentlichen Profil erfasst wurden.',
    // MD Customization strings start
    companySettings: 'Firmen Einstellungen',
    globalCompanyData: 'Globale Firmen Daten',
    globalCompanyDataSub: 'Füge Standard Daten hinzu, die Du hier verwalten kannst. ',
    globalCompanyAddButton: 'Daten hinzufügen',
    organisationPageHeader: 'Organisation',
    filesPageHeader: 'Dateien',
    addressesPageHeader: 'Adressen',
    footerPageHeader: 'Footer',
    linksPageHeader: 'Links',
    qrCodePageHeader: 'QR-Code-Hintergründe',
    contactFormPageHeader: 'Kontaktformular',
    standardData: 'Datenvorlagen',
    emailTemplatesPageHeader: 'E-Mail Vorlagen',
    teamSettings: 'Team-Einstellungen',
    leadForm: 'Kontaktformular',
    customizationSub: 'Passe Deine Lemontaps Plattform genau nach Deine Bedürfnissen an.',
    customizeDesign: 'Design anpassen',
    organisation: {
      title: 'Organisation',
      name: 'Name Deiner Organisation',
      nameTooltip:
        'Der Name taucht bspw. in den E-Mail-Betreffs auf, oder im Titel des Browser Tabs.',
      nameRequired: 'Name der Organisation ist erforderlich.',
      nameFailed: 'Name der Organisation konnte nicht aktualisiert werden.',
      internalDesign: 'Internes Design der Plattform',
      internalDesignFailed: 'Aktualisierung des internen Designs fehlgeschlagen.',
      bannerUpgradeNow: 'Jetzt upgraden.',
      bannerText: 'Das Interne Design ist in Deinem aktuellen Paket nicht verfügbar. ',
      lightBackground: 'Logo für hellen Hintergrund:',
      lightBackgroundTooltip:
        'Das Lemontaps-Logo kann durch das Logo Eures Unternehmens ersetzt werden.',
      otherLightBackground: 'Andere Logo-Datei für dunklen Hintergrund hochladen:',
      logoLimit: 'nur .png mit transparentem Hintergrund, maximal {{x}}MB',
      primaryButtonColor: 'Primäre Buttonfarbe',
      sidebarColor: 'Farbe der Navigationsseitenleiste',
      sidebarColorText: 'Textfarbe der Navigationsseitenleiste',
      sidebarColorActive: 'Farbe des aktiven Status der Navigationsseitenleiste',
      sidebarColorActiveText: 'Textfarbe für aktiven Status der Navigationsseitenleiste',
      resetColors: 'Farben zurücksetzen',
    },
    unitSettings: {
      title: 'Unit Einstellungen',
      multipleUnits: {
        title: 'Zuweisung mehrerer Units',
        description:
          'Erlaube die Zuweisung eines Accounts zu mehreren Units. Eine der zugewiesenen Units muss als Haupt-Unit festgelegt sein. Die Einstellungen dieser Haupt-Unit sind entscheidend für das Verhalten des Kontos (Profildesign, Bearbeitungsrechte etc.). Wenn diese Einstellung deaktiviert ist, kann jeder Account nur einer Unit zugewiesen werden.',
      },
    },
    setEditRights: 'Rechte anpassen',
    profileDesign: 'Profil Design',
    profileDesignSub:
      'Gestalte selbst das Design der digitalen Profile - entscheide über Farben, Formen und Bilder.',
    profileDesignInfo: {
      unitAdmin:
        'Passe die digitalen Profile in Deiner Unit entsprechend eurem Corporate Design an. Die Einstellungen, die Du in diesem Abschnitt wählst, werden auf alle Profile in Deiner Unit angewendet.',
      themeAdmin: {
        global:
          'Passe alle digitalen Profile entsprechend Eures Corporate Designs an. Die Einstellungen, die Du in diesem Abschnitt wählst, werden auf alle digitalen Profile angewendet, die keine Unit-spezifischen Einstellungen haben.',
        unit: 'Passe alle digitalen Profile in der ausgewählten Unit entsprechend ihrem Corporate Design an. Die Einstellungen, die Du in diesem Abschnitt wählst, werden auf alle digitalen Profile in dieser Unit angewendet.',
      },
    },
    boxForm: 'Boxen-Form',
    rounded: 'Rund',
    squared: 'Eckig',
    buttonColors: 'Button-Farben',
    AdditionalImages: 'Zusätzliche Titelbilder',
    strSaveContact: '“Kontakt speichern”',
    genericWebsite: 'Generischer Webseitenlink',
    images: 'Bilder',
    Oops: 'Ups! Etwas lief schief!',
    anErrorHasOccured:
      'Es ist ein unerwarteter Fehler aufgetreten, wir bitten das zu entschuldigen. Wenn der Fehler weiterhin auftritt, wende Dich bitte an unser Support-Team. Du kannst zur Startseite gehen, indem Du auf die Schaltfläche "Zur Startseite" klickst. Falls Du uns etwas im Zusammenhang mit dieser Fehlermeldung mitteilen möchtest, dann klicke auf "Kontaktiere uns", um eine Nachricht an unser Support-Team zu senden.',
    goHome: 'Zur Startseite',
    message: 'Gib hier Deine Nachricht an unser Support-Team ein',
    submitText: 'Absenden',
    errorMessageSentSuccess:
      'Wir haben Deine Nachricht erhalten und werden uns schnellstmöglich darum kümmern.',
    more: 'mehr',
    less: 'weniger',
    defaultCoverImage: 'Standard-Titelbild',
    uploadCoverImage: 'Titelbild hochladen',
    uploadFurtherCoverImage: 'Weiteres Titelbild hochladen',
    uploadMoreImages: 'Lade Titelbilder hoch, aus denen die Mitarbeiter:innen auswählen müssen',
    defaultAvatar: 'Standard Profilbild',
    logo: 'Logo',
    uploadLogo: 'Logo hochladen',
    policies: '4. Bestimmungen',
    linkToImprint: 'Link zum Impressum',
    linkToPrivacyPolicy: 'Link zu den Datenschutzbestimmungen',
    nameOfYourCompany: 'Name Deiner Firma',
    addCopyRight: 'Copy-Right hinzufügen?',
    allRightsReserved: '(© @year [Deine Firma]. Alle Rechte vorbehalten)',
    allRightsReservedPublic: 'Alle Rechte vorbehalten',
    editRightsL: 'Bearbeitungsrechte für Mitarbeiter:innen',
    unitsGlobalSelect: 'Global (Standard)',
    unitsSelectLabel: 'Unit wählen',
    profilesSelectLabel: 'Profil wählen',
    editRights: {
      title: 'Bearbeitungsrechte',
      mainHeading: 'Bearbeitungsrechte für Mitarbeiter:innen',
      unitHeading: 'Bearbeitungsrechte für Unit-Admins',
      mainTabLabel: 'Für Mitarbeiter:innen',
      unitTabLabel: 'Für Unit-Admins',
      unitOverrideLabels: {
        profileDesign: {
          label: 'Profildesign',
        },
        editRights: {
          label: 'Bearbeitungsrechte',
        },
        userDefinedTranslations: {
          label: 'Übersetzungen',
          subsettings: {
            heading: 'Übersetzungsrechte',
            subheading:
              'Erlaube Unit-Admins die Bearbeitung bestimmter Übersetzungspakete. Achtung: Unit-Admins können dann die Übersetzungen der gesamten Organisation in dieser Kategorie bearbeiten. Jede Änderung, die sie machen, wird die gesamte Organisation betreffen.',
            labels: {
              'profile--links': 'Links',
              'profile--files': 'Dateien',
              'profile--system-labels': 'Systembeschriftungen',
              'profile--position': 'Positionen',
              'profile--role': 'Rollen',
              'profile--division': 'Abteilungen',
              'profile--bio': 'Bio Texte',
              'profile--addresses': 'Adressen',
              'profile--firstName': 'Vornamen',
              'profile--lastName': 'Nachnamen',
              'profile--company': 'Unternehmensnamen',
              'profile--homepage': 'Homepage URLs',
              'profile--other-contact-info': 'Weitere Kontaktinformationen',
              'profile--urls': 'URLs',
            },
          },
        },
      },
      fields: {
        detailsEditable: 'Kontaktinformationen',
        profileImageEditable: 'Profilbild',
        bannerImageEditable: 'Profilhintergrundbild',
        logoImageEditable: 'Logo',
        linksEditable: 'Links',
        filesEditable: 'Dateien',
        allowOwnQrBgImage: 'Individuelle QR-Hintergründe',
        rebuyCards: 'Karten nachbestellen',
        singleUserCrmPush: 'Erlaube den Endbenutzer:innen, Kontakte ins CRM zu pushen',
        profileShare_uidParam: 'Zwischen Profil-Designs wechseln',
      },
      tooltips: {
        allowOwnQrBgImage:
          'Bestimme, ob Deine Mitarbeiter:innen eigene Bilder hochladen dürfen, um QR-Hintergründe zu erstellen.',
        rebuyCards:
          'Bestimme, ob Deine Mitarbeiter:innen das Kartennachbestellungsformular ausfüllen dürfen (auf der Seite unter "Profil Teilen" -> "NFC-Karte")',
        profileShare_uidParam:
          'Bestimme, ob Deine Mitarbeiter:innen zwischen den ihnen zu Verfügung stehenden Profil-Designs wechseln dürfen. Sie können dann auch das globale Standard-Design wählen.',
      },
      fieldsSubHeadings: {
        detailsEditable:
          'Du kannst hier pro Profilattribut festlegen, ob dieses von Deinen Mitarbeiter:innen bearbeitet werden darf. So kannst du verhindern, dass einzelne Attribute von deinen Mitarbeiter:innen verwaltet werden, während du die Bearbeitung anderer Attribute erlaubst.',
        linksEditable:
          'Du kannst hier entscheiden, welche Linkvarianten von Deinen Mitarbeiter:innen erstellt werden dürfen. Als Admin kannst Du weiterhin alle Varianten erstellen.',
      },
    },
    editRightsSub:
      'Entscheide welche Informationen Mitarbeiter:innen selbst verändern dürfen und welche nur die Admins.',
    editRightsInfo: {
      unitAdmin:
        'Welche Informationen können Mitarbeiter:innen ändern? Welche Informationen dürfen nur von den Administratoren geändert werden? Die Einstellungen, die Du in diesem Abschnitt wählst, werden auf alle Accounts in Deiner Unit angewendet.',
      themeAdmin: {
        global:
          'Welche Informationen können Mitarbeiter:innen ändern? Welche Informationen sollen nur von den Unit-Administratoren oder globalen Administratoren geändert werden? Die Einstellungen, die Du in diesem Abschnitt wählst, werden global auf alle digitalen Profile angewendet, die keine Unit-spezifischen Einstellungen haben.',
        unit: 'Lege spezifische Bearbeitungsrechte für die ausgewählte Unit fest.',
      },
    },
    editRightsBlockedFieldsTeaser:
      'Detaillierte Bearbeitungsrechte sind sind in Deinem aktuellen Paket nicht verfügbar.',
    blockedBioFieldsSubtext:
      'Du kannst hier pro Profilattribut festlegen, ob dieses von Deinen Mitarbeiter:innen bearbeitet werden darf. So kannst du verhindern, dass einzelne Attribute von deinen Mitarbeiter:innen verwaltet werden, während du die Bearbeitung anderer Attribute erlaubst.',
    blockedLinkTypesSubtext:
      'Du kannst hier entscheiden, welche Linkvarianten von Deinen Mitarbeiter:innen erstellt werden dürfen. Als Admin kannst Du weiterhin alle Varianten erstellen.',
    profilePreview: 'Vorschau Beispielprofil:',
    comingSoon: 'kommt bald',
    mdguide: {
      tasks: 'Aufgaben',
      pageHeader: 'Erste Schritte als Administrator',
    },
    sharingDesign: {
      title: 'QR-Code Design',
      shortTitle: 'QR-Code Design',
      wallet: {
        tab: 'Wallet',
        title: 'Wallet Design',
        colors: {
          backgroundColor: {
            title: 'Hintergrundfarbe',
          },
          foregroundColor: {
            title: 'Textfarbe',
            tooltip: 'Nur für Apple Wallet',
          },
          labelColor: {
            title: 'Labelfarbe',
            tooltip: 'Nur für Apple Wallet',
          },
        },
      },
      qrbg: {
        chooseImages: 'Bilder auswählen',
        defaultImage: 'Standardbild',
        additionalImages: 'Zusätzliche Bilder',
        mobile: {
          tab: 'Handyhintergrund',
          title: 'Mobile Hintergrundeinstellungen',
          helperText:
            'Jedes vertikale Format ist erlaubt mit einer Mindestbreite von 500px. Wir empfehlen das Format des am häufigsten verwendeten Telefons in Deinem Team.',
        },
        videocall: {
          tab: 'Videohintergrund',
          title: 'Video Hintergrundeinstellungen',
          helperText:
            'Jedes horizontale Format ist erlaubt mit einer Mindestbreite von 800px. Wir empfehlen das Format für virtuelle Hintergründe des von Dir verwendeten Videokonferenz-Tools.',
        },
        dialogText:
          'Bist Du sicher, dass Du alle Bilder zurücksetzen willst?\n\nDas kann nicht rückgängig gemacht werden!',
        uploadSubtext: 'SVG, PNG, JPG oder GIF (beliebiges Format, Mindestbreite: {{limit}}px)',
      },
    },
    accountGuides: {
      headerPage: 'Los geht’s mit Deinem Lemontaps-Profil',
      subHeader:
        'Hier findest Du eine Übersicht aus Guides und Hilfen, wie Du Lemontaps einrichten und benutzen kannst.',
      guidesHeader: 'Schritt-für Schritt Erklärung zum Durcklicken',
      videoGuides: {
        header: 'Videoerklärungen',
      },
      openQuestions: {
        header: 'Weitere Fragen?',
        takeALook: 'Erkunde häufig gestellte Fragen',
        takeALookLink: 'Zur Wissensdatenbank',
        writeToSupport: 'Schreibe unserem Support-Team.',
        writeToSupportLink: 'Support Ticket erstellen',
      },
    },
    mdAnalytics: {
      title: 'Analytics',
      profileSavings: 'Daraus resultieren folgende Einsparungen',
      networking: {
        title: 'Profilaktivität',
        subtitle: 'Erfahre, wie Dein Team seine Karten benutzt.',
        shares: 'Profile geteilt',
        vcards: 'vCards gespeichert',
        contacts: 'Erfasste Kontakte',
        edits: 'Bearbeitungen',
        links: 'Link Klicks Insgesamt',
        files: 'Datei Klicks Insgesamt',
      },
      tooltips: {
        shares: 'Anzahl der Ansichten aller Deiner Unternehmensprofile',
        contacts: 'Anzahl aller eingesammelten Kontakte Deiner Unternehmenaccounts',
        vcards:
          'So oft wurde der "Kontakt speichern" Button auf allen Deiner Unternehmensprofilen bereits geklickt.',
        links:
          'Anzahl an Momenten, in denen ein Link einer Deiner Unternehmensaccounts geöffnet wurde.',
        files:
          'Anzahl an Momenten, in denen eine Datei einer Deiner Unternehmensaccounts geöffnet wurde.',
        cards:
          'Anzahl der Papierkarten, die dafür eingespart wurden, dass Du Dein Profil digital geteilt hast oder einen digitalen Kontakt eingesammelt hast.',
        flyers:
          'Anzahl der Papierseiten, die dadurch eingespart wurden, dass Du anstatt eines Papier-Ausdrucks eine digitale Version genutzt hast (bspw. PDF-Dateien statt Papier-Flyer).',
        edits:
          'Als eine Profilbearbeitung zählt jede Änderung in einem Profil deines Teams (Kontaktinformationen, Bilder, Dateien oder Links). Pro 7-Tage Periode wird höchstens eine Bearbeitung gezählt.',
      },
      saves: {
        title: '🌱 Dein Unternehmen ist ein nachhaltiger Leader, bis jetzt habt ihr schon gespart:',
        cards: 'Papierkarten',
        flyers: 'Flyerseiten',
        subtitle: '... und damit:',
        wood: '🪵 Holz',
        water: '💧 Wasser',
        energy: '🔋 Energie',
        co2: '☁️ CO2',
      },
    },
    addressesTab: {
      header: 'Standard Adressen',
      headerTooltip:
        'Definiere hier Adressen, aus denen Du später wählen kannst, wenn Du neue Profile anlegst.',
      addAddresses: 'Adresse hinzufügen',
      addNewAddress: 'Adresse hinzufügen',
      makeAddressDefault: 'Soll diese Adresse als Standard für neue Profile gelten?',
      addressDefaulTooltip:
        'Wenn neue Profile erstellt werden, werden ihnen diese Adresse per default zugeordnet.',
      default: 'Default',
      confirmDelete: 'Bist Du sicher, dass Du die Adresse löschen möchten?',
      ErrorDeletingAddress: 'Fehler beim Löschen der Adresse',
      cannotDeleteDefaultAddress: 'Du kannst die Standardadresse nicht löschen.',
      errorReadAddress: 'Adressen konnten nicht geladen werden',
      orCreateIndividual: 'oder individuelle Adresse anlegen:',
      errorWriteAddress: 'Mindestens ein Adressfeld erforderlich!',
      errorWriteLabel: 'Die Adresse muss benannt werden!',
      saveSuccess: 'Erfolgreich gespeichert',
      noAddresses: 'Noch keine Adressen vorhanden',
      emptyAddressFieldsError: 'Fülle mindestens ein Feld aus',
    },
    qrBgTab: {
      defaultBackgrounds: 'Standard Hintergründe',
      defaultMobileBg: 'Standard-Bild für QR-Handysperrbildschirm:',
      defaultVideoCallBg: 'Standard-Bild für QR-Videocallhintergrund:',
      minBgSize: 'Bildbreite: mind. {{x}}px',
      uploadBg: 'Bild hochladen',
      additionalBgImgs: 'Zusätzliche Hintergründe',
      additionalBgImgsDescription:
        'Hochladen weiterer Bilder, aus denen die Nutzer:innen wählen können',
      additionalMobileBgs: 'Weitere Handyhintergründe:',
      additionalVideoCallBgs: 'Weitere Videocallhintergründe:',
    },
    linkTab: {
      header: 'Linkvorlagen',
      headerTooltip:
        'Definiere hier Links, aus denen Du später auswählen kannst, wenn Du Links zu den Profilen Deiner Mitarbeiter:innen hinzufügst.',
      addLink: 'Hinzufügen ',
      addNewLink: 'Einen neuen Link hinzufügen',
      name: 'Titel',
      linkType: 'Link Typ',
      linkName: 'Link Name',
      linkPhone: 'Telefonnummer',
      linkUrl: 'URL',
      placeHolderUrl: 'z.B. www.website.com',
      placeHolderLink: 'z.B. Unsere Website',
      placeHolderPhone: 'z.B. 49 170 12345',
      searchStandardLinks: 'Linkvorlage suchen',
      noTemplates: 'Noch keine Linkvorlage erstellt',
      createTemplate: 'Jetzt Linkvorlage erstellen',
      deleteLink: 'Link löschen',
      confirmDelete: 'Bist Du sicher, dass Du den Link löschen möchtest?',
      yesDelete: 'Ja, löschen.',
      successAdd: 'Hinzugefügt',
      successEdit: 'Gespeichert',
      errorAdd: 'Fehler',
      errorEdit: 'Fehler',
      successDelete: 'Gelöscht',
      errorDelete: 'Error',
      orAddIndividualLink: 'oder individuellen Link anlegen:',
    },
    rename: 'Umbenennen',
    locationSelector: {
      header: 'Standardadresse auswählen',
      max2Label: '(max. 2 Adressen können angelegt werden)',
      orCreateIndividual: 'oder individuelle Adresse anlegen:',
      searchAddress: 'Adresse suchen',
      noTemplates: 'Noch keine Adressvorlage erstellt',
      createTemplate: 'Jetzt Adressvorlage erstellen',
    },
    employeeType: {
      role: 'Rolle im Unternehmen',
      employee: 'Mitarbeiter:in',
      themeAdmin: 'Administrator:in',
      unitAdmin: 'Unit-Admin',
      edit: 'edit',
      sendInviteText:
        'Beim Speichern automatisch den Zugang an die neue Email senden? Du kannst dies auch später manuell machen.',
      profile: 'Progil',
      account: 'Account',
      emailExisted: 'Die E-Mail existiert bereits!',
    },
    errorSharingData: 'Fehler beim Teilen von Daten',
    errorLoad: 'Fehler beim Laden von Kontakten',
    errorLoadMore: 'Fehler beim Laden weiterer Kontakte',
    downloading: 'Herunterladen',
    successfullyDownloaded: 'Erfolgreich heruntergeladen',
    errorWhenDownloading: 'Fehler beim Herunterladen',
    filesTab: {
      header: 'Dateivorlagen',
      headerTooltip:
        'Definiere hier Dateien, aus denen Du dann später wählen kannst, wenn Du Dateien zu den Profilen Deiner Mitarbeiter:innen hinzufügst.',
      fileName: 'Titel',
      fileNamePlaceHolder: 'z.B. One-Pager',
      uploadImage: 'Datei hochladen',
      errorUploadingFile: 'Fehler beim Hochladen der Datei',
      successfullySaved: 'Datei erfolgreich gespeichert',
      successfullyDeleted: 'Datei erfolgreich gelöscht',
      writingDataError: 'Fehler beim Speichern der Datei',
      selectFileTitle: 'Linkvorlage  auswählen',
      searchInputPlaceHolder: 'Dateivorlage suchen...',
      noTemplates: 'Noch keine Dateivorlage erstellt',
      createTemplate: 'Jetzt Dateivorlage erstellen',
      orAddIndividualFile: 'oder individuelle Datei anlegen:',
      savingFile: 'Datei wird gespeichert',
      savingChanges: 'Änderungen werden gespeichert',
      deletingFile: 'Datei wird gelöscht',
      deleteFile: 'Datei löschen',
      yesDelete: 'Ja, löschen',
      confirmDelete: 'Bist Du sicher, dass Du diese Datei löschen möchtest?',
      uploadFile: 'Datei hochladen',
    },

    // MD Customization strings end

    //How To Tap Strings Start//
    HowToTap: 'So teilt man per Berührung',
    HowToTapDescription: 'Wähle das Gerät aus, mit welchem Du Dein Profil teilen möchtest.',
    important: 'Wichtig!',
    BackupDescriptionHeading: 'Du hast immer ein Backup',

    TapNewIphoneHeading: 'Neuere iPhones “tappen”',
    TapNewIphoneSubHeading: 'iPhone XR und neuer',
    TapNewIphoneDetailHeading: 'Neuere iPhones “tappen”',
    TapNewIphoneDetailSubHeading: 'iPhone XR, XS, 11, 12, 13',
    TapNewIphoneDetailDescription:
      'Um Dein Profil mit neueren iPhones zu teilen, wische und berühre Deine Lemontaps Karte entlang des oberen Randes bis eine Push-Benachrichtigung erscheint.',
    TapNewIphoneDetailImportantDescription:
      'Dein Bildschirm muss eingeschaltet, Dein Flugmodus ausgeschaltet und Deine Kamera darf nicht geöffnet sein.',
    TapNewIphoneDetailBackupDescription:
      'Das “Tappen” erfordert etwas Übung und es kann auch mal vorkommen, dass es nicht direkt funktioniert. Keine Sorge - Du kannst jederzeit den QR-Code verwenden.',

    TapOlderIphoneHeading: 'Ältere iPhones “tappen”',
    TapOlderIphoneSubHeading: 'iPhone X und älter',
    TapOlderIphoneDetailHeading: 'Ältere iPhones “tappen”',
    TapOlderIphoneDetailSubHeading: 'iPhone 6, 7, 8, X',
    TapOlderIphoneDetailDescription:
      'Hier ist das NFC nicht automatisch aktiviert. Dafür muss der:die Besitzer:in das Kontrollzentrum hoch swipen und auf den NFC-Button klicken. Wische jetzt die Karte entlang des oberen Randes des iPhones:',
    TapOlderIphoneDetailImportantDescription:
      'Manchmal muss der NFC button erst in den Einstellungen einmalig aktiviert werden, bevor dieser im Kontrollzentrum erscheint. Außerdem muss Dein Bildschirm eingeschaltet, Dein Flugmodus ausgeschaltet und Deine Kamera darf nicht geöffnet sein.',
    TapOlderIphoneDetailBackupDescription:
      'Das “Tappen” erfordert etwas Übung und es kann auch vorkommen, dass es mal nicht direkt funktioniert. Keine Sorge - Du kannst jederzeit den QR-Code verwenden.',

    TapAndroidHeading: 'Androids “tappen”',
    TapAndroidSubHeading: 'NFC muss aktiviert sein',
    TapAndroidDetailHeading: 'Androids “tappen”',
    TapAndroidDetailSubHeading: 'Jedes Android mit NFC (fast alle)',
    TapAndroidDetailDescription:
      'Der NFC Leser kann sich bei Androids an unterschiedlichen Stellen befinden. Bewege deswegen Deine NFC-Karte von oben nach unten entlang der Rückseite:',
    TapAndroidDetailImportantDescription:
      'Frage Dein Gegenüber, ob dieser sein NFC aktiviert hat. Um NFC zu aktivieren, wische die “Quick Settings” runter und drücke auf den NFC Button.',
    TapAndroidDetailImportantDescription2:
      'Manchmal musst Du in den Telefoneinstellungen nach “NFC” suchen.',
    TapAndroidDetailBackupDescription:
      'Das “Tappen” erfordert etwas Übung und es kann auch vorkommen, dass es mal nicht direkt funktioniert. Keine Sorge - Du kannst jederzeit den QR-Code verwenden.',

    TapScanHeading: 'QR-Code scannen',
    TapScanSubHeading: 'Alle Handys',
    TapScanDetailHeading: 'QR-Code scannen',
    TapScanDetailSubHeading: 'Alle iPhone und Android Handys',
    TapScanDetailDescription:
      'Du findest Deinen QR-Code im Lemontaps Home Dashboard oder auf Deiner Karte aufgedruckt. Die meisten Geräte können den QR-Code einfach mit der Kamera einscannen.',
    TapScanDetailImportantDescription:
      'Du kannst Dir einen Handy- oder Videocallhintergrund mit Deinem QR-Code erstellen, um Deine Lemontaps-Visitenkarte immer dabei zu haben.',
    // How to Tap Strings End //
    contactShareFile: '_Profil',
    contactShareTitle: 'Kontakt',
    contactShareText: 'Digitaler Kontakt von',
    leaveYourContact: 'Hinterlasse Deinen Kontakt',
    submit: 'Jetzt vernetzen',
    profileForwardNotice: 'Hinweis: Dein Profil wird zurzeit direkt auf {{url}} weitergeleitet.',
    downloadContactFile: 'Kontaktdatei herunterladen',
    md: {
      requestNow: 'Jetzt anfragen',
      requestNowBullets: [
        'Keine Kreditkarte oder Kündigung erforderlich.',
        'Keine Installation notwendig',
      ],
      profiles: {
        sidebar: {
          tabs: {
            tooltipProfile:
              'Hier kannst Du die Profilinformationen dieses Accounts bearbeiten (Name, Telefonnummern, Adressen, etc.).',
            tooltipAccount: 'Hier kannst Du die Login-Email und Rolle bearbeiten.',
            tooltipCards: 'Hier kannst Du Karten zu diesem Account hinzufügen oder entfernen.',
          },
        },
        error: {
          deleteMainAccount: 'Der Team-Owner kann nicht gelöscht werden.',
        },
      },
      vcardConfigurator: {
        title: 'vCard Konfigurator',
        description:
          'Die vCard ist die Kontaktdatei, die heruntergeladen wird, wenn auf „Kontakt speichern“ geklickt wird oder wenn der Offline-QR-Code geteilt wird. Du kannst einige der Einstellungen hier bearbeiten.',
        contactInfoTitle: 'Konfiguration der Kontaktinformationen',
        contactInfoDescription:
          'Entscheide, ob bestimmte Felder in der vCard ausgeblendet oder die Bezeichnung des Attributs geändert werden soll. Beachte, dass es vorkommen kann, dass die benutzerdefinierten Bezeichnungen teilweise nicht angezeigt werden (insbesondere bei Android oder Outlook). Sie werden mit Standardlabel wie z.B. "Sonstige" zufällig ersetzt.',
        chooseLabel: 'Bezeichnung auswählen',
        createLabel: 'Bezeichnung erstellen',
        editLabel: 'Bezeichnung bearbeiten',
        createCustomLabel: 'Benutzerdefinierte Bezeichnung erstellen',
        customLabelGerman: 'Bezeichnung (Deutsch)',
        customLabelEnglish: 'Bezeichnung (Englisch)',
        customLabelInfo: 'Setze eine beliebige Bezeichnung oder wähle aus den Vorlagen.',
        notes: 'Notizen',
        notesGerman: 'Notizen (Deutsch)',
        notesEnglish: 'Notizen (Englisch)',
        notesTooltip:
          'Konfiguriere einen benutzerdefinierten Text, der im Abschnitt "Notizen" des heruntergeladenen Kontakts enthalten sein wird. Du kannst "{{currentDate}}" als Variable verwenden, die beim Herunterladen der vCard durch das aktuelle Datum ersetzt wird.',
        additionalSettings: 'Zusätzliche Einstellungen',
        showLinks: 'Alle Profillinks anzeigen',
        showLinksTooltip: 'Zeige die Profillinks in den heruntergeladenen Kontakten an.',
        showLinksOffline: 'Auch in Offline QR-Code alle Profillinks anzeigen',
        showLinksOfflineTooltip:
          'Nur empfohlen, wenn ihr nicht viele Links auf den Profilen habt, da der QR-Code sonst schwerer lesbar wird.',
        convertSpecialChars: 'Sonderzeichen in vCard konvertieren',
        convertSpecialCharsTooltip:
          'Ersetze Sonderzeichen wie ä, ö, ü mit ae, oe, ue, da manche Outlook Versionen die Sonderzeichen beim Import nicht lesen können.',
        samelLabelOutlookWarning:
          'Um maximale Kompatibilität zu erreichen, sollten dieselben Attribute unterschiedliche Bezeichnungen haben, da es insbesondere bei Outlook  zu fehlerhaften Imports kommen kann.',
        sameLabelEmailWarning:
          '{{fields}} haben dieselbe Bezeichnung oder beide eine benutzerdefinierte Bezeichnung. Der Import in Outlook schlägt möglicherweise fehl!',
        sameLabelAddressWarning:
          '{{fields}} haben dieselbe Bezeichnung oder beide eine benutzerdefinierte Bezeichnung. Beim Import in Outlook wird möglicherweise nur eine der beiden Adressen angezeigt!',
        sameLabelLinkWarning:
          '{{fields}} haben dieselbe Bezeichnung oder beide eine benutzerdefinierte Bezeichnung. Beim Import in Outlook wird möglicherweise nur einer der Links angezeigt!',
        show: 'Feld "{{field}}" in vCard anzeigen',
        doNotShow: 'Feld "{{field}}" nicht in vCard anzeigen',
      },
      defaultContent: {
        defaultLinks: 'Standard-Links',
        defaultLink: 'Standard-Link',
        defaultFiles: 'Standard-Dateien',
        defaultFile: 'Standard-Datei',
        description: 'Hier kannst Du die Standardinhalte für Deine Mitarbeiter:innen festlegen.',
        errorLoadingFiles: 'Fehler beim Laden der Standarddateien',
        successSaveFile: 'Dateien erfolgreich gespeichert',
        errorSavingFiles: 'Fehler beim Speichern der Dateien',
        successDeleteFile: 'Datei erfolgreich gelöscht',
        errorDeleteFile: 'Fehler beim Löschen der Datei',
        errorLoadingLinks: 'Fehler beim Laden der Standardlinks',
        successSaveLink: 'Links erfolgreich gespeichert',
        errorSavingLink: 'Fehler beim Speichern der Links',
        successDeleteLink: 'Link erfolgreich gelöscht',
        errorDeleteLink: 'Fehler beim Löschen des Links',
      },
      contentTemplate: {
        linkTemplates: 'Linkvorlagen',
        fileTemplates: 'Dateivorlagen',
        addressTemplates: 'Addressvorlagen',
        description:
          'Erstelle Inhaltsvorlagen für Adressen, Links und Dateien, aus denen Du als Administrator:in bei der Bearbeitung von Profilen auswählen kannst. Wenn Du die Vorlage aktualisierst, erhalten alle Profile, die diese Vorlage verwenden, diese Aktualisierung. ',
        errorLoadingFiles: 'Fehler beim Laden der Dateivorlagen',
        successSaveFile: 'Dateivorlage erfolgreich gespeichert',
        errorSavingFile: 'Fehler beim Speichern der Dateivorlage',
        successDeleteFile: 'Dateivorlage erfolgreich gelöscht',
        errorDeleteFile: 'Fehler beim Löschen der Dateivorlage',

        errorLoadingLink: 'Fehler beim Laden der Linkvorlagen',
        successSaveLink: 'Linkvorlage erfolgreich gespeichert',
        errorSavingLink: 'Fehler beim Speichern der Linkvorlage',
        successDeleteLink: 'Linkvorlage erfolgreich gelöscht',
        errorDeleteLink: 'Fehler beim Löschen der Linkvorlage',

        errorLoadingAddress: 'Fehler beim Laden der Adressvorlagen',
        successSaveAddress: 'Adressvorlage erfolgreich gespeichert',
        errorSavingAddress: 'Fehler beim Speichern der Adressvorlage',
        successDeleteAddress: 'Adressvorlage erfolgreich gelöscht',
        errorDeleteAddress: 'Fehler beim Löschen der Adressvorlage',
      },
      profileDesign: {
        previewTooltip: 'Speicher zuerst deine Änderungen und klicke dann auf Vorschau',
        additionalCoverImagesSize: 'Empfohlenes Format: 700px x 400px',
        additionalLogoImagesSize: 'Beliebiges Bildformat. Empfohlen: 220px x 100px',
        freeToChoose: 'Aktuelle Einstellung: Mitarbeiter:innen dürfen frei Titelbilder hochladen',
        boxFormTooltip: 'Lege das Erscheinungsbild aller rechteckigen Elemente fest.',
        uploadImageTooltip:
          'Wenn keine Bilder hochgeladen werden, können die Mitarbeiter:innen ihr Titelbild frei wählen. Bei Upload von einem oder mehreren Bildern werden Deine Mitarbeiter:innen eine Auswahl dieser Bilder sehen, von denen sie eines auswählen müssen. Dann kann kein Bild mehr frei hochgeladen werden.',
        buttonColorSaveContactTooltip:
          'Farbe des "Kontakt speichern" und des "Jetzt vernetzen" Buttons.',
        buttonColorFilesTooltip: 'Farbe der Datei Boxen.',
        buttonColorGenericWebsitePicker: 'Farbe der generischen Website-Button.',
        coverImageTooltip:
          'Legt das Standard-Titelbild fest, wenn kein individuelles Titelbild für den Benutzer hochgeladen wurde. Du kannst auch festlegen, dass kein Titelbild angezeigt werden soll. Beim Aktualisieren des Standard-Titelbilds werden nur die Profile ohne individuelles Titelbild aktualisiert.',
        profileImageTooltip:
          'Legt den Standard-Avatar fest, wenn kein individuelles Profilbild für den Benutzer hochgeladen wurde. Du kannst auch entscheiden, dass kein Avatar angezeigt werden soll. Beim Aktualisieren des Avatars werden nur die Profile ohne individuelles Profilbild aktualisiert.',
        logoImageTooltip:
          'Wenn diese Option leer ist, können Nutzer:innen ein selbst ausgewähltes Bild als Logo hochladen. Sobald diese Option durch ein Bild gesetzt ist, wird dieses Bild bei jedem Profil als Logo dargestellt, und Nutzer:innen können kein eigenes Bild mehr hochladen.',
        showForm:
          'Wenn Du diese Funktion deaktivierst, wird das Kontakterfassungsformular im digitalen Visitenkartenprofil nicht mehr angezeigt.',
        showFormAsPopup:
          'Wenn Du diese Funktion aktivierst, dann wird beim öffnen Deines digitalen Visitenkartenprofils das Kontakterfassungsformular direkt als erstes in Form eines Popups angezeigt. Diese Funktion ist hilfreich auf Events, um schnell und einfach die Kontaktinformationen des Gegenübers zu erfassen.',
        themeLinks: {
          tooltip:
            'Die hier definierten Links werden auf allen Profilen Deiner Mitarbeiter:innen angezeigt ohne dass diese sie bearbeiten oder löschen können',
          title: 'Position Standardlinks',
          question: 'Konfiguriere hier Deine Standardlinks',
        },
        themeLinksPosition: {
          before: 'Vor den individuellen Links',
          after: 'Nach den individuellen Links',
          question: 'Wo sollen die Standardlinks angezeigt werden?',
        },
        themeFiles: {
          tooltip:
            'Die hier definierten Dateien werden auf allen Profilen Deiner Mitarbeiter:innen angezeigt ohne dass diese sie bearbeiten oder löschen können',
          title: 'Position Standarddateien',
          question: 'Konfiguriere hier Deine Standarddateien',
        },
        themeFilesPosition: {
          before: 'Vor den individuellen Dateien',
          after: 'Nach den individuellen Dateien',
          question: 'Wo sollen die Standarddateien angezeigt werden?',
        },
        linksTooltip: 'Du kannst Linkvorlagen setzen unter Einstellungen -> Globale Firmen Daten',
        filesTooltip: 'Du kannst Dateivorlagen setzen unter Einstellungen -> Globale Firmen Daten',
        additionalLogosTitle: 'Zusätzliche Logos',
        additionalLogosTooltip:
          'Wenn keine Bilder hochgeladen werden, können die Mitarbeiter:innen ihr Logo frei wählen. Bei Upload von einem oder mehreren Bildern werden Deine Mitarbeiter:innen eine Auswahl dieser Bilder sehen, von denen sie eines auswählen müssen. Dann kann kein Bild mehr frei hochgeladen werden',
        additionalLogosDescription:
          'Lade Logos hoch, aus denen die Mitarbeiter:innen auswählen müssen',
        additionalLogosUploadButton: 'Logo hochladen',
        additionalLogosUploadFurtherButton: 'Weiteres Logo hochladen',
        additionalLogosCurrentSettings:
          'Aktuelle Einstellung: Mitarbeiter:innen können ihr Logo frei wählen',
        additionalLogosDefaultLogoIsSet:
          'Aktuelle Einstellung: Ein globales Logo wird auf jedem Profil angezeigt. Entferne das oben festgelegte Standardlogo, damit Du hier mehrere Logos hochladen kannst.',
      },
      customizationFooter: {
        mainTooltip:
          'Die hier einzutragenden Informationen werden im Footer des öffentlichen Profils angezeigt.',
      },
    },
    preview: 'Vorschau',
    home: 'Home',
    profile: 'Profil',
    contacts: 'Kontakte',
    nfc: {
      title: 'NFC-Karten',
      upgradeToContinue:
        'Leider kannst Du diese Aktion nicht während der Testphase auswählen. Bitte führe ein Upgrade durch, um fortzufahren.',
      noBoxThemeAdmin:
        'Kontaktiere uns um auf dieser Seite Optionen zu sehen, mit der Du NFC-Karten bestellen kannst.',
      noBoxUnitAdmin:
        'Dein Administrator hat ausgeschaltet, von dieser Seite aus neue Karten zu bestellen.',
      order: {
        title: 'NFC-Karten bestellen',
        imageAlt: 'Darstellung von physischen NFC-Karten',
        body: 'Bestelle kostenlose Musterkarten, bestelle weitere Karten mit dem bisherigem Design nach oder bestelle NFC-Karten mit einem neuen Design.',
        actionLabel: 'NFC-Karten bestellen',
        subaction: {
          sampleCards: {
            label: 'Musterkarten bestellen',
            tooltip:
              'Du kannst kostenlos Musterkarten bestellen mit einem Beispieldesign. Die Karten müssen nach dem Test zurückgesendet werden.',
          },
          firstTimeOrder: {
            label: 'Karten erstmalig bestellen',
            tooltip: 'Wenn sich dein Design ändern sollte oder du Karten zum ersten Mal bestellst.',
          },
          reorder: {
            label: 'Karten mit letztem Design nachbestellen',
            tooltip:
              'Wenn du bereits Karten bestellt hast, haben wir dein Design bereit. Du musst nur noch ein Bestellformular ausfüllen.',
          },
        },
        byCountryPopup: {
          title: 'Zielland',
          other: 'In ein anderes Land? Bitte kontaktiere support@lemontaps.com.',
        },
      },
      paperCardsBox: {
        title: 'Papiervisitenkartenbestellsystem',
        description:
          'Verwalte die Administratoreneinstellungen des Papiervisitenkartenbestellsystems, wie bspw. Freigabeprozesse.',
        imageAlt: 'Eine Papiervisitenkarte',
        buttonText: 'Zum Bestellsystem',
      },
    },
    nfcSample: {
      title: 'Musterkarten bestellen',
      subtitle:
        'Probiere unsere NFC-Karten aus. Die Musterkarten kannst Du kostenlos bestellen, wenn Du diese wieder zurücksendest.',
    },
    nfcFirstOrder: {
      title: 'Karten erstmalig bestellen',
      subtitle:
        'Als Teams Nutzer stehen Dir vergünstigte Kartenpreise zur Verfügung. Gestalte die Karten passend zu Deinem Corporate Design.',
    },
    nfcReorder: {
      title: 'Karten mit letztem Design nachbestellen',
      subtitle:
        'Hier hast Du die Möglichkeit einfach weitere Karten nachzubestellen mit demselben Design Deiner letzten Bestellung.',
    },
    settings: {
      main: 'Account-Einstellungen',
      more: 'Mehr',
      managementPlatform: 'Management Plattform',
      changePassword: 'Passwort ändern',
      changeEmail: 'Registrierungs-E-Mail ändern',
      help: 'Hilfe & Support',
      helpCaption: 'Hilfe-Center, Akademie, Support',
      openCookieSettings: 'Cookie-Einstellungen ändern',
      openShopSettings: 'Zu unserem Shop',
      openPaperCardForm: 'Papiervisitenkarte bestellen',
      mycards: 'Karten verwalten',
      changeLanguage: 'Account-Sprache ändern',
      selectLanguage: 'Sprache auswählen',
      paperCardOrder: 'Druckversion',
      paperCardOrderCaption: 'Papiervisitenkarten bestellen',
    },
    moreSettings: {
      title: 'Mehr',
      teamMangement: 'Team-Management',
      teamMangementCaption: 'Verwalte die digitalen Profile Deines Teams',
      analytics: 'Analytics',
      analyticsCaption: 'Profil- & Nachhaltigkeitsstatistiken',
      addApp: 'Homescreen-App',
      addAppCaption: 'Füge ein App-Icon auf dem Homescreen hinzu',
      accountSettings: 'Account-Einstellungen',
      accountSettingsCaption: 'Email oder Passwort ändern',
      nfcCards: 'NFC-Karten',
      nfcCardsCaption: 'Verbinde, verwalte, oder bestelle Karten',
      printCard: 'Papier-Visitenkarte',
      printCardCaption: 'Bestelle neue Papiervisitenkarten',
      signOut: 'Abmelden',
    },
    changeEmail: {
      title: 'Registrierungs-E-Mail ändern',
      currentEmail: 'Aktuelle E-Mail-Adresse',
      newEmail: 'Neue E-Mail-Adresse',
      notAllowed: {
        title: 'E-Mail kann nicht geändert werden',
        text: 'Du bist via {{idp}} authentifiziert und kann daher Deine E-Mail-Adresse hier nicht ändern. Wenn Du dich mit einer anderen Adresse registrieren möchtest, lösche diesen Account und registriere Dich erneut oder kontaktiere einen Administrator.',
      },
      error: {
        invalidFormat: 'Ungültiges Format',
        unchanged: 'E-Mail ist unverändert',
      },
    },
    homePage: {
      toAcademy: 'Zur Academy',
      digitalBusinessCard: 'Digitale Visitenkarte',
      shareProfile: {
        title: 'Profil teilen',
        copyToShare: 'Zum Teilen kopieren',
        actionTitle: 'Alle Optionen anzeigen',
        via: 'per Link, QR-Code, Wallet...',
      },
      addAppToPhone: {
        title: 'Homescreen-App',
        popupTitle: 'Füge Lemontaps als Homescreen-App hinzu',
        popupText:
          'Lemontaps ist eine Progressive Web App (PWA), die Du nicht aus dem App-Store herunterladen kannst. Stattdessen kannst Du den Anweisungen folgen, und ein Icon auf deinem Homescreen platzieren.',
        step1: 'Seite auf Handy öffnen',
        step2: 'Klicke auf das Symbol „Teilen“ oder „Mehr“ in Deinem Browser.',
        step3: 'Klicke auf „Zum Homescreen hinzufügen“ (oder ähnlich).',
      },
      nfcCard: {
        addAndManage: 'NFC-Karten',
        title: 'NFC-Karte',
        allAboutNFC: 'Alles rund um die NFC-Karte',
        manageCards: 'Karten verwalten',
        manageCardsSub: 'Verknüpfe neue Karten oder entferne sie.',
        instruction: 'Anleitung',
        instructionSub:
          'Lerne die best practices, wie man die Karte mit verschiedenen Handys “tapped”.',
        buyNew: 'Neue Karte kaufen',
        buyNewSub:
          'Gestalte die Karte mit Deinem Design. Erhältlich in Bambus, Metall und Kunststoff.',
        multiTitle: 'Entdecke unsere NFC-Karten',
        multiText:
          'Verknüpfe deine NFC-Karte oder gestalte eine neue Karten mit Deinem Design. Erhältlich in Bambus, recyceltem Kunststoff und Metall.',
        btnText: 'Zum Shop',
      },
      leads: {
        recentContacts: 'Neue Kontakte',
        title: 'Leads erfassen',
        manage: 'Zum Kontaktmanagement',
        previewForm: 'Lead-Formular auf meinem Profil ansehen',
      },
      analytics: {
        title: 'Analytics',
        profileViews: 'Profilaufrufe',
        leadsCollected: 'Erfasste Kontakte',
      },
      academy: {
        title: 'Erste Schritte & Academy',
        popupTitle: 'Die Lemontaps Academy ist in Deinem aktuellen Plan nicht verfügbar',
        popupText:
          'Die Lemontaps Academy ist eine Sammlung von Einführungs- und Best-Practice-Videos, beispielsweise zur Verwendung von Lemontaps auf Messen oder bei Einzelgesprächen.\nDiese Funktion ist ein Add-On und in Deinem aktuellen Plan nicht verfügbar.',
      },
      support: {
        title: 'Deine Kontaktperson',
        admin: 'Dein Admin',
        lemontapsSupport: 'Lemontaps Support Team',
      },
      quickActions: {
        title: 'Weitere schnelle Aktionen',
        addApp: 'App hinzufügen',
        nfcCards: 'NFC-Karten',
        analytics: 'Analytics',
        scanCard: 'Karte einscannen',
      },
      viewAll: 'Alle ansehen',
      learnMore: 'Mehr erfahren',
      hello: 'Hallo',
      addLemontapsAsAnApp: 'Lemontaps als Homescreen App hinzufügen',
      addAppToHomescreen:
        'Du kannst Lemontaps als App auf Ihren Homescreen hinzufügen. So hast Du immer einfachen Zugang zu Deinem Profil.',
      exampleForIphone: 'Beispiel für iPhone (Android ist ähnlich):',
      gotIt: 'Verstanden!',
      shareProfilePer: 'Profil teilen per...',
      addQrWallpaper: 'QR-Hintergründe erstellen',
      qrCodeWallpaper: 'QR-Code Hintergrund',
      subTitle:
        'Entdecke die verschiedenen Möglichkeiten, wie Du Dein Lemontaps-Profil nutzen kannst:',
      qrTitle: 'QR-Hintergrund',
      qrText: 'Teile Dein Lemontaps-Profil per Handy oder Videokonferenz',
      qrBtnText: 'Hintergründe erstellen',
      shareLink: 'Link teilen',
      shareYourLink: 'Teile Deinen Link',
      tip: '💡Tipp: ',
      tipText: 'Kopiere und füge den Link in Deine Email Signatur ein.',
      qrCode: 'QR-Code',
      profileViews: 'Analytics',
      overviewProfileViews: 'Überblick Profilansichten',
      total: 'Gesamt:',
      thisWeek: 'Diese Woche:',
      howToTap: ' Wie teile ich?',
      help: 'Hilfe',
      desktopSubTxt:
        'Entdecke die verschiedenen Möglichkeiten, wie Du Dein Lemontaps-Profil nutzen kannst:',
      copyLink: 'Profil-Link kopieren',
    },
    leadGen: {
      upgradeTitle: 'Jetzt upgraden!',
      upgradeText:
        'Hole Dir jetzt das Lead Generation Feature und sieh alle Deine gesammelten Kontakte!',
      upgradeBtnText: 'Mehr erfahren',
      upgradeBtnClickedText: 'Anfrage gesendet',
      noLeads: 'Keine Kontakte bisher gesammelt.',
      infoFromProfile: 'Informationen vom Profil',
      infoFromContact: 'Informationen vom Kontakt',
      lt2ltNotFound: 'Hoppla! Profil nicht gefunden 🙈',
      uploadNew: 'Neue Datei hochladen',
      businessCardTitle: 'Physische Visitenkarte',
      businessCardSubtitle: 'Lade ein Foto einer Papiervisitenkarte hoch',
      dontShowAgain: 'Nicht mehr anzeigen',
      connectToNfc: 'Mit NFC-Karte verbinden?',
    },
    showQr: {
      pageTitle: 'QR-Code',
      online: 'online',
      offline: 'offline',
      btnTxt: 'QR-Code herunterladen',
      cardHeadTxt: 'Tipp: ',
      cardDiscTxtOn: 'Benutze den QR-Code in Präsentationen.',
      cardDiscTxtOf:
        'Der Offline QR-Code funktioniert ohne Internet. Durch Scannen wird der Kontakt direkt geöffnet, nicht das Online-Profil.',
      downloadSuccess: 'Erfolgreich heruntergeladen',
      downloadError: 'Bitte aktualisiere die Seite und probiere es noch einmal',
      downloadQrBgsError: 'Du musst erst ein Standard-Bild in den Einstellungen festlegen',
      downloadQrBgsErrorMaxAllowed: 'Es können maximal {{max}} Profile ausgewählt werden.',
    },
    datePicker: {
      options: {
        title: 'Zeitraum wählen',
        today: 'Heute',
        pastWeek: 'Letzte Woche',
        pastMonth: 'Letzter Monat',
        past90Days: 'Letzte 90 Tage',
        pastYear: 'Letztes Jahr',
        allTime: 'Gesamter Zeitraum',
      },
      heading: {
        startDate: 'Startdatum',
        endDate: 'Enddatum',
      },
    },
    analytics: {
      options: {
        title: 'Periode wählen',
        today: 'Heute',
        pastWeek: 'Letzte Woche',
        pastMonth: 'Letzter Monat',
        past90Days: 'Letzte 90 Tage',
        pastYear: 'Letztes Jahr',
        allTime: 'Gesamter Zeitraum',
      },
      saves: {
        title: '🌱 Du bist ein:e nachhaltige:r Leader:in, bis jetzt hast Du schon gespart:',
        cards: 'Papierkarten',
        flyers: 'Flyerseiten',
        subtitle: '... und damit:',
        wood: '🪵 Holz',
        water: '💧 Wasser',
        energy: '🔋 Energie',
        co2: '☁️ CO2',
      },
      networking: {
        title: '{{firstName}}, Du bist ein:e echte:r Networker:in!',
        titleSmall: 'Deine Networking Statistik',
        shares: 'Profile geteilt',
        contacts: 'Leads erfasst',
        vcards: 'vCard gespeichert',
        edits: 'Bearbeitungen',
      },
      clicks: {
        unit_one: '{{count}} Klick',
        unit_other: '{{count}} Klicks',
        title: 'An die Details:',
        links: 'Link Klicks Insgesamt',
        files: 'Datei Klicks Insgesamt',
        oldFileClicks: 'Clicks auf alte Dateien',
        oldLinkClicks: 'Clicks auf alte Links',
      },
      tooltips: {
        vcards: 'So oft wurde der "Kontakt speichern" Button auf Deinem Profil geklickt.',
        edits:
          'Als eine Profilbearbeitung zählt jede Änderung in Deinem Profil (Kontaktinformationen, Bilder, Dateien oder Links). Pro 7-Tage Periode wird höchstens eine Bearbeitung gezählt.',
        contacts: 'So viele Kontakte hast Du erfasst.',
        shares: 'So oft hast Du dein Profil geteilt.',
        cards:
          'Anzahl der Papierkarten, die dafür eingespart wurden, dass Du Dein Profil digital geteilt hast oder einen digitalen Kontakt eingesammelt hast.',
        flyers:
          'Anzahl der Papierseiten, die dadurch eingespart wurden, dass Du anstatt eines Papier-Ausdrucks eine digitale Version genutzt hast (bspw. PDF-Dateien statt Papier-Flyer).',
      },
      share: {
        share: 'Teilen',
        dialogTitle: 'Teile deine Statistiken',
        title: 'Meine nachhaltigen Networking Statistiken',
        text: 'Mein Impact mit meiner digitalen und nachhaltigen Visitenkarte von Lemontaps:\n\n🎫   {{cards}} gesparte Papiervisitenkarten\n📃   {{flyers}} gesparte Flyer\n🪵   {{wood}}kg gespartes Holz\n💧   {{water}}l gespartes Wasser\n🔌   {{energy}}kWh gesparte Energie\n🌫️   {{co2}}kg gespartes CO2 \n\nWerde jetzt Teil der Networking Revolution und sei ein digitaler und nachhaltiger Vorreiter! Registriere dich jetzt selber kostenlos bei Lemontaps!',
      },
    },
    loginOrRegister: {
      title: 'Diese Karte ist mit keinem Visitenkartenprofil verbunden',
      connectNew: 'Neues Profil erstellen',
      connectExisting: 'Karte mit bestehendem Profil verknüpfen',
      linkAsAdmin: 'Als Administrator verknüpfen',
    },
    linkCode: {
      noProfilesFound: 'Keine Profile gefunden',
      searchBy: 'Suchen in',
      selectProfile: 'Profil auswählen',
      searchProfile: 'Profil suchen',
      close: 'Schließen',
      name: 'Name',
      editDate: 'Bearbeitungsdatum',
      createDate: 'Erstellungsdatum',
      sortBy: 'Sortieren nach',
      sortOrder: 'Sortierreihenfolge',
      asc: 'Aufsteigend',
      desc: 'Absteigend',
      filter: 'Filter',
      withoutNFC: 'Nur ohne verknüpfte Karte',
      successLinkTitle: 'Du hast die NFC-Karte erfolgreich mit dem ausgewählten Profil verknüpft.',
      successLinkSubTitle: 'Öffne jetzt die Kamera, um die nächste Karte zu scannen.',
      errorLinkTitle: 'Fehler beim Verknüpfen',
      tryAgain: 'Bitte versuche es noch einmal.',
      dontShowAgain: 'Nicht mehr anzeigen',
      confirmLinkText:
        'Bist Du sicher, dass Du das ausgewählte Profil mit der NFC-Karte verknüpfen möchten?',
      connectToNfc: 'Mit NFC-Karte verbinden?',
    },
    onboarding: {
      howShareProfile: 'So teilst Du Dein Profil:',
      howMany: 'Wie viele Mitarbeiter:innen sind in Deinem Unternehmen?',
      howManyPotentialUsers: 'Wie viele davon kommen als Lemontaps-Nutzer:innen infrage?',
      whyLemontaps: {
        title: 'Was ist dein Ziel mit Lemontaps?',
        personal: 'Für den persönlichen Gebrauch',
        company: 'Tests für mein Unternehmen/Team',
      },
      viaQR: 'via QR-Code',
      viaNFC: '...oder via NFC',
      slideVeryTop: 'Am oberen Rand berühren:',
      mustPressNFCbutton:
        'Hier muss die Besitzer:in erst das Kontrollzentrum hoch swipen und auf den NFC-Button klicken: ',
      androidGifText1: 'Frage, ob das NFC des Empfängers aktiviert ist',
      androidGifText2: 'Bewege Deine NFC-Karte von oben nach unten entlang der Rückseite',
      turnOnNFC: 'Wie NFC aktivieren',
      lastAdvice: 'Ein paar letzte Tipps',
      importForNFC: 'Wichtig für NFC:',
      lastAdviceAboutNFC: {
        pointOne: 'Der Bildschirm muss an sein',
        pointTwo: ' Flugmodus darf nicht an sein',
        pointThree: 'Die Kamera darf nicht geöffnet sein',
      },
      advice:
        'Das “Tappen” verlangt etwas Übung, aber keine Sorge wenn es mal nicht funktioniert, Du hast immer noch den QR Code als Backup. 🤗',
      congratsTitle: 'Glückwunsch, Du bist jetzt Bereit zum Netzwerken! 🎉',
      continue: 'Fortsetzen',
      welcome: `Willkommen - Erstelle jetzt Deine digitale Visitenkarte!`,
      addToWallet: {
        title: 'QR-Code zur Wallet hinzufügen',
        description:
          'Um Deine digitiale Visitenkarte zu teilen, füge den QR-Code zu Deiner Wallet hinzu. Wir haben Dir Deine Wallet auch nochmals per E-Mail gesendet.',
      },
      tellUs: 'Erzähle etwas über Dich',
      forPersonalize: 'für eine personalisierte Lemontaps-Experience',

      other: 'andere',
      selectCategory: 'Welche Kategorie beschreibt Dich am besten?',
      enterOtherCategory: 'Gib eine andere Kategorie ein',
      howDidYouhear: 'Wie hast Du von Lemontaps erfahren?',
      EnterOther: 'Andere eingeben',
      letsStart: `Los geht’s`,
    },
    'sales-manager': 'Verkaufsleiter:in',
    'field-representative': 'Außendienstmitarbeiter:in',
    hr: 'HR',
    procurement: 'Beschaffung',
    consultant: 'Berater:in',
    'c-level': 'C-Level',
    it: 'IT',
    freelancer: 'Freiberufler:in',
    marketing: 'Marketing',
    other: 'Andere',
    openCookieSettings: 'Cookie-Einstellungen',
    openShopSettings: 'Zu unserem Shop',
    showMore: 'Mehr anzeigen',
    showLess: 'Weniger anzeigen',
    optional: 'optional',
    publicProfile: {
      autoConnect: {
        heading: 'Kontakt erstellt',
        subheading: 'Füge nun Notizen hinzu.',
        button: 'Öffnen',
      },
      actionsBar: {
        buttonLabel: 'Zurück zur Plattform',
      },
      profileForced:
        'Wenn Du dein Profil teilst, leitet es gerade auf "{{url}}" weiter, da Du bei einem Deiner Links den Blitz aktiviert hast. Wenn du das nicht mehr möchtest, deaktiviere den Blitz bei dem entsprechenden Link.',
    },
    fieldRequired: 'Pflichtfeld',
    cards: {
      card: 'Karte "{{code}}"',
      connect: 'Verbinden',
      alert: {
        title: 'Flexibles Management Deiner Karten',
        subtitle:
          'Weitere Karten können jederzeit mit Deinem Profil verbunden, oder auch daraus entfernt werden. Entfernte Karten können mit neuen Profilen verknüpft werden.',
      },
      orderCard: 'Bestellen',
      reorderCard: 'Nachbestellen',
      connectCard: 'Karte verbinden',
      deletePopup: {
        title: 'Karte entfernen?',
        description:
          'Sobald die Karte entfernt ist, kann sie wieder mit einem beliebigen Profil verbunden werden.',
      },
      enterCodePopup: {
        title: 'Gib zum Verbinden den Code ein',
        description:
          'Hinweis: Dies ist nur eine Backup-Methode, wenn die Kamera nicht funktioniert. Um den Code zu erhalten, wende dich an support@lemontaps.com.',
      },
      scanCardPopup: {
        title: 'Scanne den QR-Code auf der Karte',
        description:
          'QR-Code wird nicht erkannt?\n\n1. Scanne den QR-Code mit Deiner normalen Handykamera\n2. Backup: Kontaktiere support@lemontaps.com und gib dann <0>den Code manuell ein</0>',
      },
      noCardsViewWithShop:
        'Mit Deinem Profil sind noch keine Karten verknüpft. Verbinde jetzt Deine erste Karte oder bestelle eine Karte in unserem Shop.',
      noCardsViewWithoutShop:
        'Mit Deinem Profil sind noch keine Karten verknüpft. Verbinde jetzt Deine erste Karte.',
      cardsHeader: 'Übersicht verknüpfter Karten',
      loadingCards: 'Lade Karten...',
      noCards: 'Keine Karten',
      connectANewCard: ' Neue Karte verknüpfen',
      cards: 'Karten',
      remove: 'entfernen',
      uploadingCode: 'Code prüfen',
      successUpload: 'Code verknüpft',
      errorUpload:
        'Code konnte nicht verknüpft werden. Prüfe den Code noch einmal. Er könnte auch bereits in Verwendung sein.',
      successDelete: 'Karte entfernt',
      errorDelete: 'Karte konnte nicht entfernt werden',
      codeDelete: 'Karte entfernen',
      areYouSureDelete: 'Möchtest Du die Karte wirklich entfernen?',
      headToolTip:
        'Es können beliebig viele Karten zu einem Profil hinzugefügt werden. Wenn die Karte entfernt ist, ist das Profil nicht mehr auf der Karte und die Karte kann mit einem neuen Profil verknüpft werden.',
      bottomToolTip:
        'Als Admin kannst Du die Karte verknüpfen per Scan des QR-Codes. Du kannst die Karte auch an den:die Mitarbeiter:in verteilen, diese:r kann sich einloggen, um die Karte mit seinem:ihrem Profil zu verknüpfen.',
      cardAlreadyConnected: 'Die Karte ist bereits mit diesem Profil verknüpft.',
      cardFrom: 'Karte vom',
      nodate:
        'Bei Karten, die vor dem 20.10.2022 verknüpft wurden, ist das Datum leider nicht verfügbar.',
      notValid: 'Ungültiger Code',
    },
    md2Phone: {
      popupStep1: 'Schritt 1: Scanne folgenden QR-Code mit Deinem Handy',
      popupStep2:
        'Schritt 2: Befolge nun die Anweisungen auf dem Handy und scanne den QR-Code der Karte. Der QR-Code ist 24h lang gültig.',
      title: 'Scanne den QR-Code der Karte',
      subTitle:
        'Die Karte wird dann automatisch mit dem Profil vom Management Dashboard verknüpft.',
      success:
        'Karte wurde erfolgreich mit dem Profil verknüpft.\n Bitte schließe die Seite, gehe zurück zum Management Dashboard und aktualisiere die Seite, um die Änderungen oder die neu verbundene Karte zu sehen!',
      noCodeToken:
        'Kein Code oder Token gefunden, bitte schließe die Seite und versuche es erneut.',
    },
    csvImport: {
      label: 'Bulk-Aktionen',
      add: 'Profile hinzufügen',
      update: 'Profile aktualisieren',
      delete: 'Profile löschen',
      toast: {
        added: 'Profile erfolgreich hochgeladen',
        backgroundImport:
          'Du erhälst eine E-Mail, sobald die Profile erstellt wurden. Das kann eine Weile dauern.',
        otherCards: 'Neue Karten bestellen',
        selectedCards:
          'Du hast während des .csv-Imports ausgewählt, @amount Karte(n) zu bestellen.',
        cardsMessage:
          'Diese werden im selben Design produziert wie bei Deiner letzten Bestellung. Du erhältst 1 Mal pro Quartal eine Rechnung aller neu bestellten Karten und Lizenzen.',
        addedThenError:
          'Profile wurden hochgeladen, aber das erneute Laden der Profile schlug fehl',
        updated: 'Profile erfolgreich aktualisiert',
        updatedThenError:
          'Profile wurden aktualisiert, aber das erneute Laden der Profile schlug fehl',
        deleted: 'Profile erfolgreich gelöscht',
        deletedThenError: 'Profile wurden gelöscht, aber das erneute Laden der Profile schlug fehl',
        exceedtotalLicensing0: 'Kontingent an Lizenzen überschritten',
        exceedtotalLicensing1: `Wenn Du weitere Profile anlegst, wirst Du die Anzahl Deiner bezahlten Lizenzen überschreiten.
          Du erhältst am Ende des Monats eine Rechnung per E-Mail für die neu hinzugefügten Lizenzen. Die Lizenzgebühren werden Dir nur anteilig zur restlichen Vertragslaufzeit berechnet.

          Willst Du trotzdem fortfahren?`,
        exceedtotalLicensing2: 'Aktuelles Lizenzkontingent',
        exceedtotalLicensing3: `Du hast während des .csv-Imports ausgewählt, @amount Karte(n) zu bestellen.
        Diese werden im selben Design produziert wie bei Deiner letzten Bestellung. Du erhältst 1 Mal pro Quartal eine Rechnung aller neu bestellten Karten und Lizenzen.

        Wenn Du weitere Profile hinzufügst, überschreitest Du die Anzahl Deiner bezahlten Lizenzen.
                  Für die neu hinzugefügten Lizenzen erhältst Du am Monatsende eine Rechnung per E-Mail. Die Lizenzgebühren werden Dir nur anteilig zur verbleibenden Vertragslaufzeit in Rechnung gestellt.

                  Möchtest Du trotzdem fortfahren?`,
        continue: 'Fortfahren',
        employeesLicenses: 'Mitarbeiterlizenzen',
      },
      errors: {
        header: 'Fehler beim Benutzerimport',
        length: {
          header: '-----------------\nBenutzer "{{user}}" hat folgende(n) Fehler: \n',
          field: ' {{field}} darf nicht länger als {{length}} Zeichen sein.\n',
          fields: ' {{fields}} dürfen nicht länger als {{length}} Zeichen sein.\n',
          amount: ' {{field}} darf nicht größer sein als der {{amount}}.',
        },
        invalidEmails: 'Ungültige Emails: \n "{{emails}}"',
        disposableDomains: 'Wegwerf-Emails: \n "{{domains}}"',
        duplicatedEmails: 'Doppelt vorhandene Emails in hochgeladener Datei: \n "{{emails}}"',
        existedEmails:
          'Accounts mit diesen Emails existieren bereits (möglicherweise außerhalb Ihres Teams): \n "{{emails}}"',
        missedParams:
          '"{{params}}" sind erforderliche Parameter, aber sie fehlen in Zeile "{{row}}".',
        invalidParam: 'Ungültiger {{param}} in Zeile {{row}}',
        invalidCodes: 'Ungültige Codes: "{{codes}}"',
        trialUsersExceeded: 'Während des Trials seid ihr auf maximal {{max}} Profile beschränkt.',
      },
    },
    referrals: {
      heading: 'Erhalte kostenlos Deine NFC-Karte',
      subtext:
        'Sammle jetzt Guthaben für Deine kostenlose NFC-Karte oder Teams Lizenz. Lade hierfür einfach Deine Kollegen oder Freunde zu Lemontaps ein oder verfasse einen Linkedin Post. Wenn Du uns mit mehreren Bonusaktionen unterstützt, summieren wir die Punkte.',
      balance: {
        title: 'Dein aktuelles Guthaben ',
        tooltip:
          'Dein Guthaben kannst Du verwenden, um Dir Lemontaps Produkte zu kaufen. Eine Bargeldauszahlung ist nicht möglich.',
      },
      redeem: {
        title: 'Löse Dein Guthaben direkt ein!',
        button: 'Guthaben einlösen',
        popup: {
          title: 'Guthaben einlösen?',
          success:
            'Bitte kontaktiere uns unter <0>support@lemontaps.com</0> indem Du kurz erwähnst, welches Item Du einlösen möchtest.',
          failed: 'Dein aktuelles Guthaben reicht nicht aus. Empfehle uns weiter!',
        },
      },
      collect: {
        title: 'Guthaben sammeln',
        email: {
          title: 'Lade Kontakte ein',
          subtext:
            'Lade Deine Kollegen, Freunde oder Kontakte mit ihrer geschäftlichen E-Mail zu Lemontaps ein und erhalte <0>2€ Guthaben</0> pro verifizierter Registrierung. ',
          tooltip:
            'Nur geschäftliche Emails werden mitgezählt (keine G-Mail etc.), die auch das Onboarding durchgeführt haben.',
          placeholder: 'Geschäftliche Email',
          button: 'Jetzt einladen',
          copyButton: 'Teilbaren Link kopieren',
        },
        linkedin: {
          title: 'Erhalte 15€ Guthaben geschenkt',
          subtext:
            'Markiere uns auf deinem nächsten LinkedIn Post über Lemontaps und erhalte sofort einmalig <0>15€ in Credits</0>. <1></1>Sende uns hierfür einfach den Link zum Post an <2>contact@lemontaps.com</2>, und wir bestätigen dein neues Guthaben.',
        },
      },
      toast: {
        emailError: 'Bitte gib eine gültige Firmen-E-Mail ein',
        emailSuccess: 'E-Mail erfolgreich gesendet',
        linkSuccess: 'Link in die Zwischenablage kopiert',
      },
      menuButton: 'Gratis NFC-Karte',
      card: 'Kostenlose NFC-Karte erhalten',
    },
    shareTo: {
      shareTo: 'Teilen mit..',
      shareDetails: 'Teile Dein Lemontaps mit all Deinen Kontakten, damit jeder davon erfährt',
      shareWhatsapp: 'Per Whatsapp teilen',
      shareLinkedin: 'Per Linkedin teilen',
      shareEmail: 'Per E-Mail teilen',
      shareViaOther: 'Über andere teilen',
      shareDestination: 'Teilen mit..',
      shareFacebook: 'Per Facebook teilen',
      shareTelegram: 'Per Telegram teilen',
      shareMail: 'Per Mail teilen',
      copyAndShare: 'Text kopieren und über {{via}} teilen',
      textCopied: 'Text in die Zwischenablage kopiert',
      shareProfile: {
        shareYourProfile: 'Teile Dein Profil',
        shareYourProfileDetails:
          'Vernetze und verbinde Dich besser, indem Du Dein Lemontaps-Profil überall teilst.',
        userText: 'Verwende Lemontaps in',
        qrCode: 'Mein Lemontaps QR-Code',
        lemonTapsShare: 'Teile meine Lemontaps mit ..',
      },
      presentations: {
        useInPresentations: 'Verwendung in Präsentationen',
        useInPresentationsDetails:
          'Platziere Deinen Lemontaps QR-Code in Präsentationen, damit sich jeder leicht mit Dir vernetzen kann.',
      },
      emailSignature: {
        eMailSignature: 'E-Mail Signatur',
        eMailSignatureDetails:
          'Kopiere Deinen Lemontaps-Link und füge ihn in Deine E-Mail-Signatur ein:',
      },
      useInVideoCalls: {
        useInVideoCalls: 'Verwendung in Videoanrufen',
        useInVideoCallsDetails:
          'Generiere Deinen Videocall-Hintergrund mit Deinem Lemontaps QR-Code, damit Du auch bei Online-Meetings eine Visitenkarte hast.',
      },
      useIn: {
        useInPersonalMeetings: 'In persönlichen Meetings verwenden',
        useInVideoCalls: 'In Videoanrufen verwenden',
        useInEmail: 'In E-Mail-Signatur verwenden',
        useInPresentations: 'In Präsentationen verwenden',
      },
      createBackground: 'Hintergrund erstellen',
    },
    selectFromList: 'Aus Liste auswählen',
    send: 'Senden',
    hereIsMyContact: 'Mein Kontakt',
    myContact: 'Mein Kontakt',
    profileImageUpdated: 'Profilbild aktualisiert',
    bannerImageUpdated: 'Banner aktualisiert',
    logoImageUpdated: 'Logo aktualisiert',
    error: {
      general: 'Etwas ging schief, bitte aktualisiere die Seite und probiere es noch einmal',
      editBio: 'Fehler beim Speichern.',
      defaultAddress: 'Adressen konnten nicht aktualisiert werden',
      defaultLink: 'Link konnte nicht aktualisiert werden',
      defaultFile: 'Datei konnte nicht aktualisiert werden',
      uploadFile: 'Datei konnte nicht hochlgeaden werden',
      updateFile: 'Datei konnte nicht aktualisiert werden',
      updateLink: 'Link konnte nicht aktualisiert werden',
      deleteLink: 'Link konnte nicht gelöscht werden',
      deleteFile: 'Datei konnte nicht gelöscht werden',
      uploadingImage: 'Fehler beim Hochladen',
      updatingImage: 'Fehler beim Aktualisieren des Bildes',
      updateAccounts: 'Fehler beim Aktualisieren der Konten, bitte probiere es noch einmal!',
      errorDownloadQr: 'Es können maximal 10 Profile ausgewählt werden für diese Aktion.',
      successfullySent: 'erfolgreich gesendet',
      disposableEmail: 'Die Verwendung von Wegwerf-E-Mails ist nicht erlaubt!',
      invalidEmail: 'Bitte benutze ein gültiges E-Mail-Format!',
      required: 'Erforderlich',
      existedEmail: 'E-Mail bereits verwendet! Bitte verwende eine andere E-Mail.',
      socialEmail:
        'Du bist mit einem Social Provider authentifiziert. Um deine E-Mail anzupassen, logge dich dort ein.',
      requiredFieldGeneral: 'Feld ist erforderlich',
      invalidFieldGeneral: 'Format ist ungültig',
      minOneFieldRequired: 'Mindestens ein Feld erforderlich',
      pleaseFillOutRequired: 'Bitte fülle alle Felder aus',
      maxLength: 'Maximal {{value}} Zeichen',
    },
    teaser: {
      starterBullets: [
        'Digitale Visitenkarte für Mobil & Desktop',
        'Unbegrenzt Links & Dateien hochladen',
        'Apple Wallet, QR-Code, QR-Hintergründe',
        'Analytics & Insights',
      ],
      proBullets: [
        'Zentrale Nutzerverwaltung',
        'Individuelle Firmendomain nutzen',
        'Unterteilung in Untergruppen',
        'Profil nach Corporate Design',
        'Templates & Bilder hinterlegen',
        'Rollen- & Rechte verteilen',
        'Massen-Export von QR-Codes/Wallet',
        'Kontakterfassung und Verwaltung',
        'KI-Visitenkartenscanner für Leads',
        'Schnittstellen: SSO, MS AD, CRM möglich',
        'Schulungsvideos & Prio-Support',
      ],
      enterpriseBullets: [
        'Whitelabel Funktionalitäten wie internes Design, Favicon, Email Editor usw.',
        'Unterteilung in Untergruppen mit spezifischen Design & Einstellungen',
        'Übersetzung der Profil-Inhalte in jede beliebige Sprachen',
        'Lead-Reporting Funktionen für Messen',
        'Persönliche Beratung & Onboarding',
        'Rollout & Best-Practice Beratung',
        'Lemontaps Academy Videoreihe',
        'Dedizierter Support und SLA',
        'Custom Code Anpassungen',
      ],

      starterShortDescr:
        'Erstelle für Dich einfach & kostenlos Dein digitales Visitenkartenprofil.',
      teamsShortDescr:
        'Nutze Lemontaps im Team (1-50 Nutzer) und nach Euren corporate Richtlinien.',
      enterpriseShortDescr:
        'Empfohlen für Teams ab 50 Personen mit erweiterten Sicherheits-, Kontroll- und Supportfunktionen.',

      testItXDaysForFree: 'Jetzt kostenlos testen',
      centralUserAdministration: 'Zentrale Nutzerverwaltung',
      easilyDataOfAllEmployees:
        'Daten aller Mitarbeiter:innen für die Profilerstellung einfach einpflegen, bearbeiten und verwalten.',
      leadRecording: 'Lead Erfassung',
      teamsEssential: 'Lemontaps Teams kostenlos ausprobieren',
      noCredit: '  Keine Kreditkarte oder Kündigung erforderlich.',
      noInstallation: 'Keine Installation notwendig',
      contacts:
        'Über das Lead-Formular auf Deinem Profil oder in Deiner eigenen Ansicht können Kontakte direkt digital aufgenommen werden und automatische Follow-Up Emails gesendet werden.',
      contactsLand:
        ' Die Kontakte landen chronologisch nach Zeit und Datum sortiert im Kontaktmanagementsystem. Hier können weitere Infos ergänzt werden (wie Notizen und Bilder), um die Person nicht zu vergessen.',
      manageContacts: 'Kontakte verwalten ',
      makeContacts: 'Kontakte aufnehmen',
      packageComparison: 'Alle Pakete im Vergleich',
      starter: 'Anlasser',
      individualUser: ' Für Einzelnutzer',
      noPayment: 'Für immer kostenlos. Keine Kreditkarte nötig.',
      currentPlan: 'Aktueller Plan',
      basicFeature: 'Grundfunktionen:',
      teamEssential: 'Teams unerlässlich',
      groupUsers: '1 - 50 Nutzer:innen',
      peruserpermonth: 'Pro Nutzer/Monat im Jahrespaket.',
      graduatedprices: 'Staffelpreise für Lizenzen und NFC-Karten.',
      costless: 'Kostenlos testen',
      allStarterFeatures: 'Starter Funktionen, plus:',
      allProFeatures: 'Teams Funktionen, plus:',
      allFeatures: 'Alle Teams Features, plus:',
      platformManagment: 'Management Plattform zur zentralen Nutzerverwaltung',
      trading: 'Profil nach Corporate Branding ',
      contact: 'Kontakterfassung ',
      teamsPro: 'Lemontaps Teams',
      fiftygroupUsers: 'Empfohlen ab 50 Nutzern',
      recievedError: 'Angebot erhalten',
      allTeamsFeature: 'Alle Teams Features plus:',
      settelment: ' Einteilung in Mehrere Einheiten / Standorte',
      individualAdjustment: 'Individuelle Anpassungen wie Emails und internes Design',
      standard: 'Standard Links/Adressen Templates',
      askUs: 'Frage uns für ein Angebot an.',
      contactSales: 'Vertrieb kontaktieren',
      hunderdsgroupUsers: 'Firmen ab 50 Nutzer:innen',
      allCompanyFeatures: 'Alle Company Features, plus:',
      premiumSupport: 'Premium Support und SLA',
      comparison: 'Zum vollständigen Funktionsvergleich',
      popular: 'Am beliebtesten für Teams',
      adminManagment: 'Admin Managment',
      efficientSolution:
        'Die effizienteste Lösung Dein gesamtes Team mit digitalen Visitenkarten auszustatten. Teste unsere beliebtesten Funktionen kostenlos. ',
      leadgenEfficientSolution:
        'Das Lead-Erfassung Feature ist im Lemontaps Teams Paket enthalten. Du kannst auch als einzelner Nutzer Lemontaps Teams benutzen. Teste unsere beliebtesten Funktionen kostenlos.',
      importantFunction: 'Die wichtigsten Funktionen im Überblick',
      centralUser: 'Zentrale Nutzerverwaltung',
      easyData:
        'Daten aller Mitarbeiter für die Profilerstellung einfach einpflegen, bearbeiten und verwalten. ',
      corporateIdentity: ' Profil nach Corporate Identity ',
      designDigitalProfile:
        'Gestalte das digitale Profil nach Deinen Farben, Formen, Logo und Standard Hintergrundbilder.',
      individualProcessing: 'Individuelle Bearbeitungsrechte',
      access: 'Entscheide wer welche Zugriffe und welche Bearbeitungsrechte haben soll.',
      leader: 'Leaderfassung ',
      efficientContact:
        'Dein Team kann über das Profil effizient Kontakte aufnehmen, verwalten und automatische Follow-Up Emails versenden.',
      myProfile: 'Mein Profil',
      noCancellation: 'Und keine Kündigung erforderlich',
      expired: 'Deine Testphase ist abgelaufen.',
      moreInfo: 'Mehr Infos zu den Teams Paketen',
      welcome: ' Wilkommen zu Lemontaps Teams 🎉',
      companyName: 'Wie heißt deine Organisation?',
      potentialUser: ' Um wie viele potentielle Nutzer handelt es sich?',
      stratNow: 'Jetzt loslegen!',
      upgradeSubtext: 'oder upgraden',
      salesSubtext: 'oder Vertrieb kontaktieren',
      collectLeadsWithLt: 'Leads erfassen mit Lemontaps',
    },
    upgradeTeaser: {
      upgradeYourPlan: 'Upgrade erforderlich',
      contactUs: 'Kontaktiere uns.',
      enableForTrial: 'Mehr Features aktivieren für die Testphase!',
      feature: {
        vcardConfig:
          'Konfiguriere, welche Informationen in heruntergeladenen Kontakten angezeigt werden sollen.',
        units:
          'Gruppiere Nutzer in Units. Diese können durch Sub-Admins auch selbst verwaltet werden, sowie Unit-spezifische Einstellungen erhalten. Units sind in Deinem aktuellen Paket nicht verfügbar.',
        leadgenMd:
          'Finde alle erfassten Kontakte/Leads aller Mitarbeiter:innen an einem Ort, welche mithilfe des Kontaktformulars unten im öffentlichen Profil erfasst wurden. Bearbeite und exportiere die Kontakte. Die Kontaktansicht ist in Deinem aktuellen Paket nicht verfügbar.',
        leadgenIndividual:
          'Finde alle von Dir mithilfe des Kontaktformulars im öffentlichen Profil erfassten Kontakte/Leads. Die Kontaktansicht ist in Deinem aktuellen Paket nicht verfügbar.',
        themeLinks: 'Standardlinks sind in Deinem aktuellen Paket nicht verfügbar',
        themeFiles: 'Standarddateien sind in Deinem aktuellen Paket nicht verfügbar',
        dataTemplates:
          'Erstelle Vorlagen für Standard Adressen, Links und Dateien. Wenn diese Vorlagen hier geändert werden, werden die Änderungen direkt bei allen Profilen übernommen, bei denen diese Vorlage hinzugefügt wurde. Datei Vorlagen sind in Deinem aktuellen Paket nicht verfügbar',
        globalData:
          'Konfiguriere Links, Dateien oder Adressen, die global auf allen Profilen angezeigt werden.',
        emailTemplates:
          'Passe die von Lemontaps versendeten E-Mails mit eurem eigenen Design und Text an. Du kannst die Einladungs-E-Mail und die E-Mails für das Kontaktformular bearbeiten. Diese Funktion ist in Deinem aktuellen Paket nicht enthalten.',
        leadGenContactForm:
          'Passe das Kontaktformular auf der Profilseite Deiner Teammitglieder an. Entscheide, welche Felder verpflichtend ausgefüllt werden müssen, welche Felder optional abgefragt werden, und welche Felder gar nicht angezeigt werden. Dieses Feature ist in Deinem aktuellen Paket nicht enthalten.',
        mdAnalytics:
          'Analysiere profilübergreifende, aggregierte Statistiken zur Nutzung deiner Mitarbeiter:innen.',
        analyticsIndividual: 'Analysiere Statistiken zu Deinem Profil.',
        internalDesign: 'Das Interne Design ist in Deinem aktuellen Paket nicht verfügbar. ',
        unitHierarchies: {
          editRightsTheme:
            'Überschreibe Bearbeitungsrechte für diese Theme. Diese Funktion ist in Deinem aktuellen Paket nicht enthalten.',
          editRights:
            'Überschreibe Bearbeitungsrechte für diese Unit. Diese Funktion ist in Deinem aktuellen Paket nicht enthalten.',
          editRightsGeneral:
            'Entscheide, welche Unit-spezifischen Einstellungen von Unit-Admins selbst verwaltet werden dürfen. Diese Funktion ist in Deinem aktuellen Paket nicht enthalten.',
          profileDesign:
            'Überschreibe das Profildesign für diese Unit. Diese Funktion ist in Deinem aktuellen Paket nicht enthalten.',
        },
        translations:
          'Übersetze die digitalen Visitenkartenprofile Deines Teams. Du kannst den Standardtext, Kontaktinformationen, Linknamen, Links und Dateinamen übersetzen. Du kannst auch festlegen, dass Informationen in bestimmten Sprachen nicht angezeigt werden. Diese Funktion ist in Deinem aktuellen Tarif nicht verfügbar.',
        businessCardScanner:
          'Digitalisiere und importiere Kontaktdaten aus Papiervisitenkarten zu Lemontaps, ins CRM oder in Outlook. Natürlich DSGVO-konform und in Sekundenschnelle.',
      },
    },
    globalSettings: 'Weitere globale Einstellungen',
    integrations: {
      title: 'Integrationen',
      subtitle:
        'Lemontaps lässt sich optimal in Deine Systemlandschaft integrieren. Die folgenden Integrationen können optional hinzugebucht werden.',
      connect: 'Verbinden',
      configure: 'Konfigurieren',
      connected: 'Verbunden',
      inProgress: 'In Bearbeitung',
      customDomain: {
        title: 'Firmendomain',
        description: 'Verbinde die digitalen Profile mit Deiner eigenen Subdomain/URL.',
        popupDescription:
          'Wähle eine Subdomain aus. Dann sende eine E-Mail mit Deiner Entscheidung an {{email}}.',
      },
      sso: {
        title: 'SSO Login',
        description: 'Integriere einen sicheren SSO/SAML-Unternehmenszugang.',
      },
      activeDirectory: {
        title: 'Active Directory',
        description: 'Die Profildaten können mit Deinem Active Directory synchronisiert werden.',
      },
      crm: {
        title: 'CRM',
        description: 'Synchronisiere erfasste Kontakte.',
      },
    },
    businessCardScanner: {
      title: 'Visitenkartenscanner',
      infoText:
        'Fokussiere auf eine Papiervisitenkarte oder einen QR-Code. Dann drücke auf den Auslöser unten.',
      infoTextFront: 'Vorderseite aufnehmen',
      infoTextBack: 'Rückseite aufnehmen',
      enterMinOne: 'Fülle mindestens ein Feld aus, um fortzufahren',
      infoEmailWillBeSent:
        'Wenn du auf "Speichern" klickst, wird eine E-Mail mit deinen Kontaktinformationen an diesen Kontakt gesendet.',
      cameraNotAvailable: 'Du musst den Kamerazugriff erlauben, um den Scanner zu nutzen.',
      fileCaptureError: 'Error. Versuch es noch einmal.',
      addContactSuccess: 'Kontakt gespeichert',
      addContactError: 'Error beim Speichern',
      upload: 'Hochladen',
      loadingTitle: 'Visitenkarte wird analysiert',
      loadingSubtitle: 'Dauert meistens wenige Sekunden',
      businessCardTitle: 'Richtige Treffer auswählen',
      businessCardSubtitle: 'Diese Details können auch später bearbeitet werden.',
      retake: 'Neu aufnehmen',
      next: 'Weiter',
      sendContactToEmail: 'Meine digitale Visitenkarte an {{email}} senden',
      gdprTitle: 'DSGVO',
      gdprSubtitle:
        'Bitte wähle eine rechtliche Grundlage für die Verarbeitung dieses Kontakts aus.',
      noFieldsAvailable:
        'Leider wurde nichts erkannt. Klicke auf "Weiter", um den Kontakt manuall anzulegen.',
      fileSelectSizeError: 'Bitte lade eine Datei hoch, die kleiner als 10MB ist.',
      fileSelectMaxFileSelectError: 'Du kannst maximal {{count}} Dateien auf einmal hochladen.',
      permissionDenied: {
        preText: 'Folge den Anweisungen, um den Kamerazugriff zu aktivieren',
        desktop: {
          firefox:
            "Klicke auf das Schutzschild-Symbol in der Adressleiste, wähle 'Berechtigungen', finde 'Kamera verwenden' und wähle 'Zulassen'. Aktualisiere die Seite danach.",
          chrome:
            "Klicke auf das Vorhängeschloss-Symbol in der Adressleiste, wähle 'Kamera', wähle 'Zulassen' und aktualisiere dann die Seite.",
          safari:
            "Klicke auf das 'aA'-Symbol in der Adressleiste, wähle 'Website-Einstellungen', wähle 'Zulassen' neben 'Kamera' und aktualisiere dann die Seite.",
          edge: "Klicke auf das Vorhängeschloss-Symbol in der Adressleiste, wähle 'Kamera', wähle 'Zulassen' und aktualisiere dann die Seite.",
          opera:
            "Klicke auf das Vorhängeschloss-Symbol in der Adressleiste, wähle 'Kamera', wähle 'Zulassen' und aktualisiere dann die Seite.",
        },
        mobile: {
          firefox:
            "Klicke auf das Schutzschild-Symbol in der Adressleiste, wähle 'Berechtigungen', finde 'Kamera verwenden' und wähle 'Zulassen'. Aktualisiere die Seite danach.",
          chrome:
            "Tippe auf das Dreipunkt-Menü oder das 'Mehr'-Symbol, gehe zu 'Einstellungen', finde 'Website-Einstellungen', tippe auf 'Kamera' und stelle sicher, dass es auf 'Erst fragen' oder 'Erlaubt' steht.",
          safari:
            "Gehe zur iOS 'Einstellungen'-App, scrolle nach unten zu 'Safari', tippe auf 'Kamera' und wähle 'Zulassen'",
          edge: "Tippe auf das Dreipunkt-Menü, gehe zu 'Einstellungen', tippe auf 'Website-Berechtigungen', wähle 'Kamera' und setze es auf 'Vor dem Zugriff fragen'.",
          opera:
            "Öffne das Menü, gehe zu 'Einstellungen', finde 'Website-Einstellungen', wähle 'Kamera' und stelle es auf 'Erst fragen' oder 'Erlaubt' ein.",
        },
      },
      showFront: 'Vorderseite anzeigen',
      showBack: 'Rückseite anzeigen',
      captureFront: 'Vorderseite scannen',
      captureBack: 'Rückseite scannen',
    },
    freeTrialBanner: {
      ongoing: {
        line1_one:
          'Du hast {{count}} Tag Deiner kostenlosen {{days}}-tägigen Testphase von Lemontaps übrig. ',
        line1_other:
          'Du hast {{count}} Tage Deiner kostenlosen {{days}}-tägigen Testphase von Lemontaps übrig. ',
        line2:
          ', um die Teams-Features von Lemontaps nach Ablauf der Testphase weiterhin nutzen zu können. <0>Was passiert nach Ablauf der Testphase?</0>',
      },
      finished: {
        line1: 'Deine {{days}}-tägige Testphase ist abgelaufen',
        line2:
          ', um wieder Zugang zu den Teams-Funktionen zu erhalten. Dein Profil und alle Profile, die Du über das Management Dashboard erstellt hast, wurden in den Starter Plan heruntergestuft.',
      },
    },
    mobilePopupText:
      'Die Bildschirmbreite ist zu klein. Bitte öffne diese Seite auf einem Desktop-Gerät oder vergrößere die Browserbreite auf mindestens {{pixel}}px.',

    onboardingReferrer: {
      google: 'Google',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      referral: 'Empfehlung / Referral',
      event: 'Event',
      email: 'E-Mail',
      friends: 'Freunde',
    },
    contactFormEdit: {
      saveTooltip: 'Mindestens ein Feld muss erforderlich sein',
      fullName: 'Name',
      required: 'Pflichtfeld',
      optional: 'Optionales Feld',
      notShown: 'Nicht anzeigen',
      shown: 'Anzeigen',
      title: 'Konfiguration des Kontakterfassungsformulars',
      description:
        'Die hier vorgenommenen Einstellungen werden auf allen Profilen Deines Teams übernommen.',
      atLeastOneRequired: 'Mindestens ein Attribut muss als Pflichtfeld gesetzt werden',
      sortRequiredFields: 'Sortiere Pflichtfelder',
      sortOptionalFields: 'Sortiere optionale Felder',
      external: {
        title: 'Externes Formular',
      },
      internal: {
        title: 'Internes Formular',
        teaser: {
          title: 'Kontaktiere uns, um das Formular zu ändern',
          body: 'Schreibe an support@lemontaps.com und teile uns deine Änderungswünsche für das interne Formular mit. Unsere IT-Abteilung setzt die Änderungen für Dich um.',
        },
      },
    },
    deleteAccount: {
      title: 'Account löschen',
      pageTitle: 'Gefahrenzone',
      buttonText: 'Meinen Account löschen',
      popupTitle:
        'Bist Du sicher, dass Du Deinen Account löschen willst? Das kann nicht rückgängig gemacht werden!',
      popupBody:
        'Alle Daten, die mit diesem Konto verbunden sind, werden dauerhaft gelöscht. Dies kann nicht rückgängig gemacht werden.',
      popupEmailLabel: 'Gib die Email-Adresse ein, mit der Du registriert bist',
      success: 'Dein Account wurde erfolgreich gelöscht.',
      emailMismatch: 'Die E-Mail stimmt nicht mit der überein, mit der Du registriert bist.',
      confirm: 'Ja, Account löschen',
    },
    emailTemplates: {
      upgradeAlertTitle: 'Eingeschränkter E-Mail-Editor',
      upgradeAlertBody:
        'Du hast keine vollständigen Bearbeitungsrechte für den E-Mail-Editor und kannst daher die Schriftart und Farben nicht ändern, sowie keine Bilder hochladen und das Lemontaps Branding nicht entfernen.',
      noSetPasswordUrlAlert:
        'Der Platzhalter $SET_PASSWORD_URL$ ist aktuell nicht Teil der E-Mail. Ohne diese URL wird der*die eingeladene*r Benutzer*in nicht in der Lage sein, ein Passwort zu setzen, und sich damit einzuloggen.',
      selectTemplate: 'E-Mail auswählen',
      selectLanguage: 'Sprache auswählen',
      errorUploadingImage: 'Fehler beim Hochladen des Bildes',
      errorSaving: 'Fehler beim Speichern',
      errorSendingTestEmail: 'Fehler beim Senden der Test-E-Mail',
      successfullySaved: 'Erfolgreich gespeichert',
      testEmailSuccessfullySent: 'Test-E-Mail erfolgreich versendet',
      standardLocale: 'Standard',
      enLocale: 'Englisch',
      deLocale: 'Deutsch',
      'welcome-email-template': 'Einladungsemail an Mitarbeitende',
      'contact-share-email-template': 'Kontaktformular: E-Mail an Kontakt',
      'contact-received-email-template': 'Kontaktformular: Interne Notification',
      'generic-status-email': 'Generische Statusemail',
      'qr-bg-complete-email-template': 'Statusemail: Erstellung QR-Code-Hintergründe',
      'qr-complete-email-template': 'Statusemail: Erstellung QR-Codes',
      'card-order-confirmation-email-template': 'Kartenbestellungsbestätigungsemail',
      'referral-email-template': 'Empfehlungs-/Referral-E-Mail',
      'referral-referrer-template': 'Referral erfolgreich: E-Mail an Empfehlenden',
      'referral-recipient-template': 'Referral erfolgreich: E-Mail an Empfohlenen',
      'wallet-pass-mail-template': 'Wallet E-Mail',
      'onboarding-email-template': 'Onboarding E-Mail',
      testEmailSendPopupTitle: 'Test-E-Mail senden an',
      testEmailSendPopupSubtitle:
        'Die dynamischen Informationen in der E-Mail basieren auf den Daten aus deinem Profil.',
      sendTestEmail: 'Test-E-Mail senden',
      content: 'Inhalt',
      layout: 'Layout',
      columns: 'Spalten',
      column: 'Spalte',
      editView: 'Bearbeitungsansicht',
      desktopPreview: 'Desktop-Vorschau',
      mobilePreview: 'Mobil-Vorschau',
      duplicateTitle: 'Inhalt duplizieren nach',
      attachments: 'Anhänge',
      attachmentKeys: {
        'offline-apple-wallet': 'Offline Apple Wallet',
        'online-apple-wallet': 'Online Apple Wallet',
      },
    },
    export: {
      refresh: 'Aktualisieren',
      title: 'Export',

      infoCard: {
        title: 'Export-Datei wird generiert',
        body: 'Dies kann etwas Zeit in Anspruch nehmen. Wir werden Dich per Email benachrichtigen, sobald diese zum Export bereitstehen.<0/><1>Hinweis</1>: Es könnte passieren, dass die Email von Deiner Firmen-IT blockiert wird. In diesem Fall schau bitte direkt auf der Export-Seite nach dem Status.',
      },
      emptyCard: {
        title: 'Keine Dateien gefunden',
        body: 'Du hast in den letzten 14 Tagen keine Dateien exportiert.',
      },
      fileCard: {
        errorTitle: 'Export fehlgeschlagen.',
        runningTitle: 'Export-Datei wird generiert...',
        date: 'Exportiert {{date}}',
        status: {
          running: 'Export läuft...',
          finished: 'Export abgeschlossen',
          failed: 'Fehlgeschlagen',
        },
        action: {
          download: 'Herunterladen',
          retry: 'Erneut versuchen',
          contact: 'Unerwarteter Fehler. Bitte kontaktiere: <0>support@lemontaps.com</0>',
        },
        header: {
          qrPlain_one: '{{count}} {{type}} QR-Code',
          qrPlain_other: '{{count}} {{type}} QR-Codes',
          qrBg_one: '{{count}} QR-Code Hintergrund',
          qrBg_other: '{{count}} QR-Code Hintergründe',
        },
      },
    },
    idp: {
      setupScim: 'Setup SCIM',
      teaserDescription:
        'Setze SSO und Nutzer:innenprovisionierung auf. Kompatibel mit den meisten Identitätsanbietern.',
      identityProvider: 'Identitätsanbieter',
      listPageTitle: 'Wähle einen Identitätsanbieter',
      listPageDescription:
        'Integriere euren Identitätsanbieter. Richte Single Sign-On und Benutzersynchronisierung ein.',
      settingsPageDescription:
        'Verwalte die Integration deines Identity Providers. Konfiguriere Single Sign-On und SCIM-Provisionierung.',
      choose: 'Wählen',
      addIdentityProviderDirectory: 'Identitätsanbieterverzeichnis hinzufügen',
      directoryName: 'Verzeichnisname',
      createSuccess: 'Verzeichnis des Identitätsanbieters erfolgreich erstellt',
      createError: 'Fehler beim Erstellen des Identitätsanbieterverzeichnisses',
      updateSucess: 'Identitätsanbieterverzeichnis erfolgreich aktualisiert',
      updateError: 'Fehler beim Aktualisieren des Identitätsanbieterverzeichnisses',
      settings: 'Einstellungen',
      renameDirectory: 'Verzeichnis umbenennen',
      dirName: 'Verzeichnisname',
      deleteConfiguration: 'Konfiguration löschen',
      edit: 'Bearbeiten',
      try: 'Testen',
      deleteSuccess: 'Identitätsanbieterverzeichnis erfolgreich gelöscht',
      deleteError: 'Fehler beim Löschen des Identitätsanbieterverzeichnisses',
      deleteMessage: 'Are you sure you want to delete this directory?',
      disconnect: {
        title: 'Bevor Sie die Verbindung trennen',
        description: 'Bevor Sie Ihren Identitätsanbieter trennen können, müssen Sie dies tun:',
        bulletPoints:
          '• SSO-Konfiguration löschen\n• Benutzerbereitstellungskonfiguration löschen\n• Verknüpfen Sie Domänen mit einem anderen Direktor',
        anchorText: 'Erfahren Sie mehr darüber, wie Sie die Verbindung trennen',
      },
      scim: {
        inviteUserUponProvisioning: 'Einladung automatisch bei Provisionierung versenden',
        title: 'SCIM-Provisionierung',
        description:
          'Hier kannst du die automatische Benutzerprovisionierung und -deprovisionierung einrichten und verwalten.',
        isActive:
          'SCIM ist aktiv. Um Provisionierungseinstellungen anzupassen, verwalte die SCIM-Konfiguration in deinem Identitätsanbieter.',
        setupProvisioning: 'Jetzt Einrichtung starten',
        syncedUsers: 'Synchronisierte Benutzer:innen',
        regenerateApiKey: 'API-Schlüssel neu generieren',
        deleteConfigurationConfirmation:
          'Bist Du sicher, dass Du diese Konfiguration löschen möchtest?',
        deleteConfigurationSuccess: 'SCIM-Konfiguration gelöscht',
        deleteConfigurationError: 'Fehler beim Löschen der SCIM-Konfiguration',
        stupScimProvisioning: 'SCIM-Einrichtung',
        saveConfig: 'SCIM-Konfiguration speichern',
        learnMoreAboutScim: 'Erfahren Sie mehr über die SCIM-Bereitstellung',
        regenerateKey: 'Schlüssel neu generieren',
        steps: {
          beforeYouBegin: {
            stepperTitle: 'Vor dem Start',
            title: 'Vor dem Start',
            description:
              'Bevor Sie die Benutzerbereitstellung über SCIM einrichten, empfehlen wir Ihnen: \nErstellen Sie Testkonten und -gruppen bei Ihrem Identitätsanbieter, um zu verhindern, dass bestehende Benutzer den Produktzugriff verlieren. Wenn Sie Ihren Identitätsanbieter zum ersten Mal verbinden und synchronisieren, können Sie diese Testkonten und -gruppen verwenden, um sicherzustellen, dass alles funktioniert.',
            description2: 'Nachdem die Einrichtung abgeschlossen ist, wird Folgendes möglich sein:',
            description3:
              '• Benutzer:innendetails über den IdP verwalten \n• Automatische Provisionierung von Nutzer:innen \n• Automatische De-Provisionierung von Nutzer:innen',
          },
          setupScim: {
            stepperTitle: 'SCIM einrichten',
            title: 'Details',
            description:
              'Um die Benutzerbereitstellung über SCIM einzurichten und Deinen Identity Provider mit Lemontaps zu verbinden, kopiere die SCIM Base-URL und den API Key in Deinen Identity Provider. Trage "userName" als Unique Identifier ein, falls Du danach gefragt wirst.',
            scimBaseUrl: 'SCIM-Base-URL',
            apiKey: 'API-Key',
            apiKeyHelperText:
              'Wir speichern den API-Key nicht. Wir empfehlen, ihn an einem sicheren Ort aufzubewahren.',
          },
          saveConfig: {
            stepperTitle: 'Konfiguration speichern',
            title: 'SCIM-Konfiguration speichern',
            description:
              'Du hast gerade die Einrichtung von SCIM abgeschlossen. Jetzt kannst Du Benutzer:innen aus deinem Identity Provider heraus verwalten.',
          },
          regenerate: {
            stepperTitle: 'Schlüssel neu generieren',
            title: 'API-Schlüssel neu generieren',
            description:
              'Durch das Generieren eines neuen API-Schlüssels wird der vorhandene Schlüssel deaktiviert. Aktualisiere die Anmeldeinformationen in Deinem Identity Provider mit den neuen Details, um die Provisionierung mit Deinem Identity Provider fortzusetzen,',
          },
          apiKeys: {
            stepperTitle: 'API-Schlüssel',
          },
        },
      },
      sso: {
        connectionEnabled: 'SSO aktiviert',
        cardTitle: 'Single Sign-On',
        cardDescription: 'Hier kannst du Single Sign-On (SSO) einrichten und verwalten.',
        buttonText: 'Richte Single Sign-On ein',
        setupSaml: 'Richte Single Sign-On ein',
        pageTitle: 'SSO-Konfiguration',
        entityId: 'Entity Id',
        singleSignOnUrlForCopy: 'Single Sign-On URL',
        signInUrl: 'Sign-in URL',
        signOutUrl: 'Sign-out URL',
        certificate: 'x509 Certificate',
        signInUrlHelper: 'signInUrlHelper',
        signOutUrlHelper: 'signOutUrlHelper',
        certificateHelper: 'certificateHelper',
        selectDomains: 'Domains auswählen',
        oktaDomain: 'Okta Domain',
        oktaDomainHelper: 'oktaDomainHelper',
        clientID: 'Client ID',
        clientIDHelper: 'clientIDHelper',
        clientSecret: 'Client Secret',
        clientSecretHelper: 'clientSecretHelper',
        entraIdDomain: 'Entra ID Domain',
        entraIdDomainHelper: 'entraIdDomainHelper',
        metaDataUrl: 'Metadata URL',
        metaDataUrlHelper: 'metaDataUrlHelper',
        identityAPI: 'Identity API',
        azureActiveDirectoryV1: 'Azure Active Directory v1',
        microsoftIdentityPlatformV2: 'Microsoft Identity Platform v2',
        updateSuccess: 'Die SSO-Konfiguration wurde erfolgreich aktualisiert',
        updateError: 'Error updating SSO configuration',
        deleteSuccess: 'Fehler beim Aktualisieren der SSO-Konfiguration',
        deleteError: 'Fehler beim Löschen der SSO-Konfiguration',
        deleteConfigurationConfirmation:
          'Bist Du sicher, dass Du diese Konfiguration löschen möchtest?',
        learnMoreAboutSaml: 'Learn more about Single Sign-On',
        learnMoreAboutDomains: 'Learn more about linking domains',

        copySection: {
          title: 'URLs zu Identitätsanbieter kopieren',
          description: 'description',
        },
        editSection: {
          title: 'Bearbeite die SSO-Konfiguration',
          description: 'description',
        },
        domainSection: {
          title: 'Domains',
          description: 'description',
        },
        steps: {
          beforeYouBegin: {
            stepperTitle: 'Vor dem Start',
            title: 'Vor dem Start',
            description: 'Beachte folgende Hinweise:',
            description2:
              '• SSO erlaubt es euren Nutzer:innen, sich mit den Firmenzugangsdaten bei Lemontaps zu authentifizieren.\n• Achte darauf, dass auch die IdP-Seite korrekt konfiguriert ist.',
            description3:
              'Nach dem Setup-Wizard kann die SSO-Verbindung getestet werden. Erst danach darf SSO aktiviert werden.',
            selectStrategy: 'Strategie wählen',
          },
          copyUrls: {
            stepperTitle: 'Werte kopieren',
            title: 'Werte kopieren',
            description:
              'Copy the values and enter them in the appropriate place in the identity provider.',
          },
          addSaml: {
            stepperTitle: 'Werte eintragen',
            title: 'Werte eintragen',
            description: 'Trage die benötigten Werte hier ein:',
            setupUsingMetadata: 'Trage einfach die Metadaten-URL Deines Identitätsanbieters ein',
            or: 'ODER',
            setupManually: 'Manuell eintragen',
          },
          linkDomain: {
            stepperTitle: 'Domain verknüpfen',
            title: 'Domain verknüpfen',
            description: 'Verknüpfe mindestens eine Domain mit dieser SSO-Konfiguration.',
          },
          save: {
            stepperTitle: 'Speichern und fortfahren',
            title: 'Konfiguration gespeichert',
            description:
              'Du hast gerade die Einrichtung von SSO abgeschlossen. Jetzt kannst Du SSO erzwingen.',
          },
        },
      },
    },
    crmIntegration: {
      title: 'CRM Integration',
      description:
        'Verbinde Euer CRM mit Lemontaps, um Lemontaps Kontakte automatisch oder manuell an Euer CRM als Kontakte oder Leads zu senden. Bitte lese Dir den Hilfeartikel durch, um zu sehen, welche Attribute gesendet werden.',
      settingsCard: {
        title: 'Allgemeine CRM-Integrationseinstellungen',
        autoSync:
          'Automatischer Export: Aktiviere diesen Workflow, um neue Kontakte sofort nach der Erstellung automatisch an das CRM zu senden.',
        manualSync:
          'Manueller Push: Aktiviere diesen Workflow, um zu entscheiden, welche Kontakte an das CRM gesendet werden sollen.',
        headerSyncType: 'Manueller oder Automatischer Export',
        headerUseLeads: 'Als Kontakte oder Leads exportieren',
        contacts: 'Kontakte: Lemontaps-Kontakte in CRM-Kontakte umwandeln',
        leads: 'Leads: Lemontaps-Kontakte in CRM-Leads umwandeln',
      },
      crmListCard: {
        title: 'Verwalte Deine CRM-Integrationen',
        subheader:
          'Wir unterstützen nachfolgende CRM Systeme. Klicke auf ein CRM System, um mit der Konfigurierung zu beginnen oder es zu bearbeiten.',
      },
      upgradeAlert: {
        title:
          'Verbinde Euer CRM mit Lemontaps, um per Lemontaps gesammelte Kontakte als CRM-Leads zu exportieren.',
        description:
          'Wähle zwischen einer automatischen Synchronisierung und einem manuellen Push.',
        learnMore: 'Erfahre mehr über die CRM-Integration',
      },
      exportStarted: 'Export zu {{crm}} gestartet am {{datetime}}',
      exported: 'Am {{datetime}} zu {{crm}} exportiert',
      exportFailed: 'Export zu {{crm}} fehlgeschlagen am {{datetime}}',
      exportFailedDuplicate: 'Export zu {{crm}} fehlgeschlagen am {{datetime}} (Duplikat)',
      exportFailedValidation:
        'Der Export nach {{crm}} ist am {{datetime}} aufgrund eines Validierungsfehlers fehlgeschlagen. Folgende Felder fehlen: ',
      errorFields: {
        company: 'Unternehmen',
        first_or_last_name: 'Vorname oder Nachname',
      },
      status: {
        notConnected: 'Nicht verbunden',
        added: 'Konfiguration erforderlich',
        authorized: 'Konfiguration erforderlich',
        invalid: 'Falsche Konfiguration',
        callable: 'Verbunden',
        disabled: 'Deaktiviert',
      },
    },
    selectAllTexts: {
      profile: 'Alle {{count}} Profile auswählen?',
      lead: 'Alle {{count}} Leads auswählen?',
    },
    dropzone: {
      title: 'Datei hier ablegen oder klicken, um hochzuladen',
      errors: {
        'file-invalid-type': 'Ungültiges Dateiformat. Akzeptierte Formate: {{formats}}',
        'file-too-large': 'Die Datei ist zu groß (<{{maxSize}}MB für .{{ext}}).',
        'file-too-small': 'Die Datei ist zu klein.',
        'too-many-files': 'Zu viele Dateien hochgeladen.',
      },
    },
    filter: {
      title: 'Filter',
      addFilter: 'Filter hinzufügen',
      searchLabel: 'Suche',
      operators: {
        is_in: 'ist in',
        is_not_in: 'ist nicht in',
        is_equal: 'ist gleich',
        is_not_equal: 'ist nicht gleich',
        is_like: 'ist wie',
        is_not_like: 'ist nicht wie',
        is_greater: 'ist größer als',
        is_greater_or_equal: 'ist größer oder gleich',
        is_less: 'ist kleiner als',
        is_less_or_equal: 'ist kleiner oder gleich',
      },
    },
    ariaPreviewProfile: 'Profilvorschau',
    ariaCopyProfileUrl: 'Profil-URL kopieren',
    ariaDownloadProfileQrCode: 'QR-Code herunterladen',
    ariaViewAllAnalytics: 'Gesamte Statistiken anzeigen',
    ariaviewAllLeads: 'Alle Kontakte anzeigen',
    ariaSendInviteAfterUserCreate: 'Einladung nach Benutzererstellung senden',
    ariaSendInviteToSelected: 'Einladung an ausgewählte Benutzer senden',
    ariaBulkEditSelected: 'Ausgewählte Benutzer in großen Mengen bearbeiten',
    ariaExportSelected: 'Ausgewählte exportieren',
    ariaUnitAssignSelected: 'Ausgewählte Benutzer der Einheit zuweisen',
    ariaDeleteNfcCard: '{{name}} NFC-Karte löschen',
    ariaShareAnalytics: 'Analysen teilen',
    ariaOpenDateRangeSelector: 'Datumsbereichsselektor öffnen. Aktuell ausgewählt: ',
    ariaCloseDrawer: 'Schublade schließen',
    ariaBulkOverride: 'Ausgewählte Massenoption: Überschreiben',
    ariaBulkDelete: 'Ausgewählte Massenoption: Löschen',
    ariaShareQrInfo: 'QR-Anweisungen teilen',
    ariaQrDownload: '{{type}} QR-Code herunterladen',
    ariaQrDownloadAppleWallet: '{{type}} QR-Code für Apple Wallet herunterladen',
    ariaQrDownloadGoogleWallet: '{{type}} QR-Code für Google Wallet herunterladen',
    ariaShareWhatsApp: 'Über WhatsApp teilen',
    ariaShareEmail: 'Per E-Mail teilen',
    ariaShareLinkedIn: 'Über LinkedIn teilen',
    ariaWebShare: 'Über Web Share API teilen',
    ariaSaveContactColor: 'Farbe "Kontakt speichern" - {{color}}',
    ariaFilesColor: 'Dateifarbe - {{color}}',
    ariaGenericWebsiteLinkColor: 'Allgemeine Website-Link-Farbe - {{color}}',
    ariaCoverImageSettings: 'Coverbild-Einstellungen öffnen',
    ariaProfileImageSettings: 'Profilbild-Einstellungen öffnen',
    ariaLogoImageSettings: 'Logo-Einstellungen öffnen',
    ariaDelete: '{{name}} löschen',
    ariaEdit: '{{name}} bearbeiten',
    ariaAddLinkTemplate: 'Linkvorlage hinzufügen',
    ariaAddAddressTemplate: 'Adressvorlage hinzufügen',
    ariaAddFileTemplate: 'Dateivorlage hinzufügen',
    ariaSelect: '{{name}} auswählen',
    ariaUnitViewMembers: 'Mitglieder der Unit {{name}} anzeigen',
    ariaSettings: '{{name}} - Weitere Optionen anzeigen',
    ariaProfileStatusActions: 'Statusaktionen von {{name}} anzeigen',
    ariaEditUser: '{{name}} bearbeiten',
    ariaEditContact: '{{name}} bearbeiten',
    ariaBusinessCardImageSettings: 'Visitenkartenbild-Einstellungen öffnen',
    ariaCloseScanner: 'Visitenkartenscanner schließen',
    ariaGoBackToPreviewsScreen: 'Zurück zum vorherigen Bildschirm',
    ariaBusinessCardImagesToggler: 'Sichtbarkeit von Visitenkartenbildern umschalten',
    ariaRefreshCampaigns: 'Kampagnen aktualisieren',
    ariaSaveCrmConfiguration: 'CRM-Konfiguration speichern',
    ariaOpenIdpSettings: 'IDP-Einstellungen öffnen',
    ariaOpenScimSettings: 'SCIM-Einstellungen öffnen',
    ariaOpenSsoSettings: 'SSO-Einstellungen öffnen',
    ariaClickToCopy: 'Klicken, um zu kopieren',
    ariaRefreshCrmConfigurations: 'CRM-Konfigurationen aktualisieren',
    ariaToggleVCardFieldVisibility: 'Toggle "{{field}}" field visibility',
    ariaRedirectBusinesssCardTo: '{{name}} als Ziel für Visitenkarte festlegen',
    ariaHideLinkFromProfile: '{{link}} auf Visitenkarte ausblenden',
    ariaEditMessage: '{{name}} - Bearbeiten',
    ariaDeleteMessage: '{{name}} - Löschen',
    ariaHideMessage: '{{name}} - Ausblenden auf Visitenkarte',
    ariaShowMessage: '{{name}} - Einblenden auf Visitenkarte (wird zurzeit nicht angezeigt)',
    ariaRedirectMessage: '{{name}} - Als Ziel für Visitenkarte festlegen.',
    ariaRedirectOffMessage: '{{name}} - Als Ziel für Visitenkarte deaktivieren.',
    ariaDisabledFieldHelpButton: '{{fieldName}} - Hilfe',
    ariaLeadsTableCaption: 'Leads-Tabelle, Spaltenüberschriften mit Schaltflächen sind sortierbar',
    ariaUnitsTableCaption:
      'Einheitentabelle, Spaltenüberschriften mit Schaltflächen sind sortierbar',
    ariaProfilesTableCaption:
      'Profile-Tabelle, Spaltenüberschriften mit Schaltflächen sind sortierbar',
    ariaThemeLinkFileEdit: '{{name}} (global durch Admin festgelegt) - Bearbeiten',
    showNextPage: 'Nächste Seite anzeigen',
    showPreviousPage: 'Vorherige Seite anzeigen',
    ariaShowColumn: '{{name}} - Spalte anzeigen',
    ariaDnd: {
      draggableItem: 'Manuell sortierbares Element',
      draggableDescription:
        'Drücke die Leertaste, um den Sortiervorgang zu starten. Beim Sortieren kannst Du die Pfeiltasten verwenden, um das Element zu verschieben, und die Escape-Taste, um den Vorgang abzubrechen. Bei einigen Bildschirmlesegeräten musst Du möglicherweise den Fokusmodus aktivieren oder die Durchreichtaste verwenden.',
      onDragStart:
        'Du hast das Element "{{itemName}}" in Position {{position}} angehoben. Verwende die Pfeiltasten, um es zu bewegen.',
      onDragUpdate:
        'Du hast das Element von Position {{fromPosition}} zu Position {{toPosition}} bewegt. Drücke die Leertaste, um es abzulegen.',
      onDragEnd:
        'Du hast das Element {{itemName}} abgelegt. Du hast das Element von Position {{fromPosition}} zu Position {{toPosition}} verschoben.',
    },
  },
};
