import { ContactDetailsBox } from '@/shared/types/api';
import {
  AccountCircleOutlined,
  LinkOutlined,
  LocationOnOutlined,
  MailOutlined,
  PhoneAndroidOutlined,
} from '@mui/icons-material';

interface Props {
  name: ContactDetailsBox['rows'][number]['icon']['name'];
}

export default function IconRenderer({ name }: Props) {
  switch (name) {
    case 'mailIcon':
      return <MailOutlined />;
    case 'phoneIcon':
      return <PhoneAndroidOutlined />;
    case 'websiteIcon':
      return <LinkOutlined />;
    case 'locationIcon':
      return <LocationOnOutlined />;
    case 'userIcon':
      return <AccountCircleOutlined />;
  }
}
