import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CookieSettings() {
  const { i18n, t } = useTranslation();
  return (
    <Button
      variant='text'
      onClick={() => {
        window.UC_UI?.updateLanguage?.(i18n.language.substring(0, 2));
        window.UC_UI?.showSecondLayer?.();
      }}
      aria-label={t('ariaOpenCookieSettings')}
    >
      Cookies
    </Button>
  );
}
