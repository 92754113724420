import { Auth0ContextInterface } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import { PERMISSIONS } from '../constants';
import config from '@/config/config';
import { Link } from '@/shared/types/api';

export const getDefaultLinks = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  query?: { unitId?: number | null },
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}theme/global-links`, {
        params: query,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );
  if (!res.isSuccess) {
    throw new Error('error fetching default links');
  }
  return res.data;
};

export const saveDefaultLinks = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  links: Link[],
  unitId?: number,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        `${config.API_BASE_URL}theme/global-links`,
        {
          links: links.map((link, index) => {
            return {
              ...('id' in link ? { id: link.id } : {}),
              canDisplayOnProfile: true,
              link: link.link,
              name: link.name,
              order: index * 10,
              linkType: {
                // @ts-ignore:next-line
                id: link.linkType?.id || link?.themeLinkType?.linkTypeId || link?.linkTypeId,
              },
              linkTemplateId: link.linkTemplateId,
            };
          }),
        },
        {
          params: { unitId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );

  return res;
};
