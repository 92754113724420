import { Link, LinkTemplate, LinkType } from '@/shared/types/api';
import { Box } from '@mui/material';
import { LinkField, LinkTypeSelector, NameField, TemplateSelectorTheme } from './components';
import { LinkBox } from './LinkBox';

type Props = {
  linkTypes: LinkType[];
  templates?: LinkTemplate[];
  hideTemplateSelector?: boolean;
  genericWebsiteColor?: string;
  values: Partial<Link>;
  onChange: (values: Partial<Link>) => void;
};

export const AddEditThemeLinkForm = ({
  linkTypes,
  values,
  onChange,
  templates,
  hideTemplateSelector,
  genericWebsiteColor,
}: Props) => {
  const isEdit = Boolean(values.id);

  const selectedLinkType =
    (values.linkTypeId && linkTypes.find(lt => lt.id === values.linkTypeId)) || null;

  const handleTemplateSelect = (templateId: number) => {
    const linkTemplate = templates?.find(t => t.id === templateId);
    if (!linkTemplate) return;

    onChange({
      link: linkTemplate.link,
      name: linkTemplate.name,
      linkTypeId: linkTemplate.themeLinkType.linkTypeId,
      linkType: linkTemplate.themeLinkType.linkType,
      linkTemplateId: templateId,
    });
  };

  const isTemplateSelected = Boolean(values.linkTemplateId);

  return (
    <Box display='flex' flexDirection='column' gap='2rem'>
      {!hideTemplateSelector && (
        <TemplateSelectorTheme
          onSelect={handleTemplateSelect}
          templates={templates}
          disabled={isTemplateSelected || isEdit}
        />
      )}
      {isTemplateSelected ? (
        <LinkBox link={values as Link} onDelete={isEdit ? null : () => onChange({})} />
      ) : (
        <>
          <LinkTypeSelector
            linkTypes={linkTypes}
            value={values.linkTypeId}
            onChange={linkTypeId =>
              onChange({ linkTypeId, linkType: linkTypes.find(lt => lt.id === linkTypeId) })
            }
            disabled={isEdit}
            genericWebsiteColor={genericWebsiteColor}
          />
          {selectedLinkType && (
            <>
              <NameField
                value={values.name || ''}
                onChange={name => onChange({ ...values, name })}
              />
              <LinkField
                value={values.link || ''}
                onChange={link => onChange({ ...values, link })}
                selectedLinkType={selectedLinkType}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
