import { Box, Typography, styled } from '@mui/material';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import {
  BorderColorOutlined,
  ColorLensOutlined,
  ContactsOutlined,
  GroupOutlined,
} from '@mui/icons-material';

interface BenefitProps {
  icon: (props: any) => JSX.Element;
  title: string;
  subtitle: string;
}
const Benefit = ({ icon, title, subtitle }: BenefitProps) => {
  const Icon = icon;
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={2}>
        <StyledIconWrapper>
          <Icon sx={{ color: 'info.main' }} />
        </StyledIconWrapper>
      </Box>
      <Box>
        <Typography variant='h4' sx={{ mb: 0.5 }}>
          {title}
        </Typography>
        <Typography variant='body2' sx={{ color: 'secondary.main' }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

const BenefitsSection = () => {
  const { t } = useAppTranslation();
  return (
    <Box
      py={3}
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.info.contrastText,
      })}
    >
      <Typography variant='h1'>{t('teaser.importantFunction')}</Typography>

      <StyledBenefitsSectionContainer>
        <Benefit
          icon={GroupOutlined}
          title={t('teaser.centralUser')}
          subtitle={t('teaser.easyData')}
        />
        <Benefit
          icon={ColorLensOutlined}
          title={t('teaser.corporateIdentity')}
          subtitle={t('teaser.designDigitalProfile')}
        />
        <Benefit
          icon={BorderColorOutlined}
          title={t('teaser.individualProcessing')}
          subtitle={t('teaser.access')}
        />
        <Benefit
          icon={ContactsOutlined}
          title={t('teaser.leader')}
          subtitle={t('teaser.efficientContact')}
        />
      </StyledBenefitsSectionContainer>
    </Box>
  );
};

export default BenefitsSection;

const StyledBenefitsSectionContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ' repeat(2, minmax(0, 1fr))',
  gridColumnGap: '3rem',
  gridRowGap: '3rem',
  margin: '4rem 5rem',
  [theme.breakpoints.down('700')]: {
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  },
}));

const StyledIconWrapper = styled(Box)({
  width: '5.6rem',
  height: '5.6rem',
  background: 'white',
  boxShadow: ' 0 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
