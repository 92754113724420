import { useCallback, useEffect, useRef, useState } from 'react';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../../../config/config';
import { AnalyticsSummary, DateRange } from '../../../../shared/types/api';
import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '../../../../shared/constants/global/analytics.constants';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import useDeferredLoader from '../../../../infrastructure/hooks/useDeferredLoader';
import withNav from '@/infrastructure/hoc/withNav';
import { LtDialog, PageContainer } from '@/components';
import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { CalendarTodayOutlined, SupervisedUserCircleOutlined } from '@mui/icons-material';
import StatsCard from '../../analytics/components/StatsCard';
import LTDateRangePicker from '@/components/LTDataRangePicker';
import { getDateRangeButtonText, getEndDayDate, getStartDayDate } from './helpers';
import FeatureTeaser from '../teaser/featureTeaser';
import { FEATURE } from '@/shared/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { UnitSelector, useUnitsForSettings } from '@/components/UnitSelector';
import { Unit } from '@/shared/types/api/unit.type';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const MdAnalytics = () => {
  const { t, activeLanguage } = useAppTranslation();
  const initialDataFetch = useRef(null);
  const newDataFetched = useRef(false);
  const { notify: toast } = useLtNotifications();
  const { isFeatureAllowed } = useTierInfo();
  const mdAnalyticsAllowed = isFeatureAllowed(FEATURE.ANALYTICS_MD);
  const unitsAllowed = isFeatureAllowed(FEATURE.UNITS);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<AnalyticsSummary>({
    raw: {
      contactsCollected: 0,
      contactsSaved: 0,
      fileClicksIndividual: [],
      fileClicksTotal: 0,
      linkClicksIndividual: [],
      linkClicksTotal: 0,
      profileEdits: 0,
      profileShares: 0,
    },
    savings: {
      cards: 0,
      co2: 0,
      energy: 0,
      flyers: 0,
      water: 0,
      wood: 0,
    },
  });

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [dateRange, setDateRange] = useState<Required<DateRange>>({
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(),
  });
  const [dateRangeChanged, setDateRangeChanged] = useState(false);
  const [dateRangeButtonTtext, setDateRangeButtonTtext] = useState(
    getDateRangeButtonText(dateRange),
  );

  useDeferredLoader(isLoading, 'md-analytics-toast');

  const { units, selectedUnit, setSelectedUnit } = useUnitsForSettings();

  useEffect(() => {
    // update button text when switching language
    if (!!newDataFetched.current) {
      setDateRangeButtonTtext(getDateRangeButtonText(dateRange));
      newDataFetched.current = false;
    }
  }, [dateRange, activeLanguage]);

  const handleChange = useCallback(
    (unit: Unit) => {
      setIsLoading(true);
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `data/theme-summary`, {
            params: {
              startDate: getStartDayDate(dateRange?.startDate),
              endDate: getEndDayDate(dateRange?.endDate),
              unitId: unitsAllowed ? unit?.id : '',
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_PROFILES],
      )
        .then(res => {
          if (res.data?.success) {
            setData(res.data.data);
            setDateRangeButtonTtext(getDateRangeButtonText(dateRange));
          }
        })
        .catch(_ => {
          toast.error(t('error.general'));
        })
        .finally(() => setIsLoading(false));
    },
    [dateRange, getAccessTokenSilently, t, unitsAllowed, toast],
  );

  useEffect(() => {
    if (!initialDataFetch.current && mdAnalyticsAllowed) {
      initialDataFetch.current = true;
      handleChange(selectedUnit);
    }
  }, [handleChange, mdAnalyticsAllowed, selectedUnit]);

  return (
    <PageContainer maxWidth={'l'}>
      {!mdAnalyticsAllowed ? (
        <FeatureTeaser text={t('upgradeTeaser.feature.mdAnalytics')} fullWidth />
      ) : (
        <>
          <Box display={'flex'} justifyContent={'end'} mb={2} gap={1}>
            <Button
              variant='outlined'
              color='primary'
              size='large'
              startIcon={<CalendarTodayOutlined />}
              onClick={() => setShowDatePicker(true)}
            >
              {t(dateRangeButtonTtext)}
            </Button>
            {unitsAllowed && units?.length ? (
              <UnitSelector
                selectedUnit={selectedUnit}
                onChange={value => {
                  setSelectedUnit(value);
                  handleChange(value);
                }}
                units={units}
                requiredFeature={FEATURE.UNITS}
                sx={{ width: '30%' }}
              />
            ) : null}
          </Box>

          <Box>
            <Card sx={{ mb: 2 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    backgroundColor: 'secondary.main',
                    p: 0.5,
                    borderRadius: '50%',
                    height: '3.2rem',
                    width: '3.2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SupervisedUserCircleOutlined sx={{ color: 'white' }} />
                </Box>
                <Box>
                  <Typography component='h2' variant='h3' ml='1rem'>
                    {t('mdAnalytics.networking.title')}
                  </Typography>
                  <Typography variant='body2' ml='1rem'>
                    {t('mdAnalytics.networking.subtitle')}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 1.6,
                    mb: 1.6,
                  }}
                >
                  <StatsCard
                    title={t('mdAnalytics.networking.shares')}
                    body={data?.raw?.profileShares?.toString()}
                    tooltip={t('mdAnalytics.tooltips.shares')}
                  />
                  <StatsCard
                    title={t('mdAnalytics.networking.contacts')}
                    body={data?.raw?.contactsCollected?.toString()}
                    tooltip={t('mdAnalytics.tooltips.contacts')}
                  />
                  <StatsCard
                    title={t('mdAnalytics.networking.edits')}
                    body={data?.raw?.profileEdits?.toString()}
                    tooltip={t('mdAnalytics.tooltips.edits')}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 1.6,
                  }}
                >
                  <StatsCard
                    title={t('mdAnalytics.networking.vcards')}
                    body={data?.raw?.contactsSaved?.toString()}
                    tooltip={t('mdAnalytics.tooltips.vcards')}
                  />
                  <StatsCard
                    title={t('mdAnalytics.networking.links')}
                    body={data?.raw?.linkClicksTotal.toString()}
                    tooltip={t('mdAnalytics.tooltips.links')}
                  />
                  <StatsCard
                    title={t('mdAnalytics.networking.files')}
                    body={data?.raw?.fileClicksTotal.toString()}
                    tooltip={t('mdAnalytics.tooltips.files')}
                  />
                </Box>
              </CardContent>
            </Card>

            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography component='h2' variant='h3'>
                  {t('mdAnalytics.saves.title')}
                </Typography>
              </CardContent>
              <Divider />
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  gap: 1.6,
                }}
              >
                <StatsCard
                  title={t('mdAnalytics.saves.cards')}
                  body={data?.savings?.cards?.toString()}
                  tooltip={t('mdAnalytics.tooltips.cards')}
                />
                <StatsCard
                  title={t('mdAnalytics.saves.flyers')}
                  body={data?.savings?.flyers?.toString()}
                  tooltip={t('mdAnalytics.tooltips.flyers')}
                />
              </CardContent>
              <CardContent>
                <Typography component='div' variant='h3'>
                  {t('mdAnalytics.saves.subtitle')}
                </Typography>
              </CardContent>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 1.6,
                }}
              >
                <>
                  <StatsCard
                    title={t('mdAnalytics.saves.wood')}
                    body={`${Math.ceil(data?.savings?.wood)}kg`}
                  />
                  <StatsCard
                    title={t('mdAnalytics.saves.water')}
                    body={`${Math.ceil(data?.savings?.water)}l`}
                  />
                  <StatsCard
                    title={t('mdAnalytics.saves.energy')}
                    body={`${Math.ceil(data?.savings?.energy)}kwh`}
                  />
                  <StatsCard
                    title={t('mdAnalytics.saves.co2')}
                    body={`${Math.ceil(data?.savings?.co2)}kg`}
                  />
                </>
              </CardContent>
            </Card>
          </Box>
          <LtDialog
            open={showDatePicker}
            onClose={() => setShowDatePicker(false)}
            confirmAction={{
              onClick: () => {
                handleChange(selectedUnit);
                setShowDatePicker(false);
                newDataFetched.current = true;
              },
              text: t('apply'),
              disabled: !dateRangeChanged,
            }}
            onCancel={() => setShowDatePicker(false)}
            withActionDivider
            size='lg'
          >
            <LTDateRangePicker
              setValue={val =>
                setDateRange(curDate => {
                  if (curDate.startDate !== val.startDate || curDate.endDate !== val.endDate)
                    setDateRangeChanged(true);
                  return {
                    startDate: getStartDayDate(val.startDate || curDate.startDate),
                    endDate: getEndDayDate(val.endDate || curDate.endDate),
                  };
                })
              }
              open={showDatePicker}
              setOpen={setShowDatePicker}
              initialDateRange={dateRange}
            />
          </LtDialog>
        </>
      )}
    </PageContainer>
  );
};

export default withNav(
  MdAnalytics,
  {
    tTitle: 'mdAnalytics.title',
  },
  {},
);
