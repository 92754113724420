import { ContactDetailsBox as ContactDetailsBoxConfig } from '@/shared/types/api';
import { ProfileEditProps } from '../../types';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { Button, Collapse, List } from '@mui/material';
import { routePaths } from '@/infrastructure/constants';
import { EditOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import ContentBoxUi from '../../components/ContentBoxUI';
import { Link } from 'react-router-dom';
import ContactDetail from './components/ContactDetail';
import TooltipInfo from '@/components/TooltipInfo';
import useId from '@mui/material/utils/useId';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

interface Props {
  config: ContactDetailsBoxConfig;
  editProps?: ProfileEditProps['contactDetailsBoxEditProps'];
}

export default function ContactDetailsBox({ config, editProps }: Props) {
  const { t } = useTranslation();
  const { notifyInfo } = useLtNotifications();

  const isEdit = !!editProps?.isEdit;
  const editRightAllowed = !!editProps?.editRightAllowed;
  const editButton = useMemo(
    () =>
      isEdit ? (
        <Button
          startIcon={<EditOutlined />}
          aria-label={t('ariaEditContactDetails')}
          {...(editRightAllowed
            ? { component: Link, to: { pathname: routePaths.EDITBIO } }
            : { onClick: () => notifyInfo(t('adminDisabledEditRight')) })}
        >
          {t('edit')}
        </Button>
      ) : undefined,
    [editRightAllowed, isEdit, notifyInfo, t],
  );

  const [collapsed, setCollapsed] = useState(config.foldInitially);
  const collapsable = config.rows.length > config.foldAfter;

  const expandButtonLabel = collapsed
    ? t('ariaExpandFor', { count: config.rows.length - config.foldAfter })
    : t('ariaCollapseTo', { count: Math.min(config.foldAfter, config.rows.length) });

  const listId = useId();

  return (
    <ContentBoxUi title={config.label} actionButton={editButton} isEdit={isEdit}>
      <List id={listId} aria-label={config.label}>
        {config.rows.slice(0, config.foldAfter).map(rowConfig => (
          <ContactDetail key={rowConfig.href} {...rowConfig} />
        ))}
        {collapsable && (
          <Collapse in={!collapsed} timeout='auto' unmountOnExit>
            {config.rows.slice(config.foldAfter).map(rowConfig => (
              <ContactDetail key={rowConfig.href} {...rowConfig} />
            ))}
          </Collapse>
        )}
      </List>
      {collapsable && (
        <TooltipInfo text={expandButtonLabel} enterDelay={500} onHover>
          <Button
            variant='text'
            fullWidth
            onClick={() => setCollapsed(p => !p)}
            aria-label={expandButtonLabel}
            aria-expanded={!collapsed}
            aria-controls={listId}
          >
            {collapsed ? <ExpandMore /> : <ExpandLess />}
          </Button>
        </TooltipInfo>
      )}
    </ContentBoxUi>
  );
}
