import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import withNav from '@/infrastructure/hoc/withNav';

import { useAuth0 } from '@auth0/auth0-react';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import {
  Checklist,
  ChecklistItem,
  FAQ,
  Header,
  MdGuideConfig,
  Section,
} from '@/shared/types/global';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { MarkdownRenderer, PageContainer } from '@/components';
import LtVideo from '@/components/LtVideo';
import { AccessTimeOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import CheckListItem from './components/CheckListItem';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { Theme } from '@/shared/types/api';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import FAQItem from './components/FAQItem';
import { withSilentAccessToken } from '@/infrastructure/helper';
import axios from 'axios';
import config from '@/config/config';
import { PERMISSIONS } from '@/infrastructure/constants';
import { dispatchTypes } from '@/application/actions';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { checkConditions } from './util';
import GsAlert from './components/GsAlert';
import GsBadge from './components/GsBadge';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const MdGuide = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { notify: toast } = useLtNotifications();

  const theme = useAppSelector<Theme>(state => state.account.theme);

  const [selectedCheckItemsKeys, setSelectedCheckItemsKeys] = useState(
    theme?.themeInternal?.mdGuideState?.checked || [],
  );

  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const tierInfo = useTierInfo();
  const firstRender = useRef(true);
  const timer = useRef(null);

  const {
    config: guides,
    error,
    loading,
  } = usePrivateThemeConfig<MdGuideConfig>(THEME_CONFIG_KEYS.MD_GUIDE);
  useDeferredLoader(loading, 'md-guides-loader');
  useEffect(() => {
    if (error) {
      toast.error(t('error.general'), { id: 'md-guides-loader' });
    }
  }, [error, t, toast]);

  const relevantSections = useMemo(() => {
    if (!guides?.sections) return [];
    return guides.sections
      .filter(section => checkConditions(tierInfo, section.conditions))
      .map(section =>
        section.type === 'checklist'
          ? ({
              ...section,
              items: section.items.filter(item => checkConditions(tierInfo, item.conditions)),
            } as Checklist)
          : section.type === 'faq'
          ? ({
              ...section,
              items: section.items.filter(item => checkConditions(tierInfo, item.conditions)),
            } as FAQ)
          : section,
      )
      .filter(section => section.type === 'header' || section.items.length > 0);
  }, [guides?.sections, tierInfo]);

  const updateKeys = useCallback(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.post(
          config.API_BASE_URL + 'theme/update-guide-state',
          { keys: selectedCheckItemsKeys },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [PERMISSIONS.WRITE.THEME_GLOBAL],
    )
      .then(res => {
        if (res.data.isSuccess)
          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_FIELDS,
            payload: { mdGuideState: { checked: res.data.data } },
          });
      })
      .catch(() => toast.error(t('error.general', { id: 'md-guides-loader' })));
  }, [dispatch, getAccessTokenSilently, selectedCheckItemsKeys, t, toast]);

  const isChecked = useCallback(
    (item: ChecklistItem) => selectedCheckItemsKeys.includes(item.key),
    [selectedCheckItemsKeys],
  );

  useEffect(() => {
    if (!firstRender.current) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        timer.current = null;
        updateKeys();
      }, 2000);
    } else firstRender.current = false;
  }, [dispatch, getAccessTokenSilently, selectedCheckItemsKeys, t, updateKeys]);

  const getChecklistSectionProgressCount = useCallback(
    (checklist: Checklist): { checked: number; total: number; progress: number } => {
      const counts = { checked: 0, total: 0 };
      // const checklist = relevantSections.find((s: Section): s is Checklist => s.type === 'checklist' && s.key === key);
      for (const item of checklist.items) {
        counts.total++;
        if (isChecked(item)) counts.checked++;
      }
      return {
        ...counts,
        progress: counts.total <= 0 ? 0 : (100 * counts.checked) / counts.total,
      };
    },
    [isChecked],
  );

  const getHeaderProgressCount = useCallback(
    (progressBar: Header['progressBar']): { checked: number; total: number; progress: number } => {
      const counts = { checked: 0, total: 0 };
      if (progressBar?.length) {
        const relevantLocalSections = relevantSections
          .filter((s): s is Checklist => s.type === 'checklist')
          .filter(cl => progressBar.includes(cl.key));

        for (const section of relevantLocalSections) {
          const itemCounts = getChecklistSectionProgressCount(section);
          counts.total += itemCounts.total;
          counts.checked += itemCounts.checked;
        }
      }
      return {
        ...counts,
        progress: counts.total <= 0 ? 0 : (100 * counts.checked) / counts.total,
      };
    },
    [getChecklistSectionProgressCount, relevantSections],
  );

  const renderSection = useCallback(
    (section: Section) => {
      if (!section) return null;
      switch (section.type) {
        case 'header':
          const header = section;
          return (
            <Paper key={header.key} sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Box display='flex' alignItems='center' gap={1}>
                  {header.title && <Typography variant='h2'>{header.title}</Typography>}
                  <GsBadge badge={header.badge} />
                </Box>
                <GsAlert currentPosition='pre' alert={header.alert} />
                {header.content && (
                  <Typography variant='body2' color='secondary'>
                    <MarkdownRenderer content={header.content} />
                  </Typography>
                )}
                <GsAlert currentPosition='post' alert={header.alert} />
                {header.videoEmbedUrl && <LtVideo embedUrl={header.videoEmbedUrl} />}
                {header.progressBar?.length ? (
                  <Box>
                    <Typography variant='body2' color='secondary' mb={1}>
                      {getHeaderProgressCount(header.progressBar).checked}/
                      {getHeaderProgressCount(header.progressBar).total} {t('mdguide.tasks')}
                    </Typography>
                    <LinearProgress
                      variant='determinate'
                      value={getHeaderProgressCount(header.progressBar).progress}
                      color='success'
                      sx={{ height: 5 }}
                    />
                  </Box>
                ) : null}
              </Stack>
            </Paper>
          );
        case 'checklist':
          const checklist = section;
          return (
            <Card key={checklist.key}>
              <Accordion sx={{ borderRadius: '.7rem', boxShadow: 'none' }}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  borderBottom={'solid 1px'}
                  borderColor={'divider'}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls='checklist-content'
                    id='checklist-header'
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      '>.MuiAccordionSummary-contentGutters': { alignItems: 'center', gap: 1 },
                    }}
                  >
                    <Box position={'relative'} display={'flex'}>
                      <CircularProgress
                        aria-hidden
                        variant='determinate'
                        value={100}
                        sx={theme => ({ color: theme.palette.grey[300] })}
                        thickness={5}
                        size={32}
                      />
                      <CircularProgress
                        aria-hidden
                        variant='determinate'
                        value={getChecklistSectionProgressCount(checklist).progress}
                        size={32}
                        sx={theme => ({
                          position: 'absolute',
                          left: 0,
                          color: theme.palette.primaryButton.main,
                        })}
                        thickness={6}
                      />
                    </Box>
                    {Boolean(checklist?.title) && (
                      <Typography variant='h3'>{checklist.title}</Typography>
                    )}
                    <GsBadge badge={checklist.badge} />
                    {Boolean(checklist?.timeEffort) && (
                      <Box display={'flex'} gap={0.3} alignItems={'center'} minWidth={'20%'}>
                        <AccessTimeOutlined sx={{ color: 'text.secondary', fontSize: 24 }} />
                        <Box typography={'subtitle2'} color='text.secondary'>
                          {checklist.timeEffort}
                        </Box>
                      </Box>
                    )}
                  </AccordionSummary>
                </Box>
                <AccordionDetails>
                  <GsAlert currentPosition='pre' alert={checklist.alert} />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'start'}
                    width={'100%'}
                    pt={2}
                    pb={2}
                    gap={5}
                  >
                    {checklist.items
                      .filter(item => checkConditions(tierInfo, item.conditions))
                      .map(item => (
                        <CheckListItem
                          item={item}
                          keys={selectedCheckItemsKeys}
                          setKeys={setSelectedCheckItemsKeys}
                        />
                      ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Card>
          );
        case 'faq':
          const faq = section;
          return (
            <Card key={section.key}>
              {Boolean(faq?.title) && (
                <CardContent>
                  <Typography variant='h3'>{faq.title}</Typography>
                </CardContent>
              )}
              {faq.items?.length &&
                faq.items.map((faqItem, index) => <FAQItem key={index} item={faqItem} />)}
            </Card>
          );
        default:
          return null;
      }
    },
    [getChecklistSectionProgressCount, getHeaderProgressCount, selectedCheckItemsKeys, t, tierInfo],
  );

  return (
    <PageContainer maxWidth={'m'}>
      <Box display='flex' flexDirection={'column'} gap={2}>
        {relevantSections.map(renderSection)}
      </Box>
    </PageContainer>
  );
};

export default withNav(
  MdGuide,
  {
    tTitle: 'mdguide.pageHeader',
  },
  {},
);
