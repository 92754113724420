import { EntraIdApi, StrategyName, StrategyOptions } from '@/shared/types/api';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ADIdentityAPIs = [
  {
    title: 'azureActiveDirectoryV1',
    value: 'azure-active-directory-v1.0',
  },
  {
    title: 'microsoftIdentityPlatformV2',
    value: 'microsoft-identity-platform-v2.0',
  },
];

export const useStrategyFields = (
  strategy: StrategyName,
  values: StrategyOptions,
  onChange: (values: StrategyOptions) => void,
) => {
  const { t } = useTranslation();

  if (!values) return [];

  const generateTextFieldsProps = (prop: keyof typeof values) => ({
    value: values[prop] || '',
    onChange: e => onChange({ ...values, [prop]: e.target.value }),
    fullWidth: true,
    label: t(`idp.sso.${prop}`),
    // helperText: t(`idp.sso.${prop}Helper`),
    key: prop,
  });

  const samlFields = [
    <TextField {...generateTextFieldsProps('signInUrl')} />,
    <TextField {...generateTextFieldsProps('signOutUrl')} />,
    <TextField multiline rows={6} {...generateTextFieldsProps('certificate')} />,
  ];

  const oktaFields = [
    <TextField {...generateTextFieldsProps('oktaDomain')} />,
    <TextField {...generateTextFieldsProps('clientID')} />,
    <TextField {...generateTextFieldsProps('clientSecret')} type='password' />,
  ];

  const adFields = [
    <TextField {...generateTextFieldsProps('entraIdDomain')} />,
    <TextField {...generateTextFieldsProps('clientID')} />,
    <TextField {...generateTextFieldsProps('clientSecret')} type='password' />,
    <FormControl fullWidth>
      <InputLabel>{t('idp.sso.identityAPI')}</InputLabel>
      <Select
        value={values.identityAPI}
        label={t('idp.sso.identityAPI')}
        onChange={e => onChange({ ...values, identityAPI: e.target.value as EntraIdApi })}
      >
        {ADIdentityAPIs.map(({ value, title }) => (
          <MenuItem key={value} value={value}>
            {t(`idp.sso.${title}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>,
  ];

  const strategyFieldsDict = {
    samlp: samlFields,
    okta: oktaFields,
    waad: adFields,
  };

  return strategyFieldsDict[strategy];
};
