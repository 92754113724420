import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { StaticProfileConfig } from '@/shared/types/api';
import { Stack, Typography } from '@mui/material';
import Policies from './components/Policies';

interface Props {
  config: StaticProfileConfig['footer'];
  supportedLangs: string[];
  renderCookieSettings?: boolean; //defaults to true
}

export default function Footer({ config, supportedLangs, renderCookieSettings = true }: Props) {
  const { t, Translation } = useAppTranslation();
  return (
    <Stack direction='column' spacing={2} pt={4}>
      <Policies
        imprint={config.imprint}
        privacyPolicy={config.privacyPolicy}
        renderCookieSettings={renderCookieSettings}
      />
      {config.copyright && (
        <Typography variant='body2' color='secondary'>
          {config.copyright} <br />
          {t('allRightsReservedPublic')}
        </Typography>
      )}
      {supportedLangs.length > 1 && (
        <Translation languages={supportedLangs} textAlign='center' justifyContent='center' py={4} />
      )}
    </Stack>
  );
}
