import { routePaths } from '@/infrastructure/constants';
import { FeatureFlagGuard, PageContainer } from '@/components';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import withNav from '@/infrastructure/hoc/withNav';
import { useAuth0 } from '@auth0/auth0-react';
import { getThemeIdps } from '@/infrastructure/apis/md/idp';
import { Redirect, useHistory } from 'react-router-dom';
import { ThemeIdentityProvider } from '@/shared/types/api';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';

type SingleCardProps = {
  themeIdp: ThemeIdentityProvider;
};

const SingleCard = ({ themeIdp }: SingleCardProps) => {
  const history = useHistory();
  return (
    <Paper
      sx={{ p: 1.5, borderRadius: '4px' }}
      elevation={0}
      variant='outlined'
      onClick={() => history.push(`/md/settings/idps/themeIdps/${themeIdp.id}`)}
    >
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' sx={{ ml: 1.5 }}>
          {themeIdp.name}
        </Typography>
        <Typography variant='body1' sx={{ ml: 1.5 }}>
          {themeIdp.niceName}
        </Typography>
      </Box>
    </Paper>
  );
};

const ThemeIdpsListPage = () => {
  const [themeIdps, setThemeIdps] = useState<ThemeIdentityProvider[]>([]);
  const [idpsLoaded, setIdpsLoaded] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { items },
        } = await getThemeIdps(getAccessTokenSilently, { take: 1000 });
        setThemeIdps(items);
      } catch (error) {
        console.log(error);
      }
      setIdpsLoaded(true);
    };
    fetchData();
  }, [getAccessTokenSilently]);

  const { t } = useTranslation();

  useDeferredLoader(themeIdps.length === 0, 'themeIdp');

  if (!idpsLoaded) return null;

  if (themeIdps.length === 0) return <Redirect to={routePaths.IDP.LIST} />;
  if (themeIdps.length === 1)
    return <Redirect to={`/md/settings/idps/themeIdps/${themeIdps[0].id}`} />;

  return (
    <>
      <PageContainer maxWidth='xl' helpSidebarConfigKey='help-sidebar-configured-idp-list'>
        <Typography variant='h2'>{t('idp.listPageTitle')}</Typography>
        <Typography variant='body1' color='secondary' sx={{ mb: 2, mt: 1 }}>
          {t('idp.listPageDescription')}
        </Typography>

        <Grid container spacing={2}>
          {themeIdps.map(themeIdp => (
            <Grid item key={themeIdp.id} xs={12}>
              <SingleCard themeIdp={themeIdp} />
            </Grid>
          ))}
        </Grid>
      </PageContainer>
    </>
  );
};

export default withNav(
  () => (
    <FeatureFlagGuard privateFlags={['iam']}>
      <ThemeIdpsListPage />
    </FeatureFlagGuard>
  ),
  {
    tTitle: 'idp.identityProvider',
  },
  {
    activeScreen: routePaths.IDP.THEME_IDPS,
  },
);
