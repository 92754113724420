import withNav from '@/infrastructure/hoc/withNav';
import { PaperCardsPage } from '@/views/pages/paper-cards';

const MdPaperOrderingSystem = () => {
  return <PaperCardsPage variant='approval' />;
};

export default withNav(
  MdPaperOrderingSystem,
  {
    tTitle: 'nfc.paperCardsBox.title',
    showBackButton: { linkTo: null },
  },
  {},
);
