import { DropdownAction } from '@/infrastructure/hoc/types';

import { useAppTranslation } from '../useAppTranslation';
import useTierInfo from '../useTierInfo';
import { Box, Typography } from '@mui/material';
import { ChatOutlined, HelpOutline, SupportAgentOutlined } from '@mui/icons-material';
import { useContactPersons } from '../useContactPersons';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
const SingleContactRow = ({
  name,
  email,
  helperText,
}: {
  name?: string;
  email?: string;
  helperText?: string;
}) => (
  <Box display='flex' flexDirection='column' gap={0.5} my={0.5}>
    {name && (
      <Typography variant='h3' sx={{ userSelect: 'text' }}>
        {name}
      </Typography>
    )}
    {email && (
      <Typography variant='body2' sx={{ userSelect: 'text' }}>
        {email}
      </Typography>
    )}
    {helperText && (
      <Typography color='secondary' variant='body2' sx={{ userSelect: 'text' }}>
        {helperText}
      </Typography>
    )}
  </Box>
);

export const useHelpActions = (): DropdownAction[] => {
  const { isEnterprise, isStarter, isAnyAdmin } = useTierInfo();
  const { activeLanguage, t } = useAppTranslation();

  const contactPersons = useContactPersons();

  return [
    {
      icon: HelpOutline,
      label: 'header.helpCenter',
      onClick: () =>
        window.open(
          `https://support.lemontaps.com/${
            activeLanguage.startsWith('de') ? 'de' : 'en'
          }/support/home`,
          '_blank',
        ),
    },
    {
      icon: ChatOutlined,
      label: 'header.chatWithUs',
      onClick: () => window.FreshworksWidget?.('open'),
      withSeparator: true,
    },
    ...(contactPersons?.map(contactPerson => ({
      icon: PermIdentityIcon,
      hide: isStarter,
      customActionUI: (
        <SingleContactRow
          key={contactPerson.email}
          name={contactPerson.name}
          email={contactPerson.email}
          helperText={contactPerson.text || t('internalContactPerson')}
        />
      ),
    })) || []),
    {
      icon: SupportAgentOutlined,
      hide: !isEnterprise || !isAnyAdmin,
      customActionUI: (
        <SingleContactRow helperText='+4971193964293' email='support@lemontaps.com' />
      ),
    },
  ].filter(Boolean);
};
