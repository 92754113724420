import { ProfileEditProps } from '../../types';
import { getBannerImageUrl } from '@/shared/business-logic/features/profile/getter';
import { ImageSettings } from '@/components/Profile/components/ImageSettings';
import { useTranslation } from 'react-i18next';

type Props = ProfileEditProps['bannerImageEditProps'];

export default function EditBanner({
  account,
  profileDesign,
  editRightAllowed,
  onUpdateByFile,
  onUpdateByUrl,
  onResetToDefault,
}: Props) {
  const { t } = useTranslation();
  const bannerImageUrlToDisplay = getBannerImageUrl(account, profileDesign);

  return (
    <ImageSettings
      disallowed={!editRightAllowed}
      currentUrl={getBannerImageUrl(account, profileDesign)}
      imageState={account.bannerImageState}
      defaultImageState={profileDesign.defaultBannerImageState}
      isButton={!Boolean(bannerImageUrlToDisplay)}
      buttonText={t('addCover')}
      crop={{ crop: { width: 100, x: 0, y: 0, aspect: 16 / 9 } }}
      options={profileDesign.bannerImgOptions}
      onUploadByFile={onUpdateByFile}
      onUploadByUrl={onUpdateByUrl}
      onResetToDefault={onResetToDefault}
      onDelete={onResetToDefault}
      ariaLabel={!Boolean(bannerImageUrlToDisplay) ? t('addCover') : t('editCoverPicture')}
    />
  );
}
