import { LtDialog } from '@/components';
import { routePaths } from '@/infrastructure/constants';
import Image from '@/views/images/add-user-to-unit-explainer.png';
import { East } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddUserToUnitPopup = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <LtDialog
      title={t('mdUnits.addMembers')}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      confirmAction={{
        text: t('mdUnits.goToUsers'),
        onClick: () => {
          history.push(routePaths.MD.PROFILES);
          onClose();
        },
        endIcon: <East />,
      }}
    >
      <Typography color='secondary' variant='body2'>
        {t('mdUnits.addMembersExplainer')}
      </Typography>
      <Box width='90%' m='auto' mt={2}>
        <img src={Image} alt='' width='100%' />
      </Box>
    </LtDialog>
  );
};
