import { getAddressFromAccount } from '../../util';
import { Account, Theme } from '../../types/api';
import { generateProfileLink } from '../features/profile/getter';

export type GoogleFormPrefillConfig = {
  baseUrl: string;
  attributeMap: Record<string, string>;
  options?: {
    lt_medium?: string;
  };
};

// verified with google and microsoft forms
export function getPrefilledGoogleFormLink(
  config: GoogleFormPrefillConfig,
  account: Account,
  theme: Theme,
  options: { customMap?: Record<string, string>; lt_medium?: string } = {},
): string {
  const formAttributes = Object.entries(config.attributeMap)
    .map(([formKey, attr]) => {
      switch (attr) {
        case 'fullAddress1':
          const address1Text = getAddressFromAccount(account, 1);
          if (!address1Text) return null;
          return `${formKey}=` + encodeURIComponent(address1Text);
        case 'fullAddress2':
          const address2Text = getAddressFromAccount(account, 2);
          if (!address2Text) return null;
          return `${formKey}=` + encodeURIComponent(address2Text);
        case 'profileUrl':
          return (
            `${formKey}=` +
            encodeURIComponent(
              generateProfileLink(
                { ...account, theme },
                { lt: { lt_medium: config.options?.lt_medium || options.lt_medium } },
              ),
            )
          );
        case 'homePhone':
        case 'mobilePhone':
        case 'workPhone':
        case 'assistantPhone':
        case 'hotlinePhone':
          if (!account[attr]) return null;
          return `${formKey}=` + encodeURIComponent(`+${account[attr]}`);
        default:
          if (options.customMap && attr.startsWith('custom--')) {
            const [, key] = attr.split('--');
            if (!options.customMap[key]) return null;
            return `${formKey}=` + encodeURIComponent(`${options.customMap[key]}`);
          } else {
            if (!account[attr as keyof Account]) return null;
            return `${formKey}=` + encodeURIComponent(`${account[attr as keyof Account]}`);
          }
      }
    })
    .filter(Boolean)
    .join('&');
  const preFilledUrl = config.baseUrl + formAttributes;

  return preFilledUrl;
}
