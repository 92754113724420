import Loader from '@/components/Loader';
import React, { Suspense, LazyExoticComponent, ComponentType, PropsWithoutRef } from 'react';

function withSuspense<P extends object>(
  Component: LazyExoticComponent<ComponentType<P>>,
  FallbackComponent?: ComponentType<{}>,
) {
  return function Loadable(props: PropsWithoutRef<P>) {
    return (
      <Suspense fallback={FallbackComponent ? <FallbackComponent /> : <Loader delayedShow={500} />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default withSuspense;
