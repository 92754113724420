import { Typography, Box, BoxProps } from '@mui/material';

type Props = {
  title: string;
  texts: string[];
} & BoxProps;

export const TextsRenderer = ({ title, texts, ...rest }: Props) => {
  return (
    <Box {...rest}>
      <Typography mb={1.3} variant='h4'>
        {title}
      </Typography>
      <Box
        sx={{ whiteSpace: 'pre-wrap' }}
        typography={'body1'}
        lineHeight={2}
        component={Typography}
        color='text.secondary'
      >
        {texts.map(text => (
          <Box mb={1.3}>{text}</Box>
        ))}
      </Box>
    </Box>
  );
};
