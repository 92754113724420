import { LtDialog } from '@/components';
import { Stepper, Step, StepLabel, Box, Divider } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BeforeYouBeginStep, GenerateKeyStep, SaveConfigStep } from './ScimSteps';

enum StepEnum {
  BEFORE_YOU_BEGIN,
  SETUP_SCIM,
  SAVE_CONFIG,
}

const INITIAL_STEP = StepEnum.BEFORE_YOU_BEGIN;
const steps = [StepEnum.BEFORE_YOU_BEGIN, StepEnum.SETUP_SCIM, StepEnum.SAVE_CONFIG];
const stepsLabels = {
  [StepEnum.BEFORE_YOU_BEGIN]: 'idp.scim.steps.beforeYouBegin.stepperTitle',
  [StepEnum.SETUP_SCIM]: 'idp.scim.steps.setupScim.stepperTitle',
  [StepEnum.SAVE_CONFIG]: 'idp.scim.steps.saveConfig.stepperTitle',
};

const getNextStep = (step: StepEnum) => steps.indexOf(step) + 1;

type Props = {
  onClose: () => void;
  open: boolean;
};
export const SetupScimPopup = ({ onClose, open }: Props) => {
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const { t } = useTranslation();

  const isLastStep = activeStep === steps[steps.length - 1];

  const isFirstStep = activeStep === steps[0];

  const nextClickHandler = () => {
    if (isLastStep) {
      onClose();
      return;
    }
    setActiveStep(getNextStep(activeStep));
  };

  return (
    <LtDialog
      TransitionProps={{
        onExited: () => {
          setActiveStep(INITIAL_STEP);
        },
      }}
      size='lg'
      open={open}
      onClose={onClose}
      onCancel={isFirstStep && onClose}
      title={t('idp.scim.stupScimProvisioning')}
      confirmAction={{
        onClick: nextClickHandler,
        text: t(isLastStep ? 'idp.scim.saveConfig' : 'next'),
      }}
    >
      <Box mb={3}>
        <Stepper activeStep={activeStep}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{t(stepsLabels[step])}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider />
      <Box mt={3}>
        {activeStep === StepEnum.BEFORE_YOU_BEGIN && <BeforeYouBeginStep />}
        {activeStep === StepEnum.SETUP_SCIM && <GenerateKeyStep />}
        {activeStep === StepEnum.SAVE_CONFIG && <SaveConfigStep />}
      </Box>
    </LtDialog>
  );
};
