import { useMemo, useState } from 'react';
import Logos from './logos';
import Colors from './colors';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { Account, ThemeInternal } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { updateThemeInternal } from '@/application/actions/md/customization';
import { useAuth0 } from '@auth0/auth0-react';
import { UpdateInternalDesignProps } from '@/infrastructure/apis/md/customization';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { Temporary } from '@/application/reducers/temporary';
import withNav from '@/infrastructure/hoc/withNav';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useTranslation } from 'react-i18next';
import FeatureTeaser from '../../teaser/featureTeaser';
import { PageContainer } from '@/components';
import { Box, Divider, TextField, Typography } from '@mui/material';
import TooltipInfo from '@/components/TooltipInfo';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const Organisation = () => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureAllowed } = useTierInfo();
  const isInternalDesignAllowed = isFeatureAllowed('internalDesign');
  const account: Account = useAppSelector(state => state.account);
  const themeInternal = useAppSelector<ThemeInternal>(state => state.account.theme.themeInternal);
  const temporary = useAppSelector<Temporary>(state => state.temporary);

  const isLoading = useAppSelector(state => state.md.isLoading);

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();

  const { logoWideUrl, logoWideBrightUrl } = themeInternal;

  const [niceName, setNiceName] = useState(themeInternal.niceName);

  const primaryButtonColor = temporary?.primaryButtonColor || themeInternal?.primaryColor;
  const sidebarColor = temporary?.sidebarColor || themeInternal?.sidebarColor;
  const sidebarColorText = temporary?.sidebarColorText || themeInternal?.sidebarColorText;
  const sidebarColorActive = temporary?.sidebarColorActive || themeInternal?.sidebarColorActive;
  const sidebarColorActiveText =
    temporary?.sidebarColorActiveText || themeInternal?.sidebarColorActiveText;

  useDeferredLoader(isLoading, 'organisations-initial-loader-id');

  const colorProps = useMemo(
    () => ({
      primaryButtonColor,
      sidebarColor,
      sidebarColorText,
      sidebarColorActive,
      sidebarColorActiveText,
    }),
    [
      primaryButtonColor,
      sidebarColor,
      sidebarColorActive,
      sidebarColorActiveText,
      sidebarColorText,
    ],
  );

  const [logoWide, setLogoWide] = useState(logoWideUrl);
  const [logoWideBright, setLogoWideBright] = useState(logoWideBrightUrl);
  const [logoWideInvert, setLogoWideInvert] = useState(themeInternal.logoWideInvert);
  const [saveLoading, setSaveLoading] = useState(false);

  const logoProps = useMemo(
    () => ({
      logoWide,
      logoWideBright,
      logoWideInvert,
      setLogoWide: withUnsavedSetter(setLogoWide),
      setLogoWideBright: withUnsavedSetter(setLogoWideBright),
      setLogoWideInvert: withUnsavedSetter(setLogoWideInvert),
    }),
    [logoWide, logoWideBright, logoWideInvert, withUnsavedSetter],
  );

  const handleSave = () => {
    if (!niceName) return toast.error(t('organisation.nameRequired'));

    const isNameChanged = niceName !== themeInternal.niceName;

    const ids = { id: account.id, uniqueId: themeInternal.uniqueId };
    const flags = { isNameChanged, isInternalDesignAllowed };

    // automatically set to inverted logo when no custom logo uploaded
    if (!logoWideInvert && !logoWideBright) setLogoWideInvert(true);

    // map to field names
    const internalDesign: UpdateInternalDesignProps = {
      logoWideInvert: !logoWideInvert && !logoWideBright ? true : logoWideInvert,
      logoWideUrl: logoWide,
      logoWideBrightUrl: logoWideBright,
      primaryColor: primaryButtonColor,
      sidebarColor: temporary?.sidebarColor || themeInternal?.sidebarColor,
      sidebarColorText: temporary?.sidebarColorText || themeInternal?.sidebarColorText,
      sidebarColorActive: temporary?.sidebarColorActive || themeInternal?.sidebarColorActive,
      sidebarColorActiveText:
        temporary?.sidebarColorActiveText || themeInternal?.sidebarColorActiveText,
    };

    setSaveLoading(true);
    dispatch(
      updateThemeInternal(
        ids,
        flags,
        niceName,
        internalDesign,
        getAccessTokenSilently,
        (message?: string) => {
          message
            ? toast.success(t(message), { id: 'organisations-loader-id' })
            : toast.success(t('changesSaved'), { id: 'organisations-loader-id' });
          setIsUnsaved(false);
          setSaveLoading(false);
        },
        (message?: string) => {
          message
            ? toast.error(t(message), { id: 'organisations-loader-id' })
            : toast.error(t('error.general'), { id: 'organisations-loader-id' });

          setSaveLoading(false);
        },
      ),
    );
  };

  const ungatedContent = useMemo(
    () => (
      <>
        <Box display={'inline-flex'} alignItems={'center'}>
          <Typography component='h2' variant='h3'>
            {t('organisation.name')}
          </Typography>
          <TooltipInfo
            text={t('organisation.nameTooltip')}
            icon={{ color: 'text.primary' }}
            placement='right'
          />
        </Box>
        <TextField
          label={t('organisation.title')}
          name={'niceName'}
          value={niceName}
          required={true}
          onChange={e => setNiceName(e.target.value)}
          InputLabelProps={{ color: 'secondary', shrink: true }}
        />
        <Divider />
        <Box display={'flex'} alignItems={'center'}>
          <Typography component='h2' variant='h3'>
            {t('organisation.internalDesign')}
          </Typography>
        </Box>
      </>
    ),
    [niceName, t],
  );

  const gatedContent = useMemo(
    () => (
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Logos {...logoProps} />
        <Colors {...colorProps} />
      </Box>
    ),
    [colorProps, logoProps],
  );

  return (
    <PageContainer
      whiteCardWrapper
      maxWidth='m'
      bottomActionBar={{ onSave: handleSave, loading: saveLoading }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'start'}
        width={'100%'}
        gap={2.4}
      >
        {isInternalDesignAllowed ? (
          <>
            {ungatedContent}
            {gatedContent}
          </>
        ) : (
          <>
            {ungatedContent}
            <FeatureTeaser text={t('upgradeTeaser.feature.internalDesign')} fullWidth />
            <Box
              sx={{
                opacity: 0.35,
                pointerEvents: 'none !important',
                display: 'flex',
                flexDirection: 'column',
                gap: 2.4,
              }}
            >
              {gatedContent}
            </Box>
          </>
        )}
      </Box>
    </PageContainer>
  );
};

export default withNav(
  Organisation,
  {
    tTitle: 'organisationPageHeader',
  },
  {},
);
