import { Auth0ContextInterface } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import { PERMISSIONS } from '../constants';
import config from '@/config/config';

export type LinkTemplatePayload = {
  id?: number;
  name?: string;
  link: string;
  linkTypeId: number;
};

export const getAddressTemeplates = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/address-templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error fetching address templates');
  }
  return res.data;
};

export const deleteAddressTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: number,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(config.API_BASE_URL + `theme/address-templates/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error deleting link template');
  }
  return res.data;
};

export const createAddressTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  newAddress: any,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + `theme/address-templates`,
        {
          addressee: newAddress.addressee,
          addressLine1: newAddress.addressLine1,
          addressLine2: newAddress.addressLine2,
          city: newAddress.city,
          label: newAddress.label,
          country: newAddress.country,
          postCode: newAddress.postCode,
          isDefault: newAddress.isDefault,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error creating address template');
  }
  return res.data;
};

export const updateAddressTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  newAddress: any,
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.patch(
        config.API_BASE_URL + `theme/address-templates/${newAddress.id}`,
        {
          addressee: newAddress.addressee || undefined,
          addressLine1: newAddress.addressLine1 || undefined,
          addressLine2: newAddress.addressLine2 || undefined,
          city: newAddress.city || undefined,
          label: newAddress.label || undefined,
          country: newAddress.country || undefined,
          postCode: newAddress.postCode || undefined,
          isDefault: newAddress.isDefault || undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  if (!res.isSuccess) {
    throw new Error('error deleting address template');
  }
  return res.data;
};
