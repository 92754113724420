import { Link, LinkTemplate } from '@/shared/types/api';

import { AddEditLinkDialog, LinkBox, LtDialog } from '@/components';
import { Theme } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@/application/hooks';
import { useTranslation } from 'react-i18next';
import { getLinkTemplates } from '@/infrastructure/apis/linkTemplates';
import { Box, Button, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';

type Props = {
  links: Link[];
  onChange: (links: Link[]) => void;
};

export const Links = ({ links, onChange }: Props) => {
  const [addLinkDialogOpened, setAddLinkDialogOpened] = useState(false);
  const [linkIdxToDelete, setLinkIdxToDelete] = useState<number | null>(null);
  const { profileDesign } = useProfileDesign();

  const [linkTemplates, setLinkTemplates] = useState<LinkTemplate[]>([]);
  const theme: Theme = useAppSelector(state => state.account.theme);
  const linkTypes = theme.themeLinkTypes?.map(({ linkType }) => linkType) || [];

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const linkTemplates = await getLinkTemplates(getAccessTokenSilently);
        setLinkTemplates(linkTemplates);
      } catch {}
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const handleAdd = (link: Link) => {
    onChange([...links, link]);
    setAddLinkDialogOpened(false);
  };

  const handleLinkDelete = () => {
    if (typeof linkIdxToDelete !== 'number') return;

    const newLinks = [...links];
    newLinks.splice(linkIdxToDelete, 1);
    onChange(newLinks);

    setLinkIdxToDelete(null);
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2}>
        <Button
          onClick={() => setAddLinkDialogOpened(true)}
          startIcon={<AddCircleOutline />}
          aria-label={t('ariaAddLink')}
          sx={{ ml: ' auto' }}
        >
          {t('add')}
        </Button>
        {links.map((link, index) => (
          <LinkBox
            key={`link-${index}`}
            link={link}
            onDelete={() => setLinkIdxToDelete(index)}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
        ))}
      </Box>
      <AddEditLinkDialog
        mode='admin'
        linkTypes={linkTypes}
        templates={linkTemplates}
        open={addLinkDialogOpened}
        onClose={() => setAddLinkDialogOpened(false)}
        onSave={handleAdd}
        genericWebsiteColor={profileDesign.genericWebsiteColor}
      />
      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(typeof linkIdxToDelete === 'number')}
        onClose={() => setLinkIdxToDelete(null)}
        onCancel={() => setLinkIdxToDelete(null)}
        onDelete={handleLinkDelete}
      >
        <Typography>{t('deleteMessageLink')}</Typography>
      </LtDialog>
    </>
  );
};
