import { useTranslation } from 'react-i18next';
import { Box, Switch, Typography } from '@mui/material';
import useId from '@mui/material/utils/useId';

interface Props {
  leadGenFormShown: boolean;
  setLeadGenFormShown: (value: boolean) => void;
  leadGenFormAsPopup: boolean;
  setLeadGenFormAsPopup: (value: boolean) => void;
}

export const ContactsForm = ({
  leadGenFormAsPopup,
  leadGenFormShown,
  setLeadGenFormAsPopup,
  setLeadGenFormShown,
}: Props) => {
  const { t } = useTranslation();

  const formSwitchId = useId();
  const popupSwitchId = useId();

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography id={formSwitchId} variant='body1'>
          {t('showContactsForm')}
        </Typography>
        <Switch
          checked={leadGenFormShown}
          onChange={() => setLeadGenFormShown(!leadGenFormShown)}
          inputProps={{
            'aria-labelledby': formSwitchId,
          }}
        />
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography id={popupSwitchId} variant='body1'>
          {t('showContactsFormAsPopup')}
        </Typography>
        <Switch
          checked={leadGenFormAsPopup}
          onChange={() => setLeadGenFormAsPopup(!leadGenFormAsPopup)}
          inputProps={{
            'aria-labelledby': popupSwitchId,
          }}
        />
      </Box>
    </>
  );
};
