import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import Month from './Month';
import DefinedRanges from './DefinedRanges';
import { DefinedRange, Setter, NavigationAction } from '../utils/types';
import { MARKERS, Marker } from './Markers';
import moment from 'moment';
import { differenceInCalendarMonths } from '../utils/utils';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { DateRange } from '@/shared/types/api';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { flexCenterProps } from '@/util';
import { visuallyHidden } from '@mui/utils';

interface MenuProps {
  dateRange: DateRange;
  ranges: DefinedRange[];
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: Setter<DateRange>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (marker: Marker, day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: Marker, action: NavigationAction) => void;
  };
  coupledView?: boolean;
}

const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
    coupledView,
  } = props;

  const { desktopView } = useDesktopView();

  const { startDate, endDate } = dateRange;
  const canNavigateCloser = desktopView
    ? differenceInCalendarMonths(secondMonth, firstMonth) >= 2
    : true;
  const { t } = useAppTranslation();
  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers,
  };
  return (
    <Box display='flex' flexDirection='row' flexWrap='nowrap' justifyContent={'center'}>
      {desktopView && (
        <>
          <Box {...flexCenterProps}>
            <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />
          </Box>
          <Divider orientation='vertical' flexItem />
        </>
      )}
      <Box>
        <Box sx={{ display: 'flex', py: 1, px: 3 }} alignItems='center'>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant='subtitle1'>
              <span style={visuallyHidden}>
                {t('ariaFrom')}
                {': '}
              </span>
              {startDate ? moment(startDate).format('L') : t('datePicker.heading.startDate')}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <ArrowRightAlt sx={{ fontSize: 24 }} aria-hidden />
          </Box>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant='subtitle1'>
              <span style={visuallyHidden}>
                {t('ariaTo')}
                {': '}
              </span>
              {endDate ? moment(endDate).format('L') : t('datePicker.heading.endDate')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display={'flex'}
          flexDirection='row'
          justifyContent='center'
          flexWrap='nowrap'
          minHeight='32rem'
        >
          <Month
            {...commonProps}
            value={firstMonth}
            setValue={setFirstMonth}
            navState={[true, canNavigateCloser]}
            marker={MARKERS.FIRST_MONTH}
            coupledView={coupledView}
          />
          {desktopView && (
            <>
              <Divider orientation='vertical' flexItem />
              <Month
                {...commonProps}
                value={secondMonth}
                setValue={setSecondMonth}
                navState={[canNavigateCloser, true]}
                marker={MARKERS.SECOND_MONTH}
                coupledView={coupledView}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
