import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import nl2br from 'react-nl2br';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { Alert, AlertTitle, BoxProps, Button, Typography } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ArrowForward } from '@mui/icons-material';
import { Box, ThemeProvider } from '@mui/system';
import { flexCenterProps } from '@/util';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { RequireEveryOrNone } from '@/shared/types/util';
import { PropsWithChildren } from 'react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

type Props = RequireEveryOrNone<
  PropsWithChildren<{
    text: string;
    condition?: boolean;
    imageSrc?: string;
    fullWidth?: boolean;
  }>,
  'children' | 'condition'
> &
  BoxProps;

export default function FeatureTeaser({
  fullWidth,
  condition,
  text,
  imageSrc,
  children,
  ...rest
}: Props) {
  const { theme: muiTheme } = useMuiTheme();
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  return (
    <>
      {upgradeRequestPopup}
      <ThemeProvider theme={muiTheme}>
        <Box mx={fullWidth ? 0 : 4} display='flex' flexDirection='column' gap={1} {...rest}>
          {typeof condition !== 'undefined' ? (
            condition ? (
              <TeaserAlert upgradeHandler={upgradeAction} text={text} />
            ) : null
          ) : (
            <TeaserAlert upgradeHandler={upgradeAction} text={text} />
          )}
          {imageSrc && (
            <Box m={5} {...flexCenterProps}>
              <Box
                component='img'
                src={imageSrc}
                alt={text}
                maxWidth='80%'
                sx={{ objectFit: 'contain' }}
              />
            </Box>
          )}
          {typeof condition !== 'undefined' ? (
            condition ? (
              <Box
                sx={{
                  opacity: 0.35,
                  pointerEvents: 'none !important',
                }}
              >
                {children}
              </Box>
            ) : (
              children
            )
          ) : null}
        </Box>
      </ThemeProvider>
    </>
  );
}

const TeaserAlert = ({ text, upgradeHandler }: { text: string; upgradeHandler: () => void }) => {
  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  const { isTrial } = useTierInfo();
  const { t } = useAppTranslation();
  return (
    <Alert
      sx={{
        '.MuiAlert-message': {
          flex: 1,
        },
      }}
      severity={'info'}
      variant='outlined'
      action={
        <Button variant='text' onClick={upgradeHandler} endIcon={<ArrowForward />} size='small'>
          {isTrial
            ? t('upgradeTeaser.enableForTrial')
            : flag_hidePrices
            ? t('contactSales')
            : t('upgradeNow')}
        </Button>
      }
    >
      <AlertTitle>
        <Typography>{t('upgradeTeaser.upgradeYourPlan')}</Typography>
      </AlertTitle>

      <Typography variant='body2'>{nl2br(text)}</Typography>
    </Alert>
  );
};
