import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const NameField = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t('nameLink')}
      value={value}
      onChange={e => onChange(e.target.value)}
      inputProps={{ maxLength: 30 }}
      fullWidth
    />
  );
};
