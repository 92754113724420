import { useAppSelector } from '@/application/hooks';
import { Box } from '@mui/material';
import { ReactComponent as LemontapsLogo } from '@/views/images/lemontaps-logo.svg';

export const HeaderLogo = ({ onClick, center }: { onClick?: () => void; center?: boolean }) => {
  const theme = useAppSelector(state => state.account?.theme);
  return (
    <Box
      onClick={onClick}
      sx={{
        height: '3rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: center ? 'center' : 'flex-start',
        cursor: 'pointer',
      }}
    >
      {theme?.themeInternal?.logoWideUrl ? (
        <img
          height='100%'
          src={theme.themeInternal.logoWideUrl}
          style={{ objectFit: 'contain' }}
          alt='custom-logo'
        />
      ) : (
        <LemontapsLogo />
      )}
    </Box>
  );
};
