import { Dropzone } from '@/components';
import { isFileValid, isNotEmptyArray } from '@/infrastructure/helper';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { ALLOWED_FORMATS } from '@/views/pages/qrcode-background/common/constants';
import { QRBGMode } from '@/views/pages/qrcode-background/common/types';
import { imageSizeValidator } from '@/views/pages/qrcode-background/common/utils';
import { Box, CardContent, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { SingleListImage } from '../../../SingleListImage';
import { extension } from 'mime-types';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import ImageCropper from '@/components/ImageCropper';

type Props = {
  defaultImageUrl: string;
  setDefaultImage: Setter<string | File>;
  mode: QRBGMode;
};

const DefaultImage: React.FC<Props> = ({ defaultImageUrl, setDefaultImage, mode }) => {
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const [imageToCrop, setImageToCrop] = useState('');

  const [_defaultImageUrl, _setDefaultImageUrl] = useState<string>(null);

  const initDone = useRef(false);
  useEffect(() => {
    if (!initDone.current) {
      _setDefaultImageUrl(defaultImageUrl);
      initDone.current = true;
    }
  }, [defaultImageUrl]);

  const onImageSelection = async (files: File[]) => {
    if (isNotEmptyArray(files)) {
      let uploadedFile = files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => setImageToCrop(reader.result as string);
        return;
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    // validate uploaded image size
    const error = await imageSizeValidator(croppedImageUrl, mode);

    if (error && error.length) {
      toast.error(error);
      return;
    }

    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = extension(blob.type);
    setImageToCrop('');

    const file = new File([blob], `default-qr-background-${mode}.${ext}`);

    setDefaultImage(file);
    _setDefaultImageUrl(croppedImageUrl);
  };

  const handleX = () => {
    setDefaultImage(null);
    _setDefaultImageUrl(null);
  };

  return (
    <>
      {imageToCrop && (
        <ImageCropper
          image={imageToCrop}
          onCropClick={url => onUploadClick(url)}
          onClose={() => setImageToCrop('')}
          noAspectRatio={true}
          fixedCrop={true}
          cropOptions={{ width: 100, height: 100 }}
        />
      )}
      <Box p={2}>
        <CardContent>
          <Typography variant='h3'>{t('sharingDesign.qrbg.chooseImages')}</Typography>
          <Typography variant='helperText' color='secondary'>
            {t(`sharingDesign.qrbg.${mode}.helperText`)}
          </Typography>
          <Typography variant='body2' my={2} sx={{ display: 'flex', alignItems: 'center' }}>
            {t('sharingDesign.qrbg.defaultImage')}
          </Typography>
          <Box sx={{ width: '100%' }}>
            {_defaultImageUrl || defaultImageUrl ? (
              <SingleListImage
                src={_defaultImageUrl || defaultImageUrl}
                onDeleteClick={handleX}
                sx={{ width: '50%' }}
              />
            ) : (
              <Dropzone
                maxSize={10}
                types={ALLOWED_FORMATS}
                onChange={files => onImageSelection(files)}
                fullWidth
              />
            )}
          </Box>
        </CardContent>
      </Box>
    </>
  );
};

export default DefaultImage;
