import * as React from 'react';
import { DefinedRange, NavigationAction } from '../utils/types';
import {
  addMonths,
  addYears,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
} from '../utils/utils';
import { getDefaultRanges } from '../utils/defaults';
import Menu from './Menu';
import { MARKERS, Marker } from './Markers';
import { DateRange } from '@/shared/types/api';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';

interface DateRangePickerProps {
  open: boolean;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date;
  maxDate?: Date;
  onChange: (dateRange: DateRange) => void;
  coupledView?: boolean;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = (
  props: DateRangePickerProps,
) => {
  const today = new Date();

  const {
    open,
    onChange,
    initialDateRange,
    minDate,
    maxDate,
    definedRanges = getDefaultRanges(today),
  } = props;

  const minDateValid = minDate || addYears(today, -10);
  const maxDateValid = maxDate || addYears(today, 10);

  const [dateRange, setDateRange] = React.useState<DateRange>({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(today);
  const [secondMonth, setSecondMonth] = React.useState<Date>(addMonths(today, 1));

  const { startDate, endDate } = dateRange;

  const lastDayClickMarker = React.useRef<Marker>(MARKERS.SECOND_MONTH);

  React.useEffect(() => {
    if (initialDateRange) {
      if (lastDayClickMarker.current === MARKERS.FIRST_MONTH) return; // to prevent the selection to jump from left to right: ;
      setFirstMonth(
        props.coupledView ? addMonths(initialDateRange?.endDate, -1) : initialDateRange?.startDate,
      );
      setSecondMonth(initialDateRange?.endDate);
    }
  }, [initialDateRange, props.coupledView]);

  // handlers
  const setFirstMonthValidated = (date: Date) => {
    if (props.coupledView) {
      setFirstMonth(date);
      setSecondMonth(addMonths(date, 1));
    } else {
      if (isBefore(date, secondMonth)) {
        setFirstMonth(date);
      }
    }
  };

  const setSecondMonthValidated = (date: Date) => {
    if (props.coupledView) {
      setFirstMonth(addMonths(date, -1));
      setSecondMonth(date);
    } else {
      if (isAfter(date, firstMonth)) {
        setSecondMonth(date);
      }
    }
  };

  const setDateRangeValidated = (range: DateRange) => {
    let { startDate: newStart, endDate: newEnd } = range;
    if (newStart && newEnd) {
      setDateRange(range);
      onChange(range);

      if (props.coupledView) {
        setFirstMonth(addMonths(newEnd, -1));
        setSecondMonth(newEnd);
      } else {
        setFirstMonth(newStart);
        setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
      }
    } else {
      const emptyRange = {};

      setDateRange(emptyRange);
      onChange(emptyRange);

      setFirstMonth(today);
      setSecondMonth(addMonths(firstMonth, 1));
    }
  };

  const onDayClick = (marker: Marker, day: Date) => {
    lastDayClickMarker.current = marker;
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const { desktopView } = useDesktopView();
  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (props.coupledView || !desktopView) {
      const firstNew = addMonths(firstMonth, action);
      const secondNew = addMonths(secondMonth, action);
      setFirstMonth(firstNew);
      setSecondMonth(secondNew);
    } else {
      if (marker === MARKERS.FIRST_MONTH) {
        const firstNew = addMonths(firstMonth, action);
        if (!desktopView || isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
      } else {
        const secondNew = addMonths(secondMonth, action);
        if (!desktopView || isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
      }
    }
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day: Date) =>
    (startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, { start: startDate, end: hoverDay })) as boolean;

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  return open ? (
    <Menu
      dateRange={dateRange}
      minDate={minDateValid || today}
      maxDate={maxDateValid}
      ranges={definedRanges}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      setDateRange={setDateRangeValidated}
      helpers={helpers}
      handlers={handlers}
      coupledView={props.coupledView}
    />
  ) : null;
};

export default DateRangePicker;
