import { Box, Divider, Typography } from '@mui/material';

import { LinksSection } from './components/LinksSection';
import { FilesSection } from './components/FilesSection';
import { useTranslation } from 'react-i18next';
import { AddressSection } from './components/AddressSection';

export const ContentTemplates = () => {
  const { t } = useTranslation();

  return (
    <Box pt={2}>
      <Box>
        <Typography variant='h3' component='h2' sx={{ display: 'block' }}>
          {t('contentTemplate')}
        </Typography>
        <Divider sx={{ mt: 2.4, mb: 1.6 }} />
        <Typography variant='body2' sx={{ display: 'block', mb: 4 }}>
          {t('md.contentTemplate.description')}
        </Typography>
        <AddressSection />
        <Divider sx={{ my: 4 }} />
        <LinksSection />
        <Divider sx={{ my: 4 }} />
        <FilesSection />
      </Box>
    </Box>
  );
};
