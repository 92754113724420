import { InfoOutlined } from '@mui/icons-material';
import { CampaignSelector } from './CampaignSelector';
import { useCallback, useEffect, useState } from 'react';
import { getCampaigns, getCrmConfiguration } from '@/infrastructure/apis/md/crmIntegration';
import { CrmCampaign, CrmServiceConfguration } from '@/shared/types/api';
import { useTranslation } from 'react-i18next';
import { RenderCrmConfigurations } from './RenderCrmConfigurations';
import { Typography } from '@mui/material';
import { LtDialog } from '@/components';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  selectedCrmConnectorToExport: { serviceId: string; serviceName: string } | null;
  exporting: boolean;
  onConfirm: (campaignId: string, configurationValues?: Record<string, string | boolean>) => void;
  selectedCount: number;
  onCancel: () => void;
  isAllSelected: boolean;
};
export const ExportConfirmationDialog = ({
  exporting,
  onConfirm,
  selectedCrmConnectorToExport,
  selectedCount,
  onCancel,
  isAllSelected,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const [campaigns, setCampaigns] = useState<CrmCampaign[]>([]);
  const [configurations, setConfigurations] = useState<CrmServiceConfguration[]>([]);
  const [configurationValues, setConfigurationValues] = useState<Record<string, string | boolean>>(
    {},
  );
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');

  const fetchCampaigns = useCallback(
    async (refresh?: boolean) => {
      try {
        const { data } = await getCampaigns(getAccessTokenSilently, { refresh });
        setCampaigns(data);
      } catch (error) {}
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    if (selectedCrmConnectorToExport?.serviceId !== 'salesforce') return;
    fetchCampaigns();
  }, [fetchCampaigns, selectedCrmConnectorToExport?.serviceId]);

  const fetchConfigurations = useCallback(
    async (refresh?: boolean) => {
      try {
        const { data } = await getCrmConfiguration(
          getAccessTokenSilently,
          selectedCrmConnectorToExport.serviceId,
          { refresh },
        );
        setConfigurations(data?.configurations || []);
        // prefill with globally defined values
        setConfigurationValues(prev => {
          const newValues = { ...prev };
          data?.configurations.forEach(config => {
            if (!(config.id in prev) && config.value) {
              newValues[config.id] = config.value;
            }
          });
          return newValues;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [getAccessTokenSilently, selectedCrmConnectorToExport?.serviceId],
  );

  useEffect(() => {
    if (!selectedCrmConnectorToExport?.serviceId) return;

    fetchConfigurations();
  }, [fetchConfigurations, selectedCrmConnectorToExport?.serviceId]);

  useEffect(() => {
    if (!selectedCrmConnectorToExport) {
      setSelectedCampaignId('');
      setConfigurationValues({});
    }
  }, [selectedCrmConnectorToExport]);

  return (
    <LtDialog
      open={Boolean(selectedCrmConnectorToExport)}
      loading={exporting}
      onConfirm={() => onConfirm(selectedCampaignId, configurationValues)}
      onCancel={onCancel}
      onClose={onCancel}
      title={t('mdLeads.crmExport.confirmDialog.title')}
      titleIcon={<InfoOutlined />}
    >
      <Typography variant='body1' mb={2}>
        {isAllSelected
          ? t('mdLeads.crmExport.confirmDialog.textAll', {
              name: selectedCrmConnectorToExport?.serviceName,
            })
          : t('mdLeads.crmExport.confirmDialog.text', {
              count: selectedCount,
              name: selectedCrmConnectorToExport?.serviceName,
            })}
      </Typography>

      {selectedCrmConnectorToExport?.serviceId === 'salesforce' && (
        <CampaignSelector
          value={selectedCampaignId}
          onSelect={setSelectedCampaignId}
          campaigns={campaigns}
          onRefresh={() => fetchCampaigns(true)}
        />
      )}

      <RenderCrmConfigurations
        configurations={configurations}
        values={configurationValues}
        onChange={setConfigurationValues}
        onRefresh={() => fetchConfigurations(true)}
      />
    </LtDialog>
  );
};
