import TooltipInfo from '@/components/TooltipInfo';
import { Box, BoxProps, Typography } from '@mui/material';

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  subHeader?: string;
  tooltipText?: string;
  showDivider?: boolean;
} & BoxProps;

export const Section = ({
  children,
  title,
  subTitle,
  tooltipText,
  subHeader,
  showDivider,
  ...rest
}: Props) => {
  return (
    <Box {...rest}>
      <Box display='flex' alignItems='center' mb={1.6}>
        <Typography variant='h3' mr='1.6rem'>
          {title}
        </Typography>
        {subHeader && (
          <Typography variant='body2' color='text.secondary'>
            {subHeader}
          </Typography>
        )}
        {tooltipText && (
          <TooltipInfo text={tooltipText} placement='right' icon={{ color: 'text.primary' }} />
        )}
      </Box>
      {subTitle && (
        <Typography variant='body2' mb={1.6} color='secondary'>
          {subTitle}
        </Typography>
      )}
      <Box>{children}</Box>
      {showDivider && <Box mt={3.5} mb={3} borderBottom={1} borderColor='divider' />}
    </Box>
  );
};
