import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './application/reducers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import GlobalErrorBoundary from '@/components/ErrorBoundary';
import { I18nextProvider } from 'react-i18next';
import i18nMain from './config/i18n';
import { Helmet } from 'react-helmet';
import LtAuth0Provider from './infrastructure/hoc/LtAuth0Provider';
import { InternalDesignProvider } from './config/theme/useMuiTheme';

declare global {
  interface Window {
    FreshworksWidget?: Function;
    UC_UI: {
      showSecondLayer: Function;
      updateLanguage(lang: string): void;
      isInitialized(): boolean;
    };
  }
}

export const fontFamily: React.CSSProperties['fontFamily'] = 'Poppins, sans-serif';

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']?.({
    trace: true,
    traceLimit: 25,
  }) as typeof compose) || compose;

const middleware = [thunk];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const fontStyles = `
  body ,
  textarea,
  textarea::placeholder,
  input,
  input::placeholder,
  .MuiTooltip-tooltip,
  label {
    font-family: ${fontFamily};
  }
`;

ReactDOM.render(
  <>
    <Helmet>
      <style>{fontStyles}</style>
    </Helmet>
    <Provider store={store}>
      <LtAuth0Provider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <I18nextProvider i18n={i18nMain}>
            <InternalDesignProvider>
              <GlobalErrorBoundary>
                <App />
              </GlobalErrorBoundary>
            </InternalDesignProvider>
          </I18nextProvider>
        </LocalizationProvider>
      </LtAuth0Provider>
    </Provider>
  </>,
  document.getElementById('root'),
);
