// import { AnchorText } from '../AnchorText';
import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';

export const BeforeYouBeginStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextsRenderer
        title={t('idp.scim.steps.beforeYouBegin.title')}
        texts={[
          // t('idp.scim.steps.beforeYouBegin.description'),
          t('idp.scim.steps.beforeYouBegin.description2'),
          t('idp.scim.steps.beforeYouBegin.description3'),
        ]}
      />
      {/* <AnchorText href='#' text={t('idp.scim.learnMoreAboutScim')} /> */}
    </>
  );
};
