import ThemeProvider from '@mui/system/ThemeProvider';
import { Route, RouteProps } from 'react-router-dom';
import { useMuiTheme } from './config/theme/useMuiTheme';
import Loader from './components/Loader';

interface Props {
  exact?: boolean;
  path: string;
  component: RouteProps['component'];
}

export const RouteWithMuiTheme = ({ path, exact, component }: Props) => {
  const { theme: muiTheme, isFetching, globalStyles } = useMuiTheme();

  if (isFetching) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={muiTheme}>
      {globalStyles}
      <Route exact={exact} path={path} component={component} />
    </ThemeProvider>
  );
};
