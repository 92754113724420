import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect, useCallback } from 'react';
import { Account } from '../../shared/types/api';
import useDeferredLoader from './useDeferredLoader';
import { useTranslation } from 'react-i18next';
import { getBlockedFields } from '../apis/account';
import useLtNotifications from '../notifications/useLtNotifications';

export const useBlockedFields = () => {
  const { notify } = useLtNotifications();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [blockedFields, setBlockedFields] = useState<Array<keyof Account> | null>(null);
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [blockedFieldsLoading, setBlockedFieldsLoading] = useState<boolean>(true);

  const isFieldBlocked = useCallback(
    (field: keyof Account) => {
      return disableFields || blockedFields?.includes(field);
    },
    [blockedFields, disableFields],
  );

  useDeferredLoader(blockedFieldsLoading, 'blocked-fields-toast');

  useEffect(() => {
    const fetchBlockedFields = async () => {
      setBlockedFieldsLoading(true);
      try {
        const { data: _blockedFields } = await getBlockedFields(getAccessTokenSilently);
        setBlockedFields(_blockedFields);
      } catch (error) {
        notify.error(t('error.general'), { id: 'blocked-fields-toast' });
        setDisableFields(true);
      }
      setBlockedFieldsLoading(false);
    };
    fetchBlockedFields();
  }, [getAccessTokenSilently, t, notify]);

  return { blockedFields, disableFields, isFieldBlocked };
};
