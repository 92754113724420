import { useTranslation } from 'react-i18next';
import { isValidHttpUrl } from '@/infrastructure/validations';
import { ProfileDesignConfig } from '@/shared/types/api';
import { Box, TextField } from '@mui/material';

type Props = {
  privacyUrl: string;
  imprintUrl: string;
  onChange: (field: keyof ProfileDesignConfig, value: string | boolean) => void;
};

export const PolicyForm = (props: Props) => {
  const { t } = useTranslation();
  const { privacyUrl, imprintUrl, onChange } = props;
  const isURLValid = (value: string) => isValidHttpUrl(value) || value.trim() === '';

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    transformFct?: (arg: string) => string,
  ) => {
    const { name } = e.target;
    let { value } = e.target;
    if (transformFct) {
      value = transformFct(value);
    }
    onChange(name as keyof ProfileDesignConfig, value);
  };

  return (
    <Box>
      <TextField
        label={t('linkToImprint')}
        name={'imprintUrl'}
        value={imprintUrl}
        onChange={handleInputChange}
        fullWidth
        helperText={!isURLValid(imprintUrl) && 'This is not a valid webstite'}
        placeholder={'https://'}
        sx={{ mb: '2.4rem' }}
      />

      <TextField
        label={t('linkToPrivacyPolicy')}
        name={'privacyUrl'}
        value={privacyUrl}
        onChange={handleInputChange}
        fullWidth
        helperText={!isURLValid(privacyUrl) && 'This is not a valid webstite'}
        placeholder={'https://'}
      />
    </Box>
  );
};
