import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { imageSizeValidator } from '@/views/pages/qrcode-background/common/utils';
import { QRBGMode } from '@/views/pages/qrcode-background/common/types';
import { Box, CardContent, Typography } from '@mui/material';
import { Dropzone } from '@/components';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { isFileValid, isNotEmptyArray } from '@/infrastructure/helper';
import { ALLOWED_FORMATS, UPLOAD_LIMIT } from '@/views/pages/qrcode-background/common/constants';
import { SingleListImage } from '../../../SingleListImage';
import { extension } from 'mime-types';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import ImageCropper from '@/components/ImageCropper';

interface Props {
  mode: QRBGMode;
  additionalImages: Array<string>; // should be Array<FIle / string>
  setAdditionalImages: Dispatch<SetStateAction<Array<any>>>; // should be Array<FIle / string>;
}

type ImageType = { name: string; path: string };

const AdditionalImages = (props: Props) => {
  const { notify: toast } = useLtNotifications();
  const { t } = useAppTranslation();

  const { mode } = props;

  const [_additionalImages, _setAdditionalImages] = useState<ImageType[]>(null);
  const [imageCount, setImageCount] = useState(0);
  const [showCropper, setShowCropper] = useState('');

  useEffect(() => {
    if (!_additionalImages && props?.additionalImages) {
      _setAdditionalImages(
        (props?.additionalImages || []).map(x => {
          return {
            name: x,
            path: x,
          };
        }),
      );
    }
  }, [_additionalImages, props?.additionalImages]);

  const onImageSelection = async (files: File[]) => {
    if (isNotEmptyArray(files)) {
      let uploadedFile = files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => setShowCropper(reader.result as string);
        return;
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    // validate uploaded image size
    const error = await imageSizeValidator(croppedImageUrl, mode);
    if (error && error.length) {
      toast.error(error);
      return;
    }

    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = extension(blob.type);
    setShowCropper('');

    const fileName = `qr-background-${mode}/${imageCount}.${ext}`;

    setImageCount(curCount => curCount + 1);
    const file = new File([blob], fileName);
    props.setAdditionalImages([...props.additionalImages, file]);
    _setAdditionalImages(current => [...current, { name: fileName, path: croppedImageUrl }]);
  };

  const handleX = (file: ImageType) => {
    const filterFn = (img: File | string) => {
      if (img instanceof File) return img.name !== file.name;
      else return img !== file.path;
    };

    _setAdditionalImages(images => images.filter(image => image.name !== file.name));
    props.setAdditionalImages(images => images.filter(filterFn));
  };

  return (
    <>
      {showCropper && (
        <ImageCropper
          image={showCropper}
          onCropClick={url => onUploadClick(url)}
          onClose={() => setShowCropper('')}
          noAspectRatio={true}
          fixedCrop={true}
          cropOptions={{ width: 100, height: 100 }}
        />
      )}
      <Box p={2}>
        <CardContent>
          <Typography variant='body2' mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
            {t('sharingDesign.qrbg.additionalImages')}
          </Typography>
          <Dropzone
            maxSize={10}
            types={ALLOWED_FORMATS}
            onChange={files => onImageSelection(files)}
            fullWidth
            extensionsText={t('sharingDesign.qrbg.uploadSubtext', { limit: UPLOAD_LIMIT[mode] })}
          />
          <Box sx={{ width: '100%', mt: 3 }}>
            {Boolean(_additionalImages) && (
              <Box display='flex' flexWrap={'wrap'} gap={1}>
                {_additionalImages.map((file, index) => (
                  <SingleListImage
                    key={index}
                    src={file.path}
                    onDeleteClick={() => handleX(file)}
                  />
                ))}
              </Box>
            )}
          </Box>
        </CardContent>
      </Box>
    </>
  );
};

export default AdditionalImages;
