import config from '../../../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../../../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { InputFormSaveProps } from '../../../../views/pages/md/profiles/common/inputs-form';
import { AccountImageState, ApiFile, BulkFile, Link } from '../../../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';
import { doS3UploadForBusiness } from '../../aws';
import { saveFile } from './files';
import { updateImageUrl } from '.';

export const _bulkUpdateInfo = async (
  ids: readonly string[],
  toUpdateAccounts: InputFormSaveProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = Object.assign({ employeeIds: ids }, toUpdateAccounts);
  const response = await axios.post(config.API_BASE_URL + 'business/bulkUpdateEmployees', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkUpdateRoles = async (
  ids: readonly string[],
  roles: string[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = { ids, roles };
  const response = await axios.post(config.API_BASE_URL + 'business/bulkUpdateRoles', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkUpdateLinks = async (
  ids: readonly string[],
  toUpdateLinks: Link[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = Object.assign({ ids }, { toUpdateLinks });
  const response = await axios.post(config.API_BASE_URL + 'business/bulkUpdateLinks', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

const getLinkPromise = (
  file: ApiFile & { fileToUpload?: File },
  id: string,
  username: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return file.fileToUpload instanceof File
    ? doS3UploadForBusiness(`file/${username}`, file.fileToUpload, id, getAccessTokenSilently)
    : new Promise(res => res(file.link));
};

export const _bulkUploadFiles = async (
  ids: readonly string[],
  usernames: readonly string[],
  files: (ApiFile & { fileToUpload?: File })[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const res: Promise<{ id: string; fileName: string; url: string; pageCount: number } | null>[] =
    files.reduce((acc, file) => {
      const promises = ids.map((id, index) => {
        const username = usernames[index];
        return new Promise(async (res, rej) => {
          const { fileName, pageCount } = file;
          try {
            const url = await getLinkPromise(file, id, username, getAccessTokenSilently);
            res({ id, fileName, url, pageCount });
          } catch (error) {
            res(null);
          }
        });
      });
      return [...acc, ...promises];
    }, []);

  const results = (await Promise.all(res)).filter(Boolean);

  try {
    await Promise.all(
      results.map(({ id, fileName, url, pageCount }) =>
        saveFile(id, fileName, url, pageCount, getAccessTokenSilently),
      ),
    );
  } catch (error) {}
};

export const _bulkExtendFiles = async (
  ids: readonly string[],
  file: BulkFile,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = { ids, file };
  const response = await axios.post(config.API_BASE_URL + 'business/bulkExtendFiles', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkSendEmail = async (
  ids: readonly string[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  let payload = { ids };

  const response = await axios.post(config.API_BASE_URL + 'business/bulkSendEmail', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _getCommonLinks = async (ids, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.READ.THEME_PROFILES),
  });

  const payload = { ids };
  const response = await axios.post(`${config.API_BASE_URL}link/commons`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const _getCommonFiles = async (ids, usernames, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.READ.THEME_PROFILES),
  });

  const payload = { ids, usernames };
  const response = await axios.post(`${config.API_BASE_URL}file/commons`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const _bulkDelete = async (
  ids: readonly string[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.DELETE.THEME_PROFILES),
  });

  let payload = { ids };

  try {
    const { data: response } = await axios.post(
      config.API_BASE_URL + 'business/bulkDeleteEmployees',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.isSuccess) {
      throw new Error(response.message);
    }
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.error?.message || error.message);
  }
};

export const _bulkUpdateImage = async (
  values: { id: string; url: string; imageState: AccountImageState }[],
  type: 'banner' | 'profile' | 'logoHeader',
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  try {
    await Promise.all(
      values.map(({ id, url, imageState }) =>
        updateImageUrl(id, url, type, imageState, getAccessTokenSilently),
      ),
    );
  } catch (error) {
    //
  }
};

export const _bulkUploadImage = async (
  ids: readonly string[],
  usernames: readonly string[],
  file: File,
  type: 'banner' | 'profile' | 'logoHeader',
  imageState: AccountImageState,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const promises: Promise<{ id: string; url: string } | null>[] = ids.map(
    (id, index) =>
      new Promise(async res => {
        const username = usernames[index];
        try {
          const url = await doS3UploadForBusiness(
            `${type}/${username}`,
            file,
            id,
            getAccessTokenSilently,
            type,
          );

          res({ id, url });
        } catch (error) {
          res(null);
        }
      }),
  );

  const results = (await Promise.all(promises)).filter(Boolean);

  await _bulkUpdateImage(
    results.map(x => ({ ...x, imageState })),
    type,
    getAccessTokenSilently,
  );
};
