import React from 'react';
import styled from 'styled-components';
import LtIconButton from './lt-icon-button';
import { ThemeBoxStyle } from '@/shared/types/global';
import nl2br from 'react-nl2br';

interface Props {
  headerText?: string;
  themeBoxStyle?: ThemeBoxStyle;
  flat?: boolean; // whether to render a card
  noPadding?: boolean;
  actionButton?: {
    icon: React.ReactElement;
    text: string;
    customBtnClasses?: string;
    onClick?: () => void;
    backgroundCss?: string;
  };
  children: React.ReactNode;
}

const WhiteLayout = (props: Props): JSX.Element => {
  const { headerText, themeBoxStyle, actionButton, flat, noPadding, children } = props;
  const content = (
    <>
      {(headerText || actionButton) && (
        <WhiteLayoutHeader>
          {headerText && <WhiteLayoutHeaderText>{nl2br(headerText)}</WhiteLayoutHeaderText>}
          <WhiteLayoutHeaderAction>
            {actionButton && (
              <LtIconButton
                text={actionButton.text}
                onClick={actionButton.onClick}
                boxStyle={themeBoxStyle}
                icon={actionButton.icon}
                backgroundCss={actionButton.backgroundCss}
                customClass={actionButton.customBtnClasses}
                style={{ fontSize: '1.4rem' }}
              />
            )}
          </WhiteLayoutHeaderAction>
        </WhiteLayoutHeader>
      )}
      <WhiteLayoutContent>{children}</WhiteLayoutContent>
    </>
  );
  if (flat) {
    return <>{noPadding ? <div>{content}</div> : <PaddedWrapper>{content}</PaddedWrapper>}</>;
  } else {
    return <WhiteLayoutWrapper themeBoxStyle={themeBoxStyle}>{content}</WhiteLayoutWrapper>;
  }
};

export default WhiteLayout;

const PaddedWrapper = styled.div`
  padding: 0 2rem;
`;

export const WhiteLayoutWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin: 24px 0;

  border-radius: ${(props: Props) => (props.themeBoxStyle === 'rounded' ? '1rem' : '0')};
`;

const WhiteLayoutHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.7rem;
`;

const WhiteLayoutHeaderText = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0;
  color: #55595e;
`;

const WhiteLayoutHeaderAction = styled.div`
  margin-left: auto;
`;

const WhiteLayoutContent = styled.div``;
