import { Box, Switch } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { TooltipText } from '../../TooltipText';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  title: string;
  tooltipText: string;
};

export const SwitchRow = ({ title, value, onChange, tooltipText }: Props) => {
  const id = useId();
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' id={id}>
      <TooltipText label={title} tooltipText={tooltipText} />
      <Switch
        checked={value}
        onChange={() => onChange(!value)}
        inputProps={{
          'aria-labelledby': id,
        }}
      />
    </Box>
  );
};
