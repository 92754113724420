import { isPhoneFaxField } from '@/views/pages/edit-bio/utils';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, FieldValue, ImageValue, isTextField, UnitValue } from '../utils';
import { toOnlyNumbersAndSpacesAndDashes } from '@/infrastructure/helper';
import { AccountImageState, ApiFile, Link } from '@/shared/types/api';
import { Links } from './Links';
import { Files } from './Files';
import { RoleSelector } from './RoleSelector';
import { Units } from './Units';
import { Employee } from '@/shared/types/api/employee.type';
import Banner from '../../common/images/banner';
import ProfilePicture from '../../common/images/profile-picture';
import LogoHeader from '../../common/images/logo-header';

type Props = {
  fieldValue: FieldValue;
  onChange: (value: FieldValue) => void;
  selectedField: Field;
  selectedEmployees: Employee[];
};

export const FieldRenderer = ({
  selectedField,
  fieldValue,
  onChange,
  selectedEmployees,
}: Props) => {
  const { t } = useTranslation();

  const isPhoneFieldSelected = selectedField && isPhoneFaxField(selectedField);

  if (!selectedField) {
    return null;
  }

  if (isTextField(selectedField)) {
    return (
      <TextField
        label={t('value')}
        value={fieldValue || ''}
        onChange={({ target: { value } }) =>
          onChange(isPhoneFieldSelected ? toOnlyNumbersAndSpacesAndDashes(value) : value)
        }
        fullWidth
        required
        InputProps={isPhoneFieldSelected && { startAdornment: <Box mr={0.5}>+</Box> }}
      />
    );
  }

  if (selectedField === 'links') {
    return <Links links={(fieldValue || []) as Link[]} onChange={onChange} />;
  }

  if (selectedField === 'files') {
    return <Files files={(fieldValue || []) as ApiFile[]} onChange={onChange} />;
  }

  if (selectedField === 'accountRole') {
    return <RoleSelector value={(fieldValue as string) || ''} onChange={onChange} />;
  }

  if (selectedField === 'unit') {
    return (
      <Units
        onChange={onChange}
        value={fieldValue as UnitValue}
        selectedEmployees={selectedEmployees}
      />
    );
  }

  if (selectedField === 'bannerImageUrl') {
    const val = fieldValue as ImageValue;
    return (
      <Banner
        bannerImgOptions={[]}
        bannerImageState={val?.state || AccountImageState.DEFAULT}
        bannerImageUrl={val?.url || ''}
        onChange={(file, url, state) => onChange({ url, file, state })}
      />
    );
  }

  if (selectedField === 'profileImageUrl') {
    const val = fieldValue as ImageValue;
    return (
      <Box m='auto' width='max-content'>
        <ProfilePicture
          profileImageState={val?.state || AccountImageState.DEFAULT}
          profileImageUrl={val?.url || ''}
          onChange={(file, url, state) => onChange({ url, file, state })}
          isButton
          hideDeleteOption
        />
      </Box>
    );
  }

  if (selectedField === 'logoHeaderUrl') {
    const val = fieldValue as ImageValue;
    return (
      <LogoHeader
        logoHeaderState={val?.state || AccountImageState.DEFAULT}
        logoHeaderUrl={val?.url || ''}
        onChange={(file, url, state) => onChange({ url, file, state })}
      />
    );
  }

  return null;
};
