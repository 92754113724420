import {
  Checkbox,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import {
  ArrowDropDown,
  EditOutlined,
  Replay as ReplayIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { Dispatch, SetStateAction } from 'react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import {
  ColumnDefinition,
  NON_SORTABLE_COLUMNS,
} from '@/views/pages/md/profiles/table/utils/constants';
import moment from 'moment';
import {
  getFullName,
  getProfileImageUrlWithGlobalDefault,
} from '@/shared/business-logic/features/profile/getter';
import { isPhoneFaxField } from '@/views/pages/edit-bio/utils';
import { getPhone, handleProfileClick } from '@/views/pages/md/profiles/table/utils/helpers';
import {
  CustomTableCell as CustomTableCellGeneric,
  CustomTableCellHeader,
} from '@/components/Table';
import SortableCustomTableCellHeader from '@/components/Table/SortableTableCellHeader';
import { isDateField } from '@/components/Table/common';
import { SortOption } from '@/shared/types/global';
import { Employee } from '@/shared/types/api/employee.type';
import { getMainUnitSync, getOtherUnitsSync } from '@/shared/util';
import { CustomTableCellProps } from '@/components/Table/CustomTableCell';
import LinksCell from './linksCell';
import FilesCell from './filesCell';
import { Account, ApiFile, Link } from '@/shared/types/api';
import { ACCOUNT_STATUSES } from '@/shared/constants';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { SROnly } from '@/components';

const lineClampCount = 1;

const CustomTableCell = (props: CustomTableCellProps) => (
  <CustomTableCellGeneric {...props} lineClampCount={lineClampCount} />
);

const EnhancedTableRow = ({
  employee,
  onSelect,
  isSelected,
  onEditClick,
  columns,
  fileProps,
  linkProps,
  sendInvitationEmail,
}: {
  employee: Employee;
  onSelect: () => void;
  isSelected: boolean;
  onEditClick: (employeeId: string) => void;
  columns: ColumnDefinition[];
  linkProps: ProfilesTableProps['linkProps'];
  fileProps: ProfilesTableProps['fileProps'];
  sendInvitationEmail: (employee: Employee) => void;
}) => {
  const { t } = useAppTranslation();

  const rowActions = [
    { icon: <EditOutlined />, label: t('edit'), onClick: () => onEditClick(employee.id) },
    {
      icon: <VisibilityIcon />,
      label: t('preview'),
      onClick: () => handleProfileClick(employee.username),
    },
  ];
  const statusColumnActions = [
    employee.status === ACCOUNT_STATUSES.INVITED && {
      icon: <ReplayIcon />,
      label: t('resendInvite'),
      onClick: () => sendInvitationEmail(employee),
    },

    employee.status === ACCOUNT_STATUSES.UNINVITED && {
      icon: <SendIcon />,
      label: t('sendInvite'),
      onClick: () => sendInvitationEmail(employee),
    },
  ].filter(Boolean);

  const mainUnit = getMainUnitSync(employee);
  const { profileDesign } = useProfileDesignForUnits(mainUnit?.id || null);

  const avatar = getProfileImageUrlWithGlobalDefault(employee, profileDesign);

  const userRole = employee.isThemeAdmin
    ? t('employeeType.themeAdmin')
    : employee.isUnitAdmin
    ? t('employeeType.unitAdmin')
    : t('employeeType.employee');

  const name = getFullName({
    namePrefix: employee.namePrefix,
    firstName: employee.firstName,
    lastName: employee.lastName,
  });

  const otherUnitsNames = getOtherUnitsSync(employee)
    .map(u => u?.niceName)
    .join(', ');

  const language =
    (employee?.preferredLang &&
      (employee?.preferredLang?.startsWith('de') ? t('german') : t('english'))) ||
    '-';

  const status = t(getStatusTtext(employee.status));
  const mainUnitName = mainUnit?.niceName || '';

  return (
    <>
      <TableRow hover aria-selected={isSelected} key={employee.id} selected={isSelected}>
        <TableCell
          padding='checkbox'
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Checkbox
            color='primary'
            onClick={() => onSelect()}
            checked={isSelected}
            inputProps={{ 'aria-label': t('ariaSelect', { name }) }}
          />
        </TableCell>

        <CustomTableCellGeneric
          tooltipText={name}
          noPadding
          dim='l'
          renderAvatar
          avatarSrc={avatar}
          actions={rowActions}
          onClick={() => onEditClick(employee.id)}
          onAvatarClick={() => handleProfileClick(employee.username)}
          actionsBtnAriaLabel={t('ariaSettings', { name })}
          avatarAriaLabel={t('ariaEditUser', { name })}
        >
          {name}
        </CustomTableCellGeneric>
        {columns.map(({ key }) => {
          switch (key) {
            case 'accountRole':
              return <CustomTableCell tooltipText={userRole}>{userRole}</CustomTableCell>;
            case 'status':
              return (
                <CustomTableCell
                  key={key}
                  tooltipText={status}
                  actions={statusColumnActions}
                  actionsIcon={<ArrowDropDown />}
                  noPadding
                  sx={{ pl: 1.6 }}
                  actionsBtnAriaLabel={t('ariaProfileStatusActions', { name })}
                >
                  {status}
                </CustomTableCell>
              );
            case 'mainUnit':
              return (
                <CustomTableCell key={key} tooltipText={mainUnitName}>
                  {mainUnitName}
                </CustomTableCell>
              );
            case 'otherUnits':
              return (
                <CustomTableCell key={key} tooltipText={otherUnitsNames}>
                  {otherUnitsNames}
                </CustomTableCell>
              );
            case 'unit':
              return (
                <CustomTableCell key={key} tooltipText={mainUnitName}>
                  {mainUnitName}
                </CustomTableCell>
              );
            case 'language':
              return (
                <CustomTableCell key={key} tooltipText={language}>
                  {language}
                </CustomTableCell>
              );
            case 'links':
              return (
                <CustomTableCell key={key}>
                  <LinksCell selectedLinks={linkProps} links={employee?.links || []} />
                </CustomTableCell>
              );
            case 'files':
              return (
                <CustomTableCell key={key}>
                  <FilesCell selectedFiles={fileProps} files={employee?.files || []} />
                </CustomTableCell>
              );
            default:
              const isDate = isDateField(key);
              const isPhone = isPhoneFaxField(key);
              const text = isDate
                ? moment(employee[key]).format('L LT')
                : isPhone
                ? getPhone(employee[key])
                : employee[key];

              return (
                <CustomTableCell key={key} tooltipText={text}>
                  {text}
                </CustomTableCell>
              );
          }
        })}
      </TableRow>
    </>
  );
};

type ProfilesTableProps = {
  employees: Employee[];
  onCheckboxClick: (employee: Employee) => void;
  onHeaderCheckboxClick: () => void;
  selectedItems: { id: string }[];
  isAllSelected: boolean;
  isAnySelectedOnCurrentPage: boolean;
  onEditClick: (employeeId: string) => void;
  columns: ColumnDefinition[];
  sortOptions: SortOption;
  onSortOptionsChange: (sortOptions: SortOption) => void;
  linkProps: {
    selectedLink: Link;
    setSelectedLink: Dispatch<SetStateAction<Link>>;
  };
  fileProps: {
    selectedFile: ApiFile;
    setSelectedFile: Dispatch<SetStateAction<ApiFile>>;
  };
  sendInvitationEmail: (employee: Employee) => void;
};

export const ProfilesTable = ({
  employees,
  onCheckboxClick,
  onHeaderCheckboxClick,
  isAllSelected,
  isAnySelectedOnCurrentPage,
  selectedItems,
  onEditClick,
  columns,
  sortOptions,
  onSortOptionsChange,
  linkProps,
  fileProps,
  sendInvitationEmail,
}: ProfilesTableProps) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      sx={(theme: Theme) => ({
        flex: '1',
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      <MuiTable stickyHeader sx={{ minWidth: 750 }}>
        <SROnly component='caption'>{t('ariaProfilesTableCaption')}</SROnly>
        <TableHead>
          <TableRow sx={{ bgcolor: 'white' }}>
            <TableCell
              padding='checkbox'
              sx={theme => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Checkbox
                color='primary'
                indeterminate={isAnySelectedOnCurrentPage}
                checked={isAllSelected}
                onChange={onHeaderCheckboxClick}
                inputProps={{ 'aria-label': t('selectAll') }}
              />
            </TableCell>
            <SortableCustomTableCellHeader
              column={{ key: 'lastName', tkey: 'fullName' }}
              sortOptions={sortOptions}
              onSortOptionsChange={onSortOptionsChange}
              lineClampCount={lineClampCount}
            />
            {columns.map(column =>
              NON_SORTABLE_COLUMNS.includes(column.key) ? (
                <CustomTableCellHeader lineClampCount={lineClampCount} tooltipText={t(column.tkey)}>
                  {t(column.tkey)}
                </CustomTableCellHeader>
              ) : (
                <SortableCustomTableCellHeader
                  column={column}
                  sortOptions={sortOptions}
                  onSortOptionsChange={onSortOptionsChange}
                  lineClampCount={lineClampCount}
                />
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee, index) => (
            <EnhancedTableRow
              key={employee.id}
              employee={employee}
              onSelect={() => onCheckboxClick(employee)}
              isSelected={selectedItems.some(({ id }) => id === employee.id)}
              onEditClick={onEditClick}
              columns={columns}
              linkProps={linkProps}
              fileProps={fileProps}
              sendInvitationEmail={sendInvitationEmail}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

const getStatusTtext = (status: Account['status']) => {
  switch (status) {
    case ACCOUNT_STATUSES.ACTIVE:
      return 'statusActive';
    case ACCOUNT_STATUSES.INVITED:
      return 'statusInvited';
    case ACCOUNT_STATUSES.UNINVITED:
      return 'statusUninvited';
  }
};
