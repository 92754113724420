import React from 'react';
import { Box, styled } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';

import useDesktopView from '../hooks/useDesktopView';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { Sidebar } from '@/components/Sidebar';
import { BottomBarConfig, HeaderProps } from './types';
import { BoxMobileBottomBarRelative, MobileBottomBar } from '@/components/MobileBottomBar.tsx';
import { BoxHeaderRelative, Header } from '@/components/Header';
import { CSS_VARS } from '../constants';
import MobilePopup from '@/components/MobilePopup';
import FreeTrialBanner from '@/components/FreeTrialBanner';

type HeaderConfig = HeaderProps;

const withNav =
  <TProps extends Object>(
    Component: React.FC<TProps>,
    headerConfig: HeaderConfig,
    bottomBarConfig: BottomBarConfig,
  ) =>
  (props: TProps) => {
    const { desktopView } = useDesktopView();

    const { theme } = useMuiTheme();

    if (desktopView) {
      return (
        <Box display='flex'>
          <ThemeProvider theme={theme}>
            <Sidebar />
          </ThemeProvider>
          <DesktopContentContainer>
            <ThemeProvider theme={theme}>
              <Header {...headerConfig} />
            </ThemeProvider>
            <main>
              <ThemeProvider theme={theme}>
                <FreeTrialBanner />
                <MobilePopup />
              </ThemeProvider>
              <Component {...props} />
            </main>
          </DesktopContentContainer>
        </Box>
      );
    } else {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Header {...headerConfig} />
            <BoxHeaderRelative />
          </ThemeProvider>
          <main>
            <ThemeProvider theme={theme}>
              <FreeTrialBanner />
              <MobilePopup />
            </ThemeProvider>
            <Component {...props} />
          </main>
          <ThemeProvider theme={theme}>
            <MobileBottomBar {...bottomBarConfig} />
            <BoxMobileBottomBarRelative />
          </ThemeProvider>
        </>
      );
    }
  };

export default withNav;

const DesktopContentContainer = styled(Box)({
  flexGrow: 1,
  height: '100vh',
  overflowY: 'auto',
  paddingTop: CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR,
});
