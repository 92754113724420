import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { THEME_BOX_STYLES } from '@/shared/constants';
import { useTranslation } from 'react-i18next';

export const BoxForm = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();
  return (
    <FormControl {...rest}>
      <RadioGroup row value={value} onChange={({ target: { value } }) => onChange(value)}>
        <FormControlLabel
          value={THEME_BOX_STYLES.ROUNDED}
          control={<Radio />}
          label={t('rounded')}
        />
        <FormControlLabel
          value={THEME_BOX_STYLES.ANGULAR}
          control={<Radio />}
          label={t('squared')}
        />
      </RadioGroup>
    </FormControl>
  );
};
