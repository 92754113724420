import { routePaths } from '@/infrastructure/constants';
import { FeatureFlagGuard, PageContainer } from '@/components';
import { Box, Button, Grid, Paper, Typography, Link } from '@mui/material';
import { customLightPalette } from '@/config/theme/palette';
import { IdpOption, idpOptionsArray } from '@/shared/constants/Idp';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import withNav from '@/infrastructure/hoc/withNav';
import { CreateIdpPopup } from './components/CreateThemIdpPopup';
import { useAuth0 } from '@auth0/auth0-react';
import { createThemeIdp } from '@/infrastructure/apis/md/idp';
import { useHistory } from 'react-router-dom';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE, THEME_CONFIG_KEYS } from '@/shared/constants';
import FeatureTeaser from '../teaser/featureTeaser';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

type IdentityCardProps = {
  idp: IdpOption;
  onSelect?: () => void;
};

const IdentityCard = ({ idp, onSelect }: IdentityCardProps) => {
  const { t } = useTranslation();
  return (
    <Paper sx={{ p: 1.5, borderRadius: '4px' }} elevation={0} variant='outlined'>
      <Box display='flex' alignItems='center'>
        <StyledImg src={idp.imgPath} alt={idp.label} />
        <Typography variant='body1' sx={{ ml: 1.5 }}>
          {idp.label}
        </Typography>
        {onSelect ? (
          <Button
            onClick={onSelect}
            sx={{ color: customLightPalette.anchor, ml: 'auto', textTransform: 'none' }}
            size='large'
            variant='text'
            aria-label={`${t('idp.choose')} ${idp.label}`}
          >
            {t('idp.choose')}
          </Button>
        ) : null}
      </Box>
    </Paper>
  );
};

const IDPPage = () => {
  const { notify: toast } = useLtNotifications();

  const [selectedIdp, setSelectedIdp] = useState<IdpOption | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (selectedIdp) {
      setOpen(true);
    }
  }, [selectedIdp]);

  useEffect(() => {
    if (!open) {
      const timer = setTimeout(() => {
        setSelectedIdp(null);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const { getAccessTokenSilently } = useAuth0();

  const handleAdd = async (idpNiceName: string) => {
    setLoading(true);
    try {
      const { data } = await createThemeIdp(getAccessTokenSilently, {
        niceName: idpNiceName,
        name: selectedIdp.value,
      });
      toast.success(t('idp.createSuccess'));
      if (data?.id) {
        history.push(`/md/settings/idps/themeIdps/${data.id}`);
      }

      setOpen(false);
    } catch {
      toast.error(t('idp.createError'));
    }
    setLoading(false);
  };

  const { config: helpUrls } = usePrivateThemeConfig<Record<string, string>>(
    THEME_CONFIG_KEYS.HELP_RESOURCE_URLS,
  );
  const helpArticleUrl = helpUrls?.['idps-root'];

  const { isFeatureAllowed } = useTierInfo();
  const isProfileSyncAllowed = isFeatureAllowed(FEATURE.PROFILE_SYNC);
  const isSsoAllowed = isFeatureAllowed(FEATURE.SSO);
  const isIamAllowed = isProfileSyncAllowed || isSsoAllowed;

  const { t } = useTranslation();
  return (
    <>
      <PageContainer maxWidth='xl' helpSidebarConfigKey='help-sidebar-idp-list'>
        <Typography variant='h2'>{t('idp.listPageTitle')}</Typography>
        <Typography variant='body1' color='secondary' sx={{ mb: 2, mt: 1 }}>
          {t('idp.listPageDescription')}{' '}
          {helpArticleUrl && (
            <Typography
              underline='none'
              component={Link}
              href={helpArticleUrl}
              target='_blank'
              variant='body1'
            >
              {t('learnMore')}.
            </Typography>
          )}
        </Typography>
        {!isIamAllowed && (
          <Box mb={2}>
            <FeatureTeaser fullWidth text={t('idp.teaserDescription')} />
          </Box>
        )}
        <Grid container spacing={3}>
          {idpOptionsArray.map(idp => (
            <Grid item xs={12} md={6} key={idp.value}>
              <IdentityCard
                idp={idp}
                onSelect={!isIamAllowed ? undefined : () => setSelectedIdp(idp)}
              />
            </Grid>
          ))}
        </Grid>
      </PageContainer>
      <CreateIdpPopup
        open={open}
        idp={selectedIdp}
        onClose={() => setOpen(false)}
        onAdd={handleAdd}
        loading={loading}
      />
    </>
  );
};

const StyledImg = styled('img')({
  width: '4.2rem',
  height: '4.2rem',
  objectFit: 'contain',
});

export default withNav(
  () => (
    <FeatureFlagGuard privateFlags={['iam']}>
      <IDPPage />
    </FeatureFlagGuard>
  ),
  {
    tTitle: 'idp.identityProvider',
  },
  {
    activeScreen: routePaths.IDP.THEME_IDPS,
  },
);
