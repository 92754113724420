import { createContext } from 'react';

export type LtNotificationsContextProps = {
  notifyInfo: (text: string) => void;
  notifySuccess: (text: string, toDismissId?: string) => void;
  notifyError: (text: string, toDismissId?: string) => void;
  notifyLoading: (id: string) => void;
  dismiss: (id: string) => void;
  dismissAll: () => void;
  notify: {
    success: (
      text: string,
      options?: {
        id: string;
      },
    ) => void;
    error: (
      text: string,
      options?: {
        id: string;
      },
    ) => void;
    loading: (
      text: string,
      options?: {
        id: string;
      },
    ) => void;
    dismiss: (id: string) => void;
  };
};

export const LtNotificationsContext = createContext<LtNotificationsContextProps>({
  notifyInfo: () => {},
  notifySuccess: () => {},
  notifyError: () => {},
  notifyLoading: () => {},
  dismiss: () => {},
  dismissAll: () => {},
  notify: {
    success: () => {},
    error: () => {},
    loading: () => {},
    dismiss: () => {},
  },
});
