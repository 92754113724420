import { useCallback, useEffect, useRef, useState } from 'react';
import { FullScreenContainer } from './FullScreenContainer';
import { Box } from '@mui/material';
import { VideoToolsOverlay } from './VideoToolsOverlay';
import { styled } from '@mui/system';
import { useIsMounted } from '@/infrastructure/hooks/useIsMounted';
import {
  MAX_FILE_SIZE,
  PAGE_CONTAINER_SELECTOR,
  CameraAvailabilityEnum,
  getCameras,
  getStream,
  getTorchAvailability,
  toggleTorch,
  stopStream,
  MAX_FILE_SELECT_SIZE,
} from '../helpers';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CameraNotAvailable } from './CameraNotAvailable';
import { routePaths } from '@/infrastructure/constants';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import Loader from '@/components/Loader';

const crop = (
  img: HTMLImageElement,
  cropCanvas: HTMLCanvasElement,
  useFullScreenVideo: boolean,
) => {
  const cropCanvasContext = cropCanvas.getContext('2d');
  const container = document.querySelector(PAGE_CONTAINER_SELECTOR);

  if (useFullScreenVideo) {
    const imgWidth = img.width;
    const imgHeight = img.height;

    const cropWidth = container.clientWidth;
    const cropHeight = cropWidth / 1.414;
    const cropX = (imgWidth - cropWidth) / 2;
    const cropY = (imgHeight - cropHeight) / 2;

    cropCanvas.width = cropWidth;
    cropCanvas.height = cropHeight;

    cropCanvasContext.drawImage(
      img,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );
  } else {
    const imgWidth = img.width;
    const imgHeight = img.height;
    const cropWidth = imgWidth;
    const cropHeight = imgWidth / 1.414;
    const cropX = 0;
    const cropY = (imgHeight - cropHeight) / 2;
    cropCanvas.width = cropWidth;
    cropCanvas.height = cropHeight;
    cropCanvasContext.drawImage(
      img,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );
  }

  return cropCanvas.toDataURL('image/jpeg');
};

const getDataUrl = (file: File) => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    if (!file) reject('No file');
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false,
    );

    reader.readAsDataURL(file);
  });
};

type Props = {
  onCapture: (
    result: { file: File; dataUrl: string | ArrayBuffer }[],
    isSelectedFile?: boolean,
  ) => void;
  onHelpClick?: () => void;
  useFullScreenVideo?: boolean;
};

const AbsoluteContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 100,
  display: 'flex',
  overflow: 'hidden',
});

const Video = styled('video')({
  margin: 'auto',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const Image = styled('img')(props => ({
  margin: 'auto',
  ...(props.useFullScreenVideo
    ? { position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }
    : { width: '100%' }),
}));

export const CaptureScreen = ({ onCapture, onHelpClick, useFullScreenVideo }: Props) => {
  const { notify: toast } = useLtNotifications();
  const history = useHistory();
  const { t } = useAppTranslation();

  const [cameraAvailability, setCameraAvailability] = useState(CameraAvailabilityEnum.LOADING);
  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>([]);
  const [currentStream, setCurrentStream] = useState<MediaStream | null>(null);
  const [isTorchOn, setIsTorchOn] = useState(false);
  const [frontImageSource, setFrontImageSource] = useState<string>('');
  const [backImageSource, setBackImageSource] = useState<string>('');
  const [isFront, setIsFront] = useState(true);

  const [imageFaceFlipped, setImageFaceFlipped] = useState(false);

  // note about videoRefElement
  // It has to be stored as state, since we need an additional re-render once the ref becomes available, so that we can show the camera stream
  // Otherwise (if stored as ref) the test in line 102 (`if (!currentStream || !videoRefElement)`) would return true and no stream would be shown on screen
  // This would happen _only_ on PWA standalone app added to the homescreen on an iPhone (and only in about 20% of the time)
  const [videoRefElement, setVideoRefElement] = useState<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cropCanvasRef = useRef<HTMLCanvasElement>(null);
  const frontImageRef = useRef<HTMLImageElement>(null);
  const backImageRef = useRef<HTMLImageElement>(null);

  const isTorchAvailable = getTorchAvailability(currentStream);

  const mounted = useIsMounted();

  useEffect(() => {
    if (!mounted) return;
    const fetchCameras = async () => {
      const cameras = await getCameras();
      setAvailableCameras(cameras);
    };
    fetchCameras();
  }, [mounted]);

  const startStreamIfPossible = useCallback(async (deviceId?: string | null) => {
    try {
      const stream = await getStream(deviceId);
      setCameraAvailability(CameraAvailabilityEnum.AVAILABLE);
      setCurrentStream(stream);
    } catch (error) {
      console.log('error', error);
      setCameraAvailability(CameraAvailabilityEnum.NOT_AVAILABLE);
    }
  }, []);

  useEffect(() => {
    if (!mounted) return;
    startStreamIfPossible(null);
  }, [mounted, startStreamIfPossible]);

  useEffect(() => {
    return () => {
      stopStream(currentStream);
    };
  }, [currentStream]);

  useEffect(() => {
    if (!currentStream || !videoRefElement) return;

    const video = videoRefElement;
    video.srcObject = currentStream;

    const adjustVideoSize = () => {
      const container = document.querySelector(PAGE_CONTAINER_SELECTOR);
      const containerWidth = container?.clientWidth || 0;
      const containerHeight = container?.clientHeight || 0;
      const videoRatio = video.videoWidth / video.videoHeight;

      if (useFullScreenVideo) {
        //fix height to container height if video height is smaller
        if (videoRatio > 1) {
          video.height = containerHeight;
          video.width = containerHeight * videoRatio;
          //fix width to container width if video width is smaller
        } else {
          video.width = containerWidth;
          video.height = containerWidth / videoRatio;
        }
      } else {
        video.width = containerWidth;
        if (video.clientHeight > containerHeight) {
          video.style.width = '100%';
          video.style.height = '100%';
          video.style.objectFit = 'cover';
        }
      }

      video.play();
    };

    video.addEventListener('loadeddata', adjustVideoSize);
    return () => {
      video.removeEventListener('loadeddata', adjustVideoSize);
    };
  }, [currentStream, videoRefElement, useFullScreenVideo]);

  const handleImageFaceFlipClick = () => {
    setIsFront(!isFront);

    //Set and leave true after first flip click
    setImageFaceFlipped(true);
  };

  const capture = () => {
    if (!currentStream) return;
    const video = videoRefElement;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.clientWidth;
    canvas.height = video.clientHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL('image/jpeg');
    if (isFront) {
      setFrontImageSource(dataUrl);
    } else {
      setBackImageSource(dataUrl);
    }
  };

  const handleCaptureCancel = () => {
    if (isFront) {
      setFrontImageSource('');
    } else {
      setBackImageSource('');
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const handleFileSelect = async e => {
    const files = e.target.files;
    if (!files.length) return;

    if (files.length > MAX_FILE_SELECT_SIZE) {
      toast.error(t('businessCardScanner.fileSelectMaxFileSelectError'));
      return;
    }

    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error(t('businessCardScanner.fileSelectSizeError'));
        return;
      }
    }

    const result = await Promise.all(
      [...files].map(async (file: File) => {
        const dataUrl = await getDataUrl(file);
        return { file, dataUrl };
      }),
    );

    onCapture(result, true);
  };

  const handleCaptureAccept = async () => {
    if (!frontImageSource && !backImageSource) return;
    const frontImage = frontImageRef.current;
    const backImage = backImageRef.current;
    const cropCanvas = cropCanvasRef.current;

    const result = await Promise.all(
      [frontImage, backImage].filter(Boolean).map(async img => {
        const croppedDataUrl = crop(img, cropCanvas, useFullScreenVideo);

        const res = await fetch(croppedDataUrl);
        const blob = await res.blob();

        const file = new File([blob], `${uuidv4()}.jpg`, {
          type: blob.type,
        });

        return {
          file,
          dataUrl: croppedDataUrl,
        };
      }),
    );

    onCapture(result);
  };

  const handleCameraChange = async () => {
    if (availableCameras.length <= 1) return;
    const currentStreamCameraId = currentStream?.getVideoTracks()[0].getSettings().deviceId;
    if (!currentStreamCameraId) return;

    const currentCameraIndex = availableCameras.findIndex(
      x => x.deviceId === currentStreamCameraId,
    );
    const nextIndex = (currentCameraIndex + 1) % availableCameras.length;
    const nextCamera = availableCameras[nextIndex];

    handleCaptureCancel();
    //Triggers efect to clean up the stream
    setCurrentStream(null);
    if (videoRefElement) videoRefElement.srcObject = null;

    setTimeout(() => {
      startStreamIfPossible(nextCamera.deviceId);
    }, 500);
  };

  const handleTorchToggle = () => {
    if (!currentStream || !isTorchAvailable) return;
    toggleTorch(currentStream);
    setIsTorchOn(!isTorchOn);
  };

  if (availableCameras.length === 0) return null;

  if (cameraAvailability === CameraAvailabilityEnum.LOADING) return <Loader />;

  if (cameraAvailability === CameraAvailabilityEnum.NOT_AVAILABLE)
    return <CameraNotAvailable onCloseClick={() => history.push(routePaths.CONTACTS.ROOT)} />;

  const isFrontFaceCaptured = Boolean(frontImageSource);
  const isBackFaceCaptured = Boolean(backImageSource);
  const isCurrentFaceCaptured = isFront ? isFrontFaceCaptured : isBackFaceCaptured;

  return (
    <FullScreenContainer>
      <Box
        width='100%'
        height='100%'
        bgcolor='black'
        position='relative'
        sx={{
          '.focus-styles': {
            outline: '3px solid white',
          },
        }}
      >
        <AbsoluteContainer sx={{ opacity: isCurrentFaceCaptured ? 0 : 1 }}>
          <Video ref={setVideoRefElement} playsInline muted />
        </AbsoluteContainer>

        <AbsoluteContainer sx={{ opacity: 0 }}>
          <canvas ref={canvasRef} />
          <canvas ref={cropCanvasRef} />
        </AbsoluteContainer>
        {frontImageSource && (
          <AbsoluteContainer sx={{ opacity: isFront ? 1 : 0 }}>
            <Image
              useFullScreenVideo={useFullScreenVideo}
              ref={frontImageRef}
              src={frontImageSource}
              alt=''
            />
          </AbsoluteContainer>
        )}
        {backImageSource && (
          <AbsoluteContainer sx={{ opacity: isFront ? 0 : 1 }}>
            <Image
              useFullScreenVideo={useFullScreenVideo}
              ref={backImageRef}
              src={backImageSource}
              alt=''
            />
          </AbsoluteContainer>
        )}
        <VideoToolsOverlay
          onFileSelect={handleFileSelect}
          onCaptureClick={capture}
          onCloseClick={() => history.goBack()}
          onCaptureCancel={handleCaptureCancel}
          onCaptureAccept={handleCaptureAccept}
          onCameraFlipClick={handleCameraChange}
          onTorchClick={handleTorchToggle}
          onHelpClick={onHelpClick}
          cameraFlipVisible={availableCameras?.length > 1}
          isTorchAvailable={isTorchAvailable}
          isTorchOn={isTorchOn}
          isCurrentFaceCaptured={isCurrentFaceCaptured}
          isFrontFaceCaptured={isFrontFaceCaptured}
          isBackFaceCaptured={isBackFaceCaptured}
          infoText={
            imageFaceFlipped
              ? isFront
                ? t('businessCardScanner.infoTextFront')
                : t('businessCardScanner.infoTextBack')
              : t('businessCardScanner.infoText')
          }
          isFrontFaceImage={isFront}
          onImageFaceFlipClick={handleImageFaceFlipClick}
        />
      </Box>
    </FullScreenContainer>
  );
};
