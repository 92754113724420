import { CrmConnectorType, LeadAPI } from '@/shared/types/api';
import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  IconButton,
  Theme,
  Typography,
  alpha,
} from '@mui/material';
import { getAvatar, getLeadDisplayName } from '../utils/helpers';
import { MoreVert } from '@mui/icons-material';
import moment from 'moment';
import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useState } from 'react';

import {
  DeleteOutline,
  DownloadOutlined,
  EditOutlined,
  NoteAddOutlined,
} from '@mui/icons-material';
import { openLink } from '@/util';
import config from '@/config/config';
import { CustomMenuList, LtDialog } from '@/components';
import { NoteAddEditPopup } from './NoteAddEditPopup';
import { useHistory } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { ExportEventRenderer } from './ExportEventRenderer';
import { LineClamp } from '@/components/LineClamp';

const SingleRow = ({
  lead,
  onCheckboxClick,
  isSelected,
  onDelete,
  onNoteAddEdit,
  crmConnectors,
  showCrmExport,
}: {
  lead: LeadAPI;
  isSelected: boolean;
  onCheckboxClick: () => void;
  onDelete: (leadId: number) => Promise<void>;
  onNoteAddEdit: (lead: LeadAPI, note: string) => Promise<void>;
  crmConnectors?: CrmConnectorType[];
  showCrmExport?: boolean;
}) => {
  const { id: accountId } = useAppSelector(state => state.account);
  const { activeLanguage, t } = useAppTranslation();

  const [notePopupOpened, setNotePopupOpened] = useState(false);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [noteSaving, setNoteSaving] = useState(false);

  const history = useHistory();

  const handleDownload = () => {
    openLink(
      `${config.API_BASE_URL}lead-gen/accounts/${accountId}/leads/${lead.id}/vcard?lang=${activeLanguage}`,
    );
  };

  const handleDelete = async () => {
    setDeleting(true);
    await onDelete(lead.id);
    setDeletePopupOpened(false);
    setDeleting(false);
  };

  const handleNoteAddEditSave = async (newNote: string) => {
    setNoteSaving(true);
    await onNoteAddEdit(lead, newNote);
    setNotePopupOpened(false);
    setNoteSaving(false);
  };

  const handleLeadEditClick = () => {
    history.push(routePaths.CONTACTS.EDIT.replace(':leadId', lead.id.toString()));
  };

  const rowActions = [
    {
      icon: <EditOutlined />,
      label: t('edit'),
      onClick: handleLeadEditClick,
    },
    {
      icon: <NoteAddOutlined />,
      label: t('leads.addEditNote'),
      onClick: () => setNotePopupOpened(true),
    },
    { icon: <DeleteOutline />, label: t('delete'), onClick: () => setDeletePopupOpened(true) },
    { icon: <DownloadOutlined />, label: t('download'), onClick: handleDownload },
  ];

  const fullName = getLeadDisplayName(lead);
  const avatar = getAvatar(lead);
  return (
    <>
      <Box display='flex' alignItems='center' px='1.6rem' py='2.4rem'>
        <Checkbox
          color='primary'
          checked={isSelected}
          onChange={onCheckboxClick}
          inputProps={{ 'aria-label': t('ariaSelect', { name: fullName }) }}
        />
        <Avatar
          sx={{ ml: 0.8, mr: 1.6, cursor: 'pointer' }}
          alt={fullName}
          src={avatar}
          onClick={handleLeadEditClick}
          aria-label={t('ariaEditContact', { name: fullName })}
        />
        <Box flex={1}>
          <Box sx={{ cursor: 'pointer' }} onClick={handleLeadEditClick}>
            <Box sx={{ typography: 'h3' }}>{fullName}</Box>
            <Typography variant='body2'>
              <LineClamp lineCount={1}>
                {lead.position}
                {lead.company && (
                  <>
                    {lead.position && <> {t('at')} </>}
                    {lead.company}
                  </>
                )}
              </LineClamp>
            </Typography>
          </Box>
          {showCrmExport && Boolean(crmConnectors?.length) && lead.crmExportEvents && (
            <Box display='flex' gap={1}>
              {lead.crmExportEvents?.map(event => (
                <ExportEventRenderer crmConnectors={crmConnectors} event={event} />
              ))}
            </Box>
          )}
        </Box>
        <CustomMenuList
          items={rowActions}
          toggleElement={
            <IconButton aria-label={t('ariaSettings', { name: fullName })} aria-haspopup='menu'>
              <MoreVert />
            </IconButton>
          }
        />
      </Box>
      <LtDialog
        title={t('requestDelete')}
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={handleDelete}
        loading={deleting}
      >
        {t('deleteLeadMsg')}
      </LtDialog>
      <NoteAddEditPopup
        opened={notePopupOpened}
        onClose={() => setNotePopupOpened(false)}
        onSave={handleNoteAddEditSave}
        saving={noteSaving}
        initialValue={lead.notes}
      />
    </>
  );
};

type Props = {
  leads: LeadAPI[];
  onCheckboxClick: (lead: LeadAPI) => void;
  selectedItems: { id: number }[];
  onDelete: (leadId: number) => Promise<void>;
  onNoteAddEdit: (lead: LeadAPI, note: string) => Promise<void>;
  crmConnectors?: CrmConnectorType[];
  showCrmExport?: boolean;
};

export const MobileLeadRows = ({
  leads,
  onCheckboxClick,
  onNoteAddEdit,
  onDelete,
  selectedItems,
  crmConnectors,
  showCrmExport,
}: Props) => {
  const set = new Set();

  return (
    <Box pb={5}>
      {leads.map(lead => {
        const date = moment(lead.createdOn).format('DD.MM.YYYY');
        const isAlreadyRendered = set.has(date);
        if (!isAlreadyRendered) {
          set.add(date);
        }
        return (
          <>
            {!isAlreadyRendered && (
              <Box
                color='secondary'
                sx={(theme: Theme) => ({
                  py: '0.4rem',
                  px: '1.6rem',
                  bgcolor: alpha(theme.palette.secondary.main, 0.04),
                  typography: 'subtitle2',
                })}
              >
                {date}
              </Box>
            )}
            {isAlreadyRendered && <Divider />}

            <SingleRow
              lead={lead}
              isSelected={selectedItems.some(item => item.id === lead.id)}
              onCheckboxClick={() => onCheckboxClick(lead)}
              onDelete={onDelete}
              onNoteAddEdit={onNoteAddEdit}
              crmConnectors={crmConnectors}
              showCrmExport={showCrmExport}
            />
          </>
        );
      })}
    </Box>
  );
};
