import { useAppSelector } from '@/application/hooks';
import { routePaths } from '@/infrastructure/constants';
import { isDarkHex } from '@/infrastructure/helper';
import { Theme } from '@/shared/types/api';
import LTIcon from '@/views/images/lemontap-icon.png';
import LTLogo from '@/views/images/lemontaps-logo.png';
import { Link } from 'react-router-dom';
import { Box, CardMedia, styled } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { useTranslation } from 'react-i18next';

export const Logo = () => {
  const { t } = useTranslation();
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const themeInternal = theme?.themeInternal;
  const collapsed = useAppSelector(state => state.ui.sidebarCollapsed);
  const { theme: muiTheme } = useMuiTheme();
  const sidebarColor = muiTheme.palette.sidebar.main;

  return (
    <StyledLink to={routePaths.HOME} aria-label={t('navSidebar.ariaGoHome')}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='3.2rem'
        px={!collapsed && '4rem'}
      >
        {collapsed ? (
          themeInternal?.logoWideBrightUrl || themeInternal?.logoWideUrl ? null : (
            <LogoImage
              src={LTIcon}
              alt={t('ariaLogoOf', { name: theme.name })}
              component='img'
              invert={isDarkHex(sidebarColor)}
            />
          )
        ) : themeInternal?.logoWideUrl && themeInternal?.logoWideBrightUrl ? (
          <LogoImage
            src={
              isDarkHex(sidebarColor) ? themeInternal.logoWideBrightUrl : themeInternal.logoWideUrl
            }
            alt='custom-logo'
            component='img'
          />
        ) : !themeInternal?.logoWideBrightUrl && themeInternal?.logoWideUrl ? (
          <LogoImage
            src={themeInternal.logoWideUrl}
            invert={themeInternal.logoWideInvert && isDarkHex(sidebarColor)}
            alt='custom-logo'
            component='img'
          />
        ) : (
          <LogoImage
            src={LTLogo}
            alt='Lemontaps'
            component='img'
            invert={isDarkHex(sidebarColor)}
          />
        )}
      </Box>
    </StyledLink>
  );
};

const StyledLink = styled(Link)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const LogoImage = styled(CardMedia)<{ invert: boolean }>(({ invert }) => ({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  ...(invert && { filter: 'brightness(0) invert(1)' }),
}));
