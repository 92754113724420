import { Box, styled } from '@mui/material';
import EntityBoxRenderer, { EntityBoxRendererGenericProps } from '../EntityBoxRenderer';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Address } from '@/shared/types/api';

type Props = {
  address: Address;
} & EntityBoxRendererGenericProps;

export const AddressBox = ({ address, ...rest }: Props) => {
  const subtitle = [
    address.addressee,
    address.addressLine1,
    address.addressLine2,
    [address.postCode, address.city].filter(Boolean).join(' '),
    address.country,
  ]
    .filter(Boolean)
    .join(', ');
  return (
    <EntityBoxRenderer
      title={address.label}
      subtitle={subtitle}
      icon={
        <IconWrapper>
          <RoomOutlinedIcon />
        </IconWrapper>
      }
      {...rest}
    />
  );
};

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4.4rem',
  width: '4.4rem',
  backgroundColor: theme.palette.action.hover,
  borderRadius: '50%',
  img: {
    height: '2rem',
    display: 'block',
  },
}));
