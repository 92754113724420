import { Theme, styled } from '@mui/material';
import { default as CustomTableCell, CustomTableCellProps } from './CustomTableCell';

const CustomTableCellHeader = ({ children, ...rest }: CustomTableCellProps) => {
  return <StyledCustomTableCell {...rest}>{children}</StyledCustomTableCell>;
};

const StyledCustomTableCell = styled(CustomTableCell)(({ theme }: { theme: Theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:focus-visible': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default CustomTableCellHeader;
