import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { ThemeInternal } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { updateUnitSettings } from '@/application/actions/md/customization';
import { useAuth0 } from '@auth0/auth0-react';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { FEATURE } from '@/shared/constants';
import withNav from '@/infrastructure/hoc/withNav';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '@/components';
import { Box, Switch, Typography } from '@mui/material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const UnitSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureAllowed } = useTierInfo();
  const isUnitsAllowed = isFeatureAllowed(FEATURE.UNITS);
  const themeInternal = useAppSelector<ThemeInternal>(state => state.account.theme.themeInternal);

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();

  const [multiUnitsAllowed, seMultiUnitsAllowed] = useState(themeInternal.multiUnitsAllowed);

  const { notifySuccess, notifyError } = useLtNotifications();

  const [saveLoading, setSaveLoading] = useState(false);
  const handleSave = () => {
    const unitSettings = {
      multiUnitsAllowed,
    };

    setSaveLoading(true);
    dispatch(
      updateUnitSettings(
        unitSettings,
        getAccessTokenSilently,
        t,
        (message?: string) => {
          message ? notifySuccess(t(message)) : notifySuccess(t('changesSaved'));
          setIsUnsaved(false);
          setSaveLoading(false);
        },
        (message?: string) => {
          message ? notifyError(t(message)) : notifyError(t('error.general'));
          setSaveLoading(false);
        },
      ),
    );
  };

  return (
    <PageContainer
      maxWidth='m'
      bottomActionBar={isUnitsAllowed ? { onSave: handleSave, loading: saveLoading } : undefined}
      whiteCardWrapper
    >
      <Box>
        <Typography component='h2' variant='h3' sx={{ marginBottom: 2 }}>
          {t('unitSettings.multipleUnits.title')}
        </Typography>
        <Box display={'flex'}>
          <Typography variant='body2' color={'text.secondary'}>
            {t('unitSettings.multipleUnits.description')}
          </Typography>
          <Switch
            onChange={withUnsavedSetter(() => seMultiUnitsAllowed(!multiUnitsAllowed))}
            checked={multiUnitsAllowed}
            disabled={!isUnitsAllowed}
          />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withNav(
  UnitSettings,
  {
    tTitle: 'unitSettings.title',
  },
  {},
);
