import { Add, EditOutlined } from '@mui/icons-material';
import { Button, IconButton, Paper, ThemeProvider } from '@mui/material';
import SettingsMenu, { MenuItemType } from './SettingsMenu';
import { useState } from 'react';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

type Props = {
  actions: MenuItemType[];
  isButton?: boolean;
  buttonText?: string;
  ariaLabel?: string;
};

export const ImageSettings = ({ actions, buttonText, isButton, ariaLabel }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <ThemeProvider theme={muiTheme}>
      {isButton ? (
        <Button
          startIcon={<Add />}
          variant='outlined'
          color='primary'
          onClick={handleClick}
          aria-label={ariaLabel}
          aria-haspopup='menu'
        >
          {buttonText}
        </Button>
      ) : (
        <Paper sx={{ borderRadius: '50%', width: 'max-content', height: 'max-content' }}>
          <IconButton
            onClick={handleClick}
            sx={{ color: 'primary' }}
            aria-label={ariaLabel}
            aria-haspopup='menu'
          >
            <EditOutlined color='primary' />
          </IconButton>
        </Paper>
      )}
      <SettingsMenu
        renderAnchor={false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        menuItems={actions}
      />
    </ThemeProvider>
  );
};
