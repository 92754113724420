import { FullScreenContainer } from './FullScreenContainer';
import {
  Box,
  Card,
  Checkbox,
  CardHeader,
  Button,
  Collapse,
  IconButton,
  Alert,
} from '@mui/material';
import { BottomActionContainer } from './BottomActionContainer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContactFormTranslationKeyByField } from '@/shared/util';
import WestIcon from '@mui/icons-material/West';
import { LeadDataProps } from '@/shared/types/api';
import { ImagesSlider } from '../../components/ImagesSlider';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import useId from '@mui/material/utils/useId';

type Props = {
  onFinish: () => void;
  onBackClick: () => void;
  onRetakeClick: () => void;
  onToggle: (newValues: Array<keyof LeadDataProps>) => void;
  scanResult: Partial<LeadDataProps>;
  selectedFields: Array<keyof LeadDataProps>;
  businessCardUrls: string[];
  allowedFields: Array<keyof LeadDataProps>;
};

const SingleSelectBox = ({ selected, title, value, onClick }) => {
  const elemId = useId();
  return (
    <Card sx={theme => selected && { borderColor: theme.palette.primary.main }} onClick={onClick}>
      <CardHeader
        title={title}
        subheader={value}
        titleTypographyProps={{ variant: 'h4', mb: 1, id: elemId }}
        action={<Checkbox checked={selected} inputProps={{ 'aria-labelledby': elemId }} />}
      />
    </Card>
  );
};

export const SelectionScreen = ({
  onFinish,
  onBackClick,
  onRetakeClick,
  scanResult,
  selectedFields,
  onToggle,
  businessCardUrls,
  allowedFields,
}: Props) => {
  const [businessCardDataUrlVisible, setBusinessCardDataUrlVisible] = useState(true);
  const { t } = useTranslation();

  const toggle = (key: keyof LeadDataProps) => {
    if (selectedFields.includes(key)) {
      onToggle(selectedFields.filter(k => k !== key));
    } else {
      onToggle([...selectedFields, key]);
    }
  };

  const fields = allowedFields.filter(key => scanResult[key]);

  return (
    <FullScreenContainer>
      <IconButton
        sx={{ p: 1.6, width: 'max-content' }}
        onClick={onBackClick}
        size='large'
        aria-label={t('ariaGoBackToPreviewsScreen')}
      >
        <WestIcon />
      </IconButton>
      <Box py={2.1} px={1.6}>
        <Card>
          <CardHeader
            title={t('businessCardScanner.businessCardTitle')}
            subheader={t('businessCardScanner.businessCardSubtitle')}
            titleTypographyProps={{ variant: 'h4', mb: 1 }}
            subheaderTypographyProps={{ variant: 'h6', color: 'primary' }}
            action={
              <IconButton
                onClick={() => setBusinessCardDataUrlVisible(!businessCardDataUrlVisible)}
                aria-expanded={businessCardDataUrlVisible}
                aria-label={t('ariaBusinessCardImagesToggler')}
              >
                {businessCardDataUrlVisible ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            }
          />

          <Collapse in={businessCardDataUrlVisible}>
            <ImagesSlider images={businessCardUrls} />
          </Collapse>
        </Card>
        {fields.length > 0 ? (
          <Box gap={1.4} display='flex' flexDirection='column' mt={2.5}>
            {fields.map(key => (
              <SingleSelectBox
                key={key}
                title={t(getContactFormTranslationKeyByField(key))}
                value={scanResult[key]}
                selected={selectedFields.includes(key as keyof LeadDataProps)}
                onClick={() => toggle(key as keyof LeadDataProps)}
              />
            ))}
          </Box>
        ) : (
          <Alert sx={{ mt: 2 }} severity='warning' variant='outlined'>
            {t('businessCardScanner.noFieldsAvailable')}
          </Alert>
        )}
      </Box>
      <BottomActionContainer display='flex' gap='1.3rem'>
        <Button fullWidth size='large' color='primary' variant='outlined' onClick={onRetakeClick}>
          {t('businessCardScanner.retake')}
        </Button>
        <Button fullWidth size='large' onClick={onFinish}>
          {t('businessCardScanner.next')}
        </Button>
      </BottomActionContainer>
    </FullScreenContainer>
  );
};
