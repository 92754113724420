import { Account } from '../../../../shared/types/api';
import { Box, FormControl } from '@mui/material';
import { ACCOUNT_BIO_FIELDS_KEYS, AccountBioFields } from '@/shared/constants';
import { isAddressField } from '../utils';
import AddressRenderer from './AddressRenderer';
import BioTextField from './BioTextField';
interface IProps {
  bioForm: Account;
  isFieldBlocked: (field: keyof Account, additionalConditions?: Array<boolean>) => boolean;
}

const EditBioForm: React.FC<IProps> = ({ bioForm, isFieldBlocked }) => {
  const fields = (Object.keys(bioForm) as Array<keyof Account>)
    .filter((key): key is keyof AccountBioFields => ACCOUNT_BIO_FIELDS_KEYS.includes(key))
    .sort((a, b) => ACCOUNT_BIO_FIELDS_KEYS.indexOf(a) - ACCOUNT_BIO_FIELDS_KEYS.indexOf(b));

  const [address1Fields, address2Fields, restFields] = [
    fields.filter(field => isAddressField(field) && field.includes('address1')),
    fields.filter(field => isAddressField(field) && field.includes('address2')),
    fields.filter(field => !isAddressField(field)),
  ];

  return (
    <FormControl sx={{ width: '100%' }}>
      <Box display='flex' flexDirection='column' gap={3}>
        {restFields.map(field => (
          <BioTextField fieldName={field} readonly={isFieldBlocked(field)} />
        ))}
      </Box>
      {address1Fields.length && (
        <AddressRenderer addressFields={address1Fields} isFieldBlocked={isFieldBlocked} />
      )}
      {address2Fields.length && (
        <AddressRenderer addressFields={address2Fields} isFieldBlocked={isFieldBlocked} />
      )}
    </FormControl>
  );
};

export default EditBioForm;
