import { useAppSelector } from '@/application/hooks';
import { routePaths } from '@/infrastructure/constants';
import withNav from '@/infrastructure/hoc/withNav';
import { useState, useMemo, useRef, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LeadForm } from './LeadForm';
import { Account, LeadAPI } from '@/shared/types/api';
import { useHistory, useParams } from 'react-router-dom';
import { PageContainer } from '@/components';
import { IconButton } from '@mui/material';
import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useLeadGen } from './helpers';
import { useTranslation } from 'react-i18next';
import { deleteAccountLead, getSingleLead } from '@/infrastructure/apis/leadGen';
import { useAuth0 } from '@auth0/auth0-react';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const CreateEditLead = () => {
  const { notify: toast } = useLtNotifications();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const account = useAppSelector<Account>(state => state.account);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const { setIsUnsaved } = useUnsavedStatusSetter();
  const [mobileSettingAnchorEl, setMobileSettingAnchorEl] = useState<null | HTMLElement>(null);
  const { createLeadHandler, editLeadHandler } = useLeadGen();
  const [lead, setLead] = useState<LeadAPI | null>(null);
  const [loading, setLoading] = useState(false);

  const { leadId } = useParams<{ leadId: string }>();

  const isEditLead = Boolean(leadId);

  const leadFormRef = useRef(null);

  const headerActions = useMemo(
    () =>
      leadId &&
      createHeaderAction(
        <IconButton onClick={event => setMobileSettingAnchorEl(event?.currentTarget)}>
          <MoreVertIcon />
        </IconButton>,
        { mobileOnly: true },
      ),
    [leadId],
  );
  useHeaderActions(headerActions);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const { leadData, businessCardImages, profileImageFile } = leadFormRef.current.getValues();
      if (isEditLead) {
        await editLeadHandler(leadData, businessCardImages, profileImageFile);
        setIsUnsaved(false);
      } else {
        await createLeadHandler(leadData, businessCardImages, profileImageFile);
        setIsUnsaved(false);
        history.replace(routePaths.CONTACTS.ROOT);
      }
      leadFormRef?.current?.resetValues();
      toast.success(t('leadSaveSuccess'));
    } catch (error) {
      toast.error(t('leadError'));
      console.log(error);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (!leadId || !account?.id) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const { lead } = await getSingleLead(getAccessTokenSilently, account.id, leadId);

        setLead(lead);
      } catch (error) {
        history.goBack();
        toast.error(t('error.general'));
      }
      setLoading(false);
    };
    fetchData();
  }, [leadId, account.id, getAccessTokenSilently, t, history, toast]);

  //Account guard to avoid extra checks inside component
  if (!account) return null;

  const handleDelete = async () => {
    try {
      await deleteAccountLead(getAccessTokenSilently, account.id, leadId);
      history.push(routePaths.CONTACTS.ROOT);
      toast.success(t('deleteLeadSuccess'));
    } catch (error) {
      toast.error(t('deleteLeadError'));
    }
  };

  return (
    <>
      <PageContainer
        maxWidth='s'
        bottomActionBar={{
          saveAction: { onClick: handleSave, disabled: isSaving },
          onCancel: () => history.push(routePaths.CONTACTS.ROOT),
        }}
      >
        <LeadForm
          onDelete={handleDelete}
          ref={leadFormRef}
          mobileSettingAnchorEl={mobileSettingAnchorEl}
          onMobileSettingClose={() => setMobileSettingAnchorEl(null)}
          lead={lead}
          loading={loading}
        />
      </PageContainer>
    </>
  );
};

export default withNav(
  CreateEditLead,
  {
    showBackButton: {
      linkTo: routePaths.CONTACTS.ROOT,
    },
    tTitle: () => (window.location.pathname.endsWith('/edit') ? 'editContact' : 'addContact'),
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
    noBorderRadiusMobile: true,
    noBoxShadowMobile: true,
  },
);
