import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@mui/icons-material';

type Props = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  operators: string[];
  onDelete: () => void;
};

export const FilterItemHeader = ({ value, onChange, operators, label, onDelete }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (op: string) => {
    onChange(op);
    handleClose();
  };

  const { t } = useTranslation();

  const getTranslatedOperator = (op: string) => {
    return t(`filter.operators.${op}`);
  };

  return (
    <Box display='flex' gap={2} alignItems='center' mb={2}>
      <Typography variant='h2'>{label}</Typography>
      <Button
        onClick={handleClick}
        variant='text'
        sx={{ color: 'secondary' }}
        size='small'
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {getTranslatedOperator(value)}
      </Button>
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
        {operators.map(x => (
          <MenuItem key={x} onClick={() => handleSelect(x)}>
            {getTranslatedOperator(x)}
          </MenuItem>
        ))}
      </Menu>
      <IconButton onClick={onDelete} size='small' sx={{ ml: 'auto' }}>
        <DeleteOutlined />
      </IconButton>
    </Box>
  );
};
