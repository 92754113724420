import TooltipInfo from '@/components/TooltipInfo';
import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Switch,
  Typography,
} from '@mui/material';
import useId from '@mui/material/utils/useId';

type Props = {
  title: string;
  checked: boolean;
  onChange: () => void;
  tooltip?: string;
  subMenu?: React.ReactNode;
} & React.ComponentProps<typeof Box>;

const SwitchRow = ({ title, checked, onChange, tooltip, subMenu, ...boxProps }: Props) => {
  const id = useId();
  const titleBox = (
    <Typography id={id} color='text.primary'>
      {title}
    </Typography>
  );

  const switchBox = (
    <Switch
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-labelledby': id, style: { width: '100%', left: 0 } }}
    />
  );
  return (
    <Box {...boxProps} mt={1}>
      {!!subMenu ? (
        <Accordion variant='outlined' sx={{ borderRadius: '0.8rem !important' }}>
          <Box display={'flex'} alignItems={'center'} px={1.6}>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{ width: '100%', p: 0, height: '5rem' }}
            >
              <Box display={'flex'} alignItems={'center'}>
                {titleBox}
                {tooltip && <TooltipInfo text={tooltip} icon={{ color: 'text.primary' }} onHover />}
              </Box>
            </AccordionSummary>
            <Box display={'flex'} justifyContent={'end'}>
              {switchBox}
            </Box>
          </Box>
          <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {subMenu}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '5rem',
            px: 1.6,
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            {titleBox}
            {tooltip && <TooltipInfo text={tooltip} icon={{ color: 'text.primary' }} onHover />}
          </Box>
          <Box>{switchBox}</Box>
        </Card>
      )}
    </Box>
  );
};

export default SwitchRow;
