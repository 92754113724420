import { useCallback, useEffect, useMemo } from 'react';

import { updateAccountDetails } from '../../../application/actions/edit-bio';
import { routePaths } from '../../../infrastructure/constants';
import BioForm from './components/EditBioForm';
import { trackPrivateEvent } from '../../../infrastructure/apis/analytics';

import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import withNav from '../../../infrastructure/hoc/withNav';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../shared/constants/global';
import { Account } from '../../../shared/types/api';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { PageContainer } from '@/components';
import { LtDefaultActionButtonsProps } from '@/components/LtDefaultActionButtons/types';
import { useBlockedFields } from '@/infrastructure/hooks/useBlockedFields';
import { useScimBlockedFields } from '@/infrastructure/hooks/useScimBlockedFields';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import Loader from '@/components/Loader';
import { FormProvider, useForm } from 'react-hook-form';
import { AccountBioFields } from '@/shared/constants';

const EditBio = (): JSX.Element => {
  const { notify: toast } = useLtNotifications();
  const account = useAppSelector<Account>(state => state.account);
  const {
    editRights: { detailsEditable },
  } = useEditRights();

  const isLoading = useAppSelector(state => state.editBio.isLoading);

  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { state: routerState } = useLocation<{ focus?: keyof AccountBioFields }>();

  const { t } = useAppTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const { blockedFields, isFieldBlocked: isFieldBlockedByAdmin } = useBlockedFields();
  const { isFieldBlocked: isFieldBlockedByScim } = useScimBlockedFields();
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const isFieldBlocked = (field: keyof Account) => {
    return isFieldBlockedByAdmin(field) || isFieldBlockedByScim(field);
  };

  const methods = useForm<AccountBioFields>({
    mode: 'onSubmit',
    defaultValues: { ...account },
  });

  const { handleSubmit, setFocus } = methods;

  useEffect(() => {
    if (!isLoading && detailsEditable) {
      if (routerState?.focus) {
        setFocus(routerState.focus);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [detailsEditable, isLoading, routerState?.focus, setFocus]);

  const handleSave = useCallback(
    (values: AccountBioFields) => {
      if (!blockedFields) return;
      const updatedAccount = { ...account, ...values };
      dispatch(
        updateAccountDetails(updatedAccount, getAccessTokenSilently, {
          onSuccess: () => toast.success(t('EditBioSuccess')),
          onError: () => toast.error(t('error.editBio')),
        }),
      );
      trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
        type: PRIVATE_META_TYPES.BIO,
      });
      setIsUnsaved(false);
    },
    [account, blockedFields, dispatch, getAccessTokenSilently, setIsUnsaved, t, toast],
  );

  const actions = useMemo<LtDefaultActionButtonsProps>(
    () => ({
      onSave: handleSubmit(handleSave),
      onCancel: () => push(routePaths.EDITPROFILE),
    }),
    [handleSubmit, handleSave, push],
  );

  if (isLoading) return <Loader />;

  if (!detailsEditable) {
    return <Redirect to={routePaths.EDITPROFILE} />;
  }

  return (
    <PageContainer maxWidth='s' bottomActionBar={actions} whiteCardWrapper>
      <FormProvider<AccountBioFields> {...methods}>
        <BioForm bioForm={account} isFieldBlocked={isFieldBlocked} />
      </FormProvider>
    </PageContainer>
  );
};

export default withNav(
  EditBio,
  {
    showBackButton: {
      linkTo: routePaths.EDITPROFILE,
    },
    tTitle: 'editDetails',
  },
  {
    activeScreen: routePaths.EDITPROFILE,
  },
);
