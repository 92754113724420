// TODO: Consolidate and Integrate with MUI

import { FontType } from '@/shared/types/api';
import { Box, GlobalStyles } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { PropsWithChildren, useEffect } from 'react';

interface Props {
  font?: FontType | null;
}

export default function FontWrapper({ children, font }: PropsWithChildren<Props>) {
  // fetch font source file
  useEffect(() => {
    if (font?.url) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = font.url;
      document.head.appendChild(link);
    }
  }, [font?.url]);

  const id = useId();

  if (!font) return <Box>{children}</Box>;

  return (
    <Box id={id}>
      <GlobalStyles
        styles={{
          body: {
            [`#${id} *`]: {
              fontFamily: font?.name || 'Poppins, sans-serif',
            },
          },
        }}
      />
      {children}
    </Box>
  );
}
