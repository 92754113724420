import { FormControl, InputLabel, Select, MenuItem, FormControlProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISO6391 from 'iso-639-1';
import useId from '@mui/material/utils/useId';

type Props = {
  onLangSelect: (value: string) => void;
  value: string;
  showEmptyOption?: boolean;
  labelOverride?: string;
} & FormControlProps;

const languages = ['en', 'de'];

export const LanguageSelector = ({
  onLangSelect,
  value,
  showEmptyOption = false,
  labelOverride,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const labelProps = showEmptyOption ? { shrink: true } : {};
  const selectProps = showEmptyOption ? { notched: true, displayEmpty: true } : {};

  const labelId = useId();

  return (
    <FormControl fullWidth {...rest}>
      <InputLabel {...labelProps} id={labelId}>
        {labelOverride || t('language')}
      </InputLabel>
      <Select
        {...selectProps}
        labelId={labelId}
        value={value}
        label={labelOverride || t('language')}
        onChange={({ target: { value } }) => onLangSelect(value)}
      >
        {showEmptyOption && <MenuItem value={''}>{'standard'}</MenuItem>}
        {ISO6391.getLanguages(languages).map(({ code, nativeName }) => (
          <MenuItem key={code} value={code}>
            {nativeName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
