import { useTranslation } from 'react-i18next';
import { ColorPicker } from '@/components';
import { Box } from '@mui/material';
import { TooltipText } from '../TooltipText';

type RowProps = {
  tooltipText: string;
  value: string;
  onChange: (value: string) => void;
  label: string;
  ariaLabel?: string;
};

const SingleRow = ({ tooltipText, label, onChange, value, ariaLabel }: RowProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TooltipText tooltipText={tooltipText} label={label} />
      <ColorPicker value={value} onChange={onChange} ariaLabel={ariaLabel} />
    </Box>
  );
};

interface Props {
  saveColor: string;
  setSaveColor: (value: string) => void;
  filesColor: string;
  setFilesColor: (value: string) => void;
  genericWebsiteColor: string;
  setGenericWebsiteColor: (value: string) => void;
}

const ButtonColors = (props: Props) => {
  const { t } = useTranslation();

  const {
    saveColor,
    setSaveColor,
    filesColor,
    setFilesColor,
    genericWebsiteColor,
    setGenericWebsiteColor,
  } = props;

  return (
    <Box display='flex' flexDirection='column' gap='1.6rem'>
      <SingleRow
        tooltipText={t('md.profileDesign.buttonColorSaveContactTooltip')}
        label={t('strSaveContact')}
        value={saveColor}
        onChange={setSaveColor}
        ariaLabel={t('ariaSaveContactColor', { color: saveColor })}
      />

      <SingleRow
        tooltipText={t('md.profileDesign.buttonColorFilesTooltip')}
        label={t('files')}
        value={filesColor}
        onChange={setFilesColor}
        ariaLabel={t('ariaFilesColor', { color: filesColor })}
      />

      <SingleRow
        tooltipText={t('md.profileDesign.buttonColorGenericWebsitePicker')}
        label={t('genericWebsite')}
        value={genericWebsiteColor}
        onChange={setGenericWebsiteColor}
        ariaLabel={t('ariaGenericWebsiteLinkColor', { color: genericWebsiteColor })}
      />
    </Box>
  );
};

export default ButtonColors;
