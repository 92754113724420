import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { Box, Card, BoxProps, SxProps } from '@mui/material';
import { CSS_VARS } from '@/infrastructure/constants';
import { useAppSelector } from '@/application/hooks';
import { LtDefaultActionButtonsProps } from './LtDefaultActionButtons/types';
import LtDefaultActionButtons, { hasAnyAction } from './LtDefaultActionButtons';
import React, { useMemo, useState } from 'react';

export type LtActionButtonBarProps = BoxProps &
  LtDefaultActionButtonsProps & {
    noCancelOnDesktop?: boolean;
    position?: 'relative' | 'sticky' | 'fixed';
    contentLocation?: 'left' | 'center' | 'right';
    renderRelativeBox?: boolean; // render a box that takes up the space in relative positioning
    location?: 'top' | 'bottom'; // relevant only, if position != 'relative'
    renderCard?: boolean; // whether to render a card UI or just a Box (no background or outline), defaults to true
    explicitOffsets?: {
      // override the offsets (for sticky and fixed positioning) and margins (for relative positioning)
      top?: number | string;
      left?: number | string;
      right?: number | string;
      bottom?: number | string;
    };
  };

const LtActionButtonBar = ({
  sx,
  position = 'fixed',
  renderRelativeBox = false,
  location = 'bottom',
  contentLocation = 'right',
  renderCard = true,
  explicitOffsets = {},
  ...rest
}: LtActionButtonBarProps) => {
  const { desktopView } = useDesktopView();
  const sidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  // as state to force a re-render after mount
  const [domElement, setDomElement] = useState<HTMLDivElement>();

  const sxPosition: SxProps = useMemo(
    () =>
      position === 'relative'
        ? {
            position: 'relative',
            marginLeft: explicitOffsets.left,
            marginRight: explicitOffsets.right,
            marginTop: explicitOffsets.top,
            marginBottom: explicitOffsets.bottom,
          }
        : position === 'sticky'
        ? {
            position: 'sticky',
            bottom: explicitOffsets.bottom ?? (location === 'bottom' ? 0 : undefined),
            top: explicitOffsets.top ?? (location === 'top' ? 0 : undefined),
            left: explicitOffsets.left ?? 0,
            right: explicitOffsets.right ?? 0,
            overflow: 'unset !important',
          }
        : {
            position: 'fixed',
            bottom:
              explicitOffsets.bottom ??
              (location === 'bottom'
                ? desktopView
                  ? 0
                  : CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR
                : undefined),
            top:
              explicitOffsets.top ??
              (location === 'top'
                ? desktopView
                  ? 0
                  : CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR
                : undefined),
            left:
              explicitOffsets.left ??
              (desktopView
                ? sidebarCollapsed
                  ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
                  : CSS_VARS.LT_SIDEBAR_WIDTH_VAR
                : 0),
            right: explicitOffsets.right ?? 0,
          },
    [
      desktopView,
      explicitOffsets.bottom,
      explicitOffsets.left,
      explicitOffsets.right,
      explicitOffsets.top,
      location,
      position,
      sidebarCollapsed,
    ],
  );

  const sxContentLocation: SxProps = useMemo(
    () => ({
      justifyContent: desktopView
        ? contentLocation === 'right'
          ? 'flex-end'
          : contentLocation === 'left'
          ? 'flex-start'
          : 'center'
        : 'space-between',
    }),
    [contentLocation, desktopView],
  );

  if (!hasAnyAction(rest) && !rest.customElemsPre?.length && !rest.customElemsPost?.length)
    return null;

  return (
    <>
      <Box
        component={renderCard ? Card : undefined}
        ref={setDomElement}
        sx={{
          p: 2,
          pb: !desktopView && location === 'bottom' ? 4 : undefined,
          ...sxPosition,
          zIndex: 10,
          ...sx,
        }}
        {...rest}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            ...sxContentLocation,
            alignItems: 'strech',
            gap: 2,
            maxWidth: theme.spacing(240),
            margin: 'auto',
          })}
        >
          <LtDefaultActionButtons {...rest} />
        </Box>
      </Box>

      {position === 'fixed' && domElement && renderRelativeBox && (
        <Box height={domElement.getBoundingClientRect().height} />
      )}
    </>
  );
};

export default LtActionButtonBar;
