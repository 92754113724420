import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFileValid, isNotEmptyArray } from '@/infrastructure/helper';
import { CSS_VARS } from '@/infrastructure/constants';
import { Box, styled } from '@mui/material';
import { DeleteOutline, ImageOutlined, Upload } from '@mui/icons-material';
import { ImageSettings } from '@/components/ImageSettings';
import { AccountImageState } from '@/shared/types/api';
import { TooltipText } from '../TooltipText';
import ImageCropper, { CropperProps } from '@/components/ImageCropper';

interface Props {
  value: string | File;
  defaultValue?: string;
  onChange: (value: { val: string | File; state: AccountImageState }) => void;
  cropperProps?: Partial<CropperProps>;
  label?: string;
  tooltipText?: string;
  btnText?: string;
  stateValue?: AccountImageState;
  settingsBtnAriaLabel?: string;
}

const ImageSelector = ({
  onChange,
  defaultValue,
  label,
  tooltipText,
  value,
  cropperProps,
  btnText,
  stateValue,
  settingsBtnAriaLabel,
}: Props) => {
  let fileInput = useRef(null);
  const { t } = useTranslation();

  const [cropperDataUrl, setCropperDataUrl] = useState('');

  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const imageUrl = croppedImageUrl || value;

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);

        reader.onload = () => {
          setCropperDataUrl(reader.result as string);
        };
      }
    }
  };

  const onCropClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = fileInput.current.files[0].name.split('.').pop();
    setCropperDataUrl('');

    const file = new File([blob], `banner.${ext}`);
    onChange({ val: file, state: AccountImageState.DEFINED });
    setCroppedImageUrl(croppedImageUrl);
  };

  const handleDelete = () => {
    setCroppedImageUrl(null);
    onChange({ val: null, state: AccountImageState.DELETED });
  };

  const items = [
    {
      icon: <Upload />,
      label: t('upload'),
      onClick: () => fileInput.current.click(),
    },
    value && {
      icon: <DeleteOutline />,
      label: t('delete'),
      onClick: handleDelete,
    },
    defaultValue &&
      stateValue !== AccountImageState.DEFAULT && {
        icon: <ImageOutlined />,
        label: t('resetToDefault'),
        onClick: () => {
          onChange({ val: null, state: AccountImageState.DEFAULT });
          setCroppedImageUrl(null);
        },
      },
  ].filter(Boolean);

  return (
    <Box position='relative' mt='1rem'>
      {cropperDataUrl && (
        <ImageCropper
          image={cropperDataUrl}
          onCropClick={url => onCropClick(url)}
          onClose={() => setCropperDataUrl('')}
          {...cropperProps}
        />
      )}

      <TooltipText tooltipText={tooltipText} label={label} />

      {imageUrl && (
        <BoxWrapper>
          <PreviewContainer>
            <img key={imageUrl} src={imageUrl} alt='' />
          </PreviewContainer>
          <Box sx={{ position: 'absolute', top: '0.3rem', right: '0.3rem' }}>
            <ImageSettings actions={items} ariaLabel={settingsBtnAriaLabel} />
          </Box>
        </BoxWrapper>
      )}

      {!imageUrl && (
        <Box sx={{ position: 'absolute', top: '-1rem', right: 0 }}>
          <ImageSettings
            actions={items}
            isButton
            buttonText={btnText}
            ariaLabel={settingsBtnAriaLabel}
          />
        </Box>
      )}

      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </Box>
  );
};

export default ImageSelector;

export const BoxWrapper = styled(Box)({
  border: `2px dashed ${CSS_VARS.LT_DEFAULT_COLOR_VAR}`,
  boxSizing: 'border-box',
  borderRadius: '1rem',
  padding: '3px 5px',
  height: '10rem',
  width: '30rem',
  cursor: 'pointer',
  position: 'relative',
  marginTop: '0.5rem',
});

export const PreviewContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',

  img: {
    objectFit: 'contain',
    width: '100%',
  },
});
