export const ANALYTICS_EVENTS = {
  PUBLIC: {
    PROFILE_VIEW: 'profile-view',
    LINK_CLICK: 'link-click',
    FILE_CLICK: 'file-click',
    PROFILE_PIC_CLICK: 'profile-pic-click',
    CONTACT_SAVE_CLICK: 'contact-save-click',
    CONTACT_SUBMIT_CLICK: 'contact-submit-click',
    CONTACT_LT2LT_SAVED: 'contact-lt2lt-saved',
  },
  PRIVATE: {
    PROFILE_EDIT: 'profile-edit',
    PROFILE_EDIT_MD_SINGLE: 'profile-edit-md-single',
    PROFILE_EDIT_MD_BULK: 'profile-edit-md-bulk',
  },
} as const;

export const META_DATA_TYPES = {
  PRIVATE: {
    BIO: 'bio',
    LINK_ADD: 'link-add',
    FILE_ADD: 'file-add',
    LINK_DELETE: 'link-delete',
    FILE_DELETE: 'file-delete',
    BANNER: 'banner',
    PROFILE_PICTURE: 'profile-picture',
    LOGO: 'logo',
  },
} as const;

export const DEFAULT_START_DATE = new Date(new Date('2020-01-01').setHours(0, 0, 0, 0));

export const DATE_RANGE_OPTIONS = [
  { tTitle: 'today', type: 'day', value: 0 },
  { tTitle: 'pastWeek', type: 'day', value: 7 },
  { tTitle: 'pastMonth', type: 'month', value: 1 },
  { tTitle: 'past90Days', type: 'day', value: 90 },
  { tTitle: 'pastYear', type: 'year', value: 1 },
  {
    tTitle: 'allTime',
    type: 'fixed',
    value: DEFAULT_START_DATE.getTime(),
  },
] as const;

export const getDefaultStartDate = () => {
  return DEFAULT_START_DATE;
};

export const getDefaultEndDate = () => {
  const today = new Date();
  return new Date(today.setHours(23, 59, 59, 999));
};

export const PUBLIC_EVENTS = ANALYTICS_EVENTS.PUBLIC;
export const PRIVATE_EVENTS = ANALYTICS_EVENTS.PRIVATE;
export const PRIVATE_META_TYPES = META_DATA_TYPES.PRIVATE;
