import { Button, Card, Divider, Switch, ThemeProvider, Typography } from '@mui/material';
import { useState } from 'react';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';
import { RestartAlt, TuneOutlined } from '@mui/icons-material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import LtSorter from './LtSorter';
import LtDialog from './LtDialog';
import { Box, Stack } from '@mui/system';

interface Props {
  columnDefs: ColumnDefinition[];
  setColumnDefs: (newDef: ColumnDefinition[]) => any;
  reset: Function;
}
const ColSortMenu = (props: Props) => {
  const { t } = useAppTranslation();

  const [open, setOpen] = useState(false);

  const handleToggleItem = (idx: number) => {
    props.setColumnDefs(
      props.columnDefs.map((col, i) => (i === idx ? { ...col, hidden: !col.hidden } : col)),
    );
  };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <ThemeProvider theme={muiTheme}>
      <Button
        startIcon={<TuneOutlined />}
        variant='text'
        onClick={() => setOpen(true)}
        sx={{ width: 'max-content' }}
        aria-haspopup='true'
      >
        {t('customizeTable')}
      </Button>
      <LtDialog
        title={t('colSortMenu.dialogTitle')}
        open={open}
        onClose={() => setOpen(false)}
        onDone={() => setOpen(false)}
        PaperProps={{
          sx: { height: 'min(80rem, 80vh)' },
        }}
      >
        <Stack direction='column' spacing={2} height='100%'>
          <Box display='flex' alignItems='center' gap={2}>
            <Typography variant='body2' flex={1}>
              {t('colSortMenu.description')}
            </Typography>
            <Button
              variant='text'
              onClick={() => {
                props.reset();
              }}
              startIcon={<RestartAlt />}
              sx={{ ml: 'auto' }}
            >
              {t('resetToDefault')}
            </Button>
          </Box>
          <Card sx={{ px: 4, overflowY: 'auto' }}>
            <Box
              display='flex'
              justifyContent='flex-end'
              py={1}
              position='sticky'
              top={0}
              sx={{ background: muiTheme.palette.background.paper }}
              zIndex={2} // Switches have zindex of 1
            >
              <Typography variant='body2'>{t('colSortMenu.showColumnHeader')}</Typography>
            </Box>
            <Box>
              <LtSorter
                items={props.columnDefs}
                onEnd={props.setColumnDefs}
                dense
                getItemAriaLabel={item => t(item.tkey)}
                getItemKey={item => item.key}
                renderer={(col, idx) => (
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body1'>{t(col.tkey)}</Typography>
                    <Switch
                      sx={{ ml: 'auto' }}
                      onChange={() => handleToggleItem(idx)}
                      checked={!col.hidden}
                      inputProps={{ 'aria-label': t('ariaShowColumn', { name: t(col.tkey) }) }}
                    />
                  </Box>
                )}
                divider={<Divider />}
              />
            </Box>
          </Card>
        </Stack>
      </LtDialog>
    </ThemeProvider>
  );
};

export default ColSortMenu;
