import { Box, Button, Drawer, Typography } from '@mui/material';
import { LeadForm } from '../../CreateEditLead/LeadForm';
import { LeadAPI } from '@/shared/types/api';
import { CloseOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useLeadGen } from '../../CreateEditLead/helpers';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { useRef, useState } from 'react';
import { BusinessCardImageType } from '../../CreateEditLead/components/BusinessCardImage';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

type Props = {
  onClose: () => void;
  onSave: () => void;
  onDelete: (leadId: number) => Promise<void>;
  opened: boolean;
  focusNotes?: boolean;
  lead?: LeadAPI;
};

export const LeadFormDrawer = ({ opened, onClose, onSave, onDelete, focusNotes, lead }: Props) => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();

  const [isSaving, setIsSaving] = useState(false);
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const handleClose = () => openDialogIfUnsaved(() => onClose());
  const { createLeadHandler, editLeadHandler } = useLeadGen();
  const isEditLead = Boolean(lead);

  const leadFormRef = useRef<{
    resetValues: () => void;
    getValues: () => {
      leadData: Partial<LeadAPI>;
      businessCardImages: BusinessCardImageType[];
      profileImageFile: File;
    };
  }>(null);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const { leadData, businessCardImages, profileImageFile } = leadFormRef.current?.getValues();
      if (isEditLead) {
        await editLeadHandler(leadData, businessCardImages, profileImageFile);
        setIsUnsaved(false);
      } else {
        await createLeadHandler(leadData, businessCardImages, profileImageFile);
        setIsUnsaved(false);
        onClose();
      }
      onSave?.();
      leadFormRef?.current?.resetValues();
      toast.success(t('leadSaveSuccess'));
    } catch (error) {
      toast.error(t('leadError'));
      console.log(error);
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      anchor='right'
      open={opened}
      onClose={handleClose}
      PaperProps={{ sx: { width: '54rem' } }}
    >
      <Box p={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h3'>
          {isEditLead ? t('mdLeads.editFormTitle') : t('addContact')}
        </Typography>
        <Button variant='text' startIcon={<CloseOutlined />} onClick={handleClose}>
          {t('close')}
        </Button>
      </Box>
      <LeadForm ref={leadFormRef} onDelete={onDelete} focusNotes={focusNotes} lead={lead} />
      <Box flex={1} />
      <LtActionButtonBar
        saveAction={{ onClick: handleSave, loading: isSaving, 'aria-label': t('saveContact') }}
        onCancel={handleClose}
        position='sticky'
      />
    </Drawer>
  );
};
