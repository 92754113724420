import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../../application/hooks';
import { Employee } from '../../../../shared/types/api/employee.type';
import { useTranslation } from 'react-i18next';
import { Account, APIError, AuthRole, Theme } from '../../../../shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../config/config';
import { AVAILABLE_PERMISSION_LEVELS, PERMISSIONS } from '../../../../infrastructure/constants';
import { useDispatch } from 'react-redux';
import { getEmployees } from '../../../../application/actions/md/profiles';
import { assignUnits, getUnits } from '../../../../infrastructure/apis/md/units';
import { Unit } from '../../../../shared/types/api/unit.type';
import useDeferredLoader from '../../../../infrastructure/hooks/useDeferredLoader';
import { ERROR_KEYS, FEATURE } from '../../../../shared/constants';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { getMainUnitSync, getOtherUnitsSync, lexicalSortObject } from '@/shared/util';
import {
  getFullName,
  getProfileImageUrlWithGlobalDefault,
} from '@/shared/business-logic/features/profile/getter';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { fetchUserData } from '@/application/actions/account';
import { EditOutlined } from '@mui/icons-material';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { SectionRenderer } from './SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

interface Props {
  employee: Employee;
  onBackClick: () => void;
  onSaveSuccess?: Function;
  setShowUnit?: Dispatch<SetStateAction<boolean>>;
}

const getInitialRoles = (employee: Account): AuthRole[] =>
  employee.authRoles && employee.authRoles.length > 0
    ? employee.authRoles
    : [{ id: 'plain', name: 'plain', niceName: 'Employee' }];

const EmployeeTypeAssignment = (props: Props) => {
  const { notify: toast } = useLtNotifications();
  const { isFeatureAllowed, isThemeAdmin, isUnitAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user: userSelf, logout } = useAuth0();
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const multiUnitsAllowed = !!theme?.themeInternal?.multiUnitsAllowed;
  const [roles, setRoles] = useState(getInitialRoles(props.employee));
  const [initialAuthEmail, setInitialAuthEmail] = useState<string>();
  const [updatedAuthEmail, setUpdatedAuthEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (error) toast.error(t('error.general'));
  }, [error, t, toast]);
  const { getAccessTokenSilently } = useAuth0();
  const [units, setUnits] = useState<Unit[]>([]);
  const originalMainUnit = useMemo(() => getMainUnitSync(props.employee), [props.employee]);
  const [mainUnit, setMainUnit] = useState<Unit | null | undefined>(originalMainUnit);
  const [otherUnits, setOtherUnits] = useState<Unit[]>(getOtherUnitsSync(props.employee));
  const [errorNoUnitSelected, setErrorNoUnitSelected] = useState(false);
  const [errorNoSelectedRole, setErrorNoSelectedRole] = useState(false);
  const account = useAppSelector(state => state.account);
  const { profileDesign } = useProfileDesignForUnits(mainUnit?.id);

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const [loadingUnits, setLoadingUnits] = useState(false);
  useDeferredLoader(loadingUnits, 'loadingUnits');
  useEffect(() => {
    if (isFeatureAllowed(FEATURE.UNITS)) {
      if (!isThemeAdmin) {
        setUnits(account.units);
        return;
      }
      setLoadingUnits(true);
      getUnits(getAccessTokenSilently)
        .then(res => {
          setUnits(res.data.data.units);
        })
        .catch(err => {
          toast.error(t('mdLeads.errorReadUnits'), { id: 'loadingUnits' });
          setUnits([]);
        })
        .finally(() => setLoadingUnits(false));
    }
  }, [account.units, getAccessTokenSilently, t, isThemeAdmin, isFeatureAllowed, toast]);

  useEffect(() => {}, [props.employee]);
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'business/employees/' + props.employee.id + '/authData', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES_AUTH_DATA],
    )
      .then(res => {
        setInitialAuthEmail(res.data.data.email);
        setUpdatedAuthEmail(res.data.data.email);
      })
      .catch(() => {
        setError(true);
        toast.error(t('mdLeads.loadingUnitsError'));
      });
  }, [getAccessTokenSilently, props.employee.id, t, toast]);
  const [disabled, setDisabled] = useState(true);
  const [sendInvite, setSendInvite] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>();

  const saveDisabled = useMemo(() => {
    if (
      mainUnit?.id !== originalMainUnit?.id ||
      otherUnits
        .map(unit => unit?.id)
        .sort((a, b) => a - b)
        .join(',') !==
        props.employee.units
          ?.map(unit => unit?.id)
          ?.filter(uid => uid !== mainUnit?.id)
          .sort((a, b) => a - b)
          .join(',')
    )
      return false;
    return (
      error ||
      !initialAuthEmail ||
      (getInitialRoles(props.employee).every(ar => roles.some(r => r.name === ar.name)) &&
        initialAuthEmail === updatedAuthEmail)
    );
  }, [
    mainUnit?.id,
    originalMainUnit?.id,
    otherUnits,
    props.employee,
    error,
    initialAuthEmail,
    updatedAuthEmail,
    roles,
  ]);

  const onSaveClick = async () => {
    setErrorNoUnitSelected(false);
    setErrorNoSelectedRole(false);
    if (roles.filter(r => r.name === 'unit_admin').length > 0) {
      if (!mainUnit) {
        setErrorNoUnitSelected(true);
        return;
      }
    }
    if (roles.length === 0) {
      setErrorNoSelectedRole(true);
    }

    if (saveDisabled) return;

    const updatedRoles = roles.filter(r => r.name !== 'plain');

    const requestObj = {
      roles: updatedRoles,
      authEmail: {
        email: updatedAuthEmail,
        sendInvite: sendInvite,
      },
    };

    const toastId = 'emplTypeAss-toast';
    setIsLoading(true);

    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios
          .patch<{ isSuccess: boolean }>(
            config.API_BASE_URL + 'business/employees/' + props.employee.id + '/authData',
            requestObj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(async res => {
            if (res.data.isSuccess) {
              if (isFeatureAllowed(FEATURE.UNITS)) {
                assignUnits(
                  getAccessTokenSilently,
                  [props.employee.id],
                  mainUnit?.id,
                  multiUnitsAllowed ? otherUnits.map(unit => unit?.id) : [],
                )
                  .then(res => {
                    if (res.data.isSuccess) {
                      props?.onSaveSuccess();
                      if (props.employee.id === account.id)
                        dispatch(fetchUserData(getAccessTokenSilently, logout));
                      dispatch(
                        getEmployees(getAccessTokenSilently, '', '', t, {
                          withToast: true,
                          onSuccess: () => toast.success(t('changesSaved'), { id: toastId }),
                          onFail: () => toast.success(t('changesSaved'), { id: toastId }),
                        }),
                      );
                      setIsUnsaved(false);
                    }
                  })
                  .catch(err => toast.error(t('mdLeads.errorReadUnits', { id: toastId })))
                  .finally(() => setIsLoading(false));
              } else {
                props?.onSaveSuccess();
                dispatch(
                  getEmployees(getAccessTokenSilently, '', '', t, {
                    withToast: true,
                    onSuccess: () => toast.success(t('changesSaved'), { id: toastId }),
                    onFail: () => toast.success(t('changesSaved'), { id: toastId }),
                  }),
                );
                setIsLoading(false);
                setIsUnsaved(false);
              }
            }
          })
          .catch((err: APIError) => {
            setIsLoading(false);
            if (err?.response?.data?.error?.code === ERROR_KEYS.DISPOSABLE_EMAIL) {
              // disposable email
              toast.error(t('error.disposableEmail'));
            } else if (err?.response?.data?.error?.code === ERROR_KEYS.EXISTED_EMAILS) {
              // email already existed for another user
              toast.error(t('error.existedEmail'));
            } else if (err?.response?.data?.error?.code === ERROR_KEYS.INVALID_EMAIL) {
              // invalid email format
              toast.error(t('error.invalidEmail'));
            } else {
              return toast.error(t('error.general'));
            }
          }),
      [PERMISSIONS.WRITE.THEME_PROFILES_AUTH_DATA],
    );
  };

  useEffect(() => {
    if (!disabled) emailInputRef.current.focus();
  }, [disabled]);

  const emailHasChanged = useMemo(
    () => initialAuthEmail !== updatedAuthEmail,
    [initialAuthEmail, updatedAuthEmail],
  );
  const disableAdminForNoneThemeAdmin = (role: string) => role === 'theme_admin' && !isThemeAdmin; ///ss
  const disableIfNotThemeAdminAndUserAdmin = !isThemeAdmin && props.employee.isThemeAdmin;
  const onRoleChange = useCallback(
    (name: string) => {
      if (name === 'unit_admin') {
        setRoles(roles.filter(r => r.name !== 'theme_admin'));
      }
      const roleDef = AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).find(
        ar => ar.name === name,
      );
      const exclusiveRoles = AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).filter(
        ar => ar.exclusiveGroups.some(eg => roleDef.exclusiveGroups.some(rdeg => eg === rdeg)),
      );
      if (!roleDef) return;
      if (roles.find(r => r.name === name)) {
        let requiredToAdd: AuthRole[] = [];
        for (const reqGroup of roleDef.requiredGroups) {
          const toadd = AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS))
            .filter(ar => ar.name !== name)
            .find(ar => ar.requiredGroups.some(arreq => arreq === reqGroup));
          if (toadd) requiredToAdd.push(toadd);
        }
      } else {
        setRoles([...roles.filter(r => exclusiveRoles.every(er => er.name !== r.name)), roleDef]);
      }
      setIsUnsaved(true);
    },
    [isFeatureAllowed, roles, setIsUnsaved],
  );

  const canEditMainUnits =
    isThemeAdmin ||
    (isUnitAdmin && units.some(u => u && originalMainUnit && u.id === originalMainUnit.id));

  const sortedUnits = units?.sort(lexicalSortObject('niceName')) || [];

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box px={4}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          m='auto'
          width='max-content'
          my={5}
        >
          <Box overflow='hidden' width='10rem' height='10rem' borderRadius='50%' mb='1.6rem'>
            <img
              width='100%'
              src={getProfileImageUrlWithGlobalDefault(props.employee, profileDesign)}
              alt='Profile Pic'
            />
          </Box>
          <Typography variant='h2' textAlign='center'>
            {getFullName(props.employee)}
            {props.employee.id === userSelf.sub ? ' (' + t('you') + ')' : ''}
          </Typography>
        </Box>
        <SectionRenderer label={t('authEmail')}>
          <TextField
            value={updatedAuthEmail}
            onChange={e => {
              // validate input
              for (let i = 0; i < e.target.value.length; i++) {
                if (e.target.value.charCodeAt(i) > 127) {
                  toast.error(t('invalidCharEntered'));
                  return;
                }
              }
              setUpdatedAuthEmail(e.target.value);
              setIsUnsaved(true);
            }}
            fullWidth
            disabled={disabled}
            inputRef={emailInputRef}
            onBlur={() => !emailHasChanged && setDisabled(true)}
            InputProps={{
              endAdornment: disabled && !!updatedAuthEmail && isThemeAdmin && (
                <IconButton onClick={() => setDisabled(false)}>
                  <EditOutlined />
                </IconButton>
              ),
            }}
          />
          {emailHasChanged && (
            <Box mt={2.4}>
              <FormControlLabel
                labelPlacement='start'
                sx={{ ml: 'auto' }}
                control={
                  <Switch checked={sendInvite} onChange={() => setSendInvite(!sendInvite)} />
                }
                label={t('employeeType.sendInviteText')}
              />
            </Box>
          )}
        </SectionRenderer>
        <SectionRenderer label={t('role')}>
          <RadioGroup aria-labelledby='roles-group' name='roles-group' row>
            {AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).map(role => (
              <FormControlLabel
                color={muiTheme.palette.primary.main}
                value={role.name}
                control={
                  <Radio
                    inputProps={{ 'aria-label': role.niceName }}
                    color='primary'
                    checked={!!roles.find(r => role.name === r.name)}
                  />
                }
                key={role.name}
                label={t(role.translateKey)}
                disabled={
                  (props.employee.isThemeAdmin || role.name === 'theme_admin') && !isThemeAdmin
                }
                name={role.name}
                title={t(role.translateKey)}
                onChange={() => {
                  if (disableIfNotThemeAdminAndUserAdmin) return;
                  if (disableAdminForNoneThemeAdmin(role.name)) return;
                  onRoleChange(role.name);
                }}
              />
            ))}
          </RadioGroup>

          {errorNoSelectedRole && (
            <Typography variant='body2' color='error' sx={{ mt: 1 }}>
              {t('mdLeads.noRoleSelected')}
            </Typography>
          )}
        </SectionRenderer>

        {units && units.length > 0 && (
          <SectionRenderer
            label={multiUnitsAllowed ? t('mainUnit') : t('mdLeads.unit')}
            tooltipText={multiUnitsAllowed && t('mainUnitTooltip')}
          >
            <Autocomplete
              disabled={!canEditMainUnits || (isUnitAdmin && props.employee.isThemeAdmin === true)}
              multiple={false}
              options={sortedUnits.filter(({ id }) => id !== mainUnit?.id) || []}
              onChange={(_event, newValue: Unit) => {
                if (!canEditMainUnits || (isUnitAdmin && props.employee.isThemeAdmin)) {
                  return;
                }
                if (multiUnitsAllowed) {
                  if (newValue) {
                    setOtherUnits(prev =>
                      [...prev.filter(u => u.id !== newValue.id), mainUnit].filter(Boolean),
                    );
                  } else {
                    setOtherUnits([]);
                  }
                }
                setMainUnit(newValue);
                setIsUnsaved(true);
              }}
              value={mainUnit}
              renderInput={params => <TextField {...params} label={t('select')} />}
              getOptionLabel={(option: Unit) => option.niceName}
            />
          </SectionRenderer>
        )}

        {multiUnitsAllowed && units && units.length > 0 && (
          <SectionRenderer label={t('otherUnits')} tooltipText={t('otherUnitsTooltip')}>
            <Autocomplete
              disabled={!mainUnit || (props.employee.isThemeAdmin && !isThemeAdmin)}
              multiple
              options={sortedUnits.filter(({ id }) => id !== mainUnit?.id) || []}
              disableClearable
              onChange={(_event, newValue: []) => {
                setOtherUnits(newValue);
              }}
              value={otherUnits}
              renderInput={params => <TextField {...params} label={t('select')} />}
              getOptionLabel={(option: Unit) => option.niceName}
            />
          </SectionRenderer>
        )}

        {errorNoUnitSelected && (
          <Typography variant='body2' color='error' sx={{ mt: 1 }}>
            {units.length === 0 ? t('mdLeads.firstCreateUnit') : t('mdLeads.noUnitSelected')}
          </Typography>
        )}
      </Box>
      <Box flex='1' />
      <LtActionButtonBar
        saveAction={{
          loading: isLoading,
          onClick: onSaveClick,
          disabled: saveDisabled,
        }}
        onCancel={() => openDialogIfUnsaved(props.onBackClick)}
        position='sticky'
      />
    </Box>
  );
};

export default EmployeeTypeAssignment;
