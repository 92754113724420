import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../application/hooks';
import axios from 'axios';
import config from '../../../../config/config';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import { Account, AccountMeta } from '../../../../shared/types/api';
import { COMPANY_SIZES, POTENTIAL_USER_SIZES, USAGE_GOALS } from '../../../../shared/constants';
import { useDispatch } from 'react-redux';
import { dispatchTypes } from '../../../../application/actions';
import { getRedirectedRoute } from '../screens/constants';
import { Box, BoxProps, Divider, TextField, Typography } from '@mui/material';
import { ContinueButton, CustomChip, OnboardingScreenWrapper } from '../components';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const buttonsCategory = [
  'sales-manager',
  'field-representative',
  'hr',
  'procurement',
  'consultant',
  'c-level',
  'it',
  'freelancer',
  'marketing',
];
const buttonsReferral = [
  'google',
  'linkedin',
  'instagram',
  'referral',
  'event',
  'email',
  'friends',
];

type SectionProps = {
  title: string;
  children: React.ReactNode;
} & BoxProps;
const Section = ({ title, children, ...rest }: SectionProps) => {
  return (
    <>
      <Box {...rest}>
        <Typography sx={{ mb: 1.6 }} variant='h4'>
          {title}
        </Typography>
        <Box display='flex' flexWrap='wrap' gap={1}>
          {children}
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />
    </>
  );
};

export const Screen3 = () => {
  const { isLoading } = useAppSelector(state => state.onboarding);
  const account = useAppSelector<Account>(state => state.account);
  const dispatch = useDispatch();
  const { notify: toast } = useLtNotifications();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [isActiveCategory, setIsActiveCategory] = useState<string[]>([]);
  const [isActiveSocial, setIsActiveSocial] = useState<string[]>([]);
  const [isActiveUsageGoal, setIsActiveUsageGoal] = useState<string[]>([]);
  const { t, i18n } = useTranslation();
  const [categoryValue, setCategoryValue] = useState<string>(null);
  const [referralValue, setReferralValue] = useState<string>(null);
  const [companySize, setCompanySize] = useState<string>('');
  const [potentialUsers, setPotentialUsers] = useState<string>('');
  const [usageGoalValue, setUsageGoalValue] = useState<string>(null);

  const scrollRef = React.useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const redirectHandler = () => {
    const redirectedRoute = getRedirectedRoute(account?.origin);
    history.push(redirectedRoute);
  };

  const onboardingCompletedHandler = async () => {
    setLoading(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios
        .post(
          config.API_BASE_URL + `onboarding/meta`,
          {
            onboardingCategory: [
              ...isActiveCategory.map(x => ({ key: x })),
              ...(categoryValue !== null
                ? [
                    {
                      key: 'other',
                      lang: i18n.language.substring(0, 2),
                      value: categoryValue.trim(),
                    },
                  ]
                : []),
            ],
            onboardingReferrer: [
              ...isActiveSocial.map(x => ({ key: x })),
              ...(referralValue !== null
                ? [
                    {
                      key: 'other',
                      lang: i18n.language.substring(0, 2),
                      value: referralValue.trim(),
                    },
                  ]
                : []),
            ],
            onboardingCompanySize: [{ key: companySize }],
            potentialUsers: [{ key: potentialUsers }],
            usageGoal: [
              ...isActiveUsageGoal.map(x => ({ key: x })),
              ...(usageGoalValue !== null
                ? [
                    {
                      key: 'other',
                      lang: i18n.language.substring(0, 2),
                      value: usageGoalValue.trim(),
                    },
                  ]
                : []),
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(res => {
          if (res.data?.data) {
            dispatch({
              type: dispatchTypes.COMMON.UPDATEFIELD,
              field: 'accountMeta',
              value: res.data.data,
            });
          }
          redirectHandler();
        })
        .catch(err => {
          toast.error(t('unexpectedError'));
          console.error(err);
        })
        .finally(() => setLoading(false)),
    );
  };
  const handleClick = text => {
    setIsActiveCategory([text]);
    setCategoryValue(null);
    scrollToBottom();
  };

  const socialClick = text => {
    setIsActiveSocial([text]);
    setReferralValue(null);
    scrollToBottom();
  };
  const usageGoalClick = text => {
    setIsActiveUsageGoal([text]);
    setUsageGoalValue(null);
    scrollToBottom();
  };

  const accountMeta = useAppSelector<AccountMeta>(state => state.account?.accountMeta);
  useEffect(() => {
    if (accountMeta) {
      if (accountMeta.onboardingCategory) {
        setIsActiveCategory(
          (accountMeta.onboardingCategory || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key),
        );
        setCategoryValue(
          (accountMeta.onboardingCategory || []).find(cat => cat.key === 'other')?.value || null,
        );
      }
      if (accountMeta.onboardingReferrer) {
        setIsActiveSocial(
          (accountMeta.onboardingReferrer || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key),
        );
        setReferralValue(
          (accountMeta.onboardingReferrer || []).find(cat => cat.key === 'other')?.value || null,
        );
      }
      if (accountMeta.onboardingCompanySize) {
        setCompanySize(
          (accountMeta.onboardingCompanySize || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key)?.[0] || '',
        );
      }
      if (accountMeta.potentialUsers) {
        setPotentialUsers(
          (accountMeta.potentialUsers || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key)?.[0] || '',
        );
      }
      if (accountMeta.usageGoal) {
        setIsActiveUsageGoal(
          (accountMeta.usageGoal || []).filter(cat => cat.key !== 'other').map(cat => cat.key),
        );
        setUsageGoalValue(
          (accountMeta.usageGoal || []).find(cat => cat.key === 'other')?.value || null,
        );
      }
    }
  }, [accountMeta]);

  // restrict potential users to maximum company size
  useEffect(() => {
    const idxCompanySize = COMPANY_SIZES.indexOf(companySize);
    const idxPotentialUsers = POTENTIAL_USER_SIZES.indexOf(potentialUsers);
    if (idxPotentialUsers > idxCompanySize) {
      setPotentialUsers('');
    }
  }, [companySize, potentialUsers]);

  // pre-fill when in dev
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setIsActiveCategory([buttonsCategory[0]]);
      setIsActiveSocial([buttonsReferral[0]]);
      setIsActiveUsageGoal([USAGE_GOALS[0]]);
      setCompanySize(COMPANY_SIZES[0]);
      setPotentialUsers(POTENTIAL_USER_SIZES[0]);
    }
  }, []);

  return (
    <OnboardingScreenWrapper
      title={t('onboarding.tellUs')}
      description={t('onboarding.forPersonalize')}
    >
      <Section title={t('onboarding.selectCategory')}>
        {buttonsCategory.map((button, index) => (
          <CustomChip
            onClick={() => handleClick(button)}
            key={index}
            label={t(button)}
            isActive={isActiveCategory.includes(button)}
          />
        ))}
        <CustomChip
          isActive={categoryValue !== null}
          onClick={() => {
            setIsActiveCategory([]);
            setCategoryValue(prev => (prev === null ? '' : null));
          }}
          label={t('other')}
        />
        {categoryValue !== null && (
          <TextField
            label={t('onboarding.enterOtherCategory')}
            value={categoryValue}
            onChange={e => setCategoryValue(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        )}
      </Section>
      {(typeof categoryValue === 'string' || isActiveCategory.length > 0) && (
        <>
          <Section title={t('onboarding.howDidYouhear')}>
            {buttonsReferral.map((button, index) => (
              <CustomChip
                label={t(`onboardingReferrer.${button}`)}
                isActive={isActiveSocial.includes(button)}
                onClick={() => socialClick(button)}
                key={index}
              />
            ))}
            <CustomChip
              isActive={referralValue !== null}
              label={t('other')}
              onClick={() => {
                setIsActiveSocial([]);
                setReferralValue(prev => (prev === null ? '' : null));
              }}
            />
            {referralValue !== null && (
              <TextField
                label={t('onboarding.EnterOther')}
                value={referralValue}
                onChange={e => setReferralValue(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
            )}
          </Section>
          {(typeof referralValue === 'string' || isActiveSocial.length > 0) && (
            <>
              <Section title={t('onboarding.whyLemontaps.title')}>
                {USAGE_GOALS.map((button, index) => (
                  <CustomChip
                    key={index}
                    isActive={isActiveUsageGoal.includes(button)}
                    label={t(`onboarding.whyLemontaps.${button}`)}
                    onClick={() => usageGoalClick(button)}
                  />
                ))}
                <CustomChip
                  isActive={usageGoalValue !== null}
                  label={t('other')}
                  onClick={() => {
                    setIsActiveUsageGoal([]);
                    setUsageGoalValue(prev => (prev === null ? '' : null));
                  }}
                />
                {usageGoalValue !== null && (
                  <TextField
                    label={t('onboarding.EnterOther')}
                    value={usageGoalValue}
                    onChange={e => setUsageGoalValue(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
              </Section>
              {(typeof usageGoalValue === 'string' || isActiveUsageGoal.length > 0) && (
                <>
                  <Section title={t('onboarding.howMany')}>
                    {COMPANY_SIZES.map((size, index) => (
                      <CustomChip
                        isActive={companySize === size}
                        onClick={() => {
                          setCompanySize(size);
                          scrollToBottom();
                        }}
                        key={index}
                        label={t(size)}
                      />
                    ))}
                  </Section>
                  {companySize && (
                    <Section title={t('onboarding.howManyPotentialUsers')}>
                      {POTENTIAL_USER_SIZES.map((size, index) => {
                        const disabled = index > COMPANY_SIZES.indexOf(companySize);
                        if (disabled) return null;
                        return (
                          <CustomChip
                            sx={{
                              opacity: disabled ? '0.5' : '1',
                            }}
                            onClick={() => setPotentialUsers(size)}
                            key={index}
                            label={t(size)}
                            isActive={potentialUsers === size}
                          />
                        );
                      })}
                    </Section>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <ContinueButton
        sx={{ mt: 3 }}
        onClick={onboardingCompletedHandler}
        disabled={
          (!isActiveCategory.length && !categoryValue) ||
          (!isActiveSocial.length && !referralValue) ||
          (!isActiveUsageGoal.length && !usageGoalValue) ||
          companySize === '' ||
          potentialUsers === ''
        }
        loading={isLoading || loading}
        loadingPosition='end'
      >
        {t('onboarding.letsStart')}
      </ContinueButton>
      <div ref={scrollRef} />
    </OnboardingScreenWrapper>
  );
};
