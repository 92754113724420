import { ErrorInfo, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Account } from '@/shared/types/api';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '@/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { useNotificationsStore } from '@/zustand/notificationsStore';
import { useAppSelector } from '@/application/hooks';
import { withSilentAccessToken } from '@/infrastructure/helper';
import config from '@/config/config';
import { routePaths } from '@/infrastructure/constants';

interface Props {
  errorId: string;
  error: Error;
  errorInfo: ErrorInfo;
}

const DesignError = (props: Props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { theme: muiTheme } = useMuiTheme();
  const [textMessage, setTextMessage] = useState('');
  const account = useAppSelector<Account>(state => state.account);
  const MainError = props.error.toString();
  const ErrorStacktrace = props.errorInfo.componentStack;

  const SubmitError = useCallback(
    async (userDetails: boolean, textMessage: string) => {
      if (!props.error) return;
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.post(
            config.API_BASE_URL + 'monitor/error',
            {
              errorId: props.errorId,
              browser: (navigator?.userAgent || '').toString(),
              href: window.location.href,
              error: MainError || 'Error',
              errorStackTrace: ErrorStacktrace || 'Error stack not detected',
              accountId: account.id,
              message: (userDetails && textMessage) || undefined,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),
        [],
        () => {
          if (userDetails)
            useNotificationsStore.getState().notify.loading(t('Loading'), { id: 'error-toast' });
        },
      )
        .then(res => {
          if (userDetails) {
            if (res.data?.isSuccess) {
              useNotificationsStore
                .getState()
                .notify.success(t('errorMessageSentSuccess'), { id: 'error-toast' });
            } else {
              useNotificationsStore
                .getState()
                .notify.error(t('unexpectedError'), { id: 'error-toast' });
            }
          }
        })
        .catch(() => {
          if (userDetails)
            useNotificationsStore
              .getState()
              .notify.error(t('unexpectedError'), { id: 'error-toast' });
        })
        .finally(() => setTextMessage(''));
    },
    [ErrorStacktrace, MainError, account.id, getAccessTokenSilently, props.error, props.errorId, t],
  );
  useEffect(() => {
    SubmitError(false, '');
  }, [SubmitError]);

  const onHomeClickHandler = () =>
    (window.location.href =
      window.location.origin +
      (window.location.pathname === routePaths.HOME ? routePaths.EDITPROFILE : routePaths.HOME));

  return (
    <ThemeProvider theme={muiTheme}>
      <PageContainer maxWidth='m'>
        <Box display='flex' flexDirection='column' minHeight='70vh' justifyContent='center' gap={3}>
          <Alert severity='error'>
            <AlertTitle>{t('Oops')}</AlertTitle>
            {t('anErrorHasOccured')}
          </Alert>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls='error-boundary-content'
              id='error-boundary-header'
            >
              <Typography variant='body1'>{t('contactUs')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label={t('message')}
                value={textMessage}
                onChange={e => setTextMessage(e.target.value)}
                name='contactUs'
                multiline
                fullWidth
              />
              <Box>
                <Button onClick={() => SubmitError(true, textMessage)}>{t('submitText')}</Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box display='flex' justifyContent='center' width='100%'>
            <Button onClick={onHomeClickHandler} size='large'>
              {t('goHome')}
            </Button>
          </Box>
        </Box>
      </PageContainer>
    </ThemeProvider>
  );
};

export default DesignError;
