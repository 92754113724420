import { Link, LinkTemplate, LinkType } from '@/shared/types/api';
import { Box } from '@mui/material';
import { LINK_CREATE_EDIT_MODES } from '@/shared/constants';
import { LinkField, LinkTypeSelector, NameField, TemplateSelectorUser } from './components';
import { LinkBox } from './LinkBox';

type Props = {
  linkTypes: LinkType[];
  values?: Partial<Link>;
  onChange: (values: Partial<Link>) => void;
  templates?: LinkTemplate[];
};

export const AddEditUserLinkForm = ({ linkTypes, values, onChange, templates }: Props) => {
  const isEdit = Boolean(values.id);

  const selectedLinkType =
    (values.linkTypeId && linkTypes.find(lt => lt.id === values.linkTypeId)) || null;

  const handleTemplateSelect = (templateId: number) => {
    if (templateId === -1) {
      onChange({
        ...values,
        linkTemplateId: templateId,
        name: '',
        link: '',
      });
      return;
    }

    const linkTemplate = templates?.find(t => t.id === templateId);
    if (!linkTemplate) return;

    onChange({
      link: linkTemplate.link,
      name: linkTemplate.name,
      linkTypeId: linkTemplate.themeLinkType.linkTypeId,
      linkType: linkTemplate.themeLinkType.linkType,
      linkTemplateId: templateId,
    });
  };

  const availableTemplates = templates?.filter(
    t => t.themeLinkType.linkTypeId === values.linkTypeId,
  );

  const isTemplateMode = selectedLinkType?.createEditMode === LINK_CREATE_EDIT_MODES.FROM_TEMPLATES;
  const isOwnMode = selectedLinkType?.createEditMode === LINK_CREATE_EDIT_MODES.CUSTOM;
  const isOwnAndTemplateMode = selectedLinkType?.createEditMode === LINK_CREATE_EDIT_MODES.ALL;

  return (
    <Box display='flex' flexDirection='column' gap='2rem'>
      <LinkTypeSelector
        linkTypes={linkTypes}
        value={values.linkTypeId}
        onChange={linkTypeId => onChange({ linkTypeId })}
        disabled={isEdit}
      />
      {(isTemplateMode || isOwnAndTemplateMode) && (
        <TemplateSelectorUser
          templates={availableTemplates}
          value={values.linkTemplateId}
          onChange={handleTemplateSelect}
          showCreateOwnTemplate={isOwnAndTemplateMode}
        />
      )}
      {(isOwnMode || (isOwnAndTemplateMode && values.linkTemplateId === -1)) && (
        <>
          <NameField value={values.name || ''} onChange={name => onChange({ ...values, name })} />
          <LinkField
            value={values.link || ''}
            onChange={link => onChange({ ...values, link })}
            selectedLinkType={selectedLinkType}
          />
        </>
      )}
      {values.linkTemplateId && values.linkTemplateId !== -1 && <LinkBox link={values as Link} />}
    </Box>
  );
};
