import { Account } from '@/shared/types/api';
import { ContentCopy, East, FileDownloadOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';
import QRCode, { QRCodeCanvas } from 'qrcode.react';
import { useState } from 'react';
import { routePaths } from '@/infrastructure/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { flexCenterProps } from '@/util';
import { CardRenderer } from '@/components/CardRenderer';
import { generateProfileLink } from '@/shared/business-logic/features/profile/getter';

type Props = {
  account: Account;
};

export const ShareProfileCard = ({ account }: Props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(qrValue);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  const qrValue = generateProfileLink(account);

  const handleDownloadImg = () => {
    const canvas = document.querySelector('#qr-code-canvas') as HTMLCanvasElement;
    if (!canvas) return;
    var link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = canvas.toDataURL();
    link.click();
  };

  const theme = useTheme();

  const actions = [
    <Button
      component={Link}
      endIcon={<East />}
      variant='text'
      to={routePaths.SHARE_PROFILE}
      aria-label={t('ariaShowAllSharingOptions')}
    >
      {t('homePage.shareProfile.actionTitle')}
    </Button>,
  ];

  return (
    <CardRenderer title={t('homePage.shareProfile.title')} actions={actions}>
      <StyledCardContainer>
        <StyledQRContainer>
          <QRCode
            value={qrValue}
            size={100}
            includeMargin
            renderAs='svg'
            style={{ border: `1px solid ${theme.palette.divider}` }}
          />
          {/* for generating the image to download */}
          <QRCodeCanvas
            id='qr-code-canvas'
            value={qrValue}
            size={250}
            includeMargin
            style={{ display: 'none' }}
          />
          <Button
            variant='text'
            startIcon={<FileDownloadOutlined />}
            onClick={handleDownloadImg}
            aria-label={t('ariaDownloadProfileQrCode')}
          >
            {t('download')}
          </Button>
        </StyledQRContainer>
        <Box flex={1} sx={{ width: '100%' }}>
          <FormControl fullWidth>
            <InputLabel htmlFor='copy-link-input'>
              {t('homePage.shareProfile.copyToShare')}
            </InputLabel>
            <OutlinedInput
              id='copy-link-input'
              label={t('homePage.shareProfile.copyToShare')}
              endAdornment={
                <Tooltip
                  placement='top'
                  title={t('shareProfile.profileLink.copied')}
                  open={tooltipVisible}
                >
                  <IconButton onClick={handleCopy} aria-label={t('ariaCopyProfileUrl')}>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              }
              value={qrValue}
            />
          </FormControl>
        </Box>
      </StyledCardContainer>
    </CardRenderer>
  );
};

const StyledCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const StyledQRContainer = styled(Box)(({ theme }) => ({
  ...flexCenterProps,
  flexDirection: 'column',
  gap: 1,
  alignItems: 'center',
  borderRight: `1px solid ${theme.palette.divider}`,
  marginRight: '3rem',
  paddingRight: '3rem',
  paddingLeft: '1rem',
  [theme.breakpoints.down('lg')]: {
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '3rem',
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
