import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { Box, Button } from '@mui/material';
import { ArrowForwardOutlined } from '@mui/icons-material';

const FullFeatureComparisonSection = () => {
  const { t, activeLanguage } = useAppTranslation();

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Button
        variant='text'
        startIcon={<ArrowForwardOutlined />}
        target='_blank'
        href={
          activeLanguage.startsWith('de')
            ? 'https://lemontaps.de/pages/preise'
            : 'https://lemontaps.de/en/pages/prices'
        }
      >
        {t('teaser.comparison')}
      </Button>
    </Box>
  );
};

export default FullFeatureComparisonSection;
