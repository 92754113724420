import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTableCellHeader from './CustomTableCellHeader';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';
import { SortOption } from '@/shared/types/global';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { isDateField, isNumberField } from './common';
import { LineClamp } from '../LineClamp';
type Props = {
  column: ColumnDefinition;
  sortOptions: SortOption;
  onSortOptionsChange: (sortOptions: SortOption) => void;
  lineClampCount: number;
};
const SortableCustomTableCellHeader = ({
  column,
  sortOptions,
  onSortOptionsChange,
  lineClampCount,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { sort, orderBy: sortColumn } = sortOptions || {};
  const isSortedByAsc = sortColumn === column.key && sort === 'ASC';
  const isSortedByDesc = sortColumn === column.key && sort === 'DESC';
  const isCreatedOnColumn = column.key === 'createdOn';
  const isDate = isDateField(column.key);
  const isNumber = isNumberField(column.key);
  const descText = t(isDate ? 'dateDesc' : isNumber ? 'numberDesc' : 'textDesc');
  const ascText = t(isDate ? 'dateAsc' : isNumber ? 'numberAsc' : 'textAsc');

  const actions = [
    (isCreatedOnColumn || !isSortedByDesc) && {
      icon: <ArrowUpward />,
      label: descText,
      onClick: () => onSortOptionsChange({ orderBy: column.key, sort: 'DESC' }),
    },
    (isCreatedOnColumn || !isSortedByAsc) && {
      icon: <ArrowDownward />,
      label: ascText,
      onClick: () => onSortOptionsChange({ orderBy: column.key, sort: 'ASC' }),
    },
    !isCreatedOnColumn &&
      (isSortedByAsc || isSortedByDesc) && {
        label: t('reset'),
        onClick: () => onSortOptionsChange({ orderBy: 'createdOn', sort: 'DESC' }),
        color: 'secondary',
      },
  ].filter(Boolean);
  return (
    <>
      <CustomTableCellHeader
        key={column.key}
        noPadding
        aria-sort={isSortedByAsc ? 'ascending' : isSortedByDesc ? 'descending' : undefined}
      >
        <Box
          component='button'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          textAlign='left'
          onClick={e => setAnchorEl(e.currentTarget)}
          px={1.6}
          width='100%'
          height='100%'
          sx={{ cursor: 'pointer', outlineOffset: '-2px' }}
          aria-haspopup='menu'
        >
          <LineClamp lineCount={lineClampCount} text={t(column.tkey)} />
          {isSortedByAsc && <ArrowDownward />}
          {isSortedByDesc && <ArrowUpward />}
          {!isSortedByAsc && !isSortedByDesc && <SwapVertIcon color='secondary' />}
        </Box>
      </CustomTableCellHeader>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((item, index) => (
          <MenuItem
            sx={{ width: '20rem' }}
            key={index}
            onClick={() => {
              item.onClick();
              setAnchorEl(null);
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>
              <Typography color={item.color}>{item.label}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default SortableCustomTableCellHeader;
