import { ContactDetailsBox } from '@/shared/types/api';
import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import IconRenderer from './IconRenderer';

type Props = ContactDetailsBox['rows'][number];

export default function ContactDetail({ icon: { name: iconName }, href, lines, label }: Props) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} href={href} target='_blank'>
        <ListItemIcon>
          <IconRenderer name={iconName} />
        </ListItemIcon>
        <ListItemText>
          {lines.map(line => (
            <Typography variant='body2' color='secondary'>
              {line.text}
            </Typography>
          ))}
          {label && (
            <Typography variant='caption' color='secondary'>
              {label}
            </Typography>
          )}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
