import { useAppSelector } from '@/application/hooks';
import { NavItem } from '@/infrastructure/hoc/types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Divider, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CollapsableList } from './CollapsableList';

type Props = {
  title?: string;
  ariaLabel: string;
  ariaHidden?: boolean;
  items: NavItem[];
  collapsable?: boolean;
};

export const SingleListSet = ({
  ariaLabel,
  ariaHidden,
  title = '',
  items,
  collapsable = true,
}: Props) => {
  const [opened, setOpened] = useState(true);

  const sidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  const headerVisible = collapsable && !sidebarCollapsed;

  useEffect(() => {
    if (sidebarCollapsed) setOpened(true);
  }, [sidebarCollapsed]);

  return (
    <Box mb={'0.4rem'}>
      {collapsable && <Divider sx={{ borderColor: 'inherit', opacity: '0.15', mb: '0.4rem' }} />}
      {headerVisible && (
        <ListItemButton
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          sx={{
            height: '4rem',
            justifyContent: 'space-between',
            ':hover': { bgcolor: 'initial' },
            '&.Mui-focusVisible': {
              zIndex: 1,
              backgroundColor: 'inherit',
            },
          }}
          aria-expanded={opened}
          onClick={() => setOpened(!opened)}
        >
          <Typography variant='body1' color='inherit'>
            {title}
          </Typography>
          {opened ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <CollapsableList items={items} sidebarCollapsed={sidebarCollapsed} opened={opened} />
    </Box>
  );
};
