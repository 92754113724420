import { LtDialog } from '@/components';
import { IdpOption } from '@/shared/constants/Idp';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  idp?: IdpOption | null;
  onClose: () => void;
  onAdd: (idpNiceName: string) => void;
  open: boolean;
  loading?: boolean;
};
export const CreateIdpPopup = ({ idp, onClose, onAdd, open, loading }: Props) => {
  const [idpName, setIdpName] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    setIdpName('');
  }, [open]);

  return (
    <LtDialog
      open={open}
      size='lg'
      onClose={onClose}
      onCancel={onClose}
      title={t('idp.addIdentityProviderDirectory')}
      confirmAction={{
        onClick: () => onAdd(idpName),
        text: t('add'),
        loading,
        'aria-busy': loading,
      }}
    >
      <Grid container spacing={1.6}>
        <Grid item xs={6}>
          <TextField
            label={t('idp.identityProvider')}
            fullWidth
            value={idp?.label}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img
                    src={idp?.imgPath}
                    alt={idp?.label}
                    style={{ width: '2.4rem', height: '2.4rem' }}
                  />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('idp.directoryName')}
            fullWidth
            value={idpName}
            onChange={({ target: { value } }) => setIdpName(value)}
          />
        </Grid>
      </Grid>
    </LtDialog>
  );
};
