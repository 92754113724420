import { Filter, OptionValue } from '@/shared/util';

export type FilterValue = {
  operator: string;
  value: string | Date | OptionValue[];
};

/**
 * For each filter component for example ListFilter, DateFilter, etc.
 * Should be used this Props type
 */
export type FilterComponentProps = {
  filter: Filter;
  filterValue: FilterValue;
  onFilterValueChange: (value: FilterValue) => void;
  onFilterDelete: () => void;
};

export const transformValue = (val: string | Date | OptionValue[]) => {
  if (val instanceof Date) {
    return val.toISOString();
  }

  if (Array.isArray(val)) {
    return val.join(',');
  }

  return val;
};

export const transformToQueryParams = (values: Record<string, FilterValue>) => {
  const transformed = Object.entries(values)
    .filter(([_, val]) => Boolean(val?.value?.toString()?.length))
    .map(([key, value]) => `${key}-${value.operator}-${transformValue(value.value)}`);

  return transformed;
};
