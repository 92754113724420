import { DATE_RANGE_OPTIONS } from '@/shared/constants';
import { DefinedRange } from './types';
import { addDays, addMonths, addYears } from './utils';
import i18nMain from '@/config/i18n';
import { CustomDateType } from '@/shared/types/api';

export const getDefaultRanges = (date: Date): DefinedRange[] =>
  DATE_RANGE_OPTIONS.map(option => ({
    label: i18nMain.t(`datePicker.options.${option.tTitle}`),
    startDate: getCustomStartDate(date, option.value, option.type),
    endDate: date,
  }));

export const getCustomStartDate = (date: Date, value: number, type: CustomDateType) => {
  switch (type) {
    case 'day':
      return addDays(date, -value);
    case 'week':
      return addDays(date, -value);
    case 'month':
      return addMonths(date, -value);
    case 'year':
      return addYears(date, -value);
    case 'fixed':
      return new Date(value);
    default:
      return date;
  }
};

export const getDefaultMinDate = () => {
  const dateTime = DATE_RANGE_OPTIONS.filter(dr => dr.tTitle === 'allTime')[0]?.value || undefined;
  return new Date(dateTime) || undefined;
};
