import { Box, BoxProps, SxProps } from '@mui/material';

const styles = theme => ({
  width: '100%',
  height: '100%',
  maxWidth: '768px',
  margin: 'auto',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 0,
  position: 'fixed',
  zIndex: 1111,
  overflow: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.10)',
  },
});

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
  nobg?: boolean;
  noShadow?: boolean;
} & BoxProps;

export const FullScreenContainer = ({
  children,
  sx: _sx = {},
  nobg = false,
  noShadow = false,
  ...rest
}: Props) => {
  return (
    <Box
      className='scanner-page-container'
      sx={theme => ({ ...styles(theme) })}
      bgcolor={nobg ? 'transparent' : 'white'}
      {...rest}
    >
      {children}
    </Box>
  );
};
