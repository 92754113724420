import { AccountImageState, ApiFile } from '../../shared/types/api';

import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { SavelinkItemProps } from './md/profiles/links';
import { PERMISSIONS } from '../constants';
import { Link } from '../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';

export const updateImageUrl = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  imageUrl: string,
  type: 'profile' | 'banner' | 'logoHeader',
  imageState?: AccountImageState,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}account/update/imageurl`,
    {
      imageUrl,
      type,
      imageState,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const saveLinkAPI = async (
  linkItem: SavelinkItemProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}link/save`,
    {
      linkItem,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const reorderLinksAPI = async (
  links: Array<any>, //TODO: remove any
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(`${config.API_BASE_URL}links`, [...links], {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const reorderFilesAPI = async (
  files: Array<any>, //TODO: remove any
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(`${config.API_BASE_URL}files`, [...files], {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const updateLinkDisplayStatus = async (
  linkId: number,
  canDisplayOnProfile: boolean,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}link/update/display`,
    {
      linkId,
      canDisplayOnProfile,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const updateLinkDefaultStatus = async (
  linkId: number,
  isDefault: boolean,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}link/update/default`,
    {
      linkId,
      isDefault,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const deleteLinkById = async (
  linkId: number,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}link/delete`,
    {
      linkId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const saveFile = async (
  fileName: string,
  url: string,
  embed: boolean,
  pageCount: number | null,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}file/save`,
    {
      fileEntity: {
        fileName,
        link: url,
        pageCount,
        embed,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const saveFileTemplate = async (
  fileName: string,
  url: string,
  pageCount: number | null,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}theme/file-templates`, ///here must chagne
    {
      fileTemplateEntity: {
        fileName,
        link: url,
        pageCount: pageCount,
        isStandard: true,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const deleteFileById = async (
  fileId: number,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}file/delete`,
    {
      fileId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const updateFileDisplayStatus = async (
  fileId: number,
  canDisplayOnProfile: boolean,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}file/update/display`,
    {
      fileId,
      canDisplayOnProfile,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const fetchThemeLinkTypes = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  withBlockedTypes?: boolean,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.get(`${config.API_BASE_URL}link/themeLinkTypes`, {
    params: {
      withBlocked: withBlockedTypes,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updatePreferredLanguage = async (
  lang: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}account/update/preferredLanguage`,
    {
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const saveFileThemeAPI = async (
  files: ApiFile[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(`${config.API_BASE_URL}theme/global-files`, files, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const saveCompanyDetailsLinksAPI = async (
  links: Link[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  unitId?: number,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}theme/global-links`,
    {
      links: links.map((link, index) => {
        return {
          ...('id' in link ? { id: link.id } : {}),
          canDisplayOnProfile: true,
          link: link.link,
          name: link.name,
          order: index * 10,
          linkType: {
            // @ts-ignore:next-line
            id: link.linkType?.id || link?.themeLinkType?.linkTypeId || link?.linkTypeId,
          },
          linkTemplateId: link.linkTemplateId,
        };
      }),
    },
    {
      params: { unitId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};
