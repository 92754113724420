import { useEffect } from 'react';
import i18n from '../../config/i18n';
import { useNotificationsStore } from '@/zustand/notificationsStore';

const useDeferredLoader = (controlState: boolean, id: string) => {
  useEffect(() => {
    let timeoutId = null;
    if (controlState) {
      timeoutId = setTimeout(
        () => useNotificationsStore.getState().notify.loading(i18n.t('Loading'), { id }),
        500,
        id,
      );
      return () => {
        clearTimeout(timeoutId);
        useNotificationsStore.getState().notify.dismiss(id);
      };
    } else {
      useNotificationsStore.getState().notify.dismiss(id);
    }
    return () => {
      clearTimeout(timeoutId);
      useNotificationsStore.getState().notify.dismiss(id);
    };
  }, [controlState, id]);
};

export default useDeferredLoader;
