import { Box } from '@mui/material';
// import { AnchorText } from '../AnchorText';
import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';
// import { Typography } from '@mui/material';

export const CopyUrlsStep = ({ fields }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextsRenderer
        mb={1.2}
        title={t('idp.sso.steps.copyUrls.title')}
        texts={[t('idp.sso.steps.copyUrls.description')]}
      />

      {/* <AnchorText mb={2} href='#' text={t('idp.sso.learnMoreAboutSaml')} /> */}
      {/* <Typography mb={3.2} variant='body1' color='text.secondary'>
        {t('idp.sso.steps.copyUrls.description3')}
      </Typography> */}

      <Box mt={2} gap={1.8} display='flex' flexDirection='column'>
        {fields}
      </Box>
    </>
  );
};
