import { useTranslation } from 'react-i18next';
import { TextsRenderer } from '../TextsRenderer';

export const SaveConfigStep = () => {
  const { t } = useTranslation();
  return (
    <TextsRenderer
      title={t('idp.sso.steps.save.title')}
      texts={[t('idp.sso.steps.save.description')]}
    />
  );
};
