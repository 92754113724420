import { Close } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const FilterHeader = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Typography variant='h2'>{t('filter.title')}</Typography>
      <Button variant='text' size='small' startIcon={<Close />} onClick={onClose}>
        {t('close')}
      </Button>
    </Box>
  );
};
