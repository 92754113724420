import { useTranslation } from 'react-i18next';
import { Unit } from '@/shared/types/api/unit.type';
import { Employee } from '@/shared/types/api/employee.type';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { ThemeInternal } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { Alert, Autocomplete, Divider, TextField, Typography } from '@mui/material';
import { getMainUnitSync, lexicalSortObject } from '@/shared/util';

type Props = {
  units?: Unit[];
  selectedEmployees?: Employee[];
  values: {
    selectedMainUnit: Unit;
    selectedOtherUnits: Unit[];
  };
  onChange: (values: { selectedMainUnit: Unit; selectedOtherUnits: Unit[] }) => void;
  showWarning?: boolean;
  errorMessage?: string;
};

export const UnitSelectorForProfiles = ({
  selectedEmployees = [],
  units,
  values = { selectedMainUnit: null, selectedOtherUnits: [] },
  onChange,
  showWarning = true,
  errorMessage,
}: Props) => {
  const { selectedMainUnit, selectedOtherUnits } = values;
  const { t } = useTranslation();
  const { isThemeAdmin, isUnitAdmin } = useTierInfo();

  const { multiUnitsAllowed } = useAppSelector<ThemeInternal>(
    state => state.account.theme.themeInternal,
  );

  const canEditMainUnits =
    isThemeAdmin ||
    (isUnitAdmin &&
      selectedEmployees
        .map(e => getMainUnitSync(e)?.id)
        .every(mainUnitId => units.some(u => u.id === mainUnitId)));

  const sortedUnits = units && [...units].sort(lexicalSortObject('niceName'));

  return (
    <>
      {sortedUnits && sortedUnits.length > 0 && (
        <>
          <Typography variant='h3' sx={{ mb: 2 }}>
            {multiUnitsAllowed ? t('mainUnit') : t('mdLeads.unit')}
          </Typography>
          {canEditMainUnits && (
            <Autocomplete
              disabled={!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)}
              multiple={false}
              options={sortedUnits.filter(({ id }) => id !== selectedMainUnit?.id) || []}
              onChange={(_event, newValue: Unit) => {
                if (!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)) {
                  return;
                }
                let otherUnits = [];
                if (multiUnitsAllowed) {
                  otherUnits = newValue
                    ? [
                        ...(selectedOtherUnits || []).filter(u => u.id !== newValue.id),
                        selectedMainUnit,
                      ].filter(Boolean)
                    : [];
                }
                onChange({
                  selectedMainUnit: newValue,
                  selectedOtherUnits: otherUnits,
                });
              }}
              value={selectedMainUnit}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('select')}
                  error={Boolean(errorMessage)}
                  aria-invalid={Boolean(errorMessage)}
                  helperText={errorMessage}
                />
              )}
              getOptionLabel={(option: Unit) => option.niceName}
            />
          )}
        </>
      )}

      {multiUnitsAllowed && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography sx={{ mb: 2 }} variant='h3'>
            {t('otherUnits')}
          </Typography>
          <Autocomplete
            disabled={
              (canEditMainUnits && !selectedMainUnit) ||
              (!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin))
            }
            multiple
            options={sortedUnits.filter(({ id }) => id !== selectedMainUnit?.id) || []}
            disableClearable
            onChange={(_event, newValue: []) => {
              onChange({
                selectedMainUnit,
                selectedOtherUnits: newValue,
              });
            }}
            value={selectedOtherUnits}
            renderInput={params => <TextField {...params} label={t('select')} />}
            getOptionLabel={(option: Unit) => option.niceName}
          />
        </>
      )}
      {showWarning && (
        <Alert severity='warning' sx={{ my: 2 }}>
          {t('mdLeads.assignUnitWarning')}
        </Alert>
      )}
    </>
  );
};
