import { Trans } from 'react-i18next';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { LtDialog } from '@/components';
import { Box, Link, Typography } from '@mui/material';

type Props = {
  onClose: () => void;
  success?: boolean;
};

const PopupMessage = (props: Props) => {
  const { t } = useAppTranslation();
  return (
    <LtDialog open onClose={props.onClose} title={t('referrals.redeem.popup.title')} size='sm'>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        {props?.success ? (
          <Typography variant='body1'>
            <Trans
              i18nKey='referrals.redeem.popup.success'
              components={[
                <Link
                  href='mailto:contact@lemontaps.com'
                  target='_blank'
                  rel='noreferrer'
                  sx={{
                    color: 'text.primary',
                    ':hover, :visited, :active': {
                      color: 'text.primary',
                    },
                  }}
                />,
              ]}
            />
          </Typography>
        ) : (
          <Typography variant='body1'>{t('referrals.redeem.popup.failed')}</Typography>
        )}
      </Box>
    </LtDialog>
  );
};

export default PopupMessage;
