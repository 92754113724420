import { useEffect, useState } from 'react';
import { EMAIL_EDITOR_INITIALIZED_EVENT } from '../constants';

export const useAccesebility = () => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    const handleEmailEditorInitialized = () => setInitialized(true);
    document.addEventListener(EMAIL_EDITOR_INITIALIZED_EVENT, handleEmailEditorInitialized);
    return () => {
      document.removeEventListener(EMAIL_EDITOR_INITIALIZED_EVENT, handleEmailEditorInitialized);
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;
    const blockItemButtons = document.querySelectorAll('._blockItem_1ajtj_6');
    blockItemButtons.forEach(button => {
      button.setAttribute('tabindex', '0');
      button.setAttribute('role', 'button');
    });
  }, [initialized]);
};
