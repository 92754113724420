import { ReactNode, useMemo } from 'react';

import { BsChatText } from 'react-icons/bs';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { TRIAL_LENGTH_DAYS } from '@/shared/constants';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { Box, Button, Typography, styled } from '@mui/material';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { Check, GroupOutlined, PersonOutlineOutlined } from '@mui/icons-material';

interface TierProps {
  highlight?: string;
  title: string;
  subtitle: {
    icon: ReactNode;
    text: string;
  };
  shortDescr: string;
  label: JSX.Element;
  sublabel?: string;
  actionButton: {
    text: string;
    action?: () => void;
    subtext?: string;
    subaction?: () => void;
  };
  description: {
    title: string;
    bullets: string[];
  };
}

const Tier = ({
  highlight,
  title,
  subtitle,
  shortDescr,
  label,
  sublabel,
  actionButton,
  description,
}: TierProps) => {
  return (
    <TierContainer highlight={highlight}>
      {highlight && (
        <TierHighlight>
          <Typography variant='body2' color={'success.contrastText'}>
            {highlight}
          </Typography>
        </TierHighlight>
      )}

      <Typography variant='h2'>{title}</Typography>
      <Box display={'flex'} alignItems={'center'} mt={1}>
        {subtitle.icon}
        <Typography variant='body2'>{subtitle.text}</Typography>
      </Box>

      <Typography variant='body2' sx={{ mt: 2 }}>
        {shortDescr}
      </Typography>

      <Box display={'flex'} flexDirection={'column'} my={3}>
        <Box mb={2}>
          <Box>{label}</Box>
          <Typography variant='caption'>{sublabel}</Typography>
        </Box>
        <Box m={'auto'} mt={1}>
          <Button
            onClick={actionButton.action ?? undefined}
            disabled={!actionButton.action}
            size='large'
          >
            {actionButton.text}
          </Button>
        </Box>
        {actionButton.subtext && (
          <Box m={'auto'}>
            <Typography
              variant='caption'
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={actionButton.subaction}
            >
              {actionButton.subtext}
            </Typography>
          </Box>
        )}
      </Box>

      <Typography variant='h3' mt={2} mb={2}>
        {description.title}
      </Typography>
      {description.bullets.map(bullet => (
        <Box key={bullet} display={'flex'} alignItems={'center'} my={1}>
          <Check sx={{ fontSize: 15, mr: 1 }} />
          <Typography variant='caption'>{bullet}</Typography>
        </Box>
      ))}
    </TierContainer>
  );
};

const TiersSection = ({
  canStartTrial,
  onShowUpgradePopup,
}: {
  canStartTrial: boolean;
  onShowUpgradePopup: Function;
}) => {
  const { t } = useAppTranslation();
  const { desktopView } = useDesktopView();
  const { upgradeAction, upgradeRequestPopup, setShowUpgradePopup } = useUpgradeAction();
  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  const TIERS_CONFIG = useMemo<TierProps[]>(
    () => [
      {
        title: 'Lemontaps Starter',
        subtitle: {
          icon: <PersonOutlineOutlined sx={{ fontSize: 18, mr: 1 }} />,
          text: t('teaser.individualUser'),
        },
        shortDescr: t('teaser.starterShortDescr'),
        label: (
          <div style={{ height: '6rem', color: '#181A5A', fontWeight: 500, fontSize: '4.5rem' }}>
            0€
          </div>
        ),
        sublabel: t('teaser.noPayment'),
        actionButton: {
          text: t('teaser.currentPlan'),
        },
        description: {
          title: t('teaser.basicFeature'),
          bullets: t('teaser.starterBullets', { returnObjects: true }),
        },
      },
      {
        highlight: t('teaser.popular'),
        title: 'Lemontaps Teams',
        subtitle: {
          icon: <GroupOutlined sx={{ fontSize: 18, mr: 1 }} />,
          text: t('teaser.groupUsers'),
        },
        shortDescr: t('teaser.teamsShortDescr'),
        label: flag_hidePrices ? (
          <div style={{ height: '6rem' }}>
            <BsChatText style={{ height: '6rem' }} size={45} color='#181A5A' />
          </div>
        ) : (
          <div style={{ height: '6rem', color: '#181A5A', fontWeight: 500, fontSize: '4.5rem' }}>
            1,25€
          </div>
        ),
        sublabel: flag_hidePrices ? t('teaser.graduatedprices') : t('teaser.peruserpermonth'),
        actionButton: {
          text: !canStartTrial
            ? flag_hidePrices
              ? t('contactSales')
              : t('upgradeNow')
            : t('teaser.costless', { days: TRIAL_LENGTH_DAYS }),
          subtext: !canStartTrial
            ? undefined
            : flag_hidePrices
            ? t('teaser.salesSubtext')
            : t('teaser.upgradeSubtext'),
          subaction: !canStartTrial ? undefined : upgradeAction,
          action: !canStartTrial ? upgradeAction : () => onShowUpgradePopup(),
        },
        description: {
          title: t('teaser.allStarterFeatures'),
          bullets: t('teaser.proBullets', { returnObjects: true }),
        },
      },
      {
        title: 'Lemontaps Enterprise',
        subtitle: {
          icon: <GroupOutlined sx={{ fontSize: 18, mr: 1 }} />,
          text: t('teaser.hunderdsgroupUsers'),
        },
        shortDescr: t('teaser.enterpriseShortDescr'),
        label: (
          <div style={{ height: '6rem' }}>
            <BsChatText style={{ height: '6rem' }} size={45} color='#181A5A' />
          </div>
        ),
        sublabel: flag_hidePrices ? t('teaser.graduatedprices') : t('teaser.askUs'),
        actionButton: {
          text: t('contactSales'),
          action: () => setShowUpgradePopup(true),
        },
        description: {
          title: t('teaser.allProFeatures'),
          bullets: t('teaser.enterpriseBullets', { returnObjects: true }),
        },
      },
    ],
    [t, flag_hidePrices, canStartTrial, upgradeAction, onShowUpgradePopup, setShowUpgradePopup],
  );
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} py={3}>
      {upgradeRequestPopup}
      <Typography variant='h1'>{t('teaser.packageComparison')}</Typography>
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(3, minmax(0, 1fr))'}
        columnGap={1.5}
        m={'8rem auto 0'}
        justifyContent={'center'}
        sx={!desktopView && { gridTemplateColumns: 'repeat(1, minmax(0, 1fr))' }}
      >
        {TIERS_CONFIG.map(config => (
          <Tier {...config} />
        ))}
      </Box>
    </Box>
  );
};

export default TiersSection;

const TierContainer = styled('div')<{ highlight: boolean }>(({ theme, highlight }) => ({
  background: '#fff',
  border: '2px solid',
  borderColor: `${highlight ? theme.palette.info.dark : '#fff'}`,
  borderRadius: '1rem',
  borderTopLeftRadius: `${highlight ? 0 : '1rem'}`,
  borderTopRightRadius: `${highlight ? 0 : '1rem'}`,
  boxShadow: `${theme.palette.action.focus} 4px 4px 16px`,
  maxWidth: '35rem',
  overflow: 'visible',
  position: 'relative',
  padding: '2rem 1.5rem',
  marginTop: 0,
  [theme.breakpoints.down('1024')]: {
    maxWidth: 'initial',
  },
  [theme.breakpoints.down('768')]: {
    marginTop: `${highlight ? '3rem' : '0'}`,
    marginBottom: '2rem',
  },
}));

const TierHighlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '-2px',
  right: '-2px',
  top: '-3rem',
  background: theme.palette.primaryButton.main,
  height: '3rem',
  textAlign: 'center',
  display: 'grid',
  placeItems: 'center',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
}));
