import { ComponentProps, PropsWithChildren } from 'react';
import Button from '@mui/material/Button';
import East from '@mui/icons-material/East';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CardRenderer } from '@/components/CardRenderer';
import { Link } from 'react-router-dom';

export const AcademyCardRenderer = (
  props: PropsWithChildren<{
    title?: string;
    handleClick?: () => void;
    href?: string;
    to?: ComponentProps<Link>['to'];
  }>,
) => {
  const { t } = useAppTranslation();
  return (
    <CardRenderer
      title={props.title}
      px={0.8}
      actions={
        (props.handleClick || props.to) && [
          <Button
            endIcon={<East />}
            variant='text'
            component={props.to ? Link : undefined}
            target={'_blank'}
            to={props.to}
            sx={{ ml: 'auto' }}
            onClick={props.handleClick}
          >
            {t('homePage.toAcademy')}
          </Button>,
        ]
      }
    >
      {props.children}
    </CardRenderer>
  );
};
