import { Add, DeleteOutline, EditOutlined, ImageOutlined, Upload } from '@mui/icons-material';
import { Button, IconButton, Paper, ThemeProvider } from '@mui/material';
import { MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import SettingsMenu, { MenuItemType } from '@/components/SettingsMenu';
import { useTranslation } from 'react-i18next';
import { isFileValid } from '@/infrastructure/helper';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import ImageOptionsSelector from './ImageOptionsSelector';
import { ReactCropProps } from 'react-image-crop';
import { AccountImageState } from '@/shared/types/api';
import { RequireAtLeastOne } from '@/shared/types/util';
import ImageCropper from '@/components/ImageCropper';

const MENU_OPTION_TYPES = {
  upload: {
    icon: <Upload />,
    tLabel: 'uploadNew',
  },
  reset: {
    icon: <ImageOutlined />,
    tLabel: 'resetToDefault',
  },
  delete: {
    icon: <DeleteOutline />,
    tLabel: 'delete',
  },
} as const;
export type ImageSettingsOption = keyof typeof MENU_OPTION_TYPES;

type Props = RequireAtLeastOne<
  {
    disallowed?: boolean; // disable buttons with a hint to edit right
    currentUrl: string;
    imageState: AccountImageState;
    defaultImageState: AccountImageState;
    crop?: Partial<ReactCropProps>; // if provided, a cropper will be shown upon file selection
    isButton?: boolean;
    buttonText?: string;
    options?: string[];
    ariaLabel: string;
    CustomAnchor?: (props: { onClick: MouseEventHandler }) => JSX.Element;
    onUploadByFile?: (file: File) => void;
    onUploadByUrl?: (url: string) => void;
    onResetToDefault?: () => void;
    onDelete?: () => void;
    actionLabelOverrides?: Partial<Record<ImageSettingsOption, string>>;
    actionIconOverrides?: Partial<Record<ImageSettingsOption, JSX.Element>>;
  },
  'CustomAnchor' | 'ariaLabel'
>;

// generic component for image selections on profile picture, profile banner, profile logo entities
// -> the available actions are upload (either via file selection, or from a selection of pre-defined images), reset to default, delete
// the available options are determined outside of this component
export const ImageSettings = ({
  disallowed,
  currentUrl,
  imageState,
  defaultImageState,
  crop,
  options,
  buttonText,
  isButton,
  CustomAnchor,
  onUploadByFile,
  onUploadByUrl,
  onResetToDefault,
  onDelete,
  ariaLabel,
  actionLabelOverrides,
  actionIconOverrides,
}: Props) => {
  const { t } = useTranslation();
  const { notifyError, notifyInfo } = useLtNotifications();
  const { theme: muiTheme } = useMuiTheme();

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disallowed) {
      notifyInfo(t('adminDisabledEditRight'));
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const actions = useMemo<ImageSettingsOption[]>(() => {
    if (imageState === AccountImageState.DEFAULT) {
      return ['upload'];
    } else if (defaultImageState === AccountImageState.DELETED) {
      return ['upload', 'delete'];
    } else {
      return ['upload', 'reset'];
    }
  }, [imageState, defaultImageState]);

  const [showBannerChooser, setShowBannerChooser] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<string>(null);

  const fileInputRef = useRef<HTMLInputElement>();
  const onUploadClick = useCallback(() => {
    if (options && options.length > 0) {
      setShowBannerChooser(true);
    } else if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [options]);

  const onImageSelection = useCallback(async () => {
    const uploadedFile = fileInputRef.current?.files[0];

    if (uploadedFile) {
      const extension = uploadedFile.name.split('.').pop();
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        notifyError(errMsg);
        return;
      }

      if (extension === 'gif' || !crop) {
        onUploadByFile?.(uploadedFile);
      } else {
        // show cropper
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => setImageToCrop(reader.result as string);
      }
    }
  }, [crop, notifyError, onUploadByFile, t]);

  const onCropConfirmed = useCallback(
    async (croppedImageUrl: string) => {
      if (fileInputRef.current) {
        let blob = await fetch(croppedImageUrl).then(r => r.blob());
        var file = new File([blob], fileInputRef.current.files[0].name);
        setImageToCrop(null);
        onUploadByFile?.(file);
      }
    },
    [onUploadByFile],
  );

  const menuItems = useMemo<MenuItemType[]>(() => {
    const items: MenuItemType[] = [];
    for (const action of actions) {
      const base = {
        icon: actionIconOverrides?.[action] || MENU_OPTION_TYPES[action].icon,
        label: actionLabelOverrides?.[action] || t(MENU_OPTION_TYPES[action].tLabel),
      };
      switch (action) {
        case 'upload':
          items.push({
            ...base,
            onClick: onUploadClick,
          });
          break;
        case 'reset':
          items.push({
            ...base,
            onClick: onResetToDefault || (() => {}),
          });
          break;
        case 'delete':
          items.push({
            ...base,
            onClick: onDelete || (() => {}),
          });
          break;
      }
    }
    return items;
  }, [
    actionIconOverrides,
    actionLabelOverrides,
    actions,
    onDelete,
    onResetToDefault,
    onUploadClick,
    t,
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <ThemeProvider theme={muiTheme}>
      {CustomAnchor ? (
        <CustomAnchor onClick={handleMenuButtonClick} />
      ) : isButton ? (
        <Button
          startIcon={<Add />}
          variant='outlined'
          color='primary'
          onClick={handleMenuButtonClick}
          aria-label={ariaLabel}
        >
          {buttonText}
        </Button>
      ) : (
        <Paper sx={{ borderRadius: '50%', width: 'max-content', height: 'max-content' }}>
          <IconButton
            onClick={handleMenuButtonClick}
            sx={{ color: 'primary' }}
            aria-label={ariaLabel}
          >
            <EditOutlined color='primary' />
          </IconButton>
        </Paper>
      )}
      <SettingsMenu
        renderAnchor={false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        menuItems={menuItems}
      />

      <input
        type='file'
        ref={fileInputRef}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        aria-hidden='true'
        accept='image/*'
      />

      {crop && imageToCrop && (
        <ImageCropper
          image={imageToCrop}
          onCropClick={url => onCropConfirmed(url)}
          onClose={() => setImageToCrop(null)}
          cropOptions={crop?.crop}
          {...crop}
        />
      )}

      <ImageOptionsSelector
        open={showBannerChooser}
        onClose={() => setShowBannerChooser(false)}
        options={options || []}
        onSelect={url => {
          onUploadByUrl?.(url);
          setShowBannerChooser(false);
        }}
        initialSelection={currentUrl}
      />
    </ThemeProvider>
  );
};
