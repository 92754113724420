import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { QRBGConfig } from '@/shared/types/global';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { QRBGMode } from './types';
import { UPLOADED_IMG_LIMIT_MO, UPLOADED_IMG_LIMIT_VC } from './constants';
import i18nMain from '@/config/i18n';

export const loadQrConfig = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: response } = await fetchThemeConfig(
    getAccessTokenSilently,
    THEME_CONFIG_KEYS.QR_BG_CONFIG,
  );
  let config: QRBGConfig;
  if (response) {
    config = response;
  }
  return config;
};

export const imgWithTimestamp = (src: string) => {
  // our usecase: re-fetch the image from s3 and avoid browser cache
  return src?.concat(`?timestamp=${new Date().getTime()}`);
};

export const generateImageObj = (imgUrl: string) => {
  let img = new Image();
  img.src = imgUrl;
  img.crossOrigin = 'Anonymous';
  return img;
};

export const imageSizeValidator = (
  imgUrl: string,
  mode: QRBGMode | '',
  cb: () => void = undefined,
): Promise<string> => {
  return new Promise(resolve => {
    if (mode === '') resolve('');
    let error = '';
    const img = generateImageObj(imgUrl);
    img.onload = () => {
      if (mode === 'videocall' && img.width < UPLOADED_IMG_LIMIT_VC) {
        error = `${i18nMain.t('uploadedImgSizeErr', { x: UPLOADED_IMG_LIMIT_VC })}`;
      } else if (
        mode === 'mobile' &&
        (img.width < UPLOADED_IMG_LIMIT_MO || img.width >= img.height)
      ) {
        error = `${i18nMain.t('uploadedMobileImgErr', { x: UPLOADED_IMG_LIMIT_MO })}`;
      }
      if (cb) cb?.();
      resolve(error);
    };
  });
};
