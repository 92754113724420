import EastIcon from '@mui/icons-material/East';
import { Avatar, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  to?: ComponentProps<typeof Link>['to'];
};

export const SimpleCard = ({ icon, onClick, text, to }: Props) => {
  return (
    <CardActionArea onClick={onClick} sx={{ height: '100%' }} component={to && Link} to={to}>
      <Card variant='elevation' sx={{ height: '100%' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Avatar
            role='presentation'
            variant='rounded'
            sx={{ borderRadius: '0.4rem', bgcolor: 'secondary.main' }}
          >
            {icon}
          </Avatar>
          <Typography mt={1} component='h2' variant='h4'>
            {text}
          </Typography>
          <EastIcon fontSize='small' sx={{ ml: 'auto', mt: 'auto' }} />
        </CardContent>
      </Card>
    </CardActionArea>
  );
};
