import { LeadAPI } from './lead.type';
import { ThemeInternal } from './theme.type';

export enum SyncType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum CrmExportEventStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
  ERROR_DUPLICATE = 'error_duplicate',
}

export enum ApideckConnectionState {
  AVAILABLE = 'available',
  ADDED = 'added',
  INVALID = 'invalid',
  AUTHORIZED = 'authorized',
  CALLABLE = 'callable',
}

export type CrmExportEvent = {
  id: number;
  leadId: number;
  lead?: LeadAPI;
  apideckConnectionId: number;
  apideckConnection?: ApideckConnection;
  createdOn: Date;
  status: CrmExportEventStatus;
  error?: Record<string, any>;
};

export type ApideckConnection = {
  id: number | string;
  apideckConsumerId: number;
  apideckConsumer?: ApideckConsumer;
  seviceId: string;
  enabled: boolean;
  crmExportEvents?: CrmExportEvent[];
  state: ApideckConnectionState;
};

export type ApideckConsumer = {
  id: number;
  themeInternalId: number;
  themInternal?: ThemeInternal;
  syncType: SyncType;
  useLeads: boolean;
  apideckConnections?: ApideckConnection[];
};

export type CrmConnectorType = {
  id: string;
  name: string;
  websiteUrl: string;
  iconUrl: string;
};

export type SalesForceCampaignForeignResponse = {
  Name: string;
  Id: string;
};

export type SalesForceCampaignForeignListResponse = {
  records: SalesForceCampaignForeignResponse[];
  totalSize: number;
  done: boolean;
};

export type CrmCampaign = {
  name: string;
  id: string;
};

//Ref /node_modules/@apideck/node/dist/gen/models/ConnectionDefaults.d.ts
export type CrmServiceConfigurationForeignResponse = {
  custom_field: boolean;
  id: string;
  label: string;
  options?: { label: string; value: string }[];
  read_only: boolean;
  required: boolean;
  target: 'custom_fields' | 'resource';
  //Probably more types, no docs about this
  type: 'select' | 'text' | 'number' | 'date' | 'datetime' | 'multi-select' | 'checkbox';
  value?: string | number | boolean | Array<string | number> | null;
  description?: string;
};

export type CrmServiceConfguration = Pick<
  CrmServiceConfigurationForeignResponse,
  'id' | 'label' | 'required' | 'options' | 'value' | 'type' | 'description'
>;

export type CrmServiceConfigurationResponse = {
  configurations?: CrmServiceConfguration[];
  serviceId?: string;
  serviceName?: string;
};
