import { TierInfo } from '@/infrastructure/hooks/useTierInfo';
import { RenderConditions } from '@/shared/types/global';

export const checkConditions = (
  tierInfo: TierInfo,
  conditions: RenderConditions | null,
): boolean => {
  if (!conditions) return true;
  if (conditions === 'hide') return false;
  return conditions.every(
    cond =>
      (!cond.tierInfo || (cond.negate ? !tierInfo[cond.tierInfo] : tierInfo[cond.tierInfo])) &&
      (!cond.featureSet ||
        (cond.negate
          ? !tierInfo.isFeatureAllowed(cond.featureSet)
          : tierInfo.isFeatureAllowed(cond.featureSet))) &&
      (!cond.publicFlag ||
        (cond.negate
          ? !tierInfo.publicFlags[cond.publicFlag]
          : tierInfo.publicFlags[cond.publicFlag])) &&
      (!cond.privateFlag ||
        (cond.negate
          ? !tierInfo.privateFlags[cond.privateFlag]
          : tierInfo.privateFlags[cond.privateFlag])),
  );
};
