import config from '@/config/config';
import { StaticProfileConfig } from '@/shared/types/api';
import axios from 'axios';

export const fetchProfile = async (
  usernameOrCode: string,
  lang: string,
  params?: {
    uid?: number;
    includeEmptyBoxes?: boolean;
  },
): Promise<{
  status: number;
  name: 'profile' | 'code' | 'error';
  profile?: StaticProfileConfig;
  code?: string;
}> => {
  const res = await axios.get(config.API_BASE_URL + `profile/${usernameOrCode}`, {
    params: {
      lang,
      ...(params || {}),
    },
  });
  if (res.status === 200 && res.data.code === 'profile') {
    // profile fetched
    return {
      status: res.status,
      name: res.data.code,
      profile: res.data.data as StaticProfileConfig,
    };
  } else if (res.status === 200 && res.data.code === 'code') {
    // code available
    return {
      status: res.status,
      name: res.data.code,
      code: res.data.data?.code,
    };
  } else {
    return {
      status: res.status,
      name: 'error',
    };
  }
};
