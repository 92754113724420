import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Backdrop, CircularProgress, ThemeProvider } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  delayedShow?: number; //ms
}

export default function Loader(props: Props): React.ReactPortal | null {
  const { theme: muiTheme } = useMuiTheme();
  useEffect(() => {
    document.getElementsByTagName('body')[0].setAttribute('aria-busy', 'true');
    return () => {
      document.getElementsByTagName('body')[0].removeAttribute('aria-busy');
    };
  }, []);
  const portal = useMemo(
    () =>
      ReactDOM.createPortal(
        <ThemeProvider theme={muiTheme}>
          <Backdrop
            open
            sx={theme => ({
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              zIndex: theme.zIndex.modal + 1,
            })}
          >
            <CircularProgress size={60} sx={theme => ({ color: theme.palette.grey[800] })} />
          </Backdrop>
        </ThemeProvider>,
        document.getElementById('portal'),
      ),
    [muiTheme],
  );
  const [portalLoader, setPortalLoader] = useState(props.delayedShow ? null : portal);

  useEffect(() => {
    if (props.delayedShow) {
      const toid = setTimeout(() => setPortalLoader(portal), props.delayedShow);
      return () => clearTimeout(toid);
    }
  }, [portal, props.delayedShow]);

  return portalLoader;
}
