import { CopyIconButton } from '@/components';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InformativeInput = ({ value, label, helperText = '', ...rest }) => {
  const { t } = useTranslation();
  return (
    <FormControl {...rest} fullWidth>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        sx={{ color: 'text.secondary' }}
        endAdornment={
          <CopyIconButton
            color='secondary'
            onClick={() => navigator.clipboard.writeText(value)}
            aria-label={`${t('ariaClickToCopy')} ${label}`}
          />
        }
        label={label}
        value={value}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
